import makeStyles from '@mui/styles/makeStyles';
import { FC, useCallback, memo, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector, useDispatch } from 'react-redux';

import { DraggableFavoritesItem } from '../../businessRules/models/FavoriteListsHelpers';
import { URL_PERMISSION_SHARE_PRODUCTION_ASSET } from '../../state/constants';
import { favoritesActions, favoritesSelectors } from '../../state/modules/favorites';
import FavoritesItems from './FavoritesItems';
import FavoritesMenu from './FavoritesMenu';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: 0,
  },
}));

const Favorites: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [deleteDraggedItem, setDeleteDraggedItem] = useState(false);

  useEffect(() => {
    dispatch(favoritesActions.fetchFavoriteLists());
    dispatch(favoritesActions.fetchFavoriteListsOptions());
    dispatch(favoritesActions.fetchPermission(URL_PERMISSION_SHARE_PRODUCTION_ASSET));
  }, [dispatch]);

  useEffect(() => {
    const lastFavoriteListId = parseInt(localStorage.getItem('favoriteListId') || '-1', 10);
    const lastSharedActiveLists = localStorage.getItem('sharedActiveLists');
    if (lastFavoriteListId) {
      dispatch(favoritesActions.selectFavoriteList(lastFavoriteListId));
    }
    if (lastSharedActiveLists) {
      dispatch(favoritesActions.setSharedActiveLists(JSON.parse(lastSharedActiveLists)));
    }
  }, [dispatch]);

  const onListSelect = (id: number) => {
    dispatch(favoritesActions.selectFavoriteList(id));
  };

  const [, dropOutside] = useDrop<DraggableFavoritesItem, void, void>({
    accept: ['favoriteitem'],
    hover: (_draggedItem, monitor) => {
      if (monitor.isOver()) {
        setDeleteDraggedItem(true);
      }
    },
    drop: (draggedItem, monitor) => {
      if (monitor.isOver()) {
        dispatch(favoritesActions.deleteFavoriteItem(draggedItem.id));
      }
    },
  });
  useEffect(() => {
    dropOutside(document.getElementById('container-wrapper'));
  }, [dropOutside]);
  const [, drop] = useDrop({
    accept: ['favoriteitem'],
    hover: (_draggedItem, monitor) => {
      if (monitor.isOver()) {
        setDeleteDraggedItem(false);
      }
    },
  });
  const selectedListWithItems = useSelector(favoritesSelectors.getSelectedListWithItems);
  const resetDeleteDraggedItem = useCallback(() => {
    setDeleteDraggedItem(false);
  }, [setDeleteDraggedItem]);

  return (
    <div id="favorites" ref={drop} className={classes.container}>
      <FavoritesMenu onListSelect={onListSelect} selectedListId={selectedListWithItems?.id} />
      {selectedListWithItems && (
        <FavoritesItems
          listName={selectedListWithItems.name}
          listItems={selectedListWithItems.items}
          deleteDraggedItem={deleteDraggedItem}
          onItemDragStart={resetDeleteDraggedItem}
          onItemDragEnd={resetDeleteDraggedItem}
        />
      )}
    </div>
  );
};

export default memo(Favorites);
