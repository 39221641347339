import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, ngettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Archive } from 'medialoopster/icons';
import { getResourceTypeName } from 'medialoopster/rest';

import { archiveActions, archiveSelectors } from '../../../../state/modules/archive';
import { isAssetCollection } from '../../../../state/modules/rest/collection/types';
import { isAssetCollectionEmpty } from '../../../../state/modules/rest/collection/utils';
import { Asset } from '../../../../state/types/asset/unionTypes';

type Props = {
  readonly assets: ReadonlyArray<Asset>;
};

const SendToArchiveActionMenuEntry = ({ assets }: Props) => {
  const dispatch = useDispatch();

  const allArchived = assets.every((asset) => asset.is_archive);
  const collectionIsEmpty = assets.every(
    (collection) => isAssetCollection(collection) && isAssetCollectionEmpty(collection),
  );

  const getToolTip = (): string => {
    if (allArchived) {
      return gettext('All selected assets are already archived.');
    }
    if (collectionIsEmpty) {
      return gettext('The collection is empty.');
    }
    return ngettext(
      'Send the selected asset to the archive.',
      'Send the selected assets to the archive.',
      assets.length,
    );
  };

  return (
    <MenuItem
      disabled={allArchived || collectionIsEmpty}
      tooltip={getToolTip()}
      onClick={() => {
        dispatch(archiveActions.archiveAssets(assets));
      }}
      icon={<Archive fontSize="small" />}
      label={pgettext('Menu entry', 'Send to archive')}
    />
  );
};

const useSendToArchiveActionMenuEntry = (
  assets: ReadonlyArray<Asset>,
): ReadonlyArray<ReactElement> => {
  const canArchiveAssets = useSelector(archiveSelectors.canArchiveAssets);
  const archiveAssetTypes = useSelector(archiveSelectors.getArchiveAssetTypes);
  if (assets.length === 0 || !canArchiveAssets) {
    return [];
  }
  if (assets.every((asset) => !archiveAssetTypes.includes(getResourceTypeName(asset)))) {
    return [];
  }
  return [<SendToArchiveActionMenuEntry key="send-to-archive" assets={assets} />];
};

export default useSendToArchiveActionMenuEntry;
