import { Divider } from '@mui/material';
import { FC, ReactNode } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { DropdownMenu, MenuItem } from 'medialoopster/components';

export interface ActionMenuEntry {
  readonly id: string;
  readonly label: string;
  readonly tooltip?: string;
  readonly icon?: ReactNode;
}

export interface ActionMenuGroup<A extends ActionMenuEntry = ActionMenuEntry> {
  readonly key: string;
  readonly actions: ReadonlyArray<A>;
}

export interface Props {
  readonly actionGroups: ReadonlyArray<ActionMenuGroup>;
  readonly onAction: (action: ActionMenuEntry) => void;
  readonly label?: string;
  readonly icon?: ReactNode;
  readonly disabled?: boolean;
  readonly isActionDisabled?: (action: ActionMenuEntry) => boolean;
  readonly getDisabledReason?: (action: ActionMenuEntry) => string;
}

const ActionMenu: FC<Props> = ({
  actionGroups,
  onAction,
  icon = undefined,
  label = gettext('Actions'),
  disabled = false,
  isActionDisabled = () => false,
  getDisabledReason = () => '',
}: Props) => {
  const groups = actionGroups
    .map((actionGroup) => ({
      key: actionGroup.key,
      actions: actionGroup.actions.map((action) => (
        <MenuItem
          key={action.id}
          label={action.label}
          tooltip={isActionDisabled(action) ? getDisabledReason(action) : action.tooltip}
          icon={action.icon}
          disabled={isActionDisabled(action)}
          onClick={() => onAction(action)}
        />
      )),
    }))
    .filter((group) => group.actions.length > 0);
  const menuItems = groups.flatMap((group, groupIndex) => [
    groupIndex > 0 ? [<Divider key={group.key} />] : [],
    ...group.actions,
  ]);
  return (
    <DropdownMenu
      popupId="actions"
      label={label}
      icon={icon}
      buttonProps={{
        disabled,
        sx: { height: '100%' },
        'aria-label': label,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      {menuItems}
    </DropdownMenu>
  );
};

export default ActionMenu;
