import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  FC,
  MouseEvent as ReactMouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { ToolbarButtonGroup, ToolbarIconButton } from 'medialoopster/components';
import { formatSecondsAsTimecode } from 'medialoopster/formatTimecode';
import {
  NextFrame,
  NextSecond,
  Pause,
  Play,
  PreviousFrame,
  PreviousSecond,
} from 'medialoopster/icons';

import { AudioAsset } from '../../state/modules/audio/types';
import PlayerContainer from './PlayerContainer';

const useStyles = makeStyles(() => ({
  playhead: {
    cursor: 'col-resize',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '2px',
    backgroundColor: 'black',
    borderRight: '1px solid white',
  },
  player: {
    cursor: 'col-resize',
    height: '100%',
    width: '100%',
  },
}));

export const togglePlayOnRef =
  (
    controllerRef: RefObject<HTMLAudioElement>,
    setPaused: (shouldPause: boolean) => void,
  ): ((shouldPlay: boolean) => void) =>
  (shouldPlay: boolean) => {
    if (!controllerRef || typeof controllerRef === 'function') {
      return;
    }
    const { current } = controllerRef;
    if (!current) {
      return;
    }
    if (shouldPlay) {
      current.play().then(() => {
        setPaused(false);
      });
    } else {
      current.pause();
      setPaused(true);
    }
  };

type Props = {
  readonly asset: AudioAsset;
};

const AudioPlayer: FC<Props> = ({ asset }: Props) => {
  const classes = useStyles();
  const currentTimeRef = useRef<HTMLBaseElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const playheadRef = useRef<HTMLDivElement>(null);
  const [paused, setPaused] = useState<boolean>(true);
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const [isMouseDown, setMouseDown] = useState<boolean>(false);
  const getCurrentTime = useCallback(() => audioRef?.current?.currentTime || 0, [audioRef]);
  const duration = parseFloat(asset.duration);

  const togglePlay = useMemo(() => togglePlayOnRef(audioRef, setPaused), [audioRef, setPaused]);

  const updateCurrentTime = useCallback(() => {
    if (playheadRef.current && imgRef.current && duration > 0) {
      const currentSlider = playheadRef.current;
      const rect = imgRef.current.getBoundingClientRect();
      // Subtract the playhead width of 2px from 100% to get the maximum percentage.
      const maxPercent = ((rect.width - 2) / rect.width) * 100;
      const position = `${Math.min((getCurrentTime() / duration) * 100, maxPercent)}%`;
      currentSlider.style.left = position;
      if (audioRef.current?.ended) {
        togglePlay(false);
      }
    }
    if (currentTimeRef && currentTimeRef.current) {
      currentTimeRef.current.innerHTML = `${formatSecondsAsTimecode(getCurrentTime())}`;
    }
  }, [playheadRef, audioRef, imgRef, currentTimeRef, duration, getCurrentTime, togglePlay]);

  const seek = useCallback(
    (seconds: number) => {
      if (audioRef.current) {
        togglePlay(false);
        audioRef.current.currentTime = seconds;
        updateCurrentTime();
      }
    },
    [audioRef, updateCurrentTime, togglePlay],
  );

  const seekRel = useCallback(
    (seconds: number) => {
      if (audioRef.current) {
        seek(audioRef.current.currentTime + seconds);
      }
    },
    [audioRef, seek],
  );

  useEffect(() => {
    let interval: number;
    if (asset.proxy_url && !paused) {
      interval = window.setInterval(() => {
        updateCurrentTime();
      }, 50); // needs CPU Performance.
    }
    updateCurrentTime();
    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [paused, updateCurrentTime, getCurrentTime, asset]);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (document.activeElement?.tagName === 'INPUT') {
        return;
      }
      if (!canPlay) {
        if (['Space', 'KeyK', 'ArrowLeft', 'ArrowRight'].includes(event.code)) {
          // Stop any other ui events unrelated to known player shortcuts
          event.preventDefault();
        }
        return;
      }
      switch (event.code) {
        case 'Space':
        case 'KeyK':
          togglePlay(paused);
          event.preventDefault();
          event.stopPropagation();
          break;
        case 'ArrowLeft':
          if (event.shiftKey) {
            seekRel(-10);
          } else {
            seekRel(-1);
          }
          event.preventDefault();
          event.stopPropagation();
          break;
        case 'ArrowRight':
          if (event.shiftKey) {
            seekRel(10);
          } else {
            seekRel(1);
          }
          event.preventDefault();
          event.stopPropagation();
          break;
        default:
      }
    };
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [setPaused, seekRel, canPlay, paused, togglePlay]);

  const scrub = useCallback(
    (clientX: number) => {
      if (!imgRef.current) {
        return;
      }
      const rect = imgRef.current.getBoundingClientRect();
      const x = clientX - rect.left;
      if (rect.width > 0) {
        seek((x / rect.width) * duration);
      }
    },
    [imgRef, duration, seek],
  );

  useEffect(() => {
    if (!isMouseDown) {
      return () => {};
    }
    const handleEvent = (event: MouseEvent) => {
      if (!imgRef.current || event.buttons !== 1) {
        setMouseDown(false);
        return;
      }
      scrub(event.clientX);
      event.preventDefault();
      event.stopPropagation();
    };
    document.body.addEventListener('mousemove', handleEvent);
    return () => {
      document.body.removeEventListener('mousemove', handleEvent);
    };
  }, [imgRef, isMouseDown, setMouseDown, seek, scrub]);

  const onMouseDown = (event: ReactMouseEvent) => {
    if (event.buttons !== 1) {
      return;
    }
    if (!canPlay) {
      return;
    }
    setMouseDown(true);
    scrub(event.clientX);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const onMouseUp = () => {
    setMouseDown(false);
  };

  useEffect(() => {
    seek(0);
  }, [seek]);

  useEffect(() => {
    const addCanPlayListener = () => {
      if (audioRef.current) {
        audioRef.current.addEventListener('canplay', () => {
          setCanPlay(true);
        });
      }
    };
    addCanPlayListener();
    return () => {
      setCanPlay(false);
    };
  }, [audioRef]);

  useEffect(() => {
    togglePlay(false);
    setCanPlay(false);
  }, [asset.proxy_url, togglePlay]);

  return (
    <PlayerContainer
      player={
        <>
          <audio ref={audioRef} src={asset.proxy_url} data-testid="audioPlayer" />
          <img
            ref={imgRef}
            src={asset.poster_url}
            alt={asset.name}
            className={classes.player}
            draggable={false}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          />
          <div data-testid="playhead" ref={playheadRef} className={classes.playhead} />
        </>
      }
      currentTimeDisplay={
        <Grid item xs={6}>
          <Typography variant="body2" ref={currentTimeRef}>
            00:00:00.00
          </Typography>
        </Grid>
      }
      toolbarButtons={
        <ToolbarButtonGroup>
          <ToolbarIconButton
            title={paused ? gettext('Play (space, k)') : gettext('Pause (space, k)')}
            onClick={() => {
              togglePlay(paused);
            }}
            disabled={!canPlay}
          >
            {paused ? <Play /> : <Pause />}
          </ToolbarIconButton>
          <ToolbarButtonGroup>
            <ToolbarIconButton
              title={gettext('-1 second (left)')}
              onClick={() => seekRel(-1)}
              disabled={!canPlay}
            >
              <PreviousFrame />
            </ToolbarIconButton>
            <ToolbarIconButton
              title={gettext('+1 second (right)')}
              onClick={() => seekRel(1)}
              disabled={!canPlay}
            >
              <NextFrame />
            </ToolbarIconButton>
          </ToolbarButtonGroup>
          <ToolbarButtonGroup>
            <ToolbarIconButton
              title={gettext('-10 seconds (shift+left)')}
              onClick={() => seekRel(-10)}
              disabled={!canPlay}
            >
              <PreviousSecond />
            </ToolbarIconButton>
            <ToolbarIconButton
              title={gettext('+10 seconds (shift+right)')}
              onClick={() => seekRel(10)}
              disabled={!canPlay}
            >
              <NextSecond />
            </ToolbarIconButton>
          </ToolbarButtonGroup>
        </ToolbarButtonGroup>
      }
      getCurrentTime={getCurrentTime}
    />
  );
};
export default AudioPlayer;
