import { Grid, NativeSelect } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import { FunctionComponent, ChangeEvent } from 'react';

import { pgettext, gettext } from '../../../Internationalization';
import { ChevronLeft, ChevronRight } from '../../../icons';
import IconButton from '../../buttons/IconButton';

interface HeaderProps {
  date: DateTime;
  setDate: (date: DateTime) => void;
}

const MONTHS = [
  pgettext('Month name', 'January'),
  pgettext('Month name', 'February'),
  pgettext('Month name', 'March'),
  pgettext('Month name', 'April'),
  pgettext('Month name', 'May'),
  pgettext('Month name', 'June'),
  pgettext('Month name', 'July'),
  pgettext('Month name', 'August'),
  pgettext('Month name', 'September'),
  pgettext('Month name', 'October'),
  pgettext('Month name', 'November'),
  pgettext('Month name', 'December'),
];

const generateYears = (relativeTo: DateTime, count: number) => {
  const half = Math.floor(count / 2);
  return Array(count)
    .fill(0)
    .map((_y, i) => relativeTo.year - half + i);
};

const useStyles = makeStyles(() => ({
  bootstrapOverrides: {
    '&:focus': {
      outline: 'none !important',
    },
  },
}));
const Header: FunctionComponent<HeaderProps> = ({ date, setDate }: HeaderProps) => {
  const classes = useStyles();

  const handleMonthChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newDate = date.set({ month: parseInt(event.target.value as string, 10) });
    setDate(newDate);
  };

  const clickPrev = () => {
    setDate(date.minus({ month: 1 }));
  };

  const clickNext = () => {
    setDate(date.plus({ month: 1 }));
  };

  const handleYearChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDate(date.set({ year: parseInt(event.target.value as string, 10) }));
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item>
          <IconButton
            onClick={clickPrev}
            aria-label={gettext('Go to previous month')}
            className={classes.bootstrapOverrides}
          >
            <ChevronLeft color="action" />
          </IconButton>
        </Grid>
        <Grid item>
          <NativeSelect
            value={date.month}
            onChange={handleMonthChange}
            aria-label={gettext('Select month')}
          >
            {MONTHS.map((month, idx) => (
              <option key={month} value={idx + 1}>
                {month}
              </option>
            ))}
          </NativeSelect>
        </Grid>

        <Grid item>
          <NativeSelect
            value={date.year}
            onChange={handleYearChange}
            aria-label={gettext('Select year')}
          >
            {generateYears(date, 30).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </NativeSelect>
        </Grid>
        <Grid item>
          <IconButton
            onClick={clickNext}
            aria-label={gettext('Go to next month')}
            className={classes.bootstrapOverrides}
          >
            <ChevronRight color="action" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
