/**
 * A module for custom commands used in the transcript editor.
 *
 * All custom commands should be declared in this module and implemented in plugins that group
 * the responsibilities of the commands. This prevents dependency circles and keeps the code in
 * order.
 */
import { createCommand, NodeKey } from 'lexical';

/**
 * The payload of the CHANGE_ALL_SPEAKER_OCCURRENCES command
 */
export interface ChangeAllSpeakerOccurrencesPayload {
  /**
   * The old name of the speaker. All time blocks/speakers with this name will be modified
   */
  readonly oldName: string;
  /**
   * The new name of the speaker.
   */
  readonly newName: string;
}

/**
 * A command to change all speakers with a certain name to a new name.
 *
 * The command takes the old and the new name of a speaker and changes all speakers / time blocks
 * with a speaker matching the old name to a speaker with the new name.
 *
 * The handler logic for this command is implemented in TranscriptChangeSpeakerPlugin.
 */
export const CHANGE_ALL_SPEAKER_OCCURRENCES = createCommand<ChangeAllSpeakerOccurrencesPayload>(
  'CHANGE_ALL_SPEAKER_OCCURRENCES',
);

/**
 * The payload of the CHANGE_ONE_SPEAKER_OCCURRENCE
 */
export interface ChangeOneSpeakerOccurrencePayload {
  /**
   * The NodeKey of the time block with the speaker to change.
   */
  readonly timeBlockNodeKey: NodeKey;
  /**
   * The new name for the speaker.
   */
  readonly newName: string;
}

/**
 * A command to change the speaker of a single time block.
 *
 * The command takes the NodeKey of a TranscriptTimeBlockNode and a new speaker name and changes the
 * speaker of the time block to the new name.
 *
 * The handler logic for this command is implemented in TranscriptChangeSpeakerPlugin.
 */
export const CHANGE_ONE_SPEAKER_OCCURRENCE = createCommand<ChangeOneSpeakerOccurrencePayload>(
  'CHANGE_ONE_SPEAKER_OCCURRENCE',
);

/**
 * A command to save the current edit state of the transcript via a diff.
 */
export const SAVE_TRANSCRIPT = createCommand<null>('SAVE_TRANSCRIPT');
