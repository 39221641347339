import { Divider } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { pgettext } from 'medialoopster/Internationalization';
import { DropdownMenu } from 'medialoopster/components';
import { AssetActions } from 'medialoopster/icons';

import { isVideoAsset, isMediaAsset } from '../../../businessRules/models/asset/utils';
import { detailsSelectors } from '../../../state/modules/details';
import { operationsSelectors } from '../../../state/modules/operations';
import { isAssetCollection } from '../../../state/modules/rest/collection/types';
import { searchSelectors } from '../../../state/modules/search';
import { MediaAsset } from '../../../state/types/asset/unionTypes';
import useAddAssetsToCollectionActionMenuEntry from './menuitems/useAddAssetsToCollectionActionMenuEntry';
import useAnalyseActionMenuEntries from './menuitems/useAnalyseActionMenuEntries';
import useAssetExchangeActionMenuEntries from './menuitems/useAssetExchangeActionMenuEntries';
import useChangePosterFrameActionMenuEntry from './menuitems/useChangePosterFrameActionMenuEntry';
import useCreateCollectionFromAssetsActionMenuEntry from './menuitems/useCreateCollectionFromAssetsActionMenuEntry';
import {
  useHighresDownloadActionMenuEntry,
  useProxyDownloadActionMenuEntry,
} from './menuitems/useDownloadActionMenuEntry';
import useEditActionMenuEntry from './menuitems/useEditActionMenuEntry';
import useRemoveFromArchiveQueueActionMenuEntry from './menuitems/useRemoveFromArchiveQueueActionMenuEntry';
import useRestoreFromArchiveActionMenuEntry from './menuitems/useRestoreFromArchiveActionMenuEntry';
import useSendToArchiveActionMenuEntry from './menuitems/useSendToArchiveActionMenuEntry';
import useShareAssetsActionMenuEntry from './menuitems/useShareAssetsActionMenuEntry';
import useTransferActionMenuEntry from './menuitems/useTransferActionMenuEntry';
import useViewAdminActionMenuEntry from './menuitems/useViewAdminActionMenuEntry';
import useViewManagementActionMenuEntry from './menuitems/useViewManagementActionMenuEntry';
import useWebhookActionMenuEntries from './menuitems/useWebhookActionMenuEntries';

const AssetActionMenu: FC = () => {
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const selectedAssets = useSelector(operationsSelectors.getSelectedAssets);
  const searchCategory = useSelector(searchSelectors.getSearchCategory);
  const selectedVideoAssets = selectedAssets.filter(isVideoAsset);
  const shareableCollectionAssets = useSelector(detailsSelectors.getCurrentCollectionContent);

  const selectedShareableAssets =
    isAssetCollection(currentAsset) && searchCategory === 'collection'
      ? [currentAsset, ...shareableCollectionAssets]
      : selectedAssets;

  const currentMediaAssets: ReadonlyArray<MediaAsset> = useMemo(() => {
    // The media assets of a collection or the selected assets in search category aka currentMediaAssets
    if (!currentAsset || isAssetCollection(currentAsset)) {
      return [];
    }
    if (isAssetCollection(currentAsset) && searchCategory === 'collection') {
      return [...shareableCollectionAssets];
    }
    return selectedAssets.filter(isMediaAsset);
  }, [currentAsset, searchCategory, selectedAssets, shareableCollectionAssets]);

  const assetChoices = useSelector(operationsSelectors.getAssetChoices);
  const items = [
    {
      key: 'common',
      items: [
        ...useEditActionMenuEntry(currentAsset),
        ...useShareAssetsActionMenuEntry(selectedShareableAssets),
        ...useCreateCollectionFromAssetsActionMenuEntry(currentMediaAssets),
        ...useAddAssetsToCollectionActionMenuEntry(currentMediaAssets),
        ...useSendToArchiveActionMenuEntry(selectedAssets),
        ...useRemoveFromArchiveQueueActionMenuEntry(selectedAssets),
        ...useRestoreFromArchiveActionMenuEntry(selectedAssets),
        ...useChangePosterFrameActionMenuEntry(currentAsset),
        ...useProxyDownloadActionMenuEntry(),
        ...useHighresDownloadActionMenuEntry(),
        ...useAnalyseActionMenuEntries(),
      ],
    },
    {
      key: 'asset-exchange',
      items: useAssetExchangeActionMenuEntries(selectedVideoAssets),
    },
    {
      key: 'webhooks-and-transfer',
      items: [
        ...useWebhookActionMenuEntries(currentAsset),
        ...useTransferActionMenuEntry(currentAsset, assetChoices),
      ],
    },
    {
      key: 'management',
      items: [
        ...useViewManagementActionMenuEntry(currentAsset),
        ...useViewAdminActionMenuEntry(currentAsset),
      ],
    },
  ]
    .filter((group) => group.items.length > 0)
    .flatMap((group, groupIndex) => [
      groupIndex > 0 ? [<Divider key={group.key} />] : [],
      ...group.items,
    ]);
  if (items.length === 0) {
    return null;
  }
  return (
    <DropdownMenu
      popupId="asset-action-menu"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      label={pgettext('Header button title', 'Asset Actions')}
      buttonProps={{ startIcon: <AssetActions /> }}
    >
      {items}
    </DropdownMenu>
  );
};

export default AssetActionMenu;
