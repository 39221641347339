import { TypographyProps } from '@mui/material/Typography';
import { FC, ReactNode, memo } from 'react';

import InplaceTextEdit from '../../../ui/components/InplaceEdits/InplaceTextEdit';
import { textWithHighlightedRegions } from '../../../ui/services/highlighting';
import createMultiElementHighlighter from './createMultiElementHighlighter';
import replaceMarkdownLinks from './replaceMarkdownLinks';

type Props = {
  readonly value: string | null;
  readonly allowLinks?: boolean;
  readonly highlights?: ReadonlyArray<string>;
  readonly canEdit?: boolean;
  readonly variant?: TypographyProps['variant'];
  readonly validationRule?: string;
  readonly onSubmit?: (value: string) => void;
};

const Text: FC<Props> = ({
  value,
  highlights,
  allowLinks = false,
  canEdit = false,
  variant = 'body2',
  validationRule = '',
  onSubmit,
}: Props) => {
  let textComponents: ReactNode = value ?? '';
  if (value) {
    textComponents = allowLinks
      ? replaceMarkdownLinks(value, createMultiElementHighlighter(highlights))
      : textWithHighlightedRegions(value, highlights);
  }
  const editValue = value ?? '';
  return (
    <InplaceTextEdit
      textComponents={<span>{textComponents}</span>}
      onSubmit={onSubmit}
      editValue={editValue}
      canEdit={canEdit}
      variant={variant}
      dense
      validationRule={validationRule}
    />
  );
};

export default memo(Text);
