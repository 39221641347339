import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Admin } from 'medialoopster/icons';
import { getResourceTypeName } from 'medialoopster/rest';

import { usersSelectors } from '../../../../state/modules/users';
import { Asset } from '../../../../state/types/asset/unionTypes';

type Props = {
  readonly url: string;
};

const ViewAdminActionMenuEntry = ({ url }: Props) => {
  return (
    <MenuItem
      label={pgettext('Menu entry', 'View in administration')}
      tooltip={pgettext('Tooltip for menu entry', 'View asset in administration')}
      icon={<Admin fontSize="small" />}
      href={url}
      target="_blank"
    />
  );
};

const useViewAdminActionMenuEntry = (currentAsset: Asset | null): ReadonlyArray<ReactElement> => {
  const currentAssetType = currentAsset ? getResourceTypeName(currentAsset) : null;
  const isSuperUser = useSelector(usersSelectors.isSuperUser);
  if (!currentAsset || !currentAssetType || !isSuperUser) {
    return [];
  }
  // TODO: remove hack to projectasset admin <PB 2024-04-08 t:ML-2034>
  const url = `/mladmin/browser/${
    currentAssetType === 'collection' ? 'projectasset' : currentAssetType
  }/?id=${currentAsset.id}`;
  return [<ViewAdminActionMenuEntry key="view-in-admin" url={url} />];
};

export default useViewAdminActionMenuEntry;
