import { RefObject, useState } from 'react';

/**
 * Detects if the browser shows displayed text smaller than original text
 *
 * Details:
 *   E.g. when using css 'text-overflow: ellipsis' offsetWidth is smaller
 *   than scrollWidth.
 *   In safari styles for pseudoelement 'after' are needed
 *   to avoid duplicate tooltips when using ellipsis
 *
 * @param node The HTMLNode from ref's or null initially
 */
export const isOverflow = <T extends HTMLElement>(node: T | null): boolean =>
  // When using css 'text-overflow: ellipsis'
  node ? node.offsetWidth < node.scrollWidth : false;

/**
 * Hook to check text overflow of a node in browser for specific
 * user interactions.
 *
 * Details:
 *   Useful if the interactions are known. E.g. for a tooltip.
 *   (If interactions are not known resize detector has to be used)
 */
export const useTextOverflow = <T extends HTMLElement>(
  ref: RefObject<T>,
): [boolean, () => void] => {
  // Defaults to false for useRef(null) initially
  const [isTextOverflow, setIsTextOverflow] = useState(isOverflow(ref.current));

  // Callback to trigger check if the text visibility changed in the browser.
  const checkNodeOverflow = () => {
    setIsTextOverflow(isOverflow(ref.current));
  };

  return [isTextOverflow, checkNodeOverflow];
};
