import makeStyles from '@mui/styles/makeStyles';
import { useObservableEagerState } from 'observable-hooks';
import {
  FC,
  KeyboardEvent,
  useRef,
  useState,
  useCallback,
  useEffect,
  Fragment,
  CSSProperties,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { throttleTime } from 'rxjs/operators';

import { AutoSuggestInput, useVideoController } from 'medialoopster/components';

import { favoritesSelectors } from '../../../state/modules/favorites';
import { personsActions, personsConstants, personsSelectors } from '../../../state/modules/persons';
import {
  TEXT_AREA_MARGIN,
  DEFAULT_PLAYER_WIDTH,
  DEFAULT_PLAYER_HEIGHT,
  LINE_WIDTH_EM,
} from '../../../state/modules/persons/constants';
import { BoundingBox } from '../../../state/modules/persons/types';
import usePlayerDragRef from '../../../ui/hooks/usePlayerDragRef';
import useBoundingBoxesAtFrame from './useBoundingBoxesAtFrame';

const MIN_INPUT_WIDTH_IN_PX = 180;
const INPUT_HEIGHT_IN_PX = 24;

const useStyles = makeStyles(() => ({
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
  boundingBoxContainer: {
    position: 'relative',
    color: '#fff',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
  },
  boundingBox: {
    zIndex: 2,
    position: 'absolute',
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: `${LINE_WIDTH_EM}em`,
  },
  textArea: {
    zIndex: 3,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1.2em',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  renameContainer: {
    zIndex: 4,
    position: 'absolute',
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: '#fff',
    },
  },
}));

const BoundingBoxes: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dragRef = usePlayerDragRef();
  const container = useRef<HTMLDivElement>(null);
  const videoController = useVideoController();
  const updateFrame$ = useMemo(
    () => videoController.frame$.pipe(throttleTime(200)),
    [videoController],
  );
  const currentFrame = useObservableEagerState(updateFrame$);
  const boundingBoxes = useBoundingBoxesAtFrame(currentFrame);

  const overlayDisplayValues = useSelector(personsSelectors.getOverlayDisplayValues);
  const knownPersons = useSelector(personsSelectors.getKnownPersons);
  const isBoundingBoxEditMode = useSelector(personsSelectors.isBoundingBoxEditMode);
  const hasRenameError = useSelector(personsSelectors.hasRenameError);
  const isFavoritesActive = useSelector(favoritesSelectors.isActive);
  const defaultCursor = isFavoritesActive ? 'grab' : 'auto';

  const [width, setWidth] = useState(DEFAULT_PLAYER_WIDTH);
  const [height, setHeight] = useState(DEFAULT_PLAYER_HEIGHT);

  const onResize = useCallback(
    (w: number | undefined = 0) => {
      setWidth(w);
      setHeight(w * (9 / 16));
    },
    [setWidth, setHeight],
  );

  const { ref } = useResizeDetector({ onResize });

  const [inputDisplayStyle, setInputDisplayStyle] = useState<CSSProperties>({});
  const [renameBox, setRenameBox] = useState<BoundingBox | null>(null);
  const [newName, setNewName] = useState<string | null>(null);

  const onChangeName = (name: string | null) => {
    setNewName(name);
  };

  const onEnterName = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !!newName && renameBox) {
      const matchingPersons = knownPersons.filter((person) => person.name === newName);
      if (matchingPersons.length !== 0) {
        dispatch(personsActions.assignFace(renameBox.faceURI, matchingPersons[0].id));
      } else {
        dispatch(personsActions.renamePerson(renameBox.personURI, newName));
      }
    }
  };

  const setupRenameInput = useCallback(
    (box: BoundingBox) => {
      const minInputWidth = MIN_INPUT_WIDTH_IN_PX / width;
      let { left } = box;
      let inputWidth = box.width;
      if (inputWidth < minInputWidth) {
        const newLeft = box.center - minInputWidth / 2;
        if (
          newLeft + minInputWidth >
          overlayDisplayValues.mediaWidth + overlayDisplayValues.padding
        ) {
          left = box.right - minInputWidth;
        } else if (newLeft > overlayDisplayValues.padding) {
          left = newLeft;
        } // else, not enough space on the left to center, keep old left
        inputWidth = minInputWidth;
      }
      const inputHeight = INPUT_HEIGHT_IN_PX / height + TEXT_AREA_MARGIN;
      let inputTop = box.bottom + TEXT_AREA_MARGIN;
      if (inputTop > 1 - inputHeight) {
        inputTop = box.top - inputHeight;
        if (inputTop < 0) {
          // if not enough space below, put text within box
          inputTop = box.bottom - inputHeight;
        }
      }
      setInputDisplayStyle({
        top: `${inputTop * 100}%`,
        left: `${left * 100}%`,
        width: `${inputWidth * 100}%`,
      });
      setRenameBox(box);
    },
    [width, height, overlayDisplayValues],
  );

  useEffect(() => {
    if (isBoundingBoxEditMode) {
      // display the input for the first bounding box of an unknown person if isBoundingBoxEditMode
      const firstUnknown = boundingBoxes.find(({ isUnknown }) => isUnknown);
      if (firstUnknown) {
        setupRenameInput(firstUnknown);
      } else {
        // reset input after rename
        setInputDisplayStyle({});
        setRenameBox(null);
        setNewName(null);
      }
    } else if (!isBoundingBoxEditMode) {
      // reset input if no edit mode
      setInputDisplayStyle({});
      setRenameBox(null);
      setNewName(null);
    }
  }, [boundingBoxes, isBoundingBoxEditMode, setupRenameInput]);

  return (
    <>
      {boundingBoxes.length > 0 && (
        <div ref={ref}>
          <div ref={dragRef} className={`video-overlay-container ${classes.overlayContainer}`}>
            <div
              ref={container}
              data-testid="bounding-box-container"
              className={classes.boundingBoxContainer}
              style={{
                width: `${width}px`,
                height: `${height}px`,
                fontSize: `${personsConstants.FONT_SIZE_PROPORTIONAL * width}px`,
                cursor: defaultCursor,
              }}
            >
              {width > 0 &&
                height > 0 &&
                boundingBoxes.map((box) => (
                  <Fragment key={`${box.segmentURI}_fragment`}>
                    <div
                      key={`${box.segmentURI}_box`}
                      className={classes.boundingBox}
                      data-testid="bounding-box"
                      style={{
                        left: `${box.left * 100}%`,
                        top: `${box.top * 100}%`,
                        width: `${box.width * 100}%`,
                        height: `${(box.bottom - box.top) * 100}%`,
                      }}
                    />
                    {renameBox?.segmentURI !== box.segmentURI && (
                      <div
                        role="presentation"
                        key={`${box.segmentURI}_text`}
                        onMouseEnter={() => {
                          if (container.current && box.isUnknown && isBoundingBoxEditMode) {
                            container.current.style.cursor = 'pointer';
                          }
                        }}
                        onMouseLeave={() => {
                          if (container.current && box.isUnknown && isBoundingBoxEditMode) {
                            container.current.style.cursor = defaultCursor;
                          }
                        }}
                        onClick={() => {
                          if (container.current && box.isUnknown && isBoundingBoxEditMode) {
                            container.current.style.cursor = defaultCursor;
                            setupRenameInput(box);
                          }
                        }}
                        className={classes.textArea}
                        style={{
                          left: `${box.textAreaLeft * 100}%`,
                          top: `${box.textAreaTop * 100}%`,
                          width: `${box.textAreaWidth * 100}%`,
                          height: `${box.textAreaHeight * 100}%`,
                          borderRadius: `${0.004 * width}px`,
                        }}
                      >
                        {box.name[0]}
                        {!!box.name[1] && (
                          <>
                            <br />
                            {box.name[1]}
                          </>
                        )}
                      </div>
                    )}
                  </Fragment>
                ))}
              {!!renameBox && (
                <div
                  className={classes.renameContainer}
                  style={inputDisplayStyle}
                  data-testid="rename-container"
                >
                  <AutoSuggestInput
                    dense
                    onChange={onChangeName}
                    onKeyUp={onEnterName}
                    id="person-name-input"
                    name="personNameInput"
                    showError={hasRenameError}
                    options={knownPersons.map((person) => person.name)}
                    filtering={(value, options) =>
                      options.filter((name) => name.toLowerCase().includes(value.toLowerCase()))
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BoundingBoxes;
