import { useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { DefaultTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';

import { isAudioAsset, isImageAsset, isVideoAsset } from '../../businessRules/models/asset/utils';
import { detailsSelectors } from '../../state/modules/details';
import { isAssetCollection } from '../../state/modules/rest/collection/types';
import AnnotationsPanel from './Annotations';
import AssetLinks from './AssetLinks';
import AssetMetadata from './AssetMetadata';
import AssetStatus from './AssetStatus';
import AudioInfo from './AudioInfo';
import AudioPlayer from './AudioPlayer';
import CollectionFiles from './CollectionFiles';
import CollectionPlaceholder from './CollectionPlaceholder';
import CustomAssetMetadata from './CustomAssetMetadata';
import ImageInfo from './ImageInfo';
import ImageProxy from './ImageProxy';
import LinkedAssets from './LinkedAssets';
import Sequences from './Sequences';
import Title from './Title';
import Transcript from './Transcript';
import VideoPlayer from './VideoPlayer';

const useStyles = makeStyles((theme) => ({
  detailsTitle: {
    paddingRight: theme.spacing(2),
  },
  detailsLeft: {
    overflow: 'auto',
  },
  annotations: {
    paddingLeft: theme.spacing(2),
    overflow: 'auto',
    position: 'relative',
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  detailsRight: {
    overflow: 'auto',
    position: 'relative',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  media: {
    paddingBottom: theme.spacing(1),
  },
}));

export interface ResizeHandle {
  resize: (heightOffset: number) => void;
}

const DetailsSection = forwardRef<ResizeHandle>((_, ref) => {
  const classes = useStyles();

  const detailsRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback((heightOffset: number) => {
    if (detailsRef.current && titleRef.current) {
      const titleHeight = titleRef.current.getBoundingClientRect().height || 0;
      detailsRef.current.style.maxHeight = `calc(100vh - ${heightOffset}px - ${titleHeight}px)`;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    resize: onResize,
  }));

  const { sidebarType } = useSelector(detailsSelectors.getLayout);
  const asset = useSelector(detailsSelectors.getCurrentAsset);
  const embeddedAsset = useSelector(detailsSelectors.getEmbeddedAsset);
  const showTranscript = sidebarType === 'transcript';
  const showAnnotations = useSelector(detailsSelectors.showAnnotations); // && !showTranscript;
  const isMdDown = useMediaQuery((theme: DefaultTheme) => theme.breakpoints.down('md'));
  const hideDetailsRight = showAnnotations && isMdDown && isVideoAsset(asset);

  // Column calculations
  let columnWidthLeft;
  let columnWidthRight;
  let columnWidthAnnotation;
  if (showTranscript) {
    columnWidthLeft = 5.5;
    columnWidthAnnotation = 0;
    columnWidthRight = 6.5;
  } else if (showAnnotations && !hideDetailsRight) {
    columnWidthLeft = 5.5;
    columnWidthAnnotation = 3;
    columnWidthRight = 3.5;
  } else if (showAnnotations && hideDetailsRight) {
    columnWidthLeft = 7;
    columnWidthAnnotation = 5;
    columnWidthRight = 0;
  } else {
    columnWidthLeft = 7;
    columnWidthAnnotation = 0;
    columnWidthRight = 5;
  }

  if (!asset) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} ref={titleRef} className={classes.detailsTitle}>
        <Title />
        <Divider light style={{ marginBottom: '0.7em', marginTop: '0.2em' }} />
      </Grid>
      <Grid item container flexWrap={'nowrap'} ref={detailsRef} data-testid="details-columns">
        <Grid
          data-testid="details-left-column"
          item
          xs={columnWidthLeft}
          sx={{
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
          className={classes.detailsLeft}
        >
          <div id="media-player" className={classes.media}>
            {isVideoAsset(embeddedAsset) && <VideoPlayer asset={embeddedAsset} />}
            {isAudioAsset(embeddedAsset) && <AudioPlayer asset={embeddedAsset} />}
            {isImageAsset(embeddedAsset) && <ImageProxy asset={embeddedAsset} />}
            {isAssetCollection(embeddedAsset) && <CollectionPlaceholder asset={embeddedAsset} />}
          </div>
          <AssetStatus asset={asset} />
          <AssetMetadata asset={asset} />
          <CustomAssetMetadata asset={asset} />
          <AssetLinks />
        </Grid>
        {showAnnotations && isVideoAsset(asset) && (
          <Grid
            item
            xs={columnWidthAnnotation}
            className={classes.annotations}
            sx={{
              transition: (theme) =>
                theme.transitions.create('all', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            }}
          >
            <AnnotationsPanel />
          </Grid>
        )}
        {!hideDetailsRight && (
          <Grid
            data-testid="details-right-column"
            item
            xs={columnWidthRight}
            className={classes.detailsRight}
            sx={{
              transition: (theme) =>
                theme.transitions.create('all', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            }}
          >
            {isVideoAsset(asset) && (sidebarType === 'default' ? <Sequences /> : <Transcript />)}
            {isAssetCollection(asset) && <LinkedAssets />}
            {isAssetCollection(asset) && <CollectionFiles collection={asset} />}
            {isAudioAsset(asset) &&
              (sidebarType === 'default' ? (
                <AudioInfo />
              ) : (
                // TODO implement transcripts for audio assets <KH 2024-05-27 t:ML-3674>
                <div>{gettext('Transcripts are currently not supported for audio assets.')}</div>
              ))}
            {isImageAsset(asset) && <ImageInfo />}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default memo(DetailsSection);
