import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { ExportList } from 'medialoopster/icons';

import { favoritesSelectors } from '../../../../../state/modules/favorites';
import SiteExportChoice from './SiteExportChoice';

interface SiteExportChoicesProps {
  baseXMLFileName: string;
}

const SiteExportChoices: FC<SiteExportChoicesProps> = ({ baseXMLFileName }) => {
  const selectedFavoritesListXMLDownloadURL = useSelector(
    favoritesSelectors.getSelectedFavoritesListXMLDownloadURL,
  );
  const siteExportChoices = useSelector(favoritesSelectors.getFavoriteSiteExports, shallowEqual);
  return (
    <>
      {selectedFavoritesListXMLDownloadURL && siteExportChoices.length === 0 && (
        <MenuItem
          disabled
          tooltip={gettext('No export settings configured for this site')}
          icon={<ExportList fontSize="small" />}
          label={gettext('Export list')}
          onClick={() => {}}
        />
      )}

      {selectedFavoritesListXMLDownloadURL &&
        siteExportChoices.map((choice) => (
          <SiteExportChoice key={choice.id} choice={choice} baseXMLFileName={baseXMLFileName} />
        ))}
    </>
  );
};

export default SiteExportChoices;
