import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { isAudioAsset, isVideoAsset } from '../../businessRules/models/asset/utils';
import { detailsSelectors, detailsActions } from '../../state/modules/details';
import { searchSelectors } from '../../state/modules/search';
import { Asset } from '../../state/types/asset/unionTypes';
import Duration from '../../ui/components/Duration';
import InplaceTextEdit from '../../ui/components/InplaceEdits/InplaceTextEdit';
import { textWithHighlightedRegions } from '../../ui/services/highlighting';

const getHighlightedTitle = (
  asset: Asset | null,
  highlights: ReturnType<typeof searchSelectors.getCurrentAssetHighlight>,
): NonNullable<ReactNode> => {
  if (!asset) return '';
  if (isAudioAsset(asset) && asset.artist && asset.title)
    return (
      <>
        {textWithHighlightedRegions(asset.artist, highlights?.fields?.artist)}
        {' - '}
        {textWithHighlightedRegions(asset.title, highlights?.fields?.title)}
      </>
    );
  return textWithHighlightedRegions(asset.name, highlights?.fields?.name);
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 2, 0, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Title: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const asset = useSelector(detailsSelectors.getCurrentAsset);
  const canEdit = useSelector(detailsSelectors.isEditMode);
  const highlights = useSelector(searchSelectors.getCurrentAssetHighlight);

  const textComponents = getHighlightedTitle(asset, highlights);
  const isEditableTitle = canEdit && !(isAudioAsset(asset) && asset?.artist && asset?.title);
  const hasTimeCode = isVideoAsset(asset) || isAudioAsset(asset);

  const onSubmitEditMode = (value: string) => {
    if (asset && asset.name !== value) {
      dispatch(
        detailsActions.editAsset(getResourceTypeName(asset), getResourceURI(asset), {
          name: value,
        }),
      );
    }
  };

  return (
    <div className={classes.container}>
      <InplaceTextEdit
        id="editAssetTitle"
        minWidth="300px"
        maxLength={255}
        onSubmit={onSubmitEditMode}
        canEdit={isEditableTitle}
        textComponents={textComponents}
        editValue={asset?.name || ''}
        variant="h4"
      />
      {hasTimeCode && (
        <Typography variant="h4">
          {isAudioAsset(asset) && <Duration seconds={asset.duration} />}
          {isVideoAsset(asset) && <Duration frames={asset.duration} fps={asset.fps} />}
        </Typography>
      )}
    </div>
  );
};

export default Title;
