import _ from 'lodash';
import { useEffect } from 'react';
import {
  ControllerRenderProps,
  useController,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

export type UseWidgetContextOptions = Pick<
  UseControllerProps,
  'name' | 'rules' | 'defaultValue'
> & {
  readonly disabled: boolean;
};

export type WidgetContext = {
  readonly onChange: ControllerRenderProps['onChange'];
  readonly onBlur: ControllerRenderProps['onBlur'];
  readonly value: ControllerRenderProps['value'];
  readonly error: string;
};

const useWidgetContext = ({
  name,
  rules,
  defaultValue,
  disabled,
}: UseWidgetContextOptions): WidgetContext => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const error = (_.get(errors, name)?.message as string) || '';
  const {
    field: { onChange, onBlur, value },
  } = useController({
    control,
    name,
    rules: !disabled ? rules : undefined,
    defaultValue,
  });
  useEffect(() => {
    if (disabled) {
      clearErrors(name);
    }
  }, [disabled, clearErrors, name]);
  return { onChange, onBlur, value, error };
};

export default useWidgetContext;
