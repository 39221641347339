import { Paper, Grid, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Ref, forwardRef, useState, useCallback, memo, useEffect } from 'react';

import { gettext, pgettext } from '../../../Internationalization';
import { defaultRanges } from '../defaults';
import { DateRange } from '../types';
import { getCurrentDateRange, transferTimeOnTargetDateTime, formatDateTimeRange } from '../utils';
import DateTimeInput from './DateTimeInput';
import DefinedRanges from './DefinedRanges';
import Month from './Month';
import TimePicker from './TimePicker';

const useStyles = makeStyles(() => ({
  calenderContainer: {
    minWidth: '700px',
    maxWidth: '900px',
    minHeight: '510px',
  },
  forceTextTowardsCenter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageColumn: {
    marginTop: '40px',
    width: '280px',
  },
  definedRangesContainer: {
    flexGrow: 1,
  },
  footer: {
    paddingTop: 10,
  },
}));

export interface DateRangePickerProps {
  onChange: (dateRange: DateRange) => void;
  onSubmit: (dateRange: DateRange) => void;
  onReset: (dateRange: DateRange) => void;
  isExternalChange?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  transform?: string;
  rangeStart?: DateTime;
  rangeEnd?: DateTime;
  resetStart?: DateTime;
  resetEnd?: DateTime;
}

const DateRangePicker = forwardRef<HTMLDivElement, DateRangePickerProps>(
  (props: DateRangePickerProps, ref: Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const today = DateTime.local();

    const {
      onChange,
      onSubmit,
      onReset,
      isExternalChange,
      dateFormat = pgettext('Localized numeric date', 'MM/dd/yyyy'),
      timeFormat = 'HH:mm',
      transform,
      rangeStart,
      rangeEnd,
      resetStart,
      resetEnd,
    } = props;

    const defaultRange = (() => {
      // iffy
      if (rangeStart && rangeEnd)
        // use given ranges
        return { startDate: rangeStart, endDate: rangeEnd };
      if (resetStart && resetEnd) {
        // use resets
        return { startDate: resetStart, endDate: resetEnd };
      }
      // use default (today)
      return _.pick(
        defaultRanges.find((el) => el.label === gettext('Today')),
        ['startDate', 'endDate'],
      );
    })();

    const [dateRange, setDateRange] = useState<DateRange>(getCurrentDateRange(defaultRange));
    const [hourRange, setHourRange] = useState<DateRange>(getCurrentDateRange(defaultRange));
    const [hoverDay, setHoverDay] = useState<DateTime>();
    const [firstMonth, setFirstMonth] = useState<DateTime>(today);
    const [secondMonth, setSecondMonth] = useState<DateTime>(firstMonth.plus({ month: 1 }));

    const { startDate, endDate } = dateRange;

    const onSubmitDateRange = useCallback(() => {
      const { startDate: dateStart, endDate: dateEnd } = dateRange;
      const { startDate: dateTimeStart, endDate: dateTimeEnd } = hourRange;
      if (dateStart && dateEnd && dateTimeStart && dateTimeEnd) {
        const submitStart = transferTimeOnTargetDateTime(dateStart, dateTimeStart);
        const submitEnd = transferTimeOnTargetDateTime(dateEnd, dateTimeEnd);
        onSubmit({ startDate: submitStart, endDate: submitEnd });
      }
    }, [dateRange, hourRange, onSubmit]);

    const setFirstMonthValidated = useCallback(
      (date: DateTime) => {
        if (
          date < secondMonth &&
          !(date.hasSame(secondMonth, 'month') && date.hasSame(secondMonth, 'year'))
        ) {
          setFirstMonth(date);
        } else {
          setFirstMonth(date);
          setSecondMonth(date.startOf('month').plus({ month: 1 }));
        }
      },
      [secondMonth, setFirstMonth, setSecondMonth],
    );

    const setSecondMonthValidated = useCallback(
      (date: DateTime) => {
        if (date > firstMonth) {
          setSecondMonth(date);
        } else {
          setFirstMonth(date.minus({ month: 1 }));
          setSecondMonth(date);
        }
      },
      [setSecondMonth, firstMonth, setFirstMonth],
    );

    const setCalendarPages = useCallback(
      (dateTimeRange: Required<DateRange>) => {
        // based on firstMonth and secondMonth decide which pages need to be updated
        const { startDate: dateTimeStart, endDate: dateTimeEnd } = dateTimeRange;
        // Check if either date is already available on a calendar page.
        if (
          !(
            (dateTimeStart.hasSame(firstMonth, 'month') &&
              dateTimeStart.hasSame(firstMonth, 'year')) ||
            (dateTimeStart.hasSame(secondMonth, 'month') &&
              dateTimeStart.hasSame(secondMonth, 'year'))
          )
        ) {
          setFirstMonth(dateTimeStart);
          if (secondMonth < dateTimeStart) {
            setSecondMonth(dateTimeStart.plus({ month: 1 }));
          }
        }
        if (
          !(
            dateTimeEnd.hasSame(secondMonth, 'month') && dateTimeEnd.hasSame(secondMonth, 'year')
          ) &&
          !(
            dateTimeEnd.hasSame(dateTimeStart, 'month') &&
            dateTimeEnd.hasSame(dateTimeStart, 'year')
          )
        ) {
          setSecondMonth(dateTimeEnd);
        }
      },
      [firstMonth, secondMonth],
    );

    const setDateTimeRange = useCallback(
      (
        dateTimeRange: Required<DateRange>,
        mode: 'setStart' | 'setEnd' = 'setStart',
        fromInitial = false,
      ): Required<DateRange> => {
        const { startDate: newDateTimeStart, endDate: newDateTimeEnd } = dateTimeRange;
        let newRange = { startDate: newDateTimeStart, endDate: newDateTimeEnd };
        if (newDateTimeStart > newDateTimeEnd) {
          if (mode === 'setStart') {
            newRange = { startDate: newDateTimeStart, endDate: newDateTimeStart };
          } else {
            newRange = { startDate: newDateTimeEnd, endDate: newDateTimeEnd };
          }
        }
        setHourRange(newRange);
        setDateRange(newRange);
        if (!fromInitial) {
          onChange(newRange);
        }
        return newRange;
      },
      [setHourRange, setDateRange, onChange],
    );

    const setTimeRangeValidated = useCallback(
      (range: DateRange, mode: 'setStart' | 'setEnd' = 'setStart') => {
        let { startDate: newTimeStart, endDate: newTimeEnd } = range;
        const { startDate: prevDateStart, endDate: prevDateEnd } = dateRange;
        newTimeStart =
          prevDateStart && newTimeStart
            ? transferTimeOnTargetDateTime(prevDateStart, newTimeStart)
            : newTimeStart;
        newTimeEnd =
          prevDateEnd && newTimeEnd
            ? transferTimeOnTargetDateTime(prevDateEnd, newTimeEnd)
            : newTimeEnd;

        if (newTimeStart && newTimeEnd) {
          const nextRange = setDateTimeRange(
            { startDate: newTimeStart, endDate: newTimeEnd },
            mode,
          );
          setCalendarPages(nextRange);
        }
      },
      [setDateTimeRange, setCalendarPages, dateRange],
    );

    const onDayClick = useCallback(
      (day: DateTime) => {
        const { startDate: newTimeStart, endDate: newTimeEnd } = hourRange;
        let newDay = day;
        if (startDate && !endDate && !(day < startDate)) {
          newDay = transferTimeOnTargetDateTime(newDay, newTimeEnd || newDay);
          const newRange = {
            startDate,
            endDate: transferTimeOnTargetDateTime(newDay, newTimeEnd || newDay),
          };
          onChange(newRange);
          setDateRange(newRange);
        } else {
          newDay = transferTimeOnTargetDateTime(newDay, newTimeStart || newDay);
          setDateRange({ startDate: newDay, endDate: undefined });
        }
        setHoverDay(day);
      },
      [hourRange, setHoverDay, onChange, setDateRange, startDate, endDate],
    );

    const onDayHover = useCallback(
      (date: DateTime) => {
        if (startDate && !endDate) {
          if (!hoverDay || !date.hasSame(hoverDay, 'day')) {
            setHoverDay(date);
          }
        }
      },
      [startDate, endDate, setHoverDay, hoverDay],
    );

    const inHoverRange = useCallback(
      (day: DateTime): boolean =>
        !!(
          startDate &&
          !endDate &&
          hoverDay &&
          hoverDay > startDate &&
          day >= startDate &&
          day <= hoverDay
        ),
      [startDate, endDate, hoverDay],
    );

    const onCalendarReset = useCallback(() => {
      let resetDateRange: DateRange = { startDate: undefined, endDate: undefined };
      if (resetStart && resetEnd) {
        setCalendarPages(
          setDateTimeRange({ startDate: resetStart, endDate: resetEnd }, 'setStart', true),
        );
        resetDateRange = { startDate: resetStart, endDate: resetEnd };
      } else {
        const { startDate: defaultStart, endDate: defaultEnd } = getCurrentDateRange(
          _.pick(
            defaultRanges.find((el) => el.label === gettext('Today')),
            ['startDate', 'endDate'],
          ),
        );
        if (defaultStart && defaultEnd) {
          setCalendarPages(
            setDateTimeRange({ startDate: defaultStart, endDate: defaultEnd }, 'setStart', true),
          );
          resetDateRange = { startDate: defaultStart, endDate: defaultEnd };
        }
      }
      onReset(resetDateRange);
    }, [setCalendarPages, setDateTimeRange, resetStart, resetEnd, onReset]);

    useEffect(() => {
      // Logic for loading or reacting to external changes
      // Warning: Changes might introduce strong coupling in the system
      if (rangeStart && rangeEnd && isExternalChange) {
        const fromInitialStart = rangeStart;
        const fromInitialEnd = rangeEnd;
        if (fromInitialStart && fromInitialEnd) {
          setCalendarPages(
            setDateTimeRange(
              { startDate: fromInitialStart, endDate: fromInitialEnd },
              'setStart',
              true,
            ),
          );
        }
      }
    }, [rangeStart, rangeEnd, setCalendarPages, setDateTimeRange, isExternalChange]);

    return (
      <Paper ref={ref} className={classes.calenderContainer} style={{ transform }}>
        <Grid container item style={{ paddingTop: '10px' }}>
          <Grid
            item
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            className={classes.pageColumn}
          >
            <Grid item>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={10}>
                  <DateTimeInput
                    onTimeChange={(startingDate) => {
                      setCalendarPages(
                        setDateTimeRange({
                          startDate: startingDate,
                          endDate: endDate || startingDate,
                        }),
                      );
                    }}
                    hours={hourRange.startDate}
                    date={dateRange.startDate ? dateRange.startDate : firstMonth}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    ariaLabel={gettext('Start of date range')}
                  />
                  <TimePicker
                    date={hourRange.startDate}
                    onTimeChange={(startingDate) => {
                      setTimeRangeValidated({ startDate: startingDate, endDate });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Month
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onDayClick={onDayClick}
                onDayHover={onDayHover}
                inHoverRange={inHoverRange}
                value={firstMonth}
                setValue={setFirstMonthValidated}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            className={classes.pageColumn}
          >
            <Grid item>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={10}>
                  <DateTimeInput
                    onTimeChange={(endingDate) => {
                      setCalendarPages(
                        setDateTimeRange(
                          { startDate: startDate || endingDate, endDate: endingDate },
                          'setEnd',
                        ),
                      );
                    }}
                    hours={hourRange.endDate}
                    date={dateRange.endDate ? dateRange.endDate : secondMonth}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    ariaLabel={gettext('End of date range')}
                  />
                  <TimePicker
                    date={hourRange.endDate}
                    onTimeChange={(endingDate) => {
                      setTimeRangeValidated({ startDate, endDate: endingDate }, 'setEnd');
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Month
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onDayClick={onDayClick}
                onDayHover={onDayHover}
                inHoverRange={inHoverRange}
                value={secondMonth}
                setValue={setSecondMonthValidated}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.definedRangesContainer}>
            <DefinedRanges
              selectedRange={dateRange}
              ranges={defaultRanges}
              setRange={(range) => {
                setCalendarPages(setDateTimeRange(range));
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-around"
          alignItems="baseline"
          className={classes.footer}
        >
          <Grid item xs={7} className={classes.forceTextTowardsCenter}>
            <Typography color="textSecondary" align="center">
              {formatDateTimeRange(startDate, endDate, dateFormat, timeFormat)}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={5} spacing={1}>
            <Grid item>
              <Button
                onClick={onCalendarReset}
                aria-label={gettext('Reset calendar')}
                variant="contained"
                size="small"
                disableElevation
              >
                {gettext('Reset')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onSubmitDateRange}
                aria-label={gettext('Submit calendar')}
                variant="contained"
                size="small"
                color="primary"
                disableElevation
              >
                {gettext('Apply')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  },
);

export default memo(DateRangePicker);
