export default (
  name: string,
  minLineLength: number,
  maxLineLength: number,
): ReadonlyArray<string> => {
  // Check if the name should wrap at all.
  if (name.length < minLineLength) {
    return [name];
  }
  // Try to fit as much text into two lines as possible, by using half of the name's
  // length as the maximum length of one line.
  const halfNameLength = name.length / 2;
  const line1: string[] = [];
  const line2: string[] = [];
  let charIndex = 0;
  name.split(' ').forEach((word) => {
    charIndex += word.length;
    if (
      charIndex < halfNameLength ||
      // Decide which line should get the word in the middle by checking which line
      // contains the longer part of the word.
      charIndex - halfNameLength <= word.length / 2
    ) {
      line1.push(word);
    } else {
      line2.push(word);
    }
    charIndex += 1;
  });
  return [line1, line2]
    .map((line) => line.join(' '))
    .map(
      // Shorten lines which are longer than the absolute maximum line length.
      (line) => (line.length <= maxLineLength ? line : `${line.slice(0, maxLineLength - 1)}…`),
    );
};
