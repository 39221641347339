import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { IconCell } from 'medialoopster/components';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { detailsActions, detailsSelectors } from '../../state/modules/details';
import { ProjectAsset } from '../../state/modules/project/types';
import { VideoAsset } from '../../state/modules/video/types';
import AssetIcon from '../../ui/components/AssetIcon';

const useStyles = makeStyles(() => ({
  text: {
    paddingLeft: 0,
  },
}));

const AssetLinks: FC = () => {
  const dispatch = useDispatch();
  const projectsCollection = useSelector(detailsSelectors.getCurrentAssetProjectsCollection);
  const successorsCollection = useSelector(detailsSelectors.getCurrentAssetSuccessorsCollection);
  const classes = useStyles();
  const renderAssetRow = useCallback(
    (asset: VideoAsset) => (
      <TableRow key={getResourceURI(asset)}>
        <IconCell>
          <AssetIcon typeName={getResourceTypeName(asset)} size="small" />
        </IconCell>
        <TableCell className={classes.text}>
          <Link
            component="button"
            onClick={() =>
              dispatch(
                detailsActions.setCurrentAsset(getResourceTypeName(asset), getResourceURI(asset)),
              )
            }
          >
            {asset.name}
          </Link>
        </TableCell>
      </TableRow>
    ),
    [dispatch, classes.text],
  );
  // TODO: use v3 collection links (these do not exist yet <PB 2024-04-08 t:ML-2034>)
  const renderProjectRow = useCallback(
    (asset: ProjectAsset) => (
      <TableRow key={getResourceURI(asset)}>
        <IconCell>
          <AssetIcon typeName={'collection'} size="small" />
        </IconCell>
        <TableCell className={classes.text}>
          <Link
            component="button"
            onClick={() =>
              // TODO: Remove hack to load collection of asset via project relation.<PB 2024-04-08 t:ML-2034>
              dispatch(
                detailsActions.setCurrentAsset(
                  'collection',
                  getResourceURI(asset).replace(
                    `/projectassets/${asset.id}`,
                    `/collections/${asset.id}`,
                  ),
                ),
              )
            }
          >
            {asset.name}
          </Link>
        </TableCell>
      </TableRow>
    ),
    [dispatch, classes.text],
  );
  if (projectsCollection.items.length === 0 && successorsCollection.items.length === 0) {
    return null;
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{ paddingLeft: 0 }}>
            <Typography variant="body2">
              <strong>{gettext('Asset is used in')}</strong>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projectsCollection.items.map(renderProjectRow)}
        {successorsCollection.items.map(renderAssetRow)}
      </TableBody>
    </Table>
  );
};
export default AssetLinks;
