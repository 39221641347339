import { Action } from 'redux';
import { ofType } from 'redux-observable';
import { Observable, timer, MonoTypeOperatorFunction, combineLatest, EMPTY } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeWhile, last, startWith } from 'rxjs/operators';

import { getLink } from 'medialoopster/rest';

import { getCurrentAsset } from './modules/details/selectors';
import { RefreshCurrentAsset, REFRESH_CURRENT_ASSET } from './modules/details/types';
import { RootState } from './types/rootState';

export const onCurrentAssetLinkHrefChange = <A>(
  action$: Observable<Action>,
  state$: Observable<RootState>,
  rel: string,
  createObservable: (href: string) => Observable<A>,
): Observable<A> =>
  combineLatest([
    state$.pipe(
      map(getCurrentAsset),
      map((currentAsset) => (currentAsset ? getLink(currentAsset, rel) : null)),
      map((link) => (link?.methods?.GET?.code === 'ok' ? link.href : null)),
      distinctUntilChanged(),
    ),
    action$.pipe(
      ofType<Action, RefreshCurrentAsset['type'], RefreshCurrentAsset>(REFRESH_CURRENT_ASSET),
      startWith(undefined),
    ),
  ]).pipe(
    switchMap(([href]) => {
      // NOTE: Need to check !href here, not via `filter` operator to prevent an outdated href
      // from being accessed when the current asset has switched from one with a given link and
      // permission to one without.
      if (!href) {
        return EMPTY;
      }
      return createObservable(href);
    }),
  );

export const pollWhile = <T>(
  isPollingActive: (res: T) => boolean,
  emitOnlyLast = false,
  pollInterval = 1000,
  rxTest = undefined,
): MonoTypeOperatorFunction<T> => {
  return (source$) => {
    const poll$ = timer(0, pollInterval, rxTest).pipe(
      switchMap(() => source$),
      takeWhile(isPollingActive, true),
    );
    return emitOnlyLast ? poll$.pipe(last()) : poll$;
  };
};
export default {};
