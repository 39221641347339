import makeStyles from '@mui/styles/makeStyles';
import { FC, memo, ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-0.5),
    marginBottom: theme.spacing(-0.5),
  },
}));
interface StatusBarProps {
  children: ReactNode | ReactNode[];
}
const StatusBar: FC<StatusBarProps> = ({ children }: StatusBarProps) => {
  const classes = useStyles();
  return <div className={classes.bar}>{children}</div>;
};

export default memo(StatusBar);
