import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode, FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { DropdownMenuButton } from 'medialoopster/components';
import { EditList } from 'medialoopster/icons';
import { getResourceURI } from 'medialoopster/rest';

import { AudioAsset } from '../../state/modules/audio/types';
import { detailsSelectors } from '../../state/modules/details';
import { ImageAsset } from '../../state/modules/image/types';
import { searchSelectors, searchTypes } from '../../state/modules/search';
import { textWithHighlightedItems } from '../../ui/services/highlighting';
import Field from './AssetFields/Field';
import KeywordCategories from './KeywordCategories';

const useStyles = makeStyles((theme) => ({
  entry: {
    whiteSpace: 'pre-line',
  },
  paper: {
    marginBottom: theme.spacing(1), // space for elevation shadow
  },
  title: {
    display: 'flex',
  },
  header: {
    backgroundColor: theme.palette.grey['100'],
    padding: '0.6rem 0.9rem',
  },
  body: {
    padding: '0.6rem 0.9rem',
  },
  menu: {
    marginLeft: 'auto',
  },
}));

const getHighlightsBySearchKey = (field: string, highlights?: searchTypes.Highlight) => {
  if (highlights?.fields?.[field]) {
    return highlights?.fields?.[field];
  }
  return [];
};

type AssetInfoProps = {
  readonly title: string;
  readonly asset: AudioAsset | ImageAsset;
  readonly assetInfoWithoutKw: ReactNode;
  readonly keywordsByCategory: ReadonlyArray<{
    name: string;
    keywords: ReadonlyArray<string>;
    availableKeywords: ReadonlyArray<string>;
  }>;
};

const AssetInfo: FC<AssetInfoProps> = ({
  title,
  asset,
  assetInfoWithoutKw,
  keywordsByCategory,
}: AssetInfoProps) => {
  const classes = useStyles();
  const assetHighlights = useSelector(searchSelectors.getCurrentAssetHighlight);
  const isEditMode = useSelector(detailsSelectors.isEditMode);
  const canEditKeywords = useSelector(detailsSelectors.canEditCurrentAssetKeywords);
  const href = getResourceURI(asset);
  const [isKeywordsEditMode, setKeywordsEditMode] = useState(false);
  useEffect(() => {
    if (!isEditMode) {
      setKeywordsEditMode(false);
    }
  }, [isEditMode, setKeywordsEditMode]);
  const options = useMemo(
    () => [
      {
        key: 'edit',
        label: gettext('Edit'),
        onSelect: () => {
          setKeywordsEditMode(!isKeywordsEditMode);
        },
        selected: isKeywordsEditMode,
        icon: <EditList fontSize="small" />,
        visible: canEditKeywords,
      },
    ],
    [setKeywordsEditMode, isKeywordsEditMode, canEditKeywords],
  );
  return (
    <Paper key={href} className={classes.paper}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography>{title}</Typography>
          {isEditMode && <DropdownMenuButton className={classes.menu} options={options} />}
        </div>
      </div>
      <Divider light />
      <div className={classes.body}>
        <div className={classes.entry}>{assetInfoWithoutKw}</div>
        <br />
        {keywordsByCategory
          .filter((category) => category.keywords.length > 0)
          .map((category) => (
            <Field
              label={category.name}
              variant="caption"
              labelAndDisplayOnSameLine
              visible={category.keywords.length !== 0}
              testId={`${href}-keywords-${category.name}`}
              key={`${href}-keywords-${category.name}`}
            >
              {textWithHighlightedItems(
                category.keywords,
                getHighlightsBySearchKey('keywords', assetHighlights),
              )}
            </Field>
          ))}
      </div>
      {isEditMode && isKeywordsEditMode && (
        <div className={classes.header}>
          <KeywordCategories keywordsByCategory={keywordsByCategory} obj={asset} />
        </div>
      )}
    </Paper>
  );
};

export default AssetInfo;
