import { createSelector } from 'reselect';

import { FieldObjectV2, getLinkHref, getLinks, getNumberChoices } from 'medialoopster/rest';

import {
  getAssetProductionId,
  getEmbeddedSharedWithProductions,
  isAudioAsset,
  isImageAsset,
  isVideoAsset,
} from '../../../businessRules/models/asset/utils';
import { idFromHref } from '../../../businessRules/services/idFromHref';
import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { productionsSelectors } from '../productions';
import { isAssetCollection } from '../rest/collection/types';
import {
  ChoiceState,
  ShareAssetsErrors,
  ShareAssetsModalProductionChoiceState,
  ShareAssetsModalValuesState,
} from './types';

export const canShareProductionAsset = (state: RootState): boolean =>
  state.assetSharing.permissions.canShareProductionAsset;
export const getModalValues = (state: RootState): ShareAssetsModalValuesState =>
  state.assetSharing.modal.values;

const assetIsAvailableAtProduction = (asset: Asset, productionId: number): boolean => {
  if (isAssetCollection(asset)) {
    const assetProductionId = idFromHref(getLinkHref(asset, 'production') || null);
    // TODO: Remove `idFromHref` call when using API V3. <DT 2024-06-24 t:ML-3641>
    const sharedWithProductionIds = getLinks(asset, 'shared_with_productions').map(({ href }) =>
      idFromHref(href),
    );
    return productionId === assetProductionId || sharedWithProductionIds.includes(productionId);
  }
  if (isVideoAsset(asset) || isImageAsset(asset) || isAudioAsset(asset)) {
    return (
      [
        asset.production,
        ...getEmbeddedSharedWithProductions(asset).map(({ production }) => production),
      ].indexOf(productionId) >= 0
    );
  }
  return false;
};

export const getProductionChoices = createSelector(
  getModalValues,
  (state: RootState) => state.assetSharing.options?.actions?.POST?.productions?.choices,
  (
    { assets }: ShareAssetsModalValuesState,
    choices: FieldObjectV2['choices'],
  ): ReadonlyArray<ShareAssetsModalProductionChoiceState> => {
    const assetProductionIds = new Set(assets.map(getAssetProductionId));
    const productionChoices = getNumberChoices(choices || []);
    const getProductionChoiceState = (productionId: number): ChoiceState => {
      const availableAssetCount = assets.filter((asset) =>
        assetIsAvailableAtProduction(asset, productionId),
      ).length;
      if (availableAssetCount === 0) {
        // No assets are available at the target production.
        return 'none';
      }
      if (availableAssetCount === assets.length) {
        // All assets are available at the target production.
        return 'all';
      }
      return 'some';
    };
    return productionChoices
      .filter(
        (productionChoice) =>
          assetProductionIds.size > 1 || !assetProductionIds.has(productionChoice.value),
      )
      .map((productionChoice) => ({
        label: productionChoice.display_name,
        value: productionChoice.value,
        state: getProductionChoiceState(productionChoice.value),
      }));
  },
);

export const isModalVisible = (state: RootState): boolean => state.assetSharing.modal.visible;

export const getModalErrors = (state: RootState): ShareAssetsErrors =>
  state.assetSharing.modal.errors;

export const hasNoTargetProduction = createSelector(
  productionsSelectors.getProductions,
  (productions) => (assets: ReadonlyArray<Asset>) => {
    const productionsIds = productions.map((prod) => prod.id);
    const noTargetProductions = new Set(
      assets.flatMap((asset) => {
        return productionsIds.filter((prod) => prod !== getAssetProductionId(asset));
      }),
    );
    return noTargetProductions.size === 0;
  },
);
