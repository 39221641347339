import Paper from '@mui/material/Paper';
import { alpha, darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo, MouseEvent, ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  assetContainer: {
    cursor: 'pointer',
    padding: '4px 15px',
    minHeight: '70px',
    backgroundColor: theme.palette.background.secondary,
    '&:hover': {
      backgroundColor: darken(
        theme.palette.background.secondary,
        theme.palette.action.hoverOpacity,
      ),
    },
    transition: 'border-radius 300ms',
  },
  assetSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
  },
  assetContainerWide: {
    display: 'flex',
  },
  assetContainerOpen: {
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  },
  assetInSelection: {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
      ),
    },
  },
}));

interface AssetHeaderProps {
  isCurrent: boolean;
  inSelection: boolean;
  isWide: boolean;
  isOpen?: boolean;
  children?: ReactNode;
  onAssetClick: (event: MouseEvent<Element>) => void;
}

const AssetHeader: FC<AssetHeaderProps> = ({
  inSelection,
  isCurrent,
  isOpen,
  isWide,
  children,
  onAssetClick,
}: AssetHeaderProps) => {
  const classes = useStyles();

  return (
    <Paper
      onClick={onAssetClick}
      elevation={0}
      className={clsx(
        classes.assetContainer,
        { [classes.assetSelected]: isCurrent },
        { [classes.assetContainerOpen]: isOpen },
        { [classes.assetContainerWide]: isWide },
        { [classes.assetInSelection]: inSelection && !isCurrent },
      )}
      data-testid="header"
    >
      {children}
    </Paper>
  );
};

export default memo(AssetHeader);
