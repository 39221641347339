import { Box, InputAdornment, Typography } from '@mui/material';
import { FC, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { IconButton, Input } from 'medialoopster/components';
import { Check, ClearField } from 'medialoopster/icons';

import { detailsActions } from '../../../../../../state/modules/details';

interface AnnotationTextProps {
  editText: boolean;
  text: string;
  annotationURI: string;
  toggleEditText: () => void;
}

const AnnotationText: FC<AnnotationTextProps> = ({
  annotationURI,
  editText,
  text,
  toggleEditText,
}: AnnotationTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [editValue, setEditValue] = useState(text);
  const dispatch = useDispatch();

  const onEditText = useCallback(() => {
    dispatch(
      detailsActions.editAnnotation(annotationURI, {
        text: editValue,
      }),
    );
    toggleEditText();
  }, [editValue, annotationURI, toggleEditText, dispatch]);

  const onInputChange = useCallback(
    (val) => {
      setEditValue(val);
    },
    [setEditValue],
  );

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      // Submit changes on enter
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        onEditText();
      }
      // Close element on escape
      if (event.key === 'Escape') {
        toggleEditText();
      }
      // Shift+Enter inserts a newline as per the default behaviour of the Input
    },
    [onEditText, toggleEditText],
  );

  useEffect(() => {
    if (editText) {
      // When enabling edit mode, it is convenient to have cursor at the end of text
      if (inputRef?.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length,
        );
      }
    } else {
      setEditValue(text);
    }
  }, [editText, setEditValue, text]);
  return (
    <Box sx={{ pt: editText ? 2 : 1, pb: editText ? 2 : 1 }}>
      {editText ? (
        <Input
          dense
          multiline
          value={editValue}
          inputRef={inputRef}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="success" dense title={gettext('Submit')} onClick={onEditText}>
                  <Check fontSize="inherit" />
                </IconButton>
                <IconButton color="error" dense onClick={toggleEditText} title={gettext('Cancel')}>
                  <ClearField fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Typography style={{ overflowWrap: 'break-word' }} variant="caption">
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default AnnotationText;
