import { Action } from 'redux';
import { combineEpics } from 'redux-observable';

import {
  ResourceLoadedAction,
  ResourceOptionsLoadedAction,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';

import { collectionEpics } from './collection';
import { siteExportConfigsEpics } from './siteexportconfigs';
import { transcriptEpics } from './transcript';
import { RESTRootState } from './types';

export default combineEpics<
  Action,
  UnauthorizedRequestAction | ResourceLoadedAction | ResourceOptionsLoadedAction,
  RESTRootState
>(siteExportConfigsEpics, collectionEpics, transcriptEpics);
