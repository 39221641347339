import { Property } from 'csstype';
import { FC, memo, ReactNode } from 'react';

import CheckboxTableContextProvider from './CheckboxTableContextProvider';
import TableContainer from './TableContainer';
import PaginationContextProvider from './pagination/PaginationContextProvider';
import { HeadCell, RowData } from './types';

interface TableProps {
  readonly rowData: ReadonlyArray<RowData>;
  readonly getRowIdentifier: (dataRow: RowData) => string;
  readonly headCells: ReadonlyArray<HeadCell>;
  readonly loading?: boolean;
  readonly renderRow: (dataRow: RowData, rowIdentifier: string) => ReactNode;
  readonly PaginationElement: ReactNode;
  // styling properties
  readonly dense?: boolean;
  readonly height?: Property.Height;
  // checkbox table specific properties
  readonly checkboxes?: boolean;
  readonly defaultSelection?: ReadonlyArray<string>;
  readonly onSelectChange?: (selected: ReadonlyArray<string>) => void;
}

const Table: FC<TableProps> = ({
  rowData,
  getRowIdentifier,
  headCells,
  loading = false,
  renderRow,
  PaginationElement,
  dense = false,
  height = '100%',
  checkboxes = false,
  defaultSelection = [],
  onSelectChange = () => {},
}: TableProps) => {
  return (
    <PaginationContextProvider>
      <CheckboxTableContextProvider
        rowData={rowData}
        getRowIdentifier={getRowIdentifier}
        onSelectChange={onSelectChange}
        defaultSelection={defaultSelection}
      >
        <TableContainer
          getRowIdentifier={getRowIdentifier}
          headCells={headCells}
          loading={loading}
          dense={dense}
          height={height}
          checkboxes={checkboxes}
          renderRow={renderRow}
        />
        {PaginationElement}
      </CheckboxTableContextProvider>
    </PaginationContextProvider>
  );
};

export * from './rows';
export * from './pagination';
export * from './cells';
export * as tableTypes from './types';

export default memo(Table);
