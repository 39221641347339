/* eslint no-underscore-dangle: 0 */
import _ from 'lodash';
import { Action, Reducer } from 'redux';
import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { EMPTY, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError, mergeMap } from 'rxjs/operators';

import stringifySearchDict from '../stringifySearchDict';
import { ReadonlyRecord } from '../types';
import {
  normalizeURI,
  isResourceMap,
  createResourceMap,
  getLink,
  getResourceOptions,
} from './resources';
import {
  Resource,
  ResourceMap,
  ResourceLoadedAction,
  FETCH_ROOT_RESOURCE,
  RECEIVE_ROOT_RESOURCE,
  FetchRootResource,
  Link,
  ResourceRemovedAction,
  ResourceOptionsLoadedAction,
  ResourceOptions,
  ReceiveRootResource,
  RESTError,
  RootResource,
  PageResource,
  LinkedItemsPageResource,
  EmbeddedItemsPageResource,
  BaseResource,
  Choice,
  BaseResourceOptions,
  UnauthorizedRequestAction,
  unauthorizedRequestAction,
  HttpHeaders,
  CollectionType,
  ResourceType,
  ResourceModuleReducerMap,
  EmbeddedItemsResourceModuleReducerMap,
} from './types';

export const createResourcesLoadedActionType = <T extends string>(
  resourceTypeName: T,
): `REST/RESOURCES_LOADED(${T})` => `REST/RESOURCES_LOADED(${resourceTypeName})`;

export const createResourcesRemovedActionType = <T extends string>(
  resourceTypeName: T,
): `REST/RESOURCES_REMOVED(${T})` => `REST/RESOURCES_REMOVED(${resourceTypeName})`;

export const createModifyResourceActionType = <T extends string>(
  resourceTypeName: T,
): `REST/MODIFY_RESOURCE(${T})` => `REST/MODIFY_RESOURCE(${resourceTypeName})`;

export const createResourceOptionsLoadedActionType = <T extends string>(
  resourceTypeName: T,
): `REST/RESOURCE_OPTIONS_LOADED(${T})` => `REST/RESOURCE_OPTIONS_LOADED(${resourceTypeName})`;

/**
 * Create a RESOURCES_LOADED action.
 *
 * @param resourceTypeName - The type name of the resources' type.
 * @param resources - The resources. (Must all be of the type given in ``resourceTypeName``.)
 *
 * @returns The RESOURCES_LOADED action.
 */
export const createResourcesLoadedAction = <R extends Resource<string>>(
  resourceTypeName: ResourceType<R>,
  resources: ReadonlyArray<R>,
  resourceHeaders: ReadonlyRecord<string, ReadonlyRecord<string, string>> = {},
): ResourceLoadedAction<R> => {
  return {
    type: createResourcesLoadedActionType(resourceTypeName),
    payload: createResourceMap(resourceTypeName, resources, undefined, resourceHeaders),
  };
};

/**
 * Create a RESOURCES_REMOVED action.
 *
 * @param resourceTypeName - The type name of the resources' type.
 * @param resourceURIs - The URIs of the resources to remove.
 *
 * @returns The RESOURCES_REMOVED action.
 */
export const createResourcesRemovedAction = <T extends string>(
  resourceTypeName: T,
  resourceURIs: ReadonlyArray<string>,
): ResourceRemovedAction<T> => {
  return {
    type: createResourcesRemovedActionType(resourceTypeName),
    payload: {
      resourceTypeName,
      resourceURIs: resourceURIs.map(normalizeURI),
    },
  };
};

/**
 * Create a RESOURCES_OPTIONS_LOADED action.
 *
 * @param resourceTypeName - The type name of the resources' type.
 * @param uri - The resources' URI.
 * @param options - The resources' options.
 *
 * @returns The RESOURCES_OPTIONS_LOADED action.
 */
export const createResourceOptionsLoadedAction = <T extends string, O extends ResourceOptions>(
  resourceTypeName: T,
  uri: string,
  options: O,
): ResourceOptionsLoadedAction<T> => {
  return {
    type: createResourceOptionsLoadedActionType(resourceTypeName),
    payload: { uri, options },
  };
};

/**
 * Create a reducer for storing uniformly typed resources.
 *
 * @param resourceTypeName - The URI of the type handled by the reducer.
 *
 * @returns The reducer for storing resources.
 */
export const createResourcesReducer = <
  T extends string,
  R extends Resource<T> = Resource<T>,
  O = ResourceOptions,
>(
  resourceTypeName: T,
): Reducer<
  ResourceMap<R, O>,
  | ResourceLoadedAction<R>
  | ResourceRemovedAction<ResourceType<R>>
  | ResourceOptionsLoadedAction<ResourceType<R>, O>
> => {
  const RESOURCES_LOADED = createResourcesLoadedActionType(resourceTypeName);
  const RESOURCES_REMOVED = createResourcesRemovedActionType(resourceTypeName);
  const RESOURCE_OPTIONS_LOADED = createResourceOptionsLoadedActionType(resourceTypeName);
  const isResourceLoadedAction = (action: Action): action is ResourceLoadedAction<R> =>
    action.type === RESOURCES_LOADED;
  const isResourceRemovedAction = (
    action: Action,
  ): action is ResourceRemovedAction<ResourceType<R>> => action.type === RESOURCES_REMOVED;
  const isResourceOptionsLoadedAction = (
    action: Action,
  ): action is ResourceOptionsLoadedAction<ResourceType<R>, O> =>
    action.type === RESOURCE_OPTIONS_LOADED;
  return (state = { resourceTypeName, resources: {}, options: {}, headers: {} }, action) => {
    if (isResourceLoadedAction(action)) {
      // Filter out unchanged resources, to prevent triggering unnecessary rerendering.
      const newResources = Object.fromEntries(
        Object.entries(action.payload.resources).filter(
          ([href, resource]) => !_.isEqual(resource, state.resources[href]),
        ),
      );
      return {
        ...state,
        resources: {
          ...state.resources,
          ...newResources,
        },
        headers: {
          ...state.headers,
          ...action.payload.headers,
        },
      };
    }
    if (isResourceRemovedAction(action)) {
      return {
        ...state,
        resources: _.omit(state.resources, action.payload.resourceURIs),
        options: _.omit(state.options, action.payload.resourceURIs),
        headers: _.omit(state.headers, action.payload.resourceURIs),
      };
    }
    if (isResourceOptionsLoadedAction(action)) {
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.uri]: action.payload.options,
        },
      };
    }
    return state;
  };
};

export const getHandledResourceTypeNames = (state: unknown): string[] => {
  if (isResourceMap(state)) {
    return [state.resourceTypeName];
  }
  if (typeof state === 'object' && state !== null) {
    return Object.values(state).flatMap(getHandledResourceTypeNames);
  }
  return [];
};

/**
 * Create a reducer for storing a root resource link.
 *
 * @param relationTypeName - The relation type name of the link.
 *
 * @returns The reducer for storing the root link.
 */
export const createRootLinkReducer =
  (relationTypeName: string) =>
  (state: Link | null = null, { type, payload }: ReceiveRootResource): Link | null => {
    switch (type) {
      case RECEIVE_ROOT_RESOURCE:
        return getLink(payload.root, relationTypeName) || null;
      default:
        return state;
    }
  };

/**
 * Fetch the root resource.
 */
export const fetchRootResource = (url: string, version?: string): FetchRootResource => ({
  type: FETCH_ROOT_RESOURCE,
  payload: { url, version },
});

/**
 * Receive the root resource.
 *
 * @param root - The root resource.
 */
export const receiveRootResource = (root: RootResource): ReceiveRootResource => ({
  type: RECEIVE_ROOT_RESOURCE,
  payload: { root },
});

/**
 * Epic for receiving the root resource.
 */
export const fetchRootResourceEpic = (
  action$: Observable<FetchRootResource>,
  _state$: unknown,
  { getDefaultHeaders }: { getDefaultHeaders: () => HttpHeaders },
): Observable<ReceiveRootResource | UnauthorizedRequestAction> =>
  action$.pipe(
    ofType(FETCH_ROOT_RESOURCE),
    mergeMap(({ payload }) =>
      ajax
        .get<RootResource>(payload.url, {
          ...getDefaultHeaders(),
          ...(payload.version
            ? { Accept: `application/hal+json; version=${payload.version}` }
            : {}),
        })
        .pipe(
          map(({ response }) => receiveRootResource(response)),
          catchError((err) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            return EMPTY;
          }),
        ),
    ),
  );

export const getErrorAlertMessage = (err: RESTError, defaultMessage: string): string => {
  const errors = err?.response?.errors;
  return errors && errors.length > 0 ? errors[0].detail : defaultMessage;
};

export const createResourceModuleReducerMap = <
  R extends Resource<string>,
  O = ResourceOptions,
  P extends PageResource<CollectionType<ResourceType<R>>> = LinkedItemsPageResource<
    CollectionType<ResourceType<R>>
  >,
>(
  typeName: ResourceType<R>,
  relationType: string,
): ResourceModuleReducerMap<R, O, P> => ({
  collectionLink: createRootLinkReducer(relationType),
  pages: createResourcesReducer(`${typeName}-collection`),
  objects: createResourcesReducer(typeName),
});

export const createEmbeddedItemsResourceModuleReducerMap = <
  R extends BaseResource,
  T extends string,
  P extends PageResource<CollectionType<T>> = EmbeddedItemsPageResource<R, CollectionType<T>>,
>(
  typeName: T,
  relationType: string,
): EmbeddedItemsResourceModuleReducerMap<R, CollectionType<T>, P> => ({
  collectionLink: createRootLinkReducer(relationType),
  pages: createResourcesReducer(`${typeName}-collection`),
});

export const createFieldChoicesMappingSelector = <
  S,
  O extends BaseResourceOptions<unknown, unknown>,
>(
  getCollectionURI: (state: S) => string | null,
  getCollectionResourceMap: (state: S) => ResourceMap<Resource<string>, O>,
  getField: (
    actions: O['actions'],
  ) => { readonly choices?: ReadonlyArray<Choice<string | number>> } | null | undefined,
): ((state: S) => ReadonlyMap<number | string, string>) =>
  createSelector(getCollectionURI, getCollectionResourceMap, (collectionURI, resourceMap) => {
    if (!collectionURI) {
      return new Map();
    }
    const options = getResourceOptions(resourceMap, collectionURI) || null;
    if (!options) {
      return new Map();
    }
    const field = getField(options.actions);
    if (!field || !field.choices) {
      return new Map();
    }
    return new Map(field.choices.map(({ value, display_name }) => [value, display_name]));
  });

export const createFilteredCollectionURISelector = <S, F>(
  getCollectionURI: (state: S) => string | null,
  getCurrentPage: (state: S) => number,
  getItemsPerPage: (state: S) => number,
  getFilters: (state: S) => F,
  getOrdering: (state: S) => ReadonlyArray<string> = () => [],
): ((state: S) => string | null) =>
  createSelector(
    getCollectionURI,
    getCurrentPage,
    getItemsPerPage,
    getFilters,
    getOrdering,
    (collectionURI, currentPage, itemsPerPage, filters, ordering) => {
      if (!collectionURI) {
        return null;
      }
      const offset = currentPage * itemsPerPage;
      const query = stringifySearchDict(
        {
          offset,
          limit: itemsPerPage,
          ...filters,
          ...(ordering.length > 0 ? { ordering: ordering.join(',') } : {}),
        },
        { sort: (a, b) => a.localeCompare(b) },
      );
      return `${collectionURI}?${query}`;
    },
  );
