import { FC, useMemo } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { MultipleChoiceSelectWidget } from 'medialoopster/components';

import { AddableAsset } from '../../../state/modules/addAssetsToCollection/types';

interface Props {
  readonly assets: ReadonlyArray<AddableAsset>;
}

const AssetSelectionWidget: FC<Props> = ({ assets }: Props) => {
  const validationRuleSelectedAssets = useMemo(
    () => ({
      validate: (val: ReadonlyArray<string>) => {
        return val.length === 0 ? gettext('Please select assets to add.') : true;
      },
    }),
    [],
  );
  const defaultVals = useMemo(() => assets.map(({ value }) => value), [assets]);
  const optionsDisp = useMemo(
    () => assets.map((el) => ({ display: el.display, value: el.value })),
    [assets],
  );
  return (
    <MultipleChoiceSelectWidget
      name="selectedAssets"
      label={gettext('Assets to Link')}
      defaultValue={defaultVals}
      options={optionsDisp}
      rules={validationRuleSelectedAssets}
    />
  );
};

export default AssetSelectionWidget;
