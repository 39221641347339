import { combineReducers } from 'redux';

import { AssetChoice, CollectionChoice } from '../operations/types';
import {
  OpenBulkTransferModal,
  CloseBulkTransferModal,
  TransferErrorsState,
  CheckTransferAssets,
  OPEN_BULK_TRANSFER_MODAL,
  CLOSE_BULK_TRANSFER_MODAL,
  CHECK_TRANSFER_ASSETS,
  TransferStarted,
  TRANSFER_STARTED,
  CHECK_TRANSFER_COLLECTION,
  CheckTransferCollection,
  SetTransferErrors,
  SET_TRANSFER_ERRORS,
} from './types';

/**
 * Reducer for the bulk transfer modal's visibility
 */
export const bulkTransferModalVisibleReducer = (
  state = false,
  { type }: OpenBulkTransferModal | CloseBulkTransferModal,
): boolean => {
  switch (type) {
    case OPEN_BULK_TRANSFER_MODAL:
      return true;
    case CLOSE_BULK_TRANSFER_MODAL:
      return false;
    default:
      return state;
  }
};

/**
 * Reducer for the asset errors gathered via transfer-assets pre-check.
 *
 * (See also ``checkTransferAssetsEpic``.)
 */
export const transferErrorsReducer = (
  state: TransferErrorsState = {},
  action: SetTransferErrors,
): TransferErrorsState => {
  switch (action.type) {
    case SET_TRANSFER_ERRORS:
      return {
        ...state,
        [action.payload.actionURL]: {
          assetErrors: action.payload.assetErrors,
          collectionError: action.payload.collectionError,
          otherError: action.payload.otherError,
        },
      };
    default:
      return state;
  }
};

/**
 * Reducer for the transfer-assets action URL of the selected device.
 *
 * This is the action URL used when pre-checking and submitting the bulk modal form.
 * (See also ``checkTransferAssetsEpic`` and ``transferAssetsEpic``.)
 */
export const selectedTransferActionURLReducer = (
  state = '',
  action: CheckTransferAssets | CloseBulkTransferModal | CheckTransferCollection,
): string => {
  switch (action.type) {
    case CHECK_TRANSFER_ASSETS:
      return action.payload.actionURL;
    case CHECK_TRANSFER_COLLECTION:
      return action.payload.actionURL;
    case CLOSE_BULK_TRANSFER_MODAL:
      return '';
    default:
      return state;
  }
};

/**
 * Reducer for preventing modal submission while checking asset transfer.
 */
export const bulkTransferModalCheckingReducer = (
  state = false,
  action:
    | OpenBulkTransferModal
    | CloseBulkTransferModal
    | CheckTransferAssets
    | CheckTransferCollection
    | SetTransferErrors,
): boolean => {
  switch (action.type) {
    case OPEN_BULK_TRANSFER_MODAL:
      return false;
    case CLOSE_BULK_TRANSFER_MODAL:
      return false;
    case CHECK_TRANSFER_ASSETS:
      return true;
    case CHECK_TRANSFER_COLLECTION:
      return true;
    case SET_TRANSFER_ERRORS:
      return false;
    default:
      return state;
  }
};

export const bulkTransferAssetChoicesReducer = (
  state: ReadonlyArray<AssetChoice> = [],
  action: OpenBulkTransferModal | CloseBulkTransferModal,
): ReadonlyArray<AssetChoice> => {
  switch (action.type) {
    case OPEN_BULK_TRANSFER_MODAL:
      return action.payload.assetChoices;
    case CLOSE_BULK_TRANSFER_MODAL:
      return [];
    default:
      return state;
  }
};

export const bulkTransferCollectionChoiceReducer = (
  state: CollectionChoice | null = null,
  action: OpenBulkTransferModal | CloseBulkTransferModal,
): CollectionChoice | null => {
  switch (action.type) {
    case OPEN_BULK_TRANSFER_MODAL:
      return action.payload.collectionChoice;
    case CLOSE_BULK_TRANSFER_MODAL:
      return null;
    default:
      return state;
  }
};

export const monitoringURLReducer = (
  state: string | null = null,
  action: TransferStarted | CloseBulkTransferModal,
): string | null => {
  switch (action.type) {
    case TRANSFER_STARTED:
      return action.payload.monitoringURL || null;
    case CLOSE_BULK_TRANSFER_MODAL:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  bulkTransferModal: combineReducers({
    visible: bulkTransferModalVisibleReducer,
    checking: bulkTransferModalCheckingReducer,
    actionURL: selectedTransferActionURLReducer,
    transferErrors: transferErrorsReducer,
    assetChoices: bulkTransferAssetChoicesReducer,
    monitoringURL: monitoringURLReducer,
    collectionChoice: bulkTransferCollectionChoiceReducer,
  }),
});
