export const FETCH_LICENSE = 'FRONTEND/LICENSING/LOAD_CURRENT_LICENSE';

export interface FetchLicense {
  readonly type: typeof FETCH_LICENSE;
}

export const LICENSE_LOADED = 'FRONTEND/LICENSING/CURRENT_LICENSE_LOADED';
export interface LicenseLoaded {
  readonly type: typeof LICENSE_LOADED;
  readonly payload: {
    readonly license: string;
  };
}

export const SITE_COUNT_LOADED = 'FRONTEND/LICENSING/SITE_COUNT_LOADED';
export interface SiteCountLoaded {
  readonly type: typeof SITE_COUNT_LOADED;
  readonly payload: {
    readonly siteCount: number;
  };
}

export interface LicenseState {
  readonly Info: { readonly ['Date of Expiry']: string };
  readonly Nodes: {
    readonly Frontend: { readonly Site: number };
  };
}

export interface LicensingRootState {
  readonly currentLicense: LicenseState;
  readonly siteCount: number;
}
