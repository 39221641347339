import { combineReducers } from 'redux';

import { createResourcesReducer, createRootLinkReducer, mlRel } from 'medialoopster/rest';

import { AnnotationRootState } from './types';

export default combineReducers<AnnotationRootState>({
  annotations: createResourcesReducer('annotation'),
  annotationEndpointLink: createRootLinkReducer(mlRel('annotations')),
  publishAnnotationsLink: createRootLinkReducer(mlRel('annotations_publish')),
  unpublishAnnotationsLink: createRootLinkReducer(mlRel('annotations_unpublish')),
  deleteAnnotationsLink: createRootLinkReducer(mlRel('annotations_bulk_delete')),
});
