import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent, MouseEvent, forwardRef } from 'react';

import { IconButton, Input } from 'medialoopster/components';
import { ClearField } from 'medialoopster/icons';

type Props = {
  readonly name: string;
  readonly value: string;
  readonly label: string;
  readonly onChange: (event: ChangeEvent<{ value: string }>) => void;
  readonly onSubmit: () => void;
};

const SearchText = forwardRef<HTMLInputElement, Props>(
  ({ name, value, onChange, label, onSubmit }: Props, ref) => {
    const reset = (event: MouseEvent) => {
      onChange({ ...event, target: { value: '' } } as unknown as ChangeEvent<{
        value: string;
      }>);
      onSubmit();
    };

    return (
      <Input
        label={label}
        inputRef={ref}
        id={name}
        name={name}
        value={value}
        onChange={(_newValue, event) => onChange(event)}
        InputProps={{
          onKeyPress: (event) => {
            if (event.key === 'Enter') {
              onSubmit();
            }
          },
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              {value.length > 0 && (
                <IconButton onClick={reset}>
                  <ClearField />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  },
);
export default SearchText;
