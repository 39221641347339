/**
 * This module contains a plugin to implement the progress visualization by unit highlighting.
 */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $dfs } from '@lexical/utils';
import { $getRoot } from 'lexical';
import React, { useEffect } from 'react';

import { Decimal } from '../decimal';
import { $isTranscriptUnitNode } from '../nodes/TranscriptUnitNode';

/**
 * Properties for the `TranscriptProgressPlugin` compnent.
 */
export interface TranscriptProgressPluginProps {
  /**
   * The current time of the transcript that should be highlighted in seconds. This should be
   * set to the player cursor position.
   */
  readonly time: Decimal;
}

/**
 * A plugin to update the progress property of TranscriptUnitNodes.
 *
 * This plugin works together with `TranscriptUnitNode` to display the progress of the transcript
 * by highlighting past, present and future transcript units. The responsibility of this plugin
 * is to react to external updates of the asset/transcript time and to update the transcript units
 * accordingly.
 *
 * @param time The current time of the transcript/asset.
 *
 */
export const TranscriptProgressPlugin: React.FC<TranscriptProgressPluginProps> = ({ time }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      $dfs($getRoot()).forEach(({ node }) => {
        if ($isTranscriptUnitNode(node)) {
          node.updateProgress(time);
        }
      });
    });
  }, [editor, time]);

  return null;
};

export default TranscriptProgressPlugin;
