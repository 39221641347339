export const DEFAULT_SEGMENT_DURATION = 5;
export const MIN_BOUNDING_BOX_LABEL_LINE_LENGTH = 32;
export const MAX_BOUNDING_BOX_LABEL_LINE_LENGTH = 32;
export const DEFAULT_PLAYER_WIDTH = 603;
export const DEFAULT_PLAYER_HEIGHT = 339.1875;
export const DEFAULT_FONT_SIZE = 10;
export const LINE_WIDTH_EM = 0.25;
export const FONT_SIZE_PROPORTIONAL = DEFAULT_FONT_SIZE / DEFAULT_PLAYER_WIDTH;
export const FONT_SIZE_PROPORTIONAL_Y = DEFAULT_FONT_SIZE / DEFAULT_PLAYER_HEIGHT;
export const BOX_MARGIN_IN_EM = 0.15;
/* text padding based on bootstrap label-class */
/* 0.2em padding from label class + 0.2381 to emulate natural font padding */
export const TEXT_PADDING_TOP_IN_EM = 0.4381;
export const TEXT_PADDING_RIGHT_IN_EM = 0.9;
export const TEXT_PADDING_BOTTOM_IN_EM = 0.3;
export const TEXT_PADDING_LEFT_IN_EM = 0.9;
export const LINE_WIDTH_PROPORTIONAL_HALVED = (LINE_WIDTH_EM * FONT_SIZE_PROPORTIONAL_Y) / 2;
export const TEXT_AREA_MARGIN =
  LINE_WIDTH_PROPORTIONAL_HALVED + FONT_SIZE_PROPORTIONAL_Y * BOX_MARGIN_IN_EM;

let dummyContext: CanvasRenderingContext2D;
export const getDummyContext = (): CanvasRenderingContext2D => {
  if (!dummyContext) {
    // could only be null if called before document.onload is finished
    dummyContext = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D;
    dummyContext.font = `bold ${DEFAULT_FONT_SIZE}px sans-serif`;
  }
  return dummyContext;
};
