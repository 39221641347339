/**
 * This package and module implements the medialoopster transcript editor.
 *
 * The editor is split into the medialoopster specific components Transcript and TranscriptHeader
 * and a mostly independent Editor component. Transcript and TranscriptHeader integrate with
 * the redux store while Editor stays independent.
 */
import { Stack } from '@mui/material';
import _ from 'lodash';
import { useObservableState } from 'observable-hooks';
import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { throttleTime } from 'rxjs/operators';

import { gettext } from 'medialoopster/Internationalization';
import { OutlinedPaper, useVideoController } from 'medialoopster/components';

import { isVideoAsset } from '../../../businessRules/models/asset/utils';
import { detailsSelectors } from '../../../state/modules/details';
import { Editor } from './Editor';
import { EDITOR_STYLING } from './Editor/css';
import { Decimal, decimal } from './Editor/decimal';
import { TranscriptSpeakerData, TranscriptUnitData } from './Editor/diff';
import { LoadingSpinner } from './LoadingSpinner';
import { TranscriptHeader } from './TranscriptHeader';
import useSaveTranscript from './useSaveTranscript';

/**
 * Simple void keyboard event handler that stops the propagation of the event.
 *
 * @param event A keyboard event to ignore.
 */
const ignoreKeyStroke = (event: React.KeyboardEvent) => {
  event.stopPropagation();
};

/**
 * Hook to retrieve updates to the current playhead position of the current asset in seconds.
 *
 * The hook causes a rerender of the component it is used in whenever the position of the playhead
 * on the currently played asset changes. The updates are throttled to limit the number of
 * updates. The throttling duration is specified as parameter in milliseconds and the updates are
 * limited to 1 update in the specified throttling duration.
 *
 * The hook currently only works on video assets:
 * TODO: extend the implementation to work on audio assets <KH 2024-05-08 t:ML-3666>
 *
 * @param throttleDuration The throttling duration in milliseconds. Throttles updates to 1 in every duration.
 */
const useThrottledAssetTime = (throttleDuration: number): Decimal => {
  const videoController = useVideoController();

  const timeObservable$ = useMemo(
    () => videoController.time$.pipe(throttleTime(throttleDuration)),
    [videoController, throttleDuration],
  );

  const time = useObservableState(timeObservable$, 0);

  return useMemo(() => decimal(time.toString()), [time]);
};

/**
 * Hook to create a callback for navigating/seeking the asset/player
 *
 * The hook returns a callback that can be used to seek on the currently played asset.
 * The callback takes a time in seconds and moves the playhead to the given position.
 *
 * Currently the hook only works for video assets:
 * TODO: extend this to work with audio assets <KH 2024-05-08 t:ML-3666>
 */
const useAssetSeeker = (): ((time: Decimal) => void) => {
  const videoController = useVideoController();
  return useCallback(
    (time) => {
      videoController.seek(time.toNumber());
    },
    [videoController],
  );
};

/**
 * Hook to retrieve the offset time of the video asset in seconds.
 *
 * Videos can specify an offset in frames, that offsets the start time of the video to simplify
 * the coordination between multiple parallel recordings. If the current asset is a video, then
 * the offset frames are converted to a time in seconds and returned.
 *
 * Audio-assets cannot specify an offset and therefore the offset time is always 0 for audio assets.
 */
const useOffsetTime = (): Decimal => {
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  return useMemo(() => {
    if (isVideoAsset(currentAsset)) {
      const offsetFrames = decimal(currentAsset.offset_frames.toString());
      const fps = decimal(currentAsset.fps);
      return offsetFrames.div(fps);
    }
    return decimal('0');
  }, [currentAsset]);
};

/**
 * Hook to control when the identity of the units and speaker arrays changes.
 *
 * The transcript editor is reinitialized whenever the identity of the units and speaker arrays
 * change. This hook controls the identity of the arrays presented to the editor. This serves
 * two purposes.The first is to harden the logic against unintended changes in the selectors
 * that lead to unnecessary identity changes. The second is to enforce an identity change whenever
 * the editor changes into readonly-mode. This ensures that changes are discarded when the
 * lock on the asset is removed.
 *
 * To achieve those goals the hook clones the unit and speaker arrays. These clones are then
 * cached as long as the units and speakers stay the same and the editor does not switch from
 * writable to read-only.
 *
 * @param units An array with the units of the transcript.
 * @param speakers An array with the speakers of the transcript.
 * @param isEditable A boolean that indicates if the transcript is editable or not.
 * @returns A tuple containing clones of the unit and speaker arrays, which exhibit a stable and controlled identity.
 */
export const useTranscriptWithControlledIdentity = (
  units: readonly TranscriptUnitData[],
  speakers: readonly TranscriptSpeakerData[],
  isEditable: boolean,
): readonly [readonly TranscriptUnitData[], readonly TranscriptSpeakerData[]] => {
  type LastCall = {
    units: TranscriptUnitData[];
    speakers: TranscriptSpeakerData[];
    isEditable: boolean;
  };
  const lastCallRef = useRef<LastCall>();

  return useMemo(() => {
    let lastCall = lastCallRef.current;
    if (
      !lastCall || // first render
      (lastCall.isEditable && !isEditable) || // switch from writable to read-only
      !_.isEqual(lastCall.units, units) || // units did change
      !_.isEqual(lastCall.speakers, speakers) // speakers did change
    ) {
      // create arrays with new identity
      lastCall = {
        units: [...units],
        speakers: [...speakers],
        isEditable,
      };
    } else {
      // only update `isEditable`
      lastCall = {
        ...lastCall,
        isEditable,
      };
    }
    lastCallRef.current = lastCall;
    return [lastCall.units, lastCall.speakers];
  }, [units, speakers, isEditable]);
};

/**
 * A component to integrate the transcript Editor component into medialoopster.
 *
 * Transcripts responsibility is to integrate the mostly independent Editor component into
 * medialoopster. This is done by retrieving state from the store and feeding it to the Editor
 * component and by wiring up callbacks from Editor. Medialoopster specific integration code
 * should stay out of Editor.
 *
 * Transcript also renders the Header of the transcript using TranscriptHeader.
 */
const Transcript: React.FC = () => {
  const units = useSelector(detailsSelectors.getCurrentUnits);
  const speakers = useSelector(detailsSelectors.getCurrentSpeakers);
  const transcriptAvailability = useSelector(detailsSelectors.getCurrentTranscriptAvailability);
  const isEditable = useSelector(detailsSelectors.isCurrentTranscriptEditable);
  const time = useThrottledAssetTime(200);
  const seek = useAssetSeeker();
  const offsetTime = useOffsetTime();
  const saveTranscript = useSaveTranscript();
  const [controlledUnits, controlledSpeakers] = useTranscriptWithControlledIdentity(
    units,
    speakers,
    isEditable,
  );

  if (transcriptAvailability === 'no-transcript') {
    return <div>{gettext('This asset has no transcript')}</div>;
  }

  return (
    <Stack
      direction="column"
      flexWrap="nowrap"
      onKeyDown={ignoreKeyStroke}
      onKeyUp={ignoreKeyStroke}
      height="min-content"
      maxHeight="100%"
      width="100%"
      component={OutlinedPaper}
      position="relative"
    >
      <TranscriptHeader />
      <Editor
        units={controlledUnits}
        speakers={controlledSpeakers}
        time={time}
        onNavigation={seek}
        onSave={saveTranscript}
        isEditable={isEditable}
        offsetTime={offsetTime}
        sx={{
          ...EDITOR_STYLING,
          flexShrink: 1,
        }}
      />
      <LoadingSpinner show={transcriptAvailability !== 'ready'} />
    </Stack>
  );
};

export default Transcript;
