import { TranscriptDiff } from '../../../sections/DetailsSection/Transcript/Editor/diff';
import { AssetType } from '../../types/asset/baseTypes';
import { Annotation } from '../annotation/types';
import { AudioAsset } from '../audio/types';
import { ImageAsset } from '../image/types';
import { Sequence, Shot, VideoAsset } from '../video/types';
import {
  ADD_ANNOTATION,
  AddAnnotationAction,
  AudioEditFields,
  CHANGE_POSTER_FRAME,
  ChangePosterFrame,
  CREATE_SEQUENCE,
  CREATE_SHOT,
  CreateSequence,
  CreateShot,
  DELETE_ANNOTATION,
  DELETE_SEQUENCE,
  DELETE_SHOT,
  DeleteAnnotationAction,
  DeleteSequence,
  DeleteShot,
  EDIT_ANCESTOR,
  EDIT_ASSET,
  EditAncestor,
  EditAsset,
  FETCH_ANNOTATIONS,
  FETCH_SEQUENCE_OPTIONS,
  FETCH_SHOT_OPTIONS,
  FetchAnnotationsAction,
  FetchSequenceOptions,
  FetchShotOptions,
  ImageEditFields,
  LOAD_ANCESTOR,
  LOAD_ANCESTOR_ERROR,
  LoadAncestor,
  LoadAncestorError,
  LOCK_CURRENT_ASSET,
  LockCurrentAsset,
  REFRESH_CURRENT_ASSET,
  RefreshCurrentAsset,
  SELECT_CLIP,
  SELECT_SHOT_RANGE_END,
  SELECT_SHOT_RANGE_START,
  SelectClip,
  SelectShotRangeEnd,
  SelectShotRangeStart,
  SequenceEditFields,
  SET_CURRENT_ASSET,
  SET_EMBEDDED_ASSET,
  SetCurrentAsset,
  SetEmbeddedAsset,
  EDIT_ANNOTATION,
  EditAnnotationAction,
  SHOW_ALL_ANNOTATIONS,
  ShowAllAnnotationsAction,
  TOGGLE_ANNOTATIONS,
  TOGGLE_EDIT_MODE,
  TOGGLE_VIEW,
  ToggleAnnotations,
  ToggleEditMode,
  ToggleView,
  UNLOCK_CURRENT_ASSET,
  UnlockCurrentAsset,
  UPDATE_KEYWORDS,
  UpdateKeywords,
  VideoEditFields,
  ViewSidebarType,
  ViewType,
  AnnotationEditableFields,
  TOGGLE_EDITING_ANNOTATION,
  ToggleEditingAnnotation,
  CollectionEditFields,
  RemoveAssetFromCollection,
  REMOVE_ASSET_FROM_COLLECTION,
  PUBLISH_ALL_ANNOTATIONS,
  PublishAllAnnotations,
  UnpublishAllAnnotations,
  UNPUBLISH_ALL_ANNOTATIONS,
  DeleteAllAnnotations,
  DELETE_ALL_ANNOTATIONS,
  FETCH_CURRENT_TRANSCRIPT,
  FetchCurrentTranscriptAction,
  SaveTranscriptAction,
  SAVE_TRANSCRIPT,
  TranscriptAvailability,
  SET_TRANSCRIPT_AVAILABILITY,
  SetTranscriptAvailabilityAction,
} from './types';

export const selectClip = (
  assetHref: string,
  timecodeStart: number,
  timecodeEnd: number,
): SelectClip => ({
  type: SELECT_CLIP,
  payload: {
    assetHref,
    timecodeStart,
    timecodeEnd,
  },
});

export const setCurrentAsset = (type: AssetType | null, href: string | null): SetCurrentAsset => ({
  type: SET_CURRENT_ASSET,
  payload: {
    href,
    type,
  },
});

export const removeAssetFromCollection = (
  collectionURI: string,
  removeURI: string,
  linkedAssetCollectionHref: string,
  assetHref: string,
  shareURL: string,
): RemoveAssetFromCollection => ({
  type: REMOVE_ASSET_FROM_COLLECTION,
  payload: {
    collectionURI,
    removeURI,
    linkedAssetCollectionHref,
    assetHref,
    shareURL,
  },
});

export const lockCurrentAsset = (
  assetType: AssetType,
  assetHref: string,
  userId: number,
  userHref: string,
): LockCurrentAsset => ({
  type: LOCK_CURRENT_ASSET,
  payload: {
    assetType,
    assetHref,
    userId,
    userHref,
  },
});

export const unlockCurrentAsset = (
  assetType: AssetType,
  assetHref: string,
): UnlockCurrentAsset => ({
  type: UNLOCK_CURRENT_ASSET,
  payload: {
    assetType,
    assetHref,
  },
});

export const toggleEditMode = (): ToggleEditMode => ({
  type: TOGGLE_EDIT_MODE,
});

export const toggleView = (view: ViewType, sidebarType: ViewSidebarType): ToggleView => ({
  type: TOGGLE_VIEW,
  payload: {
    view,
    sidebarType,
  },
});

export const setEmbeddedAsset = (type: AssetType, href: string): SetEmbeddedAsset => ({
  type: SET_EMBEDDED_ASSET,
  payload: {
    type,
    href,
  },
});

export const editAsset = (
  assetType: AssetType | 'sequence',
  assetHref: string,
  updates:
    | VideoEditFields
    | SequenceEditFields
    | AudioEditFields
    | ImageEditFields
    | CollectionEditFields,
): EditAsset => ({
  type: EDIT_ASSET,
  payload: { assetType, assetHref, updates },
});

export const changePosterFrame = (asset: VideoAsset, frame: number): ChangePosterFrame => ({
  type: CHANGE_POSTER_FRAME,
  payload: {
    asset,
    frame,
  },
});

export const createShot = (
  asset: VideoAsset,
  timecodeStart: number,
  timecodeEnd: number,
): CreateShot => ({
  type: CREATE_SHOT,
  payload: {
    asset,
    timecodeStart,
    timecodeEnd,
  },
});

export const deleteShot = (shot: Shot): DeleteShot => ({
  type: DELETE_SHOT,
  payload: {
    shot,
  },
});

export const createSequence = (
  asset: VideoAsset,
  timecodeStart: number,
  timecodeEnd: number,
): CreateSequence => ({
  type: CREATE_SEQUENCE,
  payload: {
    asset,
    timecodeStart,
    timecodeEnd,
  },
});

export const deleteSequence = (sequence: Sequence): DeleteSequence => ({
  type: DELETE_SEQUENCE,
  payload: {
    sequence,
  },
});

export const selectShotRangeStart = (shot: Shot): SelectShotRangeStart => ({
  type: SELECT_SHOT_RANGE_START,
  payload: {
    shot,
  },
});

export const selectShotRangeEnd = (shot: Shot): SelectShotRangeEnd => ({
  type: SELECT_SHOT_RANGE_END,
  payload: {
    shot,
  },
});

export const updateKeywords = (
  resource: ImageAsset | AudioAsset | Shot | Sequence,
  keywords: ReadonlyArray<string>,
): UpdateKeywords => ({
  type: UPDATE_KEYWORDS,
  payload: {
    resource,
    keywords,
  },
});

export const fetchShotOptions = (shotURI: string): FetchShotOptions => ({
  type: FETCH_SHOT_OPTIONS,
  payload: {
    shotURI,
  },
});

export const fetchSequenceOptions = (sequenceURI: string): FetchSequenceOptions => ({
  type: FETCH_SEQUENCE_OPTIONS,
  payload: {
    sequenceURI,
  },
});

export const editAncestor = (ancestorHref: string): EditAncestor => ({
  type: EDIT_ANCESTOR,
  payload: {
    ancestorHref,
  },
});

export const loadAncestor = (ancestorHref: string): LoadAncestor => ({
  type: LOAD_ANCESTOR,
  payload: {
    ancestorHref,
  },
});

export const loadAncestorError = (ancestorHref: string, error: string): LoadAncestorError => ({
  type: LOAD_ANCESTOR_ERROR,
  payload: {
    ancestorHref,
    error,
  },
});

export const refreshCurrentAsset = (): RefreshCurrentAsset => ({
  type: REFRESH_CURRENT_ASSET,
});

// region annotations

export const toggleAnnotations = (on?: boolean): ToggleAnnotations => ({
  type: TOGGLE_ANNOTATIONS,
  payload: {
    on,
  },
});

/**
 * Set if all published annotations for a video are displayed or just the ones from the current user
 * @param showAll
 */
export const setShowAllAnnotations = (showAll: boolean): ShowAllAnnotationsAction => ({
  type: SHOW_ALL_ANNOTATIONS,
  payload: {
    showAll,
  },
});

/**
 * Trigger fetching of the annotations for the current video asset.
 */
export const fetchAnnotations = (): FetchAnnotationsAction => ({
  type: FETCH_ANNOTATIONS,
});

/**
 * Action to add an annotation by the current user to the current asset.
 *
 * @param frame The the annotation is added to.
 * @param text The text of the annotation.
 */
export const addAnnotation = (frame: number, text: string): AddAnnotationAction => ({
  type: ADD_ANNOTATION,
  payload: {
    frame,
    text,
  },
});

/**
 * Action to edit an annotation owned by the current user.
 *
 * @param uri The annotation URI which will be edited.
 * @param editFields The annotation fields which will can be edited.
 */
export const editAnnotation = (
  uri: string,
  editFields: AnnotationEditableFields,
): EditAnnotationAction => ({
  type: EDIT_ANNOTATION,
  payload: {
    uri,
    editFields,
  },
});

/**
 * Action to delete an annotation owned by the current user.
 *
 * @param annotation The annotation which will be deleted.
 */
export const deleteAnnotation = (annotation: Annotation): DeleteAnnotationAction => ({
  type: DELETE_ANNOTATION,
  payload: {
    annotation,
  },
});

/**
 * Action to toggle editing an annotation owned by the current user.
 *
 * @param annotationURI The annotation URI to toggle editing for.
 */
export const toggleEditingAnnotation = (annotationURI: string): ToggleEditingAnnotation => ({
  type: TOGGLE_EDITING_ANNOTATION,
  payload: {
    annotationURI,
  },
});

/**
 * Action to publish all annotations for the current asset owned by the current user.
 */
export const publishAllAnnotations = (): PublishAllAnnotations => ({
  type: PUBLISH_ALL_ANNOTATIONS,
});

/**
 * Action to unpublish all annotations for the current asset owned by the current user.
 */
export const unpublishAllAnnotations = (): UnpublishAllAnnotations => ({
  type: UNPUBLISH_ALL_ANNOTATIONS,
});

/**
 * Action to delete all annotations for the current asset owned by the current user.
 */
export const deleteAllAnnotations = (): DeleteAllAnnotations => ({
  type: DELETE_ALL_ANNOTATIONS,
});

// endregion annotations

// region transcript

/**
 * Action creator for the FetchCurrentTranscriptAction.
 */
export const fetchCurrentTranscript = (): FetchCurrentTranscriptAction => ({
  type: FETCH_CURRENT_TRANSCRIPT,
});

/**
 * Action to save an edit on the current transcript.
 * @param diff The diff of the edited transcript to the original.
 */
export const saveTranscript = (diff: TranscriptDiff): SaveTranscriptAction => ({
  type: SAVE_TRANSCRIPT,
  payload: {
    diff,
  },
});

/**
 * Set the transcript availability.
 * @param availability The new availability of the transcript.
 */
export const setTranscriptAvailability = (
  availability: TranscriptAvailability,
): SetTranscriptAvailabilityAction => ({
  type: SET_TRANSCRIPT_AVAILABILITY,
  payload: {
    availability,
  },
});

// endregion transcript
