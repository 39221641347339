import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, interpolate, ngettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Analyse } from 'medialoopster/icons';

import { AssetChoice } from '../../../../state/modules/operations/types';
import { personsActions, personsSelectors } from '../../../../state/modules/persons';

type Props = {
  readonly assetChoices: ReadonlyArray<AssetChoice>;
  readonly name: string;
  readonly analyseActionURL: string;
};

const AnalyseActionMenuEntry = ({ assetChoices, name, analyseActionURL }: Props) => {
  const dispatch = useDispatch();
  return (
    <MenuItem
      label={interpolate(gettext('Analyse with %(connector)s'), {
        connector: name,
      })}
      onClick={() => {
        dispatch(personsActions.analyseAssets(analyseActionURL, assetChoices));
      }}
      tooltip={interpolate(
        ngettext(
          'Analyse the selected asset with %(connector)s',
          'Analyse the selected assets with %(connector)s',
          assetChoices.length,
        ),
        {
          connector: name,
        },
      )}
      icon={<Analyse fontSize="small" />}
    />
  );
};

const useAnalyseActionMenuEntries = (): ReadonlyArray<ReactElement> => {
  const connectorChoices = useSelector(personsSelectors.getAnalyseConnectorChoices);
  if (connectorChoices.length === 0) {
    return [];
  }
  return connectorChoices.map(({ name, analyseActionURL, assetChoices }) => (
    <AnalyseActionMenuEntry
      key={analyseActionURL}
      assetChoices={assetChoices}
      name={name}
      analyseActionURL={analyseActionURL}
    />
  ));
};

export default useAnalyseActionMenuEntries;
