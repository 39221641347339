import { Box, ChipProps, IconButton } from '@mui/material';
import _ from 'lodash';
import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { KeywordsInput } from 'medialoopster/components';
import useWidgetContext from 'medialoopster/components/forms/hooks/useWidgetContext';
import { Add, Remove, Undo } from 'medialoopster/icons';

import { bulkEditSelectors } from '../../../../../state/modules/bulkEdit';

type Props = {
  readonly name: string;
  readonly label: string;
  readonly availableKeywords: ReadonlyArray<string>;
  readonly disabled?: boolean;
};

const BulkEditCategoryKeywordsWidget: FC<Props> = ({
  name,
  label,
  availableKeywords,
  disabled = false,
}: Props) => {
  const keywordsInAnyAsset = useSelector(bulkEditSelectors.getKeywordsInAnyAsset);
  const keywordsInAllAssets = useSelector(bulkEditSelectors.getKeywordsInAllAssets);
  const canRemoveKeywords = useSelector(bulkEditSelectors.canRemoveKeywords);
  const errors = useSelector(bulkEditSelectors.getKeywordsFieldsErrors);
  const fieldErrors = errors[name] || {};
  const {
    onChange: onAddedKeywordsChange,
    onBlur: onAddedKeywordsBlur,
    value: addedKeywords,
  } = useWidgetContext({
    name: `${name}.added_keywords`,
    defaultValue: [],
    rules: {},
    disabled: false,
  });
  const {
    onChange: onRemovedKeywordsChange,
    onBlur: onRemovedKeywordsBlur,
    value: removedKeywords,
  } = useWidgetContext({
    name: `${name}.removed_keywords`,
    defaultValue: [],
    rules: {},
    disabled: false,
  });
  const addKeywords = (keywords: ReadonlyArray<string>): void => {
    onRemovedKeywordsChange(_.difference(removedKeywords, keywords));
    onRemovedKeywordsBlur();
    onAddedKeywordsChange(_.union(addedKeywords, keywords));
    onAddedKeywordsBlur();
  };
  const removeKeywords = (keywords: ReadonlyArray<string>): void => {
    onAddedKeywordsChange(_.difference(addedKeywords, keywords));
    onAddedKeywordsBlur();
    onRemovedKeywordsChange(_.union(removedKeywords, keywords));
    onRemovedKeywordsBlur();
  };
  const resetKeyword = (keyword: string): void => {
    if (addedKeywords.includes(keyword)) {
      onAddedKeywordsChange(_.without(addedKeywords, keyword));
      onAddedKeywordsBlur();
    }
    if (removedKeywords.includes(keyword)) {
      onRemovedKeywordsChange(_.without(removedKeywords, keyword));
      onRemovedKeywordsBlur();
    }
  };
  const allKeywords = useMemo(() => {
    const keywords = _.intersection(_.union(keywordsInAnyAsset, addedKeywords), availableKeywords);
    keywords.sort();
    return keywords;
  }, [keywordsInAnyAsset, addedKeywords, availableKeywords]);
  const getChipColor = (keyword: string): ChipProps['color'] => {
    if (removedKeywords.includes(keyword)) {
      return 'error';
    }
    if (addedKeywords.includes(keyword) || keywordsInAllAssets.includes(keyword)) {
      return 'success';
    }
    return 'info';
  };
  const getDeleteIconProps = (keyword: string): Pick<ChipProps, 'deleteIcon' | 'onDelete'> => {
    if (removedKeywords.includes(keyword)) {
      // The keyword is selected to be removed.
      // => Display undo button.
      return { deleteIcon: <Undo fontSize="small" />, onDelete: () => resetKeyword(keyword) };
    }
    if (
      keywordsInAnyAsset.includes(keyword) &&
      !keywordsInAllAssets.includes(keyword) &&
      addedKeywords.includes(keyword)
    ) {
      // The keyword is in some, but not all selected assets, and is selected to be added.
      // => Display undo button.
      return { deleteIcon: <Undo fontSize="small" />, onDelete: () => resetKeyword(keyword) };
    }
    if (!keywordsInAnyAsset.includes(keyword) && addedKeywords.includes(keyword)) {
      // The keyword is not in any selected asset and is selected to be added.
      // => Display remove button.
      return { onDelete: () => resetKeyword(keyword) };
    }
    return {};
  };
  const getKeywordChipProps = (
    keyword: string,
  ): Partial<ChipProps> & { readonly 'data-testid': string } => {
    const errorMessage = (fieldErrors[keyword] || []).join('\n');
    return {
      color: getChipColor(keyword),
      icon: (
        <Box>
          {!keywordsInAllAssets.includes(keyword) && !addedKeywords.includes(keyword) && (
            <IconButton
              title={gettext('Add keyword to all selected assets')}
              onClick={() => addKeywords([keyword])}
              sx={{ padding: 0 }}
              color="inherit"
            >
              <Add fontSize="small" />
            </IconButton>
          )}
          {canRemoveKeywords &&
            keywordsInAnyAsset.includes(keyword) &&
            !removedKeywords.includes(keyword) && (
              <IconButton
                title={gettext('Remove keyword from all selected assets')}
                onClick={() => removeKeywords([keyword])}
                sx={{ padding: 0 }}
                color="inherit"
              >
                <Remove fontSize="small" />
              </IconButton>
            )}
        </Box>
      ),
      ...getDeleteIconProps(keyword),
      ...(errorMessage !== ''
        ? {
            title: errorMessage,
            color: 'error',
            variant: 'outlined',
          }
        : {}),
      'data-testid': 'keyword',
    };
  };
  return (
    <KeywordsInput
      label={label}
      selectedKeywords={allKeywords}
      availableKeywords={availableKeywords}
      onAddKeywords={addKeywords}
      getKeywordChipProps={getKeywordChipProps}
      disabled={disabled}
    />
  );
};

export default memo(BulkEditCategoryKeywordsWidget);
