import {
  BaseResource,
  CollectionType,
  createResourcesLoadedActionType,
  EmbeddedItemsPageResource,
  Link,
  LinkedItemsPageResource,
  ResourceMap,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { SearchQuery } from '../../../sections/Header/services/SearchQueryService';
import { AssetType, SearchResultAssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';
import { CurrentAssetState } from '../details/types';
import { SearchAssetCollectionResult } from '../rest/collectionsearchresult/types';

export const CLICK_ENTRY = 'FRONTEND/SEARCH_LIST/CLICK_ENTRY';
export const SELECT_ENTRY = 'FRONTEND/SEARCH_LIST/SELECT_ENTRY';
export const MULTI_SELECT_ENTRY = 'FRONTEND/SEARCH_LIST/MULTI_SELECT_ENTRY';
export const RANGE_SELECT_ENTRIES = 'FRONTEND/SEARCH_LIST/RANGE_SELECT_ENTRIES';
export const DESELECT_ALL_ENTRIES = 'FRONTEND/SEARCH_LIST/DESELECT_ALL_ENTRIES';
export const LOAD_SHOTS = 'FRONTEND/SEARCH_LIST/LOAD_SHOTS';
export const FETCH_SEARCH_RESULT = 'FRONTEND/SEARCH_LIST/FETCH_SEARCH_RESULT';
export const FETCH_NEXT_SEARCH_RESULT = 'FRONTEND/SEARCH_LIST/FETCH_NEXT_SEARCH_RESULT';
export const SEARCH_LOADED = 'FRONTEND/SEARCH_LIST/SEARCH_LOADED';

export const VIDEOASSET_SEARCH_RESULT_COLLECTION_LOADED = createResourcesLoadedActionType(
  'videoassetsearchresult-collection',
);
export const IMAGEASSET_SEARCH_RESULT_COLLECTION_LOADED = createResourcesLoadedActionType(
  'imageassetsearchresult-collection',
);
export const AUDIOASSET_SEARCH_RESULT_COLLECTION_LOADED = createResourcesLoadedActionType(
  'audioassetsearchresult-collection',
);
export const COLLECTION_SEARCH_RESULT_COLLECTION_LOADED = createResourcesLoadedActionType(
  'collectionsearchresult-collection',
);

//-------------
// Action Types
//-------------

export interface ClickEntry {
  readonly type: typeof CLICK_ENTRY;
  readonly payload: {
    readonly assetId: number;
    readonly assetTypeName: AssetType;
    readonly assetHref: string;
    readonly withShiftKey: boolean;
    readonly withAltKey: boolean;
    readonly withCtrlKey: boolean;
    readonly withMetaKey: boolean;
  };
}

export interface SelectEntry {
  readonly type: typeof SELECT_ENTRY;
  readonly payload: {
    readonly assetId: number;
    readonly assetTypeName: AssetType;
    readonly assetHref: string;
  };
}

export interface MultiSelectEntry {
  readonly type: typeof MULTI_SELECT_ENTRY;
  readonly payload: {
    readonly assetId: number;
    readonly assetTypeName: AssetType;
    readonly assetHref: string;
    readonly currentAssetState: CurrentAssetState;
    readonly entries: SearchEntryAssetList;
  };
}

export interface RangeSelectEntries {
  readonly type: typeof RANGE_SELECT_ENTRIES;
  readonly payload: {
    readonly assetId: number;
    readonly assetTypeName: AssetType;
    readonly assetHref: string;
    readonly currentAssetState: CurrentAssetState;
    readonly entries: SearchEntryAssetList;
  };
}

export interface DeselectAllEntries {
  readonly type: typeof DESELECT_ALL_ENTRIES;
}

export interface LoadShots {
  readonly type: typeof LOAD_SHOTS;
  readonly payload: { readonly url: string };
}

export const LOAD_NEXT_SHOTS = 'FRONTEND/SEARCH_LIST/LOAD_NEXT_SHOTS';

export interface LoadNextShots {
  readonly type: typeof LOAD_NEXT_SHOTS;
  readonly payload: { readonly url: string };
}

export interface FetchSearchResult {
  readonly type: typeof FETCH_SEARCH_RESULT;
  readonly payload: {
    readonly assetTypeName: AssetType;
    readonly query: SearchQuery;
    readonly url: string;
    readonly hideHighlights: boolean;
  };
}

export interface FetchNextSearchResult {
  readonly type: typeof FETCH_NEXT_SEARCH_RESULT;
  readonly payload: { readonly url: string };
}

export interface SearchLoaded {
  readonly type: typeof SEARCH_LOADED;
}

//---------------
// Resource Types
//---------------

/**
 TODO: ReadonlyArray<Asset> is not quite correct, but issues with return types <RS>
 | ReadonlyArray<AudioAsset>
 | ReadonlyArray<ImageAsset>
 | ReadonlyArray<VideoAsset>
 | ReadonlyArray<AssetCollection>;
 */
export type SearchEntryAssetList = ReadonlyArray<Asset | SearchAssetCollectionResult>;

export interface SelectedAssetsState {
  readonly assetIds: ReadonlyArray<number>;
  readonly assetTypeName: null | AssetType;
}

export interface Highlight {
  readonly fields?: ReadonlyRecord<string, ReadonlyArray<string>>;
}

export interface SequenceHighlight extends Highlight {
  readonly shots: ReadonlyRecord<string, Highlight>;
}

export interface AssetHighlight extends Highlight {
  readonly sequences?: ReadonlyRecord<string, SequenceHighlight>;
}

export interface SearchEntryResource extends BaseResource {
  readonly highlight: AssetHighlight;
  readonly is_available: boolean;
}

export type AssetSearchResultType<T extends SearchResultAssetType> = `${T}searchresult`;

export interface AssetSearchPage<T extends SearchResultAssetType>
  extends EmbeddedItemsPageResource<SearchEntryResource, CollectionType<AssetSearchResultType<T>>> {
  readonly isFallbackSearch: boolean;
}

export interface ShotSearchPage
  extends LinkedItemsPageResource<CollectionType<'shotsearchresult'>> {
  readonly isFallbackSearch: boolean;
}

//------------------
// Redux State Types
//------------------

export interface AssetSearchEntryState extends SearchEntryResource {
  readonly asset: Asset;
}

export interface HighlightForAssetState {
  readonly [assetHref: string]: AssetHighlight;
}

export interface DeviceFilter extends BaseResource {
  readonly display_name: string;
  readonly device_names: ReadonlyArray<string>;
}

export interface SearchRootState {
  readonly isRefreshingOrLoading: boolean;
  readonly assetSearchURI: string;
  readonly shotSearchURI: string | null;
  readonly selection: SelectedAssetsState;
  readonly searchQuery: SearchQuery;
  readonly resultCollections: {
    readonly videoasset: ResourceMap<AssetSearchPage<'videoasset'>>;
    readonly imageasset: ResourceMap<AssetSearchPage<'imageasset'>>;
    readonly audioasset: ResourceMap<AssetSearchPage<'audioasset'>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly collection: ResourceMap<AssetSearchPage<'collection'>>;
    readonly shot: ResourceMap<ShotSearchPage>;
  };
  readonly category: AssetType;
  readonly deviceFilterCollectionLink: Link | null;
  readonly deviceFilterCollections: ResourceMap<
    EmbeddedItemsPageResource<DeviceFilter, CollectionType<'devicefilter'>>
  >;
}
