import { Menu } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem, ToolbarIconButton } from 'medialoopster/components';
import { LANGUAGES } from 'medialoopster/constants';
import { ArrowRight, AudioTrack, Check, PlayerSettings, Subtitles } from 'medialoopster/icons';
import { getResourceURI } from 'medialoopster/rest';

import { Subtitle } from '../../../state/modules/video/types';

const menuStyle = {
  width: 250,
  maxWidth: '100%',
};

type Props = {
  readonly subtitles: ReadonlyArray<Subtitle>;
  readonly selectTextLanguage: (language: keyof typeof LANGUAGES | null) => void;
  readonly selectedTextLanguage: keyof typeof LANGUAGES | null;
  readonly audioTracks: ReadonlyArray<shaka.extern.Track>;
  readonly selectAudioTrack: (track: shaka.extern.Track) => void;
  readonly selectedAudioTrack?: shaka.extern.Track | null;
  readonly isMpd: boolean;
  readonly disabled?: boolean;
};

/**
 * Renders a button with player settings and submenus for selecting subtitles and audio tracks.
 */
const PlayerSettingsButton: FC<Props> = ({
  subtitles,
  selectTextLanguage,
  selectedTextLanguage,
  audioTracks,
  selectAudioTrack,
  selectedAudioTrack,
  isMpd,
  disabled = false,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const shouldShowAudioTracks = isMpd && audioTracks.length > 2;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ToolbarIconButton
        title={pgettext('Video player controls', 'Player settings')}
        disabled={disabled}
        aria-controls="player-settings-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <PlayerSettings />
      </ToolbarIconButton>
      <Menu
        id="player-settings-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem
          style={menuStyle}
          label={gettext('Audio tracks')}
          icon={<AudioTrack />}
          endIcon={<ArrowRight />}
          disabled={!shouldShowAudioTracks}
          onSubmenuClose={handleClose}
          submenuItems={audioTracks.map((track, index) => (
            <MenuItem
              style={menuStyle}
              key={`${track.id}`}
              label={track.language.charAt(0).toUpperCase() + track.language.slice(1)}
              selected={track === selectedAudioTrack || (!selectedAudioTrack && index === 0)}
              onClick={() => {
                selectAudioTrack(track);
                handleClose();
              }}
              endIcon={
                (track === selectedAudioTrack || (!selectedAudioTrack && index === 0)) && <Check />
              }
            />
          ))}
        />

        <MenuItem
          style={menuStyle}
          label={gettext('Subtitle language')}
          icon={<Subtitles />}
          endIcon={<ArrowRight />}
          disabled={subtitles.length === 0}
          onSubmenuClose={handleClose}
          submenuItems={subtitles.map((subtitle) => (
            <MenuItem
              style={menuStyle}
              key={getResourceURI(subtitle)}
              label={LANGUAGES[subtitle.language]}
              selected={selectedTextLanguage === subtitle.language}
              onClick={() => {
                selectTextLanguage(subtitle.language);
                handleClose();
              }}
              endIcon={selectedTextLanguage === subtitle.language && <Check />}
            />
          ))}
        />
      </Menu>
    </>
  );
};

export default PlayerSettingsButton;
