import Button from '@mui/material/Button';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Check } from 'medialoopster/icons';

const NoMoreResults: FC = () => (
  <Button id="no-more-results" fullWidth disabled variant="outlined">
    <Check />
    {gettext('No more results')}
    <Check />
  </Button>
);

export default NoMoreResults;
