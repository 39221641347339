import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  Collection,
  createCollectionSelector,
  getLink,
  getLinkHref,
  getResourceArray,
  getResourceTypeName,
  getResourceURI,
  mlRel,
  ResourceMap,
} from 'medialoopster/rest';
import { SomeNonNullable } from 'medialoopster/types';

import {
  buildFavoritesItemState,
  createFavoriteListDisplayName,
  getSharedActiveListsForUser,
  getSharedFavoriteListsForUser,
} from '../../../businessRules/models/FavoriteListsHelpers';
import { RootState } from '../../types/rootState';
import { getAudioAssets } from '../audio/selectors';
import { AudioAsset } from '../audio/types';
import { getImageAssets } from '../image/selectors';
import { ImageAsset } from '../image/types';
import { getCurrentSiteURL } from '../main/selectors';
import { AssetChoice } from '../operations/types';
import { getSiteExportConfigs } from '../rest/siteexportconfigs/selectors';
import { SiteExportConfig } from '../rest/siteexportconfigs/types';
import { usersSelectors } from '../users';
import { getVideoAssets } from '../video/selectors';
import { VideoAsset } from '../video/types';
import {
  FavoritesItemResource,
  FavoritesItemState,
  FavoritesListById,
  FavoritesListResource,
  FavoritesListState,
  SelectedFavoritesListState,
  SharedFavoritesListState,
} from './types';

export const isActive = (state: RootState): boolean => state.favorites.isActive;
export const getListMap = (state: RootState): ResourceMap<FavoritesListResource> =>
  state.favorites.favoriteItemLists;
export const getLists = createSelector(
  getListMap,
  (resourceMap): ReadonlyArray<FavoritesListResource> => getResourceArray(resourceMap),
);
export const getListsById = createSelector(
  getLists,
  (lists): FavoritesListById => Object.fromEntries(lists.map((list) => [list.id, list])),
);

export const getSelectedListId = (state: RootState): number | null =>
  state.favorites.selectedListId;

export const getSharedActiveLists = (state: RootState): ReadonlyArray<number> =>
  state.favorites.sharedActiveLists;

export const getFavoriteSiteExports = createSelector(
  getSiteExportConfigs,
  getCurrentSiteURL,
  (collection: Collection<SiteExportConfig>, currSite): ReadonlyArray<SiteExportConfig> => {
    return currSite !== null
      ? collection.items.filter(
          (el) => el.accepted_file_type === 'xml' && getLinkHref(el, 'site') === currSite,
        )
      : [];
  },
);

export const getSelectedList: (state: RootState) => FavoritesListResource | undefined =
  createSelector(
    getSelectedListId,
    getListsById,
    (selectedListId, listIds) => listIds[String(selectedListId)],
  );

export const getSelectedFavoritesListXMLDownloadURL = createSelector(
  getSelectedList,
  (selectedList) => {
    if (selectedList === undefined) {
      return false;
    }
    const downloadXMLLink = getLink(selectedList, mlRel('fcp7xml'));
    if (downloadXMLLink?.methods?.GET?.code === 'ok') {
      return downloadXMLLink.href;
    }
    return false;
  },
);

export const getRceExportURL = createSelector(getSelectedList, (selectedList) => {
  if (selectedList === undefined) {
    return false;
  }
  const exportEditorLink = getLink(selectedList, mlRel('roughcuteditor'));
  if (exportEditorLink?.methods?.GET?.code === 'ok') {
    return exportEditorLink.href;
  }
  return false;
});
/**
 * Get the favorite lists shared for current user and add the lists display name
 */
export const getSharedListsWithUserDisplayName = createSelector(
  usersSelectors.getUsersMap,
  getLists,
  usersSelectors.getCurrentUserURI,
  (users, favoriteLists, currentUserURI): ReadonlyArray<SharedFavoritesListState> =>
    getSharedFavoriteListsForUser(currentUserURI, favoriteLists).map((list) => ({
      ...list,
      displayName: createFavoriteListDisplayName(list, users),
    })),
);

export const getSelectedListItemsHref = (state: RootState): string | undefined => {
  const selectedList = getSelectedList(state);
  if (!selectedList) {
    return undefined;
  }
  return getLinkHref(selectedList, mlRel('favoriteitems'));
};

export const getSelectedListItemCollection = createCollectionSelector<
  RootState,
  FavoritesItemResource,
  'favoriteitemlist-favoriteitems'
>(
  getSelectedListItemsHref,
  (state) => state.favorites.favoriteItemCollections,
  (state) => state.favorites.favoriteItems,
);

export const getSelectedListItems = (state: RootState): ReadonlyArray<FavoritesItemResource> =>
  getSelectedListItemCollection(state).items;

export const isSelectedFavoritesListEmpty = createSelector(
  getSelectedListItems,
  (favoritesListContent) => !(favoritesListContent && favoritesListContent.length > 0),
);
const getMediaAssets = createSelector(
  getVideoAssets,
  getImageAssets,
  getAudioAssets,
  (videoAssets, imageAssets, audioAssets) => ({
    ...videoAssets.resources,
    ...imageAssets.resources,
    ...audioAssets.resources,
  }),
);

export const getSelectedListWithItems = createSelector(
  getSelectedListId,
  getListsById,
  getMediaAssets,
  getSelectedListItems,
  (selectedListId, listById, mediaAssets, selectedListItems): SelectedFavoritesListState | null => {
    const selectedList = listById[String(selectedListId)];
    if (!selectedList) {
      return null;
    }
    return {
      ...selectedList,
      items: selectedListItems.map((item) => {
        const assetHref = getLinkHref(item, mlRel('asset'));
        const asset = assetHref ? mediaAssets[assetHref] : null;
        return buildFavoritesItemState(item, asset || null);
      }),
    };
  },
);

export const getSelectedListAssetItems = createSelector(
  getSelectedListWithItems,
  (selectedList): ReadonlyArray<FavoritesItemState> => {
    if (!selectedList) {
      return [];
    }
    return _.uniqWith(
      selectedList.items.filter((item) => !item.deleted),
      (item1, item2) => item1.asset_type === item2.asset_type && item1.asset_id === item2.asset_id,
    );
  },
);

export const getSelectedListAssets = createSelector(
  getSelectedListAssetItems,
  (selectedListItems): ReadonlyArray<VideoAsset | ImageAsset | AudioAsset> => {
    if (!selectedListItems) {
      return [];
    }
    return selectedListItems
      .filter(
        (item): item is SomeNonNullable<FavoritesItemState, 'asset_type' | 'asset'> =>
          item.asset_type !== null && item.asset !== null,
      )
      .map((item) => item.asset);
  },
);

export const getOwnedFavoriteListsForUser = createSelector(
  usersSelectors.getCurrentUserURI,
  getLists,
  (currentUserURI, lists): ReadonlyArray<FavoritesListResource> =>
    lists.filter((list) => getLinkHref(list, mlRel('owner')) === currentUserURI),
);

/**
 * Get the favorite lists visible for current user (owned lists and loaded shared lists)
 */
export const getVisibleListsForUser = createSelector(
  usersSelectors.getCurrentUserURI,
  getLists,
  getSharedActiveLists,
  getOwnedFavoriteListsForUser,
  (
    currentUserURI,
    favoriteLists,
    sharedActiveLists,
    ownedFavoriteListsForUser,
  ): ReadonlyArray<FavoritesListState> => [
    ...ownedFavoriteListsForUser.map((list) => ({
      ...list,
      shared: false,
    })),
    ...getSharedActiveListsForUser(currentUserURI, favoriteLists, sharedActiveLists).map(
      (list) => ({
        ...list,
        shared: true,
      }),
    ),
  ],
);

export const isLocked = (state: RootState): boolean => {
  const selectedList = getSelectedList(state);
  return (
    !!selectedList &&
    getLinkHref(selectedList, mlRel('owner')) !== usersSelectors.getCurrentUserURI(state)
  );
};

export const getAssetChoices = createSelector(
  getSelectedListAssets,
  (selectedListAssets): ReadonlyArray<AssetChoice> =>
    selectedListAssets
      .filter((asset) => !!asset)
      .map((asset) => ({
        url: getResourceURI(asset),
        name: asset.name,
        typeName: getResourceTypeName(asset),
        isProduction: asset.is_production,
      })),
);

export const isSelectedListAssetsLoading = (state: RootState): boolean =>
  state.favorites.selectedListAssetsLoading;
