import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Archived } from 'medialoopster/icons';

import StatusElement from './StatusElement';

interface StatusElementProps {
  showLabel: boolean;
}

const renderIcon = () => <Archived style={{ fontSize: '1.2rem' }} />;

const IsArchivedStatus: FC<StatusElementProps> = ({ showLabel }: StatusElementProps) => (
  <StatusElement
    renderIcon={renderIcon}
    title={gettext('Asset is in archive')}
    label={gettext('Asset is in archive')}
    showLabel={showLabel}
  />
);
export default IsArchivedStatus;
