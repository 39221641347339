import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import { KeywordsRootState } from './types';

export default combineReducers<KeywordsRootState>({
  categories: combineReducers({
    video: createResourcesReducer('videokeywordcategory'),
    audio: createResourcesReducer('audiokeywordcategory'),
    image: createResourcesReducer('imagekeywordcategory'),
  }),
});
