import { ReactNode } from 'react';

export const ALERT_MESSAGE = 'SHARED_MODULE/ALERT_MESSAGE';
export interface AlertMessage {
  readonly type: typeof ALERT_MESSAGE;
  readonly payload: Readonly<Alert>;
}

export enum AlertFeedback {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}
export interface Alert {
  readonly message: ReactNode;
  readonly feedback?: AlertFeedback;
  readonly timeout?: number; // milliseconds
}

export const alertMessage = (
  message: ReactNode,
  feedback: AlertFeedback = AlertFeedback.Error,
  timeout = 5000,
): AlertMessage => ({
  type: ALERT_MESSAGE,
  payload: {
    feedback,
    message,
    timeout,
  },
});
