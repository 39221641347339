import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of, concat } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AlertMessage } from 'medialoopster/AlertMessage';
import {
  getLinkHref,
  mlRel,
  RECEIVE_ROOT_RESOURCE,
  ReceiveRootResource,
  ResourceLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { toggleAnnotations } from '../details/actions';
import * as detailsSelectors from '../details/selectors';
import { ToggleAnnotations } from '../details/types';
import {
  searchLoaded,
  loadShots,
  multiSelectEntry,
  rangeSelectEntries,
  selectEntry,
} from './actions';
import * as searchSelectors from './selectors';
import {
  CLICK_ENTRY,
  ClickEntry,
  FETCH_NEXT_SEARCH_RESULT,
  FETCH_SEARCH_RESULT,
  FetchNextSearchResult,
  FetchSearchResult,
  LOAD_NEXT_SHOTS,
  LOAD_SHOTS,
  SearchLoaded,
  LoadNextShots,
  LoadShots,
  MultiSelectEntry,
  RangeSelectEntries,
  SelectEntry,
} from './types';

export const fetchSearchResultEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction | SearchLoaded | ToggleAnnotations,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchResource }) =>
  action$.pipe(
    ofType<
      Action,
      (FetchSearchResult | FetchNextSearchResult | LoadShots | LoadNextShots)['type'],
      FetchSearchResult | FetchNextSearchResult | LoadShots | LoadNextShots
    >(FETCH_SEARCH_RESULT, FETCH_NEXT_SEARCH_RESULT, LOAD_SHOTS, LOAD_NEXT_SHOTS),
    withLatestFrom(state$),
    mergeMap(([{ type, payload }, state]) => {
      const shouldResetAnnotation =
        type === FETCH_SEARCH_RESULT &&
        detailsSelectors.getLayoutView(state) === 'all' &&
        detailsSelectors.showAnnotations(state);
      const annotationAction = shouldResetAnnotation ? of(toggleAnnotations(false)) : EMPTY;
      let shouldUseHalJsonV3Search = false;
      if (type === FETCH_SEARCH_RESULT) {
        const fetchPayload = payload as FetchSearchResult['payload'];
        if (fetchPayload.assetTypeName === 'collection') {
          // TODO if embedded resources are optional requests needs to ask for embedded resource <PB: ML-3333>
          shouldUseHalJsonV3Search = true;
        }
      }
      return concat(
        annotationAction,
        fetchResource(
          payload.url,
          () => of(searchLoaded()),
          () => of(searchLoaded()),
          ({ response, xhr }) => ({
            ...response,
            isFallbackSearch: xhr.getResponseHeader('X-Is-Fallback-Search') === 'True',
          }),
          shouldUseHalJsonV3Search ? { Accept: 'application/hal+json; version=3' } : undefined,
        ),
      );
    }),
  );

export const clickEntryEpic: Epic<
  Action,
  RangeSelectEntries | MultiSelectEntry | SelectEntry | LoadShots | AlertMessage,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, ClickEntry['type'], ClickEntry>(CLICK_ENTRY),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: {
            assetId,
            assetTypeName,
            assetHref,
            withShiftKey,
            withAltKey,
            withMetaKey,
            withCtrlKey,
          },
        },
        state,
      ]) => {
        const currentAssetState = detailsSelectors.getCurrentAssetState(state);
        const entries = searchSelectors.getEntries(state);
        // if collection multiselect is re-needed in the future, then changes in download action menu are required (see ML-3630 and ML-3640)
        if (withShiftKey) {
          return assetTypeName !== 'collection'
            ? of(rangeSelectEntries(assetId, assetTypeName, assetHref, currentAssetState, entries))
            : EMPTY;
        }
        if (withAltKey || withMetaKey || withCtrlKey) {
          return assetTypeName !== 'collection'
            ? of(multiSelectEntry(assetId, assetTypeName, assetHref, currentAssetState, entries))
            : EMPTY;
        }
        if (assetHref !== currentAssetState.href) {
          return of(selectEntry(assetId, assetTypeName, assetHref));
        }
        if (assetTypeName !== 'videoasset') {
          return EMPTY;
        }
        const clickedAsset = entries.filter((asset) => asset.id === assetId)[0];
        if (!clickedAsset) {
          return EMPTY;
        }
        return EMPTY;
      },
    ),
  );

export const loadShotsForCurrentAssetEpic: Epic<Action, LoadShots, RootState> = (
  _action$,
  state$,
) =>
  state$.pipe(
    map(searchSelectors.getShotSearchURL),
    filter((shotSearchURL): shotSearchURL is NonNullable<string> => !!shotSearchURL),
    distinctUntilChanged(),
    map(loadShots),
  );

export const fetchDeviceFiltersEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const url = getLinkHref(root, mlRel('search/devicefilters'));
      if (!url) {
        return EMPTY;
      }
      return fetchCollection(url);
    }),
  );

export default combineEpics<
  Action,
  | ResourceLoadedAction
  | LoadShots
  | RangeSelectEntries
  | MultiSelectEntry
  | SelectEntry
  | UnauthorizedRequestAction
  | SearchLoaded
  | ToggleAnnotations
  | AlertMessage,
  RootState
>(clickEntryEpic, fetchSearchResultEpic, loadShotsForCurrentAssetEpic, fetchDeviceFiltersEpic);
