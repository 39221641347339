export const URL_FAVORITE_LISTS = '/api/favoriteitemlists/';
export const URL_PRODUCTIONS = '/api/productions/';
export const URL_ARCHIVE_ASSETS = '/api/archiveactivities/';
export const URL_REVOKE_ARCHIVE_ACTIVITIES = '/api/archiveactivities/revoke/';
export const URL_RESTORE_ARCHIVED_ASSETS = '/api/restoreactivities/';
export const URL_PERSONS = '/api/persons/';
export const URL_FACES = '/api/faces/';
export const URL_SEGMENTS = '/api/segments/';
export const URL_TRANSCRIPTS = '/api/transcripts/';

// TODO implement api to get browser.share_production_asset permission <JR t:ML-2381>
export const URL_PERMISSION_SHARE_PRODUCTION_ASSET =
  '/api/check-permission/browser/share_production_asset/';
