import { Alert, AlertTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}));

const FallbackSearchWarning: FC = () => {
  const classes = useStyles();
  return (
    <Alert severity="warning" className={classes.root}>
      <AlertTitle>{gettext('Attention! Fallback search is active!')}</AlertTitle>
      <Typography display="inline">
        {gettext('The advanced search engine is currently unavailable.')}
      </Typography>
      &nbsp;
      <Typography variant="body2" display="inline">
        {gettext('Only limited searching possible.')}
      </Typography>
    </Alert>
  );
};
export default FallbackSearchWarning;
