import { ReadonlyRecord } from 'medialoopster/types';

export type SearchQuery = ReadonlyRecord<string, string | number | ReadonlyArray<string>>;

export default class SearchQueryService {
  static fromQuery(query: SearchQuery): string {
    const removableKeys: string[] = [];
    const filteredParams = Object.entries(query)
      .filter(([, value]) => !Array.isArray(value) || value.length > 0)
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          if (key === 'device_filter') {
            return value.map((device) => ({ key, value: device }));
          }
          removableKeys.push(key);
          if (!value[1]) {
            return { key, value: '' };
          }
          return [
            { key: `${key}.lookup`, value: value[0] },
            { key: `${key}.value`, value: value[1] },
          ];
        }
        if (value === 'None') {
          removableKeys.push(key);
        }
        if (key.endsWith('.start')) {
          removableKeys.push(key.slice(0, -6));
        }
        if (key.endsWith('.min')) {
          removableKeys.push(key.slice(0, -4));
        }
        return { key, value };
      })
      .filter(({ key }) => !removableKeys.includes(key));

    return filteredParams
      .map(({ key, value }) => `${key}=${encodeURIComponent(value.toString())}`)
      .join('&');
  }
}
