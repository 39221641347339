import { SELECT_PRODUCTION, SelectProduction, FETCH_PRODUCTIONS, FetchProductions } from './types';

export const fetchProductions = (): FetchProductions => ({
  type: FETCH_PRODUCTIONS,
});

export const selectProduction = (productionURL: string | null): SelectProduction => ({
  type: SELECT_PRODUCTION,
  payload: {
    productionURL,
  },
});
