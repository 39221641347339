import { Link } from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

export const FIND_ADDABLE_COLLECTIONS =
  'FRONTEND/ADD_ASSETS_TO_COLLECTION_MENU/FIND_ADDABLE_COLLECTIONS';
export const SET_CURRENT_ADDABLE_COLLECTION_SEARCH_URI =
  'FRONTEND/ADD_ASSETS_TO_COLLECTION_MENU/SET_CURRENT_ADDABLE_COLLECTION_SEARCH_URI';

export const ADD_ASSETS_TO_COLLECTION =
  'FRONTEND/ADD_ASSETS_TO_COLLECTION_MENU/ADD_ASSETS_TO_COLLECTION';

export const ADD_ASSETS_TO_COLLECTION_API_RESPONSE =
  'FRONTEND/ADD_ASSETS_TO_COLLECTION_MENU/ADD_ASSETS_TO_COLLECTION_API_RESPONSE';
export const RESET_MODAL_API_FEEDBACK =
  'FRONTEND/ADD_ASSETS_TO_COLLECTION_MENU/RESET_MODAL_API_FEEDBACK';
export const CHECK_ASSETS_COLLECTIONS_AVAILABILITY =
  'FRONTEND/ADD_ASSETS_TO_COLLECTION_MENU/CHECK_ASSETS_COLLECTIONS_AVAILABILITY';
export const SET_AVAILABLE_PRODUCTION_SEARCH_URIS =
  'FRONTEND/ADD_ASSETS_TO_COLLECTION_MENU/SET_AVAILABLE_PRODUCTION_SEARCH_URIS';
export interface FindAddableCollection {
  readonly type: typeof FIND_ADDABLE_COLLECTIONS;
  readonly payload: {
    readonly name: string;
    readonly production: string;
  };
}

export interface SetCurrentAddableCollectionSearchURI {
  readonly type: typeof SET_CURRENT_ADDABLE_COLLECTION_SEARCH_URI;
  readonly payload: {
    readonly searchURI: string;
  };
}

export interface ModalState {
  searchURI: string | null;
  errors: ReadonlyRecord<string, ReadonlyArray<string>>;
  success: boolean | null;
  isOpen: boolean;
}

export interface MenuState {
  availableProductionSearchURIs: ReadonlyArray<string>;
  error: null | string;
}
export interface AddAssetsToCollectionRootState {
  readonly menu: MenuState;
  readonly modal: ModalState;
}

export interface AddableCollection {
  display: string;
  href: string;
  addImageAssetsLink: Link | undefined;
  addVideoAssetsLink: Link | undefined;
  addAudioAssetsLink: Link | undefined;
}

export interface AddableAsset {
  display: string;
  value: string;
  type: string;
}

export interface AddAssetsToCollectionApiResponse {
  type: typeof ADD_ASSETS_TO_COLLECTION_API_RESPONSE;
  payload: {
    success: boolean | null;
    errors: ReadonlyRecord<string, ReadonlyArray<string>>;
  };
}
export interface AddAssetsToCollection {
  readonly type: typeof ADD_ASSETS_TO_COLLECTION;
  readonly payload: {
    readonly addableCollection: AddableCollection;
    readonly addableAssets: ReadonlyArray<AddableAsset>;
  };
}

export interface ResetModalApiFeedback {
  readonly type: typeof RESET_MODAL_API_FEEDBACK;
}

export interface CheckAssetCollectionAvailabilityForProduction {
  readonly type: typeof CHECK_ASSETS_COLLECTIONS_AVAILABILITY;
}
export interface SetAvailableProductionSearchURIs {
  readonly type: typeof SET_AVAILABLE_PRODUCTION_SEARCH_URIS;
  readonly payload: {
    availableProductionSearchURIs: ReadonlyArray<string>;
    error?: string | null;
  };
}
