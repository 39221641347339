import { LoadingButton } from '@mui/lab';
import { Stack, Container, Box, Alert, Typography } from '@mui/material';
import * as React from 'react';
import { FC, FormEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { EmailInput, PasswordInput } from 'medialoopster/components';

import { loginActions, loginSelectors } from '../state/login';

type Props = {
  readonly staticPrefix?: string;
};

const LogIn: FC<Props> = ({ staticPrefix = '/static' }: Props) => {
  const isLoggingIn = useSelector(loginSelectors.isLoggingIn);
  const alert = useSelector(loginSelectors.getAlert);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const dispatch = useDispatch();

  const handleChangeUsername = useCallback(
    (value) => {
      setUsername(value);
      dispatch(loginActions.clearAlert());
    },
    [setUsername, dispatch],
  );

  const handleChangePassword = useCallback(
    (value) => {
      setPassword(value);
      dispatch(loginActions.clearAlert());
    },
    [setPassword, dispatch],
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(loginActions.loginRequest(username, password));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack alignItems="center" spacing={6} mt="20%">
        <Box pl={5.5}>
          <img
            src={`${staticPrefix}/img/logo_05.svg`}
            alt="medialoopster"
            style={{ height: '52px', width: 'auto' }}
            draggable={false}
          />
        </Box>
        <Typography variant="h3">{gettext('Log In')}</Typography>
        <Stack component="form" onSubmit={handleSubmit} spacing={3} width="75%">
          <Box>
            <EmailInput id="email" autoFocus onChange={handleChangeUsername} />
          </Box>
          <Box>
            <PasswordInput id="password" onChange={handleChangePassword} />
          </Box>
          <LoadingButton
            aria-label={gettext('Log In')}
            type="submit"
            fullWidth
            variant="contained"
            loading={isLoggingIn}
            color="primary"
          >
            <span>{gettext('Log In')}</span>
          </LoadingButton>
          {alert.message && (
            <Alert variant="outlined" severity="error">
              {alert.message}
            </Alert>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default LogIn;
