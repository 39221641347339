import { FormatSettings } from './types';

// see http://ml-develop.local:8000/jsi18n/ for available functions
export const gettext = (message: string): string => django.gettext(message);
export const interpolate = (message: string, context: { [key: string]: string | number }): string =>
  django.interpolate(message, context, true);
export const pgettext = (context: string, message: string): string =>
  django.pgettext(context, message);
export const npgettext = (
  context: string,
  singular: string,
  plural: string,
  count: number,
): string => django.npgettext(context, singular, plural, count);
export const ngettext = (singular: string, plural: string, count: number): string =>
  django.ngettext(singular, plural, count);
export const getFormat = (formatSetting: keyof FormatSettings): string | string[] | number =>
  django.get_format(formatSetting);
