import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo, useLayoutEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, interpolate } from 'medialoopster/Internationalization';
import { IconButton } from 'medialoopster/components';
import { AssetLink, RemoveLink } from 'medialoopster/icons';
import {
  Link,
  LinkPermission,
  getLink,
  getLinkHref,
  getLinkPermission,
  getResourceTypeName,
  getResourceURI,
} from 'medialoopster/rest';

import { getShareURL } from '../../businessRules/services/getShareURL';
import { detailsActions, detailsSelectors } from '../../state/modules/details';
import { AssetCollection, isAssetCollection } from '../../state/modules/rest/collection/types';
import { AssetType, MediaAssetResource } from '../../state/types/asset/baseTypes';
import AssetIcon from '../../ui/components/AssetIcon';
import { useTextOverflow } from '../../ui/hooks/useTextOverflow';

const useStyles = makeStyles(() => ({
  cell: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  iconCell: {
    textAlign: 'center',
  },
  titleCell: {
    paddingLeft: '4px',
    paddingRight: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '15vw',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  disabledCell: {
    opacity: 0.5,
  },
  row: {
    cursor: 'pointer',
  },
}));

type RowProps = {
  readonly asset: MediaAssetResource;
  readonly selected: boolean;
};

/**
 * Check if asset is external or internal
 *
 * Note collections have default empty bundle root,
 * for which all assets are external
 */
const isExternalAsset = (
  assetPathFile: string | undefined,
  collectionBundleRoot: string | undefined,
) => {
  return collectionBundleRoot === '' || !assetPathFile?.startsWith(`${collectionBundleRoot}`);
};

const getAssetCollectionRemoveLink = (
  assetType: string,
  coll: AssetCollection,
): Link | undefined => {
  if (assetType === 'imageasset') {
    return getLink(coll, 'remove_image_assets');
  }
  if (assetType === 'audioasset') {
    return getLink(coll, 'remove_audio_assets');
  }
  if (assetType === 'videoasset') {
    return getLink(coll, 'remove_video_assets');
  }
  return undefined;
};

const getAssetCollectionRemoveLinkPermission = (
  assetType: string,
  coll: AssetCollection,
): LinkPermission | undefined => {
  const assetCollectionRemoveLink = getAssetCollectionRemoveLink(assetType, coll);
  if (assetCollectionRemoveLink) {
    const postPermission = getLinkPermission(assetCollectionRemoveLink, 'POST');
    return postPermission;
  }
  return undefined;
};

const getCanRemoveLinkedAssetCollection = (assetType: string, coll: AssetCollection): boolean => {
  const assetCollectionRemovePermission = getAssetCollectionRemoveLinkPermission(assetType, coll);
  return assetCollectionRemovePermission?.code === 'ok';
};

const getRemoveLinkedAssetCollectionMessage = (
  assetType: string,
  coll: AssetCollection,
): string => {
  const assetCollectionRemovePermission = getAssetCollectionRemoveLinkPermission(assetType, coll);
  return assetCollectionRemovePermission?.message || gettext('Cannot remove linked asset');
};

const Row = memo(({ asset, selected }: RowProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const assetNameCellRef = useRef<HTMLDivElement>(null);
  const [showTooltip, checkShowTooltip] = useTextOverflow(assetNameCellRef);
  const isCurrentAssetLockedByCurrentUser = useSelector(
    detailsSelectors.isCurrentAssetLockedByCurrentUser,
  );
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);

  const collection = isAssetCollection(currentAsset) ? currentAsset : null;

  const handleRemoveAssetLink = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!collection) return;

    const assetType = getResourceTypeName(asset);
    const assetHref = getResourceURI(asset);
    const collectionURI = getResourceURI(collection);
    const assetCollectionMediaAssetHref = getLinkHref(collection, `${assetType}s`);
    const removeAssetFromCollectionURI = getAssetCollectionRemoveLink(assetType, collection)?.href;
    const shareURL = getShareURL(asset.id, getResourceTypeName(asset));
    if (removeAssetFromCollectionURI && assetCollectionMediaAssetHref) {
      dispatch(
        detailsActions.removeAssetFromCollection(
          collectionURI,
          removeAssetFromCollectionURI,
          assetCollectionMediaAssetHref,
          assetHref,
          shareURL,
        ),
      );
    }
  };

  const [removeDisabled, removeTitle] = useMemo((): [boolean, string] => {
    if (isAssetCollection(collection)) {
      const assetType = getResourceTypeName(asset);
      const canRemove = getCanRemoveLinkedAssetCollection(assetType, collection);
      return [
        !canRemove,
        canRemove
          ? gettext('Remove linked asset')
          : getRemoveLinkedAssetCollectionMessage(assetType, collection),
      ];
    }
    return [true, gettext('Unsupported asset type')];
  }, [collection, asset]);

  const isExternalAssetPath = useMemo(
    () => isExternalAsset(asset.path_file, collection?.bundle_root),
    [collection, asset],
  );

  useLayoutEffect(() => {
    if (selected) {
      assetNameCellRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [assetNameCellRef, selected]);
  return (
    <TableRow
      key={getResourceURI(asset)}
      hover
      selected={selected}
      className={classes.row}
      onClick={() =>
        dispatch(detailsActions.setEmbeddedAsset(getResourceTypeName(asset), getResourceURI(asset)))
      }
    >
      <TableCell
        className={clsx(classes.cell, classes.iconCell, {
          [classes.disabledCell]: !asset.is_available,
        })}
        style={{ maxWidth: '50px' }}
      >
        <img draggable={false} alt={asset.name} src={asset.thumbnail_url} width="50" />
      </TableCell>
      <TableCell
        className={clsx(classes.cell, classes.iconCell, {
          [classes.disabledCell]: !asset.is_available,
        })}
        style={{ maxWidth: '24px' }}
      >
        <AssetIcon typeName={getResourceTypeName(asset)} size="small" />
      </TableCell>
      <TableCell
        className={clsx(classes.cell, classes.titleCell, {
          [classes.disabledCell]: !asset.is_available,
        })}
        onMouseOver={checkShowTooltip}
        ref={assetNameCellRef}
      >
        <Tooltip title={showTooltip ? asset.name : ''}>
          <small>{asset.name}</small>
        </Tooltip>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.iconCell)} style={{ maxWidth: '32px' }}>
        <IconButton
          title={gettext('Show asset')}
          aria-label={gettext('Link to asset')}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            dispatch(
              detailsActions.setCurrentAsset(getResourceTypeName(asset), getResourceURI(asset)),
            );
          }}
        >
          <AssetLink />
        </IconButton>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.iconCell)} style={{ maxWidth: '32px' }}>
        {isCurrentAssetLockedByCurrentUser && isExternalAssetPath && (
          <IconButton
            title={removeTitle}
            disabled={removeDisabled}
            aria-label={gettext('Remove link')}
            onClick={handleRemoveAssetLink}
          >
            <RemoveLink />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
});

type InaccessibleAssetsRowProps = {
  readonly typeName: AssetType;
  readonly assetCount: number;
};

const InaccessibleAssetsRow = ({ typeName, assetCount }: InaccessibleAssetsRowProps) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell
        className={clsx(classes.cell, classes.iconCell, classes.disabledCell)}
        style={{ maxWidth: '50px' }}
      >
        <img src={'/static/img/default_shot.png'} width="50" />
      </TableCell>
      <TableCell
        className={clsx(classes.cell, classes.iconCell, classes.disabledCell)}
        style={{ maxWidth: '24px' }}
      >
        <AssetIcon typeName={typeName} size="small" />
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.titleCell, classes.disabledCell)}>
        <small>
          {assetCount > 1
            ? interpolate(gettext('No permission to use %(assetCount)s assets.'), {
                assetCount,
              })
            : gettext('No permission to use this asset.')}
        </small>
      </TableCell>
      <TableCell
        className={clsx(classes.cell, classes.iconCell)}
        style={{ maxWidth: '32px' }}
      ></TableCell>
    </TableRow>
  );
};

const LinkedAssets: FC = () => {
  const embeddedAssetHref = useSelector(detailsSelectors.getEmbeddedAssetHref);
  const assets = useSelector(detailsSelectors.getCurrentCollectionContent);
  const inaccessibleVideoAssetCount = useSelector(detailsSelectors.getInaccessibleVideoAssetCount);
  const inaccessibleImageAssetCount = useSelector(detailsSelectors.getInaccessibleImageAssetCount);
  const inaccessibleAudioAssetCount = useSelector(detailsSelectors.getInaccessibleAudioAssetCount);
  if (
    assets.length +
      inaccessibleVideoAssetCount +
      inaccessibleImageAssetCount +
      inaccessibleAudioAssetCount ===
    0
  ) {
    return null;
  }
  return (
    <Table size="small" style={{ marginBottom: '64px' }}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={5}>
            <Typography variant="body2">
              <strong>{gettext('Assets used in collection')}</strong>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assets.map((asset) => (
          <Row
            key={getResourceURI(asset)}
            asset={asset}
            selected={embeddedAssetHref === getResourceURI(asset)}
          />
        ))}
        {inaccessibleVideoAssetCount > 0 && (
          <InaccessibleAssetsRow typeName={'videoasset'} assetCount={inaccessibleVideoAssetCount} />
        )}
        {inaccessibleImageAssetCount > 0 && (
          <InaccessibleAssetsRow typeName={'imageasset'} assetCount={inaccessibleImageAssetCount} />
        )}
        {inaccessibleAudioAssetCount > 0 && (
          <InaccessibleAssetsRow typeName={'audioasset'} assetCount={inaccessibleAudioAssetCount} />
        )}
      </TableBody>
    </Table>
  );
};
export default LinkedAssets;
