import pointer from 'json-pointer';
import _ from 'lodash';
import { useObservable, useObservableState } from 'observable-hooks';
import { concat, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  getLinkHref,
  getResourceURI,
  isAPIFieldError,
  isAPINonFieldError,
  Resource,
  RESTError,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginTypes } from 'medialoopster/state/login';
import { ReadonlyRecord } from 'medialoopster/types';

import { AssetChoiceWithError } from '../../../../../state/modules/operations/types';

interface DownloadActivity extends Resource<'downloadactivity'> {
  readonly status: number;
  readonly download_url: string;
}

export interface BulkDownloadState {
  readonly isFinished: boolean;
  readonly hasError: boolean;
  readonly downloadHref?: string;
  readonly monitoringHref?: string;
  readonly assetErrors: ReadonlyArray<AssetChoiceWithError>;
  readonly downloadError?: string;
  readonly isAuthorized?: boolean;
}

const poll = (
  activity: DownloadActivity,
  open$: Observable<[boolean, ReadonlyArray<AssetChoiceWithError>]>,
  token: loginTypes.Token,
): Observable<BulkDownloadState> =>
  open$.pipe(
    filter(([open]) => open),
    delay(1000),
    mergeMap(([, assetErrors]) =>
      ajax
        .get<DownloadActivity>(getResourceURI(activity), {
          ...getTokenAuthHeader(token),
          Accept: 'application/hal+json; version=3',
          'Cache-Control': 'no-cache',
        })
        .pipe(
          mergeMap(({ response }) => {
            let result: BulkDownloadState = {
              isFinished: true,
              hasError: response.status !== 2,
              monitoringHref: getLinkHref(response, 'monitoring_url'),
              assetErrors,
              isAuthorized: true,
            };

            if (response.status === 2) {
              result = {
                ...result,
                downloadHref: response.download_url,
                hasError: false,
              };
            } else if (response.status === 0 || response.status === 1) {
              return poll(activity, open$, token);
            }

            return of(result);
          }),
          catchError((err) => {
            const result: BulkDownloadState = {
              isFinished: true,
              hasError: true,
              monitoringHref: getLinkHref(activity, 'monitoring_url'),
              assetErrors,
            };

            if (err && err.status === 401) {
              return of({
                ...result,
                isAuthorized: false,
              });
            }

            return of(result);
          }),
        ),
    ),
  );

const prepareBulkDownload = (token: loginTypes.Token, downloadActivitiesURL: string) => {
  const prepare = (
    input$: Observable<[boolean, ReadonlyArray<AssetChoiceWithError>]>,
  ): Observable<BulkDownloadState> =>
    input$.pipe(
      switchMap(([isOpen, assetChoices]) => {
        if (!isOpen) {
          return of({
            downloadHref: '',
            isFinished: false,
            hasError: false,
            assetErrors: [],
            isAuthorized: true,
          });
        }
        const assetChoicesWithoutErrors = assetChoices.filter(({ error }) => !error);
        const assetChoicesWithErrors = assetChoices.filter(({ error }) => !!error);
        return ajax
          .post<DownloadActivity>(
            downloadActivitiesURL,
            {
              _links: {
                // TODO: use `collection` key for the Collection (ML-3685)
                assets: assetChoicesWithoutErrors.map(({ url }) => ({ href: url })),
              },
            },
            {
              ...getTokenAuthHeader(token),
              Accept: 'application/hal+json; version=3',
              'Content-Type': 'application/hal+json; version=3',
            },
          )
          .pipe(
            mergeMap(({ response }) => {
              return concat(
                of({
                  isFinished: false,
                  hasError: false,
                  downloadHref: response.download_url,
                  monitoringHref: getLinkHref(response, 'monitoring_url'),
                  assetErrors: assetChoicesWithErrors,
                  isAuthorized: true,
                }),
                poll(response, input$.pipe(map(([open]) => [open, assetChoicesWithErrors])), token),
              );
            }),
            catchError((errorFull) => {
              if (errorFull && errorFull.status === 401) {
                return of({
                  isFinished: true,
                  hasError: true,
                  assetErrors: assetChoicesWithErrors,
                  isAuthorized: false,
                });
              }
              const { response, request }: RESTError = errorFull;
              if (!response || !request) {
                return of({
                  isFinished: true,
                  hasError: true,
                  assetErrors: assetChoicesWithErrors,
                  isAuthorized: true,
                });
              }
              const requestBody = JSON.parse(request.body);
              const errorsByAssetURL: ReadonlyRecord<string, string> = Object.fromEntries(
                response.errors
                  ?.map((error) => {
                    if (!isAPIFieldError(error)) {
                      return [null, null];
                    }
                    const assetLink = pointer.get(requestBody, error.source.pointer);
                    if (!assetLink) {
                      return [null, null];
                    }
                    const assetURL = assetLink.href;
                    const assetChoice = assetChoices.find(({ url }) => url === assetURL);
                    if (!assetChoice) {
                      return [null, null];
                    }
                    return [assetURL, error.detail];
                  })
                  .filter((errorEntry): errorEntry is [string, string] => !!errorEntry[0]) || [],
              );
              const downloadError = response.errors
                ?.map((error) => {
                  if (!isAPINonFieldError(error)) {
                    return undefined;
                  }
                  return error.detail;
                })
                .filter((err) => !!err)
                .pop();
              if (
                _.isEmpty(errorsByAssetURL) ||
                downloadError ||
                Object.keys(errorsByAssetURL).length === assetChoices.length
              ) {
                // No assets or all assets have errors: Do not retry.
                return of({
                  isFinished: true,
                  hasError: true,
                  assetErrors: [
                    ...assetChoicesWithErrors,
                    ...assetChoices.map((assetChoice) => ({
                      ...assetChoice,
                      error: errorsByAssetURL[assetChoice.url],
                    })),
                  ],
                  downloadError,
                });
              }
              return prepare(
                input$.pipe(
                  map(([open, retryAssetChoices]) => [
                    open,
                    retryAssetChoices.map((assetChoice) => ({
                      ...assetChoice,
                      error: errorsByAssetURL[assetChoice.url] || assetChoice.error, // to maintain original errors passed from the component, such as "Collection has no files"
                    })),
                  ]),
                ),
              );
            }),
          );
      }),
    );
  return prepare;
};

const useBulkDownload = (
  token: loginTypes.Token,
  downloadActivitiesURL: string,
  open: boolean,
  assetChoices: ReadonlyArray<AssetChoiceWithError>,
): BulkDownloadState => {
  return useObservableState(
    useObservable<BulkDownloadState, [boolean, ReadonlyArray<AssetChoiceWithError>]>(
      prepareBulkDownload(token, downloadActivitiesURL),
      [open, assetChoices],
    ),
    {
      downloadHref: '',
      isFinished: false,
      hasError: false,
      assetErrors: [],
      downloadError: undefined,
      isAuthorized: true,
    },
  );
};
export default useBulkDownload;
