import LinearProgress from '@mui/material/LinearProgress';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useObservableEagerState } from 'observable-hooks';
import { FC, useState, useEffect } from 'react';
import shaka from 'shaka-player';

import { useVideoController } from './VideoControllerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    transform: 'rotate(180deg)',
    zIndex: 2,
    '&.MuiLinearProgress-root': { backgroundColor: 'transparent' },
    '&>.MuiLinearProgress-bar': {
      backgroundColor: alpha(theme.palette.common.black, 0.2),
      background: `repeating-linear-gradient(-45deg,transparent,transparent 10px,${alpha(
        theme.palette.common.black,
        0.3,
      )} 10px,${alpha(theme.palette.common.black, 0.3)} 20px)`,
    },
    pointerEvents: 'none',
    float: 'right',
  },
}));

const getLastSegment = (
  manifest: shaka.extern.Manifest | null,
  i = 0,
  segment: shaka.media.SegmentReference | null = null,
): shaka.media.SegmentReference | null => {
  const nextSegment = manifest?.variants?.[0]?.video?.segmentIndex?.get(i);
  if (nextSegment) return getLastSegment(manifest, i + 1, nextSegment);
  return segment;
};

const getSegments = (
  manifest: shaka.extern.Manifest | null,
  i = 0,
  segments: Array<shaka.media.SegmentReference> = [],
): Array<shaka.media.SegmentReference> => {
  const nextSegment = manifest?.variants?.[0]?.video?.segmentIndex?.get(i);
  if (nextSegment) return getSegments(manifest, i + 1, [...segments, nextSegment]);
  return segments;
};

interface Props {
  readonly assetDuration: number;
}

const MpdProxyLoader: FC<Props> = ({ assetDuration }: Props) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [wasLive, setWasLive] = useState(false);

  const videoController = useVideoController();

  const duration = useObservableEagerState(videoController.duration$);
  const manifest = useObservableEagerState(videoController.manifest$);

  useEffect(() => {
    let lastMax = Infinity;
    const timer = setInterval(() => {
      if (
        wasLive &&
        manifest &&
        manifest.presentationTimeline &&
        !manifest?.presentationTimeline?.isLive() &&
        videoController
      ) {
        // switch from live video to normal video and reset progress
        clearInterval(timer);
        setWasLive(false);
        setProgress(0);
        const maxPlayTime = manifest?.presentationTimeline?.getDuration() || Infinity;
        videoController.setMaxTime(maxPlayTime);
        if (duration !== maxPlayTime) {
          videoController.setDuration(maxPlayTime);
        }
      } else if (manifest?.presentationTimeline?.isLive()) {
        setWasLive(true);
        if (duration !== assetDuration) {
          videoController.setDuration(assetDuration);
        }
        const segments = getSegments(manifest);
        const maxTime = getLastSegment(manifest)?.getEndTime() || 0;
        manifest?.presentationTimeline.notifySegments(segments);
        manifest?.presentationTimeline.notifyMaxSegmentDuration(maxTime);
        if (lastMax !== maxTime) {
          lastMax = maxTime;
          const prog = Math.round((maxTime / duration) * 100 * 10) / 10;
          setProgress(Math.max(0, 100 - prog));
          videoController.setMaxTime(maxTime);
        }
      } else {
        clearInterval(timer);
      }
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, [manifest, videoController, wasLive, setWasLive, assetDuration, duration]);
  return (
    <>
      {manifest && (
        <LinearProgress className={classes.root} variant="determinate" value={progress} />
      )}
    </>
  );
};

export default MpdProxyLoader;
