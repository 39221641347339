import { combineReducers } from 'redux';

import { createEmbeddedItemsResourceModuleReducerMap, mlRel } from 'medialoopster/rest';

import { CollectionSearchResultRootState } from './types';

export default combineReducers<CollectionSearchResultRootState>(
  createEmbeddedItemsResourceModuleReducerMap(
    'collectionsearchresult',
    mlRel('search/collections'),
  ),
);
