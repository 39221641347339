import { createSelector } from 'reselect';

import { ConnectorResource } from 'medialoopster/modules';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { detailsSelectors } from '../details';
import { isAssetCollection } from '../rest/collection/types';
import { searchSelectors } from '../search';
import { SearchEntryAssetList } from '../search/types';
import { AssetChoice, AssetExchangeConnector, Webhook } from './types';

export const convertAssetToAssetChoice = (asset: Asset): AssetChoice => {
  return {
    url: getResourceURI(asset),
    name: asset.name,
    typeName: getResourceTypeName(asset),
    isProduction: asset.is_production,
  };
};

export const getWebhooks = (state: RootState): ReadonlyArray<Webhook> => state.operations.webhooks;

export const getAssetExchangeConnectors = createSelector(
  (state: RootState): ReadonlyArray<ConnectorResource<'assetexchangeconnector'>> =>
    state.operations.assetExchangeConnectors,
  (state: RootState): ReadonlyRecord<string, boolean> => state.operations.isAssetExchangeRemove,
  (assetExchangeConnectors, isAssetExchangeRemove): ReadonlyArray<AssetExchangeConnector> =>
    assetExchangeConnectors.map((connector) => ({
      ...connector,
      isRemove: isAssetExchangeRemove[getResourceURI(connector)] || false,
    })),
);

export const getSelectedAssets = createSelector(
  searchSelectors.getSelection,
  searchSelectors.getEntries,
  detailsSelectors.getCurrentAsset,
  ({ assetIds }, entries, currentAsset): SearchEntryAssetList => {
    const selectedAssets = entries.filter(({ id }) => assetIds.includes(id));
    if (selectedAssets.length === 0 && currentAsset) {
      return [currentAsset];
    }
    return selectedAssets;
  },
);

/**
 *  Determine if the bulk selected assets represent the currently loaded collection.
 */
export const isOnlyCurrentAssetCollectionSelected = createSelector(
  getSelectedAssets,
  detailsSelectors.getCurrentAsset,
  (selectedAssets, currentAsset): boolean =>
    isAssetCollection(currentAsset) &&
    selectedAssets.length === 1 &&
    selectedAssets[0] === currentAsset,
);

/**
 * Get `ReadonlyArray<AssetChoice>` from the currently selected assets in the search results.
 * If there is only 1 selected asset, which is also the currently loaded collection, then return its content.
 * Otherwise, return the selected assets.
 */
export const getAssetChoices = createSelector(
  getSelectedAssets,
  isOnlyCurrentAssetCollectionSelected,
  detailsSelectors.getCurrentCollectionContent,
  (selectedAssets, isCurrentAssetCollection, collectionContent): ReadonlyArray<AssetChoice> =>
    isCurrentAssetCollection
      ? collectionContent.map((asset) => convertAssetToAssetChoice(asset))
      : selectedAssets.map((asset) => convertAssetToAssetChoice(asset)),
);

/**
 * Convert to `AssetChoice` the currently selected collection in the search results (if it is also current asset).
 * It is not the collection's content, but the collection itself.
 */
export const getCollectionChoice = createSelector(
  detailsSelectors.getCurrentAsset,
  isOnlyCurrentAssetCollectionSelected,
  (asset, isCurrentAssetCollection): AssetChoice | null =>
    asset && isCurrentAssetCollection ? convertAssetToAssetChoice(asset) : null,
);
