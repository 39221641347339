import Dialog, { DialogProps } from '@mui/material/Dialog';
import { FC, ReactNode } from 'react';

import ModalBody from './ModalBody';
import ModalCard from './ModalCard';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

type Props = {
  id?: string;
  className?: string;
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  labelId?: string;
};

type SubComponents = {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
  Card: typeof ModalCard;
};

const Modal: FC<Props> & SubComponents = ({
  id,
  open,
  onClose,
  children,
  className,
  maxWidth = 'sm',
  labelId,
}: Props) => (
  <Dialog
    aria-labelledby={labelId}
    id={id}
    open={open}
    onClose={onClose}
    className={className}
    maxWidth={maxWidth}
  >
    {children}
  </Dialog>
);

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Card = ModalCard;
Modal.Footer = ModalFooter;

export default Modal;
