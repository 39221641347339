import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Choice } from 'medialoopster/rest';
import { loginSelectors } from 'medialoopster/state/login';
import { ReadonlyRecord } from 'medialoopster/types';

import useApiOptions from '../../../businessRules/services/useApiOptions';
import { URL_SEARCHFIELDMAPPINGS } from './constants';

const getFieldLookupChoices = (
  data: ReadonlyArray<Choice<string | number>>,
): ReadonlyRecord<string | number, string> =>
  data.reduce(
    (allChoices, choices) => ({
      ...allChoices,
      [choices.value]: choices.display_name,
    }),
    {},
  );

export type SearchFieldOptions = {
  readonly fieldLookups: ReadonlyRecord<string | number, string>;
};

const useSearchFieldMappingOptions = (): SearchFieldOptions => {
  const [fieldOptions, setFieldOptions] = useState({ fieldLookups: {} });
  const token = useSelector(loginSelectors.getToken);

  const searchFieldMappingOptions = useApiOptions(URL_SEARCHFIELDMAPPINGS, token);
  useEffect(() => {
    if (!_.isEmpty(searchFieldMappingOptions)) {
      const { actions } = searchFieldMappingOptions;
      const choices = actions.GET?.field_lookups?.choices || [];
      setFieldOptions({ fieldLookups: getFieldLookupChoices(choices) });
    }
  }, [searchFieldMappingOptions]);
  return fieldOptions;
};
export default useSearchFieldMappingOptions;
