import { FC, useState } from 'react';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { Input, Modal } from 'medialoopster/components';

import { favoritesTypes } from '../../../state/modules/favorites';
import { usersTypes } from '../../../state/modules/users';
import MultipleChoiceSelect from '../../../ui/components/MultipleChoiceSelect';
import UserSelect from '../../../ui/components/UserSelect';

export const filterLists = (
  lists: ReadonlyArray<
    Pick<favoritesTypes.SharedFavoritesListState, 'displayName' | 'id' | 'owner'>
  >,
  filterString: string,
  filterUserId: number,
): ReadonlyArray<Pick<favoritesTypes.SharedFavoritesListState, 'displayName' | 'id'>> => {
  let filterList = lists;
  if (filterString) {
    filterList = filterList.filter((list) =>
      list.displayName.toLowerCase().includes(filterString.toLowerCase()),
    );
  }
  if (filterUserId !== -1) {
    filterList = filterList.filter((list) => list.owner === filterUserId);
  }
  return filterList;
};
interface SharedListsModalProps {
  readonly show: boolean;
  readonly onSubmit: (listIds: ReadonlyArray<number>) => void;
  readonly onClose: () => void;
  readonly favoriteLists: ReadonlyArray<favoritesTypes.SharedFavoritesListState>;
  readonly sharedActiveLists: ReadonlyArray<number>;
  readonly users: ReadonlyArray<usersTypes.User>;
}

const SharedListsModal: FC<SharedListsModalProps> = ({
  show,
  onSubmit,
  onClose,
  favoriteLists,
  sharedActiveLists,
  users,
}: SharedListsModalProps) => {
  const [filterUserId, setFilterUserId] = useState<number>(-1);
  const [selectedLists, setSelectedLists] = useState(sharedActiveLists);
  const [filterString, setFilterString] = useState('');

  const onFilterStringChanged = (value: string) => {
    setFilterString(value);
    setSelectedLists([]);
  };
  const onFilterUserChanged = (value: number | string) => {
    setFilterUserId(+value);
    setSelectedLists([]);
  };
  const onFavoriteListChange = (lists: string[]) => {
    const listIds = Array.from(lists).map((entry) => +entry);
    setSelectedLists(listIds);
  };

  const onFormSubmit = () => {
    onSubmit(selectedLists);
    onClose();
  };

  return (
    <Modal
      labelId="favorites-list-modal-label"
      id="favorites-shared-lists-modal"
      open={show}
      onClose={onClose}
    >
      <Modal.Header
        id="favorites-list-modal-label"
        headerTitle={pgettext('Title-style capitalization', 'Choose a Favorites List to Show')}
        onClose={onClose}
      />
      <Modal.Body id="favorites-shared-lists-form">
        <form>
          <Input
            id="favorite-lists-filter"
            name="favorite-lists-filter"
            label={gettext('Filter favorite lists')}
            onChange={onFilterStringChanged}
          />
          <UserSelect
            users={users}
            onChange={onFilterUserChanged}
            label={gettext('Filter lists by user')}
          />
          <MultipleChoiceSelect
            id="favorite-item-list-id"
            name="favoritelist-shared_with"
            label={gettext('Select favorite lists')}
            value={selectedLists}
            onChange={onFavoriteListChange}
            choices={filterLists(favoriteLists, filterString, filterUserId)}
            choiceDisplay="displayName"
            choiceValue="id"
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel selection')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          onClick={onFormSubmit}
          label={pgettext('Form submit button label', 'Show Lists')}
          title={pgettext('Form submit button title', 'Add lists to own favorites')}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SharedListsModal;
