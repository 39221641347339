import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';

import { gettext, ngettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Collection } from 'medialoopster/icons';

import { createAssetCollectionModalSelectors } from '../../../../state/modules/createAssetCollectionModal';
import { favoritesSelectors } from '../../../../state/modules/favorites';
import { productionsSelectors } from '../../../../state/modules/productions';
import { MediaAssetResource } from '../../../../state/types/asset/baseTypes';
import CreateAssetCollectionModal from '../../../Favorites/FavoritesMenu/CreateAssetCollectionModal';

type Props = {
  readonly assets: ReadonlyArray<MediaAssetResource>;
};

const CreateCollectionFromAssetsActionMenuEntry = ({ assets }: Props) => {
  const hasWriteableCollection = useSelector(
    productionsSelectors.hasWriteableProductionsWithProjectDevice,
  );
  const isSelectedListAssetsLoading = useSelector(favoritesSelectors.isSelectedListAssetsLoading);
  const [createCollectionShown, setShowCreateCollectionModal] = useState(false);
  const onCreateCollectionClicked = () => {
    setShowCreateCollectionModal(true);
  };

  const onCreateCollectionClose = () => {
    setShowCreateCollectionModal(false);
  };

  const getToolTip = (): string => {
    if (!hasWriteableCollection) {
      return gettext(
        'You do not have write access for a production with default collection asset device',
      );
    }
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    return ngettext(
      'Create a collection from the selected asset',
      'Create a collection from the selected assets',
      assets.length,
    );
  };
  return (
    <>
      <MenuItem
        disabled={!hasWriteableCollection || isSelectedListAssetsLoading}
        tooltip={getToolTip()}
        onClick={() => {
          onCreateCollectionClicked();
        }}
        icon={<Collection fontSize="small" />}
        label={pgettext('Menu entry', 'Create collection...')}
      />
      <CreateAssetCollectionModal
        show={createCollectionShown}
        onClose={onCreateCollectionClose}
        assets={assets}
      />
    </>
  );
};

const useCreateCollectionFromAssetsActionMenuEntry = (
  assets: ReadonlyArray<MediaAssetResource>,
): ReadonlyArray<ReactElement> => {
  const canCreateAssetCollection = useSelector(
    createAssetCollectionModalSelectors.canCreateAssetCollection,
  );
  if (assets.length === 0 || !canCreateAssetCollection) {
    return [];
  }
  return [<CreateCollectionFromAssetsActionMenuEntry key="create-collection" assets={assets} />];
};

export default useCreateCollectionFromAssetsActionMenuEntry;
