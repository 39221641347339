interface User {
  username: string;
  first_name?: string;
  last_name?: string;
}

export default (user: User, shortName = false): string => {
  let displayName = user.username;
  if (user.first_name != null && user.first_name !== '') {
    displayName = user.first_name;

    if (!shortName && user.last_name != null && user.last_name !== '') {
      displayName = `${user.first_name} ${user.last_name}`;
    }
  }
  return displayName;
};
