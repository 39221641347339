import { CircularProgress, Stack, Typography } from '@mui/material';
import { FC, memo } from 'react';

import { gettext } from 'medialoopster/Internationalization';

// TODO: It is quite possible that a similar component is useful somewhere else <KH 2024-08-28 t:ML-3549>

/**
 * A loading spinner component that partially occludes its parent element while it is loading
 *
 * The parent element needs to be positioned relative (CSS position: relative).
 *
 * The component takes a single boolean property `show`. If `show` is true the component
 * will show the spinner. If it is false nothing is rendered.
 */
export const LoadingSpinner: FC<{ show: boolean }> = memo(({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <Stack
      position="absolute"
      top="0"
      left="0"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      gap="1em"
      sx={{ backgroundColor: '#ffffffb7', pointerEvents: 'none' }}
    >
      <CircularProgress />
      <Typography color="primary">{gettext('Loading...')}</Typography>
    </Stack>
  );
});

export default LoadingSpinner;
