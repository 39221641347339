import { gettext, interpolate } from 'medialoopster/Internationalization';

interface NamedEntry {
  id: number;
  name?: string;
}

export default (entries: ReadonlyArray<NamedEntry>, id: number): string => {
  const entry = entries.find((item) => item.id === id);
  if (entry === undefined || entry.name === undefined) {
    return interpolate(gettext('Not available (id: %(id)s)'), { id });
  }
  return entry.name;
};
