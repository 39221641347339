import { DateTime } from 'luxon';

import { DateRange, DefinedRange } from './types';

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] =>
  Array.from({ length: Math.ceil(array.length / size) }, (_v, i) =>
    array.slice(i * size, i * size + size),
  );

export const getDaysInPageOfMonth = (
  date: DateTime,
  startWeekAt: 0 | 1 | 2 | 3 | 4 | 5 | 6,
): DateTime[] => {
  // https://moment.github.io/luxon/api-docs/index.html#datetime  -> weekday
  // https://docs.djangoproject.com/en/3.2/ref/settings/#first-day-of-week
  // The weeks on a calendar page (left&right) each need all visible & invisible days.
  // (-> calculate necessary days of current month and adjacent months)
  const startOfMonth = date.startOf('month');
  const startDay = startOfMonth.weekday % 7; // convert to django format 0=sunday .. 6=saturday
  const dayAdjust = startDay >= startWeekAt ? startDay - startWeekAt : 7 - (startWeekAt - startDay);
  const startWeekDayOfCalendarPage = startOfMonth.minus({ day: dayAdjust });

  const endOfMonth = date.endOf('month');
  const endDay = endOfMonth.weekday % 7; // convert to django format 0=sunday .. 6=saturday
  const endWeekAt = startWeekAt === 0 ? 6 : startWeekAt - 1;
  const endWeekDayOfCalendarPage = endOfMonth.plus({ days: (7 + endWeekAt - endDay) % 7 });

  const days = [];
  for (let curr = startWeekDayOfCalendarPage; curr < endWeekDayOfCalendarPage; ) {
    days.push(curr);
    curr = curr.plus({ day: 1 });
  }
  return days;
};

export const isStartDayOfRange = ({ startDate }: DateRange, day: DateTime): boolean =>
  !!(startDate && day.hasSame(startDate, 'day'));

export const isEndDayOfRange = ({ endDate }: DateRange, day: DateTime): boolean =>
  !!(endDate && day.hasSame(endDate, 'day'));

export const inDayRange = ({ startDate, endDate }: DateRange, day: DateTime): boolean =>
  !!(
    startDate &&
    endDate &&
    ((day >= startDate && day <= endDate) ||
      day.hasSame(startDate, 'day') ||
      day.hasSame(endDate, 'day'))
  );

export const isRangeSameDay = ({ startDate, endDate }: DateRange): boolean => {
  if (startDate && endDate) {
    return startDate.hasSame(endDate, 'day');
  }
  return false;
};

export const getCurrentDateRange = (range: Partial<DefinedRange | DateRange>): DateRange => {
  const currDate = { startDate: DateTime.local(), endDate: DateTime.local() };
  if (range.startDate) {
    currDate.startDate =
      typeof range.startDate === 'function' ? range.startDate() : range.startDate;
  }
  if (range.endDate) {
    currDate.endDate = typeof range.endDate === 'function' ? range.endDate() : range.endDate;
  }
  return currDate;
};

export const transferTimeOnTargetDateTime = (
  targetDateTime: DateTime,
  timeDate: DateTime,
): DateTime => {
  return targetDateTime.set({
    hour: timeDate.hour,
    minute: timeDate.minute,
    second: timeDate.second,
    millisecond: timeDate.millisecond,
  });
};

export const formatDateTimeRange = (
  startDate: DateTime | undefined,
  endDate: DateTime | undefined,
  dateFormat: string,
  timeFormat: string,
  separator = ' - ',
): string => {
  const theDateTimeFormat = `${dateFormat} ${timeFormat}`;
  return `${startDate ? startDate.toFormat(theDateTimeFormat) : ''}${separator}${
    endDate ? endDate.toFormat(theDateTimeFormat) : ''
  }`;
};
export const isEqualDateRange = (
  dateRange: DateRange | undefined,
  anotherDateRange: DateRange | undefined,
  weakEqual = false,
): boolean => {
  if (
    dateRange &&
    dateRange.startDate &&
    dateRange.endDate &&
    anotherDateRange &&
    anotherDateRange.startDate &&
    anotherDateRange.endDate
  ) {
    return (
      +dateRange.startDate === +anotherDateRange.startDate &&
      +dateRange.endDate === +anotherDateRange.endDate
    );
  }
  return weakEqual;
};
