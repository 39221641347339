import { Action } from 'redux';
import { combineEpics, ofType, Epic } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import {
  ResourceLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { FETCH_SEARCHFIELDMAPPINGS, FetchSearchFieldMappings } from './types';

export const fetchSearchFieldMappingEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, FetchSearchFieldMappings['type'], FetchSearchFieldMappings>(
      FETCH_SEARCHFIELDMAPPINGS,
    ),
    switchMap(() => fetchCollection('/api/searchfieldmappings/')),
  );
export default combineEpics(fetchSearchFieldMappingEpic);
