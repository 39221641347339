import { combineReducers } from 'redux';

import { createResourceModuleReducerMap, createResourcesReducer, mlRel } from 'medialoopster/rest';

import { CollectionRootState } from './types';

export default combineReducers<CollectionRootState>({
  ...createResourceModuleReducerMap('collection', mlRel('collections')),
  videoassets: createResourcesReducer('collection-videoassets'),
  imageassets: createResourcesReducer('collection-imageassets'),
  audioassets: createResourcesReducer('collection-audioassets'),
});
