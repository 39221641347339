import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';

import AnnotationsActionMenu from './AnnotationsActionMenu';
import ShowAllSwitch from './ShowAllSwitch';

// TODO: The design of the header, especially the bottom border, is shared with the TranscriptHeader <KH 2024-08-28 t:ML-3549>

/** Component to render the header of the Annotations panel
 *
 * The Header holds a title, the switch for showing only comments created by the user and
 * the "hamburger menu" button.
 */
const AnnotationsPanelHeader: FC = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    flexWrap="nowrap"
    py={1}
    pl={4}
    borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
  >
    <Typography>{gettext('Annotations')}</Typography>
    {/* Controls should be distributed to the right with a minimum spacing to the Title */}
    <Stack direction="row" ml="auto" pl="1rem" justifyContent="flex-end" alignItems="center">
      <ShowAllSwitch />
      <AnnotationsActionMenu />
    </Stack>
  </Stack>
);

export default AnnotationsPanelHeader;
