import makeStyles from '@mui/styles/makeStyles';
import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { detailsSelectors } from '../../state/modules/details';
import { searchActions, searchSelectors } from '../../state/modules/search';
import AssetEntry from './AssetEntry';
import FallbackSearchWarning from './FallbackSearchWarning';
import LoadMoreResults from './LoadMoreResults';
import LoadingResults from './LoadingResults';
import NoMoreResults from './NoMoreResults';
import NoResultsFound from './NoResultsFound';
import SearchResultCount from './SearchResultCount';

const useStyles = makeStyles(() => ({
  /* Suppress Browser selection */
  suppress: {
    '-moz-user-select': '-moz-none',
    '-khtml-user-select': 'none',
    '-webkit-user-select': 'none',
    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    '-ms-user-select': 'none',
    'user-select': 'none',
    flexGrow: 1,
  },
  tooltip: {
    maxWidth: '200px',
    padding: '3px 8px',
    borderRadius: '4px',
    fontSize: '12px',
    wordWrap: 'break-word',
    textAlign: 'left',
    whiteSpace: 'pre-line',
  },
  results: { paddingTop: 2 },
}));

type Props = {
  readonly isWide: boolean;
};

const SearchList: FC<Props> = ({ isWide }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const entries = useSelector(searchSelectors.getEntries);
  const next = useSelector(searchSelectors.getNextURI);
  const isFallbackSearch = useSelector(searchSelectors.isFallbackSearch);
  const xTotalRelation = useSelector(searchSelectors.getXTotalRelation);
  const xTotalCount = useSelector(searchSelectors.getXTotalCount);
  const currentSearchCategory = useSelector(searchSelectors.getSearchCategory);
  const loading = useSelector(searchSelectors.isRefreshingOrLoading);
  const selection = useSelector(searchSelectors.getSelection);

  const loadMore = useCallback(() => {
    if (next) dispatch(searchActions.fetchNextSearchResult(next));
  }, [next, dispatch]);

  return (
    <>
      {isFallbackSearch && <FallbackSearchWarning />}
      <div className={classes.suppress}>
        <div style={{ position: 'relative', height: '30px' }}>
          <LoadingResults loading={loading} />
          <SearchResultCount
            loading={loading}
            totalCount={xTotalCount}
            totalRelation={xTotalRelation}
            searchCategory={currentSearchCategory}
          />
        </div>
        <div className={classes.results}>
          {entries.map((entry) => {
            const assetURI = getResourceURI(entry);
            const currentAssetURI = currentAsset ? getResourceURI(currentAsset) : null;
            const isCurrent = currentAssetURI === assetURI;
            const inSelection =
              selection.assetTypeName === getResourceTypeName(entry) &&
              selection.assetIds.includes(entry.id);
            return (
              <AssetEntry
                key={getResourceURI(entry)}
                asset={entry}
                isCurrent={isCurrent}
                isWide={isWide}
                inSelection={inSelection}
              />
            );
          })}
        </div>
        {entries.length === 0 && !loading && <NoResultsFound />}
        {entries.length >= 1 && !next && <NoMoreResults />}
        {entries.length >= 1 && next && <LoadMoreResults onClick={loadMore} />}
      </div>
    </>
  );
};

export default memo(SearchList);
