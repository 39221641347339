import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import { ImageRootState } from './types';

export default combineReducers<ImageRootState>({
  assets: createResourcesReducer('imageasset'),
  projectsCollections: createResourcesReducer('imageasset-projects'),
});
