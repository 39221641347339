import Collapse from '@mui/material/Collapse';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { personsSelectors } from '../../../state/modules/persons';
import PersonList from './PersonList/index';
import PersonTimeline from './PersonTimeline';

const PersonsView: FC = () => {
  const isPersonsVisible = useSelector(personsSelectors.isPersonsVisible);
  return (
    <Collapse in={isPersonsVisible}>
      <PersonTimeline />
      <PersonList />
    </Collapse>
  );
};

export default PersonsView;
