import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { AddToAssetCollection } from 'medialoopster/icons';

import {
  addAssetsToCollectionActions,
  addAssetsToCollectionSelectors,
} from '../../../state/modules/addAssetsToCollection';
import { AddableAsset } from '../../../state/modules/addAssetsToCollection/types';
import { productionsSelectors } from '../../../state/modules/productions';
import AddAssetsToCollectionModal from '../AddAssetsToCollectionModal';

interface Props {
  readonly isLoading: boolean;
  readonly isLoadingMsg: string;
  readonly isEmpty: boolean;
  readonly isEmptyMsg: string;
  readonly infoMsg: string;
  readonly label: string;
  readonly assets: ReadonlyArray<AddableAsset>;
}

const AddAssetsToCollectionMenu: FC<Props> = ({
  isLoading,
  isEmpty,
  isLoadingMsg,
  isEmptyMsg,
  infoMsg,
  label,
  assets,
}) => {
  const [show, setShow] = useState(false);
  const onAddToCollectionClicked = useCallback(() => {
    setShow(true);
  }, [setShow]);
  const onCloseModal = useCallback(() => setShow(false), []);

  const writableProductions = useSelector(
    productionsSelectors.getWriteableProductionsWithProjectDeviceOptions,
    shallowEqual,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (writableProductions.length > 0) {
      dispatch(addAssetsToCollectionActions.checkAssetCollectionAvailabilityForProduction());
    }
  }, [dispatch, writableProductions]);
  const writableProductionSearchCollectionAvailability = useSelector(
    addAssetsToCollectionSelectors.getWritableProductionSearchCollectionAvailability,
  );
  const addAssetsToCollectionFromListContentTitle = useMemo(() => {
    if (writableProductions.length === 0) {
      return gettext('There are no productions with a collection device you have write access to');
    }
    if (typeof writableProductionSearchCollectionAvailability === 'string') {
      return writableProductionSearchCollectionAvailability;
    }
    if (writableProductionSearchCollectionAvailability === 0) {
      return gettext('There are no collection assets in the productions you have write access to');
    }
    if (isLoading) {
      return isLoadingMsg;
    }
    if (isEmpty) {
      return isEmptyMsg;
    }
    return infoMsg;
  }, [
    writableProductions,
    writableProductionSearchCollectionAvailability,
    isLoading,
    isLoadingMsg,
    isEmpty,
    isEmptyMsg,
    infoMsg,
  ]);
  const isDisabled = useMemo(
    () =>
      writableProductions.length === 0 ||
      isLoading ||
      isEmpty ||
      typeof writableProductionSearchCollectionAvailability === 'string' ||
      writableProductionSearchCollectionAvailability === 0,
    [writableProductionSearchCollectionAvailability, isEmpty, isLoading, writableProductions],
  );

  return (
    <>
      <MenuItem
        disabled={isDisabled}
        tooltip={addAssetsToCollectionFromListContentTitle}
        onClick={onAddToCollectionClicked}
        icon={<AddToAssetCollection fontSize="small" />}
        label={label}
      />
      <AddAssetsToCollectionModal show={show} onClose={onCloseModal} assets={assets} />
    </>
  );
};

export default AddAssetsToCollectionMenu;
