/* eslint-disable no-underscore-dangle */

/* eslint-disable class-methods-use-this */

/**
 * This module implements a lexical DecoratorNode that displays the metadata
 * of a time block/speaker.
 */
import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';
import { ReactNode } from 'react';

import { MetadataDisplay } from '../components/MetadataDisplay';
import { METADATA_CLASS_NAME } from '../css';
import { Decimal } from '../decimal';
import { $isTranscriptTimeBlockNode, TimeBlockState } from './TranscriptTimeBlockNode';

/**
 * A DecoratorNode to display the data associated to a time block defined by a transcript speaker.
 *
 * The node uses the MetadataDisplay React component to display the metadata of a speaker.
 * The metadata has the shape of TimeBlockState and must be kept in sync with the parent
 * TranscriptTimeBlock node. This is done using a NodeTransform on the parent
 * TranscripttimeBlock in the TranscriptStructurePlugin.
 *
 * This node class prevents removal and replacement by overriding the `remove` and `replace` methods
 * to be essentially NoOps. To remove a TranscriptMetadataNode use `forceRemove`.
 */
export class TranscriptMetadataNode extends DecoratorNode<ReactNode> {
  __metadata: TimeBlockState;

  static getType(): string {
    return 'transcript-speaker';
  }

  static clone(node: TranscriptMetadataNode): TranscriptMetadataNode {
    return new TranscriptMetadataNode(node.__metadata, node.__key);
  }

  constructor(metadata: TimeBlockState, key?: NodeKey) {
    super(key);
    this.__metadata = metadata;
  }

  /**
   * Get the metadata in form of a TimeBlockState.
   */
  getTimeBlockState(): TimeBlockState {
    return this.getLatest().__metadata;
  }

  /**
   * Set the metadata in form of a TimeBlockState.
   * @param metadata The new metadata
   */
  setTimeBlockState(metadata: TimeBlockState): void {
    this.getWritable().__metadata = metadata;
  }

  /**
   * Get the speaker name from the metadata.
   */
  getSpeakerName(): string {
    return this.getTimeBlockState().speakerName;
  }

  /**
   * Get the start time fron the metadata.
   */
  getStartTime(): Decimal {
    return this.getTimeBlockState().startTime;
  }

  /*
  Usual createDOM method, but sets a class name to help with styling the node
   */
  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    dom.className = METADATA_CLASS_NAME;
    return dom;
  }

  /*
  No updates to the DOM element needed.
   */
  updateDOM(): false {
    return false;
  }

  /*
  Use MetadataDisplay to render the metadata.
  Only renders the metadata if the parent is a TranscriptTimeBlockNode.
   */
  decorate(): ReactNode {
    const timeBlockNode = this.getParent();
    if (!$isTranscriptTimeBlockNode(timeBlockNode)) {
      return null;
    }
    return (
      <MetadataDisplay
        speakerName={this.getSpeakerName()}
        startTime={this.getStartTime()}
        timeBlockNodeKey={timeBlockNode.getKey()}
      />
    );
  }

  /*
  NoOp. Disable removal.
   */
  remove(): void {}

  /*
  NoOp
   */
  replace<N extends LexicalNode>(): N {
    return this as unknown as N;
  }

  /**
   * Removes the node from the editor state.
   *
   * Use this function instead of the `remove` function if you really want to delete
   * the node; normal deletion is disabled.
   *
   * @param preserveEmptyParent Same as the respective parameter on `remove`
   */
  forceRemove(preserveEmptyParent?: boolean): void {
    super.remove(preserveEmptyParent);
  }

  /*
  Mark as not keyboard selectable
   */
  isKeyboardSelectable(): boolean {
    return false;
  }

  /*
  Mark as isolated, so it will be skipped when navigating with arrow keys.
   */
  isIsolated(): boolean {
    return true;
  }
}

/**
 * Create a new TranscriptMetadataNode with initial metadata
 *
 * @param metadata The initial metadata of the new node.
 */
export const $createTranscriptMetadataNode = (metadata: TimeBlockState): TranscriptMetadataNode =>
  new TranscriptMetadataNode(metadata);

/**
 * Type predicate for TranscriptMetadataNode.
 * @param node The node to check.
 */
export const $isTranscriptMetadataNode = (
  node: LexicalNode | null | undefined,
): node is TranscriptMetadataNode => node instanceof TranscriptMetadataNode;
