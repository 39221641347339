import { PersistPartial } from 'redux-persist/es/persistReducer';

export const ALERT_ERROR = 'LOGIN/ALERT_ERROR';
export interface AlertError {
  readonly type: typeof ALERT_ERROR;
  readonly payload: {
    readonly message: string;
  };
}

export const CLEAR_ALERT = 'LOGIN/CLEAR_ALERT';
export interface ClearAlert {
  readonly type: typeof CLEAR_ALERT;
}

export const LOGIN_REQUEST = 'LOGIN/LOGIN_REQUEST';
export interface LoginRequest {
  readonly type: typeof LOGIN_REQUEST;
  readonly payload: {
    readonly username: string;
    readonly password: string;
  };
}

export const LOGIN_SUCCESS = 'LOGIN/LOGIN_SUCCESS';
export interface LoginSuccess {
  readonly type: typeof LOGIN_SUCCESS;
  readonly payload: {
    readonly token: Token;
    readonly userId: number;
  };
}

export const LOGIN_FAILURE = 'LOGIN/LOGIN_FAILURE';
export interface LoginFailure {
  readonly type: typeof LOGIN_FAILURE;
}

export const LOGOUT = 'LOGIN/LOGOUT';
export interface Logout {
  readonly type: typeof LOGOUT;
}

export const CLEAR_TOKEN = 'LOGIN/CLEAR_TOKEN';
export interface ClearToken {
  readonly type: typeof CLEAR_TOKEN;
}

export const RESET_STORE = 'LOGIN/RESET_STORE';
export interface ResetStore {
  readonly type: typeof RESET_STORE;
}

export const INIT_APP = 'LOGIN/INIT_APP';
export interface InitApp {
  readonly type: typeof INIT_APP;
}

export interface Alert {
  readonly message: string;
}

export interface Authentication {
  readonly loggingIn: boolean;
  readonly token: Token;
  readonly userId: number;
}

export interface LoginRootState {
  readonly alert: Alert;
  readonly authentication: Authentication & PersistPartial;
}

export interface RootState {
  readonly login: LoginRootState;
}

export type Token = string;

export type HttpAuthorization = `Token ${Token}`;
