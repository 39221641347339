import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { ToolbarIconButton } from 'medialoopster/components';
import { Subtitles, SubtitlesOff } from 'medialoopster/icons';

type Props = {
  readonly visible: boolean;
  readonly setShowSubtitle: (show: boolean) => void;
  readonly showSubtitle: boolean;
  readonly disabled?: boolean;
};

const SubtitleActivateButton: FC<Props> = ({
  visible,
  setShowSubtitle,
  showSubtitle,
  disabled = false,
}: Props) => {
  const tooltipText = showSubtitle ? gettext('Subtitles off') : gettext('Subtitles on');
  const handleOpen = () => {
    setShowSubtitle(!showSubtitle);
  };
  if (!visible) {
    return <></>;
  }
  return (
    <ToolbarIconButton title={tooltipText} onClick={handleOpen} disabled={disabled}>
      {showSubtitle ? <SubtitlesOff /> : <Subtitles />}
    </ToolbarIconButton>
  );
};

export default SubtitleActivateButton;
