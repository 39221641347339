import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';

import {
  $calculateTranscriptDiff,
  isUnmodified,
  TranscriptSpeakerData,
  TranscriptUnitData,
} from '../diff';

export interface TranscriptModificationDetectorPluginProps {
  /**
   * The original units of the transcript, that is currently shown in the editor.
   * The diff is calculated against these units.
   */
  readonly units: readonly TranscriptUnitData[];
  /**
   * The original speakers of the transcript, that is currently shown in the editor.
   */
  readonly speakers: readonly TranscriptSpeakerData[];
  /**
   * This function is called on any modification with a boolean indicating if the transcript
   * is currently modified or in an unmodified state when compared with the original transcript.
   */
  readonly onModified: (isModified: boolean) => void;
}

/**
 * A plugin to detect modifications on the transcript with respect to the input transcript.
 *
 * The plugin watches for updates to the editor state and calculates the diff between the input
 * transcript and the current state. The result of the comparison is propagated to the parent
 * component via the `onModified` callback.
 *
 * @param units The original transcript units.
 * @param speakers The original transcript speakers.
 * @param onModified A callback to be notified if and when the transcript is modified (true) or not (false).
 */
export const TranscriptModificationDetectorPlugin: React.FC<
  TranscriptModificationDetectorPluginProps
> = ({ units, speakers, onModified }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      const diff = editorState.read(() => $calculateTranscriptDiff(units, speakers));
      const isTranscriptModified = !isUnmodified(diff);
      onModified(isTranscriptModified);
    });
  }, [editor, onModified, units, speakers]);

  return null;
};
