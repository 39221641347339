import { Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC, memo } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Download } from 'medialoopster/icons';

import LinkIconButton from '../../buttons/LinkIconButton';
import ButtonCell from './base/ButtonCell';

const useStyles = makeStyles(() => ({
  tooltipTitle: {
    whiteSpace: 'pre-line',
  },
  tooltipCursor: {
    cursor: 'help',
  },
}));

const getTitle = (available: boolean, link: string): string => {
  if (available) {
    return `${gettext('Download')} ${link}.`;
  }
  return gettext('Download not available');
};

interface Props {
  readonly available: boolean;
  readonly link: string;
}

const DownloadCell: FC<Props> = ({ available, link }: Props) => {
  const classes = useStyles();
  return (
    <ButtonCell>
      <Tooltip
        title={
          <Typography variant="body2" className={classes.tooltipTitle}>
            {getTitle(available, link)}
          </Typography>
        }
        placement="right-start"
      >
        <div className={classes.tooltipCursor}>
          <LinkIconButton href={link} disabled={!available} aria-label={gettext('Download')}>
            <Download />
          </LinkIconButton>
        </div>
      </Tooltip>
    </ButtonCell>
  );
};

export default memo(DownloadCell);
