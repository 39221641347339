import Button from '@mui/material/Button';
import { FC } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { gettext } from 'medialoopster/Internationalization';
import { More } from 'medialoopster/icons';

interface LoadMoreResultsProps {
  onClick: () => void;
}

const LoadMoreResults: FC<LoadMoreResultsProps> = ({ onClick }: LoadMoreResultsProps) => (
  <VisibilitySensor partialVisibility onChange={(isVisible) => (isVisible ? onClick() : null)}>
    <Button variant="outlined" onClick={onClick} fullWidth>
      <More />
      {gettext('Load more results')}
      <More />
    </Button>
  </VisibilitySensor>
);

export default LoadMoreResults;
