import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  getLinkHref,
  mlRel,
  RECEIVE_ROOT_RESOURCE,
  ReceiveRootResource,
  UnauthorizedRequestAction,
  unauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { RootState } from '../../types/rootState';
import { licenseLoaded, siteCountLoaded } from './actions';
import { FETCH_LICENSE, FetchLicense, LicenseLoaded, SiteCountLoaded } from './types';

export const fetchLicenseEpic: Epic<
  Action,
  LicenseLoaded | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_LICENSE),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      ajax<string>({
        url: '/api/medialoopsterlicense',
        method: 'GET',
        responseType: 'text',
        headers: {
          ...getTokenAuthHeader(loginSelectors.getToken(state)),
        },
      }).pipe(
        map(({ response }) => licenseLoaded(response)),
        catchError((err) => {
          if (err && err.status === 401) {
            return of(unauthorizedRequestAction());
          }
          return EMPTY;
        }),
      ),
    ),
  );

export const fetchSiteCountEpic: Epic<
  Action,
  SiteCountLoaded | UnauthorizedRequestAction,
  RootState
> = (action$: Observable<Action>, state$) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { root },
        },
        state,
      ]) => {
        const url = getLinkHref(root, mlRel('sites'));
        if (!url) {
          return EMPTY;
        }
        // TODO: Support HEAD requests in `shared_module/rest`. <DT 2023-09-21 t:ML-3451>
        return ajax({
          url,
          method: 'HEAD',
          headers: {
            ...getTokenAuthHeader(loginSelectors.getToken(state)),
          },
        }).pipe(
          map((response) => {
            const totalCount = response.responseHeaders['x-total-count'];
            return siteCountLoaded(parseInt(totalCount, 10));
          }),
          catchError((err) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            return EMPTY;
          }),
        );
      },
    ),
  );

export default combineEpics<
  Action,
  FetchLicense | LicenseLoaded | SiteCountLoaded | UnauthorizedRequestAction,
  RootState
>(fetchLicenseEpic, fetchSiteCountEpic);
