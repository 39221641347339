import { InputAdornment } from '@mui/material';
import { FC, useCallback, useRef, KeyboardEventHandler } from 'react';
import { useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { IconButton, Input, useVideoController } from 'medialoopster/components';
import { Send } from 'medialoopster/icons';

import { addAnnotation } from '../../../../state/modules/details/actions';

/**
 * An input Field for new Annotations.
 *
 * The responsibility of this component is to enable the user to enter new annotations.
 * New annotations are dispatched as ADD_ANNOTATION action with the current frame of
 * the video.
 *
 * The user can submit via a button or by pressing Enter. To add a newline the user has to
 * press Shift-Enter.
 */
const AnnotationInput: FC = () => {
  const dispatch = useDispatch();
  const videoController = useVideoController();
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      // dispatch ADD_ANNOTATION on click of the button and clear the input field.
      dispatch(addAnnotation(videoController.getCurrentFrame(), inputRef.current.value));
      inputElement.value = '';
    }
  }, [videoController, dispatch]);

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      // handle Enter-keypress on the input element
      // If only Enter and no shift is pressed, do the same as on the button click.
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        onClick();
      }
      // Enter+Shift inserts a newline as per the default behaviour of the Input
    },
    [onClick],
  );

  return (
    <Input
      label={gettext('Annotation text')}
      multiline
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} title={gettext('Submit annotation')}>
              <Send />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default AnnotationInput;
