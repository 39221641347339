import { FC, useState, useRef } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { Input, Modal } from 'medialoopster/components';
import formatUserDisplayName from 'medialoopster/formatUserDisplayName';
import { Delete } from 'medialoopster/icons';
import { getLinkHref, mlRel } from 'medialoopster/rest';

import {
  isListSharedForUser,
  isNameValid,
  getPreviousListId,
} from '../../../businessRules/models/FavoriteListsHelpers';
import {
  favoritesActions,
  favoritesSelectors,
  favoritesTypes,
} from '../../../state/modules/favorites';
import { usersSelectors } from '../../../state/modules/users';
import MultipleChoiceSelect from '../../../ui/components/MultipleChoiceSelect/Uncontrolled';
import { FormEvent } from './types';

interface EditListModalProps {
  show: boolean;
  onClose: () => void;
}
type FavoriteListEdit = Partial<Pick<favoritesTypes.FavoritesListResource, 'name' | 'shared_with'>>;

const EditListModal: FC<EditListModalProps> = ({ show, onClose }: EditListModalProps) => {
  const dispatch = useDispatch();

  const userURI = useSelector(usersSelectors.getCurrentUserURI);
  const users = useSelector(usersSelectors.getAllOtherUsers);
  const favoriteList = useSelector(
    favoritesSelectors.getSelectedList,
    shallowEqual,
  ) as favoritesTypes.FavoritesListResource;
  const favoriteLists = useSelector(favoritesSelectors.getVisibleListsForUser);
  const sharedWithUsers = users.map((user) => ({
    id: user.id,
    displayName: formatUserDisplayName(user),
  }));
  const selectedSharedWithUsersId = sharedWithUsers
    .filter((user) => isListSharedForUser(user.id, favoriteList))
    .map(({ id }) => id);
  const isSharedList = getLinkHref(favoriteList, mlRel('owner')) !== userURI;
  const [editedList, setEditedList] = useState<FavoriteListEdit>({});
  const [showInputError, setShowInputError] = useState(false);
  const [listName, setListName] = useState(favoriteList?.name ? favoriteList.name : '');

  const formRef = useRef(null);

  const validateUserInput = (newName: string | undefined) => {
    return isNameValid(newName);
  };

  const disableFormPropagate = (event: FormEvent) => {
    // Disable propagation (page reload) on enter
    if (formRef.current === event.target) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onEditModalSubmit = (event: FormEvent) => {
    disableFormPropagate(event);
    const name = editedList.name === undefined ? listName : editedList.name;
    const isValid = validateUserInput(name);
    if (isValid) {
      setShowInputError(false);
      dispatch(favoritesActions.editFavoriteList({ ...editedList, name }));
      onClose();
    } else {
      setShowInputError(true);
    }
  };

  const onListDelete = () => {
    dispatch(favoritesActions.deleteFavoriteList(favoriteList.id));
    onClose();
  };

  const onListRemove = () => {
    const listIdToRemove = favoriteList.id;
    const previousListId = getPreviousListId(favoriteLists, listIdToRemove);
    dispatch(favoritesActions.removeSharedActiveList(listIdToRemove));
    dispatch(favoritesActions.selectFavoriteList(previousListId));
    onClose();
  };

  const onNameChange = (name: string) => {
    setEditedList({ ...editedList, name });
    setListName(name);
  };
  const onSharedWithChange = (values: string[]) => {
    setEditedList({
      ...editedList,
      shared_with: values.map((val) => +val),
    });
  };

  const listActionText = isSharedList ? gettext('Remove list') : gettext('Delete list');
  const listActionTitle = isSharedList
    ? gettext('Remove this shared favorites list.')
    : gettext('Delete this favorites list.');
  const listActionClick = isSharedList ? onListRemove : onListDelete;

  return (
    <Modal id="favorites-list-modal" open={show} onClose={onClose}>
      <Modal.Header
        id="favorites-list-modal-label"
        headerTitle={`${pgettext('Title-style capitalization', 'Edit')} ${favoriteList?.name}`}
        onClose={onClose}
      />
      <Modal.Body id="edit-favorites-list-form">
        <form ref={formRef} onSubmit={onEditModalSubmit}>
          <Input
            id="favoritelist-name"
            name="favoritelist-name"
            onChange={onNameChange}
            label={gettext('Name')}
            readOnly={isSharedList}
            showError={showInputError}
            errorMsg={gettext('Please enter a valid name.')}
            autoFocus
            value={listName}
            maxLength={255}
          />
          <MultipleChoiceSelect
            id="favoritelist-shared_with"
            name="favoritelist-shared_with"
            label={gettext('Shared with')}
            choices={sharedWithUsers}
            onChange={onSharedWithChange}
            choiceDisplay="displayName"
            choiceValue="id"
            readOnly={isSharedList}
            defaultValue={selectedSharedWithUsersId}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ActionButton
          color="error"
          onClick={listActionClick}
          title={listActionTitle}
          label={listActionText}
          icon={<Delete />}
        />
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel changes to this favorites list')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          onClick={onEditModalSubmit}
          label={pgettext('Form submit button label', 'Save List')}
          title={pgettext('Form submit button title', 'Submit changes to this favorites list')}
          disabled={isSharedList}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EditListModal;
