/*  eslint-disable max-classes-per-file */

/** This module implements a lexical plugin to enforce structure constraints on the editor state
 *
 * The following structural rules must be met
 *  - the root only has TranscriptTimeBlockNodes as children
 *  - every TranscriptTimeBlockNode has a TranscriptMetadataNode as first child
 *  - every TranscriptTimeBlockNodes has only TranscriptParagraphNodes as other children
 *  - every TranscriptUnitNode is child of a TranscriptParagraphNode
 *  - every TranscriptParagraphNode has at least one TranscriptUnitNode as child
 *
 * This module implements constraints as `lexical` Node Transforms. These transforms transform
 * the editor state in a way that ensures the structure described above.
 *
 * The constraints are formulated using a custom constraint class that forms a mini DSL. The
 * DSL ensures that the purpose and effect of constraints is properly documented.
 */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $dfs, $insertFirst, $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import { Klass, LexicalEditor, LexicalNode, LineBreakNode } from 'lexical';
import { Transform } from 'lexical/LexicalEditor';
import React, { useEffect } from 'react';

import { max } from '../decimal';
import {
  $createTranscriptMetadataNode,
  $isTranscriptMetadataNode,
  TranscriptMetadataNode,
} from '../nodes/TranscriptMetadataNode';
import {
  $createTranscriptParagraphNode,
  $isTranscriptParagraphNode,
  TranscriptParagraphNode,
} from '../nodes/TranscriptParagraphNode';
import {
  $isTranscriptTimeBlockNode,
  TranscriptTimeBlockNode,
} from '../nodes/TranscriptTimeBlockNode';
import { $isTranscriptUnitNode, TranscriptUnitNode } from '../nodes/TranscriptUnitNode';

/**
 * Mini-DSL to formulate and document structure enforcing constraints as `lexical` node transforms
 *
 * The reason behind this mini DSL is that node transforms need to be properly documented to stay
 * maintainable. The node transforms should be clearly focussed on simple tasks. The constraint
 * that is to be enforced as well as the means by which it is enforced both need to be documented.
 * The domain specific language implemented by this class ensures that both are present before the
 * node transform can be registered and that an exception is thrown if some of the documentation
 * is missing.
 *
 * The DSL consists of sentences of the following form:
 * Constraint.on(<NodeType>)
 *   .toEnsure(<Description of the Constraint>)
 *   .do(<Description of the transform>)
 *   .byTransforming(<Transform function>)
 *
 * See the rest of the file for examples.
 */

const Constraint = (() => {
  interface NodeTypeInfo<T extends LexicalNode> {
    nodeType: Klass<T>;
  }

  interface GoalInfo<T extends LexicalNode> extends NodeTypeInfo<T> {
    goalDescription: string;
  }

  interface ActionInfo<T extends LexicalNode> extends GoalInfo<T> {
    actionDescription: string;
  }

  interface TransformInfo<T extends LexicalNode> extends ActionInfo<T> {
    nodeTransform: Transform<T>;
  }

  class Phrase<T> {
    protected info: T;

    constructor(info: T) {
      this.info = info;
    }
  }

  interface DescribedConstraint {
    readonly toEnsure: string;
    readonly do: string;
    readonly targetNode: string;
  }

  class CompleteConstraint<T extends LexicalNode> extends Phrase<TransformInfo<T>> {
    public readonly colaborators: DescribedConstraint[] = [];

    describe(): DescribedConstraint {
      return {
        toEnsure: this.info.goalDescription,
        do: this.info.actionDescription,
        targetNode: this.info.nodeType.getType(),
      };
    }

    registerAt(editor: LexicalEditor): () => void {
      return editor.registerNodeTransform(this.info.nodeType, this.info.nodeTransform);
    }

    interactsWith<U extends LexicalNode>(otherConstraint: CompleteConstraint<U>) {
      this.colaborators.push(otherConstraint.describe());
      otherConstraint.colaborators.push(this.describe());
      return this;
    }
  }

  class ConstraintWithAction<T extends LexicalNode> extends Phrase<ActionInfo<T>> {
    byTransforming(nodeTransform: Transform<T>) {
      return new CompleteConstraint({ ...this.info, nodeTransform });
    }
  }

  class ConstraintWithGoal<T extends LexicalNode> extends Phrase<GoalInfo<T>> {
    do(actionDescription: string) {
      return new ConstraintWithAction({ ...this.info, actionDescription });
    }
  }

  class ConstraintWithNodeType<T extends LexicalNode> extends Phrase<NodeTypeInfo<T>> {
    static on<T extends LexicalNode>(nodeType: Klass<T>) {
      return new ConstraintWithNodeType({ nodeType });
    }

    toEnsure(goalDescription: string) {
      return new ConstraintWithGoal({ ...this.info, goalDescription });
    }
  }

  return ConstraintWithNodeType;
})();

const noLineBreakNodes = Constraint.on(LineBreakNode)
  .toEnsure('There are no linebreak')
  .do('remove all linebreaks')
  .byTransforming((lineBreakNode) => {
    lineBreakNode.remove();
  });

const noEmptyParagraphs = Constraint.on(TranscriptParagraphNode)
  .toEnsure('here are no empty Paragraphs')
  .do('remove empty paragraphs')
  .byTransforming((paragraphNode) => {
    if (paragraphNode.isEmpty()) {
      paragraphNode.remove();
    }
  });

const onlyOneParagraphPerTimeBlock = Constraint.on(TranscriptParagraphNode)
  .toEnsure('timeblock nodes contain only a single paragraph')
  .do('merge adjacent paragraphs')
  .byTransforming((paragraphNode) => {
    const sibling = paragraphNode.getPreviousSibling();
    if ($isTranscriptParagraphNode(sibling)) {
      sibling.append(...paragraphNode.getChildren());
      paragraphNode.remove();
    }
  });

const paragraphsAreOnlyAllowedInTimeBlocks = Constraint.on(TranscriptParagraphNode)
  .toEnsure('transcript paragraphs only exist as direct children of time blocks')
  .do('remove any paragraphs which parent is no time block')
  .byTransforming((paragraphNode) => {
    const parent = paragraphNode.getParent();
    if (!$isTranscriptTimeBlockNode(parent)) {
      paragraphNode.remove();
    }
  });

const unitsCannotBeEmpty = Constraint.on(TranscriptUnitNode)
  .toEnsure('transcript units always have text content')
  .do('remove empty transcript units')
  .byTransforming((unitNode) => {
    if (unitNode.getTextContent().trim() === '') {
      unitNode.remove();
    }
  });

const unitsAreAlwaysPartOfAParagraph = Constraint.on(TranscriptUnitNode)
  .toEnsure('a transcript unit is always part of a paragraph')
  .do('wrap units outside of paragraphs in a paragraph')
  .byTransforming((unitNode) => {
    const parent = unitNode.getParent();
    if (!$isTranscriptParagraphNode(parent)) {
      $wrapNodeInElement(unitNode, $createTranscriptParagraphNode);
    }
  });

const noUninitializedUnits = Constraint.on(TranscriptUnitNode)
  .toEnsure('there are no units without time and duration data')
  .do('merge an uninitialized unit to a neighbouring unit or delete it')
  .byTransforming((unitNode) => {
    if (!unitNode.isInitialized()) {
      // try to merge with left neighbour
      const previousSibling = unitNode.getPreviousSibling();
      if ($isTranscriptUnitNode(previousSibling)) {
        previousSibling.mergeWithSibling(unitNode);
      } else {
        // try to merge with right neighbour
        const nextSibling = unitNode.getNextSibling();
        if ($isTranscriptUnitNode(nextSibling)) {
          nextSibling.mergeWithSibling(unitNode);
        } else {
          // if nothing works, delete the unit
          unitNode.remove();
        }
      }
    }
  });

const unitsStartWithSpace = Constraint.on(TranscriptUnitNode)
  .toEnsure(
    'transcript units have a space as their first character, if they are not the first in a paragraph',
  )
  .do(
    'trim the start of of a unit, then add a space at the front if it is not the first in a paragraph',
  )
  .byTransforming((unitNode) => {
    if (!unitNode.isInitialized()) {
      // NOTE: Spaces must only be added after uninitialized units have been merged by `noUninitializedUnits`.
      // Otherwise, spaces will be added to pasted text, which is added as a text node.
      return;
    }
    const textContent = unitNode.getTextContent();
    let newTextContent;
    if (unitNode.getIndexWithinParent() === 0) {
      newTextContent = textContent.trimStart();
    } else {
      newTextContent = ` ${textContent.trimStart()}`;
    }
    if (textContent !== newTextContent) {
      unitNode.setTextContent(newTextContent);
    }
  });

const timeBlockContainsAtLeastOneParagraph = Constraint.on(TranscriptTimeBlockNode)
  .toEnsure('a time block contains at least one paragraph')
  .do('delete a time block without paragraphs')
  .byTransforming((timeBlockNode) => {
    const paragraphChildren = timeBlockNode.getChildren().filter($isTranscriptParagraphNode);
    if (paragraphChildren.length === 0) {
      timeBlockNode.remove();
    }
  });

const timeBlockContainsAtLeastOneMetadataNode = Constraint.on(TranscriptTimeBlockNode)
  .toEnsure('a time block has at least one metadata node')
  .do('add a metadata node if a time block is missing one')
  .byTransforming((timeBlockNode) => {
    const metadataChildren = timeBlockNode.getChildren().filter($isTranscriptMetadataNode);
    if (metadataChildren.length === 0) {
      $insertFirst(timeBlockNode, $createTranscriptMetadataNode(timeBlockNode.getTimeBlockState()));
    }
  });

const timeBlockContainsAtMostOneMetadataNode = Constraint.on(TranscriptTimeBlockNode)
  .toEnsure('a time block contains at most one metadata node')
  .do('remove all extra metadata nodes but the first')
  .byTransforming((timeBlockNode) => {
    const metadataChildren = timeBlockNode.getChildren().filter($isTranscriptMetadataNode);
    const extraMetadataChildren = metadataChildren.slice(1);
    extraMetadataChildren.forEach((extraMetadataNode) => extraMetadataNode.remove());
  });

const timeBlockStartsWithAMetadataNode = Constraint.on(TranscriptTimeBlockNode)
  .toEnsure('a time block starts with a metadata node')
  .do('move the first metadata node to the start if on exists')
  .byTransforming((timeBlockNode) => {
    const children = timeBlockNode.getChildren();
    const firstChild = children.length > 0 ? children[0] : null;
    const metadataNodes = children.filter($isTranscriptMetadataNode);
    const firstMetadataNode = metadataNodes.length > 0 ? metadataNodes[0] : null;

    if (firstChild && firstMetadataNode && firstChild.getKey() !== firstMetadataNode.getKey()) {
      firstChild.insertBefore(firstMetadataNode);
    }
  })
  .interactsWith(timeBlockContainsAtLeastOneMetadataNode);

const metadataNodesAreDirectChildrenOfTimeBlocks = Constraint.on(TranscriptMetadataNode)
  .toEnsure('metadata nodes exist only as direct children of time blocks')
  .do('remove metadata nodes which parent is no time block')
  .byTransforming((metadataNode) => {
    const parent = metadataNode.getParent();
    if (!$isTranscriptTimeBlockNode(parent)) {
      metadataNode.forceRemove();
    }
  });

const metadataIsAlwaysInSyncWithItsTimeBlock = Constraint.on(TranscriptTimeBlockNode)
  .toEnsure('a metadata node always contains the same data as its time block')
  .do('update the metadata node, whenever its time block changes')
  .byTransforming((timeBlockNode) => {
    const metadataNodes = timeBlockNode.getChildren().filter($isTranscriptMetadataNode);
    metadataNodes.forEach((metadataNode) => {
      if (metadataNode.getTimeBlockState() !== timeBlockNode.getTimeBlockState()) {
        metadataNode.setTimeBlockState(timeBlockNode.getTimeBlockState());
      }
    });
  });

const timeBlockDurationIsUpToDate = Constraint.on(TranscriptTimeBlockNode)
  .toEnsure('the duration of a time block stays up to date after a merge')
  .do('update duration if the next speaker node changes')
  .byTransforming((timeBlockNode) => {
    let timeBlockState = timeBlockNode.getTimeBlockState();
    const nextTimeBlockKey = timeBlockNode.getNextSibling()?.getKey();
    if (timeBlockState.nextTimeBlockKey === 'uninitialized') {
      // initialize the nextSpeaker if it has not been initialized yet.
      timeBlockState = { ...timeBlockState, nextTimeBlockKey };
      timeBlockNode.setTimeBlockState(timeBlockState);
      return;
    }
    if (timeBlockState.nextTimeBlockKey !== nextTimeBlockKey) {
      // if a merge occurred the next Speaker changes.
      // Update the duration of the time block from its last unit and set the new nextSpeaker
      const lastUnit = $dfs(timeBlockNode)
        .map(({ node }) => node)
        .filter($isTranscriptUnitNode)
        .at(-1);
      if (lastUnit) {
        const endOfLastUnit = lastUnit.getTime().plus(lastUnit.getDuration());
        const durationToEndOfLastUnit = endOfLastUnit.minus(timeBlockState.startTime);
        // do not shorten the duration of a timeBlock. This should only happen during a split.
        const newDuration = max(timeBlockState.duration, durationToEndOfLastUnit);
        timeBlockNode.setTimeBlockState({
          ...timeBlockState,
          duration: newDuration,
          nextTimeBlockKey,
        });
      }
    }
  });

/**
 * This plugin enforces the editor-state structure that is expected by the transcript editor.
 *
 * The plugin works by registering node transforms that enforce the required structure by
 * transforming editor states not matching the structure into editor states that meet the
 * requirements.
 */
export const TranscriptStructurePlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      noLineBreakNodes.registerAt(editor),
      noEmptyParagraphs.registerAt(editor),
      onlyOneParagraphPerTimeBlock.registerAt(editor),
      paragraphsAreOnlyAllowedInTimeBlocks.registerAt(editor),
      unitsCannotBeEmpty.registerAt(editor),
      unitsAreAlwaysPartOfAParagraph.registerAt(editor),
      noUninitializedUnits.registerAt(editor),
      unitsStartWithSpace.registerAt(editor),
      timeBlockContainsAtLeastOneParagraph.registerAt(editor),
      timeBlockContainsAtLeastOneMetadataNode.registerAt(editor),
      timeBlockContainsAtMostOneMetadataNode.registerAt(editor),
      timeBlockStartsWithAMetadataNode.registerAt(editor),
      metadataNodesAreDirectChildrenOfTimeBlocks.registerAt(editor),
      metadataIsAlwaysInSyncWithItsTimeBlock.registerAt(editor),
      timeBlockDurationIsUpToDate.registerAt(editor),
    );
  }, [editor]);

  return null;
};

export default TranscriptStructurePlugin;
