import { getLinkHref, getResourceTypeName, mlRel, Resource } from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { AudioAsset } from '../../../state/modules/audio/types';
import { ImageAsset } from '../../../state/modules/image/types';
import { ProductionContent } from '../../../state/modules/productions/types';
import { isAssetCollection } from '../../../state/modules/rest/collection/types';
import { Shot, VideoAsset } from '../../../state/modules/video/types';
import { AssetType } from '../../../state/types/asset/baseTypes';
import { Asset, MediaAsset } from '../../../state/types/asset/unionTypes';
import { idFromHref } from '../../services/idFromHref';

export const isAudioAsset = (resource: Resource<string> | null): resource is AudioAsset =>
  !!resource && getResourceTypeName(resource) === 'audioasset';

export const isImageAsset = (resource: Resource<string> | null): resource is ImageAsset =>
  !!resource && getResourceTypeName(resource) === 'imageasset';

export const isVideoAsset = (resource: Resource<string> | null): resource is VideoAsset =>
  !!resource && getResourceTypeName(resource) === 'videoasset';

export const isMediaAsset = (resource: Resource<string> | null): resource is MediaAsset =>
  isVideoAsset(resource) || isImageAsset(resource) || isAudioAsset(resource);

export const isShot = (resource: Resource<string> | null): resource is Shot =>
  !!resource && getResourceTypeName(resource) === 'shot';

export const getEmbeddedSharedWithProductions = (
  asset: MediaAsset,
): ReadonlyArray<ProductionContent> =>
  // eslint-disable-next-line no-underscore-dangle
  (asset._embedded?.[mlRel('shared_with_productions')] as ReadonlyArray<ProductionContent>) || [];

export const SEARCH_RESULT_COLLECTION_TYPE_TO_TYPE_NAME: ReadonlyRecord<string, AssetType> = {
  'audioassetsearchresult-collection': 'audioasset',
  'imageassetsearchresult-collection': 'imageasset',
  'videoassetsearchresult-collection': 'videoasset',
  'collectionsearchresult-collection': 'collection',
};

/**
 * Get the ID of an asset's production.
 *
 * This is required for implementing sharing functionality using API V2 with collections.
 *
 * @deprecated use URLs instead
 */
export const getAssetProductionId = (asset: Asset): number =>
  isAssetCollection(asset)
    ? idFromHref(getLinkHref(asset, 'production') || null)
    : asset.production;
