import { TextField, InputAdornment } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { pgettext } from '../../../Internationalization';
import { Calendar } from '../../../icons';

interface DateTimeInputProps {
  onTimeChange: (date: DateTime) => void;
  hours: DateTime | undefined;
  date: DateTime | undefined;
  dateFormat?: string;
  timeFormat?: string;
  ariaLabel?: string;
}
const DateTimeInput: FC<DateTimeInputProps> = ({
  onTimeChange,
  hours,
  date,
  dateFormat = pgettext('Localized numeric date', 'MM/dd/yyyy'),
  timeFormat = 'HH:mm',
  ariaLabel = '',
}: DateTimeInputProps) => {
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;

  const [value, setValue] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const newHours = hours ? hours.toFormat(timeFormat) : '';
    const newDate = date ? date.toFormat(dateFormat) : '';
    setValue(`${newDate} ${newHours}`);
  }, [hours, date, dateFormat, timeFormat]);

  const onUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleUpdate = () => {
    const nextDate = DateTime.fromFormat(value, dateTimeFormat);
    if (nextDate.isValid) {
      setHasError(false);
      onTimeChange(nextDate);
    } else {
      setHasError(true);
    }
  };

  const handleSpecialKeys = (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event?.key === 'Enter') {
      handleUpdate();
    }
  };

  return (
    <TextField
      value={value}
      onChange={onUserChange}
      error={hasError}
      name="datetime-picker-input"
      data-testid="datetime-picker-input"
      onBlur={() => {
        handleUpdate();
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Calendar />
          </InputAdornment>
        ),
        onKeyDown: handleSpecialKeys,
      }}
      inputProps={{
        'aria-label': ariaLabel,
      }}
    />
  );
};

export default DateTimeInput;
