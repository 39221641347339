import getCookieValue from './getCookieValue';

export const getSessionAuthHeader = (): { 'X-CSRFToken'?: string } => {
  const csrfCookie = getCookieValue('csrftoken');
  return csrfCookie
    ? {
        'X-CSRFToken': csrfCookie,
      }
    : {};
};

export default getSessionAuthHeader;
