import { DesktopDatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { FC, ReactNode } from 'react';

import { useInputStyles } from './internal/InternalTextField';

export type DateInputProps = {
  readonly id?: string;
  readonly label: string;
  readonly value: DateTime | null;
  readonly onChange: (value: DateTime | null) => void;
  readonly minDate?: DateTime;
  readonly disablePast?: boolean;
  readonly showError?: boolean;
  readonly errorMsg?: ReactNode;
  readonly dense?: boolean;
  readonly autoFocus?: boolean;
  readonly readOnly?: boolean;
};

const DateInput: FC<DateInputProps> = ({
  id,
  value,
  minDate,
  disablePast,
  label,
  dense,
  onChange,
  showError,
  errorMsg,
  autoFocus,
  readOnly,
}: DateInputProps) => {
  const classes = useInputStyles();
  return (
    <DesktopDatePicker<DateTime>
      slotProps={{
        textField: {
          id,
          error: showError,
          helperText: showError ? errorMsg : null,
          fullWidth: true,
          variant: 'outlined',
          size: 'small',
          FormHelperTextProps: { role: 'alert' },
          autoFocus,
          label,
          classes: {
            root: clsx({
              [classes.inputDense]: dense,
            }),
          },
        },
      }}
      label={label}
      value={value}
      disablePast={disablePast}
      minDate={minDate}
      onChange={(newValue: DateTime | null) => {
        if (value?.toISO() !== newValue?.toISO()) {
          onChange(newValue);
        }
      }}
      readOnly={readOnly}
      disabled={readOnly}
    />
  );
};

export default DateInput;
