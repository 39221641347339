import { FC } from 'react';

import { Input } from 'medialoopster/components';

import useWidgetContext from '../hooks/useWidgetContext';

type Props = {
  readonly name: string;
  readonly label: string;
  readonly defaultValue: string;
  readonly disabled?: boolean;
  readonly maxLength?: number;
};

const StringWidget: FC<Props> = ({
  name,
  label,
  maxLength,
  defaultValue,
  disabled = false,
}: Props) => {
  const { onChange, onBlur, value, error } = useWidgetContext({
    name,
    defaultValue,
    rules: {
      maxLength,
    },
    disabled,
  });
  return (
    <Input
      value={value}
      label={label}
      readOnly={disabled}
      errorMsg={error}
      showError={!!error}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={maxLength}
      multiline
    />
  );
};

export default StringWidget;
