import jsonpointer from 'json-pointer';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { fieldObjectToFormField, FormField, linkObjectToFormField } from 'medialoopster/forms';
import {
  BaseResource,
  EmbeddedObjectV3,
  FieldObjectV3,
  getResourceURI,
  LinkObjectV3,
  ResourceActionV3,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { AssetType, SearchResultAssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { getCurrentAssetTypeCategories, getCurrentAssetTypeKeywords } from '../keywords/selectors';
import { KeywordCategory, KeywordCategoryType } from '../keywords/types';
import { getSelectedAssets } from '../operations/selectors';
import { getSearchCategory, getSelection } from '../search/selectors';
import { SelectedAssetsState } from '../search/types';
import {
  BulkEditActionsState,
  BulkEditRootState,
  KeywordsErrors,
  KeywordsField,
  KeywordsValue,
} from './types';

/**
 * Whether the Bulk Edit modal is open.
 */
export const isBulkEditModalOpen = (state: RootState): boolean => state.bulkEdit.modalIsOpen;

export const getBulkEditActionsState = createSelector(
  getSearchCategory,
  (state: RootState) => state.bulkEdit.actions,
  (
    assetType: SearchResultAssetType,
    actions: BulkEditRootState['actions'],
  ): BulkEditActionsState => {
    return actions[assetType];
  },
);

/**
 * Get the URL of the bulk edit action.
 */
export const getBulkEditHref = createSelector(
  getBulkEditActionsState,
  (bulkEditActionState: BulkEditActionsState): string | null =>
    bulkEditActionState?.bulkEditLink?.href || null,
);

/**
 * Check whether the user is allowed to bulk edit assets of the selected type.
 */
export const isBulkEditAllowed = createSelector(
  getBulkEditHref,
  getBulkEditActionsState,
  (bulkEditHref: string | null, bulkEditActionsState: BulkEditActionsState | null): boolean => {
    if (!bulkEditHref) {
      return false;
    }
    if (!bulkEditActionsState) {
      return false;
    }
    const options = bulkEditActionsState.bulkEditResources.options[bulkEditHref];
    const fields = options?.actions?.POST?.embedded;
    if (!fields) {
      return false;
    }
    const assetUpdatesFields = fields.asset_updates?.fields;
    const shotUpdatesFields = fields.shot_updates?.fields;
    return (
      (!!assetUpdatesFields && Object.entries(assetUpdatesFields).length > 0) ||
      (!!shotUpdatesFields && Object.entries(shotUpdatesFields).length > 0)
    );
  },
);

/**
 * Get the available bulk edit field definitions, depending on the selected assets.
 */
export const getBulkEditFields = createSelector(
  (state: RootState): ResourceActionV3 | null => state.bulkEdit.assetsBulkEditFields,
  (bulkEditFields: ResourceActionV3 | null): ReadonlyArray<FormField> => {
    if (!bulkEditFields) {
      return [];
    }
    const { embedded } = bulkEditFields;
    if (!embedded) {
      return [];
    }
    const attrs: ReadonlyArray<'asset_updates' | 'shot_updates'> = [
      'asset_updates',
      'shot_updates',
    ];
    return _.concat(
      ...attrs.map((attr) =>
        _.concat(
          Object.entries(embedded[attr]?.fields || {})
            .map(([name, field]: [string, FieldObjectV3]): FormField | null =>
              fieldObjectToFormField(`/_embedded/${attr}`, 'info', name, field),
            )
            .filter((field): field is NonNullable<FormField> => !!field),
          Object.entries(embedded[attr]?.links || {}).map(
            ([name, field]: [string, LinkObjectV3]): FormField =>
              linkObjectToFormField(`/_embedded/${attr}`, 'info', name, field),
          ),
        ),
      ),
    );
  },
);

export const getFieldsErrors = (state: RootState): ReadonlyRecord<string, ReadonlyArray<string>> =>
  state.bulkEdit.formFields.errors;

export const getKeywordsFieldsErrors = (state: RootState): ReadonlyRecord<string, KeywordsErrors> =>
  state.bulkEdit.formFields.keywordsErrors;

/**
 * Whether the "Edit" menu entry should be used to edit multiple assets.
 */
export const isBulkEdit = createSelector(
  isBulkEditAllowed,
  getSelection,
  (bulkEditAllowed: boolean, selection: SelectedAssetsState): boolean =>
    bulkEditAllowed && selection.assetIds.length > 1,
);

/**
 * Get the URL of the lock action.
 */
export const getLockAssetsHref = createSelector(
  getBulkEditActionsState,
  (bulkEditActionsState: BulkEditActionsState): string | null =>
    bulkEditActionsState?.lockAssetsLink?.href || null,
);

/**
 * Get the URL of the unlock action.
 */
export const getUnlockAssetsHref = createSelector(
  getBulkEditActionsState,
  (bulkEditActionsState: BulkEditActionsState): string | null =>
    bulkEditActionsState?.unlockAssetsLink?.href || null,
);

/**
 * Get a resource containing the selected assets as `assets_list` links.
 */
export const getAssetsBulkResource = createSelector(
  getSelectedAssets,
  (selectedAssets: ReadonlyArray<Asset>): BaseResource => {
    return {
      _links: {
        assets_list: selectedAssets.map((asset) => ({ href: getResourceURI(asset) })),
      },
    };
  },
);

const getKeywordsUpdatesFieldName = createSelector(
  getSearchCategory,
  (assetType: AssetType): 'shot_updates' | 'asset_updates' => {
    return assetType === 'videoasset' ? 'shot_updates' : 'asset_updates';
  },
);

const getKeywordsUpdatesField = createSelector(
  getKeywordsUpdatesFieldName,
  (state: RootState): ResourceActionV3 | null => state.bulkEdit.assetsBulkEditFields,
  (updatesFieldName: string, bulkEditFields: ResourceActionV3 | null): EmbeddedObjectV3 | null =>
    bulkEditFields?.embedded?.[updatesFieldName] || null,
);

export const getKeywordsInAnyAsset = createSelector(
  getKeywordsUpdatesField,
  (updatesField: EmbeddedObjectV3 | null): ReadonlyArray<string> => {
    const removedKeywordsChoices = updatesField?.fields?.removed_keywords?.child?.choices;
    if (!removedKeywordsChoices) {
      return [];
    }
    return removedKeywordsChoices.map(({ value }) => value.toString());
  },
);

export const getKeywordsInAllAssets = createSelector(
  getCurrentAssetTypeKeywords,
  getKeywordsUpdatesField,
  (
    currentAssetTypeKeywords: ReadonlyArray<string>,
    updatesField: EmbeddedObjectV3 | null,
  ): ReadonlyArray<string> => {
    const addedKeywordsChoices = updatesField?.fields?.added_keywords?.child?.choices;
    if (!addedKeywordsChoices) {
      return [];
    }
    return _.difference(
      currentAssetTypeKeywords,
      addedKeywordsChoices.map(({ value }) => value.toString()),
    );
  },
);

export const canAddKeywords = createSelector(
  getKeywordsUpdatesField,
  (updatesField: EmbeddedObjectV3 | null): boolean => !!updatesField?.fields?.added_keywords,
);

export const canRemoveKeywords = createSelector(
  getKeywordsUpdatesField,
  (updatesField: EmbeddedObjectV3 | null): boolean => !!updatesField?.fields?.removed_keywords,
);

export const makeKeywordsField = (
  updatesFieldName: 'shot_updates' | 'asset_updates',
  { name, label, keywords }: { name: string; label: string; keywords: ReadonlyArray<string> },
): KeywordsField => {
  const fieldPointer = `/_embedded/${updatesFieldName}`;
  return {
    type: 'keywords',
    name: `keywords__${name}`,
    label,
    defaultValue: { added_keywords: [], removed_keywords: [] },
    set: (resource: BaseResource, value: KeywordsValue) => {
      const prevValue: KeywordsValue | undefined = _.get(resource, `_embedded.${updatesFieldName}`);
      const fieldValue = {
        ...(prevValue || {}),
        added_keywords: [...(prevValue?.added_keywords || []), ...value.added_keywords],
        removed_keywords: [...(prevValue?.removed_keywords || []), ...value.removed_keywords],
      };
      jsonpointer.set(resource, fieldPointer, fieldValue);
    },
    getErrors: (resource, errors) =>
      _.mapValues(
        _.groupBy(
          errors.filter(
            (error) =>
              error.source.pointer.startsWith(`${fieldPointer}/added_keywords/`) ||
              error.source.pointer.startsWith(`${fieldPointer}/removed_keywords/`),
          ),
          (error) => {
            const keyword = jsonpointer.get(resource, error.source.pointer);
            return String(keyword);
          },
        ),
        (keywordErrors) => keywordErrors.map(({ detail }) => detail),
      ),
    keywords,
  };
};

export const getKeywordCategoryBulkEditFields = createSelector(
  canAddKeywords,
  getCurrentAssetTypeCategories,
  getKeywordsUpdatesFieldName,
  (
    addKeywords: boolean,
    keywordCategories: ReadonlyArray<KeywordCategory<KeywordCategoryType>>,
    updatesFieldName: 'shot_updates' | 'asset_updates',
  ): ReadonlyArray<KeywordsField> =>
    addKeywords
      ? keywordCategories.map((category) =>
          makeKeywordsField(updatesFieldName, {
            name: String(category.id),
            label: category.name,
            keywords: category.keywords,
          }),
        )
      : [],
);
