import { FC } from 'react';
import { useSelector } from 'react-redux';

import { getLink, getLinkHref, getResource, mlRel } from 'medialoopster/rest';

import { productionsSelectors } from '../../../state/modules/productions';
import { Asset } from '../../../state/types/asset/unionTypes';
import Text from './Text';

type Props = {
  readonly asset: Asset;
};

const Production: FC<Props> = ({ asset }: Props) => {
  const productionsMap = useSelector(productionsSelectors.getProductionsMap);
  const getProductionTitle = () => {
    const productionTitle = getLink(asset, 'production')?.title;
    if (productionTitle) {
      return productionTitle;
    }
    const productionURL = getLinkHref(asset, mlRel('production'));
    if (!productionURL) {
      return '';
    }
    const production = getResource(productionsMap, productionURL);
    if (!production) {
      return '';
    }
    return production.name;
  };
  return <Text value={getProductionTitle()} />;
};

export default Production;
