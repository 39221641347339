import { TypographyProps } from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { FC, ReactNode } from 'react';

import InplaceDateTimeEdit from '../../../ui/components/InplaceEdits/InplaceDateTimeEdit';

type Props = {
  readonly value: string | null;
  readonly canEdit?: boolean;
  readonly variant?: TypographyProps['variant'];
  readonly onSubmit?: (value: string | null) => void;
};

const CalendarDateTime: FC<Props> = ({
  value,
  canEdit = false,
  variant = 'body2',
  onSubmit,
}: Props) => {
  let textComponents: ReactNode = value ?? '';
  if (value) {
    textComponents = (
      <>
        {DateTime.fromISO(value).toLocaleString({
          ...DateTime.DATETIME_MED_WITH_SECONDS,
          month: 'long',
        })}
      </>
    );
  }
  const editValue = value ?? '';
  return (
    <InplaceDateTimeEdit
      textComponents={<span>{textComponents}</span>}
      onSubmit={onSubmit}
      editValue={editValue}
      canEdit={canEdit}
      variant={variant}
      dense
      minWidth="250px"
    />
  );
};

export default CalendarDateTime;
