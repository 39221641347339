import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo } from 'react';

import BaseCell, { BaseCellProps } from './BaseCell';

const useStyles = makeStyles(() => ({
  iconPadding: {
    width: 1,
    padding: '0 14px 0 14px',
    '& > *': {
      marginBottom: '-0.2em',
    },
    '&.MuiTableCell-sizeSmall': {
      padding: '0 14px 0 14px',
    },
  },
  noBorder: { borderBottom: 'none' },
}));

export type IconCellProps = Pick<BaseCellProps, 'disabled' | 'children'> & {
  noBorder?: boolean;
  readonly component?: 'span';
};

const IconCell: FC<IconCellProps> = ({
  disabled,
  children,
  noBorder = false,
  component,
}: IconCellProps) => {
  const classes = useStyles();
  return (
    <BaseCell
      component={component}
      className={clsx(classes.iconPadding, { [classes.noBorder]: noBorder })}
      disabled={disabled}
    >
      {children}
    </BaseCell>
  );
};

export default memo(IconCell);
