import { combineReducers } from 'redux';

import { ConnectorResource } from 'medialoopster/modules';
import { DeepReadonly } from 'medialoopster/types';

import {
  ASSET_EXCHANGE_CONNECTORS_LOADED,
  AssetExchangeConnectorsLoaded,
  EXCHANGED_ASSETS_REMOVE_ACTION_CHECKED,
  ExchangedAssetsRemoveActionChecked,
  OperationsRootState,
  Webhook,
  WEBHOOKS_LOADED,
  WebhooksLoaded,
} from './types';

export const webhooksReducer = (
  state: ReadonlyArray<Webhook> = [],
  { type, payload }: WebhooksLoaded,
): ReadonlyArray<Webhook> => {
  switch (type) {
    case WEBHOOKS_LOADED:
      return payload.instances;
    default:
      return state;
  }
};

export const assetExchangeConnectorsReducer = (
  state: ReadonlyArray<ConnectorResource<'assetexchangeconnector'>> = [],
  { type, payload }: AssetExchangeConnectorsLoaded,
): ReadonlyArray<ConnectorResource<'assetexchangeconnector'>> => {
  switch (type) {
    case ASSET_EXCHANGE_CONNECTORS_LOADED:
      return payload.instances;
    default:
      return state;
  }
};

export const assetExchangeRemoveReducer = (
  state: DeepReadonly<Record<string, boolean>> = {},
  { type, payload }: ExchangedAssetsRemoveActionChecked,
): DeepReadonly<Record<string, boolean>> => {
  switch (type) {
    case EXCHANGED_ASSETS_REMOVE_ACTION_CHECKED:
      return {
        ...state,
        [payload.connectorURI]: payload.isSuccess,
      };
    default:
      return state;
  }
};

export default combineReducers<OperationsRootState>({
  webhooks: webhooksReducer,
  assetExchangeConnectors: assetExchangeConnectorsReducer,
  isAssetExchangeRemove: assetExchangeRemoveReducer,
});
