import _ from 'lodash';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { alertMessage, AlertMessage } from 'medialoopster/AlertMessage';
import { gettext } from 'medialoopster/Internationalization';
import {
  APIFieldError,
  createFetchCollectionOptionsEpic,
  isAPIFieldError,
  ResourceLoadedAction,
  ResourceOptionsLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
  isUnauthorizedRequestAction,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import {
  assetsBulkEditFieldsLoaded,
  closeBulkEditModal,
  lockAssetsSuccess,
  setBulkEditFieldErrors,
  setBulkEditKeywordsFieldErrors,
} from './actions';
import {
  getBulkEditHref,
  getLockAssetsHref,
  getAssetsBulkResource,
  getUnlockAssetsHref,
  getBulkEditFields,
  getKeywordCategoryBulkEditFields,
} from './selectors';
import {
  AssetsBulkEditFieldsLoaded,
  CloseBulkEditModal,
  CLOSE_BULK_EDIT_MODAL,
  LockAssetsSuccess,
  OpenBulkEditModal,
  OPEN_BULK_EDIT_MODAL,
  SetBulkEditFieldErrors,
  SetBulkEditKeywordsFieldErrors,
  SubmitBulkEdit,
  SUBMIT_BULK_EDIT,
} from './types';

export const fetchAssetsBulkEditFieldsEpic: Epic<
  Action,
  AssetsBulkEditFieldsLoaded | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchOptionsFor }) =>
  action$.pipe(
    ofType<Action, OpenBulkEditModal['type'], OpenBulkEditModal>(OPEN_BULK_EDIT_MODAL),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const bulkEditHref = getBulkEditHref(state);
      if (!bulkEditHref) {
        return EMPTY;
      }
      return fetchOptionsFor(bulkEditHref, 'POST', getAssetsBulkResource(state)).pipe(
        mergeMap((response) => {
          if (isUnauthorizedRequestAction(response)) {
            return of(response);
          }
          return response.actions.POST
            ? of(assetsBulkEditFieldsLoaded(response.actions.POST))
            : EMPTY;
        }),
      );
    }),
  );

export const lockAssetsEpic: Epic<
  Action,
  LockAssetsSuccess | ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<Action, OpenBulkEditModal['type'], OpenBulkEditModal>(OPEN_BULK_EDIT_MODAL),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const lockHref = getLockAssetsHref(state);
      if (!lockHref) {
        return EMPTY;
      }
      return postResource(
        lockHref,
        getAssetsBulkResource(state),
        () => of(lockAssetsSuccess()),
        () => of(alertMessage(gettext('Some assets could not be locked.'))),
        { version: 3 },
      );
    }),
  );

export const unlockAssetsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<Action, CloseBulkEditModal['type']>(CLOSE_BULK_EDIT_MODAL),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const unlockHref = getUnlockAssetsHref(state);
      if (!unlockHref) {
        return EMPTY;
      }
      return postResource(unlockHref, getAssetsBulkResource(state), () => EMPTY, undefined, {
        version: 3,
      });
    }),
  );

export const submitBulkEditEpic: Epic<
  Action,
  | CloseBulkEditModal
  | SetBulkEditFieldErrors
  | SetBulkEditKeywordsFieldErrors
  | ResourceLoadedAction
  | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<Action, SubmitBulkEdit['type'], SubmitBulkEdit>(SUBMIT_BULK_EDIT),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { bulkEditResource },
        },
        state,
      ]) => {
        const bulkEditHref = getBulkEditHref(state);
        if (!bulkEditHref) {
          return EMPTY;
        }
        const bulkEditFields = getBulkEditFields(state);
        const keywordCategoryBulkEditFields = getKeywordCategoryBulkEditFields(state);
        return postResource(
          bulkEditHref,
          _.merge({}, getAssetsBulkResource(state), bulkEditResource),
          // TODO: Reload assets on success.
          () => of(closeBulkEditModal()),
          (err) => {
            if (!err.response.errors) {
              return EMPTY;
            }
            const fieldErrors = err.response.errors.filter((error): error is APIFieldError =>
              isAPIFieldError(error),
            );
            return of(
              setBulkEditFieldErrors(
                Object.fromEntries(
                  bulkEditFields.map(({ name, getErrors }) => [
                    name,
                    getErrors(bulkEditResource, fieldErrors),
                  ]),
                ),
              ),
              setBulkEditKeywordsFieldErrors(
                Object.fromEntries(
                  keywordCategoryBulkEditFields.map(({ name, getErrors }) => [
                    name,
                    getErrors(bulkEditResource, fieldErrors),
                  ]),
                ),
              ),
            );
          },
          { version: 3 },
        );
      },
    ),
  );

export default combineEpics<
  Action,
  | AssetsBulkEditFieldsLoaded
  | CloseBulkEditModal
  | SetBulkEditFieldErrors
  | SetBulkEditKeywordsFieldErrors
  | ResourceLoadedAction
  | ResourceOptionsLoadedAction<string>
  | LockAssetsSuccess
  | AlertMessage
  | UnauthorizedRequestAction,
  RootState
>(
  createFetchCollectionOptionsEpic('videoassets_bulk_edit', { version: 3 }),
  createFetchCollectionOptionsEpic('imageassets_bulk_edit', { version: 3 }),
  createFetchCollectionOptionsEpic('audioassets_bulk_edit', { version: 3 }),
  fetchAssetsBulkEditFieldsEpic,
  lockAssetsEpic,
  unlockAssetsEpic,
  submitBulkEditEpic,
);
