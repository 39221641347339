import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Alert } from 'medialoopster/AlertMessage';
import { UserNotification } from 'medialoopster/components';

import { alertsActions, alertsSelectors } from '../../state/modules/alerts';

const useStyles = makeStyles(() => ({
  anchorOriginTop: { top: 12 },
}));

const UserNotifications: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [hasExited, setHasExited] = useState(true);
  const [userNotification, setUserNotification] = useState<Partial<Alert>>({});
  const userNotifications = useSelector(alertsSelectors.getUserAlerts, shallowEqual);

  const handleClose = () => {
    dispatch(alertsActions.archiveLastUserAlert());
    setIsOpen(false);
  };

  useEffect(() => {
    if (userNotifications.length > 0 && !isOpen && hasExited) {
      setUserNotification({ ...userNotifications[0] });
      setIsOpen(true);
      setHasExited(false);
    }
  }, [userNotifications, isOpen, setIsOpen, hasExited, setHasExited]);

  const handleExited = () => {
    setIsOpen(false);
    setHasExited(true);
    setUserNotification({});
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      classes={{ anchorOriginTopCenter: classes.anchorOriginTop }}
      autoHideDuration={userNotification?.timeout}
      onClose={handleClose}
      TransitionComponent={Slide}
      ClickAwayListenerProps={{ mouseEvent: false }}
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <UserNotification
        feedback={userNotification?.feedback}
        message={userNotification?.message ? userNotification.message : ''}
        onClose={handleClose}
      />
    </Snackbar>
  );
};
export default UserNotifications;
