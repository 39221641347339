import TableCell, { TableCellProps } from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo } from 'react';

const useStyles = makeStyles(() => ({
  disabled: {
    color: 'rgba(0,0,0,0.26)',
  },
}));

export interface BaseCellProps {
  readonly disabled?: boolean;
  readonly className?: string;
  readonly align?: TableCellProps['align'];
  readonly padding?: TableCellProps['padding'];
  readonly children?: TableCellProps['children'];
  readonly component?: 'span';
}

const BaseCell: FC<BaseCellProps> = ({
  disabled = false,
  className = undefined,
  align = 'left',
  padding = 'normal',
  component = undefined,
  children,
}: BaseCellProps) => {
  const classes = useStyles();
  return (
    <TableCell
      component={component}
      padding={padding}
      className={clsx(className, { [classes.disabled]: disabled })}
      align={align}
    >
      {children}
    </TableCell>
  );
};

export default memo(BaseCell);
