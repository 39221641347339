import { Action } from 'redux';
import { ofType, combineEpics, Epic } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  ResourceLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import * as usersSelectors from './selectors';
import { FETCH_USERS } from './types';

export const fetchUsersEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { fetchCollection }) =>
  action$.pipe(
    ofType(FETCH_USERS),
    switchMap(() => fetchCollection('/api/users/')),
  );

export const clearLocalStorageEpic: Epic<Action, Action, RootState> = (_action$, state$) =>
  state$.pipe(
    map((state) => usersSelectors.getCurrentUserURI(state)),
    filter((currentUserURI): currentUserURI is string => !!currentUserURI),
    distinctUntilChanged(),
    mergeMap((currentUserURI) => {
      // Check if there is user switch which should trigger clear cache
      const lastUserURI = localStorage.getItem('mlUserURI');
      if (lastUserURI && lastUserURI !== currentUserURI) {
        // Clear storage if new user logged in
        localStorage.clear();
      }
      // Save current user URI
      localStorage.setItem('mlUserURI', currentUserURI);
      return EMPTY;
    }),
  );

export default combineEpics(fetchUsersEpic, clearLocalStorageEpic);
