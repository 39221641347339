/* eslint-disable no-underscore-dangle */

/* eslint-disable class-methods-use-this */

/**
 * This module implements a custom node representing the time block demarcated by a speaker.
 */
import { ElementNode, LexicalNode, NodeKey } from 'lexical';

import { TIME_BLOCK_CLASS_NAME } from '../css';
import { Decimal } from '../decimal';

/**
 * Type of the Speaker ID stored in a timed block. This is either the id of the
 * speaker as retrieved from the backend or 'new' if the speaker was newly created.
 */
export type SpeakerId = number | 'new';

/**
 * The node key of the next TranscriptTimeBlockNode in the transcript.
 *
 * This is used to recognize split, merge and delete operations on time blocks that may lead to
 * the necessity to recompute the duration of a time block.
 */
export type NextTimeBlockKey = NodeKey | 'uninitialized' | undefined;

/**
 * The state/metadata of the timeblock.
 *
 * The state of the time block is kept in a single object instead of multiple attributes to
 * make the synchronization easier between the TranscriptTimeBlockNode, that manages the data, and
 * its TranscriptMetadataNode child, that displays the data.
 */
export interface TimeBlockState {
  /**
   * The name of the speaker that gives rise to the time block.
   */
  readonly speakerName: string;
  /**
   * The start time of the time block.
   */
  readonly startTime: Decimal;
  /**
   * The duration of the time block.
   */
  readonly duration: Decimal;
  /**
   * The ID of the speaker of this time block.
   */
  readonly speakerId: SpeakerId;
  /**
   * The next speaker block in the transcript.
   *
   * This is used to detect merges.
   */
  readonly nextTimeBlockKey: NextTimeBlockKey;
}

/**
 * A lexical ElementNode that groups transcript units spoken by a single speaker instance.
 *
 * The TranscriptTimeBlock spans the speaking time of a single speaker (uninterrupted time the
 * speaker speaks; tim + duration) and contains all the transcript units that occur in this time
 * span. In addition to the transcript units it is designed to contain a single
 * TranscriptMetadataNode to display its TimeBlockState metadata.
 */
export class TranscriptTimeBlockNode extends ElementNode {
  __metadata: TimeBlockState;

  static getType(): string {
    return 'time-block';
  }

  static clone(node: TranscriptTimeBlockNode): TranscriptTimeBlockNode {
    return new TranscriptTimeBlockNode(node.__metadata, node.__key);
  }

  constructor(metadata: TimeBlockState, key?: NodeKey) {
    super(key);
    this.__metadata = metadata;
  }

  /**
   * Get the TimeBlockState.
   */
  getTimeBlockState(): TimeBlockState {
    return this.getLatest().__metadata;
  }

  /**
   * Set the TimeBlockState
   * @param metadata The new state/metadata.
   */
  setTimeBlockState(metadata: TimeBlockState): void {
    this.getWritable().__metadata = metadata;
  }

  /**
   * Get the speaker name of this time block.
   */
  getSpeakerName(): string {
    return this.getTimeBlockState().speakerName;
  }

  /**
   * Set the speaker Name of the time block.
   * @param speakerName The new speaker name.
   */
  setSpeakerName(speakerName: string): void {
    this.setTimeBlockState({ ...this.getTimeBlockState(), speakerName });
  }

  /**
   * Get the start time of the speaker / time block.
   */
  getStartTime(): Decimal {
    return this.getTimeBlockState().startTime;
  }

  /**
   * Get the duration of the speaker / time block.
   */
  getDuration(): Decimal {
    return this.getTimeBlockState().duration;
  }

  /**
   * Get the id of the speaker represented by this time block.
   */
  getSpeakerId(): SpeakerId {
    return this.getTimeBlockState().speakerId;
  }

  /*
  The usual createDOM implementation, that also sets a css class for easy styling.
   */
  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    dom.className = TIME_BLOCK_CLASS_NAME;
    return dom;
  }

  /*
  No updates to the dom element needed.
   */
  updateDOM(): boolean {
    return false;
  }
}

/**
 * Create a new TranscriptTimeBlockNode.
 * @param speakerName The name of the speaker of the time block.
 * @param startTime The start time of the time block.
 * @param duration The duration of the time block.
 * @param speakerId The id of the speaker speaking in the time block.
 */
export const $createTranscriptTimeBlockNode = (
  speakerName: string,
  startTime: Decimal,
  duration: Decimal,
  speakerId: SpeakerId,
): TranscriptTimeBlockNode =>
  new TranscriptTimeBlockNode({
    speakerName,
    startTime,
    duration,
    speakerId,
    nextTimeBlockKey: 'uninitialized',
  });

/**
 * Type predicate for TranscriptTimeBlockNode.
 * @param node The node to test.
 */
export const $isTranscriptTimeBlockNode = (
  node: LexicalNode | null | undefined,
): node is TranscriptTimeBlockNode => node instanceof TranscriptTimeBlockNode;
