import { combineEpics, Epic } from 'redux-observable';
import { catchError } from 'rxjs/operators';

const createRootEpic =
  (...epics: ReadonlyArray<Epic>): Epic =>
  (action$, store$, dependencies) =>
    combineEpics(...epics)(action$, store$, dependencies).pipe(
      catchError((error, source) => {
        // eslint-disable-next-line no-console
        console.error(error);
        return source;
      }),
    );

export default createRootEpic;
