import { Resource, ResourceMap, ResourceLoadedAction } from 'medialoopster/rest';

import { MediaType } from '../../types/asset/baseTypes';

export const FETCH_KEYWORDCATEGORY = '/FRONTEND/KEYWORDS/FETCH_KEYWORDCATEGORY';
export interface FetchKeywordCategory {
  readonly type: typeof FETCH_KEYWORDCATEGORY;
  readonly payload: { readonly url: string };
}

export type KeywordCategoryType =
  | 'videokeywordcategory'
  | 'audiokeywordcategory'
  | 'imagekeywordcategory';

export type KeywordCategoryLoaded<T extends KeywordCategoryType> = ResourceLoadedAction<
  KeywordCategory<T>
>;

export interface KeywordCategory<T extends KeywordCategoryType> extends Resource<T> {
  readonly id: number;
  readonly name: string;
  readonly keywords: ReadonlyArray<string>;
  readonly productions: ReadonlyArray<number>;
}

export interface CategoryKeywords {
  // Name of the keyword category.
  readonly name: string;

  // Keywords of this category, which have been assigned to the asset/shot/sequence.
  readonly keywords: ReadonlyArray<string>;

  // Available keywords in the category.
  readonly availableKeywords: ReadonlyArray<string>;
}

export type KeywordsRootState = {
  readonly categories: {
    readonly [T in MediaType]: ResourceMap<KeywordCategory<`${T}keywordcategory`>>;
  };
};
