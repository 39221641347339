import { SvgIconProps } from '@mui/material';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Audio, Image, Video, Collection } from 'medialoopster/icons';

import { AssetType } from '../../state/types/asset/baseTypes';

type Props = {
  readonly typeName: AssetType;
  readonly size?: SvgIconProps['fontSize'];
};

const AssetIcon: FC<Props> = ({ typeName, size }: Props) => {
  if (typeName === 'videoasset') {
    return <Video fontSize={size} aria-label={gettext('videoasset')} aria-hidden={false} />;
  }
  if (typeName === 'imageasset') {
    return <Image fontSize={size} aria-label={gettext('imageasset')} aria-hidden={false} />;
  }
  if (typeName === 'audioasset') {
    return <Audio fontSize={size} aria-label={gettext('audioasset')} aria-hidden={false} />;
  }
  if (typeName === 'collection') {
    return <Collection fontSize={size} aria-label={gettext('collection')} aria-hidden={false} />;
  }
  return null;
};
export default AssetIcon;
