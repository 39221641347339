import { createSelector } from 'reselect';

import { createCollectionSelector, normalizeURI } from 'medialoopster/rest';
import { ValueOf } from 'medialoopster/types';

import { RootState } from '../../types/rootState';
import { keywordsSelectors, keywordsTypes } from '../keywords';
import { KeywordCategoryType } from '../keywords/types';
import { personsSelectors } from '../persons';
import { searchSelectors } from '../search';
import { SearchFieldMapping, SearchFieldMappingWithOptions } from './types';

export const assetTypeToFieldTypeMap: Record<string, ValueOf<Pick<SearchFieldMapping, 'type'>>> = {
  videoasset: 'Video',
  audioasset: 'Audio',
  imageasset: 'Image',
  collection: 'Project',
};

export const getSearchFieldMappingCollection = createCollectionSelector<
  RootState,
  SearchFieldMapping
>(
  () => normalizeURI('/api/searchfieldmappings/'),
  (state: RootState) => state.searchfieldmappings.searchfieldmappingCollections,
  (state: RootState) => state.searchfieldmappings.searchfieldmappings,
);

export const getSearchFieldMappingsForSelectedSearchType = createSelector(
  searchSelectors.getSelectedAssetsTypeName,
  getSearchFieldMappingCollection,
  (assetType, searchfieldmappingCollection): ReadonlyArray<SearchFieldMapping> => {
    if (assetType === null) {
      return [];
    }
    const fieldType = assetTypeToFieldTypeMap[assetType];
    return searchfieldmappingCollection.items.filter((field) => field.type === fieldType);
  },
);

const getSearchFieldKeywords = <T extends KeywordCategoryType>(
  categories: ReadonlyArray<keywordsTypes.KeywordCategory<T>>,
  ids: ReadonlyArray<number>,
) => categories.filter((category) => ids.includes(category.id)).flatMap(({ keywords }) => keywords);

const getFieldSelectOptions = (field: SearchFieldMapping): SearchFieldMappingWithOptions => {
  const defaultOptions: ValueOf<Pick<SearchFieldMappingWithOptions, 'selectOptions'>> = [
    { value: '', display: '---' },
  ];
  const selectOptions = field.select_options
    ? [
        ...defaultOptions,
        ...field.select_options.map(({ value, display_value }) => ({
          value,
          display: display_value,
        })),
      ]
    : [];
  return { ...field, selectOptions };
};

export const getCurrentSearchFieldMappingsWithOptions = createSelector(
  getSearchFieldMappingsForSelectedSearchType,
  keywordsSelectors.getCurrentCategoryMediaType,
  keywordsSelectors.getCurrentAssetTypeCategories,
  personsSelectors.getKnownPersons,
  (
    currentSearchFields,
    currentCategoryType,
    currentKeywordCategories,
    persons,
  ): ReadonlyArray<SearchFieldMappingWithOptions> => {
    return currentSearchFields.map((field: SearchFieldMapping) => {
      if (field.input_type === 'Keyword' && currentCategoryType) {
        const fieldCategoryIds = field[`${currentCategoryType}keywordcategories`];
        const fieldKeywords = getSearchFieldKeywords(currentKeywordCategories, fieldCategoryIds);
        return { ...field, keywords: fieldKeywords };
      }
      if (field.input_type === 'Person') {
        const displayNames = persons.map((person) => person.display_name);
        return { ...field, persons: displayNames };
      }
      if (field.input_type === 'Select' || field.input_type === 'Exists') {
        return getFieldSelectOptions(field);
      }
      return field;
    });
  },
);
