import { Avatar } from '@mui/material';
import { FC, memo } from 'react';

interface UserAvatarProps {
  firstName: string;
  lastName: string;
}

/**
 * The UserAvatar is shown as a letter avatar where the letters are formed by the first letters
 * of the words of the username.
 *
 * The component is responsible for extracting the initials and displaying the avatar.
 *
 * @param firstName The first name of the user.
 * @param lastName The lastName name of the user.
 */
const UserAvatar: FC<UserAvatarProps> = ({ firstName, lastName }: UserAvatarProps) => {
  const firstLetter = firstName.length > 0 ? firstName[0].toUpperCase() : '';
  const secondLetter = lastName.length > 0 ? lastName[0].toUpperCase() : '';
  const avatarLabel = `${firstLetter}${secondLetter}`;

  return (
    <Avatar
      alt={`${firstName} ${lastName}`}
      sx={{ width: '1.5rem', height: '1.5rem', fontSize: '0.6rem' }}
    >
      {avatarLabel}
    </Avatar>
  );
};

/**
 * Memoized UserAvatar component.
 */
export default memo(UserAvatar);
