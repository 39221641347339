import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.typography.pxToRem(10),
    borderRadius: theme.typography.pxToRem(2),
    position: 'absolute',
    width: '100%',
    marginTop: '10px',
  },
  colorPrimary: {
    backgroundColor: 'rgb(217,237,247)',
  },
  bar: {
    backgroundColor: 'rgb(0,148,219)',
  },
}));

type LoadingResultsProps = {
  loading: boolean;
};

const LoadingResults: FC<LoadingResultsProps> = ({ loading }: LoadingResultsProps) => {
  const classes = useStyles();
  return (
    <Fade in={loading}>
      <LinearProgress id="loading-search-results" classes={classes} />
    </Fade>
  );
};
export default LoadingResults;
