import { FC, useCallback, memo } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { EightK, FourK, HD, NA, Remove, SD } from 'medialoopster/icons';

import { VideoAsset } from '../../../../state/modules/video/types';
import StatusElement from '../StatusElement';

const renderFormatIcon = (format: string) => {
  if (format === '--') return <Remove style={{ fontSize: '1.2rem' }} />;
  if (format === 'HD') return <HD style={{ fontSize: '1.2rem' }} />;
  if (format === 'SD') return <SD style={{ fontSize: '1.2rem' }} />;
  if (format === '4K') return <FourK style={{ fontSize: '1.2rem' }} />;
  if (format === '8K') return <EightK style={{ fontSize: '1.2rem' }} />;
  if (format === 'N/A') return <NA style={{ fontSize: '1.2rem' }} />;
  return <></>;
};

const formatTitleMap: Record<string, string> = {
  '--': `${gettext('Videoformat')}: ${gettext('Unknown format')}`,
  HD: `${gettext('Videoformat')}: HD`,
  SD: `${gettext('Videoformat')}: SD`,
  '4K': '4K',
  '8K': '8K',
  'N/A': 'N/A',
};

type Props = {
  readonly isWide: boolean;
  readonly asset: VideoAsset;
};

const VideoAssetFormat: FC<Props> = ({ isWide, asset }: Props) => {
  const renderAssetFormatIcon = useCallback(
    () => renderFormatIcon(asset.video_format),
    [asset.video_format],
  );
  return (
    <StatusElement
      renderIcon={renderAssetFormatIcon}
      title={formatTitleMap[asset.video_format]}
      showLabel={isWide}
      label={
        formatTitleMap[asset.video_format] === formatTitleMap['--']
          ? gettext('Cannot detect video format')
          : `${asset.video_codec} (${asset.video_size})`
      }
    />
  );
};

export default memo(VideoAssetFormat);
