import { Button, LinearProgress, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { Input, Select, IconButton, StatusLabel, TableRow } from 'medialoopster/components';
import { RemoveRow } from 'medialoopster/icons';

import { uploadActions, uploadSelectors } from '../../state/modules/upload';
import { UploadDisplay } from '../../state/modules/upload/types';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '& td': {
      verticalAlign: 'top',
    },
  },
  nameInputContainer: {
    display: 'flex',
  },
  fileExtensionBox: {
    padding: '4px',
    paddingLeft: '8px',
    backgroundColor: theme.palette.grey[100],
  },
  progressBar: {
    width: '100px',
    marginTop: '8px',
  },
  messageCell: {
    textAlign: 'center',
  },
  removeIcon: {
    color: theme.palette.warning.main,
  },
}));

const UploadRow: FC<UploadDisplay> = ({
  objectURL,
  baseName,
  extension,
  sizeDisplay,
  uploadURL,
  editable,
  nameErrorMessage,
  message,
  progress,
  disabled,
  isPending,
}: UploadDisplay) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const uploadProductionChoices = useSelector(uploadSelectors.getUploadProductionChoices);
  const [internalBaseName, setInternalBaseName] = useState(baseName);
  useEffect(() => {
    if (internalBaseName === baseName || !internalBaseName.trim()) {
      return () => {};
    }
    const timeout = setTimeout(
      () => dispatch(uploadActions.setUploadName(objectURL, internalBaseName + extension)),
      500,
    );
    return () => clearTimeout(timeout);
  }, [dispatch, objectURL, baseName, internalBaseName, extension]);

  const getNameErrorMessage = (): string => {
    if (!internalBaseName.trim()) {
      return gettext('The name may not be empty.');
    }
    return nameErrorMessage;
  };

  return (
    <TableRow className={classes.tableRow} color={message?.isError ? 'error' : undefined}>
      <TableCell data-testid="nameCell">
        {editable ? (
          <div className={classes.nameInputContainer}>
            <Input
              dense
              value={internalBaseName}
              onChange={(value) => setInternalBaseName(value)}
              showError={!!nameErrorMessage || !internalBaseName.trim()}
              errorMsg={getNameErrorMessage()}
              InputProps={{
                endAdornment: (
                  <span title={gettext('File extension')} className={classes.fileExtensionBox}>
                    {extension}
                  </span>
                ),
              }}
              inputElementProps={{
                title: gettext('New file name'),
              }}
            />
          </div>
        ) : (
          <span>
            {baseName}
            {extension}
          </span>
        )}
      </TableCell>
      <TableCell title={gettext('File size')}>{sizeDisplay}</TableCell>
      <TableCell>
        <Select
          id={`upload-${objectURL}-production-select`}
          title={gettext('Target production')}
          value={uploadURL}
          dense
          choices={uploadProductionChoices}
          onChange={(selectedProductionUploadURL) =>
            dispatch(uploadActions.setUploadURL(objectURL, selectedProductionUploadURL))
          }
          readOnly={!!message}
        />
      </TableCell>
      {message ? (
        <TableCell colSpan={2} className={classes.messageCell} data-testid="statusCell">
          <StatusLabel label={message.label} color={message.isError ? 'error' : 'success'} />
        </TableCell>
      ) : (
        <>
          <TableCell>
            <LinearProgress
              title={isPending ? gettext('Preparing upload') : gettext('File upload progress')}
              className={classes.progressBar}
              variant={isPending ? 'indeterminate' : 'determinate'}
              value={progress}
            />
          </TableCell>
          <TableCell>
            <Button
              title={gettext('Upload this file')}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              disabled={disabled || internalBaseName !== baseName}
              onClick={() => {
                dispatch(uploadActions.startUpload(objectURL));
              }}
            >
              {gettext('Upload')}
            </Button>
          </TableCell>
        </>
      )}
      <TableCell>
        <IconButton
          className={classes.removeIcon}
          title={gettext('Remove this file from the upload queue')}
          onClick={() => dispatch(uploadActions.removeUpload(objectURL))}
        >
          <RemoveRow fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default memo(UploadRow);
