import {
  CollectionType,
  EmbeddedItemsResourceModuleState,
  getResourceTypeName,
} from 'medialoopster/rest';

import { AssetSearchPage } from '../../../types/asset/baseTypes';
import { Asset } from '../../../types/asset/unionTypes';
import { AssetCollection } from '../collection/types';

export type CollectionSearchResultRootState = EmbeddedItemsResourceModuleState<
  AssetCollection,
  CollectionType<'collectionsearchresult'>,
  AssetSearchPage<'collection'>
>;

export interface SearchAssetCollectionResult extends AssetCollection {
  readonly sharedProductionLabels: ReadonlyArray<string>;
}

export const isAssetSearchCollection = (
  asset: Asset | SearchAssetCollectionResult,
): asset is SearchAssetCollectionResult => asset && getResourceTypeName(asset) === 'collection';
