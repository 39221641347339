import { RowData } from 'medialoopster/components/Table/types';
import { BaseResource } from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

export const ADD_FILES = 'FRONTEND/UPLOAD/ADD_FILES';
export const FILE_UPLOAD_SUCCESS = 'FRONTEND/UPLOAD/FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FRONTEND/UPLOAD/FILE_UPLOAD_ERROR';
export const UPLOAD_FILE = 'FRONTEND/UPLOAD/UPLOAD_FILE';
export const UPLOAD_ALL = 'FRONTEND/UPLOAD/UPLOAD_ALL';
export const START_UPLOAD = 'FRONTEND/UPLOAD/START_UPLOAD';
export const VALIDATE_UPLOAD = 'FRONTEND/UPLOAD/VALIDATE_UPLOAD';
export const SET_UPLOAD_NAME = 'FRONTEND/UPLOAD/SET_UPLOAD_NAME';
export const SET_UPLOAD_URL = 'FRONTEND/UPLOAD/SET_UPLOAD_URL';
export const REMOVE_UPLOAD = 'FRONTEND/UPLOAD/REMOVE_UPLOAD';
export const FILE_UPLOAD_VALIDATE_ERROR = 'FRONTEND/UPLOAD/FILE_UPLOAD_VALIDATE_ERROR';
export const FILE_UPLOAD_VALIDATE_SUCCESS = 'FRONTEND/UPLOAD/FILE_UPLOAD_VALIDATE_SUCCESS';
export const FILE_UPLOAD_PROGRESS = 'FRONTEND/UPLOAD/FILE_UPLOAD_PROGRESS';

//-------------
// Action Types
//-------------

export interface UploadedFile {
  readonly objectURL: string;
  readonly name: string;
  readonly size: number;
}

export interface AddFiles {
  readonly type: typeof ADD_FILES;
  readonly payload: {
    readonly files: ReadonlyArray<UploadedFile>;
    readonly uploadURL: string | null;
  };
}

export interface FileUploadSuccess {
  readonly type: typeof FILE_UPLOAD_SUCCESS;
  readonly payload: {
    readonly objectURL: string;
  };
}

export interface UploadError {
  readonly message: string;
  readonly code: string;
  readonly pointer?: string;
}

export interface FileUploadError {
  readonly type: typeof FILE_UPLOAD_ERROR;
  readonly payload: {
    readonly objectURL: string;
    readonly errors: ReadonlyArray<UploadError>;
  };
}

export interface FileUploadValidateSuccess {
  readonly type: typeof FILE_UPLOAD_VALIDATE_SUCCESS;
  readonly payload: {
    readonly objectURL: string;
  };
}

export interface FileUploadValidateError {
  readonly type: typeof FILE_UPLOAD_VALIDATE_ERROR;
  readonly payload: {
    readonly objectURL: string;
    readonly errors: ReadonlyArray<UploadError>;
  };
}

export interface UploadFile {
  readonly type: typeof UPLOAD_FILE;
  readonly payload: {
    readonly objectURL: string;
  };
}

export interface UploadAll {
  readonly type: typeof UPLOAD_ALL;
}

export interface StartUpload {
  readonly type: typeof START_UPLOAD;
  readonly payload: {
    readonly objectURL: string;
  };
}

export interface ValidateUpload {
  readonly type: typeof VALIDATE_UPLOAD;
  readonly payload: {
    readonly objectURL: string;
  };
}

export interface SetUploadName {
  readonly type: typeof SET_UPLOAD_NAME;
  readonly payload: {
    readonly objectURL: string;
    readonly name: string;
  };
}

export interface SetUploadURL {
  readonly type: typeof SET_UPLOAD_URL;
  readonly payload: {
    readonly objectURL: string;
    readonly uploadURL: string;
  };
}

export interface RemoveUpload {
  readonly type: typeof REMOVE_UPLOAD;
  readonly payload: {
    readonly objectURL: string;
  };
}

export interface FileUploadProgress {
  readonly type: typeof FILE_UPLOAD_PROGRESS;
  readonly payload: {
    readonly objectURL: string;
    readonly bytesUploaded: number;
    readonly bytesTotal: number;
  };
}

//---------------
// Resource Types
//---------------

export interface UploadResource extends BaseResource {
  readonly filename: string;
  readonly size: number;
}

//------------
// State Types
//------------

export enum UploadStatus {
  VALIDATING = 'VALIDATING',
  INVALID = 'INVALID',
  READY = 'READY',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface Upload extends UploadedFile {
  readonly uploadURL: string | null;
  readonly status: UploadStatus;
  readonly errors: ReadonlyArray<UploadError>;
  readonly bytesUploaded: number;
  readonly bytesTotal: number;
  readonly startTime?: number;
}

export interface UploadRootState {
  readonly uploads: ReadonlyRecord<string, Upload>;
}

//---------------
// Selector Types
//---------------

export interface UploadMessage {
  readonly label: string;
  readonly isError: boolean;
}

export interface UploadDisplay extends RowData {
  readonly objectURL: string;
  readonly editable: boolean;
  readonly baseName: string;
  readonly nameErrorMessage: string;
  readonly extension: string;
  readonly sizeDisplay: string;
  readonly uploadURL: string;
  readonly message: UploadMessage | null;
  readonly progress: number;
  readonly disabled: boolean;
  readonly isPending: boolean;
}

export interface UploadProgress {
  readonly visible: boolean;
  readonly percentage: number;
  readonly bytesUploaded: string;
  readonly bytesTotal: string;
  readonly timeElapsed: string;
  readonly speed: string;
}
