import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertFeedback, alertMessage } from 'medialoopster/AlertMessage';
import { MenuItem } from 'medialoopster/components';
import { Webhook as WebhookIcon } from 'medialoopster/icons';
import { APIError, getResourceTypeName, unauthorizedRequestAction } from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { operationsSelectors } from '../../../../../state/modules/operations';
import { Webhook } from '../../../../../state/modules/operations/types';
import { Asset } from '../../../../../state/types/asset/unionTypes';
import WebhookModal from './WebhookModal';

type Props = {
  readonly hook: Webhook;
  readonly fireHookOrOpenModal: (hook: Webhook) => void;
};

const WebhookActionMenuEntry = ({ hook, fireHookOrOpenModal }: Props) => (
  <MenuItem
    label={hook.custom_payload_fields.length > 0 ? `${hook.display_name}...` : hook.display_name}
    onClick={() => {
      fireHookOrOpenModal(hook);
    }}
    disabled={!hook.active}
    tooltip={hook.active ? hook.display_name : hook.deactivation_reason}
    icon={<WebhookIcon fontSize="small" />}
  />
);

const useWebhookActionMenuEntries = (currentAsset: Asset | null): ReadonlyArray<ReactNode> => {
  const dispatch = useDispatch();
  const token = useSelector(loginSelectors.getToken);
  const [show, setShow] = useState(false);
  const [activeWebhook, setActiveWebhook] = useState<Webhook | null>(null);
  const webhooks = useSelector(operationsSelectors.getWebhooks).filter(
    (hook) => hook.active || hook.deactivation_reason !== '',
  );

  if (webhooks.length === 0 || !currentAsset) {
    return [];
  }

  const modelName = getResourceTypeName(currentAsset);

  const fireHook = (hookId: number, values: unknown) => {
    fetch(`/api/hooks/${hookId}/fire/${modelName}/${currentAsset.id}/`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        Accept: 'application/json; version=1',
        'Content-Type': 'application/json',
        ...getTokenAuthHeader(token),
      },
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch(unauthorizedRequestAction()); // TODO: Test in ML-3735
        }
        return response.json();
      })
      .then((data) => {
        if (data.errors) {
          const msg = data.errors.map((err: APIError) => err.detail).join('\n');
          dispatch(alertMessage(msg));
        } else {
          dispatch(alertMessage(data.message, AlertFeedback.Success));
        }
      });
  };

  const fireHookOrOpenModal = (hook: Webhook) => {
    setActiveWebhook(hook);
    if (hook.custom_payload_fields.length === 0) {
      fireHook(hook.id, undefined);
    } else {
      setShow(true);
    }
  };

  const listItems = webhooks.map((hook) => (
    <WebhookActionMenuEntry
      key={`webhook-${hook.id}`}
      hook={hook}
      fireHookOrOpenModal={fireHookOrOpenModal}
    />
  ));
  return [
    ...listItems,
    ...(activeWebhook
      ? [
          <WebhookModal
            key="webhook-modal"
            show={show}
            activeWebhook={activeWebhook}
            onClose={() => setShow(false)}
            onSubmit={(values: unknown) => {
              setShow(false);
              fireHook(activeWebhook.id, values);
            }}
          />,
        ]
      : []),
  ];
};

export default useWebhookActionMenuEntries;
