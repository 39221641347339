import { Divider, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResourceURI } from 'medialoopster/rest';

import { personsActions, personsSelectors } from '../../../../state/modules/persons';
import PersonIcon from './PersonIcon';

const useStyle = makeStyles(() => ({
  personList: {
    display: 'flex',
    overflow: 'hidden',
  },
  list: {
    display: 'flex',
    overflow: 'auto',
    alignItems: 'flex-start',
  },
}));

const PersonList: FC = () => {
  const dispatch = useDispatch();
  const persons = useSelector(personsSelectors.getCurrentAssetPersons);
  const activePersonURI = useSelector(personsSelectors.getActivePersonURI);
  const colorByPerson = useSelector(personsSelectors.getColorByPersonURI);

  const classes = useStyle();

  return (
    <>
      <div className={classes.personList}>
        <List className={classes.list} disablePadding dense>
          {persons.map((person) => (
            <PersonIcon
              key={person.id}
              person={person}
              active={!!activePersonURI && getResourceURI(person) === activePersonURI}
              color={colorByPerson[getResourceURI(person)]}
              onClick={() => {
                dispatch(personsActions.setActivePerson(getResourceURI(person)));
                dispatch(personsActions.seekToActivePerson());
              }}
            />
          ))}
        </List>
      </div>
      <Divider />
    </>
  );
};

export default PersonList;
