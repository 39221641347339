import pointer, { JsonObject } from 'json-pointer';
import lodash from 'lodash';
import { stringifySearchDict } from 'medialoopster';
import { Action } from 'redux';
import { Epic, combineEpics, ofType } from 'redux-observable';
import { EMPTY, concat, forkJoin, of } from 'rxjs';
import { debounceTime, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { gettext } from 'medialoopster/Internationalization';
import {
  BaseResource,
  RESTEpicDependencies,
  RESTError,
  ResourceLoadedAction,
  UnauthorizedRequestAction,
  isAPIFieldError,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { RootState } from '../../types/rootState';
import { productionsSelectors } from '../productions';
import {
  setCurrentAddableCollectionSearchURI,
  addAssetsToCollectionApiResponse,
  setAvailableProductionSearchURIs,
} from './actions';
import {
  ADD_ASSETS_TO_COLLECTION,
  AddAssetsToCollection,
  AddAssetsToCollectionApiResponse,
  CHECK_ASSETS_COLLECTIONS_AVAILABILITY,
  CheckAssetCollectionAvailabilityForProduction,
  FIND_ADDABLE_COLLECTIONS,
  FindAddableCollection,
  SetAvailableProductionSearchURIs,
  SetCurrentAddableCollectionSearchURI,
} from './types';

export const findAddableCollectionEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction | SetCurrentAddableCollectionSearchURI,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, FindAddableCollection['type'], FindAddableCollection>(FIND_ADDABLE_COLLECTIONS),
    debounceTime(400),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const collectionSearchURL = state.rest.collectionsearchresult.collectionLink?.href;
      if (!collectionSearchURL) return EMPTY;
      const productionFilter =
        productionsSelectors.getProductionResource(state, action.payload.production)?.id || 0;
      const currentAddableCollectionSearchURI = `${collectionSearchURL}?${stringifySearchDict({
        production_filter: productionFilter,
        so_primary_search: action.payload.name,
      })}`;
      if (productionFilter === 0) {
        return of(setCurrentAddableCollectionSearchURI(currentAddableCollectionSearchURI));
      }
      return concat(
        of(setCurrentAddableCollectionSearchURI(currentAddableCollectionSearchURI)),
        fetchCollection(currentAddableCollectionSearchURI, undefined, undefined, {
          Accept: 'application/hal+json; version=3',
        }),
      );
    }),
  );

interface ResponseFeedbackSuccess {
  type: 'SUCCESS';
  payload: {
    errors: ReadonlyRecord<string, ReadonlyArray<string>>;
    linkHref: string;
    data: BaseResource;
  };
}
interface ResponseFeedbackError {
  type: 'ERROR';
  payload: {
    errors: ReadonlyRecord<string, ReadonlyArray<string>>;
  };
}

const mapPointerToDataHref = (point: string, dat: JsonObject): string => {
  if (!pointer.has(dat, point)) {
    return point;
  }
  const errorLink = pointer.get(dat, point);
  if (!errorLink) {
    return point;
  }
  return errorLink.href;
};
const mapErrorResponseToErrorDetails = (
  res: RESTError,
  data: JsonObject,
): ReadonlyRecord<string, ReadonlyArray<string>> => {
  const errs = lodash.groupBy(res.response.errors, (err) =>
    isAPIFieldError(err) ? mapPointerToDataHref(err.source.pointer, data) : 'nonFieldErrors',
  );
  return lodash.mapValues(errs, (err) => err.map((er) => er.detail));
};

const mergeArraysInObjectValues = (
  objValue: ReadonlyRecord<string, ReadonlyArray<string>>,
  srcValue: ReadonlyRecord<string, ReadonlyArray<string>>,
): ReadonlyArray<string> | undefined => {
  if (lodash.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
};

const collectErrorDetails = (actions: Action[]) => {
  return lodash
    .map(actions)
    .filter((action): action is ResponseFeedbackError => action.type === 'ERROR')
    .map((responseFeedbackErrorAction) => responseFeedbackErrorAction.payload.errors)
    .reduce(
      (accumulator, currentValue): ReadonlyRecord<string, ReadonlyArray<string>> =>
        lodash.mergeWith(accumulator, currentValue, mergeArraysInObjectValues),
      {},
    );
};

const assetTypeToAddableLinkType = new Map<
  'imageasset' | 'videoasset' | 'audioasset',
  'addImageAssetsLink' | 'addVideoAssetsLink' | 'addAudioAssetsLink'
>([
  ['imageasset', 'addImageAssetsLink'],
  ['videoasset', 'addVideoAssetsLink'],
  ['audioasset', 'addAudioAssetsLink'],
]);
export const addAssetsToCollectionEpic: Epic<
  Action,
  AddAssetsToCollectionApiResponse | UnauthorizedRequestAction | ResourceLoadedAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { postResource, postResourceDryRun }) =>
  action$.pipe(
    ofType<Action, AddAssetsToCollection['type'], AddAssetsToCollection>(ADD_ASSETS_TO_COLLECTION),
    mergeMap((action) => {
      const assetByTypeGroups = lodash.groupBy(action.payload.addableAssets, ({ type }) => type);
      const resp = forkJoin(
        Object.entries(assetByTypeGroups).map(([assetType, assetGroup]) => {
          const assetLink = assetTypeToAddableLinkType.get(
            assetType as 'imageasset' | 'videoasset' | 'audioasset',
          );
          const linkHref = assetLink
            ? action.payload.addableCollection[assetLink]?.href
            : undefined;
          if (!(assetLink && linkHref))
            return of({
              type: 'ERROR',
              payload: {
                errors: {
                  nonFieldError: [gettext('Missing URL to add assets to this collection')],
                },
              },
            });

          const data = {
            _links: { assets_list: assetGroup.map(({ value }) => ({ href: value })) },
          };
          return postResourceDryRun<BaseResource, ResponseFeedbackSuccess, ResponseFeedbackError>(
            linkHref,
            data,
            {
              createResourceObservable: () =>
                of({ type: 'SUCCESS', payload: { errors: {}, linkHref, data } }),
              createErrorObservable: (err) =>
                of({
                  type: 'ERROR',
                  payload: {
                    errors: mapErrorResponseToErrorDetails(err, data),
                  },
                }),
              headers: {
                Accept: 'application/hal+json; version=3',
                'Content-Type': 'application/hal+json; version=3',
              },
            },
          );
        }),
      );
      return resp;
    }),
    mergeMap((resp) => {
      const errors = collectErrorDetails(resp);
      if (lodash.isEmpty(errors)) {
        const postWithoutDryRunRequests = lodash
          .map(resp)
          .filter((action): action is ResponseFeedbackSuccess => action.type === 'SUCCESS')
          .map((responseFeedbackSuccessAction) =>
            postResource(
              responseFeedbackSuccessAction.payload.linkHref,
              responseFeedbackSuccessAction.payload.data,
              () =>
                of({
                  type: 'SUCCESS',
                  payload: {
                    errors: {},
                    linkHref: responseFeedbackSuccessAction.payload.linkHref,
                    data: responseFeedbackSuccessAction.payload.data,
                  },
                }),
              (response) => {
                return of({
                  type: 'ERROR',
                  payload: {
                    errors: mapErrorResponseToErrorDetails(
                      response,
                      responseFeedbackSuccessAction.payload.data,
                    ),
                  },
                });
              },
              { version: 3 },
            ),
          );
        return forkJoin(postWithoutDryRunRequests).pipe(
          mergeMap((res) => {
            const errs = collectErrorDetails(res);
            return of(addAssetsToCollectionApiResponse(Object.keys(errs).length === 0, errs));
          }),
        );
      }
      return of(addAssetsToCollectionApiResponse(Object.keys(errors).length === 0, errors));
    }),
  );

export const checkAssetCollectionAvailabilityForProductionEpic: Epic<
  Action,
  UnauthorizedRequestAction | ResourceLoadedAction | SetAvailableProductionSearchURIs,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchResourceHeaders }) =>
  action$.pipe(
    ofType<
      Action,
      CheckAssetCollectionAvailabilityForProduction['type'],
      CheckAssetCollectionAvailabilityForProduction
    >(CHECK_ASSETS_COLLECTIONS_AVAILABILITY),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const writableProductionIds = productionsSelectors
        .getWriteableProductionsWithProjectDevice(state)
        .map(({ id }) => id);
      const collectionSearchURL = state.rest.collectionsearchresult.collectionLink?.href;
      if (!collectionSearchURL) return EMPTY;
      const collectionSearchProductionHrefs = writableProductionIds
        .map((prodId) => {
          return `${collectionSearchURL}?${stringifySearchDict({
            production_filter: prodId,
            so_primary_search: '',
          })}`;
        })
        .filter((maybeEmptySearchURI) => !!maybeEmptySearchURI);

      return concat(
        of(setAvailableProductionSearchURIs(collectionSearchProductionHrefs)),
        ...collectionSearchProductionHrefs.map((search) =>
          fetchResourceHeaders(
            search,
            undefined,
            () =>
              of(
                setAvailableProductionSearchURIs(
                  [],
                  gettext('Error checking available collections'),
                ),
              ),
            {
              Accept: 'application/hal+json; version=3',
            },
          ),
        ),
      );
    }),
  );

export default combineEpics<
  Action,
  | ResourceLoadedAction
  | UnauthorizedRequestAction
  | SetCurrentAddableCollectionSearchURI
  | AddAssetsToCollectionApiResponse
  | SetAvailableProductionSearchURIs,
  RootState
>(
  findAddableCollectionEpic,
  addAssetsToCollectionEpic,
  checkAssetCollectionAvailabilityForProductionEpic,
);
