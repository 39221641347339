import { Paper, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import { FC, memo } from 'react';

import { pgettext, getFormat } from '../../../Internationalization';
import {
  chunks,
  getDaysInPageOfMonth,
  isStartDayOfRange,
  isEndDayOfRange,
  inDayRange,
  isRangeSameDay,
} from '../utils';
import Day from './Day';
import Header from './Header';

const WEEK_DAYS = [
  pgettext('Two letter abbreviation of Sunday', 'Su'),
  pgettext('Two letter abbreviation of Monday', 'Mo'),
  pgettext('Two letter abbreviation of Tuesday', 'Tu'),
  pgettext('Two letter abbreviation of Wednesday', 'We'),
  pgettext('Two letter abbreviation of Thursday', 'Th'),
  pgettext('Two letter abbreviation of Friday', 'Fr'),
  pgettext('Two letter abbreviation of Saturday', 'Sa'),
];
const useStyles = makeStyles(() => ({
  root: {
    width: 290,
  },
  weekDaysContainer: {
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  daysContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
}));

interface MonthProps {
  value: DateTime;
  startDate?: DateTime;
  endDate?: DateTime;
  setValue: (date: DateTime) => void;
  inHoverRange: (day: DateTime) => boolean;
  onDayClick: (day: DateTime) => void;
  onDayHover: (day: DateTime) => void;
}

// FIXME: Month component takes ~200ms to render, maybe there is some optimization potential <RS>
const Month: FC<MonthProps> = ({
  inHoverRange,
  onDayClick,
  onDayHover,
  value: date,
  startDate,
  endDate,
  setValue: setDate,
}: MonthProps) => {
  const classes = useStyles();

  const startWeekAt = getFormat('FIRST_DAY_OF_WEEK') as 0 | 1 | 4 | 2 | 3 | 5 | 6;
  const weekDays = [...WEEK_DAYS.slice(startWeekAt), ...WEEK_DAYS.slice(0, startWeekAt)];
  return (
    <Paper square elevation={0} className={classes.root}>
      <Grid container>
        <Header date={date} setDate={setDate} />
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.weekDaysContainer}
        >
          {weekDays.map((day) => (
            <Typography color="textSecondary" key={day} variant="caption">
              {day}
            </Typography>
          ))}
        </Grid>

        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          className={classes.daysContainer}
        >
          {chunks(getDaysInPageOfMonth(date, startWeekAt), 7).map((week, idx) => {
            return (
              <Grid key={`${idx + 1}`} container direction="row" justifyContent="center">
                {week.map((day, index) => {
                  const isStart = isStartDayOfRange({ startDate }, day);
                  const isEnd = isEndDayOfRange({ endDate }, day);
                  const isRangeOneDay = isRangeSameDay({ startDate, endDate });
                  const highlighted = inDayRange({ startDate, endDate }, day) || inHoverRange(day);
                  return (
                    <Day
                      weekIndex={index}
                      key={day.toFormat(pgettext('Localized numeric date', 'MM/dd/yyyy'))}
                      filled={isStart || isEnd}
                      outlined={day.hasSame(DateTime.local(), 'day')}
                      highlighted={highlighted && !isRangeOneDay}
                      disabled={!date.hasSame(day, 'month')}
                      startOfRange={isStart && !isRangeOneDay}
                      endOfRange={isEnd && !isRangeOneDay}
                      onDayClick={onDayClick}
                      onDayHover={onDayHover}
                      day={day}
                    />
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default memo(Month);
