import { useObservableEagerState } from 'observable-hooks';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { MenuItem, useVideoController } from 'medialoopster/components';
import { PosterFrame } from 'medialoopster/icons';
import {
  getLink,
  mlRel,
  getLinkPermission,
  HttpMethod,
  LinkPermission,
  Link,
} from 'medialoopster/rest';

import { isVideoAsset } from '../../../../businessRules/models/asset/utils';
import { changePosterFrame } from '../../../../state/modules/details/actions';
import { VideoAsset } from '../../../../state/modules/video/types';
import { Asset } from '../../../../state/types/asset/unionTypes';

type Props = {
  readonly currentAsset: VideoAsset;
};

const ChangePosterFrameActionMenuEntry = ({ currentAsset }: Props) => {
  const dispatch = useDispatch();
  const videoController = useVideoController();
  const currentFrame = useObservableEagerState(videoController.frame$);
  return (
    <MenuItem
      label={gettext('Create custom poster frame')}
      tooltip={gettext('Create a custom poster frame using the currently displayed frame')}
      icon={<PosterFrame fontSize="small" />}
      onClick={() => {
        dispatch(changePosterFrame(currentAsset, currentFrame));
      }}
    />
  );
};

const useChangePosterFrameActionMenuEntry = (
  currentAsset: Asset | null,
): ReadonlyArray<ReactElement> => {
  if (!isVideoAsset(currentAsset)) {
    return [];
  }
  const getActionLinkPermission = (
    actionLink: Link | undefined,
    httpMethod: HttpMethod,
  ): LinkPermission | undefined => {
    if (actionLink === undefined) return undefined;
    return getLinkPermission(actionLink, httpMethod);
  };

  const changePosterFramePermission =
    currentAsset &&
    getActionLinkPermission(getLink(currentAsset, mlRel('change-poster-frame')), 'POST')?.code ===
      'ok';

  if (!changePosterFramePermission) {
    return [];
  }

  return [
    <ChangePosterFrameActionMenuEntry key="change-poster-frame" currentAsset={currentAsset} />,
  ];
};

export default useChangePosterFrameActionMenuEntry;
