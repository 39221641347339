import { Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  logoBlock: {
    height: '50px',
    marginTop: '11px',
    alignItems: 'center',
  },
  version: {
    fontSize: '0.96em',
    bottom: 0,
    marginRight: '15px',
    marginBottom: '5px',
    color: 'white',
    paddingLeft: '10px',
  },
  title: {
    marginBottom: '5px',
    fontSize: '1.11em',
    color: 'white',
  },
}));

type Props = {
  readonly version: string;
  readonly siteName: string;
  readonly staticPrefix?: string;
};

const Logo: React.FC<Props> = ({ version, siteName, staticPrefix = '/static' }: Props) => {
  const classes = useStyles();
  const isMdDown = useMediaQuery<DefaultTheme>((theme) => theme.breakpoints.down('md'));
  return (
    <Grid container alignItems="center" className={classes.logoBlock}>
      <Grid item>
        <img
          src={`${staticPrefix}/img/logo_06_white.svg`}
          alt="medialoopster"
          style={{ height: '19px', width: 'auto' }}
          draggable={false}
        />
      </Grid>
      {!isMdDown && (
        <>
          <Grid item>
            <Typography variant="h4" className={classes.version}>
              {version}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.title}>
              {siteName}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Logo;
