import { RootState } from '../../../types/rootState';
import { TranscriptUnitRootState } from './types';

/**
 * Get the transcript unit state from the root state.
 * @param state The global root state.
 */
const getSelf = (state: RootState) => state.rest.transcriptunit;

/**
 * Get the transcript unit resource map.
 * @param state The global root state.
 */
export const getTranscriptUnitResourceMap = (
  state: RootState,
): TranscriptUnitRootState['objects'] => getSelf(state).objects;

/**
 * Get the transcript unit page map
 * @param state The global root state.
 */
export const getTranscriptUnitPageMap = (state: RootState): TranscriptUnitRootState['pages'] =>
  getSelf(state).pages;

export default null;
