import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { AudioAsset } from '../../state/modules/audio/types';
import { detailsSelectors, detailsActions, detailsTypes } from '../../state/modules/details';
import { searchSelectors } from '../../state/modules/search';
import AssetInfoField from './AssetFields/AssetInfoField';
import Text from './AssetFields/Text';
import TextArea from './AssetFields/TextArea';
import Year from './AssetFields/Year';
import AssetInfo from './AssetInfo';

const AudioInfo: FC = () => {
  const dispatch = useDispatch();
  const asset = useSelector(detailsSelectors.getCurrentAsset) as AudioAsset;
  const keywordsByCategory = useSelector(detailsSelectors.getCurrentAudioAssetKeywordsByCategories);
  const assetHighlights = useSelector(searchSelectors.getCurrentAssetHighlight);
  const href = getResourceURI(asset);
  const isEditMode = useSelector(detailsSelectors.isEditMode);
  const onSubmitEdit = useCallback(
    (fieldKey: keyof detailsTypes.AudioEditFields) => (value: string | null) => {
      const resourceURI = getResourceURI(asset);
      const updates: detailsTypes.AudioEditFields = { [fieldKey]: value };
      dispatch(detailsActions.editAsset(getResourceTypeName(asset), resourceURI, updates));
    },
    [asset, dispatch],
  );
  return (
    <AssetInfo
      title={gettext('Audio Info')}
      asset={asset}
      assetInfoWithoutKw={
        <>
          <AssetInfoField label={gettext('Artist')} visible testId={`${href}-artist`}>
            <Text
              value={asset.artist}
              highlights={assetHighlights?.fields?.artist}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('artist')}
            />
          </AssetInfoField>
          <AssetInfoField label={gettext('Title')} visible testId={`${href}-title`}>
            <Text
              value={asset.title}
              highlights={assetHighlights?.fields?.title}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('title')}
            />
          </AssetInfoField>
          <AssetInfoField label={gettext('Album')} visible testId={`${href}-album`}>
            <Text
              value={asset.album}
              highlights={assetHighlights?.fields?.album}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('album')}
            />
          </AssetInfoField>
          <AssetInfoField label={gettext('Release date')} visible testId={`${href}-release-date`}>
            <Year
              value={asset.date}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('date')}
            />
          </AssetInfoField>
          <AssetInfoField label={gettext('Genre')} visible testId={`${href}-genre`}>
            <TextArea
              value={asset.genre}
              highlights={assetHighlights?.fields?.genre}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('genre')}
            />
          </AssetInfoField>
          <AssetInfoField label={gettext('Description')} visible testId={`${href}-description`}>
            <TextArea
              value={asset.description}
              highlights={assetHighlights?.fields?.description}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('description')}
            />
          </AssetInfoField>
          <br />
          <AssetInfoField label={gettext('License info')} visible testId={`${href}-license-info`}>
            <Text
              value={asset.license_info}
              highlights={assetHighlights?.fields?.license_info}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('license_info')}
            />
          </AssetInfoField>
        </>
      }
      keywordsByCategory={keywordsByCategory}
    />
  );
};

export default AudioInfo;
