import { combineReducers } from 'redux';

import { SET_CURRENT_ASSET, SetCurrentAsset } from '../details/types';
import {
  PlayerState,
  SET_IN_FRAME,
  SET_INITIAL_FRAME,
  SET_OUT_FRAME,
  SetInFrame,
  SetInitialFrame,
  SetOutFrame,
} from './types';

export const initialFrameReducer = (
  state = 0,
  action: SetInitialFrame | SetCurrentAsset,
): number => {
  switch (action.type) {
    case SET_INITIAL_FRAME:
      return action.payload.frame;
    case SET_CURRENT_ASSET:
      return 0;
    default:
      return state;
  }
};

export const inFrameReducer = (
  state: number | null = null,
  action: SetInFrame | SetCurrentAsset,
): number | null => {
  switch (action.type) {
    case SET_IN_FRAME:
      return action.payload.frame;
    case SET_CURRENT_ASSET:
      return null;
    default:
      return state;
  }
};

export const outFrameReducer = (
  state: number | null = null,
  action: SetOutFrame | SetCurrentAsset,
): number | null => {
  switch (action.type) {
    case SET_OUT_FRAME:
      return action.payload.frame;
    case SET_CURRENT_ASSET:
      return null;
    default:
      return state;
  }
};

export default combineReducers<PlayerState>({
  initialFrame: initialFrameReducer,
  inFrame: inFrameReducer,
  outFrame: outFrameReducer,
});
