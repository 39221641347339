import { DateTime } from 'luxon';

import { ActionsMetadataV2 } from 'medialoopster/rest';

import { Asset } from '../../types/asset/unionTypes';

export const OPEN_SHARE_ASSETS_MODAL = 'FRONTEND/ASSET_SHARING/OPEN_SHARE_ASSETS_MODAL';
export const SELECT_PRODUCTION = 'FRONTEND/ASSET_SHARING/SELECT_PRODUCTION';
export const DESELECT_PRODUCTION = 'FRONTEND/ASSET_SHARING/DESELECT_PRODUCTION';
export const SET_DATE_WITHDRAW = 'FRONTEND/ASSET_SHARING/SET_DATE_WITHDRAW';
export const CLOSE_SHARE_ASSETS_MODAL = 'FRONTEND/ASSET_SHARING/CLOSE_SHARE_ASSETS_MODAL';
export const FETCH_SHARE_ASSETS_OPTIONS = 'FRONTEND/ASSET_SHARING/FETCH_SHARE_ASSETS_OPTIONS';
export const SHARE_ASSETS_OPTIONS_LOADED = 'FRONTEND/ASSET_SHARING/SHARE_ASSETS_OPTIONS_LOADED';
export const SHARE_ASSETS = 'FRONTEND/ASSET_SHARING/SHARE_ASSETS';
export const ASSETS_SHARED = 'FRONTEND/ASSET_SHARING/ASSETS_SHARED';
export const SET_SHARE_ASSETS_ERRORS = 'FRONTEND/ASSET_SHARING/SET_SHARE_ASSETS_ERRORS';
export const CLEAR_SHARE_ASSETS_ERRORS = 'FRONTEND/ASSET_SHARING/CLEAR_SHARE_ASSETS_ERRORS';

export interface AssetSharingPermissionsState {
  readonly canShareProductionAsset: boolean;
}

//-------------
// Action Types
//-------------

export interface OpenShareAssetsModal {
  readonly type: typeof OPEN_SHARE_ASSETS_MODAL;
  readonly payload: {
    readonly assets: ReadonlyArray<Asset>;
  };
}

export interface SelectProduction {
  readonly type: typeof SELECT_PRODUCTION;
  readonly payload: {
    readonly productionId: number;
  };
}

export interface DeselectProduction {
  readonly type: typeof DESELECT_PRODUCTION;
  readonly payload: {
    readonly productionId: number;
  };
}

export interface SetDateWithdraw {
  readonly type: typeof SET_DATE_WITHDRAW;
  readonly payload: {
    readonly dateWithdraw: string;
  };
}

export interface CloseShareAssetsModal {
  readonly type: typeof CLOSE_SHARE_ASSETS_MODAL;
}

export interface FetchShareAssetsOptions {
  readonly type: typeof FETCH_SHARE_ASSETS_OPTIONS;
}

export interface ShareAssetsOptionsLoaded {
  readonly type: typeof SHARE_ASSETS_OPTIONS_LOADED;
  readonly payload: {
    readonly actions: ActionsMetadataV2;
  };
}

export interface ShareAssets {
  readonly type: typeof SHARE_ASSETS;
  readonly payload: {
    readonly productionIds: ReadonlyArray<number>;
    readonly dateWithdraw: string | null;
    readonly assets: ReadonlyArray<Asset>;
  };
}

export interface AssetsShared {
  readonly type: typeof ASSETS_SHARED;
  readonly payload: {
    readonly assetCount: number;
  };
}
export interface SetShareAssetsErrors {
  readonly type: typeof SET_SHARE_ASSETS_ERRORS;
  readonly payload: {
    readonly errors: Partial<ShareAssetsErrors>;
  };
}

export interface ClearShareAssetsErrors {
  readonly type: typeof CLEAR_SHARE_ASSETS_ERRORS;
}

//---------------
// Resource Types
//---------------

export interface ShareAssetsErrors {
  readonly nonField: ReadonlyArray<string>;
  readonly productionIds: ReadonlyArray<string>;
  readonly dateWithdraw: ReadonlyArray<string>;
  readonly assets: ReadonlyArray<string>;
}

//------------------
// Redux State Types
//------------------

export type ChoiceState = 'none' | 'some' | 'all';
export interface ShareAssetsModalProductionChoiceState {
  readonly label: string;
  readonly value: number;
  readonly state: ChoiceState;
}

export interface ShareAssetsModalOptionsState {
  readonly actions: ActionsMetadataV2;
}

export interface ShareAssetsModalValuesState {
  readonly productionIds: ReadonlyArray<number>;
  readonly dateWithdraw: DateTime | null;
  readonly assets: ReadonlyArray<Asset>;
}

export interface ShareAssetsModalState {
  readonly visible: boolean;
  readonly values: ShareAssetsModalValuesState;
  readonly errors: ShareAssetsErrors;
}

export interface AssetSharingRootState {
  readonly options: ShareAssetsModalOptionsState;
  readonly modal: ShareAssetsModalState;
  readonly permissions: AssetSharingPermissionsState;
}
