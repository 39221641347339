import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo } from 'react';

import BaseCell, { BaseCellProps } from './BaseCell';

const useStyles = makeStyles(() => ({
  buttonPadding: {
    width: 1,
    padding: '0 12px 0 9px',
    '&.MuiTableCell-sizeSmall:last-child': {
      padding: '0 12px 0 9px',
    },
  },
}));

export type ButtonCellProps = Pick<BaseCellProps, 'disabled' | 'className' | 'children'>;

const ButtonCell: FC<ButtonCellProps> = ({ disabled, className, children }: ButtonCellProps) => {
  const classes = useStyles();
  return (
    <BaseCell disabled={disabled} className={clsx(className, classes.buttonPadding)}>
      {children}
    </BaseCell>
  );
};

export default memo(ButtonCell);
