import { throttle } from 'lodash';
import { useObservableEagerState } from 'observable-hooks';
import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pgettext } from 'medialoopster/Internationalization';
import {
  ToolbarButtonGroup,
  ToolbarIconButton,
  useVideoController,
} from 'medialoopster/components';
import { LANGUAGES } from 'medialoopster/constants';
import {
  FullScreen,
  GoToInFrame,
  GoToOutFrame,
  NextFrame,
  NextSecond,
  Pause,
  Play,
  PreviousFrame,
  PreviousSecond,
  SetInFrame,
  SetOutFrame,
} from 'medialoopster/icons';

import { detailsSelectors } from '../../../state/modules/details';
import { playerActions } from '../../../state/modules/player';
import { toogleFullScreen } from './FullScreen';
import PlayerSettingsButton from './PlayerSettingsButton';
import SubtitleActivateButton from './SubtitleActivateButton';

interface Props {
  readonly fps: number;
  readonly duration: number;
  readonly outFrame: number | null;
  readonly inFrame: number | null;
  readonly selectedAssetURL: string;
  readonly isMpd: boolean;
  readonly selectedTextLanguage: keyof typeof LANGUAGES | null;
  readonly selectTextLanguage: (lang: keyof typeof LANGUAGES | null) => void;
  readonly setShowSubtitle: (show: boolean) => void;
  readonly showSubtitle: boolean;
  readonly selectAudioTrack: (track: shaka.extern.Track) => void;
  readonly selectedAudioTrack: shaka.extern.Track | null;
}

const ToolbarButtons: FC<Props> = ({
  fps,
  duration,
  selectedAssetURL,
  outFrame,
  inFrame,
  isMpd,
  selectTextLanguage,
  selectedTextLanguage,
  setShowSubtitle,
  showSubtitle,
  selectAudioTrack,
  selectedAudioTrack,
}: Props) => {
  const dispatch = useDispatch();

  const videoController = useVideoController();
  const subtitleCollection = useSelector(detailsSelectors.getEmbeddedVideoSubtitleCollection);
  const hasSubtitles = subtitleCollection.items.length > 0;

  const canPlay = useObservableEagerState(videoController.canPlay$);
  const playbackSpeed = useObservableEagerState(videoController.playbackSpeed$);
  const paused = useObservableEagerState(videoController.paused$);
  const videoEnded = useObservableEagerState(videoController.videoEnded$);
  const audioTracks = useObservableEagerState(videoController.audioTracks$);

  const togglePlay = useCallback(
    (shouldPlay) => {
      if (videoEnded && shouldPlay) {
        videoController.seek(0);
      }
      videoController.togglePlay(shouldPlay);
    },
    [videoController, videoEnded],
  );

  useEffect(() => {
    if (videoEnded) {
      // Frontend player should show that playing has ended
      togglePlay(false);
    }
  }, [videoEnded, togglePlay]);

  const getMarkerFrame = useCallback(
    // Use api asset duration to get In and Out frames for player
    (frame: number): number => Math.min(Math.max(frame, 0), duration - 1),
    [duration],
  );

  const togglePlayerScreen = useCallback(() => {
    const vidElem = videoController.mediaElement;
    if (vidElem) toogleFullScreen(vidElem, document);
  }, [videoController.mediaElement]);

  const setInOut = useCallback(
    (newInFrame: number, newOutFrame: number): void => {
      dispatch(playerActions.setInFrame(newInFrame));
      dispatch(playerActions.setOutFrame(newOutFrame));
    },
    [dispatch],
  );

  const setIn = useCallback(() => {
    if (selectedAssetURL) {
      const currentFrame = getMarkerFrame(videoController.getCurrentFrame());
      const newOutPosition =
        outFrame === null || outFrame <= currentFrame ? duration - 1 : outFrame;
      const newInPosition = currentFrame;
      setInOut(newInPosition, newOutPosition);
    }
  }, [outFrame, selectedAssetURL, setInOut, getMarkerFrame, videoController, duration]);

  const setOut = useCallback(() => {
    if (selectedAssetURL) {
      const currentFrame = getMarkerFrame(videoController.getCurrentFrame());
      const newInPosition = inFrame === null || inFrame >= currentFrame ? 0 : inFrame;
      const newOutPosition = currentFrame;
      setInOut(newInPosition, newOutPosition);
    }
  }, [inFrame, selectedAssetURL, setInOut, getMarkerFrame, videoController]);

  const jumpToIn = useCallback(() => {
    togglePlay(false);
    if (inFrame !== null && fps !== null) {
      videoController.seekFrame(inFrame);
    }
  }, [inFrame, fps, videoController, togglePlay]);

  const jumpToOut = useCallback(() => {
    togglePlay(false);
    if (outFrame !== null && fps !== null) {
      videoController.seekFrame(outFrame);
    }
  }, [outFrame, fps, videoController, togglePlay]);

  const previousFrame = useCallback(() => {
    videoController.previousFrame();
  }, [videoController]);

  const nextFrame = useCallback(() => {
    videoController.nextFrame();
  }, [videoController]);

  const previousSecond = useCallback(() => {
    videoController.previousSecond();
  }, [videoController]);
  const nextSecond = useCallback(() => {
    videoController.nextSecond();
  }, [videoController]);

  useEffect(() => {
    const playbackSpeedOptions = [1, 1.5, 2, 4, 8]; // TODO: reenable x16 after fix. <PB t:ML-3244>
    const playbackSpeedUp = () => {
      const currentSpeedIndex = playbackSpeedOptions.findIndex((x) => x === playbackSpeed);
      if (currentSpeedIndex + 1 < playbackSpeedOptions.length) {
        videoController.setPlaybackSpeed(playbackSpeedOptions[currentSpeedIndex + 1]);
      }
    };

    const playbackSpeedDown = () => {
      const currentSpeedIndex = playbackSpeedOptions.findIndex((x) => x === playbackSpeed);
      if (currentSpeedIndex > 0) {
        videoController.setPlaybackSpeed(playbackSpeedOptions[currentSpeedIndex - 1]);
      }
    };

    const toggleMuted = () => {
      videoController.toggleMute();
    };

    const handleKeydown = throttle((event: KeyboardEvent) => {
      if (
        (document.activeElement?.tagName === 'INPUT' &&
          document.activeElement?.getAttribute('name') !== 'video-progress-slider') ||
        document.activeElement?.tagName === 'TEXTAREA'
      ) {
        // If an input is focused, deactivate player keyboard shortcuts.
        // NOTE: The video progress slider is itself an input element, so it is excluded from this check.
        return;
      }
      if (!canPlay) {
        if (
          [
            'Space',
            'KeyK',
            'ArrowLeft',
            'ArrowRight',
            'KeyI',
            'KeyO',
            'KeyM',
            'KeyJ',
            'KeyL',
            'KeyS',
          ].includes(event.code)
        ) {
          // Stop any other ui events unrelated to known player shortcuts
          event.preventDefault();
        }
        return;
      }
      switch (event.code) {
        case 'Space':
          event.preventDefault();
          event.stopPropagation();
          togglePlay(paused);
          break;
        case 'KeyK':
          togglePlay(paused);
          break;
        case 'ArrowLeft':
          event.preventDefault();
          event.stopPropagation();
          if (event.shiftKey) {
            videoController.previousSecond();
          } else {
            videoController.previousFrame();
          }
          break;
        case 'ArrowRight':
          event.preventDefault();
          event.stopPropagation();
          if (event.shiftKey) {
            videoController.nextSecond();
          } else {
            videoController.nextFrame();
          }
          break;
        case 'KeyI':
          if (event.shiftKey) {
            jumpToIn();
          } else {
            setIn();
          }
          break;
        case 'KeyO':
          if (event.shiftKey) {
            jumpToOut();
          } else {
            setOut();
          }
          break;
        case 'KeyM':
          toggleMuted();
          break;
        case 'KeyJ':
          playbackSpeedDown();
          break;
        case 'KeyL':
          playbackSpeedUp();
          break;
        case 'KeyS':
          if (event.shiftKey) {
            togglePlayerScreen();
          }
          break;
        default:
      }
    }, 33);
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [
    videoController,
    togglePlay,
    jumpToIn,
    setIn,
    jumpToOut,
    setOut,
    playbackSpeed,
    canPlay,
    paused,
    togglePlayerScreen,
  ]);

  return (
    <>
      <ToolbarButtonGroup>
        <ToolbarButtonGroup>
          <ToolbarIconButton
            title={
              paused
                ? pgettext('Media player controls', 'Play (space, k)')
                : pgettext('Media player controls', 'Pause (space, k)')
            }
            onClick={() => togglePlay(paused)}
            disabled={!canPlay}
          >
            {paused ? <Play /> : <Pause />}
          </ToolbarIconButton>
        </ToolbarButtonGroup>
        <ToolbarButtonGroup>
          <ToolbarIconButton
            title={pgettext('Video player controls', '-Frame (left)')}
            onClick={previousFrame}
            disabled={!canPlay}
          >
            <PreviousFrame />
          </ToolbarIconButton>
          <ToolbarIconButton
            title={pgettext('Video player controls', '+Frame (right)')}
            onClick={nextFrame}
            disabled={!canPlay}
          >
            <NextFrame />
          </ToolbarIconButton>
        </ToolbarButtonGroup>
        <ToolbarButtonGroup>
          <ToolbarIconButton
            // Translators: the 's' stands for Seconds
            title={pgettext('Video player controls', '-s (shift+left)')}
            onClick={previousSecond}
            disabled={!canPlay}
          >
            <PreviousSecond />
          </ToolbarIconButton>
          <ToolbarIconButton
            // Translators: the 's' stands for Seconds
            title={pgettext('Video player controls', '+s (shift+right)')}
            onClick={nextSecond}
            disabled={!canPlay}
          >
            <NextSecond />
          </ToolbarIconButton>
        </ToolbarButtonGroup>
        <ToolbarButtonGroup>
          <ToolbarIconButton
            // Translators: Starting point for a range
            title={pgettext('Video player controls', 'Set IN (i)')}
            onClick={setIn}
            disabled={!canPlay}
          >
            <SetInFrame />
          </ToolbarIconButton>
          <ToolbarIconButton
            // Translators: Ending point for a time range
            title={pgettext('Video player controls', 'Set OUT (o)')}
            onClick={setOut}
            disabled={!canPlay}
          >
            <SetOutFrame />
          </ToolbarIconButton>
        </ToolbarButtonGroup>
        <ToolbarButtonGroup>
          <ToolbarIconButton
            title={pgettext('Video player controls', 'To IN (shift+i)')}
            onClick={jumpToIn}
            disabled={!canPlay}
          >
            <GoToInFrame />
          </ToolbarIconButton>
          <ToolbarIconButton
            title={pgettext('Video player controls', 'To OUT (shift+o)')}
            onClick={jumpToOut}
            disabled={!canPlay}
          >
            <GoToOutFrame />
          </ToolbarIconButton>
        </ToolbarButtonGroup>
      </ToolbarButtonGroup>
      <ToolbarButtonGroup>
        <ToolbarIconButton
          title={pgettext('Video player controls', 'Toggle fullscreen (shift+s)')}
          onClick={togglePlayerScreen}
          disabled={!canPlay}
        >
          <FullScreen />
        </ToolbarIconButton>
        <SubtitleActivateButton
          visible={hasSubtitles}
          disabled={!canPlay}
          setShowSubtitle={setShowSubtitle}
          showSubtitle={showSubtitle}
        />
        <PlayerSettingsButton
          subtitles={subtitleCollection.items}
          selectTextLanguage={selectTextLanguage}
          selectedTextLanguage={selectedTextLanguage}
          audioTracks={audioTracks}
          selectAudioTrack={selectAudioTrack}
          selectedAudioTrack={selectedAudioTrack}
          isMpd={isMpd}
          disabled={!canPlay}
        />
      </ToolbarButtonGroup>
    </>
  );
};

export default memo(ToolbarButtons);
