import Grid from '@mui/material/Grid';
import { formatUserDisplayName } from 'medialoopster';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { DropdownMenu, MenuItem } from 'medialoopster/components';
import { Account, Admin, Logout } from 'medialoopster/icons';
import { loginActions } from 'medialoopster/state/login';

import { mainSelectors } from '../../state/modules/main';
import { usersSelectors } from '../../state/modules/users';

const MainMenu: FC = () => {
  const dispatch = useDispatch();
  const adminURL = useSelector(mainSelectors.getAdminURL);
  const currentUser = useSelector(usersSelectors.getCurrentUser);

  return (
    <Grid>
      <DropdownMenu
        popupId="main-menu"
        buttonProps={{
          size: 'medium',
          variant: 'text',
          color: 'white',
        }}
        label={
          <>
            <Account />
            &nbsp;{gettext('Signed in as')}&nbsp;
            {currentUser ? formatUserDisplayName(currentUser) : ''}
          </>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {adminURL && (
          <MenuItem
            key="admin"
            icon={<Admin fontSize="small" />}
            label={gettext('medialoopster Administration')}
            href={adminURL}
          />
        )}
        <MenuItem
          key="logout"
          target="_self"
          icon={<Logout fontSize="small" />}
          label={gettext('Log out')}
          onClick={() => dispatch(loginActions.logout())}
        />
      </DropdownMenu>
    </Grid>
  );
};

export default MainMenu;
