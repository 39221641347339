import { combineReducers } from 'redux';

import { ReadonlyRecord } from 'medialoopster/types';

import { AssetType } from '../../types/asset/baseTypes';
import {
  ARCHIVE_ASSET_OPTIONS_LOADED,
  RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED,
  ArchiveAssetOptionsLoaded,
  RestoreArchivedAssetOptionsLoaded,
} from './types';

export const archivePermissionReducer = (
  state = false,
  action: ArchiveAssetOptionsLoaded,
): boolean => {
  switch (action.type) {
    case ARCHIVE_ASSET_OPTIONS_LOADED:
      return 'POST' in action.payload.actions;
    default:
      return state;
  }
};

export const restorePermissionReducer = (
  state = false,
  action: RestoreArchivedAssetOptionsLoaded,
): boolean => {
  switch (action.type) {
    case RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED:
      return 'POST' in action.payload.actions;
    default:
      return state;
  }
};

const assetTypeMap: ReadonlyRecord<string, AssetType> = {
  videoasset: 'videoasset',
  audioasset: 'audioasset',
  imageasset: 'imageasset',
  projectasset: 'collection',
};

export const archiveAssetTypesReducer = (
  state: ReadonlyArray<AssetType> = [],
  action: ArchiveAssetOptionsLoaded,
): ReadonlyArray<AssetType> => {
  switch (action.type) {
    case ARCHIVE_ASSET_OPTIONS_LOADED:
      return (
        action.payload.actions?.POST?.content_type_model?.choices?.map(
          ({ value }) => assetTypeMap[value],
        ) || []
      );
    default:
      return state;
  }
};

export const restoreAssetTypesReducer = (
  state: ReadonlyArray<AssetType> = [],
  action: RestoreArchivedAssetOptionsLoaded,
): ReadonlyArray<AssetType> => {
  switch (action.type) {
    case RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED:
      return (
        action.payload.actions?.POST?.content_type_model?.choices?.map(
          ({ value }) => assetTypeMap[value],
        ) || []
      );
    default:
      return state;
  }
};

export default combineReducers({
  canArchive: archivePermissionReducer,
  archiveAssetTypes: archiveAssetTypesReducer,
  canRestore: restorePermissionReducer,
  restoreAssetTypes: restoreAssetTypesReducer,
});
