import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Settings } from 'luxon';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import medialoopsterTheme from 'medialoopster/medialoopsterTheme';

import App from './App';
import initStore from './state/store';

const { store, persistor } = initStore(configs.devtoolOptions, configs.redux.shouldLogActions);

// Set the default locale for `luxon`, so the date format is consistent with the language of the frontend app.
//
// This makes all `DateTime` objects use `navigator.language` (the preferred language for websites) instead of
// `Intl.DateTimeFormat().resolvedOptions().locale` (the browser language, which is the default used by luxon).
//
// See also https://nachtblau.atlassian.net/browse/ML-2806.
Settings.defaultLocale = navigator.language;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={medialoopsterTheme}>
          <DndProvider backend={HTML5Backend}>
            <LocalizationProvider adapterLocale={navigator.language} dateAdapter={AdapterLuxon}>
              <App />
            </LocalizationProvider>
          </DndProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('app'),
);
