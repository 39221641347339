import { Badge } from '@mui/material';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertFeedback, alertMessage } from 'medialoopster/AlertMessage';
import { gettext } from 'medialoopster/Internationalization';
import { ToolbarButtonGroup, ToolbarIconButton } from 'medialoopster/components';
import {
  AddComment,
  AddCommentOff,
  Approve,
  ContentCopy,
  CreateSequence,
  CreateShot,
  Disapprove,
  Refresh,
  Snapshot,
} from 'medialoopster/icons';
import {
  getLink,
  getLinkHref,
  getLinkPermission,
  HttpMethod,
  Link,
  LinkPermission,
  mlRel,
} from 'medialoopster/rest';
import { downloadFromURL } from 'medialoopster/state/main/actions';

import { isAudioAsset, isImageAsset, isVideoAsset } from '../../businessRules/models/asset/utils';
import { detailsActions, detailsSelectors } from '../../state/modules/details';
import { toggleAnnotations } from '../../state/modules/details/actions';
import { toggleApprovalStatus } from '../../state/modules/operations/actions';
import { playerSelectors } from '../../state/modules/player';
import PersonsActionButtons from './PersonsActionButtons';
import { useCopyAssetUrlToClipboard } from './useCopyUrlClipboard';

type Props = {
  readonly getCurrentTime?: () => number;
};

const AssetActionToolbar: FC<Props> = ({ getCurrentTime = () => 0 }: Props) => {
  const dispatch = useDispatch();

  const onCopyClipboard = useCallback(() => {
    dispatch(alertMessage(gettext('URL copied to clipboard.'), AlertFeedback.Success));
  }, [dispatch]);

  const onErrCopyClipboard = useCallback(() => {
    dispatch(alertMessage(gettext('Could not copy URL to clipboard.'), AlertFeedback.Error));
  }, [dispatch]);

  const [, copyContent] = useCopyAssetUrlToClipboard(onCopyClipboard, onErrCopyClipboard);

  const asset = useSelector(detailsSelectors.getCurrentAsset);
  const showAnnotation = useSelector(detailsSelectors.showAnnotations);
  const hasAnnotation = useSelector(detailsSelectors.getCurrentAssetHasAnnotations);
  const isEditMode = useSelector(detailsSelectors.isEditMode);
  const inFrame = useSelector(playerSelectors.getInFrame);
  const outFrame = useSelector(playerSelectors.getOutFrame);
  const selectedShotRange = useSelector(detailsSelectors.getSelectedShotRange);
  if (!asset) {
    return null;
  }
  const hasInOut = inFrame !== null && outFrame !== null;
  const shotCollectionLink = getLink(asset, mlRel('shots'));
  const hasShotCreatePermission = shotCollectionLink?.methods?.POST?.code === 'ok';
  const sequenceCollectionLink = getLink(asset, mlRel('sequences'));
  const hasSequenceCreatePermission = sequenceCollectionLink?.methods?.POST?.code === 'ok';
  const getActionLinkPermission = (
    actionLink: Link | undefined,
    httpMethod: HttpMethod,
  ): LinkPermission | undefined => {
    if (actionLink === undefined) return undefined;
    return getLinkPermission(actionLink, httpMethod);
  };
  const stillImagePermission = getActionLinkPermission(getLink(asset, mlRel('stillimages')), 'GET');

  const calcCurrentFrame = (currentTime: number, fps: string) =>
    Math.round(currentTime * parseFloat(fps));

  const getApproveTitle = () => {
    if (asset.status_approval) {
      return gettext('Disapprove');
    }
    return gettext('Approve');
  };

  return (
    <>
      <ToolbarButtonGroup>
        <ToolbarIconButton
          title={getApproveTitle()}
          onClick={() => dispatch(toggleApprovalStatus(asset))}
        >
          {asset.status_approval ? (
            <Disapprove data-testid="disapprove" />
          ) : (
            <Approve data-testid="approve" />
          )}
        </ToolbarIconButton>
        {isVideoAsset(asset) && (
          <>
            <ToolbarIconButton
              title={gettext('Copy asset URL')}
              onClick={() => copyContent('videoasset', asset.id)}
            >
              <ContentCopy />
            </ToolbarIconButton>
            <ToolbarIconButton
              title={showAnnotation ? gettext('Close annotations') : gettext('Make annotations')}
              onClick={() => dispatch(toggleAnnotations())}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                sx={{ '& .MuiBadge-badge': { margin: '0px 2px 3px 0px' } }}
                color="primary"
                variant="dot"
                badgeContent={hasAnnotation ? 1 : 0}
              >
                {showAnnotation ? <AddCommentOff /> : <AddComment />}
              </Badge>
            </ToolbarIconButton>
            {stillImagePermission?.code !== 'permission_denied' && (
              <ToolbarIconButton
                title={
                  stillImagePermission?.message
                    ? stillImagePermission.message
                    : gettext('Download still image')
                }
                onClick={() =>
                  dispatch(
                    downloadFromURL(
                      `${getLinkHref(asset, mlRel('stillimages'))}${calcCurrentFrame(
                        getCurrentTime(),
                        asset.fps,
                      )}/`,
                    ),
                  )
                }
                disabled={stillImagePermission?.code !== 'ok'}
              >
                <Snapshot />
              </ToolbarIconButton>
            )}
          </>
        )}
        {isImageAsset(asset) && (
          <>
            <ToolbarIconButton
              title={gettext('Copy asset URL')}
              onClick={() => copyContent('imageasset', asset.id)}
            >
              <ContentCopy />
            </ToolbarIconButton>
          </>
        )}
        {isAudioAsset(asset) && (
          <ToolbarIconButton
            title={gettext('Copy asset URL')}
            onClick={() => copyContent('audioasset', asset.id)}
          >
            <ContentCopy />
          </ToolbarIconButton>
        )}
      </ToolbarButtonGroup>
      {(isVideoAsset(asset) || isImageAsset(asset)) && <PersonsActionButtons />}
      {isVideoAsset(asset) && isEditMode && (
        <ToolbarButtonGroup>
          {hasSequenceCreatePermission && (
            <ToolbarIconButton
              title={
                selectedShotRange
                  ? gettext('Create sequence')
                  : gettext('Please set IN and OUT points or select shots')
              }
              onClick={() => {
                if (selectedShotRange) {
                  dispatch(
                    detailsActions.createSequence(
                      asset,
                      selectedShotRange.start,
                      selectedShotRange.end,
                    ),
                  );
                }
              }}
              disabled={!selectedShotRange}
            >
              <CreateSequence />
            </ToolbarIconButton>
          )}
          {hasShotCreatePermission && (
            <ToolbarIconButton
              title={hasInOut ? gettext('Create shot') : gettext('Please set IN and OUT points')}
              disabled={!hasInOut}
              onClick={() => {
                if (inFrame !== null && outFrame !== null) {
                  dispatch(detailsActions.createShot(asset, inFrame, outFrame));
                }
              }}
            >
              <CreateShot />
            </ToolbarIconButton>
          )}
        </ToolbarButtonGroup>
      )}
      <ToolbarButtonGroup>
        <ToolbarIconButton
          title={gettext('Refresh asset')}
          onClick={() => {
            dispatch(detailsActions.refreshCurrentAsset());
          }}
        >
          <Refresh />
        </ToolbarIconButton>
      </ToolbarButtonGroup>
    </>
  );
};
export default AssetActionToolbar;
