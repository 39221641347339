import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC } from 'react';

import { ErrorML } from 'medialoopster/icons';

import IconCell, { IconCellProps } from './base/IconCell';

const useStyles = makeStyles(() => ({
  tooltipTitle: {
    whiteSpace: 'pre-line',
  },
  tooltipCursor: {
    cursor: 'help',
  },
  hiddenErrorInfoIcon: {
    visibility: 'hidden',
  },
}));

type Props = {
  readonly error: string;
  readonly noBorder?: boolean;
  readonly component?: 'span';
} & Pick<IconCellProps, 'disabled'>;

const ErrorCell: FC<Props> = ({ error, disabled = false, noBorder = false, component }: Props) => {
  const classes = useStyles();
  return (
    <IconCell disabled={disabled} noBorder={noBorder} component={component}>
      <Tooltip
        title={
          error ? (
            <Typography variant="body2" className={classes.tooltipTitle}>
              {error}
            </Typography>
          ) : (
            ''
          )
        }
        placement="left"
      >
        <ErrorML
          aria-hidden={!error}
          className={clsx({
            [classes.hiddenErrorInfoIcon]: !error,
            [classes.tooltipCursor]: !!error,
          })}
        />
      </Tooltip>
    </IconCell>
  );
};

export default ErrorCell;
