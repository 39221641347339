import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  getLinkHref,
  ResourceLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { AssetCollection } from '../rest/collection/types';
import { assetCollectionCreatedResult } from './actions';
import { getCollectionLink } from './selectors';
import {
  AssetCollectionCreatedResult,
  AssetCollectionResource,
  CREATE_ASSET_COLLECTION,
  CreateAssetCollection,
} from './types';

export const createAssetCollectionEpic: Epic<
  Action,
  AssetCollectionCreatedResult | ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<Action, CreateAssetCollection['type'], CreateAssetCollection>(CREATE_ASSET_COLLECTION),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { productionId, name, imageAssetIds, videoAssetIds, audioAssetIds },
        },
        state,
      ]) => {
        const collectionHref = getCollectionLink(state);
        if (collectionHref) {
          return postResource<
            AssetCollection,
            AssetCollectionResource,
            AssetCollectionCreatedResult,
            AssetCollectionCreatedResult
          >(
            collectionHref,
            {
              production: productionId,
              name,
              audio_assets: audioAssetIds,
              image_assets: imageAssetIds,
              video_assets: videoAssetIds,
            },
            (response) => {
              if (response) {
                return of(assetCollectionCreatedResult(getLinkHref(response, 'self'), []));
              }
              return EMPTY;
            },
            (errors) => of(assetCollectionCreatedResult(undefined, errors.response.errors)),
            undefined,
            undefined,
            {
              'Content-Type': 'application/json; version=3',
            },
          );
        }
        return EMPTY;
      },
    ),
  );

export default combineEpics<
  Action,
  AssetCollectionCreatedResult | ResourceLoadedAction | UnauthorizedRequestAction,
  RootState
>(createAssetCollectionEpic);
