import { LinkedItemsPageResource, ResourceMap, ResourceOptionsV2 } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { ImageAsset } from './types';

export const getImageAssets = (state: RootState): ResourceMap<ImageAsset, ResourceOptionsV2> =>
  state.image.assets;
export const getImageProjectsCollections = (
  state: RootState,
): ResourceMap<LinkedItemsPageResource<'imageasset-projects'>> => state.image.projectsCollections;
