import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Button } from 'medialoopster/components';
import { Favorites } from 'medialoopster/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(18),
    minWidth: 'min-content',
    '&:hover': {
      background: 'none',
    },
  },
}));

type FavoritesBrandProps = {
  onDoubleClick: () => void;
};

const FavoritesBrand: FC<FavoritesBrandProps> = ({ onDoubleClick }: FavoritesBrandProps) => {
  const classes = useStyles();
  return (
    <Button
      id="favoritesBrand"
      onDoubleClick={onDoubleClick}
      startIcon={<Favorites />}
      classes={{ root: classes.root }}
      disableRipple
      size="small"
    >
      {gettext('Favorites')}
    </Button>
  );
};

export default FavoritesBrand;
