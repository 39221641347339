import { forwardRef } from 'react';

import InternalTextField, { InputElementProps } from './internal/InternalTextField';

export type Props = Omit<InputElementProps, 'select' | 'defaultValue' | 'children'>;

const Input = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      name,
      label = '',
      placeholder = '',
      readOnly = false,
      autoFocus = false,
      value = undefined,
      inputRef = undefined,
      showError = false,
      errorMsg = '',
      maxLength = 524288,
      onChange = undefined,
      onKeyDown = undefined,
      onFocus = undefined,
      onBlur = undefined,
      onKeyUp = undefined,
      dense = false,
      InputProps = undefined,
      inputElementProps = undefined,
      multiline = undefined,
      required,
    }: Props,
    ref,
  ) => {
    return (
      <InternalTextField
        ref={ref}
        InputProps={InputProps}
        inputElementProps={inputElementProps}
        maxLength={maxLength}
        id={id}
        name={name}
        label={label}
        value={value}
        inputRef={inputRef}
        autoFocus={autoFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        showError={showError}
        errorMsg={errorMsg}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        dense={dense}
        /* eslint-disable-next-line react/no-children-prop */
        children={undefined}
        defaultValue={undefined}
        select={false}
        required={required}
        multiline={multiline}
      />
    );
  },
);

export default Input;
