import Slider, { SliderThumb } from '@mui/material/Slider';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';

const useStyles = makeStyles(() => ({
  inOutSlider: {
    position: 'absolute',
    width: 'calc(100% - 2px)',
    borderRadius: 'unset',
    '&.MuiSlider-root': {
      height: '14px',
      padding: '0px',
    },
    '&> .MuiSlider-rail': {
      display: 'none',
    },
    '&> .MuiSlider-track': {
      height: '12px',
      bottom: '0',
      borderColor: 'transparent',
      backgroundColor: '#dbdbdb',
      borderRight: '1px solid #b9b9b9',
      borderLeft: '1px solid #b9b9b9',
    },
  },
  marker: {
    display: 'none',
  },
}));

interface InOutSliderProps {
  inTime: number;
  outTime: number;
  duration: number;
}

const InOutSlider: FC<InOutSliderProps> = ({ inTime, outTime, duration }: InOutSliderProps) => {
  const classes = useStyles();
  const calcUiPosition = (position: number): number => {
    return (position / (duration || 1)) * 100;
  };

  const thumbComponent = (props: HTMLAttributes<HTMLSpanElement>) => {
    /* eslint-disable react/prop-types */
    return (
      <SliderThumb
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        className={clsx(props.className, classes.marker)}
      />
    );
    /* eslint-enable react/prop-types */
  };

  return (
    <Slider
      components={{ Thumb: thumbComponent }}
      className={classes.inOutSlider}
      value={[calcUiPosition(inTime), calcUiPosition(outTime)]}
      aria-labelledby="continuous-slider"
    />
  );
};

export default InOutSlider;
