import { FC, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Download } from 'medialoopster/icons';

import { downloadSelectors, downloadActions } from '../../../../../state/modules/download';
import PrepareDownloadModal from './PrepareDownloadModal';

type PrepareProxyDownloadActionMenuEntryProps = {
  readonly label: string;
  readonly id: string;
};

const PrepareProxyDownloadActionMenuEntry: FC<PrepareProxyDownloadActionMenuEntryProps> = ({
  label,
  id,
}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);

  const dryRunErrors = useSelector(downloadSelectors.getCurrentAssetProxyDownloadDryRunErrors);
  const hasDryRunError = useMemo(() => dryRunErrors.length > 0, [dryRunErrors]);

  const downloadErrrors = useSelector(downloadSelectors.getCurrentAssetProxyDownloadErrors);
  const hasDownloadError = useMemo(() => downloadErrrors.length > 0, [downloadErrrors]);

  const monitoringHref = useSelector(downloadSelectors.getCurrentAssetProxyDownloadMonitoringHref);
  const downloadHref = useSelector(downloadSelectors.getCurrentAssetProxyDownloadHref);
  const downloadReady = useSelector(downloadSelectors.getIsCurrentAssetProxyDownloadReady);

  const isFinished = useMemo(
    () => downloadReady || hasDownloadError,
    [hasDownloadError, downloadReady],
  );
  const menuTooltip = useMemo(() => {
    if (!hasDryRunError) {
      return gettext('Download proxy of the current asset');
    }
    return dryRunErrors.join(' ');
  }, [hasDryRunError, dryRunErrors]);

  const helpText = useMemo(() => {
    if (downloadErrrors.length) {
      return downloadErrrors.join(' ');
    }
    if (isFinished) {
      return gettext('Download ready');
    }
    return gettext('Preparing download...');
  }, [downloadErrrors, isFinished]);

  return (
    <>
      <MenuItem
        label={label}
        onClick={() => {
          setModalVisible(true);
          dispatch(downloadActions.setCurrentAssetProxyDownloadPreparing());
          dispatch(downloadActions.setCurrentAssetProxyDownloadOpen(true));
        }}
        tooltip={menuTooltip}
        icon={<Download fontSize="small" />}
        disabled={hasDryRunError}
      />
      <PrepareDownloadModal
        id={id}
        hasError={hasDownloadError}
        isFinished={isFinished}
        headerTitle={pgettext('Title-style capitalization', 'Download Asset Proxy')}
        onClose={() => {
          setModalVisible(false);
          dispatch(downloadActions.setCurrentAssetProxyDownloadOpen(false));
        }}
        open={isModalVisible}
        submitButtonTitle={pgettext('Form submit button title', 'Download asset proxy')}
        downloadHref={downloadHref}
        helpText={helpText}
        monitoringHref={monitoringHref}
      />
    </>
  );
};

export default PrepareProxyDownloadActionMenuEntry;
