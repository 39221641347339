/* eslint-disable no-underscore-dangle */

/**
 * This module defines a custom text node to represent transcript units
 */
import { addClassNamesToElement, removeClassNamesFromElement } from '@lexical/utils';
import { EditorConfig, LexicalEditor, LexicalNode, NodeKey, TextNode } from 'lexical';

import {
  UNIT_CLASS_NAME,
  UNIT_CURRENT_CLASS_NAME,
  UNIT_FUTURE_CLASS_NAME,
  UNIT_PAST_CLASS_NAME,
} from '../css';
import { decimal, Decimal } from '../decimal';

const PROGRESS_PAST = 'past';
const PROGRESS_CURRENT = 'current';
const PROGRESS_FUTURE = 'future';

/**
 * The posible states of progress of a transcript unit.
 *
 * A Transcript unit with a start time after the current time has progress 'future', a unit with
 * time + duration before the current time has progress 'past' and if the current time lies
 * int the timeframe of the unit the progress is 'current'.
 */
type Progress = typeof PROGRESS_PAST | typeof PROGRESS_CURRENT | typeof PROGRESS_FUTURE;

/**
 * The type of the original unit ID.
 *
 * This is a number if there is an original unit. If it is a new unit node then the
 * ID is set to `new`.
 */
type UnitId = number | 'new';

/**
 * A customized TextNode to represent transcript units.
 *
 * This text node is designed to replace the default text node.
 *
 * TranscriptUnitNode extends TextNode with the metadata of transcript units (time, duration and
 * confidence) and tracks the progress of the unit with respect to the current time of the asset.
 *
 * When rendered, transcript-units are tagged with CSS classes that mark them as transcript units
 * and that encode the progress state of the unit to allow for easy styling based on the progress.
 *
 * Updates to the progress must be triggered by calling `updateProgress` with the current time
 * whenever a change in the current time should be reflected in the progress state. Refer to
 * TranscriptProgressPlugin, where this is done.
 */
export class TranscriptUnitNode extends TextNode {
  /**
   * The start time of the unit in seconds.
   */
  __time: Decimal;

  /**
   * The duration of the unit in seconds.
   */
  __duration: Decimal;

  /**
   * The confidence of the unit [0...1]
   */
  __confidence: Decimal;

  /**
   * The progress state of the unit with respect to the current time. See `Progress` above.
   */
  __progress: Progress;

  /**
   * The id of the source transcript unit during initialization. If there is no corresponding
   * unit in the transcript, this can be set to 'new'.
   */
  __id: UnitId;

  /**
   * The revision of the current node.
   *
   * This gets incremented whenever the node is cloned. The revision therefore provides a way
   * to detect if a modification of the node stems from an edit or an undo command.
   */
  __revision: number;

  /**
   * Get the start-time of the transcript unit represented by this node.
   */
  getTime(): Decimal {
    return this.getLatest().__time;
  }

  /**
   * Get the duration of the transcript unit represented by this node.
   */
  getDuration(): Decimal {
    return this.getLatest().__duration;
  }

  /**
   * Get the confidence value of the transcript unit represented by this node.
   */
  getConfidence(): Decimal {
    return this.getLatest().__confidence;
  }

  /**
   * Set the confidence of the transcript unit represented by this node.
   * @param confidence
   */
  setConfidence(confidence: Decimal): void {
    const self = this.getWritable();
    self.__confidence = confidence;
  }

  /**
   * Get the progress state.
   * @private
   */
  private getProgress(): Progress {
    return this.getLatest().__progress;
  }

  /**
   * Set the progress state
   * @param progress The new progress state.
   * @private
   */
  private setProgress(progress: Progress): void {
    this.getWritable().__progress = progress;
  }

  /**
   * Predicate indicating if this transcript unit is in the past of the current play-head position.
   */
  isPast(): boolean {
    return this.getProgress() === PROGRESS_PAST;
  }

  /**
   * Predicate indicating if the transcript unit is the current unit corresponding to the play-head position.
   */
  isCurrent(): boolean {
    return this.getProgress() === PROGRESS_CURRENT;
  }

  /**
   * Predicate indicating if the transcript unit is in the future of the current play-head position.
   */
  isFuture(): boolean {
    return this.getProgress() === PROGRESS_FUTURE;
  }

  /**
   * Get the id of the transcript unit represented by this node.
   */
  getId(): UnitId {
    return this.getLatest().__id;
  }

  /**
   * Returns true, if the unit was created by $createUninitializedUnitNode or without
   * data respectively.
   */
  isInitialized(): boolean {
    return this.getTime().gte('0') && this.getDuration().gte('0');
  }

  constructor(
    text: string,
    time: Decimal,
    duration: Decimal,
    confidence: Decimal,
    progress: Progress,
    revision: number,
    id?: number,
    key?: NodeKey,
  ) {
    super(text, key);
    this.__time = time;
    this.__duration = duration;
    this.__confidence = confidence;
    this.__progress = progress;
    this.__revision = revision;
    this.__id = id === undefined ? 'new' : id;
  }

  static getType(): string {
    return 'transcript-unit';
  }

  static clone(node: TranscriptUnitNode): TranscriptUnitNode {
    return new TranscriptUnitNode(
      node.__text,
      node.__time,
      node.__duration,
      node.__confidence,
      node.__progress,
      node.__revision + 1,
      node.__id === 'new' ? undefined : node.__id,
      node.__key,
    );
  }

  /*
  Usual createDOM method that also sets class names according to the metadata
   */
  createDOM(config: EditorConfig, editor?: LexicalEditor): HTMLElement {
    const element = super.createDOM(config, editor);
    this.updateClassNames(element);
    return element;
  }

  /*
  Update the DOM element by changing its class names

  Also, if the Text changed then the unit was edited and the confidence is set to 1 assuming
  the user knew what he did ;)
   */
  updateDOM(prevNode: TranscriptUnitNode, element: HTMLElement, config: EditorConfig): boolean {
    const superUpdated = super.updateDOM(prevNode, element, config);
    if (
      // the text has changes
      prevNode.__text !== this.__text &&
      // AND this is a forward edit and no undo
      prevNode.__revision < this.__revision
    ) {
      this.setConfidence(decimal('1'));
    }
    this.updateClassNames(element);
    return superUpdated;
  }

  /**
   * Update the class names of a html element based on the state/metadata of the unit.
   *
   * @param element The dom element to update
   * @private
   */
  private updateClassNames(element: HTMLElement): void {
    addClassNamesToElement(element, UNIT_CLASS_NAME);
    if (this.isPast()) {
      addClassNamesToElement(element, UNIT_PAST_CLASS_NAME);
    } else {
      removeClassNamesFromElement(element, UNIT_PAST_CLASS_NAME);
    }
    if (this.isCurrent()) {
      addClassNamesToElement(element, UNIT_CURRENT_CLASS_NAME);
    } else {
      removeClassNamesFromElement(element, UNIT_CURRENT_CLASS_NAME);
    }
    if (this.isFuture()) {
      addClassNamesToElement(element, UNIT_FUTURE_CLASS_NAME);
    } else {
      removeClassNamesFromElement(element, UNIT_FUTURE_CLASS_NAME);
    }
  }

  /**
   * Recalculate the progress state of the unit node for a given current time.
   *
   * The method is called with the current time of the asset and calculates the progress of the
   * unit with respect to this instant.
   *
   * @param time The current time of the asset/transcript
   */
  updateProgress(time: Decimal): void {
    const startTime = this.getTime();
    const duration = this.getDuration();
    const oldProgress = this.getProgress();

    const endTime = startTime.plus(duration);

    let newProgress: Progress;
    if (time.lt(startTime)) {
      newProgress = PROGRESS_FUTURE;
    } else if (startTime.lte(time) && time.lt(endTime)) {
      newProgress = PROGRESS_CURRENT;
    } else {
      newProgress = PROGRESS_PAST;
    }

    if (newProgress !== oldProgress) {
      this.setProgress(newProgress);
    }
  }

  /**
   * Get the index of the logical start of the unit.
   *
   * Units may contain leading whitespace for formatting purposes. This method returns
   * the start-index of the actual text-content of the unit.
   */
  getTrimmedStartIndex(): number {
    const textContent = this.getTextContent();
    return textContent.length - textContent.trimStart().length;
  }
}

/**
 * Create a new TranscriptUnitNode
 * @param text The text of the unit
 * @param time The start time of the unit
 * @param duration The duration of the unit
 * @param confidence The confidence of the unit
 * @param id The id of the input transcript unit or new if there is no corresponding input
 */
export const $createTranscriptUnitNode = (
  text: string,
  time: Decimal,
  duration: Decimal,
  confidence: Decimal,
  id?: number,
): TranscriptUnitNode =>
  new TranscriptUnitNode(text, time, duration, confidence, PROGRESS_FUTURE, 0, id);

/**
 * Create a unit with text but without data such as time or duration.
 *
 * The unit will return false on isInitialized.
 *
 * @param text
 */
export const $createUninitializedUnitNode = (text: string): TranscriptUnitNode =>
  new TranscriptUnitNode(text, decimal('-1'), decimal('-1'), decimal('1'), PROGRESS_FUTURE, 0);

/**
 * Type Predicate for TranscriptUnitNodes.
 *
 * @param node The node to test
 */
export const $isTranscriptUnitNode = (
  node: LexicalNode | null | undefined,
): node is TranscriptUnitNode => node instanceof TranscriptUnitNode;
