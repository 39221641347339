import {
  ActionsMetadataV2,
  BaseResource,
  createResourcesLoadedActionType,
  RelatedCollectionResourceMap,
  Resource,
  ResourceMap,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { MediaAssetType, AssetType } from '../../types/asset/baseTypes';
import { AudioAsset } from '../audio/types';
import { ImageAsset } from '../image/types';
import { SiteExportConfig } from '../rest/siteexportconfigs/types';
import { Shot, VideoAsset } from '../video/types';

export const FAVORITE_ITEM_LIST_LOADED = createResourcesLoadedActionType('favoriteitemlist');

export const TOGGLE_FAVORITES = 'FRONTEND/FAVORITES/TOGGLE_FAVORITES';
export const SHOW_FAVORITES = 'FRONTEND/FAVORITES/SHOW_FAVORITES';
export const HIDE_FAVORITES = 'FRONTEND/FAVORITES/HIDE_FAVORITES';
export const FETCH_FAVORITE_LISTS = 'FRONTEND/FAVORITES/FETCH_FAVORITE_LISTS';
export const FETCH_SELECTED_LIST_ITEMS = 'FRONTEND/FAVORITES/FETCH_SELECTED_LIST_ITEMS';
export const MOVE_FAVORITE_ITEM = 'FRONTEND/FAVORITES/MOVE_FAVORITE_ITEM';
export const DELETE_FAVORITE_ITEM = 'FRONTEND/FAVORITES/DELETE_FAVORITE_ITEM';
export const SELECT_FAVORITE_ITEM_ASSET = 'FRONTEND/FAVORITES/SELECT_FAVORITE_ITEM_ASSET';
export const FETCH_FAVORITE_LISTS_OPTIONS = 'FRONTEND/FAVORITES/FETCH_FAVORITE_LISTS_OPTIONS';
export const FAVORITE_LISTS_OPTIONS_LOADED = 'FRONTEND/FAVORITES/FAVORITE_LISTS_OPTIONS_LOADED';
export const EXPORT_TO_RENDER_ENGINE = 'FRONTEND/FAVORITES/EXPORT_TO_RENDER_ENGINE';
export const FETCH_EDITOR_EXPORT_OPTIONS = 'FRONTEND/FAVORITES/FETCH_EDITOR_EXPORT_OPTIONS';
export const SELECT_FAVORITE_LIST = 'FRONTEND/FAVORITES/SELECT_FAVORITE_LIST';
export const SET_SHARED_ACTIVE_LISTS = 'FRONTEND/FAVORITES/SET_SHARED_ACTIVE_LISTS';
export const CLEAR_FAVORITE_LIST = 'FRONTEND/FAVORITES/CLEAR_FAVORITE_LIST';
export const UPDATE_PERMISSIONS = 'FRONTEND/FAVORITES/UPDATE_PERMISSIONS';
export const FETCH_PERMISSION = 'FRONTEND/FAVORITES/FETCH_PERMISSION';
export const CREATE_FAVORITE_LIST = 'FRONTEND/FAVORITES/CREATE_FAVORITE_LIST';
export const EDIT_FAVORITE_LIST = 'FRONTEND/FAVORITES/EDIT_FAVORITE_LIST';
export const DELETE_FAVORITE_LIST = 'FRONTEND/FAVORITES/DELETE_FAVORITE_LIST';
export const REMOVE_SHARED_ACTIVE_FAVORITE_LIST =
  'FRONTEND/FAVORITES/REMOVE_SHARED_ACTIVE_FAVORITE_LIST';
export const SHARE_LIST_CONTENTS = 'FRONTEND/FAVORITES/SHARE_LIST_CONTENTS';
export const ADD_ASSET_TO_FAVORITES = 'FRONTEND/FAVORITES/ADD_ASSET_TO_FAVORITES';
export const ADD_ASSETS_TO_FAVORITES = 'FRONTEND/FAVORITES/ADD_ASSETS_TO_FAVORITES';
export const ADD_VIDEO_CLIP_TO_FAVORITES = 'FRONTEND/FAVORITES/ADD_VIDEO_CLIP_TO_FAVORITES';
export const ADD_SHOT_TO_FAVORITES = 'FRONTEND/FAVORITES/ADD_SHOT_TO_FAVORITES';
export const ADD_SEQUENCE_TO_FAVORITES = 'FRONTEND/FAVORITES/ADD_SEQUENCE_TO_FAVORITES';
export const SET_LEGACY_ASSET_CHOICES = 'FRONTEND/FAVORITES/SET_LEGACY_ASSET_CHOICES';
export const SET_LIST_ASSETS_LOADING = 'FRONTEND/FAVORITES/SET_LIST_ASSETS_LOADING';

//-------------
// Action Types
//-------------

export interface ToggleFavorites {
  readonly type: typeof TOGGLE_FAVORITES;
}

export interface ShowFavorites {
  readonly type: typeof SHOW_FAVORITES;
}

export interface HideFavorites {
  readonly type: typeof HIDE_FAVORITES;
}

export interface FetchFavoriteLists {
  readonly type: typeof FETCH_FAVORITE_LISTS;
}

export interface FetchSelectedListItems {
  readonly type: typeof FETCH_SELECTED_LIST_ITEMS;
}

export interface SelectFavoriteItemAsset {
  readonly type: typeof SELECT_FAVORITE_ITEM_ASSET;
  readonly payload: {
    readonly assetTypeName: AssetType;
    readonly assetHref: string;
    readonly assetId: number;
  };
}

export interface FetchFavoriteListsOptions {
  readonly type: typeof FETCH_FAVORITE_LISTS_OPTIONS;
}

export interface FavoriteListsOptionsLoaded {
  readonly type: typeof FAVORITE_LISTS_OPTIONS_LOADED;
  readonly payload: {
    readonly actions: ActionsMetadataV2;
    readonly allowHeader: string;
  };
}

export interface ExportToRenderEngine {
  readonly type: typeof EXPORT_TO_RENDER_ENGINE;
  readonly payload: {
    readonly exportChoice: SiteExportConfig;
    readonly favoriteXMLDownloadURI: string;
    readonly baseXMLFileName: string;
  };
}

export interface SelectFavoriteList {
  readonly type: typeof SELECT_FAVORITE_LIST;
  readonly payload: {
    readonly listId: number | null;
  };
}

export interface SetSharedActiveLists {
  readonly type: typeof SET_SHARED_ACTIVE_LISTS;
  readonly payload: {
    readonly ids: ReadonlyArray<number>;
  };
}

export interface MoveFavoriteItem {
  readonly type: typeof MOVE_FAVORITE_ITEM;
  readonly payload: {
    favoriteItemId: number;
    index: number;
  };
}

export interface DeleteFavoriteItem {
  readonly type: typeof DELETE_FAVORITE_ITEM;
  readonly payload: {
    readonly favoriteItemId: number;
  };
}

export interface ClearFavoriteList {
  readonly type: typeof CLEAR_FAVORITE_LIST;
}

export interface FetchPermission {
  readonly type: typeof FETCH_PERMISSION;
  readonly payload: {
    readonly url: string;
  };
}

export interface UpdatePermissions {
  readonly type: typeof UPDATE_PERMISSIONS;
  readonly payload: {
    readonly url: string;
    readonly hasPermission: boolean;
  };
}

export interface CreateFavoriteList {
  readonly type: typeof CREATE_FAVORITE_LIST;
  readonly payload: {
    readonly favoriteList: FavoritesListPostResource;
  };
}

export interface EditFavoriteList {
  readonly type: typeof EDIT_FAVORITE_LIST;
  readonly payload: {
    readonly favoriteList: FavoritesListPatchResource;
  };
}

export interface DeleteFavoriteList {
  readonly type: typeof DELETE_FAVORITE_LIST;
  readonly payload: {
    readonly favoriteListId: number;
  };
}

export interface RemoveSharedActiveList {
  readonly type: typeof REMOVE_SHARED_ACTIVE_FAVORITE_LIST;
  readonly payload: {
    readonly favoriteListId: number;
  };
}

export interface ShareListContents {
  readonly type: typeof SHARE_LIST_CONTENTS;
}

export interface AddAssetToFavorites {
  readonly type: typeof ADD_ASSET_TO_FAVORITES;
  readonly payload: {
    readonly assetTypeName: FavoritesItemAssetType;
    readonly assetId: number;
  };
}

export interface AddAssetsToFavorites {
  readonly type: typeof ADD_ASSETS_TO_FAVORITES;
  readonly payload: {
    readonly assetTypeName: FavoritesItemAssetType;
    readonly assetIds: ReadonlyArray<number>;
  };
}

export interface AddVideoClipToFavorites {
  readonly type: typeof ADD_VIDEO_CLIP_TO_FAVORITES;
  readonly payload: {
    readonly assetTypeName: FavoritesItemAssetType;
    readonly assetId: number;
    readonly timecodeStart: number;
    readonly timecodeEnd: number;
  };
}

export interface AddShotToFavorites {
  readonly type: typeof ADD_SHOT_TO_FAVORITES;
  readonly payload: {
    readonly shotId: number;
  };
}

export interface AddSequenceToFavorites {
  readonly type: typeof ADD_SEQUENCE_TO_FAVORITES;
  readonly payload: {
    readonly shots: ReadonlyArray<Shot>;
  };
}
export interface SetListAssetsLoading {
  readonly type: typeof SET_LIST_ASSETS_LOADING;
  readonly payload: {
    readonly loading: boolean;
  };
}

//---------------
// Resource Types
//---------------

export type FavoritesItemAssetType = MediaAssetType;

export type FavoritesItemAsset = AudioAsset | ImageAsset | VideoAsset | null;

export interface FavoritesListResource extends Resource<'favoriteitemlist'> {
  readonly id: number;
  readonly owner: number;
  readonly name: string;
  readonly shared_with: ReadonlyArray<number>;
}

export interface FavoritesListPostResource extends BaseResource {
  readonly name: string;
  readonly shared_with?: ReadonlyArray<number>;
}

export type FavoritesListPatchResource = Partial<FavoritesListResource>;

export interface FavoritesItemResource extends Resource<'favoriteitem'> {
  readonly id: number;
  readonly creator: number;
  readonly name: string;
  readonly favorite_item_list: number;
  readonly position: number;
  readonly asset_id: number | null;
  readonly asset_type: FavoritesItemAssetType | null;
  readonly thumbnail_url?: string;
  readonly timecode_start?: number;
  readonly timecode_end?: number;
}

export interface FavoritesItemPostResource extends BaseResource {
  readonly favorite_item_list: number;
  readonly asset_id: number | null;
  readonly asset_type: FavoritesItemAssetType | null;
  readonly thumbnail_url?: string;
  readonly timecode_start?: number;
  readonly timecode_end?: number;
}

//------------------
// Redux State Types
//------------------

export interface FavoritesListById {
  readonly [id: string]: FavoritesListResource;
}

export interface FavoritesItemState extends FavoritesItemResource {
  readonly asset: FavoritesItemAsset | null;
  readonly deleted: boolean;
  readonly detail: string;
  readonly displayName: string;
  readonly assetIsAvailable: boolean;
  readonly thumbnailURL?: string;
}

export interface FavoritesListState extends FavoritesListResource {
  readonly shared: boolean;
}

export interface SharedFavoritesListState extends FavoritesListResource {
  readonly displayName: string;
}

export interface SelectedFavoritesListState extends FavoritesListResource {
  readonly items: ReadonlyArray<FavoritesItemState>;
}

export interface ModelOptionsState {
  readonly FavoriteItemLists: {
    readonly modelOptions: {
      readonly actions: ActionsMetadataV2;
      readonly allow: string;
      readonly canCreate: boolean;
    };
  };
}

export interface FavoritesListAssetsState {
  readonly audioasset: ReadonlyRecord<string, AudioAsset>;
  readonly imageasset: ReadonlyRecord<string, ImageAsset>;
  readonly videoasset: ReadonlyRecord<string, VideoAsset>;
}

export interface FavoritesRootState {
  readonly isActive: boolean;
  readonly selectedListId: number | null;
  readonly sharedActiveLists: ReadonlyArray<number>;
  readonly models: ModelOptionsState;
  readonly favoriteItemLists: ResourceMap<FavoritesListResource>;
  readonly favoriteItemCollections: RelatedCollectionResourceMap<
    FavoritesListResource,
    'favoriteitems'
  >;
  readonly favoriteItems: ResourceMap<FavoritesItemResource>;
  readonly selectedListAssetsLoading: boolean;
}
