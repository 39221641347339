import { BaseField } from 'medialoopster/forms';
import {
  BaseResource,
  BaseResourceEmbedded,
  BaseResourceLinks,
  Link,
  Resource,
  ResourceActionV3,
  ResourceMap,
  ResourceOptionsV3,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { AssetType } from '../../types/asset/baseTypes';

export const OPEN_BULK_EDIT_MODAL = 'BULK_EDIT/OPEN_BULK_EDIT_MODAL';
export type OpenBulkEditModal = {
  readonly type: typeof OPEN_BULK_EDIT_MODAL;
};

export const CLOSE_BULK_EDIT_MODAL = 'BULK_EDIT/CLOSE_BULK_EDIT_MODAL';
export type CloseBulkEditModal = {
  readonly type: typeof CLOSE_BULK_EDIT_MODAL;
};

export const SET_BULK_EDIT_FIELD_ERRORS = 'BULK_EDIT/SET_BULK_EDIT_FIELD_ERRORS';
export type SetBulkEditFieldErrors = {
  readonly type: typeof SET_BULK_EDIT_FIELD_ERRORS;
  readonly payload: {
    readonly errors: ReadonlyRecord<string, ReadonlyArray<string>>;
  };
};

export const SET_BULK_EDIT_KEYWORDS_FIELD_ERRORS = 'BULK_EDIT/SET_BULK_EDIT_KEYWORDS_FIELD_ERRORS';
export type SetBulkEditKeywordsFieldErrors = {
  readonly type: typeof SET_BULK_EDIT_KEYWORDS_FIELD_ERRORS;
  readonly payload: {
    readonly errors: ReadonlyRecord<string, KeywordsErrors>;
  };
};

export const SUBMIT_BULK_EDIT = 'BULK_EDIT/SUBMIT_BULK_EDIT';
export type SubmitBulkEdit = {
  readonly type: typeof SUBMIT_BULK_EDIT;
  readonly payload: {
    readonly bulkEditResource: BulkEditResource;
  };
};

export const LOCK_ASSETS_SUCCESS = 'BULK_EDIT/LOCK_ASSETS_SUCCESS';
export type LockAssetsSuccess = {
  readonly type: typeof LOCK_ASSETS_SUCCESS;
};

export const ASSETS_BULK_EDIT_FIELDS_LOADED = 'BULK_EDIT/ASSETS_BULK_EDIT_FIELDS_LOADED';
export type AssetsBulkEditFieldsLoaded = {
  readonly type: typeof ASSETS_BULK_EDIT_FIELDS_LOADED;
  readonly payload: {
    readonly fields: ResourceActionV3;
  };
};

interface BulkEditLinks extends BaseResourceLinks {
  readonly assets_list: ReadonlyArray<Link>;
}

interface AssetUpdates extends BaseResource {
  readonly [key: string]:
    | string
    | number
    | null
    | BaseResourceLinks
    | BaseResourceEmbedded
    | undefined
    | ReadonlyArray<string>;
}

interface BulkEditEmbedded extends BaseResourceEmbedded {
  readonly asset_updates?: AssetUpdates;
  readonly shot_updates?: AssetUpdates;
}

export type BulkEditResource = BaseResource<BulkEditLinks, BulkEditEmbedded>;

export type BulkEditActionsState<T extends AssetType = AssetType> = {
  readonly lockAssetsLink: Link | null;
  readonly unlockAssetsLink: Link | null;
  readonly bulkEditLink: Link | null;
  readonly bulkEditResources: ResourceMap<Resource<`${T}-bulk-edit`>, ResourceOptionsV3>;
};

export type BulkEditRootState = {
  readonly modalIsOpen: boolean;
  readonly assetsBulkEditFields: ResourceActionV3 | null;
  readonly formFields: {
    readonly errors: ReadonlyRecord<string, ReadonlyArray<string>>;
    readonly keywordsErrors: ReadonlyRecord<string, KeywordsErrors>;
  };
  readonly actions: {
    readonly videoasset: BulkEditActionsState<'videoasset'>;
    readonly audioasset: BulkEditActionsState<'audioasset'>;
    readonly imageasset: BulkEditActionsState<'imageasset'>;
    readonly collection: BulkEditActionsState<'collection'>;
  };
};

export type KeywordsValue = {
  readonly added_keywords: ReadonlyArray<string>;
  readonly removed_keywords: ReadonlyArray<string>;
};

export type KeywordsErrors = ReadonlyRecord<string, Array<string>>;

export type KeywordsField = BaseField<KeywordsValue, KeywordsErrors> & {
  readonly type: 'keywords';
  readonly keywords: ReadonlyArray<string>;
};
