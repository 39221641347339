import { ContentAnalysisConnectorResource } from 'medialoopster/modules';
import {
  Resource,
  ResourceMap,
  Link,
  mlRel,
  RelatedCollectionResourceMap,
  CollectionResourceMap,
} from 'medialoopster/rest';

import { ImageAsset } from '../image/types';
import { AssetChoice } from '../operations/types';
import { VideoAsset } from '../video/types';

export const CONTENT_ANALYSIS_CONNECTOR_COLLECTION_REL = mlRel('contentanalysisconnectors');
export const ANALYSE_ASSETS_REL = mlRel('analyse-assets');

//-------------
// Action Types
//-------------

export const FETCH_KNOWN_PERSONS = '/FRONTEND/PERSONS/FETCH_KNOWN_PERSONS';
export interface FetchKnownPersons {
  readonly type: typeof FETCH_KNOWN_PERSONS;
  readonly payload: {
    readonly url: string;
  };
}

export const FETCH_FACES_OPTIONS = 'FRONTEND/PERSONS/FETCH_FACES_OPTIONS';
export interface FetchFacesOptions {
  readonly type: typeof FETCH_FACES_OPTIONS;
}

export const FETCH_SEGMENTS_OPTIONS = 'FRONTEND/PERSONS/FETCH_SEGMENTS_OPTIONS';
export interface FetchSegmentsOptions {
  readonly type: typeof FETCH_SEGMENTS_OPTIONS;
}

export const SET_CONFIDENCE_THRESHOLD = 'FRONTEND/PERSONS/SET_CONFIDENCE_THRESHOLD';
export interface SetConfidenceThreshold {
  readonly type: typeof SET_CONFIDENCE_THRESHOLD;
  readonly payload: {
    readonly confidenceThreshold: string;
  };
}

export const SET_ACTIVE_PERSON = 'FRONTEND/PERSONS/SET_ACTIVE_PERSON';
export interface SetActivePerson {
  readonly type: typeof SET_ACTIVE_PERSON;
  readonly payload: {
    readonly uri: string;
  };
}

export const FETCH_CURRENT_ASSET_PERSON_COUNT = 'FRONTEND/PERSONS/FETCH_CURRENT_ASSET_PERSON_COUNT';
export interface FetchCurrentAssetPersonCount {
  readonly type: typeof FETCH_CURRENT_ASSET_PERSON_COUNT;
}

export const SET_CURRENT_ASSET_PERSON_COUNT = 'FRONTEND/PERSONS/SET_CURRENT_ASSET_PERSON_COUNT';
export interface SetCurrentAssetPersonCount {
  readonly type: typeof SET_CURRENT_ASSET_PERSON_COUNT;
  readonly payload: {
    readonly count: number;
  };
}

export const RENAME_PERSON = '/FRONTEND/PERSONS/RENAME_PERSON';
export interface RenamePerson {
  readonly type: typeof RENAME_PERSON;
  readonly payload: {
    readonly url: string;
    readonly name: string;
  };
}

export const ASSIGN_FACE = '/FRONTEND/PERSONS/ASSIGN_FACE';
export interface AssignFace {
  readonly type: typeof ASSIGN_FACE;
  readonly payload: {
    readonly url: string;
    readonly person: number;
  };
}

export const SPLIT_FACES = '/FRONTEND/PERSONS/SPLIT_FACES';
export interface SplitFaces {
  readonly type: typeof SPLIT_FACES;
}

export const TOGGLE_PERSONS_VISIBLE = '/FRONTEND/PERSONS/TOGGLE_PERSONS_VISIBLE';
export interface TogglePersonsVisible {
  readonly type: typeof TOGGLE_PERSONS_VISIBLE;
}

export const TOGGLE_ALL_BOUNDING_BOXES_VISIBLE =
  '/FRONTEND/PERSONS/TOGGLE_ALL_BOUNDING_BOXES_VISIBLE';
export interface ToggleAllBoundingBoxesVisible {
  readonly type: typeof TOGGLE_ALL_BOUNDING_BOXES_VISIBLE;
}

export const SET_HAS_RENAME_ERROR = '/FRONTEND/PERSONS/SET_HAS_RENAME_ERROR';
export interface SetHasRenameError {
  readonly type: typeof SET_HAS_RENAME_ERROR;
  readonly payload: {
    readonly hasError: boolean;
  };
}

export const ANALYSE_ASSETS = '/FRONTEND/PERSONS/ANALYSE_ASSETS';
export interface AnalyseAssets {
  readonly type: typeof ANALYSE_ASSETS;
  readonly payload: {
    readonly actionURI: string;
    readonly assetChoices: ReadonlyArray<AssetChoice>;
  };
}

export const SEEK_TO_ACTIVE_PERSON = '/FRONTEND/PERSONS/SEEK_TO_ACTIVE_PERSON';
export interface SeekToActivePerson {
  readonly type: typeof SEEK_TO_ACTIVE_PERSON;
}

//---------------
// Resource Types
//---------------

export interface PersonResource extends Resource<'person'> {
  readonly id: number;
  readonly display_name: string;
  readonly name: string;
  readonly thumbnail_url: string;
}

export interface FaceResource extends Resource<'face'> {
  readonly id: number;
  readonly face_id: string;
  readonly person: number;
}

export interface SegmentResource extends Resource<'segment'> {
  readonly id: number;
  readonly analysis: number;
  readonly person: number;
  readonly face: number;
  readonly start_time: string; // floating point number as string
  readonly end_time: string; // floating point number as string
  readonly confidence: string; // floating point number as string
  readonly videoasset?: number;
  readonly imageasset?: number;
  readonly box: ReadonlyArray<readonly [string, string]>; // floating point numbers as strings
}

//------------
// State Types
//------------

export interface Person extends PersonResource {
  readonly startTimeMs: number;
}

export interface Segment extends SegmentResource {
  readonly startTimeMs: number; // parsed start_time
  readonly endTimeMs: number; // parsed end_time
  readonly startFrameNo: number; // via getAssetSegments selector
  readonly endFrameNo: number; // via getAssetSegments selector
}

export interface TimelineSegment extends Segment {
  readonly color: string;
}

export interface AnalyseConnectorChoice {
  readonly name: string;
  readonly analyseActionURL: string;
  readonly assetChoices: ReadonlyArray<AssetChoice>;
}

export interface BoundingBox {
  readonly segmentURI: string;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly bottom: number;
  readonly width: number;
  readonly center: number;
  readonly faceURI: string;
  readonly personURI: string;
  readonly isUnknown: boolean;
  readonly confidence: number;
  readonly name: ReadonlyArray<string>;
  readonly textAreaTop: number;
  readonly textAreaLeft: number;
  readonly textAreaWidth: number;
  readonly textAreaHeight: number;
}

export interface OverlayDisplayValues {
  readonly padding: number;
  readonly mediaWidth: number;
}

export interface PersonsRootState {
  readonly confidenceThreshold: string;
  readonly segments: ResourceMap<SegmentResource>;
  readonly segmentCollections: {
    readonly videoasset: RelatedCollectionResourceMap<VideoAsset, 'segments'>;
    readonly imageasset: RelatedCollectionResourceMap<ImageAsset, 'segments'>;
    readonly 'segment-collection': CollectionResourceMap<Segment>;
  };
  readonly persons: ResourceMap<PersonResource>;
  readonly faces: ResourceMap<FaceResource>;
  readonly faceCollections: CollectionResourceMap<FaceResource>;
  readonly activePersonURI: string | null;
  readonly currentAssetPersonCount: number;
  readonly isPersonsVisible: boolean;
  readonly isAllBoundingBoxesVisible: boolean;
  readonly hasRenameError: boolean;
  readonly connectorCollections: CollectionResourceMap<ContentAnalysisConnectorResource>;
  readonly connectors: ResourceMap<ContentAnalysisConnectorResource>;
  readonly connectorCollectionLink: Link | null;
}
