import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import copy from 'copy-to-clipboard';
import { FC, memo } from 'react';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { ContentCopy, ErrorML } from 'medialoopster/icons';
import { TaskStatus } from 'medialoopster/types';

import IconButton from '../../buttons/IconButton';
import ButtonCell, { ButtonCellProps } from './base/ButtonCell';

const useStyles = makeStyles(() => ({
  cell: {
    '&.MuiTableCell-sizeSmall $errorIcon': {
      marginBottom: '-0.2em',
    },
  },
  errorIcon: {
    marginTop: '0.2em',
    cursor: 'help',
  },
  tooltipTitle: {
    whiteSpace: 'pre-line',
  },
}));

type Props = {
  readonly status: TaskStatus;
  readonly taskId: string;
  readonly info: string;
} & Pick<ButtonCellProps, 'disabled'>;

const TaskErrorCell: FC<Props> = ({ status, taskId, info, disabled = false }: Props) => {
  const classes = useStyles();
  return (
    <ButtonCell className={classes.cell} disabled={disabled}>
      {status > TaskStatus.SUCCESS && status < TaskStatus.REVOKED && (
        <div style={{ display: 'flex' }}>
          <Tooltip
            title={
              <div>
                <Typography variant="body1" className={classes.tooltipTitle}>
                  {gettext('Task')}
                  {': '}
                  {taskId}
                </Typography>
                <Typography variant="body2" className={classes.tooltipTitle}>
                  {info}
                </Typography>
              </div>
            }
            placement="left"
          >
            <div className={classes.errorIcon}>
              <ErrorML />
            </div>
          </Tooltip>
          <Tooltip
            title={
              <Typography variant="body2" className={classes.tooltipTitle}>
                {pgettext('Tooltip', 'Copy error message to clipboard')}
              </Typography>
            }
            placement="top"
          >
            <div>
              <IconButton
                onClick={() => {
                  copy(`${gettext('Task')}: ${taskId}\n\n${info}`);
                }}
                disabled={disabled}
                aria-label={gettext('Copy error message to clipboard')}
              >
                <ContentCopy />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      )}
    </ButtonCell>
  );
};

export default memo(TaskErrorCell);
