import _ from 'lodash';
import { createSelector } from 'reselect';

import { ResourceMap } from 'medialoopster/rest';

import { MediaType, AssetType } from '../../types/asset/baseTypes';
import { RootState } from '../../types/rootState';
import { searchSelectors } from '../search';
import { KeywordCategory, KeywordCategoryType } from './types';

const assetTypeToMediaTypeMap: Record<AssetType, MediaType | null> = {
  videoasset: 'video',
  audioasset: 'audio',
  imageasset: 'image',
  collection: null,
};

export const getCurrentCategoryMediaType = (state: RootState): MediaType | null => {
  const assetType = searchSelectors.getSelectedAssetsTypeName(state);
  return assetType ? assetTypeToMediaTypeMap[assetType] : null;
};

export const getCurrentCategory = (
  state: RootState,
): ResourceMap<KeywordCategory<KeywordCategoryType>> | null => {
  const mediaType = getCurrentCategoryMediaType(state);
  if (!mediaType) {
    return null;
  }
  return state.keywords.categories[mediaType];
};

export const getCurrentAssetTypeCategories = createSelector(
  getCurrentCategory,
  (category): ReadonlyArray<KeywordCategory<KeywordCategoryType>> => {
    return category?.resources ? Object.values(category?.resources) : [];
  },
);

export const getCurrentAssetTypeKeywords = createSelector(
  getCurrentAssetTypeCategories,
  (categories: ReadonlyArray<KeywordCategory<KeywordCategoryType>>): ReadonlyArray<string> =>
    categories
      .map((category) => category.keywords)
      .reduce((keywords1, keywords2) => _.union(keywords1, keywords2), []),
);

export const getVideoAssetKeywordCategories = (
  state: RootState,
): ResourceMap<KeywordCategory<KeywordCategoryType>> => state.keywords.categories.video;
