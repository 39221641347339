import { APIFieldError, APIError } from 'medialoopster/rest';

import {
  SetCanSeeCurrentAssetProxyDownload,
  SET_CAN_SEE_CURRENT_ASSET_PROXY_DOWNLOAD,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_READY,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_ERRORS,
  SetCurrentAssetProxyDownloadErrors,
  SetCurrentAssetProxyDownloadReady,
  SetCurrentAssetProxyDownloadPreparing,
  SetCurrentAssetProxyDownloadOpen,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_OPEN,
  UpdateCurrentAssetProxyDownloadActivity,
  UPDATE_CURRENT_ASSET_PROXY_DOWNLOAD_ACTIVITY,
  AssetProxyDownloadActivity,
  SetCurrentAssetProxyDownloadDryRunErrors,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_DRY_RUN_ERRORS,
} from './types';

export const setCanSeeCurrenAssetProxyDownload = (
  canSeeCurrenAssetProxyDownload: boolean,
): SetCanSeeCurrentAssetProxyDownload => ({
  type: SET_CAN_SEE_CURRENT_ASSET_PROXY_DOWNLOAD,
  payload: {
    canSeeCurrenAssetProxyDownload,
  },
});

export const setCurrentAssetProxyDownloadPreparing = (): SetCurrentAssetProxyDownloadPreparing => ({
  type: SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING,
});

export const setCurrentAssetProxyDownloadReady = (): SetCurrentAssetProxyDownloadReady => ({
  type: SET_CURRENT_ASSET_PROXY_DOWNLOAD_READY,
});

export const setCurrentAssetProxyDownloadOpen = (
  isOpen: boolean,
): SetCurrentAssetProxyDownloadOpen => ({
  type: SET_CURRENT_ASSET_PROXY_DOWNLOAD_OPEN,
  payload: {
    isOpen,
  },
});
export const updateCurrentAssetProxyDownloadActivity = (
  activity: AssetProxyDownloadActivity,
): UpdateCurrentAssetProxyDownloadActivity => ({
  type: UPDATE_CURRENT_ASSET_PROXY_DOWNLOAD_ACTIVITY,
  payload: {
    activity,
  },
});

export const setCurrentAssetProxyDownloadErrors = (
  errors: ReadonlyArray<Pick<APIError, 'detail'>>,
): SetCurrentAssetProxyDownloadErrors => ({
  type: SET_CURRENT_ASSET_PROXY_DOWNLOAD_ERRORS,
  payload: {
    errors,
  },
});

export const setCurrentAssetProxyDownloadDryRunErrors = (
  errors: ReadonlyArray<APIError | APIFieldError>,
): SetCurrentAssetProxyDownloadDryRunErrors => ({
  type: SET_CURRENT_ASSET_PROXY_DOWNLOAD_DRY_RUN_ERRORS,
  payload: {
    errors,
  },
});
