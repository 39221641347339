import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  createCollectionSelector,
  createResourceMap,
  getLinkHref,
  getLinks,
  getResource,
  getResourceOptions,
  getResourceTypeName,
  getResourceURI,
  isResource,
  isResourceOptionsV2,
  LinkedItemsPageResource,
  mlRel,
  normalizeURI,
  ResourceMap,
  ResourceOptionsV2,
  ResourceOptionsV3,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import {
  isAudioAsset,
  isImageAsset,
  isVideoAsset,
} from '../../../businessRules/models/asset/utils';
import { idFromHref } from '../../../businessRules/services/idFromHref';
import { AssetType } from '../../types/asset/baseTypes';
import { Asset, MediaAsset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { getAnnotations } from '../annotation/selectors';
import { Annotation } from '../annotation/types';
import { getAudioAssets, getAudioProjectsCollections } from '../audio/selectors';
import { AudioAsset } from '../audio/types';
import { getImageAssets, getImageProjectsCollections } from '../image/selectors';
import { ImageAsset } from '../image/types';
import { CategoryKeywords, KeywordCategory, KeywordCategoryType } from '../keywords/types';
import { getProjectAssets } from '../project/selectors';
import { getAssetCollectionResourceMap } from '../rest/collection/selectors';
import { isAssetCollection } from '../rest/collection/types';
import { transcriptSelectors, transcriptTypes } from '../rest/transcript';
import { transcriptSpeakerSelectors, transcriptSpeakerTypes } from '../rest/transcriptspeaker';
import { transcriptUnitSelectors, transcriptUnitTypes } from '../rest/transcriptunit';
import { getCurrentUser, getUsersMap, getCurrentUserURI } from '../users/selectors';
import { User } from '../users/types';
import {
  getSequences,
  getShots,
  getSubtitles,
  getVideoAssets,
  getVideoProjectsCollections,
} from '../video/selectors';
import { SequenceMetadata, Shot, VideoAsset } from '../video/types';
import {
  CollectionFile,
  AncestorDetails,
  CustomMetadataChoice,
  CustomMetadataField,
  CustomMetadataSet,
  CustomMetadataSetWithFields,
  License,
  Licensor,
  ShotRangeState,
  ValidationRule,
  ViewLayoutState,
  ViewType,
  CurrentAssetState,
  EmbeddedAssetState,
  DetailsRootState,
  TranscriptAvailability,
  CurrentTranscriptState,
} from './types';

/**
 * Get the details state slice form the root state.
 * @param state The global root state.
 */
const getSelf = (state: RootState): DetailsRootState => state.details;

// region current asset

const assetMapGetterByType = {
  videoasset: getVideoAssets,
  audioasset: getAudioAssets,
  imageasset: getImageAssets,
  projectasset: getProjectAssets,
  collection: getAssetCollectionResourceMap,
};

export const getCurrentAssetState = (state: RootState): CurrentAssetState =>
  state.details.currentAsset;
export const getCurrentAssetHref = (state: RootState): string | null =>
  getCurrentAssetState(state).href;
export const getCurrentAssetTypeName = (state: RootState): AssetType | null =>
  getCurrentAssetState(state).type;

export const getCurrentAssetOptions = createSelector(
  getCurrentAssetHref,
  (state: RootState) =>
    state.details.currentAsset.type
      ? assetMapGetterByType[state.details.currentAsset.type](state)
      : null,
  (href, assetMap): ResourceOptionsV2 | ResourceOptionsV3 | null => {
    if (href === null || !assetMap) {
      return null;
    }
    return getResourceOptions<ResourceOptionsV2 | ResourceOptionsV3>(assetMap, href) || null;
  },
);

export const getCurrentAsset = createSelector(
  getCurrentAssetState,
  (state: RootState) =>
    state.details.currentAsset.type
      ? assetMapGetterByType[state.details.currentAsset.type](state)
      : null,
  ({ type, href }, assetMap) => {
    if (type === null || href === null || !assetMap) {
      return null;
    }
    return getResource<Asset>(assetMap, href) || null;
  },
);

export const getEmbeddedAssetState = (state: RootState): EmbeddedAssetState =>
  state.details.embeddedAsset;

export const getEmbeddedAssetHref = (state: RootState): string | null =>
  getEmbeddedAssetState(state).href;

export const getEmbeddedAsset = createSelector(
  getEmbeddedAssetState,
  (state: RootState) =>
    state.details.embeddedAsset.type
      ? assetMapGetterByType[state.details.embeddedAsset.type](state)
      : null,
  ({ type, href }, assetMap) => {
    if (type === null || href === null || !assetMap) {
      return null;
    }
    return getResource<Asset>(assetMap, href) || null;
  },
);

export const getEmbeddedVideoSubtitleCollection = createCollectionSelector(
  (state) => {
    const asset = getEmbeddedAsset(state);
    return asset ? getLinkHref(asset, mlRel('subtitles')) : null;
  },
  (state) => state.video.subtitleCollections,
  getSubtitles,
);

// endregion

// region current asset locking

export const isCurrentAssetLocked = (state: RootState): boolean => {
  const currentAsset = getCurrentAsset(state);
  if (!currentAsset) {
    return false;
  }
  if (isAssetCollection(currentAsset)) {
    return !!getLinkHref(currentAsset, 'locked_by');
  }
  return !!getLinkHref(currentAsset, mlRel('locked_by'));
};

export const getCurrentAssetLockedByHref = createSelector(
  getCurrentAsset,
  (currentAsset): string | null => {
    if (!currentAsset) {
      return null;
    }
    return (
      getLinkHref(
        currentAsset,
        isAssetCollection(currentAsset) ? 'locked_by' : mlRel('locked_by'),
      ) || null
    );
  },
);

export const getCurrentAssetLockedBy = createSelector(
  getCurrentAssetLockedByHref,
  getUsersMap,
  (lockedByHref, users): User | null => {
    if (!lockedByHref) {
      return null;
    }
    return getResource(users, lockedByHref) || null;
  },
);

export const isCurrentAssetLockedByCurrentUser = (state: RootState): boolean => {
  const currentAssetLockedByHref = getCurrentAssetLockedByHref(state);
  if (!currentAssetLockedByHref) {
    return false;
  }
  const currentUserHref = getCurrentUserURI(state);
  if (!currentUserHref) {
    return false;
  }
  return normalizeURI(currentAssetLockedByHref) === normalizeURI(currentUserHref);
};

export const isEditMode = (state: RootState): boolean => isCurrentAssetLockedByCurrentUser(state);

// endregion

// region current transcript

/**
 * Get the url to the transcript collection of the current asset.
 *
 * @returns The URL of the related transcript collection of the current VideoAsset or undefined, if
 * the asset is no video or has no related collection.
 */
export const getCurrentTranscriptsUrl: (state: RootState) => string | undefined = createSelector(
  [getCurrentAsset],
  (currentAsset) =>
    isVideoAsset(currentAsset) || isAudioAsset(currentAsset)
      ? getLinkHref(currentAsset, mlRel('transcripts'))
      : undefined,
);

/**
 * Get the collection of transcripts for the current asset.
 */
export const getCurrentTranscriptsCollection = createCollectionSelector(
  getCurrentTranscriptsUrl,
  transcriptSelectors.getTranscriptPageMap,
  transcriptSelectors.getTranscriptResourceMap,
);

/**
 * Get the transcript resource for the current asset.
 *
 * If there are more than one transcript for the current asset, then the first transcript
 * is returned.
 *
 * If there is no transcript, then null is returned.
 *
 * @param state The global root state.
 * @returns The first transcript resource for the current asset if available, else null.
 */
export const getCurrentTranscript = (state: RootState): transcriptTypes.Transcript | null => {
  const transcriptCollection = getCurrentTranscriptsCollection(state);
  if (transcriptCollection.totalCount > 0) {
    return transcriptCollection.items[0];
  }
  return null;
};

/**
 * Get the url for the transcript units of the (first) transcript of the current asset.
 * @param state The global root state.
 * @returns The url to the transcript unit if available, else null.
 */
export const getCurrentUnitsUrl = (state: RootState): string | null => {
  const transcript = getCurrentTranscript(state);
  return transcript ? transcriptTypes.getUnitsUrl(transcript) : null;
};

/**
 * Get the transcript unit collection for the first transcript of the current asset.
 */
export const getCurrentUnitsCollection = createCollectionSelector(
  getCurrentUnitsUrl,
  transcriptUnitSelectors.getTranscriptUnitPageMap,
  transcriptUnitSelectors.getTranscriptUnitResourceMap,
);

/**
 * Get the array of transcript units for the first transcript of the current asset.
 * @param state The global root state.
 * @returns An array of TranscriptUnit resources if available, else null.
 */
export const getCurrentUnits = (
  state: RootState,
): readonly transcriptUnitTypes.TranscriptUnit[] => {
  const unitCollection = getCurrentUnitsCollection(state);
  return unitCollection.items;
};

/**
 * Get the url to the related speaker collection of the first transcript of the current asset.
 *
 * @param state The global root state.
 * @returns The url of the related speaker collection if available, else null.
 */
export const getCurrentSpeakersUrl = (state: RootState): string | null => {
  const transcript = getCurrentTranscript(state);
  return transcript ? transcriptTypes.getSpeakersUrl(transcript) : null;
};

/**
 * Get the collection of speakers of the first transcript of the current asset.
 */
export const getCurrentSpeakersCollection = createCollectionSelector(
  getCurrentSpeakersUrl,
  transcriptSpeakerSelectors.getTranscriptSpeakerPageMap,
  transcriptSpeakerSelectors.getTranscriptSpeakerResourceMap,
);

/**
 * Get the array of speakers of the first transcript of the current asset.
 * @param state The global root state.
 * @returns An array of TranscriptSpeaker resources if available, else null.
 */
export const getCurrentSpeakers = (
  state: RootState,
): readonly transcriptSpeakerTypes.TranscriptSpeaker[] => {
  const speakerCollection = getCurrentSpeakersCollection(state);
  return speakerCollection.items;
};

/**
 * Get the current transcript state from the root state.
 * @param state the global root state.
 */
const getCurrentTranscriptState = (state: RootState): CurrentTranscriptState =>
  getSelf(state).currentTranscript;

/**
 * Get the availability of the current transcript.
 * @param state The global root state.
 */
export const getCurrentTranscriptAvailability = (state: RootState): TranscriptAvailability =>
  getCurrentTranscriptState(state).availability;

/**
 * Select if the transcript is currently editable.
 * @param state The global root state.
 */
export const isCurrentTranscriptEditable = (state: RootState): boolean =>
  isEditMode(state) && getCurrentTranscriptAvailability(state) === 'ready';

// endregion current transcript

export const getLicensorCollection = createCollectionSelector<RootState, Licensor>(
  (state) => state.details.licensorCollectionLink?.href,
  (state) => state.details.licensorCollections,
  (state) => state.details.licensors,
);
export const getLicenseCollection = createCollectionSelector<RootState, License>(
  (state) => state.details.licenseCollectionLink?.href,
  (state) => state.details.licenseCollections,
  (state) => state.details.licenses,
);
export const getLicenses = (state: RootState): ResourceMap<License> => state.details.licenses;
export const getLayout = (state: RootState): ViewLayoutState => state.details.layout;
export const getLayoutView = (state: RootState): ViewType => getLayout(state).view;

export const getCurrentAssetType = createSelector(getCurrentAsset, (currentAsset) => {
  // WARNING: resource might not be available yet when selector updates.
  // E.g when  page reloads with previously selected asset
  if (!isResource(currentAsset)) {
    return null;
  }
  return getResourceTypeName(currentAsset) || null;
});

export const canLockCurrentAsset = (state: RootState): boolean => {
  const options = getCurrentAssetOptions(state);
  if (!options) {
    return false;
  }
  const field = isResourceOptionsV2(options)
    ? options.actions?.PATCH?.locked_by
    : options.actions?.PATCH?.links?.locked_by;
  return !!field && !field.read_only;
};

export const getCurrentVideoSequencesCollection = createCollectionSelector(
  (state) => {
    const currentAsset = getCurrentAsset(state);
    return currentAsset ? getLinkHref(currentAsset, mlRel('sequences')) : null;
  },
  (state) => state.video.sequencesCollections,
  getSequences,
);

export const getKeywordsByCategories = <T extends KeywordCategoryType>(
  keywords: ReadonlyArray<string>,
  keywordCategories: { [p: string]: KeywordCategory<T> },
  productionId: number,
): ReadonlyArray<CategoryKeywords> =>
  Object.values(keywordCategories)
    .filter((category) => category.productions.includes(productionId))
    .flatMap((category) => {
      const intersection = _.intersection(keywords, category.keywords);
      return [
        {
          name: category.name,
          keywords: intersection,
          availableKeywords: category.keywords,
        },
      ];
    });

export const getCurrentVideoSequencesMetadata = createSelector(
  getCurrentVideoSequencesCollection,
  getShots,
  (state: RootState) => state.keywords.categories.video?.resources,
  getCurrentAsset,
  (
    sequencesCollection,
    shots,
    keywordCategories,
    currentAsset,
  ): ReadonlyRecord<string, SequenceMetadata> =>
    currentAsset && isVideoAsset(currentAsset)
      ? sequencesCollection.items.reduce((result, sequence) => {
          const href = getResourceURI(sequence);
          const shotLinks = getLinks(sequence, mlRel('shots'));
          const sequenceShots = shotLinks
            .map((link) => shots.resources[link.href])
            .filter((shot) => shot !== undefined);
          const licensor =
            _.intersection(...sequenceShots.map((shot) => [shot.licensor]))[0] ?? null;
          const license = _.intersection(...sequenceShots.map((shot) => [shot.license]))[0] ?? null;
          const locationCity =
            _.intersection(...sequenceShots.map((shot) => [shot.location_city]))[0] ?? '';
          const keywords = _.intersection(...sequenceShots.map((shot) => shot.keywords));
          const keywordsByCategory = getKeywordsByCategories(
            keywords,
            keywordCategories,
            currentAsset.production,
          );
          const sequenceAncestors = sequenceShots.reduce((prev, shot) => {
            const ancestorHref = getLinkHref(shot, mlRel('original_ancestor'));
            if (ancestorHref) {
              return _.uniq([...prev, ancestorHref]);
            }
            return prev;
          }, [] as ReadonlyArray<string>);
          // TODO: highlight format must be changed in backend after switch to react only
          // using href instead of id <RS>
          const id = idFromHref(href);
          return {
            ...result,
            [href]: {
              shots: sequenceShots,
              licensor,
              license,
              locationCity,
              keywordsByCategory,
              sequenceAncestors,
              id,
            },
          };
        }, {})
      : {},
);

export const getFirstShotURI = (state: RootState): string | null => {
  const firstShot = Object.values(getCurrentVideoSequencesMetadata(state)).map(
    (sequenceMetadata) => sequenceMetadata.shots[0],
  )[0];
  return firstShot ? getResourceURI(firstShot) : null;
};

export const canEditShotKeywords = (state: RootState): boolean => {
  const firstShotURI = getFirstShotURI(state);
  if (!firstShotURI) {
    return false;
  }
  const options = getShots(state).options[firstShotURI];
  const readOnly = options?.actions?.PATCH?.keywords?.read_only;
  if (readOnly === undefined) {
    return false;
  }
  return !readOnly;
};

export const canEditCurrentAssetKeywords = (state: RootState): boolean => {
  const options = getCurrentAssetOptions(state);
  if (!options) {
    return false;
  }
  const readOnly = isResourceOptionsV2(options)
    ? options?.actions?.PATCH?.keywords?.read_only
    : options?.actions?.PATCH?.fields?.keywords?.read_only;
  if (readOnly === undefined) {
    return false;
  }
  return !readOnly;
};

export const getFirstSequenceURI = createSelector(
  getCurrentVideoSequencesMetadata,
  (sequenceMetadata) => {
    const firstSequenceURI = Object.keys(sequenceMetadata)[0];
    return firstSequenceURI;
  },
);

export const getFirstSequenceOptions = createSelector(
  getFirstSequenceURI,
  getSequences,
  (firstSequenceURI, sequences) =>
    firstSequenceURI ? getResourceOptions(sequences, firstSequenceURI) : undefined,
);

export const getCanEditSequenceLicense = createSelector(
  getFirstSequenceOptions,
  (options) => !(options?.actions?.PATCH?.license?.read_only ?? true),
);

export const getCanEditSequenceLicensor = createSelector(
  getFirstSequenceOptions,
  (options) => !(options?.actions?.PATCH?.licensor?.read_only ?? true),
);

export const getCanEditSequenceLocationCity = createSelector(
  getFirstSequenceOptions,
  (options) => !(options?.actions?.PATCH?.location_city?.read_only ?? true),
);

export const getCanEditSquenceRightsRNB = createSelector(
  getFirstSequenceOptions,
  (options) => !(options?.actions?.PATCH?.rights_rnb?.read_only ?? true),
);

/**
 * Given that the current asset is a project, get a collection of its linked audio assets.
 */
export const getCurrentCollectionAudioCollection = createCollectionSelector(
  (state) => {
    const currentAsset = getCurrentAsset(state);
    if (isAssetCollection(currentAsset)) {
      return getLinkHref(currentAsset, 'audioassets');
    }
    return null;
  },
  (state) => state.rest.collection.audioassets,
  getAudioAssets,
);

/**
 * Given that the current asset is a project, get a collection of its linked image assets.
 */
export const getCurrentCollectionImageCollection = createCollectionSelector(
  (state) => {
    const currentAsset = getCurrentAsset(state);
    if (isAssetCollection(currentAsset)) {
      return getLinkHref(currentAsset, 'imageassets');
    }
    return null;
  },
  (state) => state.rest.collection.imageassets,
  getImageAssets,
);

/**
 * Given that the current asset is a project, get a collection of its linked video assets.
 */
export const getCurrentCollectionVideoCollection = createCollectionSelector(
  (state) => {
    const currentAsset = getCurrentAsset(state);
    if (isAssetCollection(currentAsset)) {
      return getLinkHref(currentAsset, 'videoassets');
    }
    return null;
  },
  (state) => state.rest.collection.videoassets,
  getVideoAssets,
);

/**
 * Get the media assets from the current loaded asset, given that it is a collection asset.
 */
export const getCurrentCollectionContent = createSelector(
  getCurrentCollectionVideoCollection,
  getCurrentCollectionImageCollection,
  getCurrentCollectionAudioCollection,
  (videoCollection, imageCollection, audioCollection): ReadonlyArray<MediaAsset> => [
    ...videoCollection.items,
    ...imageCollection.items,
    ...audioCollection.items,
  ],
);
export const getInaccessibleVideoAssetCount = (state: RootState): number => {
  const currentAsset = getCurrentAsset(state);
  if (!isAssetCollection(currentAsset)) {
    return 0;
  }
  const assetCollectionVideoCollection = getCurrentCollectionVideoCollection(state);
  if (!assetCollectionVideoCollection.loaded) {
    return 0;
  }
  return currentAsset.video_asset_count - assetCollectionVideoCollection.totalCount;
};

export const getInaccessibleImageAssetCount = (state: RootState): number => {
  const currentAsset = getCurrentAsset(state);
  if (!isAssetCollection(currentAsset)) {
    return 0;
  }
  const assetCollectionImageCollection = getCurrentCollectionImageCollection(state);
  if (!assetCollectionImageCollection.loaded) {
    return 0;
  }
  return currentAsset.image_asset_count - assetCollectionImageCollection.totalCount;
};

export const getInaccessibleAudioAssetCount = (state: RootState): number => {
  const currentAsset = getCurrentAsset(state);
  if (!isAssetCollection(currentAsset)) {
    return 0;
  }
  const assetCollectionAudioCollection = getCurrentCollectionAudioCollection(state);
  if (!assetCollectionAudioCollection.loaded) {
    return 0;
  }
  return currentAsset.audio_asset_count - assetCollectionAudioCollection.totalCount;
};

// TODO: Need collections for assets <PB 2024-04-08 t:ML-2034>
// Keep selector for now.
export const getCurrentAssetProjectsCollection = createCollectionSelector(
  (state) => {
    const currentAsset = getCurrentAsset(state);
    return currentAsset ? getLinkHref(currentAsset, mlRel('projects')) : null;
  },
  createSelector(
    getCurrentAssetState,
    getVideoProjectsCollections,
    getImageProjectsCollections,
    getAudioProjectsCollections,
    (
      currentAssetState,
      videoProjectsCollections,
      imageProjectsCollections,
      audioProjectsCollection,
    ): ResourceMap<
      LinkedItemsPageResource<'videoasset-projects' | 'audioasset-projects' | 'imageasset-projects'>
    > => {
      if (currentAssetState.type === 'videoasset') {
        return videoProjectsCollections;
      }
      if (currentAssetState.type === 'imageasset') {
        return imageProjectsCollections;
      }
      if (currentAssetState.type === 'audioasset') {
        return audioProjectsCollection;
      }
      return createResourceMap('videoasset-projects', []);
    },
  ),
  getProjectAssets,
);

export const getCurrentAssetSuccessorsCollection = createCollectionSelector(
  (state) => {
    const currentAsset = getCurrentAsset(state);
    return currentAsset ? getLinkHref(currentAsset, mlRel('successors')) : null;
  },
  (state) => state.video.successorsCollections,
  getVideoAssets,
);

export const getCustomMetadataSetCollection = createCollectionSelector<
  RootState,
  CustomMetadataSet
>(
  (state) => state.details.customMetadataSetCollectionLink?.href,
  (state) => state.details.customMetadataSetCollections,
  (state) => state.details.customMetadataSets,
);

export const getCustomMetadataChoiceCollection = createCollectionSelector<
  RootState,
  CustomMetadataChoice
>(
  (state) => state.details.customMetadataChoiceCollectionLink?.href,
  (state) => state.details.customMetadataChoiceCollections,
  (state) => state.details.customMetadataChoices,
);

export const getMetadataChoices = createSelector(
  getCustomMetadataChoiceCollection,
  (customMetadataChoiceCollection) => {
    const metadataChoices = customMetadataChoiceCollection.items;
    const metadata2ChoiceMap: Record<string, ReadonlyArray<string>> = Object.entries(
      _.groupBy(metadataChoices, (elem) => getLinkHref(elem, mlRel('custom_metadata'))),
    ).reduce(
      (accum, [href, obj]) => ({
        ...accum,
        [href]: _.sortBy(obj, 'order').map(({ value }) => value),
      }),
      {},
    );
    return metadata2ChoiceMap;
  },
);

export const getValidationRulesCollection = createCollectionSelector<RootState, ValidationRule>(
  (state) => state.details.validationrulesCollectionLink?.href,
  (state) => state.details.validationrulesCollections,
  (state) => state.details.validationrules,
);

export const getCustomMetadataSets = createSelector(
  getCustomMetadataSetCollection,
  getMetadataChoices,
  getValidationRulesCollection,
  (state: RootState) => state.details.customMetadata,
  (
    customMetadataSetCollection,
    metadataChoices,
    validationRulesCollection,
    customMetadata,
  ): ReadonlyArray<CustomMetadataSetWithFields> => {
    const withChoices = Object.values(customMetadata.resources).map((elem) => ({
      ...elem,
      choices: metadataChoices[getResourceURI(elem)] || [],
    }));
    const withChoicesAndValidationRule: CustomMetadataField[] = withChoices.map((elem) => ({
      ...elem,
      rule:
        validationRulesCollection.items.find(
          (rule) => getLinkHref(elem, mlRel('validation_rule')) === getResourceURI(rule),
        )?.rule || '',
    }));
    const setHref2CustomMetadata = _.groupBy(withChoicesAndValidationRule, (field) =>
      getLinkHref(field, mlRel('custom_metadata_set')),
    );

    const customMetadataFields: CustomMetadataSetWithFields[] = _(customMetadataSetCollection.items)
      .orderBy('order')
      .value()
      .map((customMetadataSet) => {
        const fields: CustomMetadataField[] = _(
          setHref2CustomMetadata[getResourceURI(customMetadataSet)] || [],
        )
          .sortBy('order')
          .value();
        return {
          ...customMetadataSet,
          fields,
        };
      });
    return customMetadataFields;
  },
);

const getCurrentAssetKeywordsByCategories = <T extends KeywordCategoryType>(
  currentAsset: ImageAsset | AudioAsset,
  keywordCategories: { [p: string]: KeywordCategory<T> },
): ReadonlyArray<CategoryKeywords> =>
  getKeywordsByCategories(currentAsset.keywords, keywordCategories, currentAsset.production);

export const getCurrentAudioAssetKeywordsByCategories = createSelector(
  getCurrentAsset,
  (state: RootState) => state.keywords.categories.audio?.resources,
  (currentAsset, keywordCategories) => {
    if (!isAudioAsset(currentAsset)) return [];
    return getCurrentAssetKeywordsByCategories(currentAsset, keywordCategories);
  },
);

export const getCurrentImageAssetKeywordsByCategories = createSelector(
  getCurrentAsset,
  (state: RootState) => state.keywords.categories.image?.resources,
  (currentAsset, keywordCategories): ReadonlyArray<CategoryKeywords> => {
    if (!isImageAsset(currentAsset)) return [];
    return getCurrentAssetKeywordsByCategories(currentAsset, keywordCategories);
  },
);

export const getSelectedShotRange = (state: RootState): ShotRangeState | null =>
  state.details.selectedShotRange;

export const getLicenseChoices = createSelector(getLicenseCollection, (licenses) => [
  { display: '---', value: '*' },
  ...licenses.items
    .filter((elem) => elem.show_in_browser)
    .map(({ name }) => ({ display: name, value: name })),
]);

export const getLicensorChoices = createSelector(getLicensorCollection, (licensors) => [
  { display: '---', value: '*' },
  ...licensors.items
    .filter((elem) => elem.show_in_browser)
    .map(({ name }) => ({ display: name, value: name })),
]);

export const getAncestorLoadingErrors = (ancestorHref: string): ((state: RootState) => string) =>
  createSelector(
    (state: RootState) => state.details.loadAncestorErrors,
    (errors) => errors[ancestorHref],
  );

export const getAncestorDetails = (ancestorHref: string): ((state: RootState) => AncestorDetails) =>
  createSelector(
    getShots,
    getVideoAssets,
    getAncestorLoadingErrors(ancestorHref),
    (shots, videos, ancestorError) => {
      if (ancestorError) {
        return { shot: undefined, video: undefined, error: ancestorError };
      }
      const ancestor: Shot | undefined = shots.resources[ancestorHref];
      if (ancestor) {
        const assetHref = getLinkHref(ancestor, mlRel('asset'));
        if (assetHref) {
          const ancestorAsset: VideoAsset | undefined = videos.resources[assetHref];
          return { shot: ancestor, video: ancestorAsset, error: undefined };
        }
      }
      return { shot: undefined, video: undefined, error: undefined };
    },
  );

// region annotations

export const showAnnotations = (state: RootState): boolean =>
  state.details.showAnnotations && state.details.layout.view !== 'search';

/**
 * Get the url to the annotations collection of the current asset.
 *
 * @returns The URL of the related annotations collection of the current VideoAsset or undefined, if
 * the asset is no video or has no related collection.
 */
export const getCurrentAnnotationsUrl: (state: RootState) => string | undefined = createSelector(
  [getCurrentAsset],
  (currentAsset) =>
    isVideoAsset(currentAsset) ? getLinkHref(currentAsset, mlRel('annotations')) : undefined,
);

/**
 * Get the resource collection of annotations that belong to the current video asset.
 *
 * The collection will be empty, if the current asset is no video asset.
 */
export const getCurrentVideoAnnotationsCollection = createCollectionSelector(
  getCurrentAnnotationsUrl,
  (state: RootState) => state.video.annotationsCollections,
  getAnnotations,
);

/**
 * Selector for the flag that indicates if all annotations are to be shown.
 * @param state The root state.
 */
export const getShowAllAnnotations = (state: RootState): boolean =>
  state.details.showAllAnnotations;

/**
 * Selector for the filtered current annotations.
 *
 * Annotations contain all annotations by the current user, and public annotations of other
 * users, if showAllAnnotations is set to true.
 */
export const getCurrentAnnotations: (state: RootState) => Annotation[] = createSelector(
  [getCurrentVideoAnnotationsCollection, getCurrentUser, getShowAllAnnotations],
  (annotationCollection, currentUser, showAll) => {
    if (currentUser === undefined) return [];
    const currentUserURI = getResourceURI(currentUser);
    const annotationFilter = (annotation: Annotation) => {
      const isOwnAnnotation = getLinkHref(annotation, mlRel('user')) === currentUserURI;
      const isPublic = annotation.is_public;
      return isOwnAnnotation || (isPublic && showAll);
    };
    return annotationCollection.items.filter(annotationFilter);
  },
);

/**
 * Selector to indicate if the current asset has annotations.
 */
export const getCurrentAssetHasAnnotations: (state: RootState) => boolean = createSelector(
  [getCurrentVideoAnnotationsCollection],
  (currentAnnotationsCollection) => currentAnnotationsCollection.totalCount > 0,
);

/**
 * Selector to indicate if an annotation URI is currently in edit mode.
 */
export const getIsEditingAnnotation =
  (uri: string) =>
  (state: RootState): boolean =>
    state.details.editingAnnotation === uri;

export const getCurrentUserAnnotations = createSelector(
  getCurrentVideoAnnotationsCollection,
  getCurrentUserURI,
  (annotationCollection, currentUserURI): ReadonlyArray<Annotation> => {
    return annotationCollection.items.filter(
      (annotation) => getLinkHref(annotation, mlRel('user')) === currentUserURI,
    );
  },
);

export const getPublishAnnotationsURL = (state: RootState): string | null =>
  state.annotation.publishAnnotationsLink?.href || null;

export const getUnpublishAnnotationsURL = (state: RootState): string | null =>
  state.annotation.unpublishAnnotationsLink?.href || null;

export const getDeleteAnnotationsURL = (state: RootState): string | null =>
  state.annotation.deleteAnnotationsLink?.href || null;

export const getHasUnpublishedAnnotations = createSelector(
  getCurrentUserAnnotations,
  (annotations: ReadonlyArray<Annotation>): boolean =>
    !!annotations.find((annotation) => !annotation.is_public),
);

export const getHasPublishedAnnotations = createSelector(
  getCurrentUserAnnotations,
  (annotations: ReadonlyArray<Annotation>): boolean =>
    !!annotations.find((annotation) => annotation.is_public),
);

// endregion

/**
 * Selector returning a REST collection of the file resources of the current asset collection.
 * Returns an empty array if the current asset is not a collection.
 */
export const getCurrentCollectionFilesCollection = createCollectionSelector(
  (state: RootState) => {
    const currentAsset = getCurrentAsset(state);
    return currentAsset ? getLinkHref(currentAsset, 'files') : null;
  },
  (state: RootState) => state.rest.file.pages,
  (state: RootState) => state.rest.file.objects,
);

/**
 * Selector returning the files of the current asset collection as an array of `CollectionFile`s.
 */
export const getCurrentCollectionFiles = createSelector(
  getCurrentAsset,
  getCurrentCollectionFilesCollection,
  (currentAsset, filesCollection): ReadonlyArray<CollectionFile> => {
    if (isAssetCollection(currentAsset)) {
      return filesCollection.items.map((file) => ({
        path: file.file_path,
        folder: file.file_path.split('/').slice(0, -1).join('/').split(currentAsset.bundle_root)[1],
        name: file.file_name,
        type: file.file_type,
      }));
    }
    return [];
  },
);

export const getCurrentCollectionHasFiles = createSelector(
  getCurrentCollectionFiles,
  (files) => files.length > 0,
);

export const canCreateCollection = (state: RootState): boolean => {
  const collectionURL = state.rest.collection.collectionLink?.href;
  if (!collectionURL) {
    return false;
  }
  const options = getResourceOptions(state.rest.collection.pages, collectionURL);
  return !!options?.actions.POST;
};

/**
 * Get the collection choice if available.
 */
export const isCurrentCollectionEmpty = createSelector(
  getCurrentAsset,
  getCurrentCollectionFiles,
  (asset, files) =>
    isAssetCollection(asset)
      ? files.length +
          asset.video_asset_count +
          asset.image_asset_count +
          asset.audio_asset_count ===
        0
      : true,
);

/**
 * Check if collection has no files.
 */
export const isCurrentCollectionWithoutFiles = createSelector(
  getCurrentAsset,
  getCurrentCollectionFiles,
  (asset, files): boolean => (isAssetCollection(asset) ? files.length === 0 : false),
);

/**
 * Check if collection is not in production.
 */
export const isNotInProductionCollection = createSelector(getCurrentAsset, (asset): boolean =>
  isAssetCollection(asset) ? !asset.is_production : false,
);

/**
 * Return if current collection is loading.
 */
export const isCurrentCollectionLoading = createSelector(
  getCurrentCollectionVideoCollection,
  getCurrentCollectionImageCollection,
  getCurrentCollectionAudioCollection,
  getCurrentCollectionFilesCollection,
  (vidColl, imageColl, audioColl, filesColl) =>
    !(vidColl.loaded && imageColl.loaded && audioColl.loaded && filesColl.loaded),
);
