import { Box } from '@mui/material';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { StatusLabel } from 'medialoopster/components';
import { Approve, Archived, Disapprove, NotArchived, Offline, Online } from 'medialoopster/icons';

import { Asset } from '../../state/types/asset/unionTypes';

type Props = {
  readonly asset: Asset;
};

const AssetStatus: FC<Props> = ({ asset }: Props) => (
  <Box display="flex" gap={1} marginY={3}>
    <StatusLabel
      label={
        asset.is_production ? gettext('Ready for production') : gettext('Not ready for production')
      }
      icon={asset.is_production ? <Online fontSize="inherit" /> : <Offline fontSize="inherit" />}
      color={asset.is_production ? 'success' : 'default'}
    />
    <StatusLabel
      label={asset.is_archive ? gettext('Stored in archive') : gettext('Not in archive')}
      icon={asset.is_archive ? <Archived fontSize="inherit" /> : <NotArchived fontSize="inherit" />}
      color={asset.is_archive ? 'success' : 'default'}
    />
    <StatusLabel
      label={asset.status_approval === 1 ? gettext('Approved') : gettext('Not approved')}
      icon={
        asset.status_approval === 1 ? (
          <Approve fontSize="inherit" />
        ) : (
          <Disapprove fontSize="inherit" />
        )
      }
      color={asset.status_approval === 1 ? 'success' : 'default'}
    />
  </Box>
);
export default AssetStatus;
