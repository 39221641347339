import { FC, useState, createContext, ReactNode, useContext } from 'react';

import { RowData } from '../types';

const PaginationContext = createContext<ReadonlyArray<RowData>>([]);
const PaginationChangeContext = createContext<(slice: ReadonlyArray<RowData>) => void>(() => {});

export const usePaginationContext = (): ReadonlyArray<RowData> => useContext(PaginationContext);
export const usePaginationChangeContext = (): ((slice: ReadonlyArray<RowData>) => void) =>
  useContext(PaginationChangeContext);

interface Props {
  readonly children: ReactNode | ReactNode[];
}
const PaginationContextProvider: FC<Props> = ({ children }: Props) => {
  const [paginationSlice, setPaginationSlice] = useState<ReadonlyArray<RowData>>([]);

  return (
    <PaginationContext.Provider value={paginationSlice}>
      <PaginationChangeContext.Provider value={setPaginationSlice}>
        {children}
      </PaginationChangeContext.Provider>
    </PaginationContext.Provider>
  );
};

export default PaginationContextProvider;
