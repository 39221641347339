import { ReactNode } from 'react';

/**
 * Returns the given items as ReactNodes with highlighted elements and added whitespace between
 * each item.
 *
 * @param items The given text items.
 * @param highlight The items which should be highlighted. Only exact matches are highlighted.
 */
export const textWithHighlightedItems = (
  items: ReadonlyArray<string>,
  highlight?: ReadonlyArray<string>,
): NonNullable<ReactNode> =>
  items.map((item) => {
    if (highlight?.includes(item)) {
      return (
        <span key={item}>
          <mark>{item}</mark>{' '}
        </span>
      );
    }
    return `${item} `;
  });

/**
 * Returns the given text as ReactNodes with highlighted regions.
 *
 * @param text The given text.
 * @param highlight The items which should be highlighted. Only the first occurrence of each item
 * gets highlighted.
 */
export const textWithHighlightedRegions = (
  text: string,
  highlight?: ReadonlyArray<string>,
): NonNullable<ReactNode> => {
  const normalizedNewlines = text.replace(/\r\n|\n|\r/g, '\n');

  if (!highlight || highlight.length === 0) {
    return normalizedNewlines;
  }
  // Locations of all highlighted characters.
  const highlightLocations = new Set();

  // Highlighted regions as ranges (start, end).
  const highlightRegions: [number, number][] = [];

  const findMarkStart = (item: string) => {
    let start = normalizedNewlines.indexOf(item);
    while (start >= 0 && start in highlightLocations) {
      start = normalizedNewlines.indexOf(item, start + 1);
    }
    return start;
  };
  highlight.forEach((item) => {
    const start = findMarkStart(item);
    if (start < 0) {
      return;
    }
    const end = start + item.length;
    highlightRegions.push([start, end]);
    highlightLocations.add([...Array(item.length)].map((_, i) => start + i));
  });

  const elements: NonNullable<ReactNode>[] = [];

  // Sort [start, end] region tuples by length (end-start). Shortest is first.
  highlightRegions.sort((a, b) => {
    if (b[1] - b[0] < a[1] - a[0]) {
      return 1;
    }
    return -1;
  });

  // Throw out short region tuples that are completely included in longer tuples
  const regionsWithoutOverlappingHighlights = highlightRegions.filter(([start, end], ind, arr) => {
    const rest = arr.slice(ind + 1);
    return !rest.some(([otherStart, otherEnd]) => {
      return otherStart <= start && otherEnd >= end;
    });
  });

  // Sort start of [start, end] region tuples. Tuple with smallest start becomes first element
  regionsWithoutOverlappingHighlights.sort((a, b) => a[0] - b[0]);

  // Get text segments and highlight segments consecutively from text
  let lastEnd = 0;
  regionsWithoutOverlappingHighlights.forEach(([start, end], ind, arr) => {
    // Push text in front of highlight
    if (start - lastEnd > 0) {
      elements.push(normalizedNewlines.substr(lastEnd, start - lastEnd));
    }
    // Push highlight
    const mark = normalizedNewlines.substr(start, end - start);

    elements.push(<mark key={mark}>{mark}</mark>);

    // Check if there is text after the last highlight
    if (ind === arr.length - 1 && normalizedNewlines.length - end > 0) {
      elements.push(normalizedNewlines.substr(end, normalizedNewlines.length - end));
    }
    // Iterate
    lastEnd = end;
  });

  // If there are no matching highlights for text, just push text
  if (regionsWithoutOverlappingHighlights.length === 0) {
    elements.push(normalizedNewlines);
  }
  return elements;
};
