import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';

export interface TranscriptEditabilityPluginProps {
  readonly isEditable: boolean;
}

/**
 * A simple plugin to switch the editor state between editable and read-only
 *
 * The plugin reacts to changes of its `isEditable` prop and sets the editor edit mode accordingly.
 *
 * @param isEditable The editor is set to editable if this prop is true. Else it is set to read-only
 */
export const TranscriptEditabilityPlugin: React.FC<TranscriptEditabilityPluginProps> = ({
  isEditable,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(isEditable);
  }, [editor, isEditable]);

  return null;
};
