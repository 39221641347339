import { $dfs } from '@lexical/utils';
import { $getRoot } from 'lexical';
import _ from 'lodash';

import { Decimal, decimal } from './decimal';
import {
  $isTranscriptTimeBlockNode,
  TranscriptTimeBlockNode,
} from './nodes/TranscriptTimeBlockNode';
import { $isTranscriptUnitNode, TranscriptUnitNode } from './nodes/TranscriptUnitNode';

/**
 * The relevant data that constitutes a transcript unit as used by the editor.
 */
export interface TranscriptUnitData {
  /**
   * The unique ID of the transcript unit.
   */
  readonly id: number;
  /**
   * The word represented by this unit.
   */
  readonly unit: string;
  /**
   * The start time of the unit in seconds from the start.
   *
   * This is a string containing a floating-point number with tree decimal places.
   */
  readonly time: string;
  /**
   * The duration of the unit in seconds.
   *
   * This is a string containing a floating-point number with tree decimal places.
   */
  readonly duration: string;

  /**
   * The confidence value of the unit.
   *
   * This is a string containing a floating-point number with 2 decimal places.
   */
  readonly confidence: string;
}

/**
 * The relevant data that constitutes a transcript speaker as used by the editor.
 */
export interface TranscriptSpeakerData {
  /**
   * The unique ID of the transcript-speaker.
   */
  readonly id: number;
  /**
   * The name of the transcript speaker.
   */
  readonly name: string;
  /**
   * The start-time of the transcript speaker in seconds from the start.
   *
   * This is a string containing a floating-point number with tree decimal places.
   */
  readonly time: string;
  /**
   * The duration of the transcript speaker in seconds.
   *
   * This is a string containing a floating-point number with tree decimal places.
   */
  readonly duration: string;
}

/**
 * The difference between the transcript units of two transcripts.
 *
 * The difference is described as 3 lists. These lists describe the actions necessary to transform
 * the original transcript into the edited transcript.
 *
 * 1. A list of IDs of units to delete.
 * 2. A list of complete units that replace units in the original transcript according to their IDs.
 * 3. A list of new units to be added to the transcript. These do not have IDs assigned, yet.
 */
export interface TranscriptUnitDiff {
  /**
   * IDs of units to delete.
   */
  delete_units: TranscriptUnitData['id'][];
  /**
   * Units to update.
   */
  update_units: TranscriptUnitData[];
  /**
   * New units.
   */
  create_units: Omit<TranscriptUnitData, 'id'>[];
}

/**
 * The difference between the transcript speakers of two transcripts.
 *
 * The difference is described as 3 lists. These lists describe the actions necessary to transform
 * the original transcript into the edited transcript.
 *
 * 1. A list of IDs of speakers to delete.
 * 2. A list of complete speakers that replace speakers in the original transcript according to their IDs.
 * 3. A list of new speakers to be added to the transcript. These do not have IDs assigned, yet.
 */
export interface TranscriptSpeakerDiff {
  /**
   * IDs of speakers to delete.
   */
  delete_speakers: TranscriptSpeakerData['id'][];
  /**
   * Speakers to update.
   */
  update_speakers: TranscriptSpeakerData[];
  /**
   * Speakers to create.
   */
  create_speakers: Omit<TranscriptSpeakerData, 'id'>[];
}

/**
 * The difference between two transcripts.
 *
 * This combines the difference between the units and speakers of a transcript.
 */
export interface TranscriptDiff extends TranscriptUnitDiff, TranscriptSpeakerDiff {}

/**
 * Get the duration of a transcript unit  or speaker as numeric value.
 *
 * Parses the duration as float.
 *
 * @param unitOrSpeaker A TranscriptUnitData or TranscriptSpeakerData instance.
 */
export const getNumericDuration = (
  unitOrSpeaker: TranscriptUnitData | TranscriptSpeakerData,
): Decimal => decimal(unitOrSpeaker.duration);

/**
 * Get the (start-) time of a transcript unit or speaker as numeric value.
 *
 * Parses the time as float.

 * @param unitOrSpeaker  A TranscriptUnitData or TranscriptSpeakerData instance.
 */
export const getStartTime = (unitOrSpeaker: TranscriptUnitData | TranscriptSpeakerData): Decimal =>
  decimal(unitOrSpeaker.time);

/**
 * Get the confidence value of the transcript unit as float.
 *
 * @param unit A transcript unit resource.
 */
export const getNumericConfidence = (unit: TranscriptUnitData): Decimal => decimal(unit.confidence);

/**
 * Get the end time of a unit or speaker as numeric value.
 *
 * The end time is the start time plus the duration.
 *
 * @param unitOrSpeaker A TranscriptUnitData or TranscriptSpeakerData instance.
 */
export const getEndTime = (unitOrSpeaker: TranscriptUnitData | TranscriptSpeakerData): Decimal =>
  getStartTime(unitOrSpeaker).plus(getNumericDuration(unitOrSpeaker));

/**
 * Convert the data from a TranscriptUnitNode to the same format as the original transcript units.
 * @param transcriptUnitNode
 */
const toUnitData = (transcriptUnitNode: TranscriptUnitNode): TranscriptUnitData => {
  const id = transcriptUnitNode.getId();
  return {
    id: id === 'new' ? -1 : id,
    unit: transcriptUnitNode.getTextContent().trimStart(),
    time: transcriptUnitNode.getTime().toFixed(3),
    duration: transcriptUnitNode.getDuration().toFixed(3),
    confidence: transcriptUnitNode.getConfidence().toFixed(2),
  };
};

/**
 * The keys of transcript units used for comparison.
 */
const unitDataComparisonKeys: (keyof TranscriptUnitData)[] = [
  'unit',
  'time',
  'duration',
  'confidence',
];

/**
 * Calculate the difference between the original units and the current TranscriptUnitNodes.
 *
 * @param originalUnitData The units of the original transcript.
 * @param currentUnitNodes All TranscriptUnitNodes of the current editor state.
 * @returns The diff with respect to the transcript units.
 */
const calculateUnitDiff = (
  originalUnitData: readonly TranscriptUnitData[],
  currentUnitNodes: readonly TranscriptUnitNode[],
): TranscriptUnitDiff => {
  const originals = new Map(originalUnitData.map((unit) => [unit.id, unit]));
  const createUnits: TranscriptUnitDiff['create_units'] = [];
  const updateUnits: TranscriptUnitDiff['update_units'] = [];
  currentUnitNodes.forEach((unit) => {
    const id = unit.getId();
    const unitData = toUnitData(unit);
    if (id === 'new') {
      createUnits.push(_.pick(unitData, unitDataComparisonKeys));
    } else if (originals.has(id)) {
      const originalData = originals.get(id);
      if (
        !_.isEqual(
          _.pick(unitData, unitDataComparisonKeys),
          _.pick(originalData, unitDataComparisonKeys),
        )
      ) {
        updateUnits.push(unitData);
      }
      originals.delete(id);
    }
  });
  const deleteUnits: TranscriptUnitDiff['delete_units'] = Array.from(originals.keys());
  return {
    delete_units: deleteUnits,
    update_units: updateUnits,
    create_units: createUnits,
  };
};

/**
 * Convert a TranscriptTimeBlockNode to its corresponding TranscriptSpeakerData.
 *
 * @param timeBlockNode The TranscriptTimeBlockNode to convert.
 * @returns TranscriptSpeakerData with the relevant data about the speaker represented by the block.
 */
const toSpeakerData = (timeBlockNode: TranscriptTimeBlockNode): TranscriptSpeakerData => {
  const id = timeBlockNode.getSpeakerId();
  return {
    id: id === `new` ? -1 : id,
    name: timeBlockNode.getSpeakerName(),
    time: timeBlockNode.getStartTime().toFixed(3),
    duration: timeBlockNode.getDuration().toFixed(3),
  };
};

/**
 * The keys of the speaker to use to compare two speaker data instances while diffing.
 */
const speakerDataComparisonKeys: (keyof TranscriptSpeakerData)[] = ['name', 'time', 'duration'];

/**
 * Calculate the difference between the original speakers and the current TranscriptTimeBlockNodes.
 * @param originalSpeakerData The original speakers of the transcript.
 * @param currentTimeBockNodes The current TranscriptTimeBlockNode instances.
 * @returns The difference between the original and current speakers.
 */
const calculateSpeakerDiff = (
  originalSpeakerData: readonly TranscriptSpeakerData[],
  currentTimeBockNodes: readonly TranscriptTimeBlockNode[],
): TranscriptSpeakerDiff => {
  const originals = new Map(originalSpeakerData.map((speaker) => [speaker.id, speaker]));
  const createSpeakers: TranscriptSpeakerDiff['create_speakers'] = [];
  const updateSpeakers: TranscriptSpeakerDiff['update_speakers'] = [];
  currentTimeBockNodes.forEach((timeBlock) => {
    const id = timeBlock.getSpeakerId();
    const speakerData = toSpeakerData(timeBlock);
    if (id === 'new') {
      createSpeakers.push(_.pick(speakerData, speakerDataComparisonKeys));
    } else if (originals.has(id)) {
      const originalData = originals.get(id);
      if (
        !_.isEqual(
          _.pick(speakerData, speakerDataComparisonKeys),
          _.pick(originalData, speakerDataComparisonKeys),
        )
      ) {
        updateSpeakers.push(speakerData);
      }
      originals.delete(id);
    }
  });
  const deleteSpeakers: TranscriptSpeakerDiff['delete_speakers'] = Array.from(originals.keys());
  return {
    delete_speakers: deleteSpeakers,
    update_speakers: updateSpeakers,
    create_speakers: createSpeakers,
  };
};

/**
 * Calculates the difference between the original transcript and the current editor state.
 *
 * The original transcript is represented by a list of units and speakers.
 *
 * The current transcript is retrieved by the editor in the current lexical scope.
 *
 * @param units An array of TranscriptUnitData instances representing the units of the original transcript.
 * @param speakers An array of TranscriptSpeakerData instances representing the speakers of the original transcript
 */
export const $calculateTranscriptDiff = (
  units: readonly TranscriptUnitData[],
  speakers: readonly TranscriptSpeakerData[],
): TranscriptDiff => {
  const transcriptUnitNodes: TranscriptUnitNode[] = [];
  const timeBlockNodes: TranscriptTimeBlockNode[] = [];
  $dfs($getRoot()).forEach(({ node }) => {
    if ($isTranscriptUnitNode(node)) {
      transcriptUnitNodes.push(node);
    }
    if ($isTranscriptTimeBlockNode(node)) {
      timeBlockNodes.push(node);
    }
  });

  const diff: TranscriptDiff = {
    ...calculateUnitDiff(units, transcriptUnitNodes),
    ...calculateSpeakerDiff(speakers, timeBlockNodes),
  };

  return diff;
};

/**
 * Predicate to test if a TranscriptDiff represents an unmodified transcript.
 *
 * @param diff A TranscriptDiff to test.
 * @returns true if the difference is empty, false otherwise.
 */
export const isUnmodified = (diff: TranscriptDiff): boolean =>
  _.isEqual(diff.delete_units, []) &&
  _.isEqual(diff.update_units, []) &&
  _.isEqual(diff.create_units, []) &&
  _.isEqual(diff.delete_speakers, []) &&
  _.isEqual(diff.update_speakers, []) &&
  _.isEqual(diff.create_speakers, []);
