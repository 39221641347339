import { Paper, PaperProps } from '@mui/material';
import { FC } from 'react';

/**
 * Paper component with an outline
 *
 * This is mainly used as a helper component to use in `component` properties of Stack and Box.
 * This is necessary to prevent unnecessary rerendering
 * (see [unmounting caveat](https://mui.com/material-ui/guides/composition/#inlining-amp-caveat))
 *
 * @param props The additional props of the Paper component.
 * @constructor
 */
const OutlinedPaper: FC<PaperProps> = (props: PaperProps) => (
  <Paper {...props} variant="outlined" />
);

export default OutlinedPaper;
