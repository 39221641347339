import { useObservableEagerState } from 'observable-hooks';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider, useDrag } from 'react-dnd';
import { getEmptyImage, HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import { ShakaPlayerWithSliders, useVideoController } from 'medialoopster/components';
import { LANGUAGES } from 'medialoopster/constants';
import { getResourceURI } from 'medialoopster/rest';

import { buildDraggableFavoritesItem } from '../../../businessRules/models/FavoriteListsHelpers';
import { detailsSelectors } from '../../../state/modules/details';
import { favoritesSelectors } from '../../../state/modules/favorites';
import { playerSelectors } from '../../../state/modules/player';
import { VideoAsset } from '../../../state/modules/video/types';
import BoundingBoxes from '../BoundingBoxes';
import PersonsView from '../PersonsView';
import PlayerContainer from '../PlayerContainer';
import CurrentTimeDisplay from './CurrentTimeDisplay';
import ToolbarButtons from './ToolbarButtons';

type Props = {
  readonly asset: VideoAsset;
};

const VideoPlayer: FC<Props> = ({ asset }: Props) => {
  const subtitleCollection = useSelector(detailsSelectors.getEmbeddedVideoSubtitleCollection);
  const initialFrame = useSelector(playerSelectors.getInitialFrame);
  const inFrame = useSelector(playerSelectors.getInFrame);
  const outFrame = useSelector(playerSelectors.getOutFrame);

  const controllerRef = useRef<HTMLVideoElement>(null); // Note: do not acces controllerRef.
  const sliderThumbRef = useRef<HTMLBaseElement>(null); // Note: do not acces sliderThumbRef.

  const [selectedAudioTrack, selectAudioTrack] = useState<shaka.extern.Track | null>(null);
  const [selectedTextLanguage, selectTextLanguage] = useState<keyof typeof LANGUAGES | null>(null);
  const [showSubtitle, setShowSubtitle] = useState<boolean>(false);
  const videoController = useVideoController();

  const canPlay = useObservableEagerState(videoController.canPlay$);
  const fps = useObservableEagerState(videoController.fps$);

  const selectedAssetURL = getResourceURI(asset);
  const firstTextLanguage =
    subtitleCollection.items.length > 0 ? subtitleCollection.items[0].language : null;

  // Deselect audio track when asset has changed
  useEffect(() => {
    selectAudioTrack(null);
  }, [selectedAssetURL, selectAudioTrack]);

  // Select first subtitle when asset has changed
  useEffect(() => {
    selectTextLanguage(firstTextLanguage);
  }, [selectedAssetURL, selectTextLanguage, firstTextLanguage]);

  useEffect(() => {
    if (canPlay && videoController.getCurrentFrame() !== initialFrame) {
      videoController.seekFrame(initialFrame);
    }
  }, [initialFrame, videoController, canPlay]);

  const canDrag = useSelector(favoritesSelectors.isActive);
  const isPseudoshot = inFrame !== null && outFrame !== null;

  const [, drag, preview] = useDrag({
    type: isPseudoshot ? 'pseudoshot' : 'videoasset',
    item: buildDraggableFavoritesItem(
      asset.id,
      isPseudoshot ? 'pseudoshot' : 'videoasset',
      asset,
      asset.thumbnail_url,
      isPseudoshot && inFrame !== null ? inFrame : undefined,
      isPseudoshot && outFrame !== null ? outFrame : undefined,
    ),
  });

  const onDragStart = useCallback(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const currentInTime = useMemo(() => (inFrame ? inFrame / fps : 0), [inFrame, fps]);
  const currentOutTime = useMemo(() => (outFrame ? outFrame / fps : 0), [outFrame, fps]);
  const getCurrentTime = useCallback(() => {
    const currentTime = videoController.getCurrentTime();
    return currentTime;
  }, [videoController]);

  const isMpd = useMemo(() => asset.proxy_url.split('.').pop() === 'mpd', [asset]);
  return (
    <>
      <PlayerContainer
        player={
          <ShakaPlayerWithSliders
            assetFps={asset.fps ? parseFloat(asset.fps) : undefined}
            ref={controllerRef}
            dragRef={drag}
            src={asset.proxy_url}
            inTime={currentInTime}
            outTime={currentOutTime}
            sliderThumbRef={sliderThumbRef}
            selectedAudioTrack={selectedAudioTrack}
            selectedTextLanguage={selectedTextLanguage}
            showSubtitle={showSubtitle}
            subtitles={subtitleCollection.items}
            canDrag={canPlay && canDrag}
            assetFrames={asset.duration}
            poster={asset.poster_url}
          />
        }
        onDragStart={onDragStart}
        overlay={
          <>
            <div id="overlay-container" onDragStart={onDragStart}>
              <DndProvider backend={HTML5Backend}>
                <BoundingBoxes />
              </DndProvider>
            </div>
            <div id="persons-in-asset">
              <PersonsView />
            </div>
          </>
        }
        currentTimeDisplay={
          <CurrentTimeDisplay
            offsetFrames={asset.offset_frames}
            fps={fps}
            inFrame={inFrame}
            outFrame={outFrame}
          />
        }
        toolbarButtons={
          <ToolbarButtons
            isMpd={isMpd}
            fps={fps}
            selectedAssetURL={selectedAssetURL}
            duration={asset.duration}
            inFrame={inFrame}
            outFrame={outFrame}
            selectTextLanguage={selectTextLanguage}
            selectedTextLanguage={selectedTextLanguage}
            setShowSubtitle={setShowSubtitle}
            showSubtitle={showSubtitle}
            selectAudioTrack={selectAudioTrack}
            selectedAudioTrack={selectedAudioTrack}
          />
        }
        getCurrentTime={getCurrentTime}
      />
    </>
  );
};

export default VideoPlayer;
