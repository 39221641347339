import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ngettext, interpolate, gettext } from 'medialoopster/Internationalization';
import { Close } from 'medialoopster/icons';

import { licensingActions, licensingSelectors } from '../../state/modules/licensing';
import { usersSelectors } from '../../state/modules/users';
import { isLicenseAboutToExpire, getDaysBeforeExpire } from './services/licenseExpire';

const useStyles = makeStyles((theme) => ({
  filledError: {
    borderRadius: '0',
  },
  message: {
    fontSize: '1rem',
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(1, 0),
  },
  button: {
    padding: theme.spacing(1, 0),
    '& .MuiSvgIcon-root': {
      fontSize: '1.1rem',
    },
  },
  link: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
    marginLeft: theme.spacing(4),
  },
}));

type LicenseMessageProps = {
  readonly visible: boolean;
  readonly message: string;
  readonly onClose: () => void;
};

const LicenseMessage: FC<LicenseMessageProps> = ({ visible, message, onClose }) => {
  const classes = useStyles();
  return (
    <Collapse in={visible}>
      <Alert
        classes={{
          filledError: classes.filledError,
          message: classes.message,
        }}
        severity="error"
        icon={false}
        variant="filled"
        action={
          <IconButton
            aria-label={gettext('Close')}
            classes={{ root: classes.button }}
            color="inherit"
            onClick={() => {
              onClose();
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
        <Link classes={{ root: classes.link }} href="/license_management">
          <b>{gettext('Update license')}</b>
        </Link>
      </Alert>
    </Collapse>
  );
};

const LicenseNotification: FC = () => {
  const dispatch = useDispatch();
  const isSuperUser = useSelector(usersSelectors.isSuperUser);
  const expiryDate = useSelector(licensingSelectors.getExpiryDate);
  const isSiteLimitExceeded = useSelector(licensingSelectors.isSiteLimitExceeded);
  const [showAboutToExpire, setShowAboutToExpire] = useState(false);
  const [showSiteLimitExceeded, setShowSiteLimitExceeded] = useState(false);
  const [daysToExpire, setDaysToExpire] = useState(NaN);

  useEffect(() => {
    dispatch(licensingActions.fetchLicense());
  }, [dispatch]);

  useEffect(() => {
    setShowAboutToExpire(isLicenseAboutToExpire(expiryDate));
    setDaysToExpire(getDaysBeforeExpire(expiryDate));
  }, [expiryDate]);

  useEffect(() => {
    setShowSiteLimitExceeded(isSiteLimitExceeded);
  }, [isSiteLimitExceeded]);

  const getExpirationMessage = (days: number) => {
    if (days >= 0) {
      return interpolate(
        ngettext(
          'The current medialoopster license will expire in %(days)s day.',
          'The current medialoopster license will expire in %(days)s days.',
          days,
        ),
        { days },
      );
    }
    return gettext('The current medialoopster license has expired.');
  };
  return (
    <div id="license-notification">
      <LicenseMessage
        visible={showAboutToExpire && isSuperUser}
        message={getExpirationMessage(daysToExpire)}
        onClose={() => setShowAboutToExpire(false)}
      />
      <LicenseMessage
        visible={showSiteLimitExceeded}
        message={gettext('The limit of allowed sites has been exceeded.')}
        onClose={() => setShowSiteLimitExceeded(false)}
      />
    </div>
  );
};

export default LicenseNotification;
