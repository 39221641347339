import { combineReducers } from 'redux';

import { createResourcesReducer, createRootLinkReducer, mlRel } from 'medialoopster/rest';

import { SetCurrentAsset, SET_CURRENT_ASSET } from '../details/types';
import {
  DownloadRootState,
  SetCanSeeCurrentAssetProxyDownload,
  SET_CAN_SEE_CURRENT_ASSET_PROXY_DOWNLOAD,
  CurrentAssetProxyDownloadState,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_READY,
  SetCurrentAssetProxyDownloadErrors,
  SetCurrentAssetProxyDownloadReady,
  SetCurrentAssetProxyDownloadPreparing,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_ERRORS,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING,
  SetCurrentAssetProxyDownloadOpen,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_OPEN,
  UpdateCurrentAssetProxyDownloadActivity,
  UPDATE_CURRENT_ASSET_PROXY_DOWNLOAD_ACTIVITY,
  SetCurrentAssetProxyDownloadDryRunErrors,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_DRY_RUN_ERRORS,
} from './types';

export const initialCurrentAssetProxyDownloadState: CurrentAssetProxyDownloadState = {
  canSeeCurrenAssetProxyDownload: false,
  preparingDownload: false,
  downloadReady: false,
  errors: [],
  dryRunErrors: [],
  isOpen: false,
  currentActivity: undefined,
};
export const currentAssetProxyDownloadReducer = (
  state = initialCurrentAssetProxyDownloadState,
  action:
    | SetCurrentAsset
    | SetCanSeeCurrentAssetProxyDownload
    | SetCurrentAssetProxyDownloadReady
    | SetCurrentAssetProxyDownloadErrors
    | SetCurrentAssetProxyDownloadPreparing
    | SetCurrentAssetProxyDownloadOpen
    | UpdateCurrentAssetProxyDownloadActivity
    | SetCurrentAssetProxyDownloadDryRunErrors,
): CurrentAssetProxyDownloadState => {
  switch (action.type) {
    case SET_CURRENT_ASSET:
      return initialCurrentAssetProxyDownloadState;
    case SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING:
      return { ...state, downloadReady: false, preparingDownload: true };
    case SET_CURRENT_ASSET_PROXY_DOWNLOAD_READY:
      return { ...state, downloadReady: true, preparingDownload: false };
    case SET_CAN_SEE_CURRENT_ASSET_PROXY_DOWNLOAD:
      return {
        ...state,
        canSeeCurrenAssetProxyDownload: action.payload.canSeeCurrenAssetProxyDownload,
      };
    case SET_CURRENT_ASSET_PROXY_DOWNLOAD_OPEN: {
      if (!action.payload.isOpen) {
        return {
          ...initialCurrentAssetProxyDownloadState,
          canSeeCurrenAssetProxyDownload: state.canSeeCurrenAssetProxyDownload,
        };
      }
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    }
    case UPDATE_CURRENT_ASSET_PROXY_DOWNLOAD_ACTIVITY:
      return {
        ...state,
        currentActivity: action.payload.activity,
      };
    case SET_CURRENT_ASSET_PROXY_DOWNLOAD_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
        preparingDownload: false,
        downloadReady: false,
      };
    case SET_CURRENT_ASSET_PROXY_DOWNLOAD_DRY_RUN_ERRORS:
      return {
        ...state,
        dryRunErrors: action.payload.errors,
      };
    default:
      return state;
  }
};

export default combineReducers<DownloadRootState>({
  proxyDownloadActivitiesLink: createRootLinkReducer(mlRel('proxydownloadactivities')),
  proxyDownloadActivityCollections: createResourcesReducer('proxydownloadactivity-collection'),
  highresDownloadActivitiesLink: createRootLinkReducer(mlRel('highresdownloadactivities')),
  highresDownloadActivityCollections: createResourcesReducer('highresdownloadactivity-collection'),
  assetProxyDownloadActivitiesLink: createRootLinkReducer(mlRel('assetproxydownloadactivities')),
  currentAssetDownload: currentAssetProxyDownloadReducer,
});
