import { saveAs } from 'file-saver';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AlertFeedback, alertMessage, AlertMessage } from 'medialoopster/AlertMessage';
import { gettext } from 'medialoopster/Internationalization';
import {
  getErrorAlertMessage,
  unauthorizedRequestAction,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { DOWNLOAD_FROM_URL, DownloadFromURL } from './types';

export const downloadFromURLEpic: Epic<Action, AlertMessage | UnauthorizedRequestAction> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<Action, DownloadFromURL['type'], DownloadFromURL>(DOWNLOAD_FROM_URL),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { url, headers, fileCreateError, requestError },
        },
        state,
      ]) =>
        ajax<Blob>({
          method: 'GET',
          url,
          responseType: 'blob',
          headers: {
            ...headers,
            ...getTokenAuthHeader(loginSelectors.getToken(state)),
          },
        }).pipe(
          mergeMap((response) => {
            const contentDisposition = response.responseHeaders['content-disposition'];
            const fileName = contentDisposition?.split('filename=')[1].replace(/"|\r/g, '');
            if (fileName !== undefined) {
              saveAs(
                response.response,
                contentDisposition.split('filename=')[1].replace(/"|\r/g, ''),
              );
              return EMPTY;
            }
            return of(alertMessage(fileCreateError, AlertFeedback.Error));
          }),
          catchError((err) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction()); // TODO: Test in ML-3735
            }
            return of(
              alertMessage(
                `${requestError}: ${getErrorAlertMessage(err, gettext('Unknown error.'))}`,
                AlertFeedback.Error,
              ),
            );
          }),
        ),
    ),
  );

export default combineEpics<Action, AlertMessage | UnauthorizedRequestAction>(downloadFromURLEpic);
