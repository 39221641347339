import { alpha } from '@mui/material';
import MUITableRow, { TableRowProps as MUITableRowProps } from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo, ReactNode } from 'react';

export interface TableRowProps {
  readonly disabled?: boolean;
  readonly children: ReactNode | ReactNode[];
  readonly color?: 'info' | 'success' | 'error' | 'warning';
  readonly className?: string;
  readonly onClick?: MUITableRowProps['onClick'];
  readonly selected?: MUITableRowProps['selected'];
}

const useStyles = makeStyles((theme) => ({
  info: {
    backgroundColor: alpha(theme.palette.info.light, 0.25),
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.info.light, 0.5)} !important`,
    },
  },
  success: {
    backgroundColor: alpha(theme.palette.success.light, 0.25),
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.success.light, 0.5)} !important`,
    },
  },
  warning: {
    backgroundColor: alpha(theme.palette.warning.light, 0.25),
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.warning.light, 0.5)} !important`,
    },
  },
  error: {
    backgroundColor: alpha(theme.palette.error.light, 0.25),
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.error.light, 0.5)} !important`,
    },
  },
}));

const TableRow: FC<TableRowProps> = ({
  disabled = false,
  children,
  color = undefined,
  className = undefined,
  onClick = undefined,
  selected = undefined,
}: TableRowProps) => {
  const classes = useStyles();
  return (
    <MUITableRow
      hover={!disabled}
      onClick={onClick}
      tabIndex={-1}
      selected={selected}
      className={clsx(color && classes[color], className)}
    >
      {children}
    </MUITableRow>
  );
};

export default memo(TableRow);
