import { FC, memo } from 'react';

import { useCheckboxTableRowContext } from '../CheckboxTableContextProvider';
import CheckboxCell from '../cells/CheckboxCell';
import TableRow, { TableRowProps } from './TableRow';

interface CheckboxTableRowProps {
  readonly rowIdentifier: string;
  readonly disabled?: boolean;
  readonly defaultChecked?: boolean;
  readonly color?: TableRowProps['color'];
  readonly children?: TableRowProps['children'];
}

const CheckboxTableRow: FC<CheckboxTableRowProps> = ({
  rowIdentifier,
  disabled = false,
  defaultChecked = false,
  color = undefined,
  children,
}: CheckboxTableRowProps) => {
  const { handleClick, isSelected } = useCheckboxTableRowContext();
  const handleRowClick = (e: React.MouseEvent) => {
    if (!disabled) {
      if (e.shiftKey) window.getSelection()?.removeAllRanges();
      handleClick(rowIdentifier, e.shiftKey);
    }
  };
  return (
    <TableRow
      disabled={disabled}
      onClick={handleRowClick}
      selected={!disabled && isSelected(rowIdentifier)}
      color={color}
    >
      <CheckboxCell
        checked={disabled ? defaultChecked : isSelected(rowIdentifier)}
        disabled={disabled}
      />
      {children}
    </TableRow>
  );
};

export default memo(CheckboxTableRow);
