import _ from 'lodash';
import { MouseEvent } from 'react';

import SearchQueryService, {
  SearchQuery,
} from '../../../sections/Header/services/SearchQueryService';
import { AssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';
import { CurrentAssetState } from '../details/types';
import {
  CLICK_ENTRY,
  ClickEntry,
  DESELECT_ALL_ENTRIES,
  DeselectAllEntries,
  FETCH_NEXT_SEARCH_RESULT,
  FETCH_SEARCH_RESULT,
  FetchNextSearchResult,
  FetchSearchResult,
  LOAD_NEXT_SHOTS,
  LOAD_SHOTS,
  LoadNextShots,
  LoadShots,
  MULTI_SELECT_ENTRY,
  MultiSelectEntry,
  RANGE_SELECT_ENTRIES,
  RangeSelectEntries,
  SEARCH_LOADED,
  SearchLoaded,
  SELECT_ENTRY,
  SelectEntry,
} from './types';

export const clickEntry = (
  assetId: number,
  assetTypeName: AssetType,
  assetHref: string,
  event: MouseEvent,
): ClickEntry => ({
  type: CLICK_ENTRY,
  payload: {
    assetId,
    assetTypeName,
    assetHref,
    withShiftKey: event.shiftKey,
    withAltKey: event.altKey,
    withCtrlKey: event.ctrlKey,
    withMetaKey: event.metaKey,
  },
});

export const selectEntry = (
  assetId: number,
  assetTypeName: AssetType,
  assetHref: string,
): SelectEntry => ({
  type: SELECT_ENTRY,
  payload: {
    assetId,
    assetTypeName,
    assetHref,
  },
});

export const multiSelectEntry = (
  assetId: number,
  assetTypeName: AssetType,
  assetHref: string,
  currentAssetState: CurrentAssetState,
  entries: ReadonlyArray<Asset>,
): MultiSelectEntry => ({
  type: MULTI_SELECT_ENTRY,
  payload: {
    assetId,
    assetTypeName,
    assetHref,
    currentAssetState,
    entries,
  },
});

export const rangeSelectEntries = (
  assetId: number,
  assetTypeName: AssetType,
  assetHref: string,
  currentAssetState: CurrentAssetState,
  entries: ReadonlyArray<Asset>,
): RangeSelectEntries => ({
  type: RANGE_SELECT_ENTRIES,
  payload: {
    assetId,
    assetTypeName,
    assetHref,
    currentAssetState,
    entries,
  },
});

export const deselectAllEntries = (): DeselectAllEntries => ({
  type: DESELECT_ALL_ENTRIES,
});

export const loadShots = (url: string): LoadShots => ({
  type: LOAD_SHOTS,
  payload: { url },
});

export const loadNextShots = (url: string): LoadNextShots => ({
  type: LOAD_NEXT_SHOTS,
  payload: { url },
});

const createUrl = (assetTypeName: AssetType, searchString: string): string => {
  const urlMap = {
    audioasset: '/api/search/audioassets/',
    imageasset: '/api/search/imageassets/',
    videoasset: '/api/search/videoassets/',
    collection: '/api/search/collections/',
  };
  const url = urlMap[assetTypeName];
  if (searchString.length === 0) {
    return url;
  }

  return `${url}?${searchString}`;
};

export const fetchSearchResult = (
  hideHighlights = false,
  searchOptions: SearchQuery = {},
): FetchSearchResult => {
  const query: SearchQuery = _.omit(
    {
      production_filter: 0,
      device_filter: [],
      so_primary_search: '',
      ...searchOptions,
    },
    'search_category',
  );
  const assetTypeName = (searchOptions.search_category || 'videoasset') as AssetType;
  const search = SearchQueryService.fromQuery(query);
  return {
    type: FETCH_SEARCH_RESULT,
    payload: {
      assetTypeName,
      query,
      url: createUrl(assetTypeName, search),
      hideHighlights,
    },
  };
};

export const fetchNextSearchResult = (url: string): FetchNextSearchResult => ({
  type: FETCH_NEXT_SEARCH_RESULT,
  payload: { url },
});

export const searchLoaded = (): SearchLoaded => ({
  type: SEARCH_LOADED,
});
