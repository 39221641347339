import {
  Link,
  Resource,
  ResourceLinks,
  ResourceModuleState,
  ResourceOptionsV3,
} from 'medialoopster/rest';

interface TranscriptLinks extends ResourceLinks<'transcript'> {
  readonly speakers: Link;
  readonly units: Link;
  readonly edit: Link;
}

/**
 * The shape of the transcript resource (as needed by the frontend)
 */
export interface Transcript extends Resource<'transcript', TranscriptLinks> {
  /**
   * The id of the transcript.
   */
  readonly id: number;
  /**
   * The language of the transcript.
   */
  readonly language: string;
  /**
   * The engine the transcript was created by.
   */
  readonly engine: string;
  /**
   * The creation time of the transcript.
   */
  readonly created_at: string;
}

/**
 * The state of the transcript unit rest duck.
 */
export type TranscriptRootState = ResourceModuleState<Transcript, ResourceOptionsV3>;

/**
 * Get the url for the related collection of transcript units of the transcript.
 * @param transcript A transcript resource.
 */
export const getUnitsUrl = (transcript: Transcript): string => {
  // eslint-disable-next-line no-underscore-dangle
  return transcript._links.units.href;
};

/**
 * Get the url for the related collection of transcript speakers of the transcript.
 * @param transcript A transcript resource.
 */
export const getSpeakersUrl = (transcript: Transcript): string => {
  // eslint-disable-next-line no-underscore-dangle
  return transcript._links.speakers.href;
};
