import { OutlinedInputProps } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ChangeEvent, ReactNode, Ref, forwardRef } from 'react';

export const useInputStyles = makeStyles(() => ({
  inputDense: {
    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      padding: '1px',
      '& .MuiInputBase-inputSizeSmall:not([class*=MuiSelect-select])': {
        padding: '1px 7px',
      },
      '& .MuiInputBase-inputSizeSmall.MuiSelect-select': {
        padding: '0px 14px 0px 7px',
      },
    },
    '& .MuiInputBase-input': {
      height: '20px',
      fontSize: '0.7rem',
    },
    '& .MuiOutlinedInput-notchedOutline>legend': {
      marginTop: '-1px',
      marginBottom: '16px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-sizeSmall:not([class*=MuiInputLabel-shrink])': {
      transform: 'translate(7px, 5px) scale(1)',
    },
    '& .MuiOutlinedInput-root.Mui-focused > fieldset': {
      borderWidth: '1px',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
}));

interface BasicProps {
  id?: string;
  name?: string;
  label?: ReactNode;
  placeholder?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  title?: string;
  /**
   * Set `value` if the component is supposed to be controlled via state.
   */
  value?: string;
  /**
   * Set `inputRef` if the component is supposed to be controlled via ref.
   */
  inputRef?: Ref<HTMLInputElement>;
  /**
   * If `true`, the outline is rendered in red and `errorMsg` is displayed.
   */
  showError?: boolean;
  /**
   * The error message, will be displayed if `showError` is `true`.
   */
  errorMsg?: ReactNode;
  /**
   * Maximum length (number of characters) of the input value.
   */
  maxLength?: number;
  /**
   * If `true`, the custom medialoopster dense styling is applied.
   */
  dense?: boolean;
  /**
   * If `true`, the custom medialoopster small styling is applied (input height=32 ^= MUI buttons small).
   * But dense styling has precedence over small, if accidentally mixed
   */
  small?: boolean;
  onChange?: (value: string, event: ChangeEvent<{ value: string }>) => void;
  onFocus?: () => void;
  onBlur?: TextFieldProps['onBlur'];
  /**
   * Props applied to the `OutlinedInput` element.
   */
  InputProps?: Partial<OutlinedInputProps>;
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputElementProps?: OutlinedInputProps['inputProps'];
  required?: OutlinedInputProps['required'];
  multiline?: OutlinedInputProps['multiline'];
  type?: string;
  autoComplete?: string;
}

export interface InputElementProps extends BasicProps {
  onKeyUp?: TextFieldProps['onKeyUp'];
  onKeyDown?: TextFieldProps['onKeyDown'];

  // Select only props
  select: false;
  defaultValue: undefined;
  children: undefined;
}

export interface SelectElementProps extends BasicProps {
  select: true;
  defaultValue: number | string;
  children: ReactNode;
  onKeyUp?: TextFieldProps['onKeyUp'];
  onKeyDown?: TextFieldProps['onKeyDown'];
}

type Props = InputElementProps | SelectElementProps;

const InternalTextField = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      name,
      label = '',
      placeholder = '',
      title = undefined,
      readOnly = false,
      autoFocus = false,
      value = undefined,
      inputRef = undefined,
      showError = false,
      errorMsg = '',
      maxLength = 524288,
      onChange = undefined,
      onFocus = undefined,
      onKeyDown = undefined,
      onBlur = undefined,
      onKeyUp = undefined,
      dense = false,
      InputProps = undefined,
      inputElementProps = undefined,
      select = false,
      defaultValue = undefined,
      children = undefined,
      multiline = undefined,
      required,
      type = undefined,
      autoComplete = undefined,
    }: Props,
    ref,
  ) => {
    const classes = useInputStyles();

    const handleChange = (event: ChangeEvent<{ value: string }>) => {
      onChange?.(event.target.value, event);
    };

    return (
      <TextField
        ref={ref}
        InputProps={InputProps}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        inputProps={{
          ...inputElementProps,
          maxLength,
        }}
        title={title}
        id={id}
        name={name}
        label={label}
        value={value}
        inputRef={inputRef}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={readOnly}
        error={showError}
        helperText={showError ? errorMsg : null}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        select={select}
        defaultValue={defaultValue}
        required={required}
        /* default styling properties */
        fullWidth
        variant="outlined"
        size="small"
        classes={{
          root: clsx({
            [classes.inputDense]: dense,
          }),
        }}
        FormHelperTextProps={{ role: 'alert' }}
        multiline={multiline}
        data-testid={name}
        type={type}
        autoComplete={autoComplete}
      >
        {children}
      </TextField>
    );
  },
);

export default InternalTextField;
