import pointer from 'json-pointer';
import _ from 'lodash';
import { stringifySearchDict } from 'medialoopster';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { concat, EMPTY, merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { AlertFeedback, AlertMessage, alertMessage } from 'medialoopster/AlertMessage';
import { gettext, interpolate } from 'medialoopster/Internationalization';
import {
  APIFieldError,
  createResourcesLoadedAction,
  EmbeddedItemsPageResource,
  getEmbeddedItems,
  getErrorAlertMessage,
  getLinkHref,
  getResourceURI,
  isAPIFieldError,
  LinkedItemsPageResource,
  mlRel,
  ResourceLoadedAction,
  ResourceMap,
  ResourceOptionsV2,
  ResourceRemovedAction,
  RESTEpicDependencies,
  RESTError,
  UnauthorizedRequestAction,
  unauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { getPreviousListId } from '../../../businessRules/models/FavoriteListsHelpers';
import { URL_FAVORITE_LISTS } from '../../constants';
import { RootState } from '../../types/rootState';
import { openShareAssetsModal } from '../assetSharing/actions';
import { OpenShareAssetsModal } from '../assetSharing/types';
import { RenderActivity } from '../rest/renderactivities/types';
import { Shot } from '../video/types';
import {
  addAssetsToFavorites,
  addVideoClipToFavorites,
  favoriteListsOptionsLoaded,
  hideFavorites,
  selectFavoriteList,
  setListAssetsLoading,
  showFavorites,
  updatePermissions,
} from './actions';
import {
  getSelectedList,
  getSelectedListAssets,
  getSelectedListId,
  getVisibleListsForUser,
} from './selectors';
import {
  ADD_ASSET_TO_FAVORITES,
  ADD_ASSETS_TO_FAVORITES,
  ADD_SEQUENCE_TO_FAVORITES,
  ADD_SHOT_TO_FAVORITES,
  ADD_VIDEO_CLIP_TO_FAVORITES,
  AddAssetsToFavorites,
  AddAssetToFavorites,
  AddSequenceToFavorites,
  AddShotToFavorites,
  AddVideoClipToFavorites,
  CLEAR_FAVORITE_LIST,
  ClearFavoriteList,
  CREATE_FAVORITE_LIST,
  CreateFavoriteList,
  DELETE_FAVORITE_ITEM,
  DELETE_FAVORITE_LIST,
  DeleteFavoriteItem,
  DeleteFavoriteList,
  EDIT_FAVORITE_LIST,
  EditFavoriteList,
  EXPORT_TO_RENDER_ENGINE,
  ExportToRenderEngine,
  FavoriteListsOptionsLoaded,
  FavoritesItemAssetType,
  FavoritesItemPostResource,
  FavoritesItemResource,
  FavoritesListPostResource,
  FavoritesListResource,
  FETCH_FAVORITE_LISTS,
  FETCH_FAVORITE_LISTS_OPTIONS,
  FETCH_PERMISSION,
  FETCH_SELECTED_LIST_ITEMS,
  FetchFavoriteLists,
  FetchPermission,
  FetchSelectedListItems,
  HideFavorites,
  MOVE_FAVORITE_ITEM,
  MoveFavoriteItem,
  SelectFavoriteList,
  SetListAssetsLoading,
  SHARE_LIST_CONTENTS,
  ShowFavorites,
  TOGGLE_FAVORITES,
  UpdatePermissions,
} from './types';

export const toggleFavoritesEpic: Epic<Action, HideFavorites | ShowFavorites> = (action$, state$) =>
  action$.pipe(
    ofType(TOGGLE_FAVORITES),
    withLatestFrom(state$),
    map(([, state]) => {
      const { isActive } = state.favorites;
      return isActive ? hideFavorites() : showFavorites();
    }),
  );

export const favoriteListSelectedEpic: Epic<
  Action,
  ResourceLoadedAction | FetchSelectedListItems | SetListAssetsLoading | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (_action$, state$, { fetchCollection }) =>
  state$.pipe(
    map((state) => {
      const list = getSelectedList(state);
      if (!list) {
        return null;
      }
      return {
        itemsHref: getLinkHref(list, mlRel('favoriteitems')),
        videoAssetsHref: getLinkHref(list, mlRel('videoassets')),
        imageAssetsHref: getLinkHref(list, mlRel('imageassets')),
        audioAssetsHref: getLinkHref(list, mlRel('audioassets')),
      };
    }),
    distinctUntilChanged(_.isEqual),
    switchMap((list) => {
      if (!list) {
        return EMPTY;
      }
      return concat(
        of(setListAssetsLoading(true)),
        merge(
          list.itemsHref ? fetchCollection(list.itemsHref) : EMPTY,
          list.videoAssetsHref ? fetchCollection(list.videoAssetsHref) : EMPTY,
          list.imageAssetsHref ? fetchCollection(list.imageAssetsHref) : EMPTY,
          list.audioAssetsHref ? fetchCollection(list.audioAssetsHref) : EMPTY,
        ),
        of(setListAssetsLoading(false)),
      );
    }),
  );

export const clearFavoriteListEpic: Epic<
  Action,
  ResourceRemovedAction | ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource, fetchCollection }) =>
  action$.pipe(
    ofType<Action, ClearFavoriteList['type'], ClearFavoriteList>(CLEAR_FAVORITE_LIST),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const selectedList = getSelectedList(state);
      if (!selectedList) {
        return EMPTY;
      }
      const clearHref = getLinkHref(selectedList, mlRel('clear'));
      if (!clearHref) {
        return EMPTY;
      }
      return postResource(
        clearHref,
        null,
        () => {
          const itemsHref = getLinkHref(selectedList, mlRel('favoriteitems'));
          if (!itemsHref) {
            return EMPTY;
          }
          // TODO: Remove all pages and items. (Not really necessary for display, but keeps state clean.)
          return fetchCollection(itemsHref);
        },
        () => of(alertMessage(gettext('Error while clearing favorites item list.'))),
      );
    }),
  );

export const fetchFavoriteListsEpic: Epic<
  Action,
  ResourceLoadedAction | SelectFavoriteList | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, FetchFavoriteLists['type'], FetchFavoriteLists>(FETCH_FAVORITE_LISTS),
    switchMap(() => fetchCollection(URL_FAVORITE_LISTS)),
  );

export const fetchSelectedListItemsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, FetchSelectedListItems['type'], FetchSelectedListItems>(
      FETCH_SELECTED_LIST_ITEMS,
    ),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const selectedList = getSelectedList(state);
      if (!selectedList) {
        return EMPTY;
      }
      const itemsHref = getLinkHref(selectedList, mlRel('favoriteitems'));
      if (!itemsHref) {
        return EMPTY;
      }
      return fetchCollection(itemsHref);
    }),
  );

export const fetchFavoriteListsOptionsEpic: Epic<
  Action,
  FavoriteListsOptionsLoaded | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_FAVORITE_LISTS_OPTIONS),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      ajax<ResourceOptionsV2>({
        method: 'OPTIONS',
        url: URL_FAVORITE_LISTS,
        headers: getTokenAuthHeader(loginSelectors.getToken(state)),
      }).pipe(
        map((response) =>
          favoriteListsOptionsLoaded(
            response.response.actions || {},
            response.xhr.getResponseHeader('Allow') || '',
          ),
        ),
        catchError((err) => {
          if (err && err.status === 401) {
            return of(unauthorizedRequestAction());
          }
          return EMPTY;
        }),
      ),
    ),
  );

export const exportToRenderEngineEpic: Epic<Action, AlertMessage | UnauthorizedRequestAction> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<Action, ExportToRenderEngine['type'], ExportToRenderEngine>(EXPORT_TO_RENDER_ENGINE),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { exportChoice, favoriteXMLDownloadURI, baseXMLFileName },
        },
        state,
      ]) =>
        ajax<Blob>({
          method: 'GET',
          url: `${favoriteXMLDownloadURI}?${stringifySearchDict({
            san_mount: exportChoice.san_mount,
          })}`,
          responseType: 'blob',
          headers: {
            ...getTokenAuthHeader(loginSelectors.getToken(state)),
            Accept: 'text/xml; version=3',
          },
        }).pipe(
          mergeMap((resp) => {
            const uploadURL = getLinkHref(exportChoice, 'renderactivities');
            if (!uploadURL) {
              return of(
                alertMessage(
                  `${gettext('Site export failed')}: ${gettext('Missing export URL.')}`,
                  AlertFeedback.Error,
                ),
              );
            }
            const formData = new FormData();
            formData.append('project_sequence_file', resp.response, `${baseXMLFileName}.xml`);
            return ajax<RenderActivity>({
              method: 'POST',
              url: uploadURL,
              body: formData,
              headers: {
                ...getTokenAuthHeader(loginSelectors.getToken(state)),
                Accept: 'application/hal+json; version=3',
              },
            }).pipe(
              map((response) => {
                return alertMessage(
                  interpolate(gettext('Sucessfully created site export for %(name)s'), {
                    name: `${response.response.id}_${exportChoice.name}.${exportChoice.accepted_file_type}`,
                  }),
                  AlertFeedback.Success,
                );
              }),
              catchError((err) => {
                if (err && err.status === 401) {
                  return of(unauthorizedRequestAction());
                }
                return of(
                  alertMessage(
                    `${gettext('Site export failed')}: ${getErrorAlertMessage(
                      err,
                      gettext('Unknown error.'),
                    )}`,
                    AlertFeedback.Error,
                  ),
                );
              }),
            );
          }),
          catchError((err) => {
            return of(
              alertMessage(
                `${gettext('Site export failed')}: ${getErrorAlertMessage(
                  err,
                  gettext('Unknown error.'),
                )}`,
                AlertFeedback.Error,
              ),
            );
          }),
        ),
    ),
  );

export const moveFavoriteItemEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { moveItem }) =>
  action$.pipe(
    ofType<Action, MoveFavoriteItem['type'], MoveFavoriteItem>(MOVE_FAVORITE_ITEM),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { favoriteItemId, index },
        },
        state,
      ]) => {
        const selectedList = getSelectedList(state);
        if (!selectedList) {
          return EMPTY;
        }
        const itemsHref = getLinkHref(selectedList, mlRel('favoriteitems'));
        if (!itemsHref) {
          return EMPTY;
        }
        const { move$, undoMove$ } = moveItem(
          itemsHref,
          state.favorites.favoriteItemCollections,
          state.favorites.favoriteItems,
          `/api/favoriteitems/${favoriteItemId}/`,
          index,
        );
        return concat(
          move$,
          ajax
            .post(
              `/api/favoriteitems/${favoriteItemId}/move/`,
              { index },
              {
                'Content-Type': 'application/json',
                ...getTokenAuthHeader(loginSelectors.getToken(state)),
              },
            )
            .pipe(
              mergeMap(() => EMPTY),
              catchError((err) => {
                if (err && err.status === 401) {
                  return concat(undoMove$, of(unauthorizedRequestAction())); // TODO: Test in ML-3735
                }
                return undoMove$;
              }),
            ),
        );
      },
    ),
  );

export const deleteFavoriteItemEpic: Epic<
  Action,
  ResourceRemovedAction | ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { deleteResource }) =>
  action$.pipe(
    ofType<Action, DeleteFavoriteItem['type'], DeleteFavoriteItem>(DELETE_FAVORITE_ITEM),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { favoriteItemId },
        },
        state,
      ]) => {
        const favoriteItem = Object.values(state.favorites.favoriteItems.resources).find(
          (item) => item.id === favoriteItemId,
        );
        if (!favoriteItem) {
          return EMPTY;
        }
        return deleteResource(favoriteItem);
      },
    ),
  );

export const fetchPermissionEpic: Epic<Action, UpdatePermissions | UnauthorizedRequestAction> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<Action, FetchPermission['type'], FetchPermission>(FETCH_PERMISSION),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { url },
        },
        state,
      ]) =>
        ajax({
          method: 'GET',
          url,
          headers: getTokenAuthHeader(loginSelectors.getToken(state)),
        }).pipe(
          map(() => updatePermissions(url, true)),
          catchError((err) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            return of(updatePermissions(url, false));
          }),
        ),
    ),
  );

export const createFavoriteListEpic: Epic<
  Action,
  ResourceLoadedAction | SelectFavoriteList | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { postResource }) =>
  action$.pipe(
    ofType<Action, CreateFavoriteList['type'], CreateFavoriteList>(CREATE_FAVORITE_LIST),
    mergeMap(({ payload: { favoriteList } }) =>
      postResource<FavoritesListResource, FavoritesListPostResource, SelectFavoriteList>(
        URL_FAVORITE_LISTS,
        favoriteList,
        (resource) => (resource ? of(selectFavoriteList(resource.id)) : EMPTY),
      ),
    ),
  );

export const editFavoriteListEpic: Epic<
  Action,
  ResourceLoadedAction | SelectFavoriteList | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { patchResource }) =>
  action$.pipe(
    ofType<Action, EditFavoriteList['type'], EditFavoriteList>(EDIT_FAVORITE_LIST),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { favoriteList },
        },
        state,
      ]) => {
        const selectedList = getSelectedList(state);
        if (!selectedList) {
          return EMPTY;
        }
        return patchResource(getResourceURI(selectedList), favoriteList);
      },
    ),
  );

export const deleteFavoriteListEpic: Epic<
  Action,
  ResourceRemovedAction | ResourceLoadedAction | SelectFavoriteList | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { deleteResource }) =>
  action$.pipe(
    ofType<Action, DeleteFavoriteList['type'], DeleteFavoriteList>(DELETE_FAVORITE_LIST),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const selectedList = getSelectedList(state);
      if (!selectedList) {
        return EMPTY;
      }
      const previousListId = getPreviousListId(getVisibleListsForUser(state), selectedList.id);
      return deleteResource(selectedList, {
        createResourceObservable: () => of(selectFavoriteList(previousListId)),
      });
    }),
  );

export const shareListContentsEpic: Epic<Action, OpenShareAssetsModal, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(SHARE_LIST_CONTENTS),
    withLatestFrom(state$),
    map(([, state]) => openShareAssetsModal(getSelectedListAssets(state))),
  );

const addItemToFavoritesObservable = (
  rest: RESTEpicDependencies,
  favoriteItemCollections: ResourceMap<LinkedItemsPageResource<'favoriteitemlist-favoriteitems'>>,
  selectedList: FavoritesListResource,
  assetTypeName: FavoritesItemAssetType,
  assetId: number,
  timecodeStart?: number,
  timecodeEnd?: number,
) =>
  rest.postResource<
    FavoritesItemResource,
    FavoritesItemPostResource,
    ResourceLoadedAction,
    AlertMessage
  >(
    '/api/favoriteitems/',
    {
      favorite_item_list: selectedList.id,
      asset_type: assetTypeName,
      asset_id: assetId,
      timecode_start: timecodeStart,
      timecode_end: timecodeEnd,
    },
    (favoriteItem) => {
      if (!favoriteItem) {
        return EMPTY;
      }
      const collectionURI = getLinkHref(selectedList, mlRel('favoriteitems'));
      if (!collectionURI) {
        return EMPTY;
      }
      return rest.addItemsToCollection(collectionURI, favoriteItemCollections, [
        getResourceURI(favoriteItem),
      ]);
    },
    (err) => {
      if (err?.response?.errors) {
        return of(alertMessage(err.response.errors[0].detail, AlertFeedback.Error));
      }
      return of(alertMessage(gettext('Failed to add item to favorites list.')));
    },
  );

export const addAssetToFavoritesEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, rest) =>
  action$.pipe(
    ofType<Action, AddAssetToFavorites['type'], AddAssetToFavorites>(ADD_ASSET_TO_FAVORITES),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { assetTypeName, assetId },
        },
        state,
      ]) => {
        const selectedList = getSelectedList(state);
        if (!selectedList) {
          return EMPTY;
        }
        return addItemToFavoritesObservable(
          rest,
          state.favorites.favoriteItemCollections,
          selectedList,
          assetTypeName,
          assetId,
        );
      },
    ),
  );

export const addVideoClipToFavoritesEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, rest) =>
  action$.pipe(
    ofType<Action, AddVideoClipToFavorites['type'], AddVideoClipToFavorites>(
      ADD_VIDEO_CLIP_TO_FAVORITES,
    ),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { assetTypeName, assetId, timecodeStart, timecodeEnd },
        },
        state,
      ]) => {
        const selectedList = getSelectedList(state);
        if (!selectedList) {
          return EMPTY;
        }
        return addItemToFavoritesObservable(
          rest,
          state.favorites.favoriteItemCollections,
          selectedList,
          assetTypeName,
          assetId,
          timecodeStart,
          timecodeEnd,
        );
      },
    ),
  );

export const addShotToFavoritesEpic: Epic<
  Action,
  AddVideoClipToFavorites | AlertMessage | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, AddShotToFavorites['type'], AddShotToFavorites>(ADD_SHOT_TO_FAVORITES),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { shotId },
        },
        state,
      ]) =>
        ajax
          .get<Shot>(`/api/shots/${shotId}/`, getTokenAuthHeader(loginSelectors.getToken(state)))
          .pipe(
            map(({ response }) =>
              addVideoClipToFavorites(
                response.asset,
                response.timecode_start,
                response.timecode_end,
              ),
            ),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              return of(alertMessage(gettext('Failed to get shot.')));
            }),
          ),
    ),
  );

export const addSequenceToFavoritesEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { addItemsToCollection }) =>
  action$.pipe(
    ofType<Action, AddSequenceToFavorites['type'], AddSequenceToFavorites>(
      ADD_SEQUENCE_TO_FAVORITES,
    ),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { shots },
        },
        state,
      ]) => {
        const selectedList = getSelectedList(state);
        if (!selectedList) {
          return EMPTY;
        }
        const collectionURI = getLinkHref(selectedList, mlRel('favoriteitems'));
        if (!collectionURI) {
          return EMPTY;
        }
        const { favoriteItemCollections } = state.favorites;
        // TODO: Implement DRF HAL parser, so we can use `postResource` here. <DT 2021-03-24 t:ML-2706>
        return ajax
          .post<EmbeddedItemsPageResource<FavoritesItemResource>>(
            '/api/favoriteitems/create/',
            shots.map((shot) => ({
              favorite_item_list: getSelectedListId(state),
              asset_type: 'videoasset',
              asset_id: shot.asset,
              timecode_start: shot.timecode_start,
              timecode_end: shot.timecode_end,
            })),
            {
              'Content-Type': 'application/json',
              Accept: 'application/hal+json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            mergeMap((favoriteItemsResponse) =>
              concat(
                of(
                  createResourcesLoadedAction(
                    'favoriteitem',
                    getEmbeddedItems(favoriteItemsResponse.response),
                  ),
                ),
                addItemsToCollection(
                  collectionURI,
                  favoriteItemCollections,
                  getEmbeddedItems(favoriteItemsResponse.response).map(getResourceURI),
                ),
              ),
            ),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              return of(alertMessage(gettext('Failed to add sequence to favorites list.')));
            }),
          );
      },
    ),
  );

export const addAssetsToFavoritesEpic: Epic<
  Action,
  ResourceLoadedAction | AddAssetsToFavorites | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { addItemsToCollection }) =>
  action$.pipe(
    ofType<Action, AddAssetsToFavorites['type'], AddAssetsToFavorites>(ADD_ASSETS_TO_FAVORITES),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { assetTypeName, assetIds },
        },
        state,
      ]) => {
        const selectedList = getSelectedList(state);
        if (!selectedList) {
          return EMPTY;
        }
        const collectionURI = getLinkHref(selectedList, mlRel('favoriteitems'));
        if (!collectionURI) {
          return EMPTY;
        }
        const { favoriteItemCollections } = state.favorites;
        // TODO: Implement DRF HAL parser, so we can use `postResource` here. <DT 2021-03-24 t:ML-2706>
        return ajax
          .post<EmbeddedItemsPageResource<FavoritesItemResource>>(
            '/api/favoriteitems/create/',
            assetIds.map((assetId) => ({
              favorite_item_list: selectedList.id,
              asset_type: assetTypeName,
              asset_id: assetId,
            })),
            {
              'Content-Type': 'application/json',
              Accept: 'application/hal+json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            mergeMap((favoriteItemsResponse) =>
              concat(
                of(
                  createResourcesLoadedAction(
                    'favoriteitem',
                    getEmbeddedItems(favoriteItemsResponse.response),
                  ),
                ),
                addItemsToCollection(
                  collectionURI,
                  favoriteItemCollections,
                  getEmbeddedItems(favoriteItemsResponse.response).map(getResourceURI),
                ),
              ),
            ),
            catchError((err: RESTError) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              if (err.response && err.response.errors && err.request && err.request.body) {
                const requestBody = JSON.parse(err.request.body);
                const assetIdsAlreadyInList = err.response.errors
                  .filter((error): error is APIFieldError =>
                    isAPIFieldError(error, 'already_exists'),
                  )
                  .map((error) => pointer.get(requestBody, error.source.pointer).asset_id);
                if (assetIdsAlreadyInList.length === assetIds.length) {
                  return of(
                    alertMessage(
                      gettext('Assets already in favorites list'),
                      AlertFeedback.Warning,
                    ),
                  );
                }
                const assetIdsNotInList = assetIds.filter(
                  (assetId) => !assetIdsAlreadyInList.includes(assetId),
                );
                if (assetIdsNotInList.length > 0 && assetIdsNotInList.length < assetIds.length) {
                  // Retry with assets not in list.
                  return of(addAssetsToFavorites(assetTypeName, assetIdsNotInList));
                }
              }
              return of(alertMessage('Failed to add assets to favorites list.'));
            }),
          );
      },
    ),
  );

export default combineEpics<
  Action,
  | ResourceLoadedAction
  | HideFavorites
  | ShowFavorites
  | SelectFavoriteList
  | FetchSelectedListItems
  | SetListAssetsLoading
  | FavoriteListsOptionsLoaded
  | AlertMessage
  | UpdatePermissions
  | ResourceRemovedAction
  | OpenShareAssetsModal
  | AddAssetToFavorites
  | AddAssetsToFavorites
  | AddShotToFavorites
  | AddSequenceToFavorites
  | AddVideoClipToFavorites
  | UnauthorizedRequestAction,
  RootState
>(
  toggleFavoritesEpic,
  fetchFavoriteListsEpic,
  favoriteListSelectedEpic,
  fetchSelectedListItemsEpic,
  fetchFavoriteListsOptionsEpic,
  exportToRenderEngineEpic,
  fetchPermissionEpic,
  clearFavoriteListEpic,
  moveFavoriteItemEpic,
  deleteFavoriteItemEpic,
  createFavoriteListEpic,
  editFavoriteListEpic,
  deleteFavoriteListEpic,
  shareListContentsEpic,
  addAssetToFavoritesEpic,
  addVideoClipToFavoritesEpic,
  addAssetsToFavoritesEpic,
  addShotToFavoritesEpic,
  addSequenceToFavoritesEpic,
);
