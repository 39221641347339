import { RefObject, useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { detailsSelectors } from '../state/modules/details';

/**
 * The state of the layout.
 *
 * @property prevView
 * @property showSearch: Boolean to control if search can be shown for view type
 * @property showDetails: Boolean to control if details can be shown for view type
 * @property searchSize: Number to set xs size for search results
 * @property detailsSize: Number to set xs size for details
 */
interface GridState {
  readonly prevView: '' | 'player' | 'search';
  readonly showSearch: boolean;
  readonly showDetails: boolean;
  readonly searchSize: 3 | 12;
  readonly detailsSize: 9 | 12;
}
type ViewReducerAction = {
  readonly type:
    | 'SearchView'
    | 'PlayerView'
    | 'AllView'
    | 'SearchViewExited'
    | 'DetailsViewExited'
    | 'TransitionEnd';
};

type ViewReducerTransitionStartAction = {
  readonly type: 'TransitionStart';
  readonly payload: {
    element: 'search' | 'details';
  };
};

type ViewReducerActions = ViewReducerAction | ViewReducerTransitionStartAction;

const searchView = (): ViewReducerAction => ({ type: 'SearchView' });
const playerView = (): ViewReducerAction => ({ type: 'PlayerView' });
const allView = (): ViewReducerAction => ({ type: 'AllView' });
const searchViewExited = (): ViewReducerAction => ({ type: 'SearchViewExited' });
const detailsViewExited = (): ViewReducerAction => ({ type: 'DetailsViewExited' });
const transitionEnd = (): ViewReducerAction => ({ type: 'TransitionEnd' });
const transitionStart = (element: 'search' | 'details'): ViewReducerTransitionStartAction => ({
  type: 'TransitionStart',
  payload: { element },
});

const viewReducer = (state: GridState, action: ViewReducerActions): GridState => {
  switch (action.type) {
    case 'SearchView':
      return {
        ...state,
        showDetails: false,
        showSearch: true,
        searchSize: 12,
        prevView: '',
      };
    case 'PlayerView':
      // Notes:
      // - When changing to PlayerView without transitions detailssize is not set to 12
      // - This can cause layout shifts if parent container does not use "nowrap"
      return {
        ...state,
        showSearch: false,
        detailsSize: 12,
        prevView: '',
      };
    case 'AllView':
      // transition to All view must be done in two steps, otherwise components shortly disappear
      // (actually below the other component -> not in viewport) which looks strange
      // 1. whatever is shown in full (search or details) must shrink
      // 2. whatever was not shown slides in (this is handled in 'TransitionEnd' case)
      if (state.showSearch && state.showDetails) {
        return {
          ...state,
          searchSize: 3,
          detailsSize: 9,
        };
      }
      if (state.showSearch) {
        return {
          ...state,
          prevView: 'search',
          searchSize: 3,
          detailsSize: 9,
          showDetails: true,
        };
      }
      return {
        ...state,
        prevView: 'player',
        showSearch: true,
        showDetails: true,
        searchSize: 3,
        detailsSize: state.showSearch ? 9 : 12,
      };
    case 'TransitionEnd':
      switch (state.prevView) {
        case 'search':
          return {
            ...state,
            prevView: '',
            showDetails: true,
            detailsSize: 9,
          };
        case 'player':
          return {
            ...state,
            prevView: '',
            showSearch: true,
            searchSize: 3,
          };
        default:
          return state;
      }
    case 'TransitionStart':
      switch (action.payload.element) {
        case 'search':
          return {
            ...state,
            detailsSize: 9,
          };
        default:
          return state;
      }
    case 'SearchViewExited':
      return {
        ...state,
        showDetails: true,
        detailsSize: 12,
        prevView: '',
      };
    case 'DetailsViewExited':
      return {
        ...state,
        showSearch: true,
        searchSize: 12,
      };
    default:
      return state;
  }
};

type LayoutState = {
  readonly showSearch: boolean;
  readonly showDetails: boolean;
  readonly searchSize: 12 | 3;
  readonly detailsSize: 12 | 9;
  readonly onDetailsViewExited: () => void;
  readonly onSearchViewExited: () => void;
  readonly detailsTransitionAddEndListener: (done: unknown) => void;
  readonly searchTransitionAddEndListener: (done: unknown) => void;
  readonly startTransitioning: (element: 'search') => void;
};

const useLayout = (
  gridDetailsRef: RefObject<HTMLDivElement>,
  searchRef: RefObject<HTMLDivElement>,
): LayoutState => {
  const view = useSelector(detailsSelectors.getLayoutView);

  const [{ showSearch, showDetails, searchSize, detailsSize }, viewDispatch] = useReducer(
    viewReducer,
    {
      prevView: '',
      showSearch: view !== 'player',
      showDetails: view !== 'search',
      searchSize: view === 'search' ? 12 : 3,
      detailsSize: view === 'player' ? 12 : 9,
    },
  );
  useEffect(() => {
    switch (view) {
      case 'search':
        viewDispatch(searchView());
        break;
      case 'player':
        viewDispatch(playerView());
        break;
      default:
        viewDispatch(allView());
        break;
    }
  }, [view]);

  const onDetailsViewExited = useCallback(() => {
    viewDispatch(detailsViewExited());
  }, []);
  const onSearchViewExited = useCallback(() => {
    viewDispatch(searchViewExited());
  }, []);

  const detailsTransitionAddEndListener = useCallback(
    (done) => {
      if (gridDetailsRef.current) {
        gridDetailsRef.current.addEventListener(
          'transitionend',
          (e) => {
            // Unknown reason why "flex-basis" See ML-2663
            if (e.propertyName === 'flex-basis' || e.propertyName === 'transform') {
              viewDispatch(transitionEnd());
            }
            // TODO: check with MUI5 if `if` still needed (Slide TS issue) <RS>
            if (typeof done === 'function') {
              done();
            }
          },
          false,
        );
      }
    },
    [gridDetailsRef],
  );
  const searchTransitionAddEndListener = useCallback(
    (done) => {
      if (searchRef.current) {
        searchRef.current.addEventListener(
          'transitionend',
          (e) => {
            if (e.propertyName === 'flex-basis') {
              viewDispatch(transitionEnd());
            }
            if (typeof done === 'function') {
              done();
            }
          },
          false,
        );
      }
    },
    [searchRef],
  );
  const startTransitioning = useCallback(
    (element: 'search') => {
      viewDispatch(transitionStart(element));
    },
    [viewDispatch],
  );
  return {
    showSearch,
    showDetails,
    searchSize,
    detailsSize,
    onDetailsViewExited,
    onSearchViewExited,
    detailsTransitionAddEndListener,
    searchTransitionAddEndListener,
    startTransitioning,
  };
};

export default useLayout;
