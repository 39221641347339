import { Collapse, Divider, Paper, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { SelectOption } from 'medialoopster/components/Select';
import { ReadonlyRecord } from 'medialoopster/types';

import { detailsSelectors } from '../../state/modules/details';
import { toggleAnnotations, toggleView } from '../../state/modules/details/actions';
import { searchActions, searchSelectors } from '../../state/modules/search';
import { searchFieldMappingSelectors } from '../../state/modules/searchfieldmappings';
import {
  SearchFieldMappingInputType,
  SearchFieldMappingWithOptions,
} from '../../state/modules/searchfieldmappings/types';
import { SearchResultAssetType } from '../../state/types/asset/baseTypes';
import AppsMenu from './AppsMenu';
import AssetActionMenu from './AssetActionMenu';
import HeaderTopNav from './HeaderTopNav';
import Logo from './Logo';
import SearchFields from './SearchFields';
import SearchInput from './SearchInput';
import SearchOptions, { ProductionOption } from './SearchOptions';
import ViewOptions from './ViewOptions';
import { SearchQuery } from './services/SearchQueryService';
import { parseDateRangeStringToISO } from './services/parseDateRangeStringToISO';
import { parseDurationRangeString } from './services/parseSearchDuration';
import useSearchFieldMappingOptions, {
  SearchFieldOptions,
} from './services/useSearchFieldMappingOptions';

const useStyles = makeStyles((theme) => ({
  mlHeader: {
    color: theme.palette.text.secondary,
    borderRadius: '0px',
  },
  headerContent: {
    maxWidth: '1440px',
    margin: 'auto',
    paddingLeft: '15px',
    paddingRight: '2px',
  },
  headerTopNav: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: '10px',
  },
  headerLogo: {
    paddingBottom: '3px',
  },
}));

const uuidToSearchKey = (uuid: string): string => `so_${uuid.replace(/-/g, '')}`;

type SearchFieldsRecord = ReadonlyRecord<string, string | number | Readonly<[string, string]>>;

// Returns a search key accumulator function for given uuidToInputTypeMap & userInputFields
const getSearchKeyAccumulatorForUserInputAndTypeMap =
  (
    uuidToInputTypeMap: ReadonlyRecord<string, SearchFieldMappingInputType>,
    allInputFields: ReadonlyRecord<string, string | ReadonlyArray<string>>,
    apiFields: ReadonlyArray<SearchFieldMappingWithOptions>,
    searchFieldMappingOptions: SearchFieldOptions,
  ) =>
  (accFields: SearchFieldsRecord, uuid: string): SearchFieldsRecord => {
    const fieldValue = allInputFields[uuid];
    const searchFieldMapping = apiFields.find((field) => field.uuid === uuid);
    if (_.isString(fieldValue)) {
      if (
        uuidToInputTypeMap[uuid] === 'Text' &&
        searchFieldMapping &&
        searchFieldMappingOptions &&
        searchFieldMappingOptions.fieldLookups[searchFieldMapping.default_field_lookup]
      ) {
        return {
          ...accFields,
          [uuidToSearchKey(uuid)]: [
            searchFieldMappingOptions.fieldLookups[searchFieldMapping.default_field_lookup],
            fieldValue,
          ],
        };
      }
      if (uuidToInputTypeMap[uuid] === 'Duration') {
        const [min, max] = parseDurationRangeString(fieldValue);
        const searchUuid = uuidToSearchKey(uuid);
        return {
          ...accFields,
          [`${searchUuid}.min`]: min,
          [`${searchUuid}.max`]: max,
        };
      }
      if (uuidToInputTypeMap[uuid] === 'Select' || uuidToInputTypeMap[uuid] === 'Exists') {
        return {
          ...accFields,
          [uuidToSearchKey(uuid)]: fieldValue,
        };
      }
      if (uuidToInputTypeMap[uuid] === 'Date') {
        const [start, end] = parseDateRangeStringToISO(fieldValue);
        const searchUuid = uuidToSearchKey(uuid);
        if (start && end) {
          return {
            ...accFields,
            [`${searchUuid}.start`]: start,
            [`${searchUuid}.end`]: end,
          };
        }
        return accFields;
      }
    } else if (uuidToInputTypeMap[uuid] === 'Keyword' || uuidToInputTypeMap[uuid] === 'Person') {
      const keywordStringList = fieldValue.join(',');
      return {
        ...accFields,
        [uuidToSearchKey(uuid)]: keywordStringList,
      };
    }
    return accFields;
  };

// Remove empty optional search fields and return search query object
const getOptionalQueryFields = (
  userFields: ReadonlyRecord<string, string | ReadonlyArray<string>>,
  apiFields: ReadonlyArray<SearchFieldMappingWithOptions>,
  searchFieldMappingOptions: SearchFieldOptions,
): SearchFieldsRecord => {
  const cleanUserFields = _.omitBy(userFields, _.isEmpty);
  if (_.isEmpty(cleanUserFields)) {
    return {};
  }
  const uuidToInputTypeMap = apiFields.reduce(
    (accMap, apiSearchFieldMappingObject) => ({
      ...accMap,
      ...{
        [apiSearchFieldMappingObject.uuid]: apiSearchFieldMappingObject.input_type,
      },
    }),
    {},
  );
  const searchKeyAccumulator = getSearchKeyAccumulatorForUserInputAndTypeMap(
    uuidToInputTypeMap,
    cleanUserFields,
    apiFields,
    searchFieldMappingOptions,
  );
  return Object.keys(cleanUserFields).reduce(searchKeyAccumulator, {});
};

const removeErrorFields = (
  fields: ReadonlyRecord<string, string | ReadonlyArray<string>>,
  errs: FieldErrors<ReadonlyRecord<string, string | ReadonlyArray<string>>>,
) => _.omit(fields, Object.keys(errs));

type Props = {
  readonly version: string;
  readonly productions: ReadonlyArray<ProductionOption>;
  readonly productionFilter: number;
  readonly devices: ReadonlyArray<SelectOption>;
  readonly siteName: string;
  readonly staticPrefix?: string;
};

const Header: React.FC<Props> = ({
  version,
  productions,
  productionFilter,
  devices,
  siteName,
  staticPrefix = '/static',
}: Props) => {
  const classes = useStyles();
  const isMdDown = useMediaQuery<DefaultTheme>((theme) => theme.breakpoints.down('md'));

  const [lastActiveSearchOptions] = useState(() =>
    JSON.parse(localStorage.getItem('mlSearchFields') || '{}'),
  );
  const [primarySearchValue, setPrimarySearchValue] = useState(
    () => localStorage.getItem('mlPrimarySearch') || '',
  );
  const searchCategory = useSelector(searchSelectors.getSearchCategory);

  const [deviceFilter, setDeviceFilter] = useState(
    () => JSON.parse(localStorage.getItem('mlApiSearchOptions') || '{}').device_filter || [],
  );

  const formHooks = useForm<ReadonlyRecord<string, string | ReadonlyArray<string>>>({
    mode: 'onChange',
    defaultValues: lastActiveSearchOptions,
  });
  const {
    getValues,
    formState: { errors },
  } = formHooks;
  const dispatch = useDispatch();

  const [showSearchFields, setShowSearchFields] = useState(false);

  const apiSearchFieldMappings = useSelector(
    searchFieldMappingSelectors.getCurrentSearchFieldMappingsWithOptions,
  );
  const searchFieldMappingOptions = useSearchFieldMappingOptions();

  const getApiSearchOptions = useCallback(() => {
    const allSearchFields = getValues();
    const queryFields = removeErrorFields(allSearchFields, errors);
    return getOptionalQueryFields(queryFields, apiSearchFieldMappings, searchFieldMappingOptions);
  }, [apiSearchFieldMappings, getValues, errors, searchFieldMappingOptions]);

  const saveSearch = useCallback(() => {
    const allSearchFields = getValues();
    localStorage.setItem('mlSearchFields', JSON.stringify(allSearchFields));
  }, [getValues]);

  const saveApiSearchOptions = (apiSearchOptions: SearchQuery) => {
    localStorage.setItem('mlApiSearchOptions', JSON.stringify(apiSearchOptions));
  };
  // All dependent hooks before onSearch hook. E.g formHooks, ...
  const layoutView = useSelector(detailsSelectors.getLayoutView);

  const onSearch = useCallback(
    (
      value = primarySearchValue,
      category: SearchResultAssetType = searchCategory,
      productionId = productionFilter,
      device = deviceFilter,
    ) => {
      setPrimarySearchValue(value);
      setDeviceFilter(device);
      const apiSearchOptions = {
        ...(category === searchCategory ? getApiSearchOptions() : {}),
        so_primary_search: value,
        search_category: category,
        production_filter: productionId,
        device_filter: device,
      };
      dispatch(toggleAnnotations(false));

      if (layoutView === 'player') {
        dispatch(toggleView('all', 'default'));
      }
      dispatch(searchActions.fetchSearchResult(false, apiSearchOptions));
      saveSearch();
      localStorage.setItem('mlPrimarySearch', value);
      saveApiSearchOptions(apiSearchOptions);
    },
    [
      dispatch,
      getApiSearchOptions,
      saveSearch,
      primarySearchValue,
      setPrimarySearchValue,
      searchCategory,
      productionFilter,
      deviceFilter,
      layoutView,
    ],
  );

  useEffect(() => {
    dispatch(
      searchActions.fetchSearchResult(
        false,
        JSON.parse(localStorage.getItem('mlApiSearchOptions') || '{}'),
      ),
    );
  }, [dispatch]);

  return (
    <div id="header">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formHooks}>
        <Paper className={classes.mlHeader}>
          <Grid container alignItems="center" className={classes.headerTopNav}>
            <Grid container alignItems="center" className={classes.headerContent}>
              <Grid container className={classes.headerLogo}>
                <Grid item sx={{ width: '32px' }}>
                  <AppsMenu />
                </Grid>
                <Grid item sm={12} md={5}>
                  <Logo version={version} siteName={siteName} staticPrefix={staticPrefix} />
                </Grid>
                {!isMdDown && (
                  <Grid item md={6}>
                    <HeaderTopNav />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems="center" className={classes.headerContent}>
            <Grid
              container
              spacing={2}
              item
              xs={12}
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{
                marginBottom: 2,
              }}
            >
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <SearchInput
                  value={primarySearchValue}
                  searchCategory={searchCategory}
                  showSearchFields={showSearchFields}
                  onSearch={onSearch}
                  onShowSearchOptions={() => {
                    setShowSearchFields(!showSearchFields);
                  }}
                />
              </Grid>

              {!isMdDown && (
                <Grid
                  container
                  item
                  md={8}
                  lg={9}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container spacing={2} item md={4} lg={4}>
                    <SearchOptions
                      productions={productions}
                      devices={devices}
                      deviceFilter={deviceFilter}
                      onChangeProductionFilter={(productionId) =>
                        onSearch(undefined, undefined, productionId)
                      }
                      onChangeDeviceFilter={(deviceNames) =>
                        onSearch(undefined, undefined, undefined, deviceNames)
                      }
                    />
                  </Grid>
                  <Grid item md={6} lg={4}>
                    <ViewOptions />
                  </Grid>
                  <Grid container item md={2} lg={2} justifyContent="flex-end">
                    <Grid item>
                      <AssetActionMenu />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Collapse in={showSearchFields} sx={{ width: '100%' }}>
            <Divider />
            <Grid
              container
              alignItems="center"
              sx={{
                maxWidth: '1440px',
                margin: 'auto',
                paddingLeft: '15px',
                paddingRight: '2px',
              }}
            >
              <SearchFields
                showSearchFields={showSearchFields}
                setShowSearchFields={setShowSearchFields}
                onSearch={onSearch}
                apiSearchFieldMappings={apiSearchFieldMappings}
              />
            </Grid>
          </Collapse>
        </Paper>
      </FormProvider>
    </div>
  );
};

export default memo(Header);
