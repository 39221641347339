/**
 * This module implements a React context provider to distribute the Editor input props to plugins
 */
import _ from 'lodash';
import React, { ReactNode, createContext, useContext } from 'react';

import { decimal, Decimal } from '../decimal';

/**
 * The context that is retrievable by the provider.
 */
export interface TranscriptEditorContext {
  /**
   * The callback to call when a navigation occurs.
   *
   * This takes the time in seconds that is the target of the navigation on the asset.
   */
  readonly onNavigation: (time: Decimal) => void;
  /**
   * A boolean that describes if the editor should be editable or in read-only mode.
   */
  readonly isEditable: boolean;
  /**
   * A time-offset for the asset.
   *
   * This offsets the timestamp of the asset and is needed if a video asset does not start
   * at time 0, e.g. if it is a clip in a multi camera recording.
   */
  readonly offsetTime: Decimal;
}

/**
 * The context value with its initial state.
 */
const TranscriptEditorContextValue = createContext<TranscriptEditorContext>({
  onNavigation: () => {},
  isEditable: false,
  offsetTime: decimal('0'),
});

/**
 * A React hook to retrieve the context from the provider.
 */
export const useTranscriptEditorContext = (): TranscriptEditorContext =>
  useContext(TranscriptEditorContextValue);

/**
 * A provider for the TranscriptEditorContext.
 *
 * This takes the constituents of the TranscriptEditorContext as properties and  wraps its children in a provider
 * to share these constituents as TranscriptEditorContext with them.
 *
 * @param props The attributes of a TranscriptEditorContext as well as some React nodes as children.
 */
export const TranscriptEditorContextProvider: React.FC<
  TranscriptEditorContext & { readonly children: ReactNode }
> = (props) => {
  return (
    <TranscriptEditorContextValue.Provider value={_.omit(props, 'children')}>
      {props.children}
    </TranscriptEditorContextValue.Provider>
  );
};
