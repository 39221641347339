import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo, MouseEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { ImageWithLoadingPlaceholder } from 'medialoopster/components';
import { ErrorML, Image, NoPreview, Pause, Preview } from 'medialoopster/icons';
import { getResourceURI } from 'medialoopster/rest';

import {
  ERROR,
  GENERATING,
  mapStatus,
  PREPARING_IMPORT,
  PREVIEW_AVAILABLE,
  RECORDING,
} from '../../../../businessRules/models/AssetStatus';
import { favoritesSelectors } from '../../../../state/modules/favorites';
import { ImageAsset as ImageAssetType } from '../../../../state/modules/image/types';
import { searchActions } from '../../../../state/modules/search';
import FormattedDate from '../../../../ui/components/FormattedDate';
import useAssetDragRef from '../../../../ui/hooks/useAssetDragRef';
import AssetHeader from '../AssetHeader';
import LabeledInfo from '../LabeledInfo';
import MediaAssetStatusBar from '../MediaAssetStatusBar';

const statusTitleMap: Record<string, string> = {
  [ERROR]: gettext('An error occurred'),
  [PREPARING_IMPORT]: `${gettext('Preparing import')} …`,
  [GENERATING]: gettext('Generating image proxy'),
  [RECORDING]: gettext('Generating image proxy'),
  [PREVIEW_AVAILABLE]: gettext('Image preview available'),
};

const statusLevelMap: Record<string, string> = {
  [ERROR]: 'error',
  [PREPARING_IMPORT]: 'warning',
  [GENERATING]: 'default',
  [RECORDING]: 'default',
  [PREVIEW_AVAILABLE]: 'default',
};

export interface ImageAssetProps {
  readonly asset: ImageAssetType;
  readonly isWide: boolean;
  readonly current: boolean;
  readonly inSelection: boolean;
}

const useStyles = makeStyles((theme) => ({
  cursorGrab: {
    cursor: 'grab',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  body: { display: 'flex', flexGrow: 1, alignItems: 'flex-end', paddingBottom: theme.spacing(1) },
  bodyInfo: { flexGrow: 1 },
  bodyInfoCentered: { display: 'flex', alignItems: 'center' },
  bodyImage: { display: 'flex', paddingRight: theme.spacing(1) },
  bodyIcon: { display: 'flex', paddingRight: theme.spacing(1) },
  bodyRowCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  bodyInfoSpaced: {
    display: 'flex',
    '&>div': {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const renderStatusIcon = (internalStatus: string) => {
  // 'Preview' icons must be aligned with the other icons, since it has no left-right margin
  if (internalStatus === PREVIEW_AVAILABLE)
    return <Preview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  if (internalStatus === ERROR) return <ErrorML style={{ fontSize: '1.2rem' }} />;
  if (internalStatus === PREPARING_IMPORT) return <Pause style={{ fontSize: '1.2rem' }} />;
  if (internalStatus === GENERATING)
    return <NoPreview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  if (internalStatus === RECORDING)
    return <NoPreview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  return <></>;
};

const renderAssetIcon = () => <Image fontSize="small" />;

const ImageAsset: FC<ImageAssetProps> = ({
  asset,
  isWide,
  current,
  inSelection,
}: ImageAssetProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFavoriteListActive = useSelector(favoritesSelectors.isActive);
  const internalStatus = mapStatus(asset.status_asset);
  const titleDragRef = useAssetDragRef(asset, 'imageasset');
  const imageDragRef = useAssetDragRef(asset, 'imageasset');
  const renderAssetStatusIcon = useCallback(
    () => renderStatusIcon(internalStatus),
    [internalStatus],
  );
  const handleAssetClick = useCallback(
    (event: MouseEvent<Element>) => {
      dispatch(searchActions.clickEntry(asset.id, 'imageasset', getResourceURI(asset), event));
    },
    [dispatch, asset],
  );

  const ImageAssetStatusBar: FC = () => {
    return (
      <MediaAssetStatusBar
        asset={asset}
        isWide={isWide}
        renderAssetStatusIcon={renderAssetStatusIcon}
        statusTitle={statusTitleMap[internalStatus]}
        statusLevel={statusLevelMap[internalStatus]}
        showLabelAssetStatus={isWide || internalStatus === ERROR}
      />
    );
  };

  return (
    <AssetHeader
      onAssetClick={handleAssetClick}
      isCurrent={current}
      inSelection={inSelection}
      isWide={isWide}
    >
      {!isWide && (
        <div className={classes.header}>
          <span
            ref={titleDragRef}
            data-testid="draggableTitle"
            className={clsx(classes.bodyIcon, { [classes.cursorGrab]: isFavoriteListActive })}
          >
            {renderAssetIcon()}
          </span>
          <LabeledInfo isWide={false} label={gettext('Title')} big shorten>
            {asset.name}
          </LabeledInfo>
        </div>
      )}
      <div className={classes.body}>
        <div className={classes.bodyImage}>
          <ImageWithLoadingPlaceholder
            dragRef={imageDragRef}
            className={clsx({
              [classes.cursorGrab]: isFavoriteListActive,
            })}
            placeholderWidth={102}
            placeholderHeight={57}
            url={asset.thumbnail_url}
          />
        </div>

        {isWide && (
          <div className={classes.bodyInfo}>
            <div className={classes.bodyRowCentered}>
              <LabeledInfo isWide={isWide} label={gettext('Title')} big shorten>
                {asset.name}
              </LabeledInfo>
              <div className={classes.bodyInfoCentered}>
                <span className={classes.bodyIcon}>{renderAssetIcon()}</span>
                {gettext('Imageasset')}
              </div>
            </div>

            <div className={classes.bodyRowCentered}>
              <LabeledInfo isWide={isWide} label={gettext('Production')}>
                {asset.production_name}
              </LabeledInfo>
              <div className={classes.bodyInfoSpaced}>
                <LabeledInfo isWide={isWide} label={gettext('Image size')}>
                  {`${asset.size_x} x ${asset.size_y} `}
                </LabeledInfo>
                <LabeledInfo isWide={isWide} label={gettext('Color mode')}>
                  {asset.mode}
                </LabeledInfo>
                <LabeledInfo isWide={isWide} label={gettext('Image file format')}>
                  {asset.format}
                </LabeledInfo>{' '}
              </div>
            </div>

            <div className={classes.bodyRowCentered}>
              <ImageAssetStatusBar />
              <LabeledInfo isWide={isWide} label={gettext('Added')}>
                <FormattedDate
                  date={asset.date_add}
                  format={pgettext(
                    'Localized numeric date and time with seconds',
                    'MM/dd/yyyy h:mm:ss a',
                  )}
                />
              </LabeledInfo>
            </div>
          </div>
        )}

        {!isWide && (
          <div className={classes.bodyInfo}>
            <div className={classes.bodyRowCentered}>
              <LabeledInfo isWide={isWide} label={gettext('Image size mode format')}>
                {`${asset.size_x} x ${asset.size_y} | ${asset.mode} | ${asset.format}`}
              </LabeledInfo>
            </div>

            <div className={classes.bodyRowCentered}>
              <span>&nbsp;&nbsp;</span>
              <LabeledInfo isWide={isWide} label={gettext('Date added')}>
                <FormattedDate
                  date={asset.date_add}
                  format={pgettext('Localized numeric date', 'MM/dd/yyyy')}
                />
              </LabeledInfo>
            </div>

            <div className={classes.bodyRowCentered}>
              <LabeledInfo isWide={isWide} label={gettext('Production')}>
                {asset.production_name}
              </LabeledInfo>
              <LabeledInfo isWide={isWide} label={gettext('Time added')}>
                <FormattedDate
                  date={asset.date_add}
                  format={pgettext('Localized time with seconds', 'h:mm:ss a')}
                />
              </LabeledInfo>
            </div>

            <div className={classes.bodyRowCentered}>
              <ImageAssetStatusBar />
            </div>
          </div>
        )}
      </div>
    </AssetHeader>
  );
};

export default memo(ImageAsset);
