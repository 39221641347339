/* eslint no-underscore-dangle: 0 */
import _ from 'lodash';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { of, EMPTY, Observable, concat, combineLatest, merge } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import {
  mergeMap,
  catchError,
  map,
  filter,
  distinctUntilChanged,
  take,
  skip,
  withLatestFrom,
} from 'rxjs/operators';

import parseLinkHeader from '../parseLinkHeader';
import { DeepPartial, isReadonlyArray, ReadonlyRecord } from '../types';
import { getVersionHeaders } from './getVersionHeaders';
import {
  createResourcesLoadedAction,
  createResourceOptionsLoadedAction,
  createResourcesRemovedAction,
  getHandledResourceTypeNames,
} from './redux';
import {
  getResourceURI,
  getResourceTypeName,
  isResource,
  normalizeURI,
  getLinkHref,
  getResource,
  toTypeName,
  mlRel,
} from './resources';
import {
  BaseResource,
  Resource,
  ResourceLoadedAction,
  PageResource,
  ResourceRemovedAction,
  HttpMethod,
  RESTError,
  LinkedItemsPageResource,
  ResourceMap,
  MoveActions,
  ResourceOptionsLoadedAction,
  RECEIVE_ROOT_RESOURCE,
  ReceiveRootResource,
  Link,
  ResourceOptions,
  ResourceOptionsV3,
  unauthorizedRequestAction,
  UnauthorizedRequestAction,
  HttpHeaders,
} from './types';

/**
 * Transform a resource from an API response into a resource to be stored in the redux state.
 *
 * All embedded resources, whose type can be handled by a reducer, are missing from the resulting resource.
 * Instead of the embedded resources, the resulting resource will contain links to them.
 *
 * @param resource - The resource to transform.
 * @param handledResourceTypeNames - The types of embedded resources to transform to links.
 *
 * @return The transformed resource.
 */
const transformEmbeddedResourcesIntoLinks = <R extends BaseResource = BaseResource>(
  resource: BaseResource | undefined,
  handledResourceTypeNames: ReadonlySet<string>,
): R => {
  if (!resource?._embedded) {
    return resource as R;
  }
  const isHandledResource = (baseResource?: BaseResource): baseResource is Resource<string> =>
    isResource(baseResource) && handledResourceTypeNames.has(getResourceTypeName(baseResource));
  const isUnhandledResource = (baseResource?: BaseResource) =>
    !baseResource || !isHandledResource(baseResource);
  // First find embedded resources, which cannot be handled. These remain in the resource.
  const embedded = Object.fromEntries(
    Object.entries(resource._embedded)
      .filter(
        // Exclude all embedded resources with ``self`` links.
        ([, embeddedObject]) =>
          isReadonlyArray(embeddedObject)
            ? embeddedObject.filter(isUnhandledResource).length > 0
            : isUnhandledResource(embeddedObject),
      )
      .map(([relationType, embeddedObject]) =>
        // Recursively exclude embedded resources with ``self`` link from the embedded resources.
        [
          relationType,
          isReadonlyArray(embeddedObject)
            ? embeddedObject
                .filter(isUnhandledResource)
                .map((embeddedResource) =>
                  transformEmbeddedResourcesIntoLinks(embeddedResource, handledResourceTypeNames),
                )
            : transformEmbeddedResourcesIntoLinks(embeddedObject, handledResourceTypeNames),
        ],
      ),
  );
  // Secondly, find embedded resources which can be handled.
  // For each of them, a link is added to the resource's links.
  const links = {
    ...(resource._links || {}),
    ...Object.fromEntries(
      // Transform embedded resources with ``self`` link into ``Link``s.
      Object.entries(resource._embedded)
        .map(([relationType, embeddedObject]) => {
          if (embedded[relationType]) {
            // Relation type is still embedded, so skip it.
            return [relationType, undefined];
          }
          if (isReadonlyArray(embeddedObject)) {
            return [
              relationType,
              embeddedObject
                .filter(isHandledResource)
                .map((embeddedResource) => ({ href: getResourceURI(embeddedResource) })),
            ];
          }
          return [
            relationType,
            isHandledResource(embeddedObject)
              ? { href: getResourceURI(embeddedObject) }
              : undefined,
          ];
        })
        .filter(([, link]) => !!link),
    ),
  };
  return {
    ...resource,
    _embedded: Object.keys(embedded).length > 0 ? embedded : undefined,
    _links: Object.keys(links).length > 0 ? links : undefined,
  } as R;
};

const extractResources = <R extends BaseResource>(
  resource: BaseResource | undefined,
  handledResourceTypeNames: ReadonlySet<string>,
): { mainResource: R; resources: ReadonlyArray<Resource<string>> } => {
  // Recursively get all embedded resources with ``self`` links.
  const resources: Resource<string>[] = Object.values(resource?._embedded || {}).flatMap(
    (embeddedObject) =>
      isReadonlyArray(embeddedObject)
        ? embeddedObject.flatMap(
            (embeddedResource) =>
              extractResources(embeddedResource, handledResourceTypeNames).resources,
          )
        : extractResources(embeddedObject, handledResourceTypeNames).resources,
  );
  // Turn all embedded resources with ``self`` links into links.
  const mainResource = transformEmbeddedResourcesIntoLinks<R>(resource, handledResourceTypeNames);
  if (isResource(mainResource) && handledResourceTypeNames.has(getResourceTypeName(mainResource))) {
    resources.push(mainResource);
  }
  return { mainResource, resources };
};

const collectPages = <P extends LinkedItemsPageResource<string>>(
  collectionResourceMap: ResourceMap<P>,
  pageURI: string,
): ReadonlyArray<P> => {
  const page = getResource(collectionResourceMap, pageURI);
  if (!page) {
    return [];
  }
  const nextHref = getLinkHref(page, 'next');
  if (!nextHref) {
    return [page];
  }
  return [page, ...collectPages(collectionResourceMap, nextHref)];
};

export interface ResourceRequestOptions<A extends Action, E extends Action> {
  readonly createResourceObservable?: () => Observable<A>;
  readonly createErrorObservable?: (err: RESTError) => Observable<E>;
  readonly headers?: HttpHeaders;
  readonly query?: ReadonlyRecord<string, string>;
}

export type DeleteResourceOptions<
  A extends Action = ResourceRemovedAction<string>,
  E extends Action = ResourceRemovedAction<string>,
> = ResourceRequestOptions<A, E>;

export type PostResourceOptions<
  A extends Action = ResourceLoadedAction<Resource<string>>,
  E extends Action = ResourceLoadedAction<Resource<string>>,
> = ResourceRequestOptions<A, E>;

export class RESTEpicDependencies {
  handledResourceTypeNames: ReadonlySet<string>;

  isDebug: boolean;

  getDefaultHeaders: () => HttpHeaders;

  /**
   * Create REST epic dependencies.
   *
   * @param state - The redux state
   *
   * @param getExtraHeaders - A function that returns headers to overwrite `getDefaultHeaders`.
   *
   * @param isDebug - Turns on the debug in test mode
   */
  constructor(state: unknown, getExtraHeaders: () => HttpHeaders = () => ({}), isDebug = false) {
    this.handledResourceTypeNames = new Set(getHandledResourceTypeNames(state));
    this.isDebug = isDebug;
    this.getDefaultHeaders = () => ({
      Accept: 'application/hal+json',
      // TODO: Implement DRF HAL parser, so we can use `application/hal+json` here. <DT 2021-03-24 t:ML-2706>
      'Content-Type': 'application/json',
      ...getExtraHeaders(),
    });
  }

  private debug = (message: string) => {
    if (this.isDebug) {
      // eslint-disable-next-line no-console
      console.debug(message);
    }
  };

  /**
   * Asynchronously request a resource.
   * Emits ``RESOURCES_LOADED`` actions for the resource and all its embedded resources.
   *
   * @param url - The URL of the resource to request.
   * @param method - The HTTP method to call `ajax` with.
   * @param createResourceObservable - Function to emit additional actions on success.
   *   Takes the response's resource as argument.
   * @param createErrorObservable - Function to emit actions on error.
   * @param createResource - Function to create a resource from the response.
   *   (Mainly to add headers to the resource.)
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   * @param requestBody - The resource to pass as request body (`PATCH` or `POST`).
   *
   * @returns Observable emitting ``RESOURCES_LOADED`` actions for the fetched resource and embedded resources,
   *   as well as the actions from ``createResourceObservable``.
   */
  requestResource = <
    R extends BaseResource,
    B extends DeepPartial<BaseResource> | null = BaseResource,
    A extends Action = ResourceLoadedAction<Resource<string>>,
    E extends Action = ResourceLoadedAction<Resource<string>>,
  >(
    url: string,
    method: HttpMethod,
    createResourceObservable: (resource?: R) => Observable<A> = () => EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    createResource: (response: AjaxResponse<BaseResource>) => BaseResource,
    headers: HttpHeaders = {},
    requestBody?: B,
  ): Observable<ResourceLoadedAction<Resource<string>> | A | E | UnauthorizedRequestAction> => {
    this.debug(`${method} ${url}`);
    return ajax<BaseResource>({
      url,
      method,
      headers: {
        ...this.getDefaultHeaders(),
        ...headers,
      },
      body: JSON.stringify(requestBody),
    }).pipe(
      mergeMap((response) => {
        this.debug(`Request body: ${JSON.stringify(requestBody, null, 2)}`);
        this.debug(`Response: ${JSON.stringify(response, null, 2)}`);
        const { type } = parseLinkHeader(response.xhr.getResponseHeader('Link'));
        // TODO: Get type name from header, as soon as it is supported.
        const mainResourceTypeName = type ? toTypeName(type) : null;
        this.debug(`Resource type: ${mainResourceTypeName}`);
        if (response.response === null) {
          return concat(
            createResourceObservable(),
            mainResourceTypeName && this.handledResourceTypeNames.has(mainResourceTypeName)
              ? of(
                  createResourcesLoadedAction<Resource<string>>(mainResourceTypeName, [], {
                    [url]: response.responseHeaders,
                  }),
                )
              : EMPTY,
          );
        }
        const { mainResource, resources } = extractResources<R>(
          createResource(response),
          this.handledResourceTypeNames,
        );
        this.debug(`Main resource: ${JSON.stringify(mainResource, null, 2)}`);
        this.debug(`All resources: ${JSON.stringify(resources, null, 2)}`);
        return concat(
          of(
            ...Object.entries(
              _.groupBy(resources, (item: Resource<string>) => getResourceTypeName(item)),
            ).flatMap(([resourceTypeName, items]) =>
              createResourcesLoadedAction(
                resourceTypeName,
                items,
                resourceTypeName === mainResourceTypeName &&
                  this.handledResourceTypeNames.has(mainResourceTypeName)
                  ? {
                      [url]: response.responseHeaders,
                    }
                  : undefined,
              ),
            ),
          ),
          createResourceObservable(mainResource),
        );
      }),
      catchError((err) => {
        this.debug(`${method} ${url}`);
        this.debug(`Error: ${err}`);
        this.debug(`Error JSON: ${JSON.stringify(err, null, 2)}`);
        if (err && err.status === 401) {
          return concat(of(unauthorizedRequestAction()), createErrorObservable(err));
        }
        return createErrorObservable(err);
      }),
    );
  };

  /**
   * Perform a dry-run for a resource request.
   *
   * @param url - The URL of the resource to request.
   * @param method - The HTTP method to call `ajax` with.
   * @param createResourceObservable - Function to emit additional actions on success.
   *   Takes the response's resource as argument.
   * @param createErrorObservable - Function to emit actions on error.
   *   (Mainly to add headers to the resource.)
   * @param query - A dictionary of fields to filter by.
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   * @param requestBody - The resource to pass as request body (`PATCH` or `POST`).
   *
   * @returns Observable emitting ``RESOURCES_LOADED`` actions for the fetched resource and embedded resources,
   *   as well as the actions from ``createResourceObservable``.
   */
  requestResourceDryRun = <
    R extends BaseResource,
    B extends DeepPartial<BaseResource> | null = BaseResource,
    A extends Action = never,
    E extends Action = never,
  >(
    url: string,
    method: 'POST' | 'DELETE',
    createResourceObservable: (resource?: R) => Observable<A> = () => EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    query: ReadonlyRecord<string, string> = {},
    headers: HttpHeaders = {},
    requestBody?: B,
  ): Observable<A | E | UnauthorizedRequestAction> => {
    const resourceURL = new URL(normalizeURI(url));
    resourceURL.searchParams.append('dry_run', '1');
    Object.entries(query).forEach(([key, value]) => {
      resourceURL.searchParams.append(key, value);
    });
    const resourceURI = resourceURL.toString();
    this.debug(`${method} ${resourceURI}`);
    this.debug(`Request body: ${JSON.stringify(requestBody, null, 2)}`);
    return ajax<R | null>({
      url: resourceURI,
      method,
      headers: {
        ...this.getDefaultHeaders(),
        ...headers,
      },
      body: JSON.stringify(requestBody),
    }).pipe(
      mergeMap((response) => {
        this.debug(`Response: ${JSON.stringify(response, null, 2)}`);
        if (response.response === null) {
          return createResourceObservable();
        }
        return createResourceObservable(response.response);
      }),
      catchError((err) => {
        this.debug(`Error: ${JSON.stringify(err, null, 2)}`);
        if (err && err.status === 401) {
          return concat(of(unauthorizedRequestAction()), createErrorObservable(err));
        }
        return createErrorObservable(err);
      }),
    );
  };

  /**
   * Use an api resource.
   *
   * If the resource is already available in redux store the available resource will be used.
   * Otherwise it will be fetched from server.
   *
   * Notes:
   *  - Do not use this for a large amount of resources.
   *  - Resources from state might be old/outdated
   *
   * @param url - The URL of the resource to fetch.
   * @param resourceMap - The resource map possibly containing the resource.
   *
   * @param createResourceObservable - Function to emit additional actions on success.
   *   Takes the response's resource as argument.
   * @param createErrorObservable - Function to emit actions on error.
   *
   * @returns Observable emitting ``createResourceObservable`` actions for the available resource or
   *   createErrorObservable as well as the actions from ``RESOURCES_LOADED`` if resource is fetched from server.
   */
  useResource = <
    T extends string,
    R extends Resource<T> = Resource<T>,
    A extends Action = ResourceLoadedAction,
    E extends Action = ResourceLoadedAction,
  >(
    url: string,
    resourceMap: ResourceMap<R>,
    createResourceObservable: (resource?: R) => Observable<A> = () => EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
  ): Observable<ResourceLoadedAction | A | E | UnauthorizedRequestAction> => {
    const resource = getResource(resourceMap, url);
    if (resource) {
      return createResourceObservable(resource);
    }
    return this.fetchResource(url, createResourceObservable, createErrorObservable);
  };

  /**
   * Asynchronously fetch a resource.
   * Emits ``RESOURCES_LOADED`` actions for the resource and all its embedded resources.
   *
   * @param url - The URL of the resource to fetch.
   * @param createResourceObservable - Function to emit additional actions on success.
   *   Takes the response's resource as argument.
   * @param createErrorObservable - Function to emit actions on error.
   * @param createResource - Function to create a resource from the response.
   *   (Mainly to add headers to the resource.)
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   *
   * @returns Observable emitting ``RESOURCES_LOADED`` actions for the fetched resource and embedded resources,
   *   as well as the actions from ``createResourceObservable``.
   */
  fetchResource = <
    T extends string,
    R extends Resource<T> = Resource<T>,
    A extends Action = ResourceLoadedAction<Resource<string>>,
    E extends Action = ResourceLoadedAction<Resource<string>>,
  >(
    url: string,
    createResourceObservable: (resource?: R) => Observable<A> = () => EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    createResource = (response: AjaxResponse<BaseResource>) => response.response,
    headers: HttpHeaders = {},
  ): Observable<ResourceLoadedAction<Resource<string>> | A | E | UnauthorizedRequestAction> => {
    return this.requestResource(
      url,
      'GET',
      createResourceObservable,
      createErrorObservable,
      createResource,
      headers,
    );
  };

  /**
   * Asynchronously fetch resource headers.
   * Emits ``RESOURCES_LOADED`` actions for the resource headers.
   *
   * @param url - The URL of the resource whose headers to fetch.
   * @param createResourceObservable - Function to emit additional actions on success.
   *   Takes the response's resource as argument.
   * @param createErrorObservable - Function to emit actions on error.
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   *
   * @returns Observable emitting ``RESOURCES_LOADED`` actions for the fetched resource headers,
   *   as well as the actions from ``createResourceObservable``.
   */
  fetchResourceHeaders = <
    T extends string,
    R extends Resource<T> = Resource<T>,
    A extends Action = ResourceLoadedAction<Resource<string>>,
    E extends Action = ResourceLoadedAction<Resource<string>>,
  >(
    url: string,
    createResourceObservable: (resource?: R) => Observable<A> = () => EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    headers: HttpHeaders = {},
  ): Observable<ResourceLoadedAction<Resource<string>> | A | E | UnauthorizedRequestAction> => {
    return this.requestResource(
      url,
      'HEAD',
      createResourceObservable,
      createErrorObservable,
      ({ response }) => response,
      headers,
    );
  };

  /**
   * Asynchronously fetch all pages of a collection resource.
   * Emits ``RESOURCES_LOADED`` actions for the page resources and all their embedded resources.
   *
   * @param url - The URL to fetch the collection resource from.
   * @param createErrorObservable - Function to emit actions on error.
   * @param createResource - Function to create a resource from the fetched resource and request.
   *   (Mainly to add headers to the resource.)
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   *
   * @returns Observable emitting ``RESOURCES_LOADED`` actions for all fetched collection page resources
   *   and embedded resources.
   */
  fetchCollection = <E extends Action = ResourceLoadedAction<Resource<string>>>(
    url: string,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    createResource = (response: AjaxResponse<BaseResource>) => response.response,
    headers: HttpHeaders = {},
  ): Observable<ResourceLoadedAction<Resource<string>> | E | UnauthorizedRequestAction> => {
    return this.fetchResource(
      url,
      (resource?: PageResource<string>) => {
        if (!resource?._links.next) {
          return EMPTY;
        }
        return this.fetchCollection(
          resource._links.next.href,
          createErrorObservable,
          createResource,
          headers,
        );
      },
      createErrorObservable,
      createResource,
      headers,
    );
  };

  /**
   * Asynchronously ``POST`` a resource.
   * Emits ``RESOURCES_LOADED`` actions for the resource and all its embedded resources.
   *
   * @param url - The URL of the resource to POST to.
   * @param resource - The resource to POST.
   * @param createResourceObservable - Function to emit additional actions on success.
   *   Takes the response's resource as argument.
   * @param createErrorObservable - Function to emit actions on error.
   * @param version - The REST API version number.
   * @param createResource - Function to create a resource from the response.
   *   (Mainly to add headers to the resource.)
   * @param headers - HTTP headers to add to the request.
   *
   * @returns Observable emitting ``RESOURCES_LOADED`` actions for the fetched resource and embedded resources,
   *   as well as the actions from ``createResourceObservable``.
   */
  postResource = <
    R extends BaseResource,
    B extends BaseResource | null,
    A extends Action = ResourceLoadedAction<Resource<string>>,
    E extends Action = ResourceLoadedAction<Resource<string>>,
  >(
    url: string,
    resource: B,
    createResourceObservable: (resource?: R) => Observable<A | UnauthorizedRequestAction> = () =>
      EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    { version }: { version: undefined | number } = { version: undefined },
    createResource = (response: AjaxResponse<BaseResource>) => response.response,
    headers: HttpHeaders = {},
  ): Observable<ResourceLoadedAction<Resource<string>> | A | E | UnauthorizedRequestAction> => {
    return this.requestResource(
      url,
      'POST',
      createResourceObservable,
      createErrorObservable,
      createResource,
      { ...getVersionHeaders(version), ...headers },
      resource,
    );
  };

  /**
   * Perform a dry-run to ``POST`` a resource.
   *
   * @param url - The URL of the resource to POST to.
   * @param resource - The resource to POST.
   * @param createResourceObservable - Function to emit actions on success.
   * @param createErrorObservable - Function to emit actions on error.
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   * @param query - A dictionary of fields to filter by.
   *
   * @returns Observable emitting the actions from the given functions.
   */
  postResourceDryRun = <
    B extends BaseResource | null,
    A extends Action = never,
    E extends Action = never,
  >(
    url: string,
    resource: B,
    {
      createResourceObservable = () => EMPTY,
      createErrorObservable = () => EMPTY,
      headers = {},
      query = {},
    }: PostResourceOptions<A, E> = {},
  ): Observable<A | E | UnauthorizedRequestAction> => {
    return this.requestResourceDryRun(
      url,
      'POST',
      createResourceObservable,
      createErrorObservable,
      query,
      headers,
      resource,
    );
  };

  /**
   * Asynchronously ``PATCH`` a resource.
   * Emits ``RESOURCES_LOADED`` actions for the resource and all its embedded resources.
   *
   * @param url - The URL of the resource to PATCH.
   * @param resource - The partial resource, containing the fields to update.
   * @param createResourceObservable - Function to emit additional actions on success.
   *   Takes the response's resource as argument.
   * @param createErrorObservable - Function to emit actions on error.
   * @param createResource - Function to create a resource from the response.
   *   (Mainly to add headers to the resource.)
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   *
   * @returns Observable emitting ``RESOURCES_LOADED`` actions for the fetched resource and embedded resources,
   *   as well as the actions from ``createResourceObservable``.
   */
  patchResource = <
    R extends Resource<string> = Resource<string>,
    A extends Action = ResourceLoadedAction<Resource<string>>,
    E extends Action = ResourceLoadedAction<Resource<string>>,
  >(
    url: string,
    resource: DeepPartial<R>,
    createResourceObservable: (resource?: R) => Observable<A> = () => EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    createResource = (response: AjaxResponse<BaseResource>) => response.response,
    headers: HttpHeaders = {},
  ): Observable<ResourceLoadedAction<Resource<string>> | A | E | UnauthorizedRequestAction> => {
    return this.requestResource(
      url,
      'PATCH',
      createResourceObservable,
      createErrorObservable,
      createResource,
      headers,
      resource,
    );
  };

  /**
   * Asynchronously ``DELETE`` a resource.
   * Emits an ``RESOURCES_REMOVED`` action for the resource.
   *
   * @param resource - The resource to DELETE.
   * @param createResourceObservable - Function to emit additional actions on success.
   * @param createErrorObservable - Function to emit actions on error.
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   * @param query - A dictionary of fields to filter by.
   *
   * @returns Observable emitting ``RESOURCES_REMOVED`` actions for the deleted resource.
   */
  deleteResource = <
    R extends Resource<T>,
    T extends string,
    A extends Action = ResourceRemovedAction<string>,
    E extends Action = ResourceRemovedAction<string>,
  >(
    resource: R,
    {
      createResourceObservable = () => EMPTY,
      createErrorObservable = () => EMPTY,
      headers = {},
      query = {},
    }: DeleteResourceOptions<A, E> = {},
  ): Observable<
    | ResourceRemovedAction<string>
    | ResourceLoadedAction<Resource<string>>
    | A
    | E
    | UnauthorizedRequestAction
  > => {
    let resourceURI = getResourceURI(resource);
    const resourceURL = new URL(resourceURI);
    Object.entries(query).forEach(([key, value]) => {
      resourceURL.searchParams.append(key, value);
    });
    resourceURI = resourceURL.toString();
    const resourceTypeName = getResourceTypeName(resource);
    return concat(
      of(createResourcesRemovedAction(resourceTypeName, [resourceURI])),
      this.requestResource<R, null, A, ResourceLoadedAction<Resource<string>> | E>(
        resourceURI,
        'DELETE',
        createResourceObservable,
        (err) =>
          concat(
            of(createResourcesLoadedAction(resourceTypeName, [resource])),
            createErrorObservable(err),
          ),
        () => ({}),
        headers,
      ),
    );
  };

  /**
   * Perform a dry-run to ``DELETE`` a resource.
   *
   * @param resourceURI - The URI of the resource to DELETE.
   * @param createResourceObservable - Function to emit actions on success.
   * @param createErrorObservable - Function to emit actions on error.
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   * @param query - A dictionary of fields to filter by.
   *
   * @returns Observable emitting the actions from the given functions.
   */
  deleteResourceDryRun = <A extends Action = never, E extends Action = never>(
    resourceURI: string,
    {
      createResourceObservable = () => EMPTY,
      createErrorObservable = () => EMPTY,
      headers = {},
      query = {},
    }: DeleteResourceOptions<A, E> = {},
  ): Observable<A | E | UnauthorizedRequestAction> => {
    return this.requestResourceDryRun(
      resourceURI,
      'DELETE',
      createResourceObservable,
      createErrorObservable,
      query,
      headers,
    );
  };

  /**
   * Asynchronously move an item within a collection.
   * Emits ``RESOURCES_LOADED`` actions for all modified page resources of the collection.
   *
   * Used to sync the client's state with the server state after some action changing the
   * order of a collection's items.
   *
   * @param collectionURI - The URI of the collection.
   * @param collectionResourceMap - The resource map containing the collection.
   * @param itemURI - The URI of the item to move.
   * @param targetIndex - The index where the item should be moved.
   *
   * @returns An observable emitting ``RESOURCES_LOADED`` actions for all modified page resources
   *   of the collection, and an observable to undo the action by re-fetching the modified pages.
   */
  // eslint-disable-next-line class-methods-use-this
  moveItem = <P extends LinkedItemsPageResource<string>, I extends Resource<string>>(
    collectionURI: string,
    collectionResourceMap: ResourceMap<P>,
    itemsResourceMap: ResourceMap<I>,
    itemURI: string,
    targetIndex: number,
  ): MoveActions<P> => {
    // Have to ignore deleted items (see https://jira.nachtblau.tv/browse/ML-3045).
    const itemExists = ({ href }: Link) => !!getResource(itemsResourceMap, href);
    const normalizedItemURI = normalizeURI(itemURI);
    const pages = collectPages(collectionResourceMap, normalizeURI(collectionURI));
    const sourcePage = pages.find(
      (page: P) => !!page._links.item.find(({ href }) => href === normalizedItemURI),
    );
    if (!sourcePage) {
      // Item not in collection, cannot move.
      return { move$: EMPTY, undoMove$: EMPTY };
    }
    const items = pages.flatMap((page) =>
      page._links.item.filter(itemExists).map(({ href }) => href),
    );
    const sourceIndex = items.findIndex((uri) => uri === normalizedItemURI);
    if (sourceIndex === targetIndex) {
      // No change.
      return { move$: EMPTY, undoMove$: EMPTY };
    }
    const itemURIsWithoutSourceItem = items.filter((uri) => uri !== normalizedItemURI);
    const itemURIs = [
      ...itemURIsWithoutSourceItem.slice(0, targetIndex),
      normalizedItemURI,
      ...itemURIsWithoutSourceItem.slice(targetIndex),
    ];
    let offset = 0;
    const pagesWithOffsets = pages.map((page) => {
      const result = {
        start: offset,
        end: offset + page._links.item.filter(itemExists).length,
        page,
      };
      offset += page._links.item.filter(itemExists).length;
      return result;
    });
    const modifiedPages = pagesWithOffsets
      .filter(
        // Exclude all pages which are outside of the range where the move happens.
        ({ start, end }) =>
          !(
            (start > sourceIndex && start > targetIndex) ||
            (end <= sourceIndex && end <= targetIndex)
          ),
      )
      .map(({ start, end, page }) => {
        const pageItemURIs = itemURIs.slice(start, end);
        return {
          ...page,
          _links: {
            ...page._links,
            item: pageItemURIs.map((href) => ({ href })),
          },
        };
      });
    const modifiedPageURIs = new Set(modifiedPages.map(getResourceURI));
    return {
      move$: of(createResourcesLoadedAction(collectionResourceMap.resourceTypeName, modifiedPages)),
      undoMove$: of(
        createResourcesLoadedAction(
          collectionResourceMap.resourceTypeName,
          pages.filter((page) => modifiedPageURIs.has(getResourceURI(page))),
        ),
      ),
    };
  };

  /**
   * Asynchronously append items to a collection.
   * Emits a ``RESOURCES_LOADED`` actions for the modified page resource of the collection.
   *
   * Used to sync the client's state with the server state after some action creating a
   * resource in a collection.
   *
   * @param collectionURI - The URI of the collection.
   * @param collectionResourceMap - The resource map containing the collection.
   * @param itemURIs - The URIs of the item to append.
   *
   * @returns An observable emitting a ``RESOURCES_LOADED`` action for the modified page.
   */
  // eslint-disable-next-line class-methods-use-this
  addItemsToCollection = <P extends LinkedItemsPageResource<string>>(
    collectionURI: string,
    collectionResourceMap: ResourceMap<P>,
    itemURIs: ReadonlyArray<string>,
  ): Observable<ResourceLoadedAction<P>> => {
    if (itemURIs.length === 0) {
      return EMPTY;
    }
    const normalizedItemURIs = itemURIs.map(normalizeURI);
    const pages = collectPages(collectionResourceMap, normalizeURI(collectionURI));
    if (pages.length === 0) {
      return EMPTY;
    }
    const page = pages[pages.length - 1];
    return of(
      createResourcesLoadedAction(collectionResourceMap.resourceTypeName, [
        {
          ...page,
          _links: {
            ...page._links,
            item: [...page._links.item, ...normalizedItemURIs.map((href) => ({ href }))],
          },
        },
      ]),
    );
  };

  /**
   * Asynchronously request resource options.
   * Emits ``RESOURCES_OPTIONS_LOADED`` action for the URL.
   *
   * @param url - The URL of the resource to request.
   * @param createOptionsObservable - Function to emit additional actions on success.
   *   Takes the response as argument.
   * @param createErrorObservable - Function to emit actions on error.
   * @param headers - A dictionary of headers to overwrite `getDefaultHeaders()`.
   *
   * @returns Observable emitting ``RESOURCES_OPTIONS_LOADED`` actions for the fetched resource
   *   options as well as the actions from ``createOptionsObservable``.
   */
  fetchOptions = <A extends Action = never, E extends Action = never>(
    url: string,
    createOptionsObservable: (response: AjaxResponse<ResourceOptions>) => Observable<A> = () =>
      EMPTY,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
    headers: HttpHeaders = {},
  ): Observable<ResourceOptionsLoadedAction<string> | A | E | UnauthorizedRequestAction> => {
    return ajax<ResourceOptions>({
      url,
      method: 'OPTIONS',
      headers: {
        ...this.getDefaultHeaders(),
        ...headers,
      },
    }).pipe(
      mergeMap((response) => {
        this.debug(`OPTIONS ${url}`);
        this.debug(`Response: ${JSON.stringify(response, null, 2)}`);
        const { type } = parseLinkHeader(response.xhr.getResponseHeader('Link'));
        // TODO: Get type name from header, as soon as it is supported.
        const typeName = type ? toTypeName(type) : null;
        if (!typeName || !this.handledResourceTypeNames.has(typeName)) {
          return EMPTY;
        }
        return concat(
          of(createResourceOptionsLoadedAction(typeName, normalizeURI(url), response.response)),
          createOptionsObservable(response),
        );
      }),
      catchError((err) => {
        this.debug(`OPTIONS ${url}`);
        this.debug(`Error: ${JSON.stringify(err, null, 2)}`);
        if (err && err.status === 401) {
          return concat(of(unauthorizedRequestAction()), createErrorObservable(err));
        }
        return createErrorObservable(err);
      }),
    );
  };

  /**
   * Asynchronously request resource options for a specific method and payload.
   *
   * @param url - The URL of the resource to request.
   * @param method - The request method to check.
   * @param resource - Partial resource to include in the request body.
   * @param createErrorObservable - Function to emit actions on error.
   *
   * @returns Observable emitting ``ResourceOptions``.
   */
  fetchOptionsFor = <R extends BaseResource, E = never>(
    url: string,
    method: string,
    resource: R,
    createErrorObservable: (err: RESTError) => Observable<E> = () => EMPTY,
  ): Observable<ResourceOptionsV3 | E | UnauthorizedRequestAction> => {
    return ajax<ResourceOptionsV3>({
      url,
      method: 'OPTIONS',
      body: JSON.stringify({ method, data: resource }),
      headers: {
        ...this.getDefaultHeaders(),
        Accept: 'application/hal+json; version=3',
        'Content-Type': 'application/hal+json; version=3',
      },
    }).pipe(
      map(({ response }) => {
        this.debug(`OPTIONS ${url}`);
        this.debug(`Request Method: ${method}`);
        this.debug(`Request Data: ${resource}`);
        this.debug(`Response: ${JSON.stringify(response, null, 2)}`);
        return response;
      }),
      catchError((err) => {
        this.debug(`OPTIONS ${url}`);
        this.debug(`Error: ${JSON.stringify(err, null, 2)}`);
        if (err && err.status === 401) {
          return concat(of(unauthorizedRequestAction()), createErrorObservable(err));
        }
        return createErrorObservable(err);
      }),
    );
  };
}

/**
 * Create dependencies for use with ``createEpicMiddleware``.
 *
 * @param state - The root state.
 *
 * @param getExtraHeaders - A function that returns headers to overwrite the default class headers.
 *
 * @returns the REST dependencies to pass to ``createEpicMiddleware``.
 */
export const createRESTEpicDependencies = (
  state: unknown,
  getExtraHeaders: () => HttpHeaders,
): RESTEpicDependencies => {
  return new RESTEpicDependencies(state, getExtraHeaders);
};

export const createFetchCollectionOptionsEpic =
  (
    relationType: string,
    { version }: { version: undefined | number } = { version: undefined },
  ): Epic<
    Action,
    ResourceOptionsLoadedAction<string> | UnauthorizedRequestAction,
    unknown,
    RESTEpicDependencies
  > =>
  (action$: Observable<Action>, _state$: unknown, { fetchOptions }: RESTEpicDependencies) =>
    action$.pipe(
      ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
      mergeMap(({ payload: { root } }) => {
        let href = getLinkHref(root, relationType);
        if (!href) {
          href = getLinkHref(root, mlRel(relationType));
        }
        if (!href) {
          return EMPTY;
        }
        return fetchOptions(href, undefined, undefined, getVersionHeaders(version));
      }),
    );

export const createFetchResourceEpic =
  (
    relationType: string,
    { version }: { version: undefined | number } = { version: undefined },
  ): Epic<
    Action,
    ResourceLoadedAction<Resource<string>> | UnauthorizedRequestAction,
    unknown,
    RESTEpicDependencies
  > =>
  (action$: Observable<Action>, _state$: unknown, { fetchResource }: RESTEpicDependencies) =>
    action$.pipe(
      ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
      mergeMap(({ payload: { root } }) => {
        const href = getLinkHref(root, relationType);
        if (!href) {
          return EMPTY;
        }
        return fetchResource(href, undefined, undefined, undefined, getVersionHeaders(version));
      }),
    );

export const createFetchResourceHeadersEpic =
  (
    relationType: string,
    { version }: { version: undefined | number } = { version: undefined },
  ): Epic<
    Action,
    ResourceLoadedAction<Resource<string>> | UnauthorizedRequestAction,
    unknown,
    RESTEpicDependencies
  > =>
  (action$: Observable<Action>, _state$: unknown, { fetchResourceHeaders }: RESTEpicDependencies) =>
    action$.pipe(
      ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
      mergeMap(({ payload: { root } }) => {
        const href = getLinkHref(root, relationType);
        if (!href) {
          return EMPTY;
        }
        return fetchResourceHeaders(href, undefined, undefined, getVersionHeaders(version));
      }),
    );

export const createFetchCollectionEpic =
  (
    relationType: string,
    { version }: { version: undefined | number } = { version: undefined },
  ): Epic<
    Action,
    ResourceLoadedAction<Resource<string>> | UnauthorizedRequestAction,
    unknown,
    RESTEpicDependencies
  > =>
  (action$: Observable<Action>, _state$: unknown, { fetchCollection }: RESTEpicDependencies) =>
    action$.pipe(
      ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
      mergeMap(({ payload: { root } }) => {
        const href = getLinkHref(root, relationType);
        if (!href) {
          return EMPTY;
        }
        return fetchCollection(href, undefined, undefined, getVersionHeaders(version));
      }),
    );

/**
 * Create an observable emitting the non-null value of a given selector whenever a given action is dispatched.
 *
 * If an action is dispatched before a value is available, a value will be emitted as soon as it is
 * available.
 *
 * A common use-case is fetching a resource whenever some "fetch" action is dispatched, even before the URL
 * is present in the Redux store.
 *
 * @param type - The type of the action triggering emission of a value.
 * @param selector - A selector returning the current value. May return `null` when no value is available yet.
 * @param action$ - The actions stream.
 * @param state$ - The state stream.
 *
 * @returns an observable emitting a value from a selector whenever a given action is dispatched.
 */
export const createTriggeredSelectorObservable = <A extends Action, V, S>(
  type: A['type'],
  selector: (state: S) => V | null | undefined,
  action$: Observable<Action>,
  state$: Observable<S>,
): Observable<[A, V]> => {
  const value$ = state$.pipe(
    map(selector),
    filter((url): url is NonNullable<V> => !!url),
    distinctUntilChanged(),
  );
  const fetchAction$ = action$.pipe(ofType<Action, A['type'], A>(type));
  return merge(
    combineLatest([fetchAction$, value$]).pipe(take(1)),
    fetchAction$.pipe(skip(1), withLatestFrom(value$)),
  );
};
