import { Action } from 'redux';
import { ofType, combineEpics, Epic } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import {
  UnauthorizedRequestAction,
  ResourceLoadedAction,
  RESTEpicDependencies,
} from 'medialoopster/rest';

import { URL_PRODUCTIONS } from '../../constants';
import { RootState } from '../../types/rootState';
import { FETCH_PRODUCTIONS } from './types';

export const fetchProductionsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { fetchCollection }) =>
  action$.pipe(
    ofType(FETCH_PRODUCTIONS),
    switchMap(() => fetchCollection(URL_PRODUCTIONS)),
  );

export default combineEpics(fetchProductionsEpic);
