import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { personsSelectors } from '../../../state/modules/persons';
import { BoundingBox } from '../../../state/modules/persons/types';
import { RootState } from '../../../state/types/rootState';

const getBoundingBoxesAtFrame = createSelector(
  (_: RootState, currentFrame: number) => currentFrame,
  (state: RootState) =>
    personsSelectors.isPersonsVisible(state) ? personsSelectors.getActivePersonURI(state) : null,
  personsSelectors.isAllBoundingBoxesVisible,
  personsSelectors.getBoundingBoxesByFrame,
  (frame, personURI, isVisible, frameNoToBoundingBoxes): ReadonlyArray<BoundingBox> =>
    (frameNoToBoundingBoxes[frame] || []).filter((box) =>
      personURI ? box.personURI === personURI : isVisible,
    ),
);

export default (frame: number): ReadonlyArray<BoundingBox> => {
  return useSelector<RootState, ReadonlyArray<BoundingBox>>((state) =>
    getBoundingBoxesAtFrame(state, frame),
  );
};
