import { TypographyProps } from '@mui/material/Typography';
import { ReactNode, FC } from 'react';

import Field from './Field';

type Props = {
  readonly label: string;
  readonly children: ReactNode;
  readonly visible: boolean;
  readonly classes?: TypographyProps['classes'];
  readonly testId?: string;
};

const AssetMetadataField: FC<Props> = ({
  label,
  children,
  visible,
  classes = undefined,
  testId = undefined,
}: Props) => (
  <Field
    label={label}
    visible={visible}
    variant="body2"
    labelAndDisplayOnSameLine
    classes={classes}
    testId={testId}
  >
    {children}
  </Field>
);

export default AssetMetadataField;
