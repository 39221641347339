import makeStyles from '@mui/styles/makeStyles';
import { FC, useRef, useMemo } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import DeletedFavoriteItem from '../FavoriteListItem/DeletedFavoriteItem';
import FavoriteItem from '../FavoriteListItem/FavoriteItem';
import MultipleFavoriteItems from './MultipleFavoriteItems';

const useStyles = makeStyles(() => ({
  dragLayer: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  dragItem: {
    display: 'inline-block',
  },
}));

const getItemStyles = (clientOffset: XYCoord | null, itemElement: Element | null) => {
  if (!clientOffset || !itemElement) {
    return {
      display: 'none',
    };
  }
  const { x, y } = clientOffset;
  const rect = itemElement.getBoundingClientRect();
  const transform = `translate(${x - rect.width / 2}px, ${y - rect.height / 2}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};
type FavoriteItemsDragLayerProps = {
  readonly deleteDraggedItem: boolean;
  readonly cssClasses: {
    readonly favoriteItemContent: string;
    readonly favoriteItemDetail: string;
    readonly favoriteItemDecreasedOpacity: string;
  };
};

// applies to all draggable items
const FavoriteItemsDragLayer: FC<FavoriteItemsDragLayerProps> = ({
  deleteDraggedItem,
  cssClasses,
}: FavoriteItemsDragLayerProps) => {
  const classes = useStyles();
  const ref = useRef(null);
  const { isDragging, item, clientOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const itemStyles = useMemo(() => getItemStyles(clientOffset, ref.current), [clientOffset, ref]);
  const renderedItem = useMemo(() => {
    if (!isDragging) {
      return null;
    }
    switch (item?.type) {
      case 'asset-selection':
        return (
          <div className={classes.dragItem} ref={ref}>
            <MultipleFavoriteItems count={item.assetIds.length} cssClasses={cssClasses} />
          </div>
        );
      case 'favoriteitem':
      case 'videoasset':
      case 'imageasset':
      case 'audioasset':
      case 'shot':
      case 'pseudoshot':
      case 'sequence':
        return (
          <div
            className={classes.dragItem}
            ref={ref}
            style={deleteDraggedItem ? { backgroundColor: 'red' } : {}}
          >
            {item.deleted ? (
              <DeletedFavoriteItem displayName={item.displayName} detail={item.detail} />
            ) : (
              <FavoriteItem
                displayName={item.displayName}
                thumbnailURL={item.thumbnailURL}
                detail={item.detail}
                assetIsAvailable={item.assetIsAvailable}
                cssClasses={cssClasses}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  }, [item, isDragging, classes.dragItem, cssClasses, deleteDraggedItem]);
  if (!isDragging) {
    return null;
  }
  return (
    <div className={classes.dragLayer} data-testid="favorite-items-drag-layer">
      <div style={itemStyles}>{renderedItem}</div>
    </div>
  );
};

export default FavoriteItemsDragLayer;
