import { createSelector } from 'reselect';

import { gettext } from 'medialoopster/Internationalization';
import { AUDIO_TYPE, IMAGE_TYPE, VIDEO_TYPE } from 'medialoopster/modules';
import {
  APIError,
  CollectionResourceMap,
  getLink,
  getLinkHref,
  getLinkTargetTypes,
  getResourceOptions,
  getResourceTypeName,
  Link,
  mlRel,
  ResourceOptionsV2,
  toTypeName,
} from 'medialoopster/rest';

import { LegacyAssetType } from '../../types/asset/baseTypes';
import { RootState } from '../../types/rootState';
import { detailsSelectors } from '../details';
import { operationsSelectors } from '../operations';
import { AssetChoice, AssetChoiceWithError } from '../operations/types';
import { AssetHighresDownloadActivity, AssetProxyDownloadActivity } from './types';

export const getProxyDownloadActivitiesURL = (state: RootState): string | null =>
  state.download.proxyDownloadActivitiesLink?.href || null;

export const getAssetProxyDownloadActivitiesLink = (state: RootState): Link | null =>
  state.download.assetProxyDownloadActivitiesLink;

export const getAssetProxyDownloadActivitiesURL = (state: RootState): string | null =>
  state.download.assetProxyDownloadActivitiesLink?.href || null;

export const getSingleMediaProxyDownloadLink = createSelector(
  detailsSelectors.getCurrentAsset,
  getAssetProxyDownloadActivitiesLink,
  (currentAsset, videoDownloadLink) => {
    const assetType = currentAsset ? getResourceTypeName(currentAsset) : null;
    if (assetType === VIDEO_TYPE) {
      return videoDownloadLink;
    }
    if (currentAsset && assetType && [IMAGE_TYPE, AUDIO_TYPE].includes(assetType)) {
      return getLink(currentAsset, mlRel('download-proxy')) || null;
    }
    return null;
  },
);

export const getSingleMediaProxyDownloadURL = createSelector(
  getSingleMediaProxyDownloadLink,
  (downloadLink) => downloadLink?.href || null,
);

export const getHighresDownloadActivitiesURL = (state: RootState): string | null =>
  state.download.highresDownloadActivitiesLink?.href || null;

export const getAllowedDownloadAssetTypes = (
  downloadActivitiesURL: string | null,
  downloadActivityCollections: CollectionResourceMap<
    AssetHighresDownloadActivity | AssetProxyDownloadActivity,
    ResourceOptionsV2
  >,
): ReadonlyArray<LegacyAssetType> => {
  if (!downloadActivitiesURL) {
    return [];
  }
  const options = getResourceOptions(downloadActivityCollections, downloadActivitiesURL);
  if (!options) {
    return [];
  }
  return getLinkTargetTypes(options, mlRel('asset'), 'POST').map(
    (value) => toTypeName(value) as LegacyAssetType,
  );
};

export const getAllowedProxyDownloadAssetTypes = createSelector(
  getProxyDownloadActivitiesURL,
  (state: RootState) => state.download.proxyDownloadActivityCollections,
  getAllowedDownloadAssetTypes,
);

export const getAllowedProxyDownloadAssetChoices = createSelector(
  operationsSelectors.getAssetChoices,
  getAllowedProxyDownloadAssetTypes,
  (assetChoices, allowedTypes): ReadonlyArray<AssetChoice> =>
    assetChoices.filter(({ typeName }) => allowedTypes.includes(typeName)),
);

/**
 * The list of asset types allowed to be downloaded in the current API.
 */
export const getAllowedHighresDownloadAssetTypes = createSelector(
  getHighresDownloadActivitiesURL,
  (state: RootState) => state.download.highresDownloadActivityCollections,
  getAllowedDownloadAssetTypes,
);

/**
 * The sublist of asset choices whose type is allowed to be downloaded in the current API.
 */
export const getAllowedHighresDownloadAssetChoices = createSelector(
  operationsSelectors.getAssetChoices,
  getAllowedHighresDownloadAssetTypes,
  (assetChoices, allowedTypes): ReadonlyArray<AssetChoice> =>
    assetChoices.filter(({ typeName }) => allowedTypes.includes(typeName)),
);

/**
 * The list of 1 element collection choice.
 * It is not empty, if the collection type is in the list of asset types allowed for download in the current API.
 *
 * TODO: update after ML-3667 and ML-3513 are implemented.
 */
export const getAllowedHighresDownloadCollectionChoice = createSelector(
  operationsSelectors.getCollectionChoice,
  getAllowedHighresDownloadAssetTypes,
  detailsSelectors.isCurrentCollectionWithoutFiles,
  (collectionChoice, allowedTypes, isNoFilesCollection): AssetChoiceWithError | null => {
    if (allowedTypes.includes('projectasset') && collectionChoice)
      return isNoFilesCollection
        ? { ...collectionChoice, error: gettext('Collection has no files') }
        : collectionChoice;
    return null;
  },
);

export const getAllowedHighresDownloadCollectionChoiceArray = createSelector(
  getAllowedHighresDownloadCollectionChoice,
  (allowedCollectionChoice): ReadonlyArray<AssetChoice> =>
    allowedCollectionChoice ? [allowedCollectionChoice] : [],
);

export const getAllowedHighresDownloadChoices = createSelector(
  operationsSelectors.isOnlyCurrentAssetCollectionSelected,
  getAllowedHighresDownloadCollectionChoiceArray,
  getAllowedHighresDownloadAssetChoices,
  (
    isCurrentAssetCollection,
    allowedCollectionChoiceArray,
    allowedAssetChoices,
  ): ReadonlyArray<AssetChoice> =>
    isCurrentAssetCollection ? allowedCollectionChoiceArray : allowedAssetChoices,
);

export const isLoadingForDownload = createSelector(
  operationsSelectors.isOnlyCurrentAssetCollectionSelected,
  detailsSelectors.isCurrentCollectionLoading,
  (isCurrentAssetCollection, isCollectionLoading): boolean =>
    isCurrentAssetCollection ? isCollectionLoading : false,
);
export const getIsSingleMediaProxyDownloadAvailable = (state: RootState): boolean =>
  state.download.currentAssetDownload.canSeeCurrenAssetProxyDownload;

export const getCurrentAssetProxyDownloadDryRunErrors = createSelector(
  (state: RootState) => state.download.currentAssetDownload.dryRunErrors,
  (errors: ReadonlyArray<Pick<APIError, 'detail'>>): ReadonlyArray<string> =>
    errors.map(({ detail }) => detail),
);

export const getCurrentAssetProxyDownloadErrors = createSelector(
  (state: RootState) => state.download.currentAssetDownload.currentActivity?.status,
  (state: RootState) => state.download.currentAssetDownload.errors,
  (
    status: number | undefined,
    errors: ReadonlyArray<Pick<APIError, 'detail'>>,
  ): ReadonlyArray<string> => {
    if (status === 3) {
      return [gettext('Failed to prepare download')];
    }
    return errors.map(({ detail }) => detail);
  },
);

export const getCurrentAssetProxyDownloadMonitoringHref = (state: RootState): string | undefined =>
  state.download.currentAssetDownload.currentActivity
    ? getLinkHref(state.download.currentAssetDownload.currentActivity, 'monitoring_url')
    : undefined;

export const getCurrentAssetProxyDownloadHref = (state: RootState): string | undefined =>
  state.download.currentAssetDownload.currentActivity?.download_url ?? undefined;

export const getIsCurrentAssetProxyDownloadReady = (state: RootState): boolean =>
  state.download.currentAssetDownload.currentActivity?.download_ready || false;
