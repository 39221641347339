import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { Remove, HD, SD, FourK, EightK, NA } from 'medialoopster/icons';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { isMediaAsset, isVideoAsset } from '../../businessRules/models/asset/utils';
import { detailsSelectors, detailsActions, detailsTypes } from '../../state/modules/details';
import { isAssetCollection } from '../../state/modules/rest/collection/types';
import { searchSelectors } from '../../state/modules/search';
import { Asset } from '../../state/types/asset/unionTypes';
import AssetMetadataField from './AssetFields/AssetMetadataField';
import CalendarDate from './AssetFields/CalendarDate';
import CalendarDateTime from './AssetFields/CalendarDateTime';
import Duration from './AssetFields/Duration';
import FieldGroup from './AssetFields/FieldGroup';
import Production from './AssetFields/Production';
import Text from './AssetFields/Text';
import TextArea from './AssetFields/TextArea';

type Props = {
  readonly asset: Asset;
};

const renderVideoFormat = (format: string, size: string) => {
  if (format === '--')
    return (
      <>
        <Remove style={{ marginBottom: '-0.4rem' }} /> ({size})
      </>
    );
  if (format === 'HD')
    return (
      <>
        <HD style={{ marginBottom: '-0.4rem' }} /> ({size})
      </>
    );
  if (format === 'SD')
    return (
      <>
        <SD style={{ marginBottom: '-0.4rem' }} /> ({size})
      </>
    );
  if (format === '4K')
    return (
      <>
        <FourK style={{ marginBottom: '-0.4rem' }} /> ({size})
      </>
    );
  if (format === '8K')
    return (
      <>
        <EightK style={{ marginBottom: '-0.4rem' }} /> ({size})
      </>
    );
  if (format === 'N/A')
    return (
      <>
        <NA style={{ marginBottom: '-0.4rem' }} /> ({size})
      </>
    );
  return <></>;
};

const AssetMetadata: FC<Props> = ({ asset }: Props) => {
  const highlights = useSelector(searchSelectors.getHighlights);
  const assetHighlights = highlights[getResourceURI(asset)];
  const isEditMode = useSelector(detailsSelectors.isEditMode);
  const dispatch = useDispatch();

  const onSubmitEdit = useCallback(
    (
        fieldKey:
          | keyof detailsTypes.AudioEditFields
          | keyof detailsTypes.ImageEditFields
          | keyof detailsTypes.VideoEditFields
          | keyof detailsTypes.CollectionEditFields,
      ) =>
      (value: string | null) => {
        const resourceURI = getResourceURI(asset);
        dispatch(
          detailsActions.editAsset(getResourceTypeName(asset), resourceURI, { [fieldKey]: value }),
        );
      },
    [asset, dispatch],
  );
  return (
    <>
      <FieldGroup key="metadata1">
        <AssetMetadataField
          key="production"
          label={gettext('Production')}
          visible
          testId="production"
        >
          <Production asset={asset} />
        </AssetMetadataField>
        <AssetMetadataField
          key="date_add"
          label={gettext('Added')}
          visible={!!asset.date_add}
          testId="date_add"
        >
          <CalendarDateTime value={asset.date_add} />
        </AssetMetadataField>
        <AssetMetadataField
          key="date_mod"
          label={gettext('Last modified')}
          visible={!!asset.date_mod}
          testId="date_mod"
        >
          <CalendarDateTime value={asset.date_mod} />
        </AssetMetadataField>
        <AssetMetadataField
          key="date_del"
          label={gettext('On hold until')}
          visible={!!asset.date_del || isEditMode}
          testId="date_del"
        >
          <CalendarDate
            value={asset.date_del}
            canEdit={isEditMode}
            onSubmit={onSubmitEdit('date_del')}
            submitFormat="yyyy-MM-dd"
            disablePast
          />
        </AssetMetadataField>
      </FieldGroup>
      <FieldGroup key="metadata2">
        {isMediaAsset(asset) && (
          <AssetMetadataField key="filename" label={gettext('Filename')} visible testId="filename">
            <Text value={asset.filename} />
          </AssetMetadataField>
        )}
        {isVideoAsset(asset) && (
          <>
            <AssetMetadataField
              key="duration"
              label={gettext('Duration')}
              visible
              testId="duration"
            >
              <Duration value={asset.duration} fps={asset.fps} />
            </AssetMetadataField>
            <AssetMetadataField
              key="video_format"
              label={gettext('Video format')}
              visible={!!asset.video_format && !!asset.video_size}
              testId="video_format"
            >
              {renderVideoFormat(asset.video_format, asset.video_size)}
            </AssetMetadataField>
            <AssetMetadataField
              key="aspect_ratio"
              label={gettext('Display aspect ratio')}
              visible={!!asset.aspect_ratio}
              testId="aspect_ratio"
            >
              <Text value={asset.aspect_ratio} />
            </AssetMetadataField>
            <AssetMetadataField
              key="video_codec"
              label={gettext('Video coding format')}
              visible={!!asset.video_codec}
              testId="video_codec"
            >
              <Text value={asset.video_codec} />
            </AssetMetadataField>
          </>
        )}
      </FieldGroup>
      <FieldGroup key="metadata3">
        {!isAssetCollection(asset) && (
          <AssetMetadataField
            key="date_crt"
            label={gettext('Originated')}
            visible={!!asset.date_crt || isEditMode}
            testId="date_crt"
          >
            <CalendarDateTime
              value={asset.date_crt}
              canEdit={isEditMode}
              onSubmit={onSubmitEdit('date_crt')}
            />
          </AssetMetadataField>
        )}
        {(isAssetCollection(asset) || isVideoAsset(asset)) && (
          <AssetMetadataField
            key="description"
            label={gettext('Description')}
            visible={!!asset.description || isEditMode}
            testId="description"
          >
            <TextArea
              value={asset.description}
              highlights={assetHighlights?.fields?.description}
              canEdit={isEditMode}
              onSubmit={onSubmitEdit('description')}
            />
          </AssetMetadataField>
        )}
        {isVideoAsset(asset) && (
          <>
            <AssetMetadataField
              key="abstract"
              label={gettext('Abstract')}
              visible={!!asset.abstract || isEditMode}
              testId="abstract"
            >
              <TextArea
                value={asset.abstract}
                highlights={assetHighlights?.fields?.abstract}
                canEdit={isEditMode}
                onSubmit={onSubmitEdit('abstract')}
              />
            </AssetMetadataField>
            <AssetMetadataField
              key="transcript"
              label={gettext('Transcript')}
              visible={!!asset.transcript || isEditMode}
              testId="transcript"
            >
              <TextArea
                value={asset.transcript}
                highlights={assetHighlights?.fields?.transcript}
                canEdit={isEditMode}
                onSubmit={onSubmitEdit('transcript')}
              />
            </AssetMetadataField>
          </>
        )}
      </FieldGroup>
    </>
  );
};
export default AssetMetadata;
