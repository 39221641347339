/* eslint-disable no-underscore-dangle */

/**
 * The module implements a custom paragraph node for Transcripts
 */
import { EditorConfig, LexicalNode, ParagraphNode } from 'lexical';

import { PARAGRAPH_CLASS_NAME } from '../css';

/**
 * A custom paragraph node for transcripts.
 *
 * TranscriptParagraphNode is meant to replace the standard ParagraphNode. In contrast to
 * ParagraphNode the DOM Element is marked with a css class to facilitate styling the editor.
 */
export class TranscriptParagraphNode extends ParagraphNode {
  static getType(): string {
    return 'transcript-paragraph';
  }

  static clone(node: TranscriptParagraphNode): TranscriptParagraphNode {
    return new TranscriptParagraphNode(node.__key);
  }

  /*
  Usual createDOM implementation that marks the DOM element with a class for styling
   */
  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = PARAGRAPH_CLASS_NAME;
    return dom;
  }
}

/**
 * Create a new TranscriptParagraphNode.
 */
export const $createTranscriptParagraphNode = (): TranscriptParagraphNode =>
  new TranscriptParagraphNode();

/**
 * Type predicate for TranscriptParagraphNodes.
 * @param node The node to check.
 */
export const $isTranscriptParagraphNode = (
  node: LexicalNode | null | undefined,
): node is TranscriptParagraphNode => node instanceof TranscriptParagraphNode;
