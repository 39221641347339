import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { detailsActions, detailsSelectors } from '../../../state/modules/details';
import { isAssetCollection } from '../../../state/modules/rest/collection/types';
import { searchSelectors } from '../../../state/modules/search';
import { Asset } from '../../../state/types/asset/unionTypes';
import FieldGroup from '../AssetFields/FieldGroup';
import CustomMetadataField from './CustomMetadataField';

type Props = {
  readonly asset: Asset;
};

const CustomAssetMetadata: FC<Props> = ({ asset }: Props) => {
  const dispatch = useDispatch();
  const customMetadataSets = useSelector(detailsSelectors.getCustomMetadataSets);
  // NOTE: Collection resources contain the custom metadata for the projectasset type.
  const assetType = isAssetCollection(asset) ? 'projectasset' : getResourceTypeName(asset);
  const highlights = useSelector(searchSelectors.getHighlights);
  const assetHighlights = highlights[getResourceURI(asset)] || { fields: {} };
  const isEditMode = useSelector(detailsSelectors.isEditMode);
  const metaFieldStore = isAssetCollection(asset)
    ? // eslint-disable-next-line no-underscore-dangle
      asset._embedded.meta_field_store
    : asset.meta_field_store;

  const onSubmitEdit = useCallback(
    (metaFieldKey: string, value: string | null) => {
      const resourceURI = getResourceURI(asset);
      dispatch(
        detailsActions.editAsset(
          getResourceTypeName(asset),
          resourceURI,
          isAssetCollection(asset)
            ? {
                _embedded: {
                  meta_field_store: { [metaFieldKey]: value },
                },
              }
            : {
                meta_field_store: { [metaFieldKey]: value },
              },
        ),
      );
    },
    [asset, dispatch],
  );
  return (
    <>
      {customMetadataSets
        .filter((customMetadataSet) => customMetadataSet.model_name === assetType)
        .map((customMetadataSet) => {
          const visibleFields = customMetadataSet.fields.filter(
            (field) => metaFieldStore[field.key] || isEditMode,
          );
          if (visibleFields.length === 0) {
            return null;
          }
          return (
            <FieldGroup key={getResourceURI(customMetadataSet)} title={customMetadataSet.name}>
              {visibleFields.map((field) => (
                <CustomMetadataField
                  key={field.key}
                  field={field}
                  asset={asset}
                  assetHighlights={assetHighlights}
                  isEditMode={isEditMode}
                  onSubmit={onSubmitEdit}
                />
              ))}
            </FieldGroup>
          );
        })}
    </>
  );
};
export default CustomAssetMetadata;
