import { FC } from 'react';

import { gettext } from '../Internationalization';
import InternalTextField, { InputElementProps } from './internal/InternalTextField';

export type Props = Omit<InputElementProps, 'select' | 'defaultValue' | 'children'>;

export const EmailInput: FC<Props> = ({
  id,
  onChange,
  name = 'email',
  label = gettext('Email Address'),
  required = true,
  autoFocus = false,
}: Props) => {
  return (
    <InternalTextField
      id={id}
      onChange={onChange}
      name={name}
      label={label}
      required={required}
      autoFocus={autoFocus}
      type={'email'}
      autoComplete={'email'}
      children={undefined}
      defaultValue={undefined}
      select={false}
    />
  );
};

export default EmailInput;
