import { APIError, APIFieldError, BaseResource } from 'medialoopster/rest';

export interface AssetCollectionResource extends BaseResource {
  readonly production: number;
  readonly name: string;
  readonly audio_assets: ReadonlyArray<number>;
  readonly image_assets: ReadonlyArray<number>;
  readonly video_assets: ReadonlyArray<number>;
}

export const ASSET_COLLECTION_CREATED_RESULT = 'FRONTEND/COLLECTION/COLLECTION_CREATED_RESULT';
export const CREATE_ASSET_COLLECTION = 'FRONTEND/COLLECTION/CREATE_ASSET_COLLECTION';

export interface CreateAssetCollection {
  readonly type: typeof CREATE_ASSET_COLLECTION;
  readonly payload: {
    readonly productionId: number;
    readonly name: string;
    readonly audioAssetIds: ReadonlyArray<number>;
    readonly imageAssetIds: ReadonlyArray<number>;
    readonly videoAssetIds: ReadonlyArray<number>;
  };
}

export interface AssetCollectionCreatedResult {
  readonly type: typeof ASSET_COLLECTION_CREATED_RESULT;
  readonly payload: {
    readonly errors?: ReadonlyArray<APIError | APIFieldError>;
    readonly assetCollectionHref?: string;
  };
}

export interface CreatedAssetCollectionState {
  readonly assetCollectionHref?: string;
  readonly errors?: ReadonlyArray<APIError | APIFieldError>;
}

export type CreateAssetCollectionModalRootState = {
  readonly createdAssetCollection: CreatedAssetCollectionState;
};
