import { Stack, Switch, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';

import { setShowAllAnnotations } from '../../../../state/modules/details/actions';
import {
  getCurrentAssetHasAnnotations,
  getShowAllAnnotations,
} from '../../../../state/modules/details/selectors';

/**
 * A switch to control if all or only owned annotations are shown.
 *
 * The component is responsible to show the current state of the filter as well
 * as to control the state of the filter via actions and the store.
 *
 * The switch is only controllable by the user if the current Asset has annotations. Otherwise,
 * it is disabled and fixed in the active state.
 *
 * @constructor
 */
const ShowAllSwitch: FC = () => {
  const showAllAnnotations = useSelector(getShowAllAnnotations);
  const assetHasAnnotations = useSelector(getCurrentAssetHasAnnotations);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (event) => dispatch(setShowAllAnnotations(event.target.checked)),
    [dispatch],
  );

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Typography variant="caption">{gettext('Show all')}</Typography>
      <Switch
        size="small"
        disabled={!assetHasAnnotations}
        checked={showAllAnnotations}
        onChange={onChange}
      />
    </Stack>
  );
};

export default ShowAllSwitch;
