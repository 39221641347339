import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Fab, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Article } from 'medialoopster/icons';

import { SAVE_TRANSCRIPT } from '../commands';

export const SaveButton: React.FC = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <Fab
      size="medium"
      variant="extended"
      color="primary"
      onClick={() => editor.dispatchCommand(SAVE_TRANSCRIPT, null)}
      sx={{
        position: 'absolute',
        bottom: 16,
        right: 16,
      }}
    >
      <Stack direction="row" spacing={2} justifyContent="space-around" alignItems="center">
        <Article />
        <Typography variant="button">{gettext('Save changes')}</Typography>
      </Stack>
    </Fab>
  );
};

export default SaveButton;
