import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { IconButton } from 'medialoopster/components';
import { Edit } from 'medialoopster/icons';

interface EditAnnotationButtonProps {
  editingAnnotation: boolean;
  toggleEditAnnotation: () => void;
}

/**
 * The button shows editing state of an annotation.
 *
 * @param editingAnnotation Boolean to indicate edit mode.
 * @param toggleEditAnnotation Function to toggle edit mode.
 */
const EditAnnotationButton: FC<EditAnnotationButtonProps> = ({
  editingAnnotation,
  toggleEditAnnotation,
}) => {
  return (
    <IconButton
      aria-label="edit"
      disabled={editingAnnotation}
      onClick={toggleEditAnnotation}
      dense
      title={editingAnnotation ? gettext('Editing') : gettext('Edit this annotation')}
    >
      <Edit fontSize="inherit" />
    </IconButton>
  );
};

export default EditAnnotationButton;
