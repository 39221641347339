import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { concat, EMPTY, from, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { AlertFeedback, alertMessage, AlertMessage } from 'medialoopster/AlertMessage';
import { gettext, ngettext } from 'medialoopster/Internationalization';
import {
  createResourcesLoadedAction,
  EmbeddedItemsPageResource,
  getEmbeddedItems,
  getLink,
  getLinkHref,
  getResourceURI,
  mlRel,
  RECEIVE_ROOT_RESOURCE,
  ReceiveRootResource,
  ResourceLoadedAction,
  ResourceOptionsLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
  unauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { isVideoAsset } from '../../../businessRules/models/asset/utils';
import buildURIWithAdditionalParameters from '../../../infrastructure/buildURIWithAdditionalParameters';
import { URL_FACES, URL_SEGMENTS } from '../../constants';
import { onCurrentAssetLinkHrefChange } from '../../operators';
import { RootState } from '../../types/rootState';
import { getCurrentAsset } from '../details/selectors';
import { playerActions } from '../player';
import { SetInitialFrame } from '../player/types';
import {
  fetchCurrentAssetPersonCount,
  seekToActivePerson,
  setActivePerson,
  setCurrentAssetPersonCount,
  setHasRenameError,
} from './actions';
import {
  getActivePerson,
  getActivePersonSegmentsInRange,
  getActivePersonURI,
  getConfidenceThreshold,
  getCurrentAssetPersons,
  isPersonsVisible,
} from './selectors';
import {
  ANALYSE_ASSETS,
  AnalyseAssets,
  ASSIGN_FACE,
  AssignFace,
  CONTENT_ANALYSIS_CONNECTOR_COLLECTION_REL,
  FaceResource,
  FETCH_CURRENT_ASSET_PERSON_COUNT,
  FETCH_FACES_OPTIONS,
  FETCH_KNOWN_PERSONS,
  FETCH_SEGMENTS_OPTIONS,
  FetchCurrentAssetPersonCount,
  FetchKnownPersons,
  Person,
  RENAME_PERSON,
  RenamePerson,
  SEEK_TO_ACTIVE_PERSON,
  SeekToActivePerson,
  SegmentResource,
  SetActivePerson,
  SetCurrentAssetPersonCount,
  SetHasRenameError,
  SPLIT_FACES,
  TOGGLE_PERSONS_VISIBLE,
} from './types';

export const fetchKnownPersonsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, FetchKnownPersons['type'], FetchKnownPersons>(FETCH_KNOWN_PERSONS),
    switchMap(({ payload: { url } }) => fetchCollection(url)),
  );

export const fetchCurrentAssetSegmentsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('segments'), (href) =>
    fetchCollection(
      buildURIWithAdditionalParameters(href, {
        confidence_threshold: getConfidenceThreshold(state$.value),
      }),
    ),
  );

export const fetchCurrentAssetFacesEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('faces'), fetchCollection);

export const fetchCurrentAssetPersonsEpic: Epic<
  Action,
  FetchCurrentAssetPersonCount | ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('persons'), (href) =>
    concat(of(fetchCurrentAssetPersonCount()), fetchCollection(href)),
  );

export const segmentsOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchOptions }) =>
  action$.pipe(
    ofType(FETCH_SEGMENTS_OPTIONS),
    mergeMap(() => fetchOptions(URL_SEGMENTS)),
  );

export const facesOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchOptions }) =>
  action$.pipe(
    ofType(FETCH_FACES_OPTIONS),
    mergeMap(() => fetchOptions(URL_FACES)),
  );

export const fetchCurrentAssetPersonCountEpic: Epic<
  Action,
  SetCurrentAssetPersonCount | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CURRENT_ASSET_PERSON_COUNT),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const currentAsset = getCurrentAsset(state);
      if (!currentAsset) {
        return EMPTY;
      }
      const personsLink = getLink(currentAsset, mlRel('persons'));
      if (personsLink?.methods?.GET?.code !== 'ok') {
        return EMPTY;
      }
      return ajax({
        method: 'HEAD',
        url: personsLink.href,
        headers: getTokenAuthHeader(loginSelectors.getToken(state)),
      }).pipe(
        map((response) =>
          setCurrentAssetPersonCount(+(response.xhr.getResponseHeader('X-Total-Count') || 0)),
        ),
        catchError((err) => {
          if (err && err.status === 401) {
            return of(unauthorizedRequestAction());
          }
          return EMPTY;
        }),
      );
    }),
  );

// TODO: use patchResource <RS t:ML-2706>
export const renamePersonEpic: Epic<
  Action,
  ResourceLoadedAction | SetHasRenameError | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, RenamePerson['type'], RenamePerson>(RENAME_PERSON),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { url, name },
        },
        state,
      ]) =>
        ajax
          .patch<Person>(
            url,
            { name },
            {
              'Content-Type': 'application/json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            mergeMap((response) =>
              of(
                createResourcesLoadedAction('person', [response.response]),
                setHasRenameError(false),
              ),
            ),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              return of(setHasRenameError(true));
            }),
          ),
    ),
  );

export const assignFaceEpic: Epic<
  Action,
  | ResourceLoadedAction
  | SetHasRenameError
  | FetchCurrentAssetPersonCount
  | SetActivePerson
  | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchResource, fetchCollection }) =>
  action$.pipe(
    ofType<Action, AssignFace['type'], AssignFace>(ASSIGN_FACE),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { url, person },
        },
        state,
      ]) =>
        ajax
          .patch<FaceResource>(
            url,
            { person },
            {
              'Content-Type': 'application/json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            mergeMap((response) => {
              const personURI = getLinkHref(response.response, mlRel('person'));
              const segmentsURI = getLinkHref(response.response, mlRel('segments'));
              if (!personURI || !segmentsURI) {
                return of(setHasRenameError(true));
              }
              return concat(
                of(
                  createResourcesLoadedAction('face', [response.response]),
                  setHasRenameError(false),
                  fetchCurrentAssetPersonCount(),
                ),
                fetchResource(personURI, () => of(setActivePerson(personURI))),
                fetchCollection(
                  buildURIWithAdditionalParameters(segmentsURI, {
                    confidence_threshold: getConfidenceThreshold(state),
                  }),
                ),
              );
            }),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              return of(setHasRenameError(true));
            }),
          ),
    ),
  );

// TODO: replace with patchResource <DT t:ML-2706>
const updateSegmentsObservable = (
  state: RootState,
  updates: ReadonlyArray<{ id: number; face: number }>,
): Observable<ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction> =>
  ajax<EmbeddedItemsPageResource<SegmentResource>>({
    url: '/api/segments/',
    body: updates,
    headers: {
      'Content-Type': 'application/json',
      ...getTokenAuthHeader(loginSelectors.getToken(state)),
      Accept: 'application/hal+json',
    },
    method: 'PATCH',
  }).pipe(
    map((response) => createResourcesLoadedAction('segment', getEmbeddedItems(response.response))),
    catchError((err) => {
      if (err && err.status === 401) {
        return of(unauthorizedRequestAction()); // TODO: Test in ML-3735
      }
      return of(alertMessage(gettext('Failed to split person.')));
    }),
  );

export const splitFacesEpic: Epic<Action, Action, RootState, RESTEpicDependencies> = (
  action$,
  state$,
  { fetchResource },
) =>
  action$.pipe(
    ofType(SPLIT_FACES),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const segments = getActivePersonSegmentsInRange(state);
      if (segments.length < 1) {
        return of(alertMessage(gettext('Please set IN and OUT points.'), AlertFeedback.Warning));
      }
      return ajax
        .post<FaceResource>('/api/faces/', {}, getTokenAuthHeader(loginSelectors.getToken(state)))
        .pipe(
          mergeMap((response) => {
            const personURI = getLinkHref(response.response, mlRel('person')) as string;
            return concat(
              of(createResourcesLoadedAction('face', [response.response])),
              fetchResource(personURI, () =>
                concat(
                  of(setActivePerson(personURI)),
                  updateSegmentsObservable(
                    state,
                    segments.map((segment) => ({
                      id: segment.id,
                      face: response.response.id,
                    })),
                  ).pipe(
                    mergeMap((segmentsLoadedAction) =>
                      of(
                        segmentsLoadedAction,
                        // NOTE: We can only seek to the active person once we've got the person AND the updated segments.
                        seekToActivePerson(),
                        fetchCurrentAssetPersonCount(),
                      ),
                    ),
                  ),
                ),
              ),
            );
          }),
          catchError((err) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            return of(alertMessage(gettext('Failed to split person.')));
          }),
        );
    }),
  );

export const togglePersonsVisibilityEpic: Epic<
  Action,
  SetActivePerson | SeekToActivePerson,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(TOGGLE_PERSONS_VISIBLE),
    withLatestFrom(state$),
    filter(([, state]) => isPersonsVisible(state)),
    mergeMap(([, state]) => {
      const actionsToDispatch = [];
      const activePersonURI = getActivePersonURI(state);
      if (!activePersonURI) {
        const persons = getCurrentAssetPersons(state);
        if (persons.length > 0) {
          actionsToDispatch.push(setActivePerson(getResourceURI(persons[0])));
        }
      }
      actionsToDispatch.push(seekToActivePerson());
      return from(actionsToDispatch);
    }),
  );

export const fetchConnectorsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload }) => {
      const connectorsCollectionURI = getLinkHref(
        payload.root,
        CONTENT_ANALYSIS_CONNECTOR_COLLECTION_REL,
      );
      if (!connectorsCollectionURI) {
        return EMPTY;
      }
      return fetchCollection(connectorsCollectionURI);
    }),
  );

export const analyseAssetsEpic: Epic<
  Action,
  AnalyseAssets | AlertMessage | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, AnalyseAssets['type'], AnalyseAssets>(ANALYSE_ASSETS),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { actionURI, assetChoices },
        },
        state,
      ]) =>
        ajax
          .post(
            actionURI,
            {
              _links: {
                [mlRel('asset')]: assetChoices.map(({ url }) => ({ href: url })),
              },
            },
            {
              'Content-Type': 'application/json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            map(() =>
              alertMessage(
                ngettext(
                  'Asset is being analysed.',
                  'Assets are being analysed.',
                  assetChoices.length,
                ),
                AlertFeedback.Success,
              ),
            ),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              return of(
                alertMessage(
                  ngettext(
                    'Failed to analyse asset.',
                    'Failed to analyse assets.',
                    assetChoices.length,
                  ),
                  AlertFeedback.Error,
                ),
              );
            }),
          ),
    ),
  );

export const seekToActivePersonEpic: Epic<Action, SetInitialFrame, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(SEEK_TO_ACTIVE_PERSON),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const currentAsset = getCurrentAsset(state);
      if (isVideoAsset(currentAsset)) {
        const activePerson = getActivePerson(state);
        const initialFrame =
          ((activePerson?.startTimeMs || 0) / 1000) * parseFloat(currentAsset.fps);
        return of(playerActions.setInitialFrame(initialFrame));
      }

      return EMPTY;
    }),
  );

export default combineEpics(
  fetchKnownPersonsEpic,
  fetchCurrentAssetSegmentsEpic,
  fetchCurrentAssetFacesEpic,
  fetchCurrentAssetPersonsEpic,
  segmentsOptionsEpic,
  facesOptionsEpic,
  fetchCurrentAssetPersonCountEpic,
  renamePersonEpic,
  assignFaceEpic,
  splitFacesEpic,
  togglePersonsVisibilityEpic,
  fetchConnectorsEpic,
  analyseAssetsEpic,
  seekToActivePersonEpic,
);
