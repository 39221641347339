import { TypographyProps } from '@mui/material/Typography';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { FC, ReactNode, useEffect, useState } from 'react';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { useInputStyles } from 'medialoopster/components/internal/InternalTextField';

import ButtonGroup from './ButtonGroup';
import OverflowTextField from './OverflowTextField';

interface InplaceDateTimeEditProps {
  editValue: string;
  textComponents: NonNullable<ReactNode>;
  canEdit: boolean;
  dense?: boolean;
  component?: 'div' | 'p'; // Eg. "span" does not support text overflow ellipse
  minDate?: DateTime;
  maxDate?: DateTime;
  submitFormat?: string;
  minWidth?: string;
  onChange?: (value: DateTime | null) => void;
  onSubmit?: (value: string | null) => void;
  id?: string;
  disablePast?: boolean;
  dateFormat?: string;
  variant?: TypographyProps['variant'];
}

const getDateTime = (dateString: string, format: string | undefined): DateTime =>
  format ? DateTime.fromFormat(dateString, format) : DateTime.fromISO(dateString);

const InplaceDateTimeEdit: FC<InplaceDateTimeEditProps> = ({
  minWidth,
  onChange,
  onSubmit,
  editValue,
  canEdit,
  textComponents,
  variant = 'body1',
  dense = false,
  disablePast = false,
  component = 'div',
  dateFormat = pgettext('Localized numeric date and time without meridiem', 'MM/dd/yyyy HH:mm'),
  minDate = DateTime.fromISO('1900-01-01'),
  maxDate = DateTime.fromISO('3000-01-01'),
  submitFormat,
}: InplaceDateTimeEditProps) => {
  const [date, setDate] = useState<DateTime | null>(() => getDateTime(editValue, submitFormat));
  const [submitDate, setSubmitDate] = useState<DateTime | null>();
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = () => {
    if (!submitDate || !submitDate?.isValid) {
      if (submitDate === null) {
        // unparseable date, e.g. empty input
        onSubmit?.(null);
      } else {
        // Invalid date or unchaged date, just close end set previous value
        setDate(DateTime.fromISO(editValue));
      }
    } else if (submitFormat && submitDate.isValid) {
      // Use submit format
      onSubmit?.(submitDate.toFormat(submitFormat));
    } else {
      // Fallback to ISO format
      onSubmit?.(submitDate.toISO());
    }
    setIsEditing(false);
  };

  const onEditTitle = () => {
    if (!canEdit) return;
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  useEffect(() => {
    if (editValue) {
      const newDate = getDateTime(editValue, submitFormat);
      if (newDate.isValid) {
        setDate(newDate);
      }
    }
  }, [editValue, setDate, submitFormat]);

  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false);
    }
  }, [canEdit]);

  const onValueChange = (newDate: DateTime | null) => {
    if (date?.toISO() !== newDate?.toISO()) {
      onChange?.(newDate);
      setDate(newDate);
      setSubmitDate(newDate);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  const classes = useInputStyles();
  return (
    <>
      {!isEditing && (
        <OverflowTextField
          component={component}
          variant={variant}
          onDoubleClick={onEditTitle}
          textComponents={textComponents}
          highlightText={canEdit}
          showPlaceHolder={!editValue && canEdit}
        />
      )}
      {isEditing && (
        <div style={{ display: 'flex' }}>
          <div style={{ minWidth, marginRight: '0.5em' }} data-testid="dateInputWrapper">
            <DesktopDateTimePicker
              slotProps={{
                popper: { 'aria-label': gettext('Date picker') },
                textField: {
                  sx: {
                    '.MuiInputBase-input': { padding: 1 },
                  },
                  onKeyUp: (event) => {
                    if (event.key === 'Enter') {
                      handleSubmit();
                    }
                    if (event.key === 'Escape' || event.key === 'Esc') {
                      setIsEditing(false);
                    }
                  },
                  classes: {
                    root: clsx({
                      [classes.inputDense]: dense,
                    }),
                  },
                },
              }}
              format={dateFormat}
              value={date}
              disablePast={disablePast}
              onChange={onValueChange}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
          <ButtonGroup onSubmit={handleSubmit} onClose={handleClose} dense={dense} />
        </div>
      )}
    </>
  );
};

export default InplaceDateTimeEdit;
