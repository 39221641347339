import { combineReducers } from 'redux';

import { ALERT_MESSAGE, AlertMessage, Alert } from 'medialoopster/AlertMessage';

import { ARCHIVE_LAST_USER_ALERT, AlertsRootState, ArchiveLastUserAlert } from './types';

export const initialActiveUserAlertsReducer: ReadonlyArray<Alert> = [];
export const activeUserAlertsReducer = (
  state = initialActiveUserAlertsReducer,
  action: AlertMessage | ArchiveLastUserAlert,
): ReadonlyArray<Alert> => {
  switch (action.type) {
    case ALERT_MESSAGE:
      return [...state, { ...action.payload }];
    case ARCHIVE_LAST_USER_ALERT: {
      const nextState = [...state];
      nextState.shift();
      if (nextState) return [...nextState];
      return [];
    }
    default:
      return state;
  }
};

export default combineReducers<AlertsRootState>({
  active: activeUserAlertsReducer,
});
