import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import { Ref, forwardRef } from 'react';

import DateRangePickerView from './DateRangePickerView';
import { DateRange } from './types';

export interface DateRangePickerWrapperProps {
  onChange: (dateRange: DateRange) => void;
  onSubmit: (dateRange: DateRange) => void;
  onReset: (dateRange: DateRange) => void;
  isExternalChange?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  transform?: string;
  open: boolean;
  anchorEl?: HTMLInputElement | HTMLDivElement | null;
  placement?: PopperPlacementType;
  popperId?: string;
  rangeStart?: DateTime;
  rangeEnd?: DateTime;
  resetStart?: DateTime;
  resetEnd?: DateTime;
}

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: theme.zIndex.modal,
  },
}));

const DateRangePickerWrapper = forwardRef<HTMLDivElement, DateRangePickerWrapperProps>(
  (props: DateRangePickerWrapperProps, ref: Ref<HTMLDivElement>) => {
    const {
      onChange,
      onSubmit,
      onReset,
      isExternalChange,
      dateFormat,
      timeFormat,
      transform,
      open,
      anchorEl,
      placement,
      popperId,
      rangeStart,
      rangeEnd,
      resetStart,
      resetEnd,
    } = props;

    const classes = useStyles();
    return (
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        id={popperId}
        role="dialog"
      >
        <DateRangePickerView
          ref={ref}
          onChange={onChange}
          onSubmit={onSubmit}
          onReset={onReset}
          isExternalChange={isExternalChange}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          transform={transform}
          rangeStart={rangeStart}
          rangeEnd={rangeEnd}
          resetStart={resetStart}
          resetEnd={resetEnd}
        />
      </Popper>
    );
  },
);

export default DateRangePickerWrapper;
