import { RootState } from '../../types/rootState';
import { LicenseState } from './types';

export const getCurrentLicense = (state: RootState): LicenseState => state.licensing.currentLicense;

export const getExpiryDate = (state: RootState): string =>
  getCurrentLicense(state).Info['Date of Expiry'];

export const isSiteLimitExceeded = (state: RootState): boolean => {
  if (Number.isNaN(state.licensing.siteCount)) {
    return false;
  }
  const currentLicense = getCurrentLicense(state);
  const siteLimit = currentLicense.Nodes.Frontend.Site;
  if (Number.isNaN(siteLimit)) {
    return false;
  }
  return state.licensing.siteCount > siteLimit;
};
