import pointer from 'json-pointer';
import { nanoid } from 'nanoid';
import { Fragment, ReactNode } from 'react';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AlertFeedback, alertMessage, AlertMessage } from 'medialoopster/AlertMessage';
import { gettext, ngettext } from 'medialoopster/Internationalization';
import {
  APIError,
  APIFieldError,
  getLinkHref,
  getResourceTypeName,
  getResourceURI,
  isAPIFieldError,
  mlRel,
  ResourceOptionsV2,
  RESTError,
  unauthorizedRequestAction,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';
import { TaskStatus } from 'medialoopster/types';

import {
  URL_ARCHIVE_ASSETS,
  URL_RESTORE_ARCHIVED_ASSETS,
  URL_REVOKE_ARCHIVE_ACTIVITIES,
} from '../../constants';
import { Activity } from '../../types/activity';
import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { isAssetCollection } from '../rest/collection/types';
import { archiveAssetOptionsLoaded, restoreArchivedAssetOptionsLoaded } from './actions';
import {
  ARCHIVE_ASSETS,
  ArchiveAssetOptionsLoaded,
  ArchiveAssetResourceType,
  ArchiveAssets,
  FETCH_ARCHIVE_ASSET_OPTIONS,
  FETCH_RESTORE_ARCHIVED_ASSET_OPTIONS,
  RESTORE_ARCHIVED_ASSETS,
  RestoreArchivedAssetOptionsLoaded,
  RestoreArchivedAssets,
  REVOKE_ARCHIVE_ACTIVITIES,
  RevokeArchiveActivities,
} from './types';

export const fetchArchiveAssetOptionsEpic: Epic<
  Action,
  ArchiveAssetOptionsLoaded | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ARCHIVE_ASSET_OPTIONS),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      ajax<ResourceOptionsV2>({
        method: 'OPTIONS',
        url: URL_ARCHIVE_ASSETS,
        headers: {
          ...getTokenAuthHeader(loginSelectors.getToken(state)),
        },
      }).pipe(
        map((response) => archiveAssetOptionsLoaded(response.response.actions || {})),
        catchError((err) => {
          if (err && err.status === 401) {
            return of(unauthorizedRequestAction());
          }
          return EMPTY;
        }),
      ),
    ),
  );

export const listAssetsByError = (assetsByError: Record<string, string[]>): JSX.Element[] =>
  Object.entries(assetsByError).map(([error, assets]) => {
    if (assets.length > 0) {
      let msgError = error;
      if (error.endsWith('.')) {
        msgError = error.slice(0, -1); // remove '.' before ':'
      }
      msgError += ':';
      return (
        <Fragment key={nanoid()}>
          {msgError}
          <ul>
            {assets.map((asset) => (
              <li key={nanoid()}>{asset}</li>
            ))}
          </ul>
        </Fragment>
      );
    }
    return <p key={nanoid()}>{error}</p>;
  });

export const handleFailedActivities = (
  data: ReadonlyArray<Activity<'archiveactivity' | 'restoreactivity'>>,
): JSX.Element[] => {
  const assetsByError: Record<string, string[]> = {};
  data
    .filter((activity) => activity.status === TaskStatus.FAILURE)
    .forEach((activity) => {
      if (assetsByError[activity.info] === undefined) {
        assetsByError[activity.info] = [];
      }
      assetsByError[activity.info].push(activity.asset_name);
    });

  return listAssetsByError(assetsByError);
};

type ActivityRequestBody = ReadonlyArray<{
  readonly asset_id: number;
  readonly asset_name: string;
  readonly content_type_model: ArchiveAssetResourceType;
}>;

export const handleErrors = (
  errors: ReadonlyArray<APIError | APIFieldError>,
  requestBody: ActivityRequestBody,
): JSX.Element[] => {
  const assetsByError: Record<string, string[]> = {};
  errors.forEach((error) => {
    if (assetsByError[error.detail] === undefined) {
      assetsByError[error.detail] = [];
    }
    if (isAPIFieldError(error)) {
      const activity = pointer.get(requestBody, error.source.pointer);
      if (activity) {
        assetsByError[error.detail].push(activity.asset_name);
      }
    }
  });

  return listAssetsByError(assetsByError);
};

const buildPartialSuccessMsg = (msg: ReactNode, partialMsg: string, failedMsg: ReactNode) => (
  <>
    {msg}
    <br />
    {partialMsg}
    <br />
    {failedMsg}
  </>
);

const buildErrorMsg = (
  msg: string,
  requestBody: ActivityRequestBody,
  errors?: ReadonlyArray<APIError | APIFieldError>,
) =>
  errors ? (
    <>
      {msg}
      <br />
      {handleErrors(errors, requestBody)}
    </>
  ) : (
    msg
  );

const getArchiveAssetResourceTypeName = (asset: Asset): ArchiveAssetResourceType => {
  if (isAssetCollection(asset)) {
    return 'projectasset';
  }
  return getResourceTypeName(asset);
};

export const archiveAssetEpic: Epic<Action, AlertMessage | UnauthorizedRequestAction> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<Action, ArchiveAssets['type'], ArchiveAssets>(ARCHIVE_ASSETS),
    map(({ payload: { assets } }) =>
      assets.map((asset) => ({
        asset_id: asset.id,
        asset_name: asset.name,
        content_type_model: getArchiveAssetResourceTypeName(asset),
      })),
    ),
    withLatestFrom(state$),
    mergeMap(([body, state]) =>
      ajax
        .post<ReadonlyArray<Activity<'archiveactivity'>>>(URL_ARCHIVE_ASSETS, body, {
          'Content-Type': 'application/json',
          ...getTokenAuthHeader(loginSelectors.getToken(state)),
        })
        .pipe(
          map((response) => {
            let msg: ReactNode = ngettext(
              'The asset was successfully queued for archiving.',
              'The assets were successfully queued for archiving.',
              body.length,
            );
            let feedback = AlertFeedback.Success;
            const failedMsg = handleFailedActivities(response.response);
            if (failedMsg.length > 0) {
              msg = buildPartialSuccessMsg(
                msg,
                gettext('Some assets could not be queued:'),
                failedMsg,
              );
              feedback = AlertFeedback.Info;
            }
            return alertMessage(msg, feedback);
          }),
          catchError((err: RESTError) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            const { errors } = err.response;
            const msg: string = ngettext(
              'The asset could not be queued for archiving.',
              'The assets could not be queued for archiving.',
              body.length,
            );
            return of(alertMessage(buildErrorMsg(msg, body, errors), AlertFeedback.Error));
          }),
        ),
    ),
  );

export const handleFailedRevokes = (
  data: ReadonlyArray<Activity<'archiveactivity'>>,
  assets: ReadonlyArray<Asset>,
): JSX.Element[] => {
  const assetsByError: Record<string, string[]> = {};
  // All error sources are the same in this temporary implementation
  const errorDetail = gettext('No revocable archive activity');

  assets.forEach((asset) => {
    let revoked = false;
    if (data.some((activity) => getLinkHref(activity, mlRel('asset')) === getResourceURI(asset))) {
      revoked = true;
    }
    if (!revoked) {
      if (assetsByError[errorDetail] === undefined) {
        assetsByError[errorDetail] = [];
      }
      assetsByError[errorDetail].push(asset.name);
    }
  });

  return listAssetsByError(assetsByError);
};

export const revokeArchiveActivitiesEpic: Epic<Action, AlertMessage | UnauthorizedRequestAction> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<Action, RevokeArchiveActivities['type'], RevokeArchiveActivities>(
      REVOKE_ARCHIVE_ACTIVITIES,
    ),
    map(({ payload: { assets } }) => ({
      assets,
      body: assets.map((asset) => ({
        asset_id: asset.id,
        asset_name: asset.name,
        content_type_model: getArchiveAssetResourceTypeName(asset),
      })),
    })),
    withLatestFrom(state$),
    mergeMap(([{ assets, body }, state]) =>
      ajax
        .patch<ReadonlyArray<Activity<'archiveactivity'>>>(URL_REVOKE_ARCHIVE_ACTIVITIES, body, {
          'Content-Type': 'application/json',
          ...getTokenAuthHeader(loginSelectors.getToken(state)),
        })
        .pipe(
          map((response) => {
            let msg: ReactNode = ngettext(
              'The asset was successfully removed from the archive queue.',
              'The assets were successfully removed from the archive queue.',
              body.length,
            );
            let feedback = AlertFeedback.Success;
            const failedMsg = handleFailedRevokes(response.response, assets);
            if (failedMsg.length > 0) {
              msg = buildPartialSuccessMsg(
                msg,
                gettext('Some assets could not be removed:'),
                failedMsg,
              );
              feedback = AlertFeedback.Info;
            }
            return alertMessage(msg, feedback);
          }),
          catchError((err: RESTError) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            const { errors } = err.response;
            const msg: string = ngettext(
              'The asset could not be removed from the archive queue.',
              'The assets could not be removed from the archive queue.',
              body.length,
            );
            return of(alertMessage(buildErrorMsg(msg, body, errors), AlertFeedback.Error));
          }),
        ),
    ),
  );

export const fetchRestoreArchivedAssetOptionsEpic: Epic<
  Action,
  RestoreArchivedAssetOptionsLoaded | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_RESTORE_ARCHIVED_ASSET_OPTIONS),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      ajax<ResourceOptionsV2>({
        method: 'OPTIONS',
        url: URL_RESTORE_ARCHIVED_ASSETS,
        headers: {
          ...getTokenAuthHeader(loginSelectors.getToken(state)),
        },
      }).pipe(
        map((response) => restoreArchivedAssetOptionsLoaded(response.response.actions || {})),
        catchError((err) => {
          if (err && err.status === 401) {
            return of(unauthorizedRequestAction());
          }
          return EMPTY;
        }),
      ),
    ),
  );

export const restoreArchivedAssetsEpic: Epic<
  Action,
  AlertMessage | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, RestoreArchivedAssets['type'], RestoreArchivedAssets>(RESTORE_ARCHIVED_ASSETS),
    map(({ payload: { assets } }) =>
      assets.map((asset) => ({
        asset_id: asset.id,
        asset_name: asset.name,
        content_type_model: getArchiveAssetResourceTypeName(asset),
      })),
    ),
    withLatestFrom(state$),
    mergeMap(([body, state]) =>
      ajax
        .post<ReadonlyArray<Activity<'restoreactivity'>>>(URL_RESTORE_ARCHIVED_ASSETS, body, {
          'Content-Type': 'application/json',
          ...getTokenAuthHeader(loginSelectors.getToken(state)),
        })
        .pipe(
          map((response) => {
            let msg: ReactNode = ngettext(
              'The asset was successfully queued for restoring.',
              'The assets were successfully queued for restoring.',
              body.length,
            );
            let feedback = AlertFeedback.Success;
            const failedMsg = handleFailedActivities(response.response);
            if (failedMsg.length > 0) {
              msg = buildPartialSuccessMsg(
                msg,
                gettext('Some assets could not be queued:'),
                failedMsg,
              );
              feedback = AlertFeedback.Info;
            }
            return alertMessage(msg, feedback);
          }),
          catchError((err: RESTError) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            const { errors } = err.response;
            const msg: string = ngettext(
              'The asset could not be queued for restoring.',
              'The assets could not be queued for restoring.',
              body.length,
            );
            return of(alertMessage(buildErrorMsg(msg, body, errors), AlertFeedback.Error));
          }),
        ),
    ),
  );

export default combineEpics<
  Action,
  | AlertMessage
  | RestoreArchivedAssetOptionsLoaded
  | ArchiveAssetOptionsLoaded
  | UnauthorizedRequestAction,
  RootState
>(
  fetchArchiveAssetOptionsEpic,
  fetchRestoreArchivedAssetOptionsEpic,
  archiveAssetEpic,
  revokeArchiveActivitiesEpic,
  restoreArchivedAssetsEpic,
);
