import { Reducer } from 'redux';
import { Observable } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { ReadonlyRecord } from '../types';

/** HTTP method codes for api link permissions. */
export enum HttpMethodCode {
  OK = 'ok',
}
/** An HTTP method string. */
export type HttpMethod = 'GET' | 'HEAD' | 'POST' | 'PATCH' | 'DELETE';

/**
 * An HTTP method permission hint.
 */
export interface LinkPermission {
  /** The error code or "ok" for success. */
  readonly code: string;

  /** The error message. */
  readonly message?: string;
}

/**
 * A link in a resource.
 */
export interface Link {
  /** The link target URI. */
  readonly href: string;

  /** The link title. */
  readonly title?: string;

  /** Permission hints by HTTP method. */
  readonly methods?: {
    readonly [key in HttpMethod]?: LinkPermission;
  };
}

export interface TypeLink<T extends string> extends Link {
  /** The name of the type. */
  readonly name: T;
}

export type ResourceType<R extends Resource<string>> = R['_links']['type']['name'];

export type CollectionType<T extends string> = `${T}-collection`;

export type ResourceCollectionType<R extends Resource<string>> = CollectionType<ResourceType<R>>;

export type RelatedResourceCollectionType<
  R extends Resource<string>,
  T extends string,
> = `${ResourceType<R>}-${T}`;

/**
 * Links of a base resource.
 */
export interface BaseResourceLinks {
  readonly [relationType: string]: Link | TypeLink<string> | ReadonlyArray<Link> | undefined | null;
}

/**
 * Embedded resources of a base resource.
 */
export interface BaseResourceEmbedded {
  readonly [relationType: string]: BaseResource | ReadonlyArray<BaseResource> | undefined;
}

/**
 * A resource with optional links and embedded objects.
 */
export interface BaseResource<
  L extends BaseResourceLinks = BaseResourceLinks,
  E extends BaseResourceEmbedded = BaseResourceEmbedded,
> {
  /** Mapping of relation type to link or link array. */
  readonly _links?: L;

  /** Embedded item resources without a ``self`` link are kept here. */
  readonly _embedded?: E;
}

/**
 * Links of a resource, namely ``self`` and ``type``.
 *
 * These are required to locate it in the store.
 * Otherwise, they can only be embedded in other resources (see BaseResource).
 */
export interface ResourceLinks<T extends string> extends BaseResourceLinks {
  /** Link to the resource itself. */
  readonly self: Link;

  /** Link to the resource's type. */
  readonly type: TypeLink<T>;
}

/**
 * A resource containing at least a ``self`` and a ``type`` link.
 */
export interface Resource<
  T extends string,
  L extends ResourceLinks<T> = ResourceLinks<T>,
  E extends BaseResourceEmbedded = BaseResourceEmbedded,
> extends BaseResource<L, E> {
  /** Links or arrays of links by their relation type. */
  readonly _links: L;
}

export interface BaseResourceOptions<V, A> {
  readonly actions: A;
  readonly version: V;
}

/**
 * The options for a resource (API version 2).
 */
export type ResourceOptionsV2 = BaseResourceOptions<'2', ActionsMetadataV2>;

/**
 * The options for a resource (API version 3).
 */
export type ResourceOptionsV3 = BaseResourceOptions<'3', ActionsMetadataV3>;

export type ResourceOptions = ResourceOptionsV2 | ResourceOptionsV3;

export const isResourceOptionsV2 = (options: ResourceOptions): options is ResourceOptionsV2 =>
  options.version === '2';

export const isResourceOptionsV3 = (options: ResourceOptions): options is ResourceOptionsV3 =>
  options.version === '3';

export type ResourceHeaders = ReadonlyRecord<string, string>;

/**
 * A mapping of URI to resource.
 */
export interface ResourceMap<R extends Resource<string>, O = ResourceOptions> {
  readonly resourceTypeName: ResourceType<R>;
  readonly resources: {
    readonly [uri: string]: R;
  };
  readonly options: {
    readonly [uri: string]: O;
  };
  readonly headers: {
    readonly [uri: string]: ResourceHeaders;
  };
}

export type CollectionResourceMap<
  R extends Resource<string>,
  O = ResourceOptions,
  P extends PageResource<ResourceCollectionType<R>> = LinkedItemsPageResource<
    ResourceCollectionType<R>
  >,
> = ResourceMap<P, O>;

export type RelatedCollectionResourceMap<
  R extends Resource<string>,
  T extends string,
  O = ResourceOptions,
  P extends PageResource<RelatedResourceCollectionType<R, T>> = LinkedItemsPageResource<
    RelatedResourceCollectionType<R, T>
  >,
> = ResourceMap<P, O>;

export interface BasePageLinks extends BaseResourceLinks {
  readonly next?: Link;
}

export interface PageLinks<T extends string> extends ResourceLinks<T>, BasePageLinks {}

export interface BasePageResource<L extends BasePageLinks = BasePageLinks> extends BaseResource<L> {
  readonly total_count: number;
  readonly _links?: L;
}

/**
 * A resource representing a page of a collection.
 */
export interface PageResource<T extends string, L extends PageLinks<T> = PageLinks<T>>
  extends BasePageResource,
    Resource<T> {
  readonly total_count: number;
  readonly _links: L;
}

/**
 * Embedded items in a page resource.
 */
export interface PageResourceEmbedded<I extends BaseResource> extends BaseResourceEmbedded {
  readonly [relationType: string]: BaseResource | ReadonlyArray<BaseResource> | I | I[] | undefined;
  readonly item: ReadonlyArray<I>;
}

/**
 * Page resource with embedded items.
 */
export interface EmbeddedItemsBasePageResource<I extends BaseResource> extends BasePageResource {
  readonly _embedded?: PageResourceEmbedded<I>;
}

export interface EmbeddedItemsPageResource<
  I extends BaseResource,
  PT extends string = I extends Resource<string> ? ResourceCollectionType<I> : never,
> extends PageResource<PT> {
  readonly _embedded?: PageResourceEmbedded<I>;
}

/**
 * Item links in a page resource.
 */
export interface LinkedItemPageLinks<T extends string> extends PageLinks<T> {
  readonly item: ReadonlyArray<Link>;
}

/**
 * Page resource with linked items.
 */
export type LinkedItemsPageResource<T extends string> = PageResource<T, LinkedItemPageLinks<T>>;

/**
 * Combines items from several pages, connected by ``next`` links.
 */
export interface Collection<I extends BaseResource> {
  /** Whether the first page has been loaded. */
  loaded: boolean;

  /** Items from all of the collection's pages. */
  items: ReadonlyArray<I>;

  /** The total number of items in the collection. */
  totalCount: number;

  /**
   * If the last page of the collection is not available, contains its ``next`` link.
   */
  next?: string;
}

/**
 * An action dispatched when a rest request failed due to invalid authorization token.
 */
export const UNAUTHORIZED_REQUEST_ACTION = 'REST/UNAUTHORIZED_REQUEST_ACTION';
export interface UnauthorizedRequestAction {
  readonly type: typeof UNAUTHORIZED_REQUEST_ACTION;
}
export const unauthorizedRequestAction = (): UnauthorizedRequestAction => ({
  type: UNAUTHORIZED_REQUEST_ACTION,
});

/**
 * An action dispatched when resources of a given type have been loaded.
 */
export interface ResourceLoadedAction<R extends Resource<string> = Resource<string>> {
  type: `REST/RESOURCES_LOADED(${ResourceType<R>})`;
  payload: ResourceMap<R>;
}

/**
 * An action dispatched when resources of a given type have been removed.
 */
export interface ResourceRemovedAction<T extends string = string> {
  type: `REST/RESOURCES_REMOVED(${T})`;
  payload: {
    resourceTypeName: T;
    resourceURIs: ReadonlyArray<string>;
  };
}

export interface ModifyResourceAction<R extends Resource<string>> {
  type: `REST/MODIFY_RESOURCE(${ResourceType<R>})`;
  payload: {
    resourceTypeName: ResourceType<R>;
    resource: R;
  };
}

/**
 * An action dispatched when resources options of a given type have been loaded.
 */
export interface ResourceOptionsLoadedAction<T extends string = string, O = ResourceOptions> {
  type: `REST/RESOURCE_OPTIONS_LOADED(${T})`;
  payload: {
    uri: string;
    options: O;
  };
}

/**
 * The state of a resources reducer.
 */
export interface ResourcesReducerState<R extends Resource<string>> {
  resourceTypeName: ResourceType<R>;
  resourceMap: ResourceMap<R>;
}

export interface MoveActions<
  P extends LinkedItemsPageResource<string> = LinkedItemsPageResource<string>,
> {
  move$: Observable<ResourceLoadedAction<P>>;
  undoMove$: Observable<ResourceLoadedAction<P>>;
}

export interface APIError {
  readonly code: string;
  readonly title: string;
  readonly detail: string;
  readonly status: string;
  readonly meta?: {
    readonly links?: ReadonlyArray<{
      readonly self: Link;
      readonly type: Link;
    }>;
  };
}

export interface APIFieldError extends APIError {
  readonly source: {
    readonly pointer: string;
  };
}

export interface RESTErrorResponse {
  readonly errors?: ReadonlyArray<APIError | APIFieldError>;
}

export interface RESTError extends AjaxError {
  response: RESTErrorResponse;
}

export interface Choice<T> {
  readonly value: T;
  readonly display_name: string;
}

/**
 * The metadata description of fields in response objects.
 * @see docs/source/development/backend/architecture/api/using.rst::Field objects
 */
export interface FieldObjectV2 {
  readonly type:
    | 'field'
    | 'boolean'
    | 'string'
    | 'url'
    | 'email'
    | 'regex'
    | 'slug'
    | 'integer'
    | 'float'
    | 'date'
    | 'datetime'
    | 'time'
    | 'choice'
    | 'multiple choice'
    | 'list'
    | 'nested object';
  readonly required: boolean;
  readonly label: string;
  readonly read_only: boolean;
  readonly help_text?: string;
  readonly min_length?: number;
  readonly max_length?: number;
  readonly min_value?: number;
  readonly max_value?: number;
  readonly choices?: ReadonlyArray<Choice<string | number>>;
  readonly child?: FieldObjectV2;
  readonly children?: MappedFieldObjectsV2;
  readonly target_types?: ReadonlyArray<string>;
}

export interface MappedFieldObjectsV2 {
  readonly [key: string]: FieldObjectV2;
}

/**
 * Actions metadata from OPTIONS response.
 * @see docs/source/development/backend/architecture/api/using.rst::Metadata
 */
export type ActionsMetadataV2 = {
  readonly GET?: MappedFieldObjectsV2;
  readonly POST?: MappedFieldObjectsV2;
  readonly PATCH?: MappedFieldObjectsV2;
};

/**
 * The metadata description of fields in response objects.
 * @see docs/source/development/backend/architecture/api/using.rst::Field objects
 */
export interface FieldObjectV3 {
  readonly type:
    | 'field'
    | 'boolean'
    | 'string'
    | 'integer'
    | 'float'
    | 'date'
    | 'datetime'
    | 'time'
    | 'choice'
    | 'multiple choice'
    | 'list'
    | 'nested object';
  readonly required: boolean;
  readonly label: string;
  readonly read_only: boolean;
  readonly format?: 'url' | 'uuid' | 'url' | 'email' | 'regex';
  readonly regex?: string;
  readonly help_text?: string;
  readonly min_length?: number;
  readonly max_length?: number;
  readonly min_value?: number;
  readonly max_value?: number;
  readonly allow_null?: boolean;
  readonly allow_blank?: boolean;
  readonly allow_empty?: boolean;
  readonly choices?: ReadonlyArray<Choice<string | number>>;
  readonly child?: FieldObjectV3;
  readonly children?: MappedFieldObjectsV3;
  readonly default?: string;
}

export interface LinkChoiceV3 {
  readonly href: string;
  readonly title: string;
}

export interface LinkObjectV3 {
  readonly required: boolean;
  readonly label: string;
  readonly read_only: boolean;
  readonly allow_null?: boolean;
  readonly help_text?: string;
  readonly default?: string;
  readonly choices?: ReadonlyArray<LinkChoiceV3>;
  readonly many?: boolean;
}

export interface MappedFieldObjectsV3 {
  readonly [name: string]: FieldObjectV3;
}

export interface ResourceActionV3 {
  readonly fields?: MappedFieldObjectsV3;
  readonly links?: {
    readonly [rel: string]: LinkObjectV3;
  };
  readonly embedded?: {
    readonly [rel: string]: EmbeddedObjectV3;
  };
}

export interface EmbeddedObjectV3 extends ResourceActionV3 {
  readonly required: boolean;
  readonly label: string;
  readonly read_only: boolean;
  readonly help_text?: string;
  readonly many?: boolean;
}

/**
 * Actions metadata from OPTIONS response.
 */
export type ActionsMetadataV3 = {
  readonly GET?: ResourceActionV3;
  readonly POST?: ResourceActionV3;
  readonly PATCH?: ResourceActionV3;
};

export const FETCH_ROOT_RESOURCE = 'REST/FETCH_ROOT_RESOURCE';
export const RECEIVE_ROOT_RESOURCE = 'REST/RECEIVE_ROOT_RESOURCE';

export type FetchRootResource = {
  readonly type: typeof FETCH_ROOT_RESOURCE;
  readonly payload: {
    readonly url: string;
    readonly version?: string;
  };
};

export interface RootResource extends BaseResource {
  readonly medialoopster_version: string;
}

export type ReceiveRootResource = {
  type: typeof RECEIVE_ROOT_RESOURCE;
  payload: { root: RootResource };
};

export interface ResourceModuleState<
  R extends Resource<string>,
  O = ResourceOptions,
  P extends PageResource<ResourceCollectionType<R>> = LinkedItemsPageResource<
    ResourceCollectionType<R>
  >,
> {
  // link to the collection
  readonly collectionLink: Link | null;
  // The pages with pagination links and links to the objects. The pages are normalized by
  // removing the objects itself and storing them uder the `objects` key (see below).
  readonly pages: ResourceMap<P, O>;
  // The objects itself that contain the data.
  readonly objects: ResourceMap<R, O>;
}

export interface ResourceModuleReducerMap<
  R extends Resource<string>,
  O = ResourceOptions,
  P extends PageResource<ResourceCollectionType<R>> = LinkedItemsPageResource<
    ResourceCollectionType<R>
  >,
> {
  readonly collectionLink: Reducer<Link | null, ReceiveRootResource>;
  readonly pages: Reducer<
    ResourceMap<P, O>,
    | ResourceLoadedAction<P>
    | ResourceRemovedAction<ResourceType<P>>
    | ResourceOptionsLoadedAction<ResourceType<P>, O>
  >;
  readonly objects: Reducer<
    ResourceMap<R, O>,
    | ResourceLoadedAction<R>
    | ResourceRemovedAction<ResourceType<R>>
    | ResourceOptionsLoadedAction<ResourceType<R>, O>
  >;
}

export interface EmbeddedItemsResourceModuleState<
  R extends BaseResource,
  PT extends string,
  P extends PageResource<PT> = EmbeddedItemsPageResource<R, PT>,
> {
  readonly collectionLink: Link | null;
  readonly pages: ResourceMap<P>;
}

export interface EmbeddedItemsResourceModuleReducerMap<
  R extends BaseResource,
  PT extends string,
  P extends PageResource<PT> = EmbeddedItemsPageResource<R, PT>,
> {
  readonly collectionLink: Reducer<Link | null, ReceiveRootResource>;
  readonly pages: Reducer<
    ResourceMap<P>,
    | ResourceLoadedAction<P>
    | ResourceRemovedAction<ResourceType<P>>
    | ResourceOptionsLoadedAction<ResourceType<P>>
  >;
}

export interface HttpHeaders extends ReadonlyRecord<string, string | undefined> {
  readonly Accept?:
    | 'application/hal+json'
    | `application/hal+json; version=${number}`
    | `application/json; version=${number}`; // TODO: this value is only for frontend-legacy; remove it when frontend legacy is removed
  readonly 'Content-Type'?:
    | 'application/json'
    | `application/json; version=${number}`
    | `application/hal+json; version=${number}`;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isUnauthorizedRequestAction = (obj: any): obj is UnauthorizedRequestAction =>
  obj?.type === UNAUTHORIZED_REQUEST_ACTION;
