import { FC, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { pgettext } from 'medialoopster/Internationalization';
import { Modal } from 'medialoopster/components';

import {
  assetCollectionCreatedResult,
  createAssetCollection,
} from '../../../state/modules/createAssetCollectionModal/actions';
import {
  getNewAssetCollection,
  getNewAssetCollectionCreateErrors,
} from '../../../state/modules/createAssetCollectionModal/selectors';
import { detailsActions } from '../../../state/modules/details';
import { Production } from '../../../state/modules/productions/types';
import { MediaAssetResource, MediaAssetType } from '../../../state/types/asset/baseTypes';
import { AssetMultiSelectWidget, KeyedAssets } from './AssetMultiSelectWidget';
import { NewCollectionNameInputWidget } from './NewCollectionNameInputWidget';
import { WritableProductionSelectWidget } from './WritableProductionSelectWidget';

const productionErrorPointer = ['/production'];

const collectionNameErrorPointer = ['/name'];

const assetToLinkErrorPointer = ['/videoasset_ids', '/imageasset_ids', '/audioasset_ids'];

interface CreateCollectionModalProps {
  show: boolean;
  onClose: () => void;
  readonly assets: ReadonlyArray<MediaAssetResource>;
}

interface FormData {
  readonly collectionName: string;
  readonly selectedProduction: Production;
  readonly selectLinkedAssets: KeyedAssets;
}

const getAssetIdsByType = (
  assetType: MediaAssetType,
  selectLinkedAssets: KeyedAssets,
): ReadonlyArray<number> =>
  selectLinkedAssets.flatMap((keyedAsset) =>
    keyedAsset.type === assetType ? [keyedAsset.id] : [],
  );

const productionToId = (production: Production | null) => production?.id ?? null;

const CreateAssetCollectionModal: FC<CreateCollectionModalProps> = ({
  show,
  onClose,
  assets,
}: CreateCollectionModalProps) => {
  const dispatch = useDispatch();
  const createdCollection = useSelector(getNewAssetCollection, shallowEqual);
  const createErrors = useSelector(getNewAssetCollectionCreateErrors);

  const methods = useForm<FormData>({ mode: 'all' });
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setError,
  } = methods;

  const onModalClose = useCallback(() => {
    reset();
    dispatch(assetCollectionCreatedResult(undefined, []));
    onClose();
  }, [reset, dispatch, onClose]);

  useEffect(() => {
    if (createdCollection.assetCollectionHref && createErrors.length === 0) {
      dispatch(detailsActions.setCurrentAsset('collection', createdCollection.assetCollectionHref));
      onModalClose();
    }
  }, [createdCollection, createErrors, onModalClose, dispatch]);

  useEffect(() => {
    createErrors.forEach((error) => {
      const errorOptions = {
        type: 'serverSide',
        message: error.detail,
      };
      if ('source' in error) {
        if (error.source.pointer && productionErrorPointer.includes(error.source.pointer)) {
          setError('selectedProduction', errorOptions);
        } else if (
          error.source.pointer &&
          collectionNameErrorPointer.includes(error.source.pointer)
        ) {
          setError('collectionName', errorOptions);
        } else if (error.source.pointer && assetToLinkErrorPointer.includes(error.source.pointer)) {
          setError('selectLinkedAssets', errorOptions);
        }
      } else {
        setError('root.serverError', errorOptions);
      }
    });
  }, [createErrors, setError]);

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    const { collectionName, selectedProduction, selectLinkedAssets } = data;
    dispatch(
      createAssetCollection(
        selectedProduction.id,
        collectionName,
        getAssetIdsByType('audioasset', selectLinkedAssets),
        getAssetIdsByType('imageasset', selectLinkedAssets),
        getAssetIdsByType('videoasset', selectLinkedAssets),
      ),
    );
  };

  return (
    <FormProvider {...methods}>
      <Modal id="create-collection-asset-modal" open={show} onClose={onModalClose}>
        <Modal.Header
          onClose={onModalClose}
          id="create-collection-asset-modal"
          headerTitle={pgettext('Title-style capitalization', 'Create Collection')}
        />
        <Modal.Body id="create-collection-asset-modal-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            {errors.root?.serverError && (
              <Modal.Body.Notifier
                errorMsg={errors.root?.serverError.message ? errors.root?.serverError.message : ''}
                key={errors.root?.serverError.message}
              />
            )}
            <WritableProductionSelectWidget<FormData>
              id="favorites-create-collection-production-select"
              name="selectedProduction"
            />
            <NewCollectionNameInputWidget<FormData, Production>
              id="collectionName"
              name="collectionName"
              productionField="selectedProduction"
              productionTransform={productionToId}
            />
            <AssetMultiSelectWidget<FormData>
              id="select-assets-to-link"
              name="selectLinkedAssets"
              assets={assets}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.CancelButton
            title={pgettext('Form cancel button title', 'Cancel this form')}
            onClick={onModalClose}
          />
          <Modal.Footer.SubmitButton
            onClick={handleSubmit(onSubmit)}
            label={pgettext('Form submit button label', 'Create')}
            title={pgettext('Form submit button title', 'Submit this form')}
            disabled={!isValid}
          />
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
};

export default CreateAssetCollectionModal;
