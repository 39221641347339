import { Grid } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { FormFieldWidget, Modal } from 'medialoopster/components';
import { FormField } from 'medialoopster/forms';
import { ReadonlyRecord } from 'medialoopster/types';

import { bulkEditActions, bulkEditSelectors } from '../../../../../state/modules/bulkEdit';
import { KeywordsField } from '../../../../../state/modules/bulkEdit/types';
import BulkEditCategoryKeywordsWidget from './BulkEditCategoryKeywordsWidget';
import BulkEditFieldGroup from './BulkEditFieldGroup';

const BulkEditModal: FC = () => {
  const dispatch = useDispatch();
  const isBulkEditModalOpen = useSelector(bulkEditSelectors.isBulkEditModalOpen);
  const methods = useForm({ mode: 'onBlur' });
  const { handleSubmit, setError } = methods;
  const bulkEditFields = useSelector(bulkEditSelectors.getBulkEditFields);
  const keywordsFields = useSelector(bulkEditSelectors.getKeywordCategoryBulkEditFields);
  const [activeFields, setActiveFields] = useState<ReadonlyRecord<string, boolean>>({});
  const canSubmitBulkEdit = Object.values(activeFields).filter((active) => !!active).length > 0;
  const fieldsErrors = useSelector(bulkEditSelectors.getFieldsErrors);
  const close = () => {
    dispatch(bulkEditActions.closeBulkEditModal());
  };
  const submit = (data: FieldValues) => {
    const bulkEditResource = {};
    [...bulkEditFields, ...keywordsFields]
      .filter(({ name }) => !!activeFields[name])
      .forEach((field) => field.set(bulkEditResource, data[field.name]));
    dispatch(bulkEditActions.submitBulkEdit(bulkEditResource));
  };
  useEffect(() => {
    Object.entries(fieldsErrors).forEach(([name, errors]) => {
      errors.forEach((message) => setError(name, { message }));
    });
  }, [fieldsErrors, setError]);
  useEffect(() => {
    if (isBulkEditModalOpen) {
      setActiveFields({});
    }
  }, [isBulkEditModalOpen]);
  const setFieldActive = useCallback(
    (field, active) => setActiveFields({ ...activeFields, [field.name]: active }),
    [activeFields],
  );
  const isFieldActive = useCallback((field) => !!activeFields[field.name], [activeFields]);
  return (
    <FormProvider {...methods}>
      <Modal
        id="bulk-edit"
        open={isBulkEditModalOpen}
        onClose={close}
        maxWidth={bulkEditFields.length > 0 && keywordsFields.length > 0 ? 'lg' : 'sm'}
      >
        <Modal.Header headerTitle={gettext('Bulk Edit')} onClose={close} />
        <Modal.Body>
          <Grid container spacing={2}>
            {bulkEditFields.length > 0 && (
              <Grid item xs={keywordsFields.length > 0 ? 6 : 12}>
                <BulkEditFieldGroup
                  title={gettext('Info')}
                  fields={bulkEditFields}
                  renderField={(field) => (
                    <FormFieldWidget
                      field={field as FormField}
                      disabled={!activeFields[field.name]}
                    />
                  )}
                  isFieldActive={isFieldActive}
                  setFieldActive={setFieldActive}
                />
              </Grid>
            )}
            {keywordsFields.length > 0 && (
              <Grid item xs={bulkEditFields.length > 0 ? 6 : 12}>
                <BulkEditFieldGroup
                  title={gettext('Keywords')}
                  fields={keywordsFields}
                  renderField={(field) => (
                    <BulkEditCategoryKeywordsWidget
                      name={field.name}
                      label={field.label}
                      availableKeywords={(field as KeywordsField).keywords}
                      disabled={!activeFields[field.name]}
                    />
                  )}
                  isFieldActive={isFieldActive}
                  setFieldActive={setFieldActive}
                />
              </Grid>
            )}
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.CancelButton
            title={pgettext('Form cancel button title', 'Cancel this form')}
            onClick={close}
          />
          <Modal.Footer.SubmitButton
            label={pgettext('Form submit button label', 'Submit')}
            title={gettext('Update selected fields in all selected assets')}
            disabled={!canSubmitBulkEdit}
            onClick={handleSubmit(submit)}
          />
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
};

export default BulkEditModal;
