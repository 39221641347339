import { TypographyProps } from '@mui/material/Typography';
import { ReactNode, FC, KeyboardEvent, useState, useEffect } from 'react';

import { Select } from 'medialoopster/components';

import ButtonGroup from './ButtonGroup';
import OverflowTextField from './OverflowTextField';

interface InplaceSelectEditProps {
  minWidth?: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  id?: string;
  editValue: string;
  textComponents: NonNullable<ReactNode>;
  canEdit: boolean;
  dense?: boolean;
  component?: 'div' | 'p'; // Eg. "span" does not support text overflow ellipse
  choices?: ReadonlyArray<{ display: number | string; value: number | string }>;
  defaultChoice?: string;
  variant?: TypographyProps['variant'];
}

const InplaceSelectEdit: FC<InplaceSelectEditProps> = ({
  minWidth,
  onChange,
  onSubmit,
  id = '',
  editValue,
  canEdit,
  textComponents,
  variant = 'body1',
  dense = false,
  component = 'div',
  choices = [],
  defaultChoice,
}: InplaceSelectEditProps) => {
  const [value, setValue] = useState(defaultChoice ?? editValue);
  const [isEditing, setIsEditing] = useState(false);

  const onEditTitle = () => {
    if (!canEdit) return;
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  useEffect(() => {
    if (editValue) {
      setValue(editValue);
    }
  }, [editValue]);

  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false);
    }
  }, [canEdit]);

  const onValueChange = (val: string) => {
    onChange?.(val);
    setValue(val);
  };
  const handleSubmit = () => {
    setIsEditing(false);
    if (value !== editValue) {
      onSubmit?.(value);
    }
  };
  const handleClose = () => {
    setIsEditing(false);
  };
  const handleKeys = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      setIsEditing(false);
    }
  };
  return (
    <>
      {!isEditing && (
        <OverflowTextField
          component={component}
          variant={variant}
          onDoubleClick={onEditTitle}
          textComponents={textComponents}
          highlightText={canEdit}
          showPlaceHolder={!editValue && canEdit}
        />
      )}
      {isEditing && (
        <div style={{ display: 'flex' }}>
          <div style={{ minWidth, marginRight: '0.5em' }} data-testid="selectWrapper">
            <Select
              id={id}
              name={id}
              onChange={onValueChange}
              value={value}
              dense={dense}
              choices={choices}
              onKeyUp={handleKeys}
            />
          </div>
          <ButtonGroup onSubmit={handleSubmit} onClose={handleClose} dense={dense} />
        </div>
      )}
    </>
  );
};

export default InplaceSelectEdit;
