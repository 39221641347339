import { FC } from 'react';

import { FormField } from '../../forms';
import ChoiceWidget from './widgets/ChoiceWidget';
import DateTimeWidget from './widgets/DateTimeWidget';
import DateWidget from './widgets/DateWidget';
import StringWidget from './widgets/StringWidget';

type Props = {
  readonly field: FormField;
  readonly disabled?: boolean;
};

const FormFieldWidget: FC<Props> = ({ field, disabled = false }: Props) => {
  switch (field.type) {
    case 'choice':
      return (
        <ChoiceWidget
          name={field.name}
          label={field.label}
          allowNull={field.allowNull}
          choices={field.choices}
          defaultValue={field.defaultValue}
          disabled={disabled}
        />
      );
    case 'date':
      return (
        <DateWidget
          name={field.name}
          label={field.label}
          allowNull={field.allowNull}
          defaultValue={field.defaultValue}
          disabled={disabled}
        />
      );
    case 'datetime':
      return (
        <DateTimeWidget
          name={field.name}
          label={field.label}
          allowNull={field.allowNull}
          defaultValue={field.defaultValue}
          disabled={disabled}
        />
      );
    default:
      return (
        <StringWidget
          name={field.name}
          label={field.label}
          maxLength={field.maxLength}
          defaultValue={field.defaultValue}
          disabled={disabled}
        />
      );
  }
};

export default FormFieldWidget;
