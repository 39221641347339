import { combineReducers } from 'redux';

import { createResourcesReducer, createRootLinkReducer } from 'medialoopster/rest';

import { SET_CURRENT_ASSET, SetCurrentAsset } from '../details/types';
import {
  CONTENT_ANALYSIS_CONNECTOR_COLLECTION_REL,
  SET_ACTIVE_PERSON,
  SET_CONFIDENCE_THRESHOLD,
  SET_CURRENT_ASSET_PERSON_COUNT,
  SET_HAS_RENAME_ERROR,
  SetActivePerson,
  SetConfidenceThreshold,
  SetCurrentAssetPersonCount,
  SetHasRenameError,
  TOGGLE_ALL_BOUNDING_BOXES_VISIBLE,
  TOGGLE_PERSONS_VISIBLE,
  ToggleAllBoundingBoxesVisible,
  TogglePersonsVisible,
  PersonsRootState,
} from './types';

export const confidenceThresholdReducer = (
  state = '0.0',
  { type, payload }: SetConfidenceThreshold,
): string => {
  switch (type) {
    case SET_CONFIDENCE_THRESHOLD:
      return payload.confidenceThreshold;
    default:
      return state;
  }
};

export const activePersonReducer = (
  state: string | null = null,
  action: SetActivePerson | SetCurrentAsset,
): string | null => {
  switch (action.type) {
    case SET_ACTIVE_PERSON:
      return action.payload.uri;
    case SET_CURRENT_ASSET:
      return null;
    default:
      return state;
  }
};

export const personCountReducer = (
  state = 0,
  { type, payload }: SetCurrentAssetPersonCount,
): number => {
  switch (type) {
    case SET_CURRENT_ASSET_PERSON_COUNT:
      return payload.count;
    default:
      return state;
  }
};

export const isPersonsVisibleReducer = (
  state = false,
  { type }: TogglePersonsVisible | SetCurrentAsset,
): boolean => {
  switch (type) {
    case TOGGLE_PERSONS_VISIBLE:
      return !state;
    case SET_CURRENT_ASSET:
      return false;
    default:
      return state;
  }
};

export const isAllBoundingBoxesVisibleReducer = (
  state = false,
  { type }: ToggleAllBoundingBoxesVisible | SetCurrentAsset,
): boolean => {
  switch (type) {
    case TOGGLE_ALL_BOUNDING_BOXES_VISIBLE:
      return !state;
    case SET_CURRENT_ASSET:
      return false;
    default:
      return state;
  }
};

export const hasRenameErrorReducer = (
  state = false,
  action: SetHasRenameError | SetActivePerson | SetCurrentAsset,
): boolean => {
  switch (action.type) {
    case SET_HAS_RENAME_ERROR:
      return action.payload.hasError;
    case SET_ACTIVE_PERSON:
      return false;
    case SET_CURRENT_ASSET:
      return false;
    default:
      return state;
  }
};

export default combineReducers<PersonsRootState>({
  confidenceThreshold: confidenceThresholdReducer,
  segments: createResourcesReducer('segment'),
  segmentCollections: combineReducers({
    videoasset: createResourcesReducer('videoasset-segments'),
    imageasset: createResourcesReducer('imageasset-segments'),
    'segment-collection': createResourcesReducer('segment-collection'),
  }),
  faces: createResourcesReducer('face'),
  faceCollections: createResourcesReducer('face-collection'),
  persons: createResourcesReducer('person'),
  activePersonURI: activePersonReducer,
  currentAssetPersonCount: personCountReducer,
  isPersonsVisible: isPersonsVisibleReducer,
  isAllBoundingBoxesVisible: isAllBoundingBoxesVisibleReducer,
  hasRenameError: hasRenameErrorReducer,
  connectorCollectionLink: createRootLinkReducer(CONTENT_ANALYSIS_CONNECTOR_COLLECTION_REL),
  connectorCollections: createResourcesReducer('contentanalysisconnector-collection'),
  connectors: createResourcesReducer('contentanalysisconnector'),
});
