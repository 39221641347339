import { createCollectionSelector } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';

export const getAppConnectorCollection = createCollectionSelector(
  (state: RootState) => state.appConnectors.collectionLink?.href,
  (state: RootState) => state.appConnectors.pages,
  (state: RootState) => state.appConnectors.objects,
);

export default getAppConnectorCollection;
