import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { detailsSelectors, detailsTypes, detailsActions } from '../../state/modules/details';
import { ImageAsset } from '../../state/modules/image/types';
import { searchSelectors } from '../../state/modules/search';
import AssetInfoField from './AssetFields/AssetInfoField';
import CalendarDateTime from './AssetFields/CalendarDateTime';
import Text from './AssetFields/Text';
import TextArea from './AssetFields/TextArea';
import AssetInfo from './AssetInfo';

const ImageInfo: FC = () => {
  const dispatch = useDispatch();
  const asset = useSelector(detailsSelectors.getCurrentAsset) as ImageAsset;
  const keywordsByCategory = useSelector(detailsSelectors.getCurrentImageAssetKeywordsByCategories);
  const assetHighlights = useSelector(searchSelectors.getCurrentAssetHighlight);
  const href = getResourceURI(asset);
  const isEditMode = useSelector(detailsSelectors.isEditMode);
  const onSubmitEdit = useCallback(
    (fieldKey: keyof detailsTypes.ImageEditFields) => (value: string | null) => {
      const resourceURI = getResourceURI(asset);
      const updates: detailsTypes.ImageEditFields = { [fieldKey]: value };
      dispatch(detailsActions.editAsset(getResourceTypeName(asset), resourceURI, updates));
    },
    [asset, dispatch],
  );
  return (
    <AssetInfo
      title={gettext('Image Info')}
      asset={asset}
      assetInfoWithoutKw={
        <>
          <AssetInfoField label={gettext('Image size')} visible testId={`${href}-image-size`}>
            <Text value={`${asset.size_x}x${asset.size_y} Pixel`} variant="caption" />
          </AssetInfoField>
          <AssetInfoField label={gettext('Image file format')} visible testId={`${href}-format`}>
            <Text
              value={asset.format}
              highlights={assetHighlights?.fields?.format}
              variant="caption"
            />
          </AssetInfoField>
          <AssetInfoField label={gettext('Color mode')} visible testId={`${href}-mode`}>
            <Text value={asset.mode} highlights={assetHighlights?.fields?.mode} variant="caption" />
          </AssetInfoField>
          <AssetInfoField
            label={gettext('Description')}
            visible={!!asset.description || isEditMode}
            testId={`${href}-description`}
          >
            <TextArea
              value={asset.description}
              highlights={assetHighlights?.fields?.description}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('description')}
            />
          </AssetInfoField>
          <br />
          <AssetInfoField
            label={gettext('Camera manufacturer')}
            visible={!!asset.make || isEditMode}
            testId={`${href}-make`}
          >
            <Text
              value={asset.make}
              highlights={assetHighlights?.fields?.make}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('make')}
            />
          </AssetInfoField>
          <AssetInfoField
            label={gettext('Camera model')}
            visible={!!asset.model || isEditMode}
            testId={`${href}-model`}
          >
            <Text
              value={asset.model}
              highlights={assetHighlights?.fields?.model}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('model')}
            />
          </AssetInfoField>
          <AssetInfoField
            label={gettext('Shooting date')}
            visible={!!asset.date_orig || isEditMode}
            testId={`${href}-date-orig`}
          >
            <CalendarDateTime
              value={asset.date_orig}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('date_orig')}
            />
          </AssetInfoField>
          <AssetInfoField
            label={gettext('User comment')}
            visible={!!asset.user_comment || isEditMode}
            testId={`${href}-user-comment`}
          >
            <TextArea
              value={asset.user_comment}
              highlights={assetHighlights?.fields?.user_comment}
              canEdit={isEditMode}
              variant="caption"
              onSubmit={onSubmitEdit('user_comment')}
            />
          </AssetInfoField>
        </>
      }
      keywordsByCategory={keywordsByCategory}
    />
  );
};

export default ImageInfo;
