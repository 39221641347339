import { AssetType } from '../../types/asset/baseTypes';
import { AssetChoice, CollectionChoice } from '../operations/types';

export const OPEN_BULK_TRANSFER_MODAL = 'FRONTEND/TRANSFER/OPEN_BULK_TRANSFER_MODAL';
export const CLOSE_BULK_TRANSFER_MODAL = 'FRONTEND/TRANSFER/CLOSE_BULK_TRANSFER_MODAL';
export const TRANSFER_ASSETS = 'FRONTEND/TRANSFER/TRANSFER_ASSETS';
export const CHECK_TRANSFER_ASSETS = 'FRONTEND/TRANSFER/CHECK_TRANSFER_ASSETS';
export const SET_TRANSFER_ERRORS = 'FRONTEND/TRANSFER/SET_TRANSFER_ERRORS';
export const CHECK_TRANSFER_COLLECTION = 'FRONTEND/TRANSFER/CHECK_TRANSFER_COLLECTION';
export const TRANSFER_COLLECTION = 'FRONTEND/TRANSFER/TRANSFER_COLLECTION';
export const SET_TRANSFER_ACTION_URL = 'FRONTEND/TRANSFER/SET_TRANSFER_ACTION_URL';
export const TRANSFER_STARTED = 'FRONTEND/TRANSFER/TRANSFER_STARTED';

export const TRANSFER_ASSETS_REL = 'transfer_assets';
export const TRANSFER_VIDEO_ASSETS_REL = 'transfer_videoassets';
export const TRANSFER_IMAGE_ASSETS_REL = 'transfer_imageassets';
export const TRANSFER_AUDIO_ASSETS_REL = 'transfer_audioassets';

export interface OpenBulkTransferModal {
  readonly type: typeof OPEN_BULK_TRANSFER_MODAL;
  readonly payload: {
    readonly assetChoices: ReadonlyArray<AssetChoice>;
    readonly collectionChoice: CollectionChoice | null;
  };
}

export interface CloseBulkTransferModal {
  readonly type: typeof CLOSE_BULK_TRANSFER_MODAL;
}

export interface SetTransferActionURL {
  readonly type: typeof SET_TRANSFER_ACTION_URL;
  readonly payload: {
    readonly actionURL: string;
  };
}

export interface TransferAssets {
  readonly type: typeof TRANSFER_ASSETS;
  readonly payload: {
    readonly actionURL: string;
    readonly assetURLs: ReadonlyArray<string>;
    readonly filename: string;
  };
}

export interface CheckTransferAssets {
  readonly type: typeof CHECK_TRANSFER_ASSETS;
  readonly payload: {
    readonly actionURL: string;
    readonly assetURLs: ReadonlyArray<string>;
    readonly filename: string;
    readonly collection: string | null;
  };
}

export interface AssetErrors {
  readonly [key: string]: string;
}

export interface SetTransferErrors {
  readonly type: typeof SET_TRANSFER_ERRORS;
  readonly payload: {
    readonly actionURL: string;
    readonly assetErrors: AssetErrors;
    readonly collectionError: string | null;
    readonly otherError: string | null;
  };
}

export interface TransferStarted {
  readonly type: typeof TRANSFER_STARTED;
  readonly payload: {
    readonly monitoringURL?: string;
  };
}
export interface TransferErrorsState {
  readonly [key: string]: {
    assetErrors: AssetErrors;
    collectionError: string | null;
    otherError: string | null;
  };
}

export interface DeviceChoice {
  readonly value: string;
  readonly display: string;

  readonly [key: string]: number | string;
}

export interface TransferAssetChoice {
  readonly url: string;
  readonly display: string;
  readonly error: string;
  readonly typeName: AssetType;

  readonly [key: string]: string;
}

export interface CheckTransferCollection {
  readonly type: typeof CHECK_TRANSFER_COLLECTION;
  readonly payload: {
    readonly actionURL: string;
    readonly assetURLs: ReadonlyArray<string>;
    readonly collectionURL: string;
  };
}

export interface TransferCollection {
  readonly type: typeof TRANSFER_COLLECTION;
  readonly payload: {
    readonly actionURL: string;
    readonly assetURLs: ReadonlyArray<string>;
    readonly collectionURL: string;
  };
}

export interface TransferRootState {
  readonly bulkTransferModal: {
    readonly visible: boolean;
    readonly checking: boolean;
    readonly actionURL: string;
    readonly assetChoices: ReadonlyArray<AssetChoice>;
    readonly transferErrors: TransferErrorsState;
    readonly monitoringURL: string | null;
    readonly collectionChoice: CollectionChoice | null;
  };
}
