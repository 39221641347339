import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ReactNode, FC, useRef } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import medialoopsterTheme from 'medialoopster/medialoopsterTheme';

import { useTextOverflow } from '../../hooks/useTextOverflow';

interface OverflowTextFieldProps {
  textComponents: NonNullable<ReactNode>;
  component?: 'div' | 'p'; // Eg. "span" does not support text overflow ellipse
  clickPlaceHolder?: NonNullable<ReactNode>;
  onDoubleClick?: () => void;
  showPlaceHolder?: boolean;
  highlightText?: boolean;
  variant?: TypographyProps['variant'];
  placement?: TooltipProps['placement'];
  whiteSpace?: React.CSSProperties['whiteSpace'];
}

const useStyles = makeStyles<
  typeof medialoopsterTheme,
  { whiteSpace: React.CSSProperties['whiteSpace'] }
>((theme) => ({
  text: ({ whiteSpace }) => ({
    whiteSpace,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  }),
  highlightText: {
    '&:hover': {
      background: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
}));

const OverflowTextField: FC<OverflowTextFieldProps> = ({
  component = 'div',
  clickPlaceHolder = gettext('Double-click...'),
  showPlaceHolder = false,
  highlightText = false,
  onDoubleClick,
  variant,
  textComponents,
  placement,
  whiteSpace = 'pre-line',
}: OverflowTextFieldProps) => {
  const classes = useStyles({ whiteSpace });

  const titleRef = useRef<HTMLDivElement>(null);
  const [showTooltip, checkShowTooltip] = useTextOverflow(titleRef);

  return (
    <Tooltip title={showTooltip ? textComponents : ''} placement={placement}>
      <Typography
        className={clsx(classes.text)}
        component={component}
        variant={variant}
        onDoubleClick={onDoubleClick}
        data-testid="editableText"
        ref={titleRef}
        onMouseOver={checkShowTooltip}
      >
        <span
          className={clsx({
            [classes.highlightText]: highlightText,
          })}
        >
          {showPlaceHolder ? clickPlaceHolder : textComponents}
        </span>
      </Typography>
    </Tooltip>
  );
};

export default OverflowTextField;
