/**
 * This module implements the commands necessary to change a single or all occurrences of a speaker
 *
 * The module provides commands used by the ChangeSpeakerModal component.
 */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $dfs, mergeRegister } from '@lexical/utils';
import { $getRoot, COMMAND_PRIORITY_NORMAL, LexicalEditor } from 'lexical';
import React, { useEffect } from 'react';

import {
  CHANGE_ALL_SPEAKER_OCCURRENCES,
  CHANGE_ONE_SPEAKER_OCCURRENCE,
  ChangeAllSpeakerOccurrencesPayload,
  ChangeOneSpeakerOccurrencePayload,
} from '../commands';
import { $isTranscriptTimeBlockNode } from '../nodes/TranscriptTimeBlockNode';

/**
 * Change all occurrences of a speaker name.
 *
 * This function changes the speaker name of all time blocks whose speaker name matches `oldName`.
 *
 * @param editor The editor instance containing the editor state to change
 * @param oldName The old name of the speaker
 * @param newName The new name of the speaker (all occurrences)
 */
const handleChangeAllSpeakerOccurrences = (
  editor: LexicalEditor,
  { oldName, newName }: ChangeAllSpeakerOccurrencesPayload,
) => {
  editor.update(() => {
    $dfs($getRoot()).forEach(({ node }) => {
      if ($isTranscriptTimeBlockNode(node) && node.getSpeakerName() === oldName) {
        node.setSpeakerName(newName);
      }
    });
  });
  return true;
};

/**
 * Change a single occurrence of a speaker name.
 *
 * This function changes the speaker name of a single time block.
 *
 * @param editor The editor instance to work on
 * @param timeBlockNodeKey The node key of the TranscriptTimeBlockNode to change.
 * @param newName The new name for the speaker of the time block.
 */
const handleChangeOneSpeakerOccurrence = (
  editor: LexicalEditor,
  { timeBlockNodeKey, newName }: ChangeOneSpeakerOccurrencePayload,
) => {
  editor.update(() => {
    $dfs($getRoot()).forEach(({ node }) => {
      if ($isTranscriptTimeBlockNode(node) && node.getKey() === timeBlockNodeKey) {
        node.setSpeakerName(newName);
      }
    });
  });
  return true;
};

/**
 * A plugin that provides the logic for the commands related to speaker name changes
 *
 * The plugin provides the logic/handlers for the CHANGE_ALL_SPEAKER_OCCURRENCES and the
 * CHANGE_ONE_SPEAKER_OCCURRENCE `lexical` commands.
 */
export const TranscriptChangeSpeakerPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CHANGE_ALL_SPEAKER_OCCURRENCES,
        (payload) => handleChangeAllSpeakerOccurrences(editor, payload),
        COMMAND_PRIORITY_NORMAL,
      ),
      editor.registerCommand(
        CHANGE_ONE_SPEAKER_OCCURRENCE,
        (payload) => handleChangeOneSpeakerOccurrence(editor, payload),
        COMMAND_PRIORITY_NORMAL,
      ),
    );
  }, [editor]);

  return null;
};

export default TranscriptChangeSpeakerPlugin;
