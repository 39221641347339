import _ from 'lodash';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { concat, EMPTY, Observable, of, pipe, UnaryFunction } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { gettext } from 'medialoopster/Internationalization';
import { AUDIO_TYPE, IMAGE_TYPE, VIDEO_TYPE } from 'medialoopster/modules';
import {
  getLink,
  getLinkHref,
  getResourceTypeName,
  HttpMethodCode,
  mlRel,
  RECEIVE_ROOT_RESOURCE,
  ReceiveRootResource,
  ResourceOptionsLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
  unauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { pollWhile } from '../../operators';
import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { getCurrentAsset } from '../details/selectors';
import {
  setCanSeeCurrenAssetProxyDownload,
  setCurrentAssetProxyDownloadDryRunErrors,
  setCurrentAssetProxyDownloadErrors,
  updateCurrentAssetProxyDownloadActivity,
} from './actions';
import { getAssetProxyDownloadActivitiesURL } from './selectors';
import {
  AssetProxyDownloadActivity,
  CreateAssetProxyDownloadActivity,
  SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING,
  SetCurrentAssetProxyDownloadPreparing,
} from './types';

export const proxyDownloadActivitiesOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchOptions }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const downloadActivitiesURL = getLinkHref(root, mlRel('proxydownloadactivities'));
      if (!downloadActivitiesURL) {
        return EMPTY;
      }
      return fetchOptions(downloadActivitiesURL);
    }),
  );

export const highresDownloadActivitiesOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchOptions }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const downloadActivitiesURL = getLinkHref(root, mlRel('highresdownloadactivities'));
      if (!downloadActivitiesURL) {
        return EMPTY;
      }
      return fetchOptions(downloadActivitiesURL);
    }),
  );

export const onCurrentDownloadAssetChange = <A>(
  createObservable: (asset: Asset, assetProxyDownloadURL: string | null) => Observable<A>,
): UnaryFunction<Observable<RootState>, Observable<A>> =>
  pipe(
    map((state): [Asset | null, string | null] => [
      getCurrentAsset(state),
      getAssetProxyDownloadActivitiesURL(state),
    ]),
    distinctUntilChanged((prev, curr) => {
      return _.isEqual(prev, curr);
    }),
    switchMap(([currentAsset, assetProxyDownloadURL]) => {
      // NOTE: Need to check !href here, not via `filter` operator,
      // so the previous current asset's observable is cancelled.
      if (!currentAsset) {
        return EMPTY;
      }
      return createObservable(currentAsset, assetProxyDownloadURL);
    }),
  );

export const currentAssetProxyDownloadMenuVisibilityEpic: Epic<
  Action,
  Action,
  RootState,
  RESTEpicDependencies
> = (_action$, state$, { postResourceDryRun }) =>
  state$.pipe(
    onCurrentDownloadAssetChange((...args) => of(args)),
    switchMap(([currentAsset, assetProxyDownloadActivitiyURL]) => {
      const currentAssetHref = getLinkHref(currentAsset, 'self');
      const currentAssetType = getResourceTypeName(currentAsset);
      if (!currentAssetHref) return EMPTY;
      if (currentAssetType === VIDEO_TYPE && assetProxyDownloadActivitiyURL) {
        const assetProxyResource: CreateAssetProxyDownloadActivity = {
          _links: { asset: { href: currentAssetHref } },
        };
        return postResourceDryRun<CreateAssetProxyDownloadActivity, Action, Action>(
          assetProxyDownloadActivitiyURL,
          assetProxyResource,
          {
            createErrorObservable: (errors) => {
              const errs = errors.response.errors;
              const permissionErrors = errs?.filter(({ code }) => code === 'permission_denied');
              if (permissionErrors?.length) {
                return of(setCanSeeCurrenAssetProxyDownload(false));
              }
              if (errs?.length) {
                return concat(
                  of(setCurrentAssetProxyDownloadDryRunErrors(errs)),
                  of(setCanSeeCurrenAssetProxyDownload(true)),
                );
              }
              return EMPTY;
            },
            createResourceObservable: () => of(setCanSeeCurrenAssetProxyDownload(true)),
            headers: {
              Accept: 'application/hal+json; version=3',
              'Content-Type': 'application/hal+json; version=3',
            },
          },
        );
      }
      if (currentAssetType && [IMAGE_TYPE, AUDIO_TYPE].includes(currentAssetType)) {
        const downloadLink = getLink(currentAsset, mlRel('download-proxy')) || null;
        return of(
          setCanSeeCurrenAssetProxyDownload(downloadLink?.methods?.GET?.code === HttpMethodCode.OK),
        );
      }
      return EMPTY;
    }),
  );

export const prepareCurrentAssetProxyDownloadEpic: Epic<
  Action,
  Action,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<
      Action,
      SetCurrentAssetProxyDownloadPreparing['type'],
      SetCurrentAssetProxyDownloadPreparing
    >(SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const currentAsset = getCurrentAsset(state);
      const assetProxyDownloadActivityURL = getAssetProxyDownloadActivitiesURL(state);
      if (currentAsset && assetProxyDownloadActivityURL) {
        const currentAssetHref = getLinkHref(currentAsset, 'self');
        const currentAssetType = getResourceTypeName(currentAsset);

        if (currentAssetType === VIDEO_TYPE && currentAssetHref) {
          const assetProxyResource: CreateAssetProxyDownloadActivity = {
            _links: { asset: { href: currentAssetHref } },
          };
          return postResource<
            AssetProxyDownloadActivity,
            CreateAssetProxyDownloadActivity,
            Action,
            Action
          >(
            assetProxyDownloadActivityURL,
            assetProxyResource,
            (res) => {
              if (res) {
                const currentAssetProxyActivity = getLinkHref(res, 'self');
                if (currentAssetProxyActivity) {
                  const shouldUpdatePrearingDownloadProgress = (
                    activity: AssetProxyDownloadActivity,
                    currentState: RootState,
                  ) => {
                    return !(
                      activity.download_ready ||
                      activity.status === 3 ||
                      !currentState.download.currentAssetDownload.isOpen
                    );
                  };
                  return ajax
                    .get<AssetProxyDownloadActivity>(currentAssetProxyActivity, {
                      Accept: 'application/hal+json; version=3',
                      'Content-Type': 'application/hal+json; version=3',
                      ...getTokenAuthHeader(loginSelectors.getToken(state)),
                      'Cache-Control': 'no-cache',
                    })
                    .pipe(
                      map(({ response }): AssetProxyDownloadActivity => response),
                      withLatestFrom(state$),
                      pollWhile(([activity, whilePollingState]) => {
                        return shouldUpdatePrearingDownloadProgress(activity, whilePollingState);
                      }),
                      map(([activity]) => {
                        return updateCurrentAssetProxyDownloadActivity(activity);
                      }),
                      catchError((err) => {
                        if (err && err.status === 401) {
                          return of(unauthorizedRequestAction());
                        }
                        return of(
                          setCurrentAssetProxyDownloadErrors([
                            { detail: gettext('Unknown Error') },
                          ]),
                        );
                      }),
                    );
                }
              }
              return EMPTY;
            },
            (errors) => {
              return of(setCurrentAssetProxyDownloadErrors(errors?.response?.errors || []));
            },
            { version: 3 },
          );
        }
      }
      return EMPTY;
    }),
  );

export default combineEpics(
  proxyDownloadActivitiesOptionsEpic,
  highresDownloadActivitiesOptionsEpic,
  currentAssetProxyDownloadMenuVisibilityEpic,
  prepareCurrentAssetProxyDownloadEpic,
);
