import { gettext } from 'medialoopster/Internationalization';
import formatUserDisplayName from 'medialoopster/formatUserDisplayName';
import {
  getLinkHref,
  getResource,
  getResourceTypeName,
  mlRel,
  ResourceMap,
} from 'medialoopster/rest';

import { AudioAsset } from '../../state/modules/audio/types';
import {
  FavoritesItemAsset,
  FavoritesItemAssetType,
  FavoritesItemResource,
  FavoritesItemState,
  FavoritesListResource,
} from '../../state/modules/favorites/types';
import { ImageAsset } from '../../state/modules/image/types';
import { User } from '../../state/modules/users/types';
import { Shot, VideoAsset } from '../../state/modules/video/types';
import formatVideoAssetDuration from '../../ui/services/formatVideoAssetDuration';
import formatVideoAssetTimecode from '../../ui/services/formatVideoAssetTimecode';
import { isAudioAsset, isImageAsset, isVideoAsset } from './asset/utils';

/**
 * Services for favorite lists.
 * In the frontend shared lists for a user are lists available via api for this user,
 * which he does not own.
 */

export const createFavoriteListDisplayName = (
  list: FavoritesListResource,
  users: ResourceMap<User>,
): string => {
  const ownerHref = getLinkHref(list, mlRel('owner'));
  const listOwner = ownerHref && getResource(users, ownerHref);
  if (!listOwner) {
    return list.name;
  }
  return `[${formatUserDisplayName(listOwner)}] - ${list.name}`;
};

export const getSharedFavoriteListsForUser = (
  userURI: string | undefined,
  favoriteLists: ReadonlyArray<FavoritesListResource>,
): ReadonlyArray<FavoritesListResource> =>
  favoriteLists.filter((list) => getLinkHref(list, mlRel('owner')) !== userURI);

export const isActiveSharedListForUser = (
  userURI: string | undefined,
  favoriteList: FavoritesListResource,
  sharedActiveLists: ReadonlyArray<number>,
): boolean =>
  getLinkHref(favoriteList, mlRel('owner')) !== userURI &&
  sharedActiveLists.some((id) => id === favoriteList.id);

export const getSharedActiveListsForUser = (
  userURI: string | undefined,
  favoriteLists: ReadonlyArray<FavoritesListResource>,
  sharedActiveLists: ReadonlyArray<number> = [],
): ReadonlyArray<FavoritesListResource> =>
  favoriteLists.filter((list) => isActiveSharedListForUser(userURI, list, sharedActiveLists));

export const getSharedListsWithUserDisplayName = (
  userURI: string | undefined,
  favoriteLists: ReadonlyArray<FavoritesListResource>,
  users: ResourceMap<User>,
): ReadonlyArray<FavoritesListResource> =>
  getSharedFavoriteListsForUser(userURI, favoriteLists).map((list) => ({
    ...list,
    displayName: createFavoriteListDisplayName(list, users),
  }));

export const isListSharedForUser = (userId: number, favoriteList: FavoritesListResource): boolean =>
  favoriteList.shared_with.some((sharedId) => sharedId === userId);

export const isNameValid = (name?: string): boolean =>
  name !== undefined ? name.length > 0 : false;

/**
 * Returns the id of the list next to `currentListId`, either the one to the left or, if there is not one, the one to the right. Returns `0` if there are less than two lists given.
 * The list with id `currentListId` must be in `favoriteLists`.
 */
export const getPreviousListId = (
  favoriteLists: ReadonlyArray<FavoritesListResource>,
  currentListId: number,
): number => {
  const listPosition = favoriteLists.findIndex((list) => list.id === currentListId);
  if (listPosition > 0) {
    return favoriteLists[listPosition - 1].id;
  }
  if (listPosition === 0) {
    const nextList = favoriteLists[listPosition + 1];
    return nextList === undefined ? 0 : nextList.id;
  }
  return 0;
};

export const getVideoDetail = (
  asset: VideoAsset,
  timecode_start?: number,
  timecode_end?: number,
): string => {
  return timecode_start !== undefined && timecode_end !== undefined
    ? `${formatVideoAssetTimecode(
        timecode_start + asset.offset_frames,
        asset,
      )} - ${formatVideoAssetTimecode(timecode_end + asset.offset_frames, asset)}`
    : formatVideoAssetDuration(asset);
};

const getImageDetail = (asset: ImageAsset) => `${asset.format}, ${asset.size_x}x${asset.size_y}`;

const getAudioDetail = (asset: AudioAsset) =>
  asset.artist ? asset.artist : gettext('Unknown Artist');

const getFavoriteItemDetail = (
  asset: FavoritesItemAsset,
  timecode_start?: number,
  timecode_end?: number,
) => {
  if (!asset) {
    return '';
  }
  if (isVideoAsset(asset)) return getVideoDetail(asset, timecode_start, timecode_end);
  if (isAudioAsset(asset)) return getAudioDetail(asset);
  if (isImageAsset(asset)) return getImageDetail(asset);

  return '';
};

const getVideoDisplayName = (asset: VideoAsset) => asset.name;

const getImageDisplayName = (asset: ImageAsset) => asset.name;

const getAudioDisplayName = (asset: AudioAsset) =>
  asset.title ? asset.title : gettext('Unknown Title');

const getFavoriteItemDisplayName = (asset: FavoritesItemAsset, defaultName = '') => {
  if (!asset) {
    return defaultName;
  }
  if (isVideoAsset(asset)) return getVideoDisplayName(asset);
  if (isAudioAsset(asset)) return getAudioDisplayName(asset);
  if (isImageAsset(asset)) return getImageDisplayName(asset);

  return defaultName;
};

export type DraggableFavoritesItemType =
  | FavoritesItemAssetType
  | 'shot'
  | 'pseudoshot'
  | 'favoriteitem';

export interface DraggableFavoritesItem
  extends Pick<
    FavoritesItemState,
    | 'id'
    | 'asset_id'
    | 'timecode_start'
    | 'timecode_end'
    | 'thumbnailURL'
    | 'deleted'
    | 'displayName'
    | 'detail'
    | 'assetIsAvailable'
  > {
  type: DraggableFavoritesItemType;
  assetTypeName: FavoritesItemAssetType;
}

export type DraggableAssetSelection = {
  type: 'asset-selection';
  assetTypeName: FavoritesItemAssetType;
  assetIds: ReadonlyArray<number>;
};

export interface DraggableSequence
  extends Pick<
    FavoritesItemState,
    | 'asset_id'
    | 'timecode_start'
    | 'timecode_end'
    | 'thumbnailURL'
    | 'deleted'
    | 'displayName'
    | 'detail'
    | 'assetIsAvailable'
  > {
  type: 'sequence';
  shots: ReadonlyArray<Shot>;
}

export const buildDraggableFavoritesItem = (
  id: number,
  type: DraggableFavoritesItemType,
  asset: Exclude<FavoritesItemAsset, null>,
  thumbnail_url: string,
  timecode_start?: number,
  timecode_end?: number,
): DraggableFavoritesItem => ({
  id,
  type,
  assetTypeName: getResourceTypeName(asset),
  timecode_start,
  timecode_end,
  asset_id: asset.id,
  thumbnailURL: thumbnail_url,
  deleted: false,
  displayName: getFavoriteItemDisplayName(asset),
  detail: getFavoriteItemDetail(asset, timecode_start, timecode_end),
  assetIsAvailable: asset.is_available,
});

export const buildFavoritesItemState = (
  item: FavoritesItemResource,
  asset: FavoritesItemAsset,
): FavoritesItemState => ({
  ...item,
  asset,
  thumbnailURL: item.thumbnail_url,
  deleted: !item.asset_id,
  displayName: item.asset_id
    ? getFavoriteItemDisplayName(asset, item.name)
    : gettext('Missing Asset'),
  detail: item.asset_id
    ? getFavoriteItemDetail(asset, item.timecode_start, item.timecode_end)
    : item.name,
  assetIsAvailable: asset ? asset.is_available : true,
});
