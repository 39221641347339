import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { Button } from 'medialoopster/components';
import { Favorites, FavoritesOff, Upload } from 'medialoopster/icons';

import { favoritesActions, favoritesSelectors } from '../../state/modules/favorites';
import { uploadSelectors } from '../../state/modules/upload';
import UploadModal from '../UploadModal';
import MainMenu from './MainMenu';

const useStyles = makeStyles((theme) => ({
  headerNavTop: {
    paddingTop: theme.spacing(2),
    alignItems: 'center',
    height: '62px',
    marginLeft: theme.spacing(21),
  },
}));

const HeaderTopNav: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const defaultUploadURL = useSelector(uploadSelectors.getDefaultProductionUploadURL);
  const isFavoriteListActive = useSelector(favoritesSelectors.isActive);
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);

  return (
    <Grid container justifyContent="flex-end" className={classes.headerNavTop}>
      <Grid>
        {defaultUploadURL && (
          <Button color="white" size="medium" onClick={() => setUploadModalVisible(true)}>
            <Upload fontSize="small" />
            &nbsp;{gettext('Upload')}
          </Button>
        )}
        <UploadModal visible={isUploadModalVisible} onClose={() => setUploadModalVisible(false)} />
      </Grid>
      <Grid>
        <Button
          color="white"
          size="medium"
          onClick={() => dispatch(favoritesActions.toggleFavorites())}
        >
          {isFavoriteListActive ? (
            <FavoritesOff fontSize="medium" />
          ) : (
            <Favorites fontSize="medium" />
          )}
          &nbsp;{gettext('Favorites')}
        </Button>
      </Grid>
      <MainMenu />
    </Grid>
  );
};

export default HeaderTopNav;
