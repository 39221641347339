import Button, { ButtonProps } from '@mui/material/Button';
import { FC, MouseEvent, ReactNode } from 'react';

import LinkButton from '../../buttons/LinkButton';

type Props = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label: string;
  color?: ButtonProps['color'];
  title?: string;
  disabled?: boolean;
  icon?: ReactNode;
  href?: string;
  forceDownload?: boolean;
};

const ModalSubmitButton: FC<Props> = ({
  label,
  color = 'primary',
  title = '',
  disabled = false,
  icon = undefined,
  onClick = undefined,
  href = undefined,
  forceDownload = undefined,
}: Props) => {
  return href && !onClick ? (
    <LinkButton
      title={title}
      href={href}
      disabled={disabled}
      color={color}
      variant="contained"
      icon={icon}
      forceDownload={forceDownload}
    >
      {label}
    </LinkButton>
  ) : (
    <Button
      variant="contained"
      color={color}
      disableElevation
      onClick={onClick}
      title={title}
      disabled={disabled}
      startIcon={icon}
    >
      {label}
    </Button>
  );
};

export default ModalSubmitButton;
