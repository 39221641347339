import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { pgettext } from 'medialoopster/Internationalization';

import Input from './Input';
import { InputElementProps } from './internal/InternalTextField';

const useStyles = makeStyles(() => ({
  rootDense: {
    height: '24px',
    '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
      paddingRight: '20px',
    },
    '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
      '&.MuiOutlinedInput-marginDense': {
        paddingTop: '1px',
        paddingBottom: '1px',
        '& .MuiAutocomplete-input': {
          paddingTop: '1px',
          paddingBottom: '1px',
        },
      },
      '& .MuiAutocomplete-endAdornment': {
        right: '0px',
        top: 'calc(50% - 12px)',
        '& .MuiAutocomplete-clearIndicator': {
          padding: '2px',
          marginRight: '0px',
        },
      },
    },
  },
}));

type Props = {
  id?: string;
  label?: string;
  name?: string;
  onChange: (value: string | null) => void;
  showError?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  maxLength?: number;
  autoFocus?: boolean;
  options?: string[];
  value?: string;
  dense?: boolean;
  onKeyUp?: InputElementProps['onKeyUp'];
  onFocus?: () => void;
  validation?: (value: string | null) => void;
  filtering?: (value: string, options: string[]) => string[];
};

const defaultFiltering = (value: string, options: string[]) =>
  options.filter((name) => name.toLowerCase().includes(value.toLowerCase()));

const AutoSuggestInput: FC<Props> = ({
  id,
  label = '',
  name = '',
  onChange,
  showError = false,
  errorMsg = '',
  maxLength = 524288,
  autoFocus = false,
  options = [],
  value = '',
  dense = false,
  disabled = false,
  onKeyUp = () => undefined,
  onFocus = () => undefined,
  validation = () => undefined,
  filtering = defaultFiltering,
}: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [setInputValue, value]);

  const handleAutosuggestChoice = (
    _event: ChangeEvent<unknown>,
    newValue: string | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'blur' && !newValue) {
      // MUI Autocomplete bug: `inputValue` must be controlled, because it is cleared on blur
      // if `suggests` only contains `value` (even though it shouldn't due to freeSolo)
      return;
    }
    setInputValue(newValue || '');
    onChange(newValue);
    validation(newValue);
  };

  const handleInputChange = (
    _event: ChangeEvent<unknown>,
    newValue: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === 'reset' && !newValue) {
      return;
    }
    setInputValue(newValue);
    const validVal = newValue.slice(0, maxLength);
    onChange(validVal);
  };

  const onBlurInput = () => {
    validation(inputValue);
  };

  const onInputFocus = () => {
    onFocus();
  };

  return (
    <Autocomplete
      freeSolo
      disabled={disabled}
      autoComplete
      includeInputInList
      id={id}
      options={options}
      isOptionEqualToValue={(option) => option === inputValue}
      filterOptions={() => filtering(inputValue, options)}
      value={value}
      inputValue={inputValue}
      onChange={handleAutosuggestChoice}
      onInputChange={handleInputChange}
      openText={pgettext('Verb; open element', 'Open')}
      closeText={pgettext('Verb; close element', 'Close')}
      clearText={pgettext('Verb; clear input', 'Clear')}
      className={clsx({ [classes.rootDense]: dense })}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <Input
          id={params.id}
          readOnly={params.disabled}
          InputProps={params.InputProps}
          inputElementProps={params.inputProps}
          name={name}
          autoFocus={autoFocus}
          label={label}
          showError={showError}
          errorMsg={showError && !!errorMsg ? errorMsg : undefined}
          maxLength={maxLength}
          onFocus={onInputFocus}
          onBlur={onBlurInput}
          onKeyUp={onKeyUp}
          dense={dense}
        />
      )}
    />
  );
};

export default AutoSuggestInput;
