import Button from '@mui/material/Button';
import { FC } from 'react';

import { pgettext } from 'medialoopster/Internationalization';

type Props = {
  readonly onClick: () => void;
  readonly title?: string;
  readonly label?: string;
};

const ModalCancelButton: FC<Props> = ({
  onClick,
  title = '',
  label = pgettext('Form cancel button text', 'Cancel'),
}: Props) => (
  <Button variant="outlined" onClick={onClick} title={title}>
    {label}
  </Button>
);

export default ModalCancelButton;
