import { ActionsMetadataV2 } from 'medialoopster/rest';

import { AssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';

export const FETCH_ARCHIVE_ASSET_OPTIONS = 'FRONTEND/ARCHIVE/FETCH_ARCHIVE_ASSET_OPTIONS';
export const ARCHIVE_ASSET_OPTIONS_LOADED = 'FRONTEND/ARCHIVE/ARCHIVE_ASSET_OPTIONS_LOADED';
export const FETCH_RESTORE_ARCHIVED_ASSET_OPTIONS =
  'FRONTEND/ARCHIVE/FETCH_RESTORE_ARCHIVED_ASSET_OPTIONS';
export const RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED =
  'FRONTEND/ARCHIVE/RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED';
export const ARCHIVE_ASSETS = 'FRONTEND/ARCHIVE/ARCHIVE_ASSETS';
export const REVOKE_ARCHIVE_ACTIVITIES = 'FRONTEND/ARCHIVE/REVOKE_ARCHIVE_ACTIVITIES';
export const RESTORE_ARCHIVED_ASSETS = 'FRONTEND/ARCHIVE/RESTORE_ARCHIVED_ASSETS';

//-------------
// Action Types
//-------------

export interface FetchArchiveAssetOptions {
  readonly type: typeof FETCH_ARCHIVE_ASSET_OPTIONS;
}

export interface ArchiveAssetOptionsLoaded {
  readonly type: typeof ARCHIVE_ASSET_OPTIONS_LOADED;
  readonly payload: {
    readonly actions: ActionsMetadataV2;
  };
}

export interface FetchRestoreArchivedAssetOptions {
  readonly type: typeof FETCH_RESTORE_ARCHIVED_ASSET_OPTIONS;
}

export interface RestoreArchivedAssetOptionsLoaded {
  readonly type: typeof RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED;
  readonly payload: {
    readonly actions: ActionsMetadataV2;
  };
}

export interface ArchiveAssets {
  readonly type: typeof ARCHIVE_ASSETS;
  readonly payload: {
    readonly assets: ReadonlyArray<Asset>;
  };
}

export interface RevokeArchiveActivities {
  readonly type: typeof REVOKE_ARCHIVE_ACTIVITIES;
  readonly payload: {
    readonly assets: ReadonlyArray<Asset>;
  };
}

export interface RestoreArchivedAssets {
  readonly type: typeof RESTORE_ARCHIVED_ASSETS;
  readonly payload: {
    readonly assets: ReadonlyArray<Asset>;
  };
}

//---------------
// Resource Types
//---------------

export type ArchiveAssetResourceType = Omit<AssetType, 'collection'> | 'projectasset';

//------------------
// Redux State Types
//------------------

export interface ArchiveRootState {
  readonly canArchive: boolean;
  readonly archiveAssetTypes: ReadonlyArray<AssetType>;
  readonly canRestore: boolean;
  readonly restoreAssetTypes: ReadonlyArray<AssetType>;
}
