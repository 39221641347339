import { combineReducers } from 'redux';

import collection from './collection';
import collectionsearchresult from './collectionsearchresult';
import device from './device';
import file from './file';
import productioncontent from './productioncontent';
import siteexportconfigs from './siteexportconfigs';
import transcript from './transcript';
import transcriptspeaker from './transcriptspeaker';
import transcriptunit from './transcriptunit';

export * as transcript from './transcript';
export * as transcriptspeaker from './transcriptspeaker';
export * as transcriptunit from './transcriptunit';

export { default as restEpics } from './epics';

export default combineReducers({
  siteexportconfigs,
  collection,
  collectionsearchresult,
  productioncontent,
  file,
  device,
  transcript,
  transcriptunit,
  transcriptspeaker,
});
