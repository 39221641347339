import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AlertFeedback, alertMessage, AlertMessage } from 'medialoopster/AlertMessage';
import { gettext, ngettext } from 'medialoopster/Internationalization';
import {
  getResourceTypeName,
  ResourceOptionsV2,
  RESTError,
  unauthorizedRequestAction,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { RootState } from '../../types/rootState';
import { isAssetCollection } from '../rest/collection/types';
import {
  assetsShared,
  closeShareAssetsModal,
  fetchShareAssetsOptions,
  setShareAssetsErrors,
  shareAssetsOptionsLoaded,
} from './actions';
import {
  ASSETS_SHARED,
  AssetsShared,
  CloseShareAssetsModal,
  FETCH_SHARE_ASSETS_OPTIONS,
  FetchShareAssetsOptions,
  OPEN_SHARE_ASSETS_MODAL,
  SetShareAssetsErrors,
  SHARE_ASSETS,
  ShareAssets,
  ShareAssetsOptionsLoaded,
} from './types';

export const openShareAssetsModalEpic: Epic<Action, FetchShareAssetsOptions> = (action$) =>
  action$.pipe(
    ofType(OPEN_SHARE_ASSETS_MODAL),
    map(() => fetchShareAssetsOptions()),
  );

export const fetchShareAssetsOptionsEpic: Epic<
  Action,
  ShareAssetsOptionsLoaded | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_SHARE_ASSETS_OPTIONS),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      ajax<ResourceOptionsV2>({
        method: 'OPTIONS',
        url: '/api/productioncontents/create/',
        headers: {
          ...getTokenAuthHeader(loginSelectors.getToken(state)),
        },
      }).pipe(
        map((response) => shareAssetsOptionsLoaded(response.response.actions || {})),
        catchError((err) => {
          if (err && err.status === 401) {
            return of(unauthorizedRequestAction());
          }
          return of(shareAssetsOptionsLoaded({}));
        }),
      ),
    ),
  );

export const shareAssetsEpic: Epic<
  Action,
  AssetsShared | SetShareAssetsErrors | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, ShareAssets['type'], ShareAssets>(SHARE_ASSETS),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { productionIds, dateWithdraw, assets },
        },
        state,
      ]) =>
        productionIds.length === 0
          ? of(
              setShareAssetsErrors({
                productionIds: [gettext('Please select at least one production.')],
              }),
            )
          : ajax
              .post(
                '/api/productioncontents/create/',
                {
                  productions: productionIds,
                  date_withdraw: dateWithdraw ? `${dateWithdraw}T00:00:00Z` : null,
                  assets: assets.map((asset) => ({
                    content_type: isAssetCollection(asset)
                      ? 'projectasset'
                      : getResourceTypeName(asset),
                    object_id: asset.id,
                  })),
                },
                {
                  'Content-Type': 'application/json',
                  ...getTokenAuthHeader(loginSelectors.getToken(state)),
                },
              )
              .pipe(
                map(() => assetsShared(assets.length)),
                catchError((err: RESTError) => {
                  if (err && err.status === 401) {
                    return of(unauthorizedRequestAction());
                  }
                  const { errors } = err.response;
                  if (!errors) {
                    return of(
                      setShareAssetsErrors({
                        nonField: [gettext('Internal Error')],
                      }),
                    );
                  }
                  return of(
                    setShareAssetsErrors({
                      nonField: errors
                        .filter((error) => !('source' in error) || !('pointer' in error.source))
                        .map(({ detail }) => detail),
                      productionIds: errors
                        .filter(
                          (error) =>
                            'source' in error &&
                            'pointer' in error.source &&
                            error.source.pointer === '/productions',
                        )
                        .map(({ detail }) => detail),
                      dateWithdraw: errors
                        .filter(
                          (error) =>
                            'source' in error &&
                            'pointer' in error.source &&
                            error.source.pointer === '/date_withdraw',
                        )
                        .map(({ detail }) => detail),
                      assets: errors
                        .filter(
                          (error) =>
                            'source' in error &&
                            'pointer' in error.source &&
                            error.source.pointer === '/assets',
                        )
                        .map(({ detail }) => detail),
                    }),
                  );
                }),
              ),
    ),
  );

export const assetsSharedEpic: Epic<Action, CloseShareAssetsModal | AlertMessage> = (action$) =>
  action$.pipe(
    ofType<Action, AssetsShared['type'], AssetsShared>(ASSETS_SHARED),
    mergeMap(({ payload: { assetCount } }) => {
      return of(
        closeShareAssetsModal(),
        alertMessage(
          ngettext('Asset is being shared.', 'Assets are being shared.', assetCount),
          AlertFeedback.Success,
        ),
      );
    }),
  );

export default combineEpics<
  Action,
  | AlertMessage
  | CloseShareAssetsModal
  | FetchShareAssetsOptions
  | ShareAssetsOptionsLoaded
  | AssetsShared
  | SetShareAssetsErrors
  | UnauthorizedRequestAction,
  RootState
>(openShareAssetsModalEpic, fetchShareAssetsOptionsEpic, shareAssetsEpic, assetsSharedEpic);
