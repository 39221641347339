import { DateTime } from 'luxon';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { DateInput } from 'medialoopster/components';

import useWidgetContext from '../hooks/useWidgetContext';

type Props = {
  readonly name: string;
  readonly label: string;
  readonly allowNull: boolean;
  readonly defaultValue: DateTime | null;
  readonly disabled?: boolean;
};

const DateWidget: FC<Props> = ({
  name,
  label,
  allowNull,
  defaultValue,
  disabled = false,
}: Props) => {
  const { onChange, value, error } = useWidgetContext({
    name,
    defaultValue: defaultValue?.startOf('day') || null,
    rules: {
      validate: (v) => {
        if (v === null && !allowNull) {
          return gettext('Please enter a date.');
        }
        if (v !== null && !v.isValid) {
          return gettext('Please enter a valid date.');
        }
        return true;
      },
    },
    disabled,
  });
  return (
    <DateInput
      value={value}
      label={label}
      errorMsg={error}
      showError={!!error}
      onChange={onChange}
      readOnly={disabled}
    />
  );
};

export default DateWidget;
