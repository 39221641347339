import { Grid } from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import {
  AutoSuggestInput,
  Button,
  IconButton,
  MultiAutoCompleteInput,
  Select,
} from 'medialoopster/components';
import { Collapse as CollapseIcon, ClearAllFields } from 'medialoopster/icons';

import { keywordsActions } from '../../../state/modules/keywords';
import { personsActions } from '../../../state/modules/persons';
import { searchFieldMappingActions } from '../../../state/modules/searchfieldmappings';
import {
  SearchFieldMapping,
  SearchFieldMappingWithOptions,
} from '../../../state/modules/searchfieldmappings/types';
import SearchDateRange from './SearchDateRange';
import SearchText from './SearchText';

// TODO: also need to handle other fields <PB t:ML-2416, ML-2418>
const getClearableFields = (
  fields: ReadonlyArray<SearchFieldMapping>,
): ReadonlyArray<SearchFieldMapping> =>
  fields.filter(
    (field) =>
      field.input_type === 'Text' ||
      field.input_type === 'Duration' ||
      field.input_type === 'Person' ||
      field.input_type === 'Keyword' ||
      field.input_type === 'Select' ||
      field.input_type === 'Exists' ||
      field.input_type === 'Date',
  );

type Props = {
  readonly showSearchFields: boolean;
  readonly setShowSearchFields: (showSearchFields: boolean) => void;
  readonly apiSearchFieldMappings?: ReadonlyArray<SearchFieldMappingWithOptions>;
  readonly onSearch: () => void;
};

const SearchFields: React.FC<Props> = ({
  showSearchFields,
  setShowSearchFields,
  onSearch,
  apiSearchFieldMappings = [],
}: Props) => {
  const dispatch = useDispatch();

  const [showClear, setShowClear] = useState(false);

  useEffect(() => {
    dispatch(keywordsActions.fetchKeywordCategory('videoasset'));
    dispatch(keywordsActions.fetchKeywordCategory('audioasset'));
    dispatch(keywordsActions.fetchKeywordCategory('imageasset'));
    dispatch(searchFieldMappingActions.fetchSearchFieldMappings());
    dispatch(personsActions.fetchKnownPersons());
  }, [dispatch]);

  const {
    getValues,
    reset,
    control,
    formState: { errors },
  } = useFormContext();

  const clearFields = getClearableFields(apiSearchFieldMappings);
  const defaultClearValues = clearFields.reduce((allFields, field) => {
    if (
      field.input_type === 'Duration' ||
      field.input_type === 'Text' ||
      field.input_type === 'Date'
    ) {
      return { ...allFields, [field.uuid]: '' };
    }
    if (field.input_type === 'Keyword' || field.input_type === 'Person') {
      return { ...allFields, [field.uuid]: [] };
    }
    if (field.input_type === 'Select' || field.input_type === 'Exists') {
      return { ...allFields, [field.uuid]: '' };
    }
    return allFields;
  }, {});

  const checkAllClearFieldsAreDefaults = useCallback(() => {
    const values = getValues();
    return Object.keys(defaultClearValues).every((valId) => _.isEmpty(values[valId]));
  }, [getValues, defaultClearValues]);

  useEffect(() => {
    // const {
    //   defaultValuesRef: { current: storedValues },
    // } = control;
    const storedValues = getValues();
    const clearableStoredFields = _.pick(storedValues, Object.keys(defaultClearValues));
    if (Object.values(clearableStoredFields).some((val) => !_.isEmpty(val))) {
      setShowClear(true);
    }
    if (checkAllClearFieldsAreDefaults()) {
      setShowClear(false);
    }
  }, [getValues, setShowClear, defaultClearValues, checkAllClearFieldsAreDefaults]);

  const checkResetButton = useCallback(
    // Logic/Validation when to show reset button if an input element changes it's value
    (nextVal) => {
      // First check if button should show when it's not visible
      if (!showClear && !_.isEmpty(nextVal)) {
        setShowClear(true);
      }
      // Then check if button should disappear
      if (showClear) {
        // Also check if there are any other used&clearable fields
        if (checkAllClearFieldsAreDefaults()) {
          setShowClear(false);
        }
      }
      return true;
    },
    [showClear, setShowClear, checkAllClearFieldsAreDefaults],
  );

  const onClearFields = () => {
    setShowClear(false);
    reset({ ...getValues(), ...defaultClearValues });
    onSearch();
  };

  return (
    <Grid container marginBottom={4}>
      <Grid container justifyContent="space-between" marginBottom={1}>
        <Grid item>
          {showClear && (
            <Button
              aria-label={gettext('Clear all search fields')}
              size="medium"
              color="primary"
              onClick={onClearFields}
              startIcon=<ClearAllFields fontSize="medium" />
            >
              {gettext('Clear all search fields')}
            </Button>
          )}
        </Grid>
        <Grid item>
          <IconButton
            aria-label={gettext('Close search options')}
            onClick={() => {
              setShowSearchFields(!showSearchFields);
            }}
            sx={{
              marginRight: 2,
              marginTop: 1,
            }}
          >
            <CollapseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {apiSearchFieldMappings.map((searchField, searchFieldIndex) => {
          if (searchField.input_type === 'Text') {
            return (
              <Grid key={searchField.uuid} item xs={12} md={4} lg={3}>
                <Controller
                  name={searchField.uuid}
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: {
                      resetButton: checkResetButton,
                    },
                  }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <SearchText
                      ref={ref}
                      label={searchField.label}
                      onChange={onChange}
                      value={value}
                      name={name}
                      onSubmit={onSearch}
                    />
                  )}
                />
              </Grid>
            );
          }
          if (searchField.input_type === 'Duration') {
            const validateDuration = (v: string | null): boolean | string =>
              !v || v.match(/^([0-9]{2}:){0,2}([0-9]{2}) - ([0-9]{2}:){0,2}([0-9]{2})$/) !== null
                ? true
                : gettext('Value must be a timecode range!');
            return (
              <Grid key={searchField.uuid} item xs={12} md={4} lg={3}>
                <Controller
                  control={control}
                  name={searchField.uuid}
                  defaultValue=""
                  rules={{
                    validate: {
                      resetButton: checkResetButton,
                      duration: validateDuration,
                    },
                  }}
                  render={({ field: { onChange, value, name }, fieldState: { invalid } }) => (
                    <AutoSuggestInput
                      name={name}
                      label={searchField.label}
                      onChange={(newValue) => {
                        onChange(newValue);
                        if (validateDuration(newValue) === true) {
                          onSearch();
                        }
                      }}
                      onKeyUp={(e: React.KeyboardEvent) => {
                        if (e.key === 'Enter') {
                          onSearch();
                        }
                      }}
                      value={value || ''}
                      showError={invalid}
                      errorMsg={errors[searchField.uuid]?.message as string}
                      options={
                        searchField.duration_presets?.map((duration) => duration.toString()) || []
                      }
                    />
                  )}
                />
              </Grid>
            );
          }
          if (searchField.input_type === 'Keyword') {
            return (
              <Grid key={searchField.uuid} item xs={12} md={4} lg={3}>
                <Controller
                  control={control}
                  name={searchField.uuid}
                  defaultValue={[]}
                  rules={{
                    validate: {
                      resetButton: checkResetButton,
                    },
                  }}
                  render={({ field: { onChange, value, name } }) => (
                    <MultiAutoCompleteInput
                      onChange={(newValue) => {
                        onChange(newValue);
                        onSearch();
                        if (newValue.length === 0) {
                          checkResetButton(newValue);
                        }
                      }}
                      value={value}
                      name={name}
                      label={searchField.label}
                      options={searchField.keywords?.slice() || []}
                    />
                  )}
                />
              </Grid>
            );
          }
          if (searchField.input_type === 'Person') {
            return (
              <Grid key={searchField.uuid} item xs={12} md={4} lg={3}>
                <Controller
                  control={control}
                  name={searchField.uuid}
                  defaultValue={[]}
                  rules={{
                    validate: {
                      resetButton: checkResetButton,
                    },
                  }}
                  render={({ field: { onChange, value, name } }) => (
                    <MultiAutoCompleteInput
                      onChange={(newValue) => {
                        onChange(newValue);
                        onSearch();
                        if (newValue.length === 0) {
                          checkResetButton(newValue);
                        }
                      }}
                      value={value}
                      name={name}
                      label={searchField.label}
                      options={searchField.persons?.slice() || []}
                    />
                  )}
                />
              </Grid>
            );
          }
          if (searchField.input_type === 'Select' || searchField.input_type === 'Exists') {
            return (
              <Grid key={searchField.uuid} item xs={12} md={4} lg={3}>
                <Controller
                  control={control}
                  name={searchField.uuid}
                  defaultValue=""
                  rules={{
                    validate: {
                      resetButton: checkResetButton,
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <>
                      <Select
                        id={`search-select-${name}`}
                        label={searchField.label}
                        inputRef={ref}
                        name={name}
                        onChange={(newValue) => {
                          onChange(newValue);
                          onSearch();
                        }}
                        onBlur={onBlur}
                        value={value.toString()}
                        choices={searchField.selectOptions}
                      />
                    </>
                  )}
                />
              </Grid>
            );
          }
          if (searchField.input_type === 'Date') {
            return (
              <Grid key={searchField.uuid} item xs={12} md={4} lg={3}>
                <Controller
                  control={control}
                  name={searchField.uuid}
                  defaultValue=""
                  rules={{
                    validate: {
                      resetButton: checkResetButton,
                      date: (v) => {
                        const [strDateStart, strDateEnd] = v.split(' - ');
                        const dateTimeFormat = pgettext(
                          'Localized numeric date and time without meridiem',
                          'MM/dd/yyyy HH:mm',
                        );
                        if (strDateStart && strDateEnd) {
                          return (
                            v === '' ||
                            (DateTime.fromFormat(strDateStart, dateTimeFormat).isValid &&
                              DateTime.fromFormat(strDateEnd, dateTimeFormat).isValid)
                          );
                        }
                        return false;
                      },
                    },
                  }}
                  render={({ field: { onChange, value, name }, fieldState: { invalid } }) => (
                    <SearchDateRange
                      onChange={onChange}
                      value={value}
                      name={name}
                      label={searchField.label}
                      onSubmit={onSearch}
                      showError={invalid}
                    />
                  )}
                />
              </Grid>
            );
          }
          return <Fragment key={`fragment-${searchFieldIndex + 1}`} />;
        })}
      </Grid>
    </Grid>
  );
};

export default SearchFields;
