import { RootState } from '../../../types/rootState';
import { TranscriptSpeakerRootState } from './types';

/**
 * Return the speaker root state
 * @param state the global root state.
 */
const getSelf = (state: RootState) => state.rest.transcriptspeaker;

/**
 * Return the transcript speaker Resource Map
 * @param state The global root state.
 */
export const getTranscriptSpeakerResourceMap = (
  state: RootState,
): TranscriptSpeakerRootState['objects'] => getSelf(state).objects;

/**
 * Return the transcript speaker page map.
 * @param state The global root state.
 */
export const getTranscriptSpeakerPageMap = (
  state: RootState,
): TranscriptSpeakerRootState['pages'] => getSelf(state).pages;

export default null;
