import Link from '@mui/material/Link';
import { useObservableEagerState } from 'observable-hooks';
import { FC, useCallback, MouseEvent } from 'react';

import { useVideoController } from 'medialoopster/components';
import { formatFramesAsTimecode } from 'medialoopster/formatTimecode';

export interface TimeCodeLinkProps {
  frame: number;
}

/**
 * Clickable TimeCode component that controls the current videoplayer.
 *
 * The component renders a frame (number of the current frame) as a timecode relative to the
 * video controlled by the current VideoController. The timecode is rendered as a link and will
 * seek the video to the given frame when clicked.
 *
 * The component is responsible for computing the timecode and setting the current frame
 * via the VideoController.
 *
 * @param frame The frame for the timecode.
 */
const TimecodeLink: FC<TimeCodeLinkProps> = ({ frame }) => {
  const videoController = useVideoController();

  // calculate the timecode
  const fps = useObservableEagerState(videoController.fps$);
  const timeCode = `${formatFramesAsTimecode(frame, fps.toFixed(1))}`;

  // set timecode on click
  const onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      videoController.seekFrame(frame);
    },
    [frame, videoController],
  );

  return (
    <Link onClick={onClick} href="#">
      {timeCode}
    </Link>
  );
};

export default TimecodeLink;
