import { AssetType } from '../../types/asset/baseTypes';
import { RootState } from '../../types/rootState';

export const canArchiveAssets = (state: RootState): boolean => state.archive.canArchive;

export const canRestoreArchivedAssets = (state: RootState): boolean => state.archive.canRestore;

export const getArchiveAssetTypes = (state: RootState): ReadonlyArray<AssetType> =>
  state.archive.archiveAssetTypes;

export const getRestoreAssetTypes = (state: RootState): ReadonlyArray<AssetType> =>
  state.archive.restoreAssetTypes;
