/* eslint max-classes-per-file: ["error", 2] */
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import shaka from 'shaka-player';

class VideoControllerDefaults {
  static DEFAULT_FPS = 25;

  static DEFAULT_PLAYBACK_SPEED = 1;

  static DEFAULT_PAUSED = true;

  static DEFAULT_TIME = 0;

  static DEFAULT_FRAME = 0;

  static DEFAULT_VIDEO_ENDED = false;

  static DEFAULT_CAN_PLAY = false;

  static DEFAULT_DURATION = Infinity;

  static DEFAULT_MUTED = false;

  static DEFAULT_MAX_TIME = Infinity;

  static DEFAULT_MANIFEST = null;

  static DEFAULT_AUDIOTRACKS = [];
}

/**
 * Central control and state updates for the Video Player in context.
 *
 * The VideoController broadcasts changes in the Player via rxjs subjects
 * and enables other components to control the player.
 *
 * Other components can subscribe to various Observables to receive state updates. All those
 * updates are backed by BahaviourSubjects (hot, will receive the current value on subscription)
 * and deduplicated via distinctUntilChanged.
 *
 * The updates are based on polling using the VideoControllerUpdater component. This allows fine
 * control over the performance characteristics of the updates according to the video requirements.
 *
 * The VideoController also provides methods to control the registered Player.
 *
 * Passive components, which are only interested in the current players state only need to subscribe to the observables.
 *
 * Active components, e.g a play button or slider, can use the VideoContoller in the react context to interact with the video.
 *
 * The VideoController is meant to be provided via the VideoControllerContext.
 */
class VideoController extends VideoControllerDefaults {
  // ================ START RxJS Observables =======================
  private TimeSubject$ = new BehaviorSubject<number>(VideoControllerDefaults.DEFAULT_TIME);

  time$: Observable<number> = this.TimeSubject$.pipe(distinctUntilChanged());

  private FrameSubject$ = new BehaviorSubject<number>(VideoControllerDefaults.DEFAULT_FRAME);

  frame$: Observable<number> = this.FrameSubject$.pipe(distinctUntilChanged());

  private FpsSubject$ = new BehaviorSubject<number>(VideoControllerDefaults.DEFAULT_FPS);

  fps$: Observable<number> = this.FpsSubject$.pipe(distinctUntilChanged());

  private PausedSubject$ = new BehaviorSubject<boolean>(VideoControllerDefaults.DEFAULT_PAUSED);

  paused$: Observable<boolean> = this.PausedSubject$.pipe(distinctUntilChanged());

  private VideoEndedSubject$ = new BehaviorSubject<boolean>(
    VideoControllerDefaults.DEFAULT_VIDEO_ENDED,
  );

  videoEnded$ = this.VideoEndedSubject$.pipe(distinctUntilChanged());

  private CanPlaySubject$ = new BehaviorSubject<boolean>(VideoControllerDefaults.DEFAULT_CAN_PLAY);

  canPlay$ = this.CanPlaySubject$.pipe(distinctUntilChanged());

  private DurationSubject$ = new BehaviorSubject<number>(VideoControllerDefaults.DEFAULT_DURATION);

  duration$ = this.DurationSubject$.pipe(distinctUntilChanged());

  private MutedSubject$ = new BehaviorSubject<boolean>(VideoControllerDefaults.DEFAULT_MUTED);

  muted$ = this.MutedSubject$.pipe(distinctUntilChanged());

  private ManifestSubject$ = new BehaviorSubject<shaka.extern.Manifest | null>(
    VideoControllerDefaults.DEFAULT_MANIFEST,
  );

  manifest$ = this.ManifestSubject$.pipe(distinctUntilChanged());

  private PlaybackSpeedSubject$ = new BehaviorSubject<number>(
    VideoControllerDefaults.DEFAULT_PLAYBACK_SPEED,
  );

  playbackSpeed$ = this.PlaybackSpeedSubject$.pipe(distinctUntilChanged());

  private MaxPlayTimeSubject$ = new BehaviorSubject<number>(
    VideoControllerDefaults.DEFAULT_MAX_TIME,
  );

  maxPlayTime$ = this.MaxPlayTimeSubject$.pipe(distinctUntilChanged());

  private AudioTracksSubject$ = new BehaviorSubject<ReadonlyArray<shaka.extern.Track>>(
    VideoControllerDefaults.DEFAULT_AUDIOTRACKS,
  );

  audioTracks$ = this.AudioTracksSubject$.pipe(distinctUntilChanged());
  // ================ END RxJS Observables =======================

  private player: shaka.Player | undefined;

  TimeBump = 0;

  private Spf = 0;

  private get Fps() {
    return this.FpsSubject$.getValue();
  }

  private get MaxPlayTime() {
    return this.MaxPlayTimeSubject$.getValue();
  }

  private get Duration() {
    return this.DurationSubject$.getValue();
  }

  private get VideoEnded() {
    return this.VideoEndedSubject$.getValue();
  }

  constructor() {
    super();
    this.Spf = 1 / this.Fps;
    this.TimeBump = parseFloat((this.Spf / 1.6).toFixed(3));
  }

  private set fps(fps: number) {
    this.Spf = 1 / fps;
    this.TimeBump = parseFloat((this.Spf / 1.6).toFixed(3));
    this.FpsSubject$.next(fps);
  }

  get mediaElement(): undefined | HTMLMediaElement {
    const mediaElement = this.player?.getMediaElement();
    if (mediaElement) {
      return mediaElement;
    }
    return undefined;
  }

  getMaxFrame(): number {
    return Math.round(this.MaxPlayTime * this.Fps);
  }

  getCurrentTime(): number {
    if (!this.mediaElement) {
      return 0;
    }
    return Math.max(0, this.mediaElement.currentTime - this.TimeBump);
  }

  getCurrentFrame(): number {
    return Math.round(this.getCurrentTime() * this.Fps);
  }

  setFps(fps: number): void {
    this.fps = fps;
    this.refreshPlayer();
  }

  setCanPlay(canPlay: boolean): void {
    this.CanPlaySubject$.next(canPlay);
  }

  setAudioTracks(audioTracks: ReadonlyArray<shaka.extern.Track>): void {
    this.AudioTracksSubject$.next(audioTracks);
  }

  setManifest(manifest: shaka.extern.Manifest | null): void {
    this.ManifestSubject$.next(manifest);
  }

  setDuration(duration: number): void {
    this.DurationSubject$.next(duration);
  }

  setMaxTime(maxTime: number): void {
    this.MaxPlayTimeSubject$.next(maxTime);
  }

  setVideoEnded(ended: boolean): void {
    if (this.TimeSubject$.getValue() === 0) {
      // Edge case: Video starting to play on last frame, fires videoended event while seeking/starting to play
      // Todo <PB 2022-03-09 t:ML-2842, ML-3324, ML-3292>
      // Note: Video still hangs/stalls/breaks for some assets. html video loop attribute doesn't help too.
      // For some reason (maybe buffer events?) videoRef.currentTime is close to last frame even though seek(0) is done.
      // Best guess: fix by update, most likely.
      return;
    }
    this.VideoEndedSubject$.next(ended);
  }

  setPlaybackSpeed(playbackRate: number): void {
    if (this.mediaElement) {
      this.mediaElement.playbackRate = playbackRate;
      this.PlaybackSpeedSubject$.next(playbackRate);
    }
  }

  registerPlayer(player: shaka.Player): void {
    this.player = player;
  }

  unRegisterPlayer(): void {
    this.player = undefined;
  }

  togglePlay(shouldPlay: boolean): void {
    if (this.mediaElement) {
      if (shouldPlay) {
        this.mediaElement.play().then(() => {
          this.PausedSubject$.next(false);
        });
      } else {
        this.mediaElement.pause();
        this.PausedSubject$.next(true);
      }
    }
  }

  toggleMute(): void {
    if (this.mediaElement) {
      this.mediaElement.muted = !this.mediaElement.muted;
      this.MutedSubject$.next(this.mediaElement.muted);
    }
  }

  refreshPlayer(): void {
    if (this.mediaElement) {
      this.TimeSubject$.next(this.getCurrentTime());
      this.FrameSubject$.next(this.getCurrentFrame());
    }
  }

  onNewSource(): void {
    this.seek(0);
    this.PlaybackSpeedSubject$.next(1);
    this.MutedSubject$.next(VideoController.DEFAULT_MUTED);
    this.setVideoEnded(VideoController.DEFAULT_VIDEO_ENDED);
    this.setDuration(VideoController.DEFAULT_DURATION);
    this.setMaxTime(VideoController.DEFAULT_MAX_TIME);
    this.FrameSubject$.next(VideoController.DEFAULT_FRAME);
    this.TimeSubject$.next(VideoController.DEFAULT_TIME);
  }

  seek(currentTime: number): void {
    if (!this.mediaElement) {
      return;
    }
    if (this.getCurrentTime() < this.Duration && this.VideoEnded) {
      this.VideoEndedSubject$.next(false);
    }
    if (this.getCurrentTime() === currentTime && currentTime === 0) {
      // Don't reset poster frame during initialization. See test for details.
      return;
    }
    this.mediaElement.pause();
    this.PausedSubject$.next(true);
    this.mediaElement.currentTime = Math.min(this.MaxPlayTime, currentTime) + this.TimeBump;
    this.refreshPlayer();
  }

  seekFrame(currentFrame: number): void {
    this.seek(currentFrame / this.Fps);
  }

  seekRel(delta: number): void {
    this.seek(this.getCurrentTime() + delta);
  }

  nextFrame(): void {
    this.seekFrame(this.getCurrentFrame() + 1);
  }

  nextSecond(): void {
    this.seekRel(1);
  }

  previousSecond(): void {
    this.seekRel(-1);
  }

  previousFrame(): void {
    this.seekFrame(this.getCurrentFrame() - 1);
  }
}
export default VideoController;
