import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';

const useStyles = makeStyles((theme) => ({
  missingAsset: {
    width: 'max-content',
    height: 77,
    margin: '7px 0',
    opacity: 0.6,
    padding: '12px 16px',
    '&.MuiAlert-root': {
      fontSize: theme.typography.pxToRem(10.5),
    },
    '& .MuiAlertTitle-root': {
      fontSize: theme.typography.pxToRem(11.5),
    },
  },
  cursorGrab: {
    cursor: 'grab',
  },
}));

type DeletedFavoriteItemProps = {
  displayName: string;
  detail: string;
};

const DeletedFavoriteItem: FC<DeletedFavoriteItemProps> = ({
  displayName,
  detail,
}: DeletedFavoriteItemProps) => {
  const classes = useStyles();
  return (
    <Alert
      icon={false}
      variant="filled"
      severity="info"
      className={`${classes.missingAsset} ${classes.cursorGrab}`}
      title={gettext('This asset has been deleted!')}
    >
      <AlertTitle>
        <strong>{displayName}</strong>
      </AlertTitle>
      {detail}
    </Alert>
  );
};

export default DeletedFavoriteItem;
