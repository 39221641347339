import { Badge, Divider, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { IconButton, Input } from 'medialoopster/components';
import { ClearField, Search, SearchOptions, SearchOptionsOff } from 'medialoopster/icons';

import { searchSelectors } from '../../state/modules/search';
import { SearchResultAssetType } from '../../state/types/asset/baseTypes';
import SearchCategorySelect from './SearchCategorySelect';
import { SearchQuery } from './services/SearchQueryService';

const useStyles = makeStyles(() => ({
  divider: {
    height: '32px',
    marginLeft: '4px',
    marginRight: '4px',
  },
}));

type Props = {
  readonly value: string;
  readonly searchCategory: SearchResultAssetType;
  readonly showSearchFields: boolean;
  readonly onSearch: (value: string, category: SearchResultAssetType) => void;
  readonly onShowSearchOptions: () => void;
};

const SearchInput: FC<Props> = ({
  value,
  searchCategory,
  showSearchFields,
  onSearch,
  onShowSearchOptions,
}: Props) => {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState(value);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [searchBadgeContent, setSearchBadgeContent] = useState<string | number>(0);
  const searchQuery = useSelector(searchSelectors.getSearchQuery);

  const calculateSearchBadgeContent = (searchQueryParam: SearchQuery): string | number => {
    const searchQueryKeys = Object.keys(searchQueryParam).filter(
      (x) => x.startsWith('so_') && x !== 'so_primary_search',
    );
    if (searchQueryKeys.length > 0) {
      return ' ';
    }
    return 0;
  };

  useEffect(() => setSearchBadgeContent(calculateSearchBadgeContent(searchQuery)), [searchQuery]);

  return (
    <Input
      id="primary-search"
      autoFocus
      value={internalValue}
      onChange={(newValue) => {
        if (newValue === internalValue) {
          return;
        }
        setInternalValue(newValue);
        if (timeoutId) {
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }
        if (newValue.length > 2 || newValue === '') {
          setTimeoutId(setTimeout(() => onSearch(newValue, searchCategory), 500));
        }
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onSearch(internalValue, searchCategory);
        }
      }}
      name="primary-search"
      placeholder={gettext('Enter search term')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchCategorySelect
              searchCategory={searchCategory}
              onChange={(category) => onSearch(internalValue, category)}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {internalValue.length > 0 && (
              <IconButton
                onClick={() => {
                  setInternalValue('');
                  onSearch('', searchCategory);
                }}
                aria-label={pgettext('Verb; clear input', 'Clear')}
              >
                <ClearField fontSize="small" />
              </IconButton>
            )}
            <IconButton
              onClick={() => onSearch(internalValue, searchCategory)}
              aria-label={gettext('Search')}
            >
              <Search />
            </IconButton>
            <Divider orientation="vertical" className={classes.divider} />
            <Badge
              color="primary"
              overlap="circular"
              badgeContent={searchBadgeContent}
              variant="dot"
            >
              <IconButton
                onClick={onShowSearchOptions}
                aria-label={gettext('Search Options')}
                title={
                  showSearchFields ? gettext('Hide search options') : gettext('Show search options')
                }
              >
                {showSearchFields ? <SearchOptionsOff /> : <SearchOptions />}
              </IconButton>
            </Badge>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
