/**
 * This module and package implements the mainly independent and reusable transcript editor component
 *
 * The editor is designed to be mostly independent of the rest of the frontend. The only component
 * that depends on code or types external  to the editor is the TranscriptInitializationPlugin
 * that transfers the transcript state from the redux store to the editor state and thus
 * initializes the editor. External dependencies should be kept at a minimum and be local
 * to plugins like TranscriptInitializationPlugin or a future TranscriptSavePlugin.
 * To further decrease dependencies of the editor to the rest of the code, these plugins could in
 * the future be rendered as children of the Editor component instead of being an integral
 * part of the editor.
 */
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { Box, Stack, SxProps } from '@mui/material';
import { ParagraphNode, TextNode } from 'lexical';
import React, { useState } from 'react';

import { SaveButton } from './components/SaveButton';
import { TranscriptEditorContextProvider } from './components/TranscriptEditorContext';
import { EDITOR_CLASS_NAME } from './css';
import { Decimal, decimal } from './decimal';
import { TranscriptMetadataNode } from './nodes/TranscriptMetadataNode';
import {
  $createTranscriptParagraphNode,
  TranscriptParagraphNode,
} from './nodes/TranscriptParagraphNode';
import { TranscriptTimeBlockNode } from './nodes/TranscriptTimeBlockNode';
import { $createUninitializedUnitNode, TranscriptUnitNode } from './nodes/TranscriptUnitNode';
import { TranscriptBehaviourPlugin } from './plugins/TranscriptBehaviourPlugin';
import { TranscriptChangeSpeakerPlugin } from './plugins/TranscriptChangeSpeakerPlugin';
import { TranscriptEditabilityPlugin } from './plugins/TranscriptEditabilityPlugin';
import {
  TranscriptInitializationPlugin,
  TranscriptInitializationPluginProps,
} from './plugins/TranscriptInitializationPlugin';
import { TranscriptModificationDetectorPlugin } from './plugins/TranscriptModificationDetectorPlugin';
import {
  TranscriptNavigationPlugin,
  TranscriptNavigationPluginProps,
} from './plugins/TranscriptNavigationPlugin';
import { TranscriptProgressPlugin } from './plugins/TranscriptProgressPlugin';
import { TranscriptSavePlugin, TranscriptSavePluginProps } from './plugins/TranscriptSavePlugin';
import { TranscriptStructurePlugin } from './plugins/TranscriptStructurePlugin';
import { TranscriptUnitTooltipPlugin } from './plugins/TranscriptUnitTooltipPlugin';

export type { TranscriptDiff } from './diff';

/**
 * The props aggregate properties needed by the integral Plugins.
 */
export interface EditorProps {
  /**
   * The transcript units to display in the editor.
   */
  readonly units: TranscriptInitializationPluginProps['units'];
  /**
   * The transcript speakers to display in the editor.
   */
  readonly speakers: TranscriptInitializationPluginProps['speakers'];
  /**
   * The current time of the transcript/asset. This is used to display the progress in the transcript
   */
  readonly time: Decimal;
  /**
   * The offset time of the asset. This is used if the timecode does not start at 0.
   */
  readonly offsetTime?: Decimal;
  /**
   * A callback to notify on navigation events.
   */
  readonly onNavigation: TranscriptNavigationPluginProps['onNavigation'];
  /**
   * A callback to handle saving the diff of the transcript.
   */
  readonly onSave: TranscriptSavePluginProps['onSave'];
  /**
   * Boolean to set the editor to an editable or read-only mode.
   */
  readonly isEditable: boolean;
  readonly sx?: SxProps;
}

/**
 * This could be investigated in the future to replace direct styling.
 */
const theme = {
  // Theme styling goes here
  // ...
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError = (error: unknown) => {
  // eslint-disable-next-line no-console
  console.error(error);
};

/**
 * A mostly standalone Transcript editor component.
 *
 * The editor state is initialized to the contents of the `speakers` and `units` props.
 * The initialization is rerun whenever the identity of these arrays changes and any edits done
 * so far will be overwritten. Use memoization to keep the identity of equal arrays!
 *
 * @param speakers A list of speakers to show in the editor.
 * @param units A list of units to show in the editor.
 * @param time The current time of the asset.
 * @param onNavigation A callback called with the new time if the user navigates using the transcript.
 * @param onSave A callback that is called with the diff of the transcript when the transcript is to be saved.
 * @param isEditable If true, the editor is editable. Otherwise, it behaves read-only.
 * @param offsetTime The offset time of the asset.
 * @param sx Mui System sx for styles.
 */
export const Editor: React.FC<EditorProps> = ({
  speakers,
  units,
  time,
  onNavigation,
  onSave,
  isEditable,
  offsetTime = decimal('0'),
  sx,
}) => {
  const [isModified, setModified] = useState(false);
  const initialConfig = {
    namespace: 'TranscriptEditor',
    theme,
    onError,
    nodes: [
      TranscriptUnitNode,
      {
        replace: TextNode,
        with: (node: TextNode) => {
          return $createUninitializedUnitNode(node.getTextContent());
        },
      },
      TranscriptParagraphNode,
      {
        replace: ParagraphNode,
        with: () => {
          return $createTranscriptParagraphNode();
        },
      },
      TranscriptMetadataNode,
      TranscriptTimeBlockNode,
    ],
  };

  return (
    <Stack direction="column" position="relative" maxHeight="100%" minHeight={50} sx={sx}>
      <TranscriptEditorContextProvider
        onNavigation={onNavigation}
        isEditable={isEditable}
        offsetTime={offsetTime}
      >
        <LexicalComposer initialConfig={initialConfig}>
          <Box flexShrink={1} overflow="auto" pb={12}>
            <HistoryPlugin />
            <TranscriptStructurePlugin />
            <TranscriptBehaviourPlugin />
            <TranscriptInitializationPlugin speakers={speakers} units={units} />
            <TranscriptNavigationPlugin onNavigation={onNavigation} />
            <TranscriptUnitTooltipPlugin />
            <TranscriptProgressPlugin time={time} />
            <TranscriptChangeSpeakerPlugin />
            <TranscriptEditabilityPlugin isEditable={isEditable} />
            <TranscriptSavePlugin units={units} speakers={speakers} onSave={onSave} />
            <TranscriptModificationDetectorPlugin
              units={units}
              speakers={speakers}
              onModified={setModified}
            />
            <PlainTextPlugin
              contentEditable={<ContentEditable className={EDITOR_CLASS_NAME} />}
              placeholder={null}
              ErrorBoundary={LexicalErrorBoundary}
            />
            {isEditable && isModified ? <SaveButton /> : null}
          </Box>
        </LexicalComposer>
      </TranscriptEditorContextProvider>
    </Stack>
  );
};

export default Editor;
export { isUnmodified as isDiffEmpty } from './diff';
