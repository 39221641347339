import { ActionsMetadataV2 } from 'medialoopster/rest';

import { AssetType } from '../../types/asset/baseTypes';
import { SiteExportConfig } from '../rest/siteexportconfigs/types';
import { Shot } from '../video/types';
import {
  ADD_ASSET_TO_FAVORITES,
  ADD_ASSETS_TO_FAVORITES,
  ADD_SEQUENCE_TO_FAVORITES,
  ADD_SHOT_TO_FAVORITES,
  ADD_VIDEO_CLIP_TO_FAVORITES,
  AddAssetsToFavorites,
  AddAssetToFavorites,
  AddSequenceToFavorites,
  AddShotToFavorites,
  AddVideoClipToFavorites,
  CLEAR_FAVORITE_LIST,
  ClearFavoriteList,
  CREATE_FAVORITE_LIST,
  CreateFavoriteList,
  DELETE_FAVORITE_ITEM,
  DELETE_FAVORITE_LIST,
  DeleteFavoriteItem,
  DeleteFavoriteList,
  EDIT_FAVORITE_LIST,
  EditFavoriteList,
  EXPORT_TO_RENDER_ENGINE,
  ExportToRenderEngine,
  FAVORITE_LISTS_OPTIONS_LOADED,
  FavoriteListsOptionsLoaded,
  FavoritesListPatchResource,
  FavoritesListPostResource,
  FETCH_FAVORITE_LISTS,
  FETCH_FAVORITE_LISTS_OPTIONS,
  FETCH_PERMISSION,
  FETCH_SELECTED_LIST_ITEMS,
  FetchFavoriteLists,
  FetchFavoriteListsOptions,
  FetchPermission,
  FetchSelectedListItems,
  HIDE_FAVORITES,
  HideFavorites,
  MOVE_FAVORITE_ITEM,
  MoveFavoriteItem,
  REMOVE_SHARED_ACTIVE_FAVORITE_LIST,
  RemoveSharedActiveList,
  SELECT_FAVORITE_ITEM_ASSET,
  SELECT_FAVORITE_LIST,
  SelectFavoriteItemAsset,
  SelectFavoriteList,
  SET_LIST_ASSETS_LOADING,
  SET_SHARED_ACTIVE_LISTS,
  SetListAssetsLoading,
  SetSharedActiveLists,
  SHARE_LIST_CONTENTS,
  ShareListContents,
  SHOW_FAVORITES,
  ShowFavorites,
  TOGGLE_FAVORITES,
  ToggleFavorites,
  UPDATE_PERMISSIONS,
  UpdatePermissions,
  FavoritesItemAssetType,
} from './types';

export const showFavorites = (): ShowFavorites => ({
  type: SHOW_FAVORITES,
});

export const hideFavorites = (): HideFavorites => ({
  type: HIDE_FAVORITES,
});

export const toggleFavorites = (): ToggleFavorites => ({
  type: TOGGLE_FAVORITES,
});

export const fetchFavoriteLists = (): FetchFavoriteLists => ({
  type: FETCH_FAVORITE_LISTS,
});

export const fetchSelectedListItems = (): FetchSelectedListItems => ({
  type: FETCH_SELECTED_LIST_ITEMS,
});

export const selectFavoriteItemAsset = (
  assetTypeName: AssetType,
  assetHref: string,
  assetId: number,
): SelectFavoriteItemAsset => ({
  type: SELECT_FAVORITE_ITEM_ASSET,
  payload: {
    assetTypeName,
    assetHref,
    assetId,
  },
});

export const fetchFavoriteListsOptions = (): FetchFavoriteListsOptions => ({
  type: FETCH_FAVORITE_LISTS_OPTIONS,
});

export const favoriteListsOptionsLoaded = (
  actions: ActionsMetadataV2,
  allowHeader: string,
): FavoriteListsOptionsLoaded => ({
  type: FAVORITE_LISTS_OPTIONS_LOADED,
  payload: {
    actions,
    allowHeader,
  },
});

export const exportToRenderEngine = (
  exportChoice: SiteExportConfig,
  favoriteXMLDownloadURI: string,
  baseXMLFileName: string,
): ExportToRenderEngine => ({
  type: EXPORT_TO_RENDER_ENGINE,
  payload: {
    exportChoice,
    favoriteXMLDownloadURI,
    baseXMLFileName,
  },
});

export const selectFavoriteList = (listId: number | null): SelectFavoriteList => ({
  type: SELECT_FAVORITE_LIST,
  payload: {
    listId,
  },
});

export const setSharedActiveLists = (ids: ReadonlyArray<number>): SetSharedActiveLists => ({
  type: SET_SHARED_ACTIVE_LISTS,
  payload: {
    ids,
  },
});

export const moveFavoriteItem = (favoriteItemId: number, index: number): MoveFavoriteItem => ({
  type: MOVE_FAVORITE_ITEM,
  payload: {
    favoriteItemId,
    index,
  },
});

export const deleteFavoriteItem = (favoriteItemId: number): DeleteFavoriteItem => ({
  type: DELETE_FAVORITE_ITEM,
  payload: {
    favoriteItemId,
  },
});

export const clearFavoriteList = (): ClearFavoriteList => ({
  type: CLEAR_FAVORITE_LIST,
});

export const fetchPermission = (url: string): FetchPermission => ({
  type: FETCH_PERMISSION,
  payload: { url },
});

export const updatePermissions = (url: string, hasPermission: boolean): UpdatePermissions => ({
  type: UPDATE_PERMISSIONS,
  payload: {
    url,
    hasPermission,
  },
});

export const createFavoriteList = (
  favoriteList: FavoritesListPostResource,
): CreateFavoriteList => ({
  type: CREATE_FAVORITE_LIST,
  payload: {
    favoriteList,
  },
});

export const editFavoriteList = (favoriteList: FavoritesListPatchResource): EditFavoriteList => ({
  type: EDIT_FAVORITE_LIST,
  payload: {
    favoriteList,
  },
});

export const deleteFavoriteList = (favoriteListId: number): DeleteFavoriteList => ({
  type: DELETE_FAVORITE_LIST,
  payload: {
    favoriteListId,
  },
});

export const removeSharedActiveList = (favoriteListId: number): RemoveSharedActiveList => ({
  type: REMOVE_SHARED_ACTIVE_FAVORITE_LIST,
  payload: {
    favoriteListId,
  },
});

export const shareListContents = (): ShareListContents => ({
  type: SHARE_LIST_CONTENTS,
});

export const addAssetToFavorites = (
  assetTypeName: FavoritesItemAssetType,
  assetId: number,
): AddAssetToFavorites => ({
  type: ADD_ASSET_TO_FAVORITES,
  payload: { assetTypeName, assetId },
});

export const addAssetsToFavorites = (
  assetTypeName: FavoritesItemAssetType,
  assetIds: ReadonlyArray<number>,
): AddAssetsToFavorites => ({
  type: ADD_ASSETS_TO_FAVORITES,
  payload: { assetTypeName, assetIds },
});

export const addVideoClipToFavorites = (
  assetId: number,
  timecodeStart: number,
  timecodeEnd: number,
): AddVideoClipToFavorites => ({
  type: ADD_VIDEO_CLIP_TO_FAVORITES,
  payload: {
    assetTypeName: 'videoasset',
    assetId,
    timecodeStart,
    timecodeEnd,
  },
});

export const addShotToFavorites = (shotId: number): AddShotToFavorites => ({
  type: ADD_SHOT_TO_FAVORITES,
  payload: { shotId },
});

export const addSequenceToFavorites = (shots: ReadonlyArray<Shot>): AddSequenceToFavorites => ({
  type: ADD_SEQUENCE_TO_FAVORITES,
  payload: { shots },
});

export const setListAssetsLoading = (loading: boolean): SetListAssetsLoading => ({
  type: SET_LIST_ASSETS_LOADING,
  payload: { loading },
});
