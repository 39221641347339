import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  getLinkHref,
  mlRel,
  RECEIVE_ROOT_RESOURCE,
  ReceiveRootResource,
  ResourceLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';

export const fetchCurrentSiteEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchResource }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const href = getLinkHref(root, mlRel('current-site'));
      if (!href) {
        return EMPTY;
      }
      return fetchResource(href);
    }),
  );

export default combineEpics(fetchCurrentSiteEpic);
