import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Management } from 'medialoopster/icons';
import { getResourceTypeName } from 'medialoopster/rest';

import { mainSelectors } from '../../../../state/modules/main';
import { Asset } from '../../../../state/types/asset/unionTypes';

type Props = {
  readonly url: string;
};

const ViewManagementActionMenuEntry = ({ url }: Props) => {
  return (
    <MenuItem
      label={pgettext('Menu entry', 'View in management')}
      tooltip={gettext('View asset in management app')}
      icon={<Management fontSize="small" />}
      href={url}
    />
  );
};

const useViewManagementActionMenuEntry = (
  currentAsset: Asset | null,
): ReadonlyArray<ReactElement> => {
  const currentAssetType = currentAsset ? getResourceTypeName(currentAsset) : null;
  const mainManagementURL = useSelector(mainSelectors.getManagementURL);
  if (!currentAssetType || !mainManagementURL) {
    return [];
  }
  const url = `/management/live/${currentAssetType.replace('asset', '')}/?id=${currentAsset?.id}`;
  return [<ViewManagementActionMenuEntry key="view-in-management" url={url} />];
};

export default useViewManagementActionMenuEntry;
