import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo, useMemo } from 'react';

import OverflowTextField from '../../../../ui/components/InplaceEdits/OverflowTextField';

const useStyles = makeStyles((theme) => ({
  favoriteItemName: {
    width: 98,
    fontSize: theme.typography.pxToRem(9),
    marginBottom: 1,
  },
  favoriteItemImage: {
    width: 90,
    height: 46,
    pointerEvents: 'none',
  },
  cursorGrab: {
    cursor: 'grab !important',
  },
}));

type FavoriteItemProps = {
  displayName: string;
  thumbnailURL?: string;
  detail: string;
  assetIsAvailable: boolean;
  onDoubleClick?: () => void;
  cssClasses: {
    favoriteItemContent: string;
    favoriteItemDetail: string;
    favoriteItemDecreasedOpacity: string;
  };
};

const FavoriteItem: FC<FavoriteItemProps> = ({
  displayName,
  thumbnailURL,
  detail,
  assetIsAvailable,
  onDoubleClick = undefined,
  cssClasses,
}: FavoriteItemProps) => {
  const classes = useStyles();
  const itemStyles = useMemo(
    () =>
      clsx(cssClasses.favoriteItemContent, classes.cursorGrab, {
        [cssClasses.favoriteItemDecreasedOpacity]: !assetIsAvailable,
      }),
    [cssClasses, assetIsAvailable, classes.cursorGrab],
  );
  return (
    <div className={itemStyles} onDoubleClick={onDoubleClick} data-testid="favorite-item-container">
      <div className={classes.favoriteItemName}>
        <OverflowTextField
          variant="inherit"
          placement="top"
          textComponents={displayName}
          whiteSpace="nowrap"
        />
      </div>
      <img src={thumbnailURL} alt={displayName} className={classes.favoriteItemImage} />
      <div className={cssClasses.favoriteItemDetail}>{detail}</div>
    </div>
  );
};

export default memo(FavoriteItem);
