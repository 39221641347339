import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { DropdownMenuButton, ImageWithLoadingPlaceholder } from 'medialoopster/components';
import { formatFramesAsTimecode } from 'medialoopster/formatTimecode';
import { Delete, EditList } from 'medialoopster/icons';
import medialoopsterTheme from 'medialoopster/medialoopsterTheme';
import { getLink, getLinkHref, getResourceURI, mlRel, ResourceMap } from 'medialoopster/rest';

import KeywordCategories from '../../sections/DetailsSection/KeywordCategories';
import { detailsActions, detailsSelectors, detailsTypes } from '../../state/modules/details';
import { getKeywordsByCategories } from '../../state/modules/details/selectors';
import { LicenseDisplayStatus } from '../../state/modules/details/types';
import { favoritesSelectors } from '../../state/modules/favorites';
import { keywordsSelectors } from '../../state/modules/keywords';
import { searchTypes } from '../../state/modules/search';
import { videoTypes } from '../../state/modules/video';
import { AssetType } from '../../state/types/asset/baseTypes';
import { textWithHighlightedItems } from '../services/highlighting';

export const flagLicense = (
  theme: Theme,
  licenseStatus?: detailsTypes.License['display_status_id'],
): string => {
  if (licenseStatus === LicenseDisplayStatus.GREEN) {
    return theme.palette.success.main;
  }
  if (licenseStatus === LicenseDisplayStatus.YELLOW) {
    return theme.palette.warning.main;
  }
  if (licenseStatus === LicenseDisplayStatus.RED) {
    return theme.palette.error.main;
  }
  return theme.palette.grey[500];
};

const useStyles = makeStyles<
  typeof medialoopsterTheme,
  { licenseStatus?: detailsTypes.License['display_status_id'] }
>((theme) => ({
  shot: {
    display: 'flex',
    padding: '0.4rem 0.9rem',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
      },
    },
  },
  image: {
    alignSelf: 'center',
  },
  activeShot: {
    boxShadow: `${theme.palette.primary.main} 0px 0px 4px 2px`,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cursorGrab: {
    cursor: 'grab',
  },
  metadata: {
    paddingLeft: '0.4rem',
  },
  licenseFlag: ({ licenseStatus }) => ({
    borderLeft: 'solid 10px',
    borderColor: flagLicense(theme, licenseStatus),
  }),
}));

const getLicenseStatus = (shot: videoTypes.Shot, licenses: ResourceMap<detailsTypes.License>) => {
  const href = getLinkHref(shot, mlRel('license'));
  if (href) {
    return licenses.resources[href]?.display_status_id;
  }
  return undefined;
};

const MAX_DISPLAY_KEYWORDS = 12;

const useEditMenuStyles = makeStyles(() => ({
  menu: {
    marginLeft: 'auto',
  },
}));

interface ShotEditMenuProps {
  readonly shot: videoTypes.Shot;
  readonly canDelete: boolean;
  readonly hasDeletePermission: boolean;
  readonly isKeywordsEditMode: boolean;
  readonly setKeywordsEditURI?: (keywordsEditURI: string | null) => void;
}

const ShotEditMenu: FC<ShotEditMenuProps> = ({
  shot,
  setKeywordsEditURI,
  isKeywordsEditMode,
  canDelete,
  hasDeletePermission,
}: ShotEditMenuProps) => {
  const dispatch = useDispatch();
  const classes = useEditMenuStyles();
  const canEditKeywords = useSelector(detailsSelectors.canEditShotKeywords);
  const onKeywordsEdit = useCallback(() => {
    if (setKeywordsEditURI) {
      if (isKeywordsEditMode) {
        setKeywordsEditURI(null);
      } else {
        setKeywordsEditURI(getResourceURI(shot));
      }
    }
  }, [shot, isKeywordsEditMode, setKeywordsEditURI]);
  const options = useMemo(
    () => [
      {
        key: 'edit',
        label: gettext('Edit'),
        onSelect: onKeywordsEdit,
        selected: isKeywordsEditMode,
        icon: <EditList fontSize="small" />,
        visible: canEditKeywords,
      },
      {
        key: 'delete',
        label: gettext('Delete'),
        onSelect: () => {
          dispatch(detailsActions.deleteShot(shot));
        },
        icon: <Delete fontSize="small" />,
        visible: canDelete && hasDeletePermission,
      },
    ],
    [
      canEditKeywords,
      shot,
      canDelete,
      hasDeletePermission,
      dispatch,
      isKeywordsEditMode,
      onKeywordsEdit,
    ],
  );
  return <DropdownMenuButton options={options} className={classes.menu} />;
};

type Props = {
  readonly assetId: number;
  readonly assetURI: string;
  readonly assetName: string;
  readonly assetIsAvailable: boolean;
  readonly assetTypeName: AssetType;
  readonly fps: string;
  readonly offsetFrames: number;
  readonly productionId: number;
  readonly shot: videoTypes.Shot;
  readonly highlights?: searchTypes.Highlight;
  readonly showKeywords?: boolean;
  readonly showLicenseFlag?: boolean;
  readonly isActive?: boolean;
  readonly canDelete?: boolean;
  readonly isSelected?: boolean;
  readonly isEditMode?: boolean;
  readonly isKeywordsEditMode?: boolean;
  readonly setKeywordsEditURI?: (keywordsEditURI: string | null) => void;
};

const Shot = forwardRef<HTMLDivElement, Props>(
  (
    {
      assetId,
      assetURI,
      assetTypeName,
      offsetFrames,
      fps,
      productionId,
      assetName,
      assetIsAvailable,
      shot,
      highlights,
      showKeywords = false,
      showLicenseFlag = false,
      isActive = false,
      canDelete = false,
      isSelected = false,
      isEditMode = false,
      isKeywordsEditMode = false,
      setKeywordsEditURI = undefined,
    }: Props,
    ref,
  ) => {
    const licenses = useSelector(detailsSelectors.getLicenses);
    const classes = useStyles({
      licenseStatus: getLicenseStatus(shot, licenses),
    });
    const dispatch = useDispatch();

    const canDrag = useSelector(favoritesSelectors.isActive);

    const selfLink = getLink(shot, 'self');
    const hasDeletePermission = selfLink?.methods?.DELETE?.code === 'ok';

    const [, drag, preview] = useDrag({
      type: 'shot',
      item: {
        id: shot.id,
        type: 'shot',
        assetTypeName,
        timecode_start: shot.timecode_start,
        timecode_end: shot.timecode_end,
        asset_id: assetId,
        thumbnailURL: shot.thumbnail_url,
        deleted: false,
        displayName: assetName,
        detail: `${formatFramesAsTimecode(shot.timecode_start, fps)} - ${formatFramesAsTimecode(
          shot.timecode_end,
          fps,
        )}`,
        assetIsAvailable,
      },
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    const onImageClick = useCallback(() => {
      dispatch(detailsActions.selectClip(assetURI, shot.timecode_start, shot.timecode_end));
    }, [dispatch, assetURI, shot]);
    const keywordCategories = useSelector(keywordsSelectors.getVideoAssetKeywordCategories);
    const { keywords } = shot;

    return (
      <>
        <div
          ref={ref}
          role="none"
          className={clsx(classes.shot, { 'Mui-selected': isSelected })}
          onMouseDown={(e) => {
            if (isEditMode) {
              if (e.shiftKey) {
                dispatch(detailsActions.selectShotRangeEnd(shot));
              } else {
                dispatch(detailsActions.selectShotRangeStart(shot));
              }
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onKeyUp={() => {}}
          onFocus={() => {}}
          data-testid="shot"
        >
          <ImageWithLoadingPlaceholder
            dragRef={drag}
            url={shot.thumbnail_url}
            placeholderWidth={102}
            placeholderHeight={57}
            onClick={onImageClick}
            className={clsx(classes.image, {
              [classes.cursorGrab]: canDrag,
              [classes.cursorPointer]: !canDrag,
              [classes.licenseFlag]: showLicenseFlag,
              [classes.activeShot]: isActive,
            })}
          />
          <div className={classes.metadata}>
            <Typography variant="caption">
              {formatFramesAsTimecode(shot.timecode_start + offsetFrames, fps)}
              {' - '}
              {formatFramesAsTimecode(shot.timecode_end + offsetFrames, fps)}
            </Typography>
            <br />
            {showKeywords && (
              <Tooltip
                title={
                  shot.keywords.length > MAX_DISPLAY_KEYWORDS ? (
                    <div>
                      <Typography variant="body1">{gettext('Complete keywords')}:</Typography>
                      <Typography variant="body2">
                        {textWithHighlightedItems(shot.keywords, highlights?.fields?.keywords)}
                      </Typography>
                    </div>
                  ) : (
                    ''
                  )
                }
                placement="bottom"
              >
                <Typography variant="caption">
                  {textWithHighlightedItems(
                    shot.keywords.slice(0, MAX_DISPLAY_KEYWORDS),
                    highlights?.fields?.keywords,
                  )}
                  {shot.keywords.length > MAX_DISPLAY_KEYWORDS && '[ … ]'}
                </Typography>
              </Tooltip>
            )}
          </div>
          {isEditMode && (
            <ShotEditMenu
              shot={shot}
              canDelete={canDelete}
              hasDeletePermission={hasDeletePermission}
              isKeywordsEditMode={isKeywordsEditMode}
              setKeywordsEditURI={setKeywordsEditURI}
            />
          )}
        </div>
        {isEditMode && isKeywordsEditMode && (
          <KeywordCategories
            obj={shot}
            keywordsByCategory={getKeywordsByCategories(
              keywords,
              keywordCategories.resources,
              productionId,
            )}
          />
        )}
        <Divider light />
      </>
    );
  },
);

export default memo(Shot);
