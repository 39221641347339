import {
  Action,
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
  Middleware,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { createEpicMiddleware, Epic, ofType } from 'redux-observable';
import { Persistor, persistStore } from 'redux-persist';
import { concat, mergeMap, Observable, of } from 'rxjs';

import createRootEpic from 'medialoopster/createRootEpic';
import {
  createRESTEpicDependencies,
  fetchRootResource,
  fetchRootResourceEpic,
  toTypeName,
} from 'medialoopster/rest';
import loginReducer, {
  getTokenAuthHeader,
  loginEpics,
  loginSelectors,
  resettingReducer,
} from 'medialoopster/state/login';
import { INIT_APP } from 'medialoopster/state/login/types';
import { downloadFromURLEpic } from 'medialoopster/state/main/epics';

import * as reducers from './modules';
import { addAssetsToCollectionEpics } from './modules/addAssetsToCollection';
import { appConnectorsEpics } from './modules/appConnectors';
import { archiveActions, archiveEpics } from './modules/archive';
import { assetSharingEpics } from './modules/assetSharing';
import { bulkEditEpics } from './modules/bulkEdit';
import { createAssetCollectionModalEpic } from './modules/createAssetCollectionModal';
import { detailsActions, detailsEpics } from './modules/details';
import { deviceEpics } from './modules/device';
import { downloadEpics } from './modules/download';
import { favoritesEpics } from './modules/favorites';
import { keywordsEpics } from './modules/keywords';
import { licensingEpics } from './modules/licensing';
import { mainEpics } from './modules/main';
import { operationsEpics } from './modules/operations';
import { personsActions, personsEpics } from './modules/persons';
import { productionsActions, productionsEpics } from './modules/productions';
import { restEpics } from './modules/rest';
import { searchEpics } from './modules/search';
import { searchFieldMappingEpics } from './modules/searchfieldmappings';
import { transferEpics } from './modules/transfer';
import { uploadEpics } from './modules/upload';
import { usersActions, usersEpics } from './modules/users';
import { AssetType } from './types/asset/baseTypes';
import { RootState } from './types/rootState';

export const actionLog: { action: Action; nextState: RootState }[] = [];
const logMiddleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    const result = next(action);
    actionLog.push({ action, nextState: getState() });

    return result;
  };

export const initAppEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType(INIT_APP),
    mergeMap(() => {
      let initAppObservables: Observable<Action> = of(
        fetchRootResource('/api/'),
        // must be dispatched before setCurrentAsset
        personsActions.setConfidenceThreshold(configs.contentAnalysis.confidenceThreshold),
        personsActions.fetchFacesOptions(),
        personsActions.fetchSegmentsOptions(),
        archiveActions.fetchArchiveAssetOptions(),
        archiveActions.fetchRestoreArchivedAssetOptions(),
      );

      const layoutString = localStorage.getItem('layout');
      if (layoutString) {
        const layout = JSON.parse(layoutString);
        initAppObservables = concat(
          initAppObservables,
          of(detailsActions.toggleView(layout.view, layout.sidebarType)),
        );
      }

      const currentAssetString = localStorage.getItem('currentAssetState');
      const embeddedAssetString = localStorage.getItem('embeddedAssetState');

      const queryParams = new URLSearchParams(window.location.search);
      const assetType = queryParams.get('asset') as AssetType | null;
      const urlAssetId = queryParams.get('id');

      if (assetType && urlAssetId) {
        const assetID = parseInt(urlAssetId, 10);
        const href = `${window.location.origin}/api/${assetType}s/${assetID}/`;

        initAppObservables = concat(
          initAppObservables,
          of(
            detailsActions.setCurrentAsset(assetType, href),
            detailsActions.setEmbeddedAsset(assetType, href),
            detailsActions.toggleView('player', 'default'),
          ),
        );
      } else {
        if (currentAssetString) {
          const currentAssetState = JSON.parse(currentAssetString);
          const currentAssetTypeName =
            typeof currentAssetState?.type === 'string'
              ? toTypeName<AssetType>(currentAssetState.type)
              : null;

          initAppObservables = concat(
            initAppObservables,
            of(
              detailsActions.setCurrentAsset(currentAssetTypeName, currentAssetState.href || null),
            ),
          );
        }
        if (embeddedAssetString) {
          const embeddedAssetState = JSON.parse(embeddedAssetString);
          const embeddedAssetTypeName =
            typeof embeddedAssetState?.type === 'string'
              ? toTypeName<AssetType>(embeddedAssetState.type)
              : null;

          if (embeddedAssetTypeName) {
            initAppObservables = concat(
              initAppObservables,
              of(
                detailsActions.setEmbeddedAsset(
                  embeddedAssetTypeName,
                  embeddedAssetState.href || null,
                ),
              ),
            );
          }
        }
      }

      initAppObservables = concat(
        initAppObservables,
        of(productionsActions.fetchProductions(), usersActions.fetchUsers()),
      );

      return initAppObservables;
    }),
  );

/**
 * Initialize Redux by creating the store.
 *
 * The store is created from all reducers and middlewares registered by `addReducer()`
 * and `addMiddleware()` and is observed by the observers registered by
 * `addObserver()`.
 * After the store has been created, all initialization functions are called in the
 * order in which they have been registered.
 * @param devToolsOptions - Options for recording call stack traces for dispatched actions and for storing them in the history tree.
 * @param shouldLogActions - Whether to use the logging Middleware, by default false.
 * @param initState - An initial redux state. If `undefined` (default), no initial state will be set. If `non-undefined`, the state is initially set with it (used only for storybook Stories).
 * @param epics - A list of epics to run in the `epicMiddleware`. If `undefined` (default), all system epics are run. If `non-undefined`, only the given epics are run (used only for storybook Stories), in which case it can also be `[]`, to fully disable epics.
 * @param withRESTEpicDependencies - Whether the epics that run in the `epicMiddleware` should also use RESTEpicDependencies, if any is called within them. `true` is default. (`false` used only for storybook Stories).
 * @returns - The created store.
 * @returns {Object} - Dictionary with the created store and a persistor for this store.
 */
const initStore = (
  devToolsOptions: DevToolsOptions,
  shouldLogActions = false,
  initState?: RootState,
  withRESTEpicDependencies = true,
  epics?: Epic[],
): { store: Store; persistor: Persistor } => {
  const rootReducer = combineReducers<RootState>({ ...reducers, login: loginReducer });
  const dependencies = {};
  const epicMiddleware = createEpicMiddleware<Action, Action, RootState>({ dependencies });
  const configuredMiddlewares = shouldLogActions ? [logMiddleware] : [];
  const composeEnhancers = composeWithDevTools(devToolsOptions);
  const enhancer = composeEnhancers(
    applyMiddleware(...configuredMiddlewares.concat([epicMiddleware])),
  );
  let store: Store;

  if (initState !== undefined) {
    store = createStore(resettingReducer(rootReducer), initState, enhancer);
  } else {
    store = createStore(resettingReducer(rootReducer), enhancer);
  }

  if (withRESTEpicDependencies) {
    Object.assign(
      dependencies,
      createRESTEpicDependencies(store.getState(), () => ({
        ...getTokenAuthHeader(loginSelectors.getToken(store.getState())),
      })),
    );
  }

  if (epics !== undefined) {
    epicMiddleware.run(createRootEpic(...epics));
  } else {
    epicMiddleware.run(
      createRootEpic(
        appConnectorsEpics,
        addAssetsToCollectionEpics,
        archiveEpics,
        bulkEditEpics,
        loginEpics,
        initAppEpic,
        fetchRootResourceEpic,
        operationsEpics,
        detailsEpics,
        searchEpics,
        favoritesEpics,
        assetSharingEpics,
        licensingEpics,
        mainEpics,
        usersEpics,
        keywordsEpics,
        searchFieldMappingEpics,
        personsEpics,
        productionsEpics,
        deviceEpics,
        transferEpics,
        downloadEpics,
        uploadEpics,
        restEpics,
        createAssetCollectionModalEpic,
        downloadFromURLEpic,
      ),
    );
  }
  return { store, persistor: persistStore(store) };
};

export default initStore;
