import Error from '@mui/icons-material/Error';
import List from '@mui/icons-material/List';
import SpeakerNotes from '@mui/icons-material/SpeakerNotes';
import Video from '@mui/icons-material/Theaters';
import ViewSidebar from '@mui/icons-material/ViewSidebar';
import { createSvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef } from 'react';

const useStyles = makeStyles((theme) => ({
  Mirror: {
    transform: 'scaleX(-1)',
  },
  errorInfoIcon: {
    color: theme.palette.error.dark,
  },
}));

// Common.
export { default as Add } from '@mui/icons-material/Add';
export { default as ArrowDropDown } from '@mui/icons-material/ArrowDropDown';
export { default as ArrowDropUp } from '@mui/icons-material/ArrowDropUp';
export { default as ChevronLeft } from '@mui/icons-material/ChevronLeft';
export { default as ChevronRight } from '@mui/icons-material/ChevronRight';
export { default as ClearField } from '@mui/icons-material/Close';
export { default as ClearAllFields } from '@mui/icons-material/Cancel';
export { default as Close } from '@mui/icons-material/Close';
export { default as Copy } from '@mui/icons-material/FileCopy';
export { default as Day } from '@mui/icons-material/Today';
export { default as Calendar } from '@mui/icons-material/Event';
export { default as Delete } from '@mui/icons-material/Delete';
export { default as Download } from '@mui/icons-material/Download';
export { default as Edit } from '@mui/icons-material/Edit';
export { default as EditOff } from '@mui/icons-material/EditOff';
export { default as Apps } from '@mui/icons-material/Apps';
export { List as EditList };
export const ErrorML = forwardRef<SVGSVGElement, SvgIconProps>(
  ({ className, ...props }: SvgIconProps, ref) => {
    const classes = useStyles();
    return (
      <Error
        className={clsx(className, classes.errorInfoIcon)}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  },
);
export { default as File } from '@mui/icons-material/InsertDriveFile';
export { default as Info } from '@mui/icons-material/Info';
export { default as Link } from '@mui/icons-material/Input';
export { default as Logout } from '@mui/icons-material/Logout';
export { default as PermissionDenied } from '@mui/icons-material/Block';
export { default as Refresh } from '@mui/icons-material/Refresh';
export { default as RemoveRow } from '@mui/icons-material/Block';
export { default as Remove } from '@mui/icons-material/Remove';
export { default as Search } from '@mui/icons-material/Search';
export { default as SearchOptions } from '@mui/icons-material/ManageSearch';
export { default as Time } from '@mui/icons-material/AccessTime';
export { default as Undo } from '@mui/icons-material/Undo';
export { default as User } from '@mui/icons-material/Person';
export { default as Collapse } from '@mui/icons-material/ExpandLess';
export { default as Account } from '@mui/icons-material/AccountCircle';

export const SearchOptionsOff = createSvgIcon(
  <g>
    <rect x="2" y="12" width="5" height="2" />
    <path
      d="M14,8c1.7,0,3,1.3,3,3c0,0.8-0.4,1.6-0.9,2.2l5.2,5.2l0.7-0.7l-3.8-3.8C18.7,13,19,12,19,11c0-2.8-2.2-5-5-5
		c-1.4,0-2.7,0.6-3.6,1.5l1.4,1.4C12.4,8.4,13.2,8,14,8z"
    />
    <rect x="2" y="17" width="10" height="2" />
    <path d="M22,21.9l-8.5-8.5L3.7,3.6L2.2,2.1L0.8,3.5L4.3,7H2v2h4.3l2.8,2.8c0.3,2.1,2,3.8,4.1,4.1l7.4,7.4L22,21.9z" />
  </g>,
  'SearchOptionsOff',
);

// Views.
export { default as DetailView } from '@mui/icons-material/Home';
export { default as SearchView } from '@mui/icons-material/ViewList';
export { ViewSidebar as PlayerView };
export { SpeakerNotes as TranscriptView };

// Assets.
export { default as Online } from '@mui/icons-material/Task';
export const Offline = createSvgIcon(
  <g>
    <path d="M20,16.7V8l-6-6H6C5.8,2,5.6,2,5.4,2.1L20,16.7z M13,3.5L18.5,9H13V3.5z" />
    <path
      d="M20,19.5l-7.9-7.9l-8-8L2.2,1.7L0.8,3.1l1.6,1.6L4,6.3L4,20c0,1.1,0.9,2,2,2h12c0.5,0,0.9-0.2,1.2-0.4l1.3,1.3l1.4-1.4
		  L20,19.5z"
    />
  </g>,
  'Offline',
);
export { default as AssetActions } from '@mui/icons-material/BuildCircle';

// Media assets.
export { Video };
export { default as Image } from '@mui/icons-material/Image';
export { default as Audio } from '@mui/icons-material/Audiotrack';
export { default as HD } from '@mui/icons-material/Hd';
export { default as SD } from '@mui/icons-material/Sd';
export { default as EightK } from '@mui/icons-material/EightK';
export { default as FourK } from '@mui/icons-material/FourK';
export { default as NA } from '@mui/icons-material/IndeterminateCheckBox';

// Project assets.
export { default as Project } from '@mui/icons-material/BusinessCenter';
export const Collection = createSvgIcon(
  <>
    <path d="M18,20v2H4c-1.1,0-2-0.9-2-2V6h2v14H18z" />
    <path
      d="M20,2H8C6.9,2,6,2.9,6,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M10,4.8l3.3,2.5L10,9.8V4.8z M10,16
	l2-2.7l1.3,1.8l2-2.5L18,16H10z M18.7,6.8h-1.6v2.9c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
	c0.3,0,0.6,0.1,0.8,0.3V5.8h2.1V6.8z"
    />
  </>,
  'Collection',
);
export { default as AddToProject } from '@mui/icons-material/PlaylistAdd';
export { default as AddToAssetCollection } from '@mui/icons-material/LibraryAdd';
export { default as AssetLink } from '@mui/icons-material/Launch';
export { default as RemoveLink } from '@mui/icons-material/LinkOff';

// Favorite lists.
export { default as Favorites } from '@mui/icons-material/Bookmark';
export { default as ExportList } from '@mui/icons-material/MovieFilter';
export { default as ClearList } from '@mui/icons-material/Delete';
export const SharedList = createSvgIcon(
  <>
    <path
      d="M21,8.2c-0.3,0-0.7,0.1-0.9,0.4l-3.2-1.9c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3l3.2-1.9c0.2,0.2,0.6,0.4,0.9,0.4
	c0.8,0,1.4-0.6,1.4-1.4S21.8,1.8,21,1.8s-1.4,0.6-1.4,1.4c0,0.1,0,0.2,0,0.3l-3.2,1.9C16.2,5.1,15.9,5,15.5,5
	c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.4,0,0.7-0.1,0.9-0.4l3.2,1.9c0,0.1,0,0.2,0,0.3c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
	C22.3,8.8,21.7,8.2,21,8.2z"
    />
    <path d="M19,21l-7-3l-7,3V5c0-1.1,0.9-2,2-2h7c-0.6,0.8-1,1.9-1,3c0,2.8,2.2,5,5,5c0.3,0,0.7,0,1-0.1V21z" />
  </>,
  'SharedList',
);
export { default as CreateList } from '@mui/icons-material/BookmarkAdd';
export { default as EditingSystem } from '@mui/icons-material/MovieCreation';
export const DownloadFavorites = createSvgIcon(
  <path
    d="M17,3H7C5.9,3,5,3.9,5,5l0,16l7-3l7,3V5C19,3.9,18.1,3,17,3z M11.1,9.3V6.1h1.6v3.2h2.4l-3.2,3.2L8.7,9.3H11.1z M15.9,14.9
	h-8v-1.6h8V14.9z"
  />,
  'DownloadFavorites',
);
export { List as DragMultipleItems };
export const FavoritesOff = createSvgIcon(
  <g>
    <path d="M19,5v11.7L5.7,3.5C6.1,3.2,6.5,3,7,3h10C18.1,3,19,3.9,19,5z M3.6,3.8L2.3,5.1L5,7.8L5,21l7-3l5.6,2.4l2.2,2.2l1.3-1.3" />
  </g>,
  'FavoritesOff',
);

// Transfer.
export { default as Transfer } from '@mui/icons-material/SyncAlt';

// Archiving.
export const RemoveFromArchive = createSvgIcon(
  <>
    <style type="text/css">{'.st0{fill:#FFFFFF;}'}</style>
    <g>
      <g>
        <path
          d="M20.5,5.2C19.9,4.7,19.1,2.9,18,3H6C5.9,3,5.8,3,5.7,3.1L6.6,4h11.3l0.9,1H7.6l5,5H14v1.4l0.6,0.6h2.9L16,13.5l4.9,4.9
			C21,15.7,21.3,5.3,20.5,5.2z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M13.3,16.2L12,17.5L6.5,12H9L3.1,6.1C3,6.2,3,6.4,3,6.5V19c0,1.1,0.9,2,2,2h13L13.3,16.2z" />
      </g>
    </g>
    <g>
      <g>
        <polygon
          className="st0"
          points="21,18.4 16,13.5 14.6,12 6.6,4 5.7,3.1 3.5,0.9 2.1,2.3 2.1,2.3 4.1,4.3 10,10.2 14.7,14.9
			20.3,20.5 21.9,22.1 21.9,22.1 23.3,20.7 		"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M21.9,22.1l-1.6-1.6C20,20.8,19.5,21,19,21h-1l2.5,2.5L21.9,22.1L21.9,22.1z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M14.7,14.9l-1.4,1.4L18,21h1c0.5,0,1-0.2,1.3-0.5L14.7,14.9z" />
      </g>
    </g>
    <g>
      <g>
        <polygon points="10,10.2 10,12 9,12 13.3,16.2 14.7,14.9 		" />
      </g>
    </g>
    <g>
      <g>
        <path d="M4.1,4.3c-0.5,0.6-0.8,1.2-1,1.8L9,12h1v-1.8L4.1,4.3z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M2.1,2.3L0.8,3.7l2.3,2.4c0.2-0.6,0.5-1.2,1-1.8L2.1,2.3z" />
      </g>
    </g>
  </>,
  'RemoveFromArchive',
);
export { default as Archive } from '@mui/icons-material/Archive';
export { default as Restore } from '@mui/icons-material/Unarchive';
export { default as Archived } from '@mui/icons-material/Save';
export const NotArchived = createSvgIcon(
  <>
    <style type="text/css">{'.st0{fill:none;}'}</style>
    <path className="st0" d="M0,0h24v24H0V0z" style={{ opacity: 0 }} />
    <path
      d="M5.8,3H17l4,4v11.2L11.8,9H15V5H7.8L5.8,3z M20.4,20.4l1.5,1.5l-1.4,1.4L18.2,21H5c-1.1,0-2-0.9-2-2V5.8L0.7,3.5l1.4-1.4
	l1.5,1.5c0,0,0,0,0,0L5,5h0l4,4h0l4.3,4.3c0,0,0,0,0,0l0.1,0.1l1.2,1.2c0,0,0,0,0,0L20.4,20.4C20.4,20.4,20.4,20.4,20.4,20.4z
	 M6.2,9L5,7.8V9H6.2z M14.6,17.4l-4.1-4.1C9.6,13.9,9,14.9,9,16c0,1.7,1.3,3,3,3C13.1,19,14.1,18.4,14.6,17.4z"
    />
  </>,
  'Offline',
);

// Asset sharing.
export { default as ShareAsset } from '@mui/icons-material/Share';

// Content copy
export { default as ContentCopy } from '@mui/icons-material/ContentCopy';

// Poster frame
export { default as PosterFrame } from '@mui/icons-material/PhotoSizeSelectActual';

// Asset exchange.
export const ExchangeAsset = createSvgIcon(
  <g>
    <path d="M21.9,7.6L21.9,7.6c-0.8-1-1.8-1.2-3-1.1L5.6,7.3c-0.9,0.1-1.8,0.4-2.4,1C3.1,8.4,3.1,8.5,3,8.5   C2.9,8.6,2.9,8.7,2.8,8.8c0,0.1-0.1,0.1-0.1,0.2C2.6,9.1,2.5,9.2,2.4,9.4C2.4,9.5,2.3,9.6,2.3,9.7c-0.2,0.5-0.2,1-0.2,1.6L2.4,16   c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0,0c-1.1-1.1-1.2-2.8-1.2-2.8l0-4.7C1.3,5.1,5,5.1,5,5.1L18.3,5C20.7,5,21.6,6.5,21.9,7.6z   " />
    <path d="M23.2,14.7c0.2,3.7-3.5,4-3.5,4L6.4,19.5c-3,0.2-3.8-2.3-3.9-3.2c0.5,0.5,1.2,0.8,2.2,0.9c0,0,0.1,0,0.1,0   c0.1,0,0.2,0,0.3,0l13.3-0.1c0.4,0,0.8-0.1,1.2-0.2c0.1,0,0.1,0,0.2-0.1c1-0.4,2.3-1.2,2.4-3.3c0-0.1,0-0.2,0-0.2l0-4.7   c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.3-0.1-0.7-0.2-1c0.9,1,1,2.4,1,2.4L23.2,14.7z" />
  </g>,
  'ExchangeAsset',
);

// Webhooks.
export { default as Webhook } from '@mui/icons-material/Webhook';

// App Connectors.
export { default as AppConnector } from '@mui/icons-material/Shortcut';

// Upload.
export { default as Upload } from '@mui/icons-material/Upload';

// Annotations
export const RevokeShare = createSvgIcon(
  <g>
    <path
      d="M7.8,11.7c0.4,0,0.8,0,1.2,0.1c0-0.2,0-0.4-0.1-0.5L16,7.2C16.5,7.7,17.2,8,18,8c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3
		c0,0.2,0,0.5,0.1,0.7L8,9.8C7.5,9.3,6.8,9,6,9c-1.7,0-3,1.3-3,3c0,0.6,0.2,1.2,0.5,1.6C4.5,12.4,6.1,11.7,7.8,11.7z"
    />
    <path
      d="M18,16.1c-0.8,0-1.4,0.3-2,0.8l-2.9-1.7c0.3,0.7,0.5,1.5,0.5,2.3l1.6,0.9c0,0.2-0.1,0.4-0.1,0.7c0,1.6,1.3,2.9,2.9,2.9
		s2.9-1.3,2.9-2.9S19.6,16.1,18,16.1z"
    />
    <path
      d="M7.8,13c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S10.3,13,7.8,13z M10,19.1l-0.6,0.6l-1.6-1.6l-1.6,1.6l-0.6-0.6
		l1.6-1.6l-1.6-1.6l0.6-0.6l1.6,1.6l1.6-1.6l0.6,0.6l-1.6,1.6L10,19.1z"
    />
  </g>,
  'RevokeShare',
);

// medialoopster Apps.
export { default as Monitoring } from '@mui/icons-material/Speed';
export { default as Management } from '@mui/icons-material/Build';
export { default as LicenseManagement } from '@mui/icons-material/Receipt';
export { default as Admin } from '@mui/icons-material/Settings';
export const RoughCutEditor = createSvgIcon(
  <>
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <g>
          <path d="M4 10h18V6c0-1.1-.9-2-2-2h-3l2 4h-3l-2-4h-2l2 4h-3L9 4H7l2 4H6L4 4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h8v-2H4v-8z" />
        </g>
        <g>
          <polygon points="14,18.88 14,21 16.12,21 21.29,15.83 19.17,13.71" />
        </g>
        <g>
          <path d="m22.71 13-.71-.71c-.39-.39-1.02-.39-1.41 0l-.71.71L22 15.12l.71-.71c.39-.39.39-1.02 0-1.41z" />
        </g>
      </g>
    </g>
  </>,
  'RoughCutEditor',
);

// Content analysis.
export { default as Person } from '@mui/icons-material/Face';
export { default as PersonOff } from '@mui/icons-material/FaceRetouchingOff';
export { default as BoundingBoxes } from '@mui/icons-material/PictureInPicture';
export { default as Analyse } from '@mui/icons-material/AutoAwesome';
export const BoundingBoxesOff = createSvgIcon(
  <svg x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24;" xmlSpace="preserve">
    <g>
      <path d="M0.2,2.8L1.3,4C1.1,4.3,1,4.6,1,5v14c0,1.1,0.9,2,2,2h15.3l2.4,2.4l1.4-1.4L1.6,1.4L0.2,2.8z M3,19V5.7L16.4,19H3z" />
      <polygon points="19,7 11,7 11,8.3 15.7,13 19,13 	" />
      <path d="M21,3H5.7l2,2H21v13.3l1.7,1.7c0.2-0.3,0.3-0.6,0.3-1V5C23,3.9,22.1,3,21,3z" />
    </g>
  </svg>,
  'BoundingBoxesOff',
);

export const SplitPerson = createSvgIcon(
  <path
    xmlns="http://www.w3.org/2000/svg"
    d="M10.2,13c0,0.7-0.6,1.2-1.2,1.2S7.8,13.7,7.8,13s0.6-1.2,1.2-1.2S10.2,12.3,10.2,13z M15,11.8c-0.7,0-1.2,0.6-1.2,1.2  s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2S15.7,11.8,15,11.8z M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2c1.4,0,2.8,0.3,4,0.8V7h2v2  h3.5C21.8,10,22,11,22,12z M20,12c0-0.8-0.1-1.5-0.3-2.3C19,9.9,18.2,10,17.4,10c-3.4,0-6.3-1.7-8.2-4.2c-1,2.4-2.9,4.3-5.2,5.4  c0,0.3,0,0.6,0,0.9c0,4.4,3.6,8,8,8S20,16.4,20,12z M21.9,1h-2v2h-2v2h2v2h2V5h2V3h-2V1z"
  />,
  'SplitPerson',
);

// Video player.
export { default as SetOutFrame } from '@mui/icons-material/SkipPrevious';
export { default as SetInFrame } from '@mui/icons-material/SkipNext';
export { default as Insert } from '@mui/icons-material/GetApp';
export { default as GoToInFrame } from '@mui/icons-material/KeyboardTab';
export { default as FullScreen } from '@mui/icons-material/OpenInFull';
export { default as PlayerSettings } from '@mui/icons-material/Settings';
export { default as ArrowRight } from '@mui/icons-material/ArrowRight';

const KeyboardTabReverse = createSvgIcon(
  <svg x="0px" y="0px" viewBox="0 0 24 24" enableBackground={'new 0 0 24 24'} xmlSpace="preserve">
    <path fill="none" d="M24,24H0V0h24V24z" />
    <path d="M12.4,16.6L8.8,13H23v-2H8.8l3.6-3.6L11,6l-6,6l6,6L12.4,16.6z M4,18V6H2v12H4z" />
  </svg>,
  'GoToOutFrame',
);
export { KeyboardTabReverse as GoToOutFrame };

export { default as Mute } from '@mui/icons-material/VolumeDown';
export { default as Unmute } from '@mui/icons-material/VolumeMute';
export { default as PreviousFrame } from '@mui/icons-material/ChevronLeft';
export { default as NextFrame } from '@mui/icons-material/ChevronRight';
export { default as Pause } from '@mui/icons-material/Pause';
export { default as Play } from '@mui/icons-material/PlayArrow';
export { default as PreviousSecond } from '@mui/icons-material/FastRewind';
export { default as NextSecond } from '@mui/icons-material/FastForward';
export { default as Approve } from '@mui/icons-material/DoneAll';
export { default as Disapprove } from '@mui/icons-material/RemoveDone';
export { default as AddComment } from '@mui/icons-material/ChatBubble';
export { default as Send } from '@mui/icons-material/Send';
export { default as ShowMenu } from '@mui/icons-material/MoreVert';
export { default as Snapshot } from '@mui/icons-material/PhotoCamera';
export { default as Subtitles } from '@mui/icons-material/Subtitles';
export { default as SubtitlesOff } from '@mui/icons-material/SubtitlesOff';
export { default as AudioTrack } from '@mui/icons-material/Headphones';
export { default as CreateSequence } from '@mui/icons-material/Segment';
export { default as CreateShot } from '@mui/icons-material/PlaylistAdd';
const ChatBubbleOff = createSvgIcon(
  <svg x="0px" y="0px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24;" xmlSpace="preserve">
    <path
      d="M10.5,11L10,10.5L7.5,8L6,6.5L2.4,2.8L1.3,1.7L0,3l2,2l0,17l4-4h9l5.7,5.7l1.3-1.3L17.5,18C17.5,18,10.5,11,10.5,11z
	 M11.1,9 M20.1,18c1.1,0,1.9-0.9,1.9-2V4c0-1.1-0.9-2-2-2H4.1"
    />
  </svg>,
  'ChatBubbleOff',
);

export { ChatBubbleOff as AddCommentOff };

// RCE Timeline
export { default as BrokenImage } from '@mui/icons-material/BrokenImage';
export { default as ZoomIn } from '@mui/icons-material/ZoomIn';
export { default as ZoomOut } from '@mui/icons-material/ZoomOut';
export { default as ZoomOutMapIcon } from '@mui/icons-material/ZoomOutMap';
export { default as PresentToAll } from '@mui/icons-material/PresentToAll';

// SearchList
export { default as More } from '@mui/icons-material/ArrowDownward';
export { default as Recording } from '@mui/icons-material/RadioButtonChecked';
export { default as NoPreview } from '@mui/icons-material/VisibilityOff';
export { default as Preview } from '@mui/icons-material/Visibility';
export { default as AudioPreview } from '@mui/icons-material/Headphones';
export { default as NoAudioPreview } from '@mui/icons-material/HeadsetOff';
export { default as Warning } from '@mui/icons-material/ReportProblem';
export { default as Check } from '@mui/icons-material/Check';
export { default as NoResults } from '@mui/icons-material/SearchOff';

// List
export { default as OpenList } from '@mui/icons-material/ExpandMore';

// Transcript Editor
export { default as RecordVoiceOver } from '@mui/icons-material/RecordVoiceOver';
export { default as Article } from '@mui/icons-material/Article';
