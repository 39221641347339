import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, ngettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Restore } from 'medialoopster/icons';
import { getResourceTypeName } from 'medialoopster/rest';

import { archiveActions, archiveSelectors } from '../../../../state/modules/archive';
import { isAssetCollection } from '../../../../state/modules/rest/collection/types';
import { isAssetCollectionEmpty } from '../../../../state/modules/rest/collection/utils';
import { Asset } from '../../../../state/types/asset/unionTypes';

type Props = {
  readonly assets: ReadonlyArray<Asset>;
};

const RestoreFromArchiveActionMenuEntry = ({ assets }: Props) => {
  const dispatch = useDispatch();
  const allAvailable = assets.every((asset) => asset.is_production);
  const collectionIsEmpty = assets.every(
    (collection) => isAssetCollection(collection) && isAssetCollectionEmpty(collection),
  );
  const getToolTip = (): string => {
    if (allAvailable) {
      return gettext('All selected assets are already available.');
    }
    if (collectionIsEmpty) {
      return gettext('The collection is empty.');
    }
    return ngettext(
      'Restore the selected asset from the archive.',
      'Restore the selected assets from the archive.',
      assets.length,
    );
  };
  return (
    <MenuItem
      key="restore-from-archive"
      disabled={allAvailable || collectionIsEmpty}
      tooltip={getToolTip()}
      onClick={() => {
        dispatch(archiveActions.restoreArchivedAssets(assets));
      }}
      icon={<Restore fontSize="small" />}
      label={pgettext('Menu entry', 'Restore from archive')}
    />
  );
};

const useRestoreFromArchiveActionMenuEntry = (
  assets: ReadonlyArray<Asset>,
): ReadonlyArray<ReactElement> => {
  const canRestoreArchivedAssets = useSelector(archiveSelectors.canRestoreArchivedAssets);
  const restoreAssetTypes = useSelector(archiveSelectors.getRestoreAssetTypes);
  if (assets.length === 0 || !canRestoreArchivedAssets) {
    return [];
  }
  if (assets.every((asset) => !restoreAssetTypes.includes(getResourceTypeName(asset)))) {
    return [];
  }
  return [<RestoreFromArchiveActionMenuEntry key="restore-from-archive" assets={assets} />];
};

export default useRestoreFromArchiveActionMenuEntry;
