import _ from 'lodash';
import { createSelector } from 'reselect';

import { Device } from 'medialoopster/modules';
import { createCollectionSelector } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';

export const getDevices = createCollectionSelector(
  (state: RootState) => state.rest.device.collectionLink?.href,
  (state: RootState) => state.rest.device.pages,
  (state: RootState) => state.rest.device.objects,
);

export const getDeviceList = createSelector(
  getDevices,
  (devices): ReadonlyArray<Device> =>
    _.sortBy(Object.values(devices.items), [(device) => device.display_name]),
);
