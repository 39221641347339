import Typography, { TypographyProps } from '@mui/material/Typography';
import { FC } from 'react';

interface Props {
  readonly text: string;
  readonly align?: TypographyProps['align'];
}

const MultilineText: FC<Props> = ({ text, align = 'center' }: Props) => (
  <>
    {text
      .split(/(.*?[.?!]\s)/g)
      .filter((s) => s !== '')
      .map((sents) => sents.trim())
      .map((sentence, ind) => (
        <Typography key={`${sentence}${ind}`} align={align}>
          {sentence}
        </Typography>
      ))}
  </>
);
export default MultilineText;
