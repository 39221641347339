import _ from 'lodash';
import { createSelector } from 'reselect';

import { ContentAnalysisConnectorResource } from 'medialoopster/modules';
import {
  createCollection,
  createCollectionSelector,
  getLink,
  getLinkedItems,
  getLinkHref,
  getLinkPermission,
  getResourceLink,
  getResourceTypeName,
  getResourceURI,
  LinkedItemsPageResource,
  mlRel,
  normalizeURI,
  ResourceMap,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { isImageAsset, isVideoAsset } from '../../../businessRules/models/asset/utils';
import wrappedPersonNameLines from '../../../businessRules/services/wrappedPersonNameLines';
import buildURIWithAdditionalParameters from '../../../infrastructure/buildURIWithAdditionalParameters';
import { URL_FACES, URL_SEGMENTS } from '../../constants';
import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { detailsSelectors } from '../details';
import { getAssetChoices } from '../operations/selectors';
import { AssetChoice } from '../operations/types';
import { playerSelectors } from '../player';
import {
  DEFAULT_PLAYER_WIDTH,
  DEFAULT_SEGMENT_DURATION,
  FONT_SIZE_PROPORTIONAL,
  FONT_SIZE_PROPORTIONAL_Y,
  getDummyContext,
  MAX_BOUNDING_BOX_LABEL_LINE_LENGTH,
  MIN_BOUNDING_BOX_LABEL_LINE_LENGTH,
  TEXT_AREA_MARGIN,
  TEXT_PADDING_BOTTOM_IN_EM,
  TEXT_PADDING_LEFT_IN_EM,
  TEXT_PADDING_RIGHT_IN_EM,
  TEXT_PADDING_TOP_IN_EM,
} from './constants';
import {
  ANALYSE_ASSETS_REL,
  AnalyseConnectorChoice,
  BoundingBox,
  FaceResource,
  OverlayDisplayValues,
  Person,
  PersonResource,
  Segment,
  SegmentResource,
  TimelineSegment,
  PersonsRootState,
} from './types';

export const getPersons = (state: RootState): ResourceMap<PersonResource> => state.persons.persons;

export const getKnownPersons = createSelector(
  getPersons,
  (persons): ReadonlyArray<PersonResource> =>
    Object.values(persons.resources).filter((person) => person.name !== ''),
);

export const getSegments = (state: RootState): ResourceMap<SegmentResource> =>
  state.persons.segments;

export const getSegmentCollections = (state: RootState): PersonsRootState['segmentCollections'] =>
  state.persons.segmentCollections;

export const getFaces = (state: RootState): ResourceMap<FaceResource> => state.persons.faces;

export const canViewPersons = (state: RootState): boolean =>
  !!state.persons.segmentCollections['segment-collection'].options[normalizeURI(URL_SEGMENTS)]
    ?.actions?.GET;

export const canCreateFaces = (state: RootState): boolean =>
  !!state.persons.faceCollections.options[normalizeURI(URL_FACES)]?.actions?.POST;

export const canUpdateManySegments = (state: RootState): boolean =>
  !!state.persons.segmentCollections['segment-collection'].options[normalizeURI(URL_SEGMENTS)]
    ?.actions?.PATCH;

export const getActivePersonURI = (state: RootState): string | null =>
  state.persons.activePersonURI;

export const getCurrentAssetPersonCount = (state: RootState): number =>
  state.persons.currentAssetPersonCount;

export const getConfidenceThreshold = (state: RootState): string =>
  state.persons.confidenceThreshold;

export const canEditActivePerson = (state: RootState): boolean => {
  const uri = getActivePersonURI(state);
  if (!uri) {
    return false;
  }
  return (
    getLinkPermission(getResourceLink(getPersons(state).resources[uri]), 'PATCH').code === 'ok'
  );
};

export const isSplitPersonButtonVisible = (state: RootState): boolean =>
  detailsSelectors.isEditMode(state) &&
  isVideoAsset(detailsSelectors.getCurrentAsset(state)) &&
  getCurrentAssetPersonCount(state) > 0 &&
  canCreateFaces(state) &&
  canUpdateManySegments(state);

export const isPersonsVisible = (state: RootState): boolean => state.persons.isPersonsVisible;

const msToFrameNo = (timeMs: string, asset: Asset) => {
  if (!isVideoAsset(asset)) {
    return 0;
  }
  return Math.round((parseInt(timeMs, 10) * parseFloat(asset.fps)) / 1000);
};
export const getCurrentAssetSegments = createSelector(
  detailsSelectors.getCurrentAsset,
  getSegments,
  getSegmentCollections,
  getConfidenceThreshold,
  (currentAsset, allSegments, segmentCollections, confidenceThreshold): ReadonlyArray<Segment> => {
    if (currentAsset === null) {
      return [];
    }
    const segmentsURI = getLinkHref(currentAsset, mlRel('segments'));
    if (!segmentsURI) {
      return [];
    }
    const assetTypeName = getResourceTypeName(currentAsset);
    if (assetTypeName !== 'videoasset' && assetTypeName !== 'imageasset') {
      return [];
    }
    const collectionResourceMap: ResourceMap<
      LinkedItemsPageResource<'videoasset-segments' | 'imageasset-segments'>
    > = segmentCollections[assetTypeName];
    const assetSegments = createCollection(
      buildURIWithAdditionalParameters(segmentsURI, {
        confidence_threshold: confidenceThreshold,
      }),
      collectionResourceMap,
      (page) => getLinkedItems(page, allSegments),
    ).items.map((segment) => ({
      ...segment,
      startTimeMs: parseFloat(segment.start_time),
      endTimeMs: parseFloat(segment.end_time),
      startFrameNo: msToFrameNo(segment.start_time, currentAsset),
      endFrameNo: msToFrameNo(segment.end_time, currentAsset),
    }));
    if (isImageAsset(currentAsset)) {
      return assetSegments;
    }
    return assetSegments.map((segment, index, segments) => {
      if (segment.startFrameNo !== segment.endFrameNo) {
        return segment;
      }

      // HACK: Recap does not supply end times, so we have to figure them out.

      // Try using the frame just before the start of the next segment with the same face.
      const nextSegment = segments.slice(index + 1).filter((next) => next.face === segment.face)[0];
      const defaultEndTime = segment.startFrameNo + DEFAULT_SEGMENT_DURATION;

      // If there is no next segment with the same face or it is farther away than the
      // default segment duration, use self instead.
      const endFrameNo = nextSegment
        ? Math.min(nextSegment.startFrameNo - 1, defaultEndTime)
        : defaultEndTime;
      return {
        ...segment,
        endFrameNo,
      };
    });
  },
);

export const getCurrentAssetFaceURIs = (state: RootState): ReadonlyArray<string> =>
  getCurrentAssetSegments(state)
    .map((segment) => getLinkHref(segment, mlRel('face')))
    .filter((uri): uri is string => !!uri);

export const getCurrentAssetFaces = createSelector(
  getFaces,
  getCurrentAssetFaceURIs,
  (allFaces, facesURIs): ReadonlyArray<FaceResource> =>
    Object.values(allFaces.resources).filter((face) => facesURIs.includes(getResourceURI(face))),
);

export const getCurrentAssetSegmentsByPersonURI = createSelector(
  getCurrentAssetSegments,
  (segments): ReadonlyRecord<string, ReadonlyArray<Segment>> =>
    _.groupBy(segments, (segment) => getLinkHref(segment, mlRel('person'))),
);

const makePerson = (
  resource: PersonResource,
  segmentsByPersonURI: ReadonlyRecord<string, ReadonlyArray<Segment>>,
): Person => ({
  ...resource,
  startTimeMs:
    Math.min(
      ...(segmentsByPersonURI[getResourceURI(resource)] || []).map(
        (segment) => segment.startTimeMs,
      ),
    ) || 0,
});

export const getActivePerson = createSelector(
  getPersons,
  getActivePersonURI,
  getCurrentAssetSegmentsByPersonURI,
  (persons, activePersonURI, currentAssetSegmentsByPersonURI): Person | undefined => {
    const resource = persons.resources[String(activePersonURI)];
    if (!resource) {
      return undefined;
    }
    return makePerson(resource, currentAssetSegmentsByPersonURI);
  },
);

export const getCurrentAssetPersons = createSelector(
  getPersons,
  getCurrentAssetSegments,
  getCurrentAssetSegmentsByPersonURI,
  (allPersons, currentAssetSegments, segmentsByPersonURI): ReadonlyArray<Person> => {
    const personURIs = currentAssetSegments.map((segment) => getLinkHref(segment, mlRel('person')));
    return Object.values(allPersons.resources)
      .filter((person) => personURIs.includes(getResourceURI(person)))
      .map((person) => makePerson(person, segmentsByPersonURI))
      .sort((person1, person2) => person1.startTimeMs - person2.startTimeMs);
  },
);

// Default person colors.
const personColors = ['#d9534f', '#5bc0de', '#5cb85c', '#428bca'];
export const getColorByPersonURI = createSelector(
  getCurrentAssetPersons,
  (persons): ReadonlyRecord<string, string> =>
    Object.fromEntries(
      persons.map((person, index) => [
        getResourceURI(person),
        personColors[index % personColors.length],
      ]),
    ),
);

export const getActivePersonSegmentsInRange = createSelector(
  getCurrentAssetSegmentsByPersonURI,
  getActivePersonURI,
  playerSelectors.getInFrame,
  playerSelectors.getOutFrame,
  (currentAssetSegmentsByPersonURI, activePersonURI, inFrame, outFrame): ReadonlyArray<Segment> => {
    if (inFrame === null || outFrame === null || !activePersonURI) {
      return [];
    }
    const segments = currentAssetSegmentsByPersonURI[activePersonURI];
    return segments.filter(
      (segment) => segment.startFrameNo < outFrame && segment.endFrameNo > inFrame,
    );
  },
);

export const getPersonTimelineSegments = createSelector(
  getCurrentAssetSegmentsByPersonURI,
  getActivePersonURI,
  getColorByPersonURI,
  (segments, activePersonURI, colorByPersonURI): ReadonlyArray<TimelineSegment> => {
    const activePersonSegments = segments[String(activePersonURI)];
    if (!activePersonSegments) {
      return [];
    }
    const personSegments: Record<number, TimelineSegment> = {};
    let currentPersonSegment: TimelineSegment;
    activePersonSegments.forEach((segment) => {
      const currentPersonURI =
        currentPersonSegment && getLinkHref(currentPersonSegment, mlRel('person'));
      const personURI = getLinkHref(segment, mlRel('person'));
      if (
        currentPersonSegment &&
        currentPersonURI === personURI &&
        segment.startFrameNo === currentPersonSegment.endFrameNo + 1
      ) {
        currentPersonSegment = {
          ...currentPersonSegment,
          endFrameNo: segment.endFrameNo,
        };
      } else {
        currentPersonSegment = {
          ...segment,
          color: colorByPersonURI[String(personURI)],
        };
      }
      personSegments[currentPersonSegment.id] = currentPersonSegment;
    });

    return Object.values(personSegments);
  },
);

export const getConnectorCollection = createCollectionSelector(
  (state: RootState) => state.persons.connectorCollectionLink?.href,
  (state: RootState) => state.persons.connectorCollections,
  (state: RootState) => state.persons.connectors,
);

const filterAssetChoices = (
  connector: ContentAnalysisConnectorResource,
  assetChoices: ReadonlyArray<AssetChoice>,
): ReadonlyArray<AssetChoice> =>
  assetChoices.filter(({ typeName }) => connector.supported_asset_types.includes(typeName));

export const getAnalyseConnectorChoices = createSelector(
  getConnectorCollection,
  getAssetChoices,
  (connectorCollection, allAssetChoices): ReadonlyArray<AnalyseConnectorChoice> =>
    connectorCollection.items.flatMap((connector) => {
      const actionLink = getLink(connector, ANALYSE_ASSETS_REL);
      if (actionLink?.methods?.POST?.code !== 'ok') {
        return [];
      }
      const assetChoices = filterAssetChoices(connector, allAssetChoices);
      if (assetChoices.length === 0) {
        return [];
      }
      return [
        {
          name: connector.name,
          analyseActionURL: actionLink.href,
          assetChoices,
        },
      ];
    }),
);

export const isAllBoundingBoxesVisible = (state: RootState): boolean =>
  state.persons.isAllBoundingBoxesVisible;

export const isBoundingBoxEditMode = (state: RootState): boolean =>
  detailsSelectors.isEditMode(state) && canEditActivePerson(state) && isPersonsVisible(state);

/**
 * Display values of the overlay for the video/image tag.
 *
 * If a video is non-16/9 (e.g. 4/3) or has a vertical rotation (e.g. 16/9 and 90°), the video tag
 * dimensions stay the same but the video is display with a pillarbox effect. If the video is encoded
 * with vertical dimensions (x < y; e.g. 9/16), then the pillarbox effect is created programmatically
 * via HTML padding.
 * aspectRatioYOverX: The displayed reverse aspect ration of the media (y over x).
 * padding: `0` or the normalized left and right padding for stylized pillarboxing.
 * mediaWidth: `1` or the normalized width of the video content for stylized pillarboxing.
 *
 * Example overlay:
 * ╔═════════╦═════════╦═════════╗
 * ║ padding ║  9/16   ║ padding ║
 * ║ (left)  ║  media  ║ (right) ║
 * ║         ║  width  ║         ║
 * ...      ...       ...      ...
 * ╚═════════╩═════════╩═════════╝
 */
export const getOverlayDisplayValues = createSelector(
  detailsSelectors.getCurrentAsset,
  (currentAsset): OverlayDisplayValues => {
    if (isImageAsset(currentAsset)) {
      const ratio = currentAsset.size_x / currentAsset.size_y;
      const padding = (1 - ratio * (9 / 16)) / 2;
      return {
        padding,
        mediaWidth: 1 - padding * 2,
      };
    }
    if (isVideoAsset(currentAsset)) {
      const [x, y] = currentAsset.aspect_ratio.split(':').map((val) => +val);
      const isVertical = [90, 270].includes(currentAsset.rotation);
      const ratio = isVertical ? y / x : x / y;
      const padding = (1 - ratio * (9 / 16)) / 2;
      return {
        padding,
        mediaWidth: 1 - padding * 2,
      };
    }
    return {
      padding: 0,
      mediaWidth: 1,
    };
  },
);

/**
 * Get the bounding boxes by frame.
 *
 * Creates a bounding box for each segment, the given bounding box vertices are adjusted if the
 * video needs stylized pillarboxing.
 */
export const getBoundingBoxesByFrame = createSelector(
  getCurrentAssetSegments,
  getPersons,
  getOverlayDisplayValues,
  (segments, persons, overlayDisplayValues): ReadonlyRecord<number, ReadonlyArray<BoundingBox>> => {
    const frameNoToBoundingBoxes: Record<number, BoundingBox[]> = {};
    segments.forEach((segment) => {
      const personURI = getLinkHref(segment, mlRel('person'));
      const faceURI = getLinkHref(segment, mlRel('face'));
      if (!personURI || !faceURI || !segment.box) {
        return;
      }
      const person = persons.resources[personURI];
      if (!person) {
        return;
      }

      const nameLines = wrappedPersonNameLines(
        person.display_name,
        MIN_BOUNDING_BOX_LABEL_LINE_LENGTH,
        MAX_BOUNDING_BOX_LABEL_LINE_LENGTH,
      );

      const xValues = segment.box.map((vertex) => parseFloat(vertex[0]));
      const yValues = segment.box.map((vertex) => parseFloat(vertex[1]));
      const top = Math.min(...yValues);
      const bottom = Math.max(...yValues);
      // adjust x-values for potential pillarboxed video display
      const left =
        Math.max(0, Math.min(...xValues)) * overlayDisplayValues.mediaWidth +
        overlayDisplayValues.padding;
      const right =
        Math.min(1, Math.max(...xValues)) * overlayDisplayValues.mediaWidth +
        overlayDisplayValues.padding;

      const width = right - left;
      const center = left + width / 2;
      // Canvas is not used anymore for rendering but it is still the best way to get the text width
      const textWidth =
        Math.max(...nameLines.map((line) => getDummyContext().measureText(line).width)) /
        DEFAULT_PLAYER_WIDTH;
      const textAreaWidth =
        textWidth + FONT_SIZE_PROPORTIONAL * (TEXT_PADDING_LEFT_IN_EM + TEXT_PADDING_RIGHT_IN_EM);
      let textAreaLeft = center - textAreaWidth / 2;
      let textAreaTop = bottom + TEXT_AREA_MARGIN;
      const textAreaHeight =
        nameLines.length * FONT_SIZE_PROPORTIONAL_Y +
        FONT_SIZE_PROPORTIONAL_Y * (TEXT_PADDING_BOTTOM_IN_EM + TEXT_PADDING_TOP_IN_EM);
      if (textAreaWidth > width) {
        if (textAreaLeft < 0) {
          textAreaLeft = left;
        } else if (
          textAreaLeft + textAreaWidth >
          overlayDisplayValues.mediaWidth + overlayDisplayValues.padding
        ) {
          textAreaLeft = right - textAreaWidth;
        } // else, enough space to center text
      }
      if (textAreaTop > 1 - textAreaHeight) {
        // if not enough space below, put text on top
        const offset = TEXT_AREA_MARGIN + textAreaHeight;
        textAreaTop = top - offset;
        if (textAreaTop < 0) {
          // if not enough space below, put text within box
          textAreaTop = bottom - offset;
        }
      }
      const box = {
        segmentURI: getResourceURI(segment),
        left,
        right,
        top,
        bottom,
        width,
        center,
        faceURI,
        personURI,
        isUnknown: person.name === '',
        confidence: parseFloat(segment.confidence) * 100,
        name: nameLines,
        textAreaTop,
        textAreaLeft,
        textAreaWidth,
        textAreaHeight,
      };
      for (let frameNo = segment.startFrameNo; frameNo <= segment.endFrameNo; frameNo += 1) {
        if (frameNoToBoundingBoxes[frameNo]) {
          frameNoToBoundingBoxes[frameNo].push(box);
        } else {
          frameNoToBoundingBoxes[frameNo] = [box];
        }
      }
    });
    return frameNoToBoundingBoxes;
  },
);

export const hasRenameError = (state: RootState): boolean => state.persons.hasRenameError;
