import { load } from 'js-yaml';
import { combineReducers } from 'redux';

import {
  LICENSE_LOADED,
  LicenseLoaded,
  LicenseState,
  LicensingRootState,
  SITE_COUNT_LOADED,
  SiteCountLoaded,
} from './types';

export const initialLicenseState: LicenseState = {
  Info: { 'Date of Expiry': '' },
  Nodes: { Frontend: { Site: NaN } },
};

const isDataValidLicenseData = (data: unknown) =>
  typeof data === 'object' && data != null && 'Info' in data;

export const licenseReducer = (
  state = initialLicenseState,
  action: LicenseLoaded,
): LicenseState => {
  switch (action.type) {
    case LICENSE_LOADED: {
      const data = load(action.payload.license);
      const isValidLicense = isDataValidLicenseData(data);
      if (!isValidLicense) {
        return state;
      }
      return data as LicenseState;
    }
    default:
      return state;
  }
};

export const siteCountReducer = (state = NaN, action: SiteCountLoaded): number => {
  switch (action.type) {
    case SITE_COUNT_LOADED: {
      return action.payload.siteCount;
    }
    default:
      return state;
  }
};

export default combineReducers<LicensingRootState>({
  currentLicense: licenseReducer,
  siteCount: siteCountReducer,
});
