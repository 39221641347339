import { Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  label: string;
  component?: 'div' | 'span';
}

const TableLabel: FC<Props> = ({ label, component = 'div' }) => {
  return (
    <Typography
      sx={{ padding: (theme) => theme.spacing(2) }}
      variant="subtitle1"
      component={component}
    >
      {label}
    </Typography>
  );
};

export default TableLabel;
