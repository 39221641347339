import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { ImageWithLoadingPlaceholder } from 'medialoopster/components';
import { ErrorML, NoPreview, Pause, Preview, Recording, Video } from 'medialoopster/icons';
import { getResourceURI } from 'medialoopster/rest';

import {
  ERROR,
  GENERATING,
  mapStatus,
  PREPARING_IMPORT,
  PREVIEW_AVAILABLE,
  RECORDING,
} from '../../../../businessRules/models/AssetStatus';
import { favoritesSelectors } from '../../../../state/modules/favorites';
import { searchActions } from '../../../../state/modules/search';
import { VideoAsset as VideoAssetType } from '../../../../state/modules/video/types';
import Duration from '../../../../ui/components/Duration';
import FormattedDate from '../../../../ui/components/FormattedDate';
import useAssetDragRef from '../../../../ui/hooks/useAssetDragRef';
import AssetHeader from '../AssetHeader';
import LabeledInfo from '../LabeledInfo';
import MediaAssetStatusBar from '../MediaAssetStatusBar';
import ShotList from './ShotList';
import VideoAssetFormat from './VideoAssetFormat';

const statusTitleMap: Record<string, string> = {
  [ERROR]: gettext('An error occurred'),
  [PREPARING_IMPORT]: `${gettext('Preparing import')} …`,
  [RECORDING]: `${gettext('Recording')} …`,
  [GENERATING]: gettext('Generating video proxy'),
  [PREVIEW_AVAILABLE]: gettext('Video preview available'),
};

const statusLevelMap: Record<string, string> = {
  [ERROR]: 'error',
  [RECORDING]: 'error',
  [PREPARING_IMPORT]: 'warning',
  [GENERATING]: 'default',
  [PREVIEW_AVAILABLE]: 'default',
};

export interface VideoAssetProps {
  readonly asset: VideoAssetType;
  readonly isWide: boolean;
  readonly current: boolean;
  readonly inSelection: boolean;
}

const useStyles = makeStyles((theme) => ({
  cursorGrab: {
    cursor: 'grab',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  body: { display: 'flex', flexGrow: 1, alignItems: 'flex-end', paddingBottom: theme.spacing(1) },
  bodyInfo: { flexGrow: 1 },
  bodyInfoCentered: { display: 'flex', alignItems: 'center' },
  bodyImage: { display: 'flex', paddingRight: theme.spacing(1) },
  bodyIcon: { display: 'flex', paddingRight: theme.spacing(1) },
  bodyRowCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

const renderStatusIcon = (internalStatus: string) => {
  // 'Preview' icons must be aligned with the other icons, since it has no left-right margin
  if (internalStatus === PREVIEW_AVAILABLE)
    return <Preview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  if (internalStatus === ERROR) return <ErrorML style={{ fontSize: '1.2rem' }} />;
  if (internalStatus === PREPARING_IMPORT) return <Pause style={{ fontSize: '1.2rem' }} />;
  if (internalStatus === RECORDING) return <Recording style={{ fontSize: '1.2rem' }} />;
  if (internalStatus === GENERATING)
    return <NoPreview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  return <></>;
};

const isWithKeyCombo = (event: MouseEvent<Element>): boolean =>
  Boolean(event.shiftKey || event.altKey || event.ctrlKey || event.metaKey);

const renderAssetIcon = () => <Video fontSize="small" />;

const VideoAsset: FC<VideoAssetProps> = ({
  asset,
  isWide,
  current,
  inSelection,
}: VideoAssetProps) => {
  const classes = useStyles();
  const assetHref = getResourceURI(asset);
  const assetId = asset.id;

  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);

  const isFavoriteListActive = useSelector(favoritesSelectors.isActive);

  const imageDragRef = useAssetDragRef(asset, 'videoasset');
  const titleDragRef = useAssetDragRef(asset, 'videoasset');

  const internalStatus: ReturnType<typeof mapStatus> = asset.is_proxy_available
    ? 'preview_available'
    : mapStatus(asset.status_asset);
  const renderAssetStatusIcon = useCallback(
    () => renderStatusIcon(internalStatus),
    [internalStatus],
  );

  const handleHeaderClick = useCallback(
    (event: MouseEvent<Element>) => {
      // - triggers click events of current asset
      // - opens or closes loaded shots
      dispatch(searchActions.clickEntry(assetId, 'videoasset', assetHref, event));
      if (isWithKeyCombo(event)) {
        return;
      }
      if (current) {
        setOpen((toggle) => !toggle);
      }
    },
    [setOpen, dispatch, assetId, current, assetHref],
  );

  useEffect(() => {
    if (!current) {
      setOpen(false);
    }
  }, [setOpen, current]);

  const VideoAssetStatusBar: FC = () => {
    return (
      <MediaAssetStatusBar
        assetFormat={<VideoAssetFormat isWide={isWide} asset={asset} />}
        asset={asset}
        isWide={isWide}
        renderAssetStatusIcon={renderAssetStatusIcon}
        statusTitle={statusTitleMap[internalStatus]}
        statusLevel={statusLevelMap[internalStatus]}
        showLabelAssetStatus={isWide || internalStatus === ERROR || internalStatus === RECORDING}
      />
    );
  };
  return (
    <>
      <AssetHeader
        isOpen={isOpen}
        isWide={isWide}
        onAssetClick={handleHeaderClick}
        isCurrent={current}
        inSelection={inSelection}
      >
        {!isWide && (
          <div className={classes.header}>
            <span
              ref={titleDragRef}
              data-testid="draggableTitle"
              className={clsx(classes.bodyIcon, { [classes.cursorGrab]: isFavoriteListActive })}
            >
              {renderAssetIcon()}
            </span>
            <LabeledInfo isWide={false} label={gettext('Title')} big shorten>
              {asset.name}
            </LabeledInfo>
          </div>
        )}
        <div className={classes.body}>
          <div className={classes.bodyImage}>
            <ImageWithLoadingPlaceholder
              dragRef={imageDragRef}
              className={clsx({
                [classes.cursorGrab]: isFavoriteListActive,
              })}
              placeholderWidth={102}
              placeholderHeight={57}
              url={asset.thumbnail_url}
            />
          </div>

          {isWide && (
            <div className={classes.bodyInfo}>
              <div className={classes.bodyRowCentered}>
                <LabeledInfo isWide={isWide} label={gettext('Title')} big shorten>
                  {asset.name}
                </LabeledInfo>
                <div className={classes.bodyInfoCentered}>
                  <span className={classes.bodyIcon}>{renderAssetIcon()}</span>
                  {gettext('Videoasset')}
                </div>
              </div>

              <div className={classes.bodyRowCentered}>
                <LabeledInfo isWide={isWide} label={gettext('Production')}>
                  {asset.production_name}
                </LabeledInfo>
                <LabeledInfo isWide={isWide} label={gettext('Duration')}>
                  <Duration frames={asset.duration} fps={asset.fps} />
                </LabeledInfo>
              </div>

              <div className={classes.bodyRowCentered}>
                <VideoAssetStatusBar />
                <LabeledInfo isWide={isWide} label={gettext('Added')}>
                  <FormattedDate
                    date={asset.date_add}
                    format={pgettext(
                      'Localized numeric date and time with seconds',
                      'MM/dd/yyyy h:mm:ss a',
                    )}
                  />
                </LabeledInfo>
              </div>
            </div>
          )}

          {!isWide && (
            <div className={classes.bodyInfo}>
              <div className={classes.bodyRowCentered}>
                <div>
                  {/* empty line to be aligned with Audio, Image and Collection Asset Entries */}
                  <span>&nbsp;&nbsp;</span>
                </div>
              </div>

              <div className={classes.bodyRowCentered}>
                <LabeledInfo isWide={isWide} label={gettext('Duration')}>
                  <Duration frames={asset.duration} fps={asset.fps} />
                </LabeledInfo>
                <LabeledInfo isWide={isWide} label={gettext('Date added')}>
                  <FormattedDate
                    date={asset.date_add}
                    format={pgettext('Localized numeric date', 'MM/dd/yyyy')}
                  />
                </LabeledInfo>
              </div>

              <div className={classes.bodyRowCentered}>
                <LabeledInfo isWide={isWide} label={gettext('Production')}>
                  {asset.production_name}
                </LabeledInfo>
                <LabeledInfo isWide={isWide} label={gettext('Time added')}>
                  <FormattedDate
                    date={asset.date_add}
                    format={pgettext('Localized time with seconds', 'h:mm:ss a')}
                  />
                </LabeledInfo>
              </div>

              <div className={classes.bodyRowCentered}>
                <VideoAssetStatusBar />
              </div>
            </div>
          )}
        </div>
      </AssetHeader>
      {current && <ShotList isOpen={isOpen} asset={asset} />}
    </>
  );
};

export default memo(VideoAsset);
