import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getPreviousListId } from '../../../businessRules/models/FavoriteListsHelpers';
import { favoritesActions, favoritesSelectors } from '../../../state/modules/favorites';
import { usersSelectors } from '../../../state/modules/users';
import ClearListModal from './ClearListModal';
import CreateAssetCollectionModal from './CreateAssetCollectionModal';
import CreateListModal from './CreateListModal';
import EditListModal from './EditListModal';
import FavoriteLists from './FavoriteLists';
import FavoritesActionMenu from './FavoritesActionMenu';
import FavoritesBrand from './FavoritesBrand';
import SharedListsModal from './SharedListsModal';

const useStyles = makeStyles((theme) => ({
  toolbarDense: {
    minHeight: 'min-content',
    backgroundColor: theme.palette.background.secondary,
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

type FavoritesMenuProps = {
  selectedListId?: number;
  onListSelect: (id: number) => void;
};

const FavoritesMenu: FC<FavoritesMenuProps> = ({
  selectedListId,
  onListSelect,
}: FavoritesMenuProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const favoriteAssets = useSelector(favoritesSelectors.getSelectedListAssets, shallowEqual);

  const [showClearListModal, setShowClearListModal] = useState(false);
  const [showSharedListsModal, setShowSharedListsModal] = useState(false);
  const [showEditListModal, setShowEditListModal] = useState(false);
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [showCreateCollectionFromListModal, setShowCreateCollectionFromListModal] = useState(false);

  const onClearListClicked = () => {
    setShowClearListModal(true);
  };
  const onSharedListsClicked = () => {
    setShowSharedListsModal(true);
  };
  const onEditListClicked = () => {
    setShowEditListModal(true);
  };
  const onCreateListClicked = () => {
    setShowCreateListModal(true);
  };
  const onCreateCollectionFromListClicked = () => {
    setShowCreateCollectionFromListModal(true);
  };

  const onSharedListsClose = () => {
    setShowSharedListsModal(false);
  };
  const onCreateListClose = () => {
    setShowCreateListModal(false);
  };
  const onClearListClose = () => {
    setShowClearListModal(false);
  };

  const onEditListClose = () => {
    setShowEditListModal(false);
  };
  const onCreateCollectionFromListClose = () => {
    setShowCreateCollectionFromListModal(false);
  };

  const allOtherUsers = useSelector(usersSelectors.getAllOtherUsers);
  const sharedListsWithUserDisplayName = useSelector(
    favoritesSelectors.getSharedListsWithUserDisplayName,
  );
  const favoriteLists = useSelector(favoritesSelectors.getVisibleListsForUser);
  const sharedActiveLists = useSelector(favoritesSelectors.getSharedActiveLists);

  useEffect(() => {
    if (showSharedListsModal) {
      dispatch(favoritesActions.fetchFavoriteLists());
    }
  }, [showSharedListsModal, dispatch]);

  const onSharedListsSubmit = (selectedLists: ReadonlyArray<number>) => {
    dispatch(favoritesActions.setSharedActiveLists(selectedLists));

    if (selectedListId !== undefined) {
      // select new list if the selected list was a shared one and is not displayed anymore
      const removedLists = sharedActiveLists.filter((id) => !selectedLists.includes(id));
      if (removedLists.includes(selectedListId)) {
        const validLists = favoriteLists.filter(
          ({ id }) => id === selectedListId || !removedLists.includes(id),
        );
        const nextSelectedListId =
          validLists.length > 1
            ? getPreviousListId(validLists, selectedListId)
            : selectedLists[0] || null;
        dispatch(favoritesActions.selectFavoriteList(nextSelectedListId));
      }
    } else if (selectedLists.length > 0) {
      dispatch(favoritesActions.selectFavoriteList(selectedLists[0]));
    }
  };

  return (
    <Toolbar variant="dense" disableGutters classes={{ dense: classes.toolbarDense }}>
      <FavoritesBrand onDoubleClick={onSharedListsClicked} />
      <FavoriteLists
        onDoubleClick={onEditListClicked}
        favoriteLists={favoriteLists}
        onSelect={onListSelect}
        selectedListId={selectedListId}
      />
      <FavoritesActionMenu
        onSharedListsClicked={onSharedListsClicked}
        onEditListClicked={onEditListClicked}
        onCreateListClicked={onCreateListClicked}
        onClearListClicked={onClearListClicked}
        onCreateCollectionFromList={onCreateCollectionFromListClicked}
      />
      {showCreateCollectionFromListModal && (
        <CreateAssetCollectionModal
          show={showCreateCollectionFromListModal}
          onClose={onCreateCollectionFromListClose}
          assets={favoriteAssets}
        />
      )}
      {showClearListModal && (
        <ClearListModal show={showClearListModal} onClose={onClearListClose} />
      )}
      {showEditListModal && <EditListModal show={showEditListModal} onClose={onEditListClose} />}
      {showSharedListsModal && (
        <SharedListsModal
          show={showSharedListsModal}
          favoriteLists={sharedListsWithUserDisplayName}
          sharedActiveLists={sharedActiveLists}
          users={allOtherUsers}
          onSubmit={onSharedListsSubmit}
          onClose={onSharedListsClose}
        />
      )}
      {showCreateListModal && (
        <CreateListModal show={showCreateListModal} onClose={onCreateListClose} />
      )}
    </Toolbar>
  );
};

export default FavoritesMenu;
