import pointer from 'json-pointer';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { AlertFeedback, AlertMessage, alertMessage } from 'medialoopster/AlertMessage';
import { gettext, ngettext } from 'medialoopster/Internationalization';
import { ConnectorResource } from 'medialoopster/modules';
import {
  APIFieldError,
  BaseResource,
  getLinks,
  getResourceTypeName,
  getResourceURI,
  isAPIFieldError,
  mlRel,
  Resource,
  ResourceLoadedAction,
  RESTEpicDependencies,
  RESTError,
  UnauthorizedRequestAction,
  unauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { BaseAssetResource } from '../../types/asset/baseTypes';
import { RootState } from '../../types/rootState';
import { detailsSelectors } from '../details';
import {
  assetExchangeConnectorsLoaded,
  exchangeAssets,
  exchangedAssetsRemoveActionChecked,
  exchangedAssetsRemoved,
  removeExchangedAssets,
  webhooksLoaded,
} from './actions';
import {
  AssetExchangeConnectorsLoaded,
  CHECK_EXCHANGED_ASSETS_REMOVE_ACTION,
  CheckExchangedAssetsRemoveAction,
  EXCHANGE_ASSETS,
  ExchangeAssets,
  ExchangedAssetsRemoveActionChecked,
  ExchangedAssetsRemoved,
  FETCH_ASSET_EXCHANGE_CONNECTORS,
  REMOVE_EXCHANGED_ASSETS,
  RemoveExchangedAssets,
  TOGGLE_APPROVAL_STATUS,
  ToggleApprovalStatus,
  Webhook,
  WebhooksLoaded,
} from './types';

export const fetchCurrentAssetTypeWebhooksEpic: Epic<
  Action,
  WebhooksLoaded | UnauthorizedRequestAction,
  RootState
> = (_, state$) =>
  state$.pipe(
    map(detailsSelectors.getCurrentAssetType),
    distinctUntilChanged(),
    withLatestFrom(state$.pipe(map(loginSelectors.getToken))),
    switchMap(([assetType, token]) =>
      assetType
        ? ajax
            .get<ReadonlyArray<Webhook>>(
              `/api/webhooks/?model_name=${assetType}`,
              getTokenAuthHeader(token),
            )
            .pipe(
              map((response) => webhooksLoaded(response.response)),
              catchError((err) => {
                if (err && err.status === 401) {
                  return of(unauthorizedRequestAction());
                }
                return EMPTY;
              }),
            )
        : EMPTY,
    ),
  );

export const fetchAssetExchangeConnectorsEpic: Epic<
  Action,
  AssetExchangeConnectorsLoaded | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ASSET_EXCHANGE_CONNECTORS),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      ajax
        .get<ReadonlyArray<ConnectorResource<'assetexchangeconnector'>>>(
          '/api/assetexchangeconnectors/',
          getTokenAuthHeader(loginSelectors.getToken(state)),
        )
        .pipe(
          map((response) => assetExchangeConnectorsLoaded(response.response)),
          catchError((err) => {
            if (err && err.status === 401) {
              return of(unauthorizedRequestAction());
            }
            return of(assetExchangeConnectorsLoaded([]));
          }),
        ),
    ),
  );

export const exchangeAssetsEpic: Epic<
  Action,
  AssetExchangeConnectorsLoaded | ExchangeAssets | AlertMessage | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, ExchangeAssets['type'], ExchangeAssets>(EXCHANGE_ASSETS),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { actionURI, assetURIs, assetTitles, alreadyUploadedAssetURIs },
        },
        state,
      ]) =>
        ajax
          .post<Resource<string>>(
            actionURI,
            {
              _links: {
                [mlRel('asset')]: assetURIs.map((href) => ({ href })),
              },
            },
            {
              'Content-Type': 'application/json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            map(({ response }) => {
              const assetLinks = getLinks(response, mlRel('asset'));
              let message = ngettext(
                'Asset is being published.',
                'Assets are being published.',
                assetLinks.length,
              );
              let feedback: AlertFeedback = AlertFeedback.Success;
              if (alreadyUploadedAssetURIs.length > 0) {
                message += `\n\n${gettext(
                  'The following assets have already been published',
                )}:\n\n${alreadyUploadedAssetURIs
                  .map((assetURI) => assetTitles[assetURI])
                  .join('\n')}`;
                feedback = AlertFeedback.Info;
              }
              return alertMessage(message, feedback);
            }),
            catchError((err: RESTError) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              const defaultErrorMessage = ngettext(
                'Failed to exchange asset.',
                'Failed to exchange assets.',
                assetURIs.length,
              );
              if (!err.response || !err.response.errors || !err.request || !err.request.body) {
                return of(alertMessage(defaultErrorMessage));
              }
              const requestBody = JSON.parse(err.request.body);
              const moreAlreadyUploadedAssetURIs = err.response.errors
                .filter((error): error is APIFieldError =>
                  isAPIFieldError(error, 'already_uploaded'),
                )
                .map((error) => pointer.get(requestBody, error.source.pointer).href);
              if (moreAlreadyUploadedAssetURIs.length === assetURIs.length) {
                return of(
                  alertMessage(
                    ngettext(
                      'Asset has already been published.',
                      'Assets have already been published.',
                      moreAlreadyUploadedAssetURIs.length,
                    ),
                    AlertFeedback.Info,
                  ),
                );
              }
              const notUploadedAssetURIs = assetURIs.filter(
                (assetURI) => !moreAlreadyUploadedAssetURIs.includes(assetURI),
              );
              const allAlreadyUploadedAssetURIs = [
                ...alreadyUploadedAssetURIs,
                ...moreAlreadyUploadedAssetURIs,
              ];
              if (
                notUploadedAssetURIs.length > 0 &&
                notUploadedAssetURIs.length < assetURIs.length
              ) {
                // Retry with not already uploaded assets.
                return of(
                  exchangeAssets(
                    actionURI,
                    notUploadedAssetURIs,
                    assetTitles,
                    allAlreadyUploadedAssetURIs,
                  ),
                );
              }
              return of(alertMessage(defaultErrorMessage));
            }),
          ),
    ),
  );

export const removeExchangedAssetsEpic: Epic<
  Action,
  ExchangedAssetsRemoved | RemoveExchangedAssets | AlertMessage | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, RemoveExchangedAssets['type'], RemoveExchangedAssets>(REMOVE_EXCHANGED_ASSETS),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { actionURI, assetURIs, assetTitles, notUploadedAssetURIs },
        },
        state,
      ]) =>
        ajax
          .post<BaseResource>(
            actionURI,
            {
              _links: {
                [mlRel('asset')]: assetURIs.map((href) => ({ href })),
              },
            },
            {
              'Content-Type': 'application/json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            mergeMap(({ response }) => {
              const assetLinks = getLinks(response, mlRel('asset'));
              let message = ngettext(
                'Asset has been removed.',
                'Assets have been removed.',
                assetLinks.length,
              );
              let feedback: AlertFeedback = AlertFeedback.Success;
              if (notUploadedAssetURIs.length > 0) {
                message += `\n\n${gettext(
                  'The following assets have not been published',
                )}:\n\n${notUploadedAssetURIs.map((assetURI) => assetTitles[assetURI]).join('\n')}`;
                feedback = AlertFeedback.Info;
              }
              return of(alertMessage(message, feedback), exchangedAssetsRemoved());
            }),
            catchError((err: RESTError) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              const defaultErrorMessage = ngettext(
                'Failed to remove asset.',
                'Failed to remove assets.',
                assetURIs.length,
              );
              if (!err.response || !err.response.errors || !err.request || !err.request.body) {
                return of(alertMessage(defaultErrorMessage));
              }
              const requestBody = JSON.parse(err.request.body);
              const moreNotUploadedAssetURIs = err.response.errors
                .filter((error): error is APIFieldError => isAPIFieldError(error, 'not_uploaded'))
                .map((error) => pointer.get(requestBody, error.source.pointer).href);
              if (moreNotUploadedAssetURIs.length === assetURIs.length) {
                return of(
                  alertMessage(
                    ngettext(
                      'Asset has not been published.',
                      'Assets have not been published.',
                      moreNotUploadedAssetURIs.length,
                    ),
                    AlertFeedback.Error,
                  ),
                );
              }
              const uploadedAssetURIs = assetURIs.filter(
                (assetURI) => !moreNotUploadedAssetURIs.includes(assetURI),
              );
              const allNotUploadedAssetURIs = [
                ...notUploadedAssetURIs,
                ...moreNotUploadedAssetURIs,
              ];
              if (uploadedAssetURIs.length > 0 && uploadedAssetURIs.length < assetURIs.length) {
                // Retry with uploaded assets.
                return of(
                  removeExchangedAssets(
                    actionURI,
                    uploadedAssetURIs,
                    assetTitles,
                    allNotUploadedAssetURIs,
                  ),
                );
              }
              return of(alertMessage(defaultErrorMessage));
            }),
          ),
    ),
  );

export const checkExchangedAssetsRemoveActionEpic: Epic<
  Action,
  ExchangedAssetsRemoveActionChecked | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, CheckExchangedAssetsRemoveAction['type'], CheckExchangedAssetsRemoveAction>(
      CHECK_EXCHANGED_ASSETS_REMOVE_ACTION,
    ),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { connectorURI, actionURI, assetURIs },
        },
        state,
      ]) =>
        ajax
          .post(
            actionURI,
            {
              dry_run: true,
              _links: {
                [mlRel('asset')]: assetURIs.map((href) => ({ href })),
              },
            },
            {
              'Content-Type': 'application/json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            map(() => exchangedAssetsRemoveActionChecked(connectorURI, true)),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              return of(exchangedAssetsRemoveActionChecked(connectorURI, false));
            }),
          ),
    ),
  );

export const toggleApprovalStatusEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { patchResource }) =>
  action$.pipe(
    ofType<Action, ToggleApprovalStatus['type'], ToggleApprovalStatus>(TOGGLE_APPROVAL_STATUS),
    switchMap(({ payload: { asset } }) => {
      const approvalStatus: Partial<BaseAssetResource> = {
        status_approval: asset.status_approval ? 0 : 1,
      };
      return patchResource(getResourceURI(asset), approvalStatus, undefined, undefined, undefined, {
        Accept:
          getResourceTypeName(asset) === 'collection'
            ? 'application/hal+json; version=3'
            : 'application/hal+json; version=2',
      });
    }),
  );

export default combineEpics<
  Action,
  | ResourceLoadedAction
  | WebhooksLoaded
  | AssetExchangeConnectorsLoaded
  | ExchangeAssets
  | AlertMessage
  | ExchangedAssetsRemoved
  | RemoveExchangedAssets
  | ExchangedAssetsRemoveActionChecked
  | UnauthorizedRequestAction,
  RootState
>(
  fetchCurrentAssetTypeWebhooksEpic,
  fetchAssetExchangeConnectorsEpic,
  exchangeAssetsEpic,
  removeExchangedAssetsEpic,
  checkExchangedAssetsRemoveActionEpic,
  toggleApprovalStatusEpic,
);
