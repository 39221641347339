import { styled } from '@mui/material';
import MuiButton from '@mui/material/Button';

const Button = styled(MuiButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
}));

export default Button;
