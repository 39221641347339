/**
 * This module implements a plugin to initialize/synchronize the editor state with its input data.
 */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import React, { useEffect } from 'react';

import {
  getEndTime,
  getNumericConfidence,
  getNumericDuration,
  getStartTime,
  TranscriptSpeakerData,
  TranscriptUnitData,
} from '../diff';
import { $createTranscriptParagraphNode } from '../nodes/TranscriptParagraphNode';
import { $createTranscriptTimeBlockNode } from '../nodes/TranscriptTimeBlockNode';
import { $createTranscriptUnitNode } from '../nodes/TranscriptUnitNode';

/**
 * Properties for the TranscriptInitializationPlugin
 */
export interface TranscriptInitializationPluginProps {
  /**
   * The units that form the transcripts.
   */
  readonly units: readonly TranscriptUnitData[];
  /**
   * The speakers that form the transcript.
   */
  readonly speakers: readonly TranscriptSpeakerData[];
}

/**
 * A function to associate speakers with their units.
 *
 * The unit and speaker models are only associated by their time stamps: A unit belongs to a speaker
 * if its start time lies inside the timespan of the speaker.
 *
 * This function takes an array of speakers and an array of units and returns records that
 * associate a single speaker with a time-sorted array of its transcript units.
 *
 * @param speakers An array of TranscriptSpeakers
 * @param units An array of TranscriptUnits
 */
const groupBySpeaker = (
  speakers: readonly TranscriptSpeakerData[],
  units: readonly TranscriptUnitData[],
) => {
  const speakerGroups = speakers.map((speaker) => {
    const startTime = getStartTime(speaker);
    const endTime = getEndTime(speaker);
    const unitsForSpeaker = units.filter(
      (unit) => getStartTime(unit).gte(startTime) && getStartTime(unit).lt(endTime),
    );
    unitsForSpeaker.sort((u1, u2) => getStartTime(u1).minus(getStartTime(u2)).toNumber());
    return { speaker, units: unitsForSpeaker, startTime };
  });

  speakerGroups.sort((g1, g2) => g1.startTime.minus(g2.startTime).toNumber());
  return speakerGroups;
};

/**
 * A react hook that initializes the transcript editor.
 *
 * The hook initializes the editor state from an editor instance received via the
 * LexicalComposerContext to a transcript represented as a list of speakers and units.
 *
 * This initialization will rerun and overwrite any editor state whenever either the speaker
 * or the unit array changes.
 * ATTENTION: The arrays are compared by identity and not value equality. Use memoization
 * to ensure the identity of the arrays only changes when the content changes.
 *
 * @param speakers An array of speakers conforming to the backend transcript representation.
 * @param units An array of transcript units conforming to the backend transcript representation.
 */
const useInitialization = ({ speakers, units }: TranscriptInitializationPluginProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => {
      editor.update(() => {
        const root = $getRoot();
        root.clear();

        groupBySpeaker(speakers, units).forEach(({ speaker, units: unitsForSpeaker }) => {
          const unitNodes = unitsForSpeaker.map((u) =>
            $createTranscriptUnitNode(
              u.unit,
              getStartTime(u),
              getNumericDuration(u),
              getNumericConfidence(u),
              u.id,
            ),
          );
          const paragraphNode = $createTranscriptParagraphNode();
          paragraphNode.append(...unitNodes);
          const timeBlockNode = $createTranscriptTimeBlockNode(
            speaker.name,
            getStartTime(speaker),
            getNumericDuration(speaker),
            speaker.id,
          );
          timeBlockNode.append(paragraphNode);
          root.append(timeBlockNode);
        });
      });
    },
    // units and speakers must be memoized to prevent unwanted reinitialization!
    [units, speakers, editor],
  );
};

/**
 * A Plugin to initialize the editor state to a transcript.
 *
 * The editor is reinitialized whenever the transcript chagnes (by identity). This will overwrite
 * any edit done on the old editor state.
 *
 * The transcript representation corresponds to the backend transcript representation.
 *
 * @param props Arrays of speakers and units representing a transcript.
 * @constructor
 */
export const TranscriptInitializationPlugin: React.FC<TranscriptInitializationPluginProps> = (
  props,
) => {
  useInitialization(props);

  return null;
};

export default TranscriptInitializationPlugin;
