import {
  getResourceTypeName,
  RelatedCollectionResourceMap,
  Resource,
  ResourceModuleState,
  ResourceOptionsV3,
} from 'medialoopster/rest';

import { Asset } from '../asset/types';

//---------------
// Resource Types
//---------------
export interface AssetCollection extends Asset<'collection'> {
  readonly bundle_root: string;
  readonly rel_bundle_root: string;
  readonly video_asset_count: number;
  readonly image_asset_count: number;
  readonly audio_asset_count: number;
}

export type CollectionRootState = ResourceModuleState<AssetCollection, ResourceOptionsV3> & {
  readonly videoassets: RelatedCollectionResourceMap<AssetCollection, 'videoassets'>;
  readonly imageassets: RelatedCollectionResourceMap<AssetCollection, 'imageassets'>;
  readonly audioassets: RelatedCollectionResourceMap<AssetCollection, 'audioassets'>;
};

export const isAssetCollection = (
  asset: Resource<string> | undefined | null,
): asset is AssetCollection => !!asset && getResourceTypeName(asset) === 'collection';
