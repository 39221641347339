import Button, { ButtonProps } from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { FC, MouseEvent, ReactNode } from 'react';

import LinkButton from '../../buttons/LinkButton';

const useStyles = makeStyles(() => ({
  root: {
    marginRight: 'auto',
  },
}));

type Props = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label: string;
  color?: ButtonProps['color'];
  title?: string;
  disabled?: boolean;
  icon?: ReactNode;
  href?: string;
};

const ModalActionButton: FC<Props> = ({
  label,
  color = 'primary',
  title = '',
  disabled = false,
  icon = undefined,
  onClick = undefined,
  href = undefined,
}: Props) => {
  const classes = useStyles();
  return href ? (
    <LinkButton
      className={classes.root}
      variant="contained"
      color={color}
      title={title}
      disabled={disabled}
      icon={icon}
      href={href}
    >
      {label}
    </LinkButton>
  ) : (
    <Button
      className={classes.root}
      variant="contained"
      color={color}
      disableElevation
      title={title}
      disabled={disabled}
      startIcon={icon}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default ModalActionButton;
