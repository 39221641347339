import { FC } from 'react';

import { formatFramesAsTimecode } from 'medialoopster/formatTimecode';

import Text from './Text';

type Props = {
  readonly value: number;
  readonly fps: string;
};

const Duration: FC<Props> = ({ value, fps }: Props) => (
  <Text value={formatFramesAsTimecode(value, fps)} />
);

export default Duration;
