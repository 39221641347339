import { useObservable, useObservableState } from 'observable-hooks';
import { EMPTY, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';

import { gettext } from 'medialoopster/Internationalization';
import { getTokenAuthHeader, loginTypes } from 'medialoopster/state/login';

export interface URLState {
  readonly tooltip: string;
  readonly disabled: boolean;
}
export interface StatusMessages {
  [status: number]: string;
  default: string;
}

const useStatusCodePermission = (
  checkURL: string | null,
  statusMessages: StatusMessages,
  token: loginTypes.Token,
): URLState => {
  return useObservableState(
    useObservable(
      (input$) =>
        input$.pipe(
          switchMap(([url]) => {
            if (!url) {
              return EMPTY;
            }
            return ajax({
              url,
              method: 'HEAD',
              headers: getTokenAuthHeader(token),
            }).pipe(
              map((ajaxResponse) => {
                const response = { tooltip: statusMessages.default, disabled: false };
                if (ajaxResponse.status in statusMessages) {
                  response.tooltip = statusMessages[ajaxResponse.status];
                }
                return response;
              }),
              catchError((err) => {
                const response = { tooltip: statusMessages.default, disabled: true };
                if (err.status in statusMessages) {
                  response.tooltip = statusMessages[err.status];
                }
                return of(response);
              }),
            );
          }),
        ),
      [checkURL],
    ),
    {
      tooltip: gettext('Checking availability...'),
      disabled: true,
    },
  );
};
export default useStatusCodePermission;
