import { TypographyProps } from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { FC, ReactNode } from 'react';

import InplaceDateEdit from '../../../ui/components/InplaceEdits/InplaceDateEdit';

type Props = {
  readonly value: string | null;
  readonly canEdit?: boolean;
  readonly variant?: TypographyProps['variant'];
  readonly minDate?: DateTime;
  readonly onSubmit?: (value: string | null) => void;
};

const Year: FC<Props> = ({
  value,
  canEdit = false,
  variant = 'body2',
  minDate = DateTime.fromISO('1900-01-01'),
  onSubmit,
}: Props) => {
  const dateFormat = 'yyyy';
  let textComponents: ReactNode = value ?? '';
  if (value) {
    textComponents = <>{DateTime.fromISO(value).toFormat(dateFormat)}</>;
  }
  const editValue = value ?? '';
  return (
    <InplaceDateEdit
      textComponents={<span>{textComponents}</span>}
      onSubmit={onSubmit}
      editValue={editValue}
      canEdit={canEdit}
      variant={variant}
      dense
      component="p"
      minWidth="250px"
      dateFormat={dateFormat}
      dateViews={['year']}
      minDate={minDate}
    />
  );
};

export default Year;
