import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useRef, useLayoutEffect, memo } from 'react';

import {
  isAudioAsset,
  isImageAsset,
  isVideoAsset,
} from '../../../businessRules/models/asset/utils';
import { collectionSearchResultTypes } from '../../../state/modules/rest/collectionsearchresult';
import { SearchAssetCollectionResult } from '../../../state/modules/rest/collectionsearchresult/types';
import { Asset } from '../../../state/types/asset/unionTypes';
import AssetCollection from './AssetCollection';
import AudioAsset from './AudioAsset';
import ImageAsset from './ImageAsset';
import VideoAsset from './VideoAsset';

interface AssetEntryProps {
  readonly asset: Asset | SearchAssetCollectionResult;
  readonly isWide: boolean;
  readonly isCurrent: boolean;
  readonly inSelection: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '70px',
    minWidth: '328px',
  },
  containerDecreasedOpacity: { opacity: 0.5 },
  listEntry: {
    marginBottom: '5px',
    marginRight: 1,
  },
}));

const AssetEntry: FC<AssetEntryProps> = ({
  asset,
  isWide,
  isCurrent,
  inSelection,
}: AssetEntryProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  useLayoutEffect(() => {
    if (isCurrent) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }, [ref, isCurrent]);

  return (
    <div className={classes.listEntry} id={`search-asset-id-${asset.id}`}>
      <Paper
        ref={ref}
        className={clsx(classes.container, {
          [classes.containerDecreasedOpacity]:
            !asset.is_production || ('is_available' in asset && !asset.is_available),
        })}
        role="presentation"
        elevation={inSelection ? 4 : 1}
      >
        {isAudioAsset(asset) && (
          <AudioAsset isWide={isWide} asset={asset} current={isCurrent} inSelection={inSelection} />
        )}
        {isVideoAsset(asset) && (
          <VideoAsset isWide={isWide} asset={asset} current={isCurrent} inSelection={inSelection} />
        )}
        {isImageAsset(asset) && (
          <ImageAsset isWide={isWide} asset={asset} current={isCurrent} inSelection={inSelection} />
        )}
        {collectionSearchResultTypes.isAssetSearchCollection(asset) && (
          <AssetCollection
            isWide={isWide}
            asset={asset}
            current={isCurrent}
            inSelection={inSelection}
          />
        )}
      </Paper>
    </div>
  );
};
export default memo(AssetEntry);
