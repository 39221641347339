import * as addAssetsToCollectionActions from './actions';
import addAssetsToCollectionEpics from './epics';
import reducer from './reducers';
import * as addAssetsToCollectionSelectors from './selectors';
import * as addAssetsToCollectionTypes from './types';

export {
  addAssetsToCollectionActions,
  addAssetsToCollectionTypes,
  addAssetsToCollectionSelectors,
  addAssetsToCollectionEpics,
};
export default reducer;
