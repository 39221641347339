import { LinkedItemsPageResource, ResourceMap, ResourceOptionsV2 } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { AudioAsset } from './types';

export const getAudioAssets = (state: RootState): ResourceMap<AudioAsset, ResourceOptionsV2> =>
  state.audio.assets;
export const getAudioProjectsCollections = (
  state: RootState,
): ResourceMap<LinkedItemsPageResource<'audioasset-projects'>> => state.audio.projectsCollections;
