import { CircularProgress } from '@mui/material';
import Popper from '@mui/material/Popper';
import MUITable from '@mui/material/Table';
import MUITableContainer from '@mui/material/TableContainer';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Property } from 'csstype';
import { FC, useState, useRef, useEffect, memo, ReactNode } from 'react';

import { pgettext } from 'medialoopster/Internationalization';

import TableBody from './TableBody';
import TableHead from './TableHead';
import { HeadCell, RowData } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  highlight: {
    color: theme.palette.info.dark,
  },
  tableTitle: {
    padding: theme.spacing(2),
  },
  paginationSpacer: {
    flex: 'unset',
  },
  paginationCaption: {
    display: 'contents',
  },
  paginationCaptionOverride: {
    // paginationCaption is used for multiple components in pagination -> manual override for different instances
    whiteSpace: 'nowrap',
  },
  paginationSelect: {
    marginRight: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
  loading: {
    opacity: '0.4',
  },
  progressIcon: {
    zIndex: theme.zIndex.tooltip,
  },
}));

interface TableContainerProps {
  readonly getRowIdentifier: (dataRow: RowData) => string;
  readonly headCells: ReadonlyArray<HeadCell>;
  readonly dense?: boolean;
  readonly height: Property.Height;
  readonly loading?: boolean;
  readonly checkboxes?: boolean;
  readonly renderRow: (dataRow: RowData, rowIdentifier: string) => ReactNode;
}

const TableContainer: FC<TableContainerProps> = ({
  getRowIdentifier,
  headCells,
  dense = false,
  height,
  loading = false,
  checkboxes = false,
  renderRow,
}: TableContainerProps) => {
  const classes = useStyles();

  // Visual loading feedback
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const tableContainerRef = useRef<HTMLTableElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (loading && tableContainerRef) {
      setAnchorEl(tableContainerRef.current);
    } else {
      setAnchorEl(null);
    }
  }, [loading]);

  return (
    <MUITableContainer
      ref={tableContainerRef}
      className={clsx({
        [classes.loading]: loading,
      })}
      style={{ height }}
    >
      <MUITable
        size={dense ? 'small' : 'medium'}
        aria-label={
          checkboxes
            ? pgettext('As in list of facts/numbers', 'Table with checkboxes')
            : pgettext('As in list of facts/numbers', 'Table')
        }
        stickyHeader
      >
        {headCells.length > 0 && <TableHead headCells={headCells} checkboxes={checkboxes} />}
        <TableBody getRowIdentifier={getRowIdentifier} renderRow={renderRow} />
      </MUITable>
      <Popper
        open={open}
        className={classes.progressIcon}
        placement="top"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: '0, -50%',
            },
          },
        ]}
        id={open ? 'loading' : undefined}
        anchorEl={anchorEl}
      >
        <CircularProgress />
      </Popper>
    </MUITableContainer>
  );
};

export default memo(TableContainer);
