import { Link } from '@mui/material';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';

import { detailsActions } from '../../../state/modules/details';
import AncestorLinkDetails from './AncestorLinkDetails';

export interface AncestorLinkProps {
  href: string;
  label: string;
}

/*
 * A link to a shot ancestor which loads the ancestor shot and it's asset in edit mode.
 *
 */
const AncestorLink: FC<AncestorLinkProps> = ({ href, label }: AncestorLinkProps) => {
  const dispatch = useDispatch();
  const onAncestorClick = () => {
    dispatch(detailsActions.editAncestor(href));
  };
  return (
    <div>
      <Link underline="hover" onClick={onAncestorClick}>
        {gettext('Set')}&nbsp;{label}&nbsp;
      </Link>
      <AncestorLinkDetails ancestorHref={href} />
    </div>
  );
};

export default AncestorLink;
