import { combineReducers } from 'redux';

import { createResourcesReducer, createRootLinkReducer, mlRel } from 'medialoopster/rest';

import {
  CurrentAssetState,
  DetailsRootState,
  EmbeddedAssetState,
  LOAD_ANCESTOR_ERROR,
  LoadAncestorError,
  LoadAncestorErrorState,
  SELECT_SHOT_RANGE_END,
  SELECT_SHOT_RANGE_START,
  SelectShotRangeEnd,
  SelectShotRangeStart,
  SET_CURRENT_ASSET,
  SET_EMBEDDED_ASSET,
  SET_TRANSCRIPT_AVAILABILITY,
  SetCurrentAsset,
  SetEmbeddedAsset,
  SetTranscriptAvailabilityAction,
  ShotRangeState,
  SHOW_ALL_ANNOTATIONS,
  ShowAllAnnotationsAction,
  TOGGLE_ANNOTATIONS,
  TOGGLE_EDITING_ANNOTATION,
  TOGGLE_VIEW,
  ToggleAnnotations,
  ToggleEditingAnnotation,
  ToggleView,
  CurrentTranscriptState,
  UNLOCK_CURRENT_ASSET,
  UnlockCurrentAsset,
  ViewLayoutState,
} from './types';

export const initialCurrentAssetState: CurrentAssetState = {
  type: null,
  href: null,
};

export const currentAssetReducer = (
  state = initialCurrentAssetState,
  { type, payload }: SetCurrentAsset,
): CurrentAssetState => {
  switch (type) {
    case SET_CURRENT_ASSET:
      localStorage.setItem('currentAssetState', JSON.stringify(payload));
      return payload;
    default:
      return state;
  }
};

export const initialViewLayoutState: ViewLayoutState = { view: 'search', sidebarType: 'default' };

export const viewLayoutReducer = (
  state = initialViewLayoutState,
  { type, payload }: ToggleView,
): ViewLayoutState => {
  switch (type) {
    case TOGGLE_VIEW:
      localStorage.setItem('layout', JSON.stringify(payload));
      return payload;
    default:
      return state;
  }
};

export const initialEmbeddedAssetState: EmbeddedAssetState = {
  type: null,
  href: null,
};

export const embeddedAssetReducer = (
  state = initialEmbeddedAssetState,
  { type, payload }: SetEmbeddedAsset | SetCurrentAsset,
): EmbeddedAssetState => {
  switch (type) {
    case SET_EMBEDDED_ASSET:
      localStorage.setItem('embeddedAssetState', JSON.stringify(payload));
      return payload;
    case SET_CURRENT_ASSET: {
      const embeddedAssetState = { type: payload.type, href: payload.href };
      localStorage.setItem('embeddedAssetState', JSON.stringify(embeddedAssetState));
      return embeddedAssetState;
    }
    default:
      return state;
  }
};

export const selectedShotRangeReducer = (
  state: ShotRangeState | null = null,
  action: SelectShotRangeStart | SelectShotRangeEnd | UnlockCurrentAsset | SetCurrentAsset,
): ShotRangeState | null => {
  switch (action.type) {
    case SELECT_SHOT_RANGE_START:
      return { start: action.payload.shot.timecode_start, end: action.payload.shot.timecode_end };
    case SELECT_SHOT_RANGE_END:
      if (state === null) {
        return state;
      }
      return {
        start: Math.min(state.start, action.payload.shot.timecode_start),
        end: Math.max(state.end, action.payload.shot.timecode_end),
      };
    case UNLOCK_CURRENT_ASSET:
      return null;
    case SET_CURRENT_ASSET:
      return null;
    default:
      return state;
  }
};

export const loadAncestorErrorReducer = (
  state: LoadAncestorErrorState = {},
  { type, payload }: LoadAncestorError,
): LoadAncestorErrorState => {
  switch (type) {
    case LOAD_ANCESTOR_ERROR:
      return { ...state, [payload.ancestorHref]: payload.error };
    default:
      return state;
  }
};

// region annotations

export const showAnnotationsReducer = (state = false, action: ToggleAnnotations): boolean => {
  switch (action.type) {
    case TOGGLE_ANNOTATIONS: {
      const nextState = action.payload.on ?? !state;
      localStorage.setItem('mlShowAnnotations', JSON.stringify(nextState));
      return nextState;
    }
    default:
      return JSON.parse(localStorage.getItem('mlShowAnnotations') ?? 'false');
  }
};

export const showAllAnnotationsReducer = (
  state = true,
  action: ShowAllAnnotationsAction,
): boolean => {
  switch (action.type) {
    case SHOW_ALL_ANNOTATIONS:
      return action.payload.showAll;
    default:
      return state;
  }
};

export const toggleEditingAnnotationReducer = (
  state: string | null = null,
  action: ToggleEditingAnnotation,
): string | null => {
  switch (action.type) {
    case TOGGLE_EDITING_ANNOTATION: {
      if (state === action.payload.annotationURI) return null;

      return action.payload.annotationURI;
    }
    default:
      return state;
  }
};

// endregion annotations

// region transcript

export const initialCurrentTranscriptState: CurrentTranscriptState = {
  availability: 'loading',
};

export const currentTranscriptReducer = (
  state: CurrentTranscriptState = initialCurrentTranscriptState,
  action: SetTranscriptAvailabilityAction,
): CurrentTranscriptState => {
  switch (action.type) {
    case SET_TRANSCRIPT_AVAILABILITY:
      return { ...state, availability: action.payload.availability };
    default:
      return state;
  }
};

// endregion transcript

export default combineReducers<DetailsRootState>({
  currentAsset: currentAssetReducer,
  embeddedAsset: embeddedAssetReducer,
  licenseCollectionLink: createRootLinkReducer(mlRel('license')),
  licenseCollections: createResourcesReducer('license-collection'),
  licenses: createResourcesReducer('license'),
  licensorCollectionLink: createRootLinkReducer(mlRel('licensor')),
  licensorCollections: createResourcesReducer('licensor-collection'),
  licensors: createResourcesReducer('licensor'),
  customMetadataSetCollectionLink: createRootLinkReducer(mlRel('custommetadatasets')),
  customMetadataSetCollections: createResourcesReducer('custommetadataset-collection'),
  customMetadataSets: createResourcesReducer('custommetadataset'),
  customMetadata: createResourcesReducer('custommetadata'),
  customMetadataChoiceCollectionLink: createRootLinkReducer(mlRel('custommetadatachoice')),
  customMetadataChoiceCollections: createResourcesReducer('custommetadatachoice-collection'),
  customMetadataChoices: createResourcesReducer('custommetadatachoice'),
  layout: viewLayoutReducer,
  selectedShotRange: selectedShotRangeReducer,
  validationrulesCollectionLink: createRootLinkReducer(mlRel('validationrules')),
  loadAncestorErrors: loadAncestorErrorReducer,
  validationrulesCollections: createResourcesReducer('validationrule-collection'),
  validationrules: createResourcesReducer('validationrule'),
  showAnnotations: showAnnotationsReducer,
  showAllAnnotations: showAllAnnotationsReducer,
  editingAnnotation: toggleEditingAnnotationReducer,
  currentTranscript: currentTranscriptReducer,
});
