import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { MenuItem } from 'medialoopster/components';
import { AppConnector as AppConnectorIcon } from 'medialoopster/icons';
import { getLinkHref } from 'medialoopster/rest';

import { appConnectorsSelectors } from '../../../state/modules/appConnectors';
import { AppConnector } from '../../../state/modules/appConnectors/types';

const useAppConnectorMenuEntries = (): ReadonlyArray<ReactNode> => {
  const appConnectors = useSelector(appConnectorsSelectors.getAppConnectorCollection);

  return appConnectors.items.map((connector: AppConnector) => {
    const url = getLinkHref(connector, 'target');
    return (
      url && (
        <MenuItem
          key={`app-connector-${connector.id}`}
          label={connector.display_name}
          href={url}
          tooltip={url}
          icon={<AppConnectorIcon fontSize="small" />}
        />
      )
    );
  });
};

export default useAppConnectorMenuEntries;
