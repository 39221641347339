import { amber, grey } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  export interface TypeBackground {
    secondary: string;
  }

  export interface PaletteOptions {
    border?: string;

    // Custom palette color used as default color for buttons.
    // See https://mui.com/material-ui/customization/palette/#custom-colors
    // and https://github.com/mui/material-ui/issues/27468.
    buttonDefault?: Palette['primary'];
    white?: PaletteOptions['primary'];
  }

  export interface Palette {
    border: string;
  }
}
// Override to use `white` for the `color` property in buttons and icon buttons,
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

// Override to use `buttonDefault` for the `color` property in buttons and icon buttons,
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buttonDefault: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    buttonDefault: true;
  }
}

/*
dark mode palette
{
  secondary: {
    main: amber.A200, // TODO: decide final secondary color
  },
  background: {
    secondary: '#303030',
  },
  border: 'rgba(255, 255, 255, 0.23)',
  type: 'dark',
};
*/
// for the default values see: https://material-ui.com/customization/default-theme/
const medialoopsterTheme = createTheme({
  spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
  palette: {
    primary: {
      main: '#0099DF',
      light: '#44C1F1',
      dark: '#0075B8',
    },
    secondary: {
      // main: '#9c27b0', Mui5
      main: '#F03C00',
      light: '#FF6837',
      dark: '#CA2200',
    },
    background: {
      secondary: '#f5f5f5',
    },
    white: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#000000',
    },
    border: 'rgba(0, 0, 0, 0.23)',
    // Default button color (grey).
    // See also the component overrides below for `MuiButton` and `MuiIconButton`.
    buttonDefault: {
      light: grey[400],
      main: grey[700],
      dark: grey[800],
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'body, html, #app': {
          lineHeight: 'normal',
        },
        body: {
          // Mui5: body font size has changed from theme.typography.body2 to theme.typography.body1
          fontSize: '0.875rem',
        },
        mark: {
          backgroundColor: amber.A400, // palette.secondary, TODO: some opacity?
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
          },
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // All buttons are medium-sized by default.
        size: 'medium',
        // Use the default button color (grey) for all buttons.
        color: 'buttonDefault',
      },
    },
    MuiIconButton: {
      defaultProps: {
        // Use the default button color (grey) for all buttons.
        color: 'buttonDefault',
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '& option:disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    h3: {
      fontSize: '1.71rem',
    },
    h4: {
      fontSize: '1.29rem',
    },
    button: {
      textTransform: 'none', // MUI buttons have default 'uppercase'
    },
  },
});

export default medialoopsterTheme;
