import { combineReducers } from 'redux';

import {
  createResourcesReducer,
  createRootLinkReducer,
  mlRel,
  ReceiveRootResource,
  RECEIVE_ROOT_RESOURCE,
} from 'medialoopster/rest';

import { MainRootState } from './types';

export const versionReducer = (state = '', action: ReceiveRootResource): string => {
  switch (action.type) {
    case RECEIVE_ROOT_RESOURCE:
      return action.payload.root.medialoopster_version;
    default:
      return state;
  }
};

export default combineReducers<MainRootState>({
  monitoring: createRootLinkReducer(mlRel('monitoring')),
  management: createRootLinkReducer(mlRel('management')),
  licenseManagement: createRootLinkReducer(mlRel('license-management')),
  admin: createRootLinkReducer(mlRel('admin')),
  version: versionReducer,
  currentSite: createRootLinkReducer(mlRel('current-site')),
  sites: createResourcesReducer('site'),
});
