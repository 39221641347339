import { HttpAuthorization, Token } from './types';

export const getTokenAuthHeader = (token: Token): { Authorization?: HttpAuthorization } =>
  token !== undefined
    ? {
        Authorization: `Token ${token}`,
      }
    : {};

export default getTokenAuthHeader;
