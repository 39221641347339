import { Grid, Typography } from '@mui/material';
import { useObservableEagerState } from 'observable-hooks';
import { memo, FC } from 'react';

import { useVideoController } from 'medialoopster/components';
import { formatFramesAsTimecode } from 'medialoopster/formatTimecode';

type Props = {
  readonly offsetFrames: number;
  readonly fps?: number | null;
  readonly inFrame: number | null;
  readonly outFrame: number | null;
};

const CurrentTimeDisplay: FC<Props> = ({ offsetFrames, inFrame, outFrame }: Props) => {
  const videoController = useVideoController();

  const currentFrame = useObservableEagerState(videoController.frame$);
  const fps = useObservableEagerState(videoController.fps$);

  const currentTimecode = `${formatFramesAsTimecode(currentFrame + offsetFrames, fps.toFixed(1))}`;

  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body2" data-testid="video-player-timecode">
          {currentTimecode}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={6}
        justifyContent="flex-end"
        data-testid="video-player-timerangecode"
      >
        {formatFramesAsTimecode(inFrame !== null ? inFrame + offsetFrames : 0, fps.toString())} –{' '}
        {formatFramesAsTimecode(outFrame !== null ? outFrame + offsetFrames : 0, fps.toString())}
      </Grid>
    </>
  );
};

export default memo(CurrentTimeDisplay);
