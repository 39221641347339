import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode, FC } from 'react';
import { useDispatch } from 'react-redux';

import { gettext, interpolate } from 'medialoopster/Internationalization';
import medialoopsterTheme from 'medialoopster/medialoopsterTheme';

import { Logout } from '../icons';
import { loginActions } from '../state/login';
import IconButton from './buttons/IconButton';

const useStyles = makeStyles((theme) => ({
  navigationGrid: {
    flexWrap: 'nowrap',
    padding: theme.spacing(0, 4, 0, 4),
    minHeight: '60px',
  },
  pagesContainer: {
    // For nav scrolling
    minWidth: '100px',
    paddingLeft: theme.spacing(6),
  },
  homeItem: {
    paddingBottom: '0.8rem',
    flexGrow: 1,
  },
  logOut: {
    padding: '1px',
  },
}));

interface BasicHeaderProps {
  username: string;
  appName: string;
  children?: ReactNode;
  darkMode?: boolean;
}

const BasicHeader: FC<BasicHeaderProps> = ({
  children = <></>,
  username,
  darkMode = false,
  appName,
}: BasicHeaderProps) => {
  const classes = useStyles();
  const isMdDown = useMediaQuery<DefaultTheme>((theme) => theme.breakpoints.down('md'));
  const theme = createTheme({
    ...medialoopsterTheme,
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });
  const dataTestId = `BasicHeaderTheme-${theme.palette.mode}`;
  const dispatch = useDispatch();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppBar
          id="header"
          position="static"
          elevation={1}
          color="inherit"
          role="navigation"
          data-testid={dataTestId}
        >
          <Grid container alignItems="flex-end" className={classes.navigationGrid}>
            <Grid item>
              <Typography color="textSecondary" component="span" variant="h5">
                <Box fontWeight="fontWeightMedium" paddingBottom="0.65rem">
                  {appName}
                </Box>
              </Typography>
            </Grid>
            {!isMdDown && (
              <Grid item className={classes.pagesContainer}>
                {children}
              </Grid>
            )}
            <Grid item className={classes.homeItem} id="home_item">
              <Typography variant="body2" color="textSecondary" component="span">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  whiteSpace="nowrap"
                  fontWeight="fontWeightMedium"
                  padding="1px"
                >
                  {gettext('Back to')}&nbsp;
                  <Link
                    href="/"
                    color={darkMode ? 'textPrimary' : 'primary'}
                    aria-label={`${gettext('Back to')} medialoopster`}
                    underline="none"
                  >
                    medialoopster
                  </Link>
                  <span>
                    &nbsp;&nbsp;
                    {interpolate(gettext('Signed in as %(loggedInUser)s'), {
                      loggedInUser: username,
                    })}
                    &nbsp;&nbsp;
                  </span>
                  <IconButton
                    title={gettext('Log out')}
                    aria-label={gettext('Log out')}
                    className={classes.logOut}
                    onClick={() => dispatch(loginActions.logout())}
                  >
                    <Logout />
                  </IconButton>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          {isMdDown && <>{children}</>}
        </AppBar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default BasicHeader;
