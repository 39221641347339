import { Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { pgettext } from '../Internationalization';
import Modal from './Modal';

type Props = {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly title: string;
  readonly message: string;
  readonly label: string;
  readonly icon: ReactNode;
};

const ConfirmationModal: FC<Props> = ({
  title,
  message,
  label,
  icon,
  open,
  onCancel,
  onConfirm,
}) => (
  <Modal open={open} onClose={onCancel}>
    <Modal.Header headerTitle={title} onClose={onCancel} />
    <Modal.Body>
      <Typography>{message}</Typography>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Footer.CancelButton
        title={pgettext('Form cancel button title', 'Cancel this form')}
        label={pgettext('Form cancel button title', 'Cancel')}
        onClick={onCancel}
      />
      <Modal.Footer.SubmitButton
        onClick={onConfirm}
        label={label}
        title={title}
        color="error"
        icon={icon}
      />
    </Modal.Footer>
  </Modal>
);
export default ConfirmationModal;
