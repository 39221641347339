/**
 * This module contains constants describing the css class names as well as the editor styling.
 *
 * This module contains constants for the class names used to tag the dom elements of the
 * custom lexical node types used by the editor. The class names follow the
 * [BEM](https://getbem.com/) schema for css class names: `block__element--modifier`.
 * `transcript` is chosen for the block name, the node type is chosen for the element name
 * and modifier depends on the domain logic of the node type.
 *
 * The module also contains the default styling of the editor nodes in EDITOR_STYLING.
 */
export const EDITOR_CLASS_NAME = 'transcript__editor';
export const METADATA_CLASS_NAME = 'transcript__metadata';
export const PARAGRAPH_CLASS_NAME = 'transcript__paragraph';
export const TIME_BLOCK_CLASS_NAME = 'transcript__time-block';
export const UNIT_CLASS_NAME = 'transcript__unit';
export const UNIT_PAST_CLASS_NAME = 'transcript__unit--past';
export const UNIT_CURRENT_CLASS_NAME = 'transcript__unit--current';
export const UNIT_FUTURE_CLASS_NAME = 'transcript__unit--future';

/**
 * The default editor styling.
 *
 * Note: the layout uses a vertical flexbox of speaker blocks at the moment. If this scheme should prove problematic
 * in the future, then CSS-Grid could be tried instead. It should be possible to limit the changes to this file if
 * time-block nodes use `display: content`. <KH 2024-08-28 t:ML-3549>
 */
export const EDITOR_STYLING = {
  '.transcript__editor': {
    outline: 'none',
  },
  '.transcript__unit': {
    color: '#00000099',
    fontSize: 14,
    whiteSpace: 'normal',
  },
  '.transcript__unit:last-child': {
    // The last unit needs to preserve whitespace or else it would not be possible to add whitespace at the end
    // of the last unit of a paragraph.
    whiteSpace: 'pre-wrap',
  },
  '.transcript__unit.transcript__unit--past': {
    color: '#000000DE',
  },
  '.transcript__unit.transcript__unit--current': {
    backgroundColor: '#1976d2', // medialoopster primary color
    color: '#FFF', // foreground color set to white to be visible in front of the highlight.
    borderRadius: '0.25em',
    // move the highlighting as good as possible respecting the leading space added to the units.
    padding: '0.2em 0.2em 0 0',
    margin: '-0.2em -0.2em 0 0',
  },
  '.transcript__time-block': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginBottom: '10px',
  },
  '.transcript__paragraph': {
    flexGrow: 1,
    margin: '0.5em',
    width: '67%',
  },
  '.transcript__metadata': {
    padding: '0.5em',
    paddingLeft: '20px',
    flexShrink: '0',
    width: '33%',
  },
} as const;
