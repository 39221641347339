import { SelectChangeEvent } from '@mui/material';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { ReactNode, forwardRef } from 'react';

import { Choice } from './types';

type Props = {
  id?: string;
  name: string;
  onChange?: (val: string[]) => void;
  label?: string;
  choices?: ReadonlyArray<Choice>;
  choiceDisplay: keyof Choice;
  choiceValue: keyof Choice;
  readOnly?: boolean;
  showError?: boolean;
  errorMsg?: ReactNode;
  value?: ReadonlyArray<number | string>;
};

const useStyles = makeStyles(() => ({
  select: {
    minHeight: 300,
  },
}));

const MultipleChoiceSelect = forwardRef<HTMLSelectElement, Props>(
  (
    {
      id,
      name,
      label = '',
      choices = [],
      choiceDisplay,
      choiceValue,
      readOnly = false,
      showError = false,
      errorMsg = '',
      onChange = () => {},
      value,
    }: Props,
    ref,
  ) => {
    const hasError = showError && !_.isEmpty(errorMsg);
    const cssClasses = useStyles();
    const onChanges = (event: SelectChangeEvent<unknown>) => {
      const { options } = event.target as HTMLSelectElement;
      onChange(Array.from(options).flatMap((opt) => (opt.selected ? [opt.value] : [])));
    };
    return (
      <TextField
        id={id}
        name={name}
        label={label}
        error={hasError}
        helperText={hasError ? errorMsg : null}
        /* Uncontrolled props */
        value={value}
        /* ref props (i.e. react-form-hook) */
        inputRef={ref}
        /* styling props */
        variant="outlined"
        size="small"
        select
        SelectProps={{
          native: true,
          multiple: true,
          classes: { select: cssClasses.select },
          onChange: onChanges,
        }}
        InputLabelProps={{ shrink: true }}
        FormHelperTextProps={{ role: 'alert' }}
      >
        {choices.map((choice) => (
          <option disabled={readOnly} key={choice[choiceValue]} value={choice[choiceValue]}>
            {choice[choiceDisplay]}
          </option>
        ))}
      </TextField>
    );
  },
);

export default MultipleChoiceSelect;
