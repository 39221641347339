import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { unauthorizedRequestAction } from 'medialoopster/rest';
import { loginSelectors } from 'medialoopster/state/login';

import { AssetChoiceWithError } from '../../../../../state/modules/operations/types';
import PrepareDownloadModal from './PrepareDownloadModal';
import useBulkDownload from './useBulkDownload';

interface Props {
  readonly id: string;
  readonly assetChoices: ReadonlyArray<AssetChoiceWithError>;
  readonly downloadActivitiesURL: string;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly headerTitle: string;
  readonly submitButtonTitle: string;
  readonly collectionChoice?: AssetChoiceWithError | null;
}

const BulkDownloadModal: FC<Props> = ({
  id,
  assetChoices,
  downloadActivitiesURL,
  open,
  onClose,
  headerTitle,
  submitButtonTitle,
  collectionChoice,
}: Props) => {
  const dispatch = useDispatch();

  const allChoices = useMemo(
    () => (collectionChoice ? [collectionChoice].concat(assetChoices) : assetChoices),
    [collectionChoice, assetChoices],
  );

  const token = useSelector(loginSelectors.getToken);

  const {
    downloadHref,
    isFinished,
    hasError,
    assetErrors,
    monitoringHref,
    downloadError,
    isAuthorized,
  } = useBulkDownload(token, downloadActivitiesURL, open, allChoices);

  useEffect(() => {
    if (isAuthorized === false) {
      dispatch(unauthorizedRequestAction());
    }
  }, [dispatch, isAuthorized]);

  const assetRows = useMemo(() => {
    if (!open) return [];
    const errAssets = assetErrors
      .filter(({ error }) => error)
      .map(({ name, error, ...assetChoice }) => ({
        ...assetChoice,
        display: name,
        error: error || '',
      }));
    const errUrls = errAssets.map(({ url }) => url);
    const selectedRows = allChoices
      .filter(({ url }) => !errUrls.includes(url))
      .map(({ name, ...assetChoice }) => ({
        ...assetChoice,
        display: name,
        defaultChecked: !downloadError,
        error: '',
        disabled: true,
      }));
    return [...errAssets, ...selectedRows];
  }, [assetErrors, allChoices, open, downloadError]);

  const helpText = useMemo(() => {
    if (hasError) {
      return downloadError ?? gettext('No file is allowed to be downloaded.');
    }
    if (isFinished) {
      return assetErrors.length
        ? gettext('Download ready. Some files are not allowed to be downloaded.')
        : gettext('Download ready.');
    }
    return gettext('Preparing download...');
  }, [hasError, downloadError, isFinished, assetErrors]);

  return (
    <PrepareDownloadModal
      id={id}
      hasError={hasError}
      isFinished={isFinished}
      headerTitle={headerTitle}
      onClose={onClose}
      open={open}
      assetErrors={assetErrors}
      submitButtonTitle={submitButtonTitle}
      downloadHref={downloadHref}
      helpText={helpText}
      assetRows={assetRows}
      monitoringHref={monitoringHref}
    />
  );
};

export default BulkDownloadModal;
