/**
 * The module implements the React modal to change speaker names.
 */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import TextField from '@mui/material/TextField';
import { NodeKey } from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';

import { pgettext } from 'medialoopster/Internationalization';
import { Modal } from 'medialoopster/components';

import { CHANGE_ALL_SPEAKER_OCCURRENCES, CHANGE_ONE_SPEAKER_OCCURRENCE } from '../commands';

/**
 * The props of the ChangeSpeakerModal component.
 */
export interface ChangeSpeakerModalProps {
  /**
   * Callback, that is notified when the modal is closed.
   */
  readonly onClose: () => void;
  /**
   * The name of the speaker this modal is opened on.
   */
  readonly speakerName: string;
  /**
   * The NodeKey of the TranscriptTimeBlock that corresponds to the speaker the modal refers to.
   */
  readonly timeBlockNodeKey: NodeKey;
}

/** Helper function to focus and select the Speaker in the TextField
 *
 * To be used as `inputRef` on the TextField.
 *
 * @param node The Html input element, that underlies the TextField.
 */
const focusAndSelect = (node: HTMLInputElement | null) => {
  if (node) {
    node.focus();
    node.select();
  }
  return () => {};
};

/**
 * A MUI modal component that allows the user to change the name of one or more speakers.
 *
 * The modal is opened with the current name of the speaker and the NodeKey of the
 * TranscriptTimeBlockNode that corresponds with the speaker. The user may enter a new name and
 * choose to change just the TranscriptTimeBlockNode referred to by `timeBlockNodeKey` or all
 * TranscriptTimeBlockNodes with speakers of the same name.
 *
 * @param onClose A callback called when the modal is closed by the user.
 * @param speakerName The current name of the speaker.
 * @param timeBlockNodeKey The NodeKey of the TranscriptTimeBlockNode that holds the exact speaker occurrence
 */
export const ChangeSpeakerModal: React.FC<ChangeSpeakerModalProps> = ({
  onClose,
  speakerName,
  timeBlockNodeKey,
}) => {
  const [newName, setNewName] = useState(speakerName);
  const [editor] = useLexicalComposerContext();

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewName(event.target.value);
    },
    [setNewName],
  );

  const changeAll = useCallback(() => {
    editor.dispatchCommand(CHANGE_ALL_SPEAKER_OCCURRENCES, {
      oldName: speakerName,
      newName,
    });
    onClose();
  }, [newName, speakerName, editor, onClose]);

  const changeOne = useCallback(() => {
    editor.dispatchCommand(CHANGE_ONE_SPEAKER_OCCURRENCE, {
      timeBlockNodeKey,
      newName,
    });
    onClose();
  }, [newName, timeBlockNodeKey, editor, onClose]);

  useEffect(() => {
    setNewName(speakerName);
  }, [speakerName]);

  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header
        onClose={onClose}
        headerTitle={pgettext('Title-style capitalization', 'Change Speaker Name')}
      />
      <Modal.Body>
        <TextField inputRef={focusAndSelect} value={newName} onChange={onTextChange} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel this form')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          onClick={changeOne}
          label={pgettext('Form submit button label', 'Change this Occurrence')}
          title={pgettext('Form submit button title', 'Submit and change this occurrence')}
        />
        <Modal.Footer.SubmitButton
          onClick={changeAll}
          label={pgettext('Form submit button label', 'Change all Occurrences')}
          title={pgettext('Form submit button title', 'Submit and change all occurrences')}
        />
      </Modal.Footer>
    </Modal>
  );
};
