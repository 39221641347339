import { parse } from 'qs';

import stringifySearchDict from 'medialoopster/stringifySearchDict';

/**
 * Adds additional parameters to an URI that may or may not already has some parameters.
 */
export default (uri: string, additionalParams: Record<string, string | number>): string => {
  const [url, params] = uri.split('?');
  return `${url}?${stringifySearchDict({ ...parse(params), ...additionalParams })}`;
};
