import { DateTime } from 'luxon';

/**
 * Format a frame number as timecode.
 *
 * The timecode is calculated based on the frame and the framerate.
 *
 * @param frame The frame to format.
 * @param fps The framerate of the video.
 */
const formatFramesAsTimecode = (frame: number, fps: string): string => {
  const fpsFl = parseFloat(fps);
  const seconds = Math.trunc(frame / fpsFl);
  const restOfFrames = Math.trunc(frame - seconds * fpsFl);
  const durationInMilliseconds = seconds * 1000;
  const formattedSeconds = DateTime.fromMillis(durationInMilliseconds, { zone: 'UTC' }).toFormat(
    'HH:mm:ss',
  );
  const paddingAmount = fpsFl >= 100 ? 3 : 2;
  const formattedRestOfFrames = String(restOfFrames).padStart(paddingAmount, '0');
  return `${formattedSeconds}:${formattedRestOfFrames}`;
};

export default formatFramesAsTimecode;
