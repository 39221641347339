import { FC } from 'react';

import { formatFramesAsTimecode, formatSecondsAsTimecode } from 'medialoopster/formatTimecode';

interface DurationFromSecondsProps {
  seconds: string;
  frames?: undefined;
  fps?: undefined;
}
interface DurationFromFramesProps {
  seconds?: undefined;
  frames: number;
  fps: string;
}
type DurationProps = DurationFromSecondsProps | DurationFromFramesProps;

const Duration: FC<DurationProps> = ({ seconds, frames, fps }: DurationProps) => {
  let formatted = '';
  if (seconds !== undefined) formatted = formatSecondsAsTimecode(parseFloat(seconds));
  else if (frames !== undefined && fps !== undefined)
    formatted = formatFramesAsTimecode(frames, fps);
  return <span>{formatted}</span>;
};

export default Duration;
