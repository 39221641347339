import { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Download } from 'medialoopster/icons';
import { AUDIO_TYPE, IMAGE_TYPE, VIDEO_TYPE } from 'medialoopster/modules';
import {
  getLink,
  getResourceURI,
  HttpMethodCode,
  mlRel,
  unauthorizedRequestAction,
} from 'medialoopster/rest';
import { loginSelectors } from 'medialoopster/state/login';

import { detailsSelectors } from '../../../../../state/modules/details';
import { downloadSelectors } from '../../../../../state/modules/download';
import { operationsSelectors } from '../../../../../state/modules/operations';
import { AssetChoice, AssetChoiceWithError } from '../../../../../state/modules/operations/types';
import CurrentAssetHighresDownloadActionMenuEntry from '../CurrentAssetHighresDownloadActionMenuEntry';
import CurrentAssetProxyDownloadActionMenuEntry from '../CurrentAssetProxyDownloadActionMenuEntry';
import BulkDownloadModal from './BulkDownloadModal';
import PrepareProxyDownloadActionMenuEntry from './PrepareProxyDownloadActionMenuEntry';
import useCheckBulkDownload from './useCheckBulkDownload';

interface BulkDownloadActionMenuEntryProps {
  readonly label: string;
  readonly tooltip: string;
  readonly disabled: boolean;
  readonly allowedAssetChoices: ReadonlyArray<AssetChoice>;
  readonly downloadActivitiesURL: string;
}

interface HighresBulkDownloadActionMenuEntryProps extends BulkDownloadActionMenuEntryProps {
  readonly allowedCollectionChoice: AssetChoiceWithError | null;
}

type ProxyBulkDownloadActionMenuEntryProps = BulkDownloadActionMenuEntryProps;

const ProxyBulkDownloadActionMenuEntry: FC<ProxyBulkDownloadActionMenuEntryProps> = ({
  label,
  tooltip,
  disabled,
  allowedAssetChoices,
  downloadActivitiesURL,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  return (
    <>
      <MenuItem
        label={label}
        onClick={() => {
          setModalVisible(true);
        }}
        tooltip={tooltip}
        icon={<Download fontSize="small" />}
        disabled={disabled}
      />
      <BulkDownloadModal
        id="proxy-bulk-download-modal"
        assetChoices={allowedAssetChoices}
        downloadActivitiesURL={downloadActivitiesURL}
        open={isModalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        headerTitle={pgettext('Title-style capitalization', 'Download Asset Proxy Files')}
        submitButtonTitle={pgettext('Form submit button title', 'Download proxy')}
      />
    </>
  );
};

export const useProxyDownloadActionMenuEntry = (): ReadonlyArray<ReactElement | undefined> => {
  const token = useSelector(loginSelectors.getToken);
  const dispatch = useDispatch();

  const downloadActivitiesURL = useSelector(downloadSelectors.getProxyDownloadActivitiesURL);

  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const currentAssetType = useSelector(detailsSelectors.getCurrentAssetType);

  const singleMediaProxyDownloadURL = useSelector(downloadSelectors.getSingleMediaProxyDownloadURL);
  const isSingleMediaProxyDownloadAvailable = useSelector(
    downloadSelectors.getIsSingleMediaProxyDownloadAvailable,
  );

  const assetChoices = useSelector(operationsSelectors.getAssetChoices);
  const allowedAssetChoices = useSelector(downloadSelectors.getAllowedProxyDownloadAssetChoices);

  // hide proxy download menu entry for collections, because at collections only the highres could be downloaded
  const isCurrentAssetCollection = useSelector(
    operationsSelectors.isOnlyCurrentAssetCollectionSelected,
  );

  const isProxyDownloadActionMenuVisible = !!(
    downloadActivitiesURL &&
    !isCurrentAssetCollection &&
    allowedAssetChoices.length > 1
  );
  const { hasPermission: isProxyDownloadActionMenuDryRunAllowed, isAuthorized } =
    useCheckBulkDownload(
      token,
      isProxyDownloadActionMenuVisible,
      false,
      downloadActivitiesURL,
      allowedAssetChoices,
    );

  useEffect(() => {
    if (isAuthorized === false) {
      dispatch(unauthorizedRequestAction());
    }
  }, [dispatch, isAuthorized]);

  const isDisabled = () => {
    return !isProxyDownloadActionMenuDryRunAllowed;
  };
  const getTooltip = () => {
    if (!isProxyDownloadActionMenuDryRunAllowed) {
      return gettext("None of the selected assets' proxy files can be downloaded");
    }
    return gettext('Download proxy of the selected assets');
  };
  const getLabel = () => {
    return gettext('Download proxy...');
  };
  if (
    currentAssetType === VIDEO_TYPE &&
    singleMediaProxyDownloadURL &&
    isSingleMediaProxyDownloadAvailable &&
    assetChoices.length === 1 &&
    currentAsset &&
    assetChoices[0].url === getResourceURI(currentAsset)
  ) {
    return [
      <PrepareProxyDownloadActionMenuEntry
        key="prepare-single-video-proxy-download"
        id="prepare-single-video-proxy-download"
        label={gettext('Download proxy...')}
      />,
    ];
  }
  if (
    currentAssetType &&
    [IMAGE_TYPE, AUDIO_TYPE].includes(currentAssetType) &&
    singleMediaProxyDownloadURL &&
    isSingleMediaProxyDownloadAvailable &&
    allowedAssetChoices.length === 1 &&
    currentAsset &&
    allowedAssetChoices[0].url === getResourceURI(currentAsset)
  ) {
    return [
      <CurrentAssetProxyDownloadActionMenuEntry
        key="download-proxy"
        downloadURL={singleMediaProxyDownloadURL}
      />,
    ];
  }
  if (isProxyDownloadActionMenuVisible) {
    return [
      <ProxyBulkDownloadActionMenuEntry
        key="bulk-proxy-download"
        label={getLabel()}
        tooltip={getTooltip()}
        disabled={isDisabled()}
        allowedAssetChoices={allowedAssetChoices}
        downloadActivitiesURL={downloadActivitiesURL}
      />,
    ];
  }
  return [];
};

const HighresBulkDownloadActionMenuEntry: FC<HighresBulkDownloadActionMenuEntryProps> = ({
  label,
  tooltip,
  disabled,
  allowedAssetChoices,
  allowedCollectionChoice,
  downloadActivitiesURL,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  return (
    <>
      <MenuItem
        label={label}
        onClick={() => {
          setModalVisible(true);
        }}
        tooltip={tooltip}
        icon={<Download fontSize="small" />}
        disabled={disabled}
      />
      <BulkDownloadModal
        id="highres-bulk-download-modal"
        assetChoices={allowedAssetChoices}
        collectionChoice={allowedCollectionChoice}
        downloadActivitiesURL={downloadActivitiesURL}
        open={isModalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        headerTitle={pgettext(
          'Title-style capitalization',
          allowedCollectionChoice ? 'Download Collection' : 'Download Asset Highres',
        )}
        submitButtonTitle={pgettext(
          'Form submit button title',
          allowedCollectionChoice ? 'Download collection' : 'Download asset highres',
        )}
      />
    </>
  );
};
export const useHighresDownloadActionMenuEntry = (): ReadonlyArray<ReactElement | undefined> => {
  const token = useSelector(loginSelectors.getToken);
  const dispatch = useDispatch();

  const downloadActivitiesURL = useSelector(downloadSelectors.getHighresDownloadActivitiesURL);
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const downloadLink = currentAsset
    ? getLink(currentAsset, mlRel('download-highres')) || null
    : null;
  const allowedAssetChoices = useSelector(downloadSelectors.getAllowedHighresDownloadAssetChoices);

  const isCurrentAssetCollection = useSelector(
    operationsSelectors.isOnlyCurrentAssetCollectionSelected,
  );
  const allowedCollectionChoice = useSelector(
    downloadSelectors.getAllowedHighresDownloadCollectionChoice,
  );
  const isEmptyCollection = useSelector(detailsSelectors.isCurrentCollectionEmpty);
  const isNotInProductionCollection = useSelector(detailsSelectors.isNotInProductionCollection);

  const isLoadingForDownload = useSelector(downloadSelectors.isLoadingForDownload);
  const downloadChoices = useSelector(downloadSelectors.getAllowedHighresDownloadChoices);

  const isHighresDownloadActionMenuVisible = !!(
    downloadActivitiesURL &&
    (allowedCollectionChoice || allowedAssetChoices.length > 1)
  );

  const { hasPermission: isHighresDownloadActionMenuDryRunAllowed, isAuthorized } =
    useCheckBulkDownload(
      token,
      isHighresDownloadActionMenuVisible,
      isLoadingForDownload,
      downloadActivitiesURL,
      downloadChoices,
    );

  useEffect(() => {
    if (isAuthorized === false) {
      dispatch(unauthorizedRequestAction());
    }
  }, [dispatch, isAuthorized]);

  const isDisabled = () => {
    if (isCurrentAssetCollection) {
      return isEmptyCollection || isNotInProductionCollection;
    }
    return !isHighresDownloadActionMenuDryRunAllowed;
  };

  const getTooltip = () => {
    if (isCurrentAssetCollection) {
      if (isEmptyCollection) return gettext('Collection is empty');
      if (isNotInProductionCollection) {
        return gettext('Collection is not in production');
      }
      return gettext('Download collection');
    }
    if (!isHighresDownloadActionMenuDryRunAllowed) {
      return gettext('None of the selected assets can be downloaded');
    }
    return gettext('Download highres of the selected assets');
  };

  const getMenuEntryLabel = () => {
    if (isCurrentAssetCollection) {
      return gettext('Download collection...');
    }
    return gettext('Download highres...');
  };

  if (
    !isCurrentAssetCollection &&
    downloadLink &&
    downloadLink?.methods?.GET?.code === HttpMethodCode.OK &&
    allowedAssetChoices.length === 1 &&
    currentAsset &&
    allowedAssetChoices[0].url === getResourceURI(currentAsset)
  ) {
    return [
      <CurrentAssetHighresDownloadActionMenuEntry
        key="download-highres"
        downloadURL={downloadLink.href}
      />,
    ];
  }

  if (isHighresDownloadActionMenuVisible) {
    // for collections display the download menu entry only if, in addition, the collection download permission is present
    if (isCurrentAssetCollection && !isHighresDownloadActionMenuDryRunAllowed) {
      return [];
    }
    return [
      <HighresBulkDownloadActionMenuEntry
        key="bulk-highres-download"
        label={getMenuEntryLabel()}
        tooltip={getTooltip()}
        disabled={isDisabled()}
        allowedAssetChoices={allowedAssetChoices}
        allowedCollectionChoice={allowedCollectionChoice}
        downloadActivitiesURL={downloadActivitiesURL}
      />,
    ];
  }
  return [];
};
