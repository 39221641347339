import { FC } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { Modal } from 'medialoopster/components';
import { Delete } from 'medialoopster/icons';

import {
  favoritesActions,
  favoritesSelectors,
  favoritesTypes,
} from '../../../state/modules/favorites';

interface ClearListModalProps {
  readonly show: boolean;
  readonly onClose: () => void;
}

const ClearListModal: FC<ClearListModalProps> = ({ show, onClose }: ClearListModalProps) => {
  const dispatch = useDispatch();

  const selectedList = useSelector(
    favoritesSelectors.getSelectedList,
    shallowEqual,
  ) as favoritesTypes.FavoritesListResource;

  const onFormSubmit = () => {
    dispatch(favoritesActions.clearFavoriteList());
    onClose();
  };

  return (
    <Modal id="favorites-list-clear-modal" open={show} onClose={onClose}>
      <Modal.Header
        id="favorites-list-clear-modal-label"
        headerTitle={`${pgettext('Title-style capitalization', 'Clear List')}: ${
          selectedList && selectedList.name
        }`}
        onClose={onClose}
      />
      <Modal.Body id="favorites-list-clear-modal-body">
        {gettext('Do you really want to clear the content of this favorites list?')}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel this form')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          color="error"
          onClick={onFormSubmit}
          label={pgettext('Form submit button label', 'Clear List')}
          title={pgettext('Form submit button title', 'Clear list')}
          icon={<Delete />}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ClearListModal;
