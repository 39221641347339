import Typography, { TypographyProps } from '@mui/material/Typography';
import { ReactNode, FC } from 'react';

type Props = {
  readonly label: string;
  readonly children: ReactNode;
  readonly visible: boolean;
  readonly variant: TypographyProps['variant'];
  readonly labelAndDisplayOnSameLine: boolean;
  readonly classes?: TypographyProps['classes'];
  readonly testId?: string;
};

const Field: FC<Props> = ({
  label,
  children,
  visible,
  variant,
  labelAndDisplayOnSameLine,
  classes = undefined,
  testId = undefined,
}: Props) => {
  if (!visible) {
    return null;
  }
  return (
    <Typography
      classes={classes}
      variant={variant}
      component="div"
      style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}
      data-testid={testId}
    >
      <div style={{ fontWeight: 'bold', paddingRight: '0.25rem' }}>{label}:</div>
      {labelAndDisplayOnSameLine ? children : <div style={{ width: '100%' }}>{children}</div>}
    </Typography>
  );
};

export default Field;
