import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { gettext, ngettext } from 'medialoopster/Internationalization';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { favoritesSelectors } from '../../../../state/modules/favorites';
import { MediaAssetResource } from '../../../../state/types/asset/baseTypes';
import AddAssetsToCollectionMenu from '../../../Menus/AddAssetsToCollectionMenu';

type Props = {
  readonly assets: ReadonlyArray<MediaAssetResource>;
};

const AddAssetsToCollectionActionMenuEntry = ({ assets }: Props) => {
  const isSelectedListAssetsLoading = useSelector(favoritesSelectors.isSelectedListAssetsLoading);
  const infoMsg = useMemo(
    () =>
      ngettext(
        'Add the selected asset to a collection',
        'Add the selected assets to a collection',
        assets.length,
      ),
    [assets],
  );
  const addableAssets = useMemo(
    () =>
      assets.map((asset) => ({
        display: asset.name,
        value: getResourceURI(asset),
        type: getResourceTypeName(asset),
      })),
    [assets],
  );
  return (
    <>
      <AddAssetsToCollectionMenu
        isLoading={isSelectedListAssetsLoading}
        isLoadingMsg={gettext('Assets are being loaded')}
        infoMsg={infoMsg}
        isEmptyMsg=""
        isEmpty={false}
        assets={addableAssets}
        label={gettext('Add to collection...')}
      />
    </>
  );
};

const useAddAssetsToCollectionActionMenuEntry = (
  assets: ReadonlyArray<MediaAssetResource>,
): ReadonlyArray<ReactElement> => {
  if (assets.length === 0) {
    return [];
  }
  return [<AddAssetsToCollectionActionMenuEntry key="add-to-collection" assets={assets} />];
};

export default useAddAssetsToCollectionActionMenuEntry;
