import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo, ReactNode } from 'react';

import BaseCell, { BaseCellProps } from './BaseCell';

export type SimpleCellProps = {
  readonly noBorder?: boolean;
  readonly value: ReactNode;
  readonly component?: 'span';
} & Pick<BaseCellProps, 'align' | 'disabled'>;

const useStyles = makeStyles(() => ({
  noBorder: { borderBottom: 'none' },
}));

const SimpleCell: FC<SimpleCellProps> = ({
  value,
  align,
  component,
  disabled = false,
  noBorder = false,
}: SimpleCellProps) => {
  const classes = useStyles();
  return (
    <BaseCell
      align={align}
      disabled={disabled}
      className={clsx({ [classes.noBorder]: noBorder })}
      component={component}
    >
      {value}
    </BaseCell>
  );
};

export default memo(SimpleCell);
