import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import makeStyles from '@mui/styles/makeStyles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTime } from 'luxon';
import { FC, FormEvent, MouseEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, interpolate, npgettext, pgettext } from 'medialoopster/Internationalization';
import { Modal } from 'medialoopster/components';
import { getResourceTypeName } from 'medialoopster/rest';

import {
  closeShareAssetsModal,
  deselectProduction,
  selectProduction,
  setDateWithdraw,
  shareAssets,
} from '../../state/modules/assetSharing/actions';
import {
  getModalErrors,
  getModalValues,
  getProductionChoices,
  isModalVisible,
} from '../../state/modules/assetSharing/selectors';
import MultipleChoiceSelect from '../../ui/components/MultipleChoiceSelect';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& .MuiCheckbox-root': {
      padding: 0,
      margin: '0 9px',
    },
  },
}));

const ShareAssetsModal: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isVisible = useSelector(isModalVisible);
  const productionChoices = useSelector(getProductionChoices);
  const values = useSelector(getModalValues);
  const errors = useSelector(getModalErrors);
  const onDateWithdrawChange = useCallback(
    (date) => dispatch(setDateWithdraw(date?.toISODate())),
    [dispatch],
  );

  const onFormSubmit = (event: MouseEvent | FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const dateWithdraw = values.dateWithdraw ? values.dateWithdraw.toISODate() : null;
    dispatch(shareAssets(values.productionIds, dateWithdraw, values.assets));
  };

  const onClose = () => {
    dispatch(closeShareAssetsModal());
  };

  return (
    <Modal open={isVisible} onClose={onClose}>
      <Modal.Header
        id="share-assets-modal-title"
        headerTitle={interpolate(
          npgettext(
            'Title-style capitalization',
            'Share %(assetName)s',
            'Share Multiple Assets',
            values.assets.length,
          ),
          { assetName: values.assets[0] ? values.assets[0].name : '' },
        )}
        onClose={onClose}
      />
      <Modal.Body>
        <form onSubmit={onFormSubmit}>
          {errors.nonField.map((errMsg) => (
            <Modal.Body.Notifier errorMsg={errMsg} key={errMsg} />
          ))}
          <div className={classes.row}>
            <FormControl
              id="share-assets-modal-productions"
              error={errors.productionIds && errors.productionIds.length > 0}
              component="fieldset"
              fullWidth
              size="small"
            >
              <FormLabel component="legend">{gettext('Productions')}</FormLabel>
              <FormGroup>
                {productionChoices.map((productionChoice) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={productionChoice.value}
                        checked={
                          productionChoice.state === 'all' ||
                          values.productionIds.indexOf(productionChoice.value) >= 0
                        }
                        disabled={productionChoice.state === 'all'}
                        indeterminate={
                          productionChoice.state === 'some' &&
                          values.productionIds.indexOf(productionChoice.value) === -1
                        }
                        onChange={() => {
                          if (values.productionIds.indexOf(productionChoice.value) >= 0) {
                            dispatch(deselectProduction(productionChoice.value));
                          } else {
                            dispatch(selectProduction(productionChoice.value));
                          }
                        }}
                        color="primary"
                        size="small"
                      />
                    }
                    label={productionChoice.label}
                    key={productionChoice.value}
                  />
                ))}
              </FormGroup>
              {errors.productionIds.length > 0 && (
                <FormHelperText id="share-assets-modal-productions-helper-text" role="alert">
                  {errors.productionIds.map((errorMessage) => (
                    <span key={errorMessage} style={{ display: 'block' }}>
                      {errorMessage}
                    </span>
                  ))}
                </FormHelperText>
              )}
            </FormControl>
            <DesktopDatePicker
              slotProps={{
                textField: {
                  error: errors.dateWithdraw.length > 0,
                  helperText: errors.dateWithdraw.length > 0 && (
                    <span id="share-assets-modal-datewithdraw-helper-text" role="alert">
                      {errors.dateWithdraw.map((errorMessage) => (
                        <span key={errorMessage} style={{ display: 'block' }}>
                          {errorMessage}
                        </span>
                      ))}
                    </span>
                  ),
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small',
                },
              }}
              label={gettext('Date to withdraw')}
              format={pgettext('Localized numeric date', 'MM/dd/yyyy')}
              value={values.dateWithdraw}
              minDate={DateTime.local().startOf('day').plus({ days: 1 })}
              onChange={onDateWithdrawChange}
            />
          </div>
          {values.assets.length > 1 && (
            <MultipleChoiceSelect
              id="share-assets-modal-assets"
              name="assetSelect"
              label={gettext('Assets to share')}
              choices={values.assets.map((asset) => ({
                key: `${getResourceTypeName(asset)}-${asset.id}`,
                name: asset.name,
              }))}
              choiceDisplay="name"
              choiceValue="key"
              showError={errors.assets.length > 0}
              errorMsg={errors.assets.map((errorMessage) => (
                <span key={errorMessage} style={{ display: 'block' }}>
                  {errorMessage}
                </span>
              ))}
              readOnly
            />
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel sharing')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          label={pgettext('Form submit button label', 'Share Assets')}
          title={pgettext('Form submit button title', 'Share assets with selected productions')}
          disabled={false}
          onClick={onFormSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ShareAssetsModal;
