import { Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import _ from 'lodash';
import { useObservableEagerState } from 'observable-hooks';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { throttleTime } from 'rxjs/operators';

import { gettext } from 'medialoopster/Internationalization';
import { DropdownMenuButton, useVideoController } from 'medialoopster/components';
import { formatFramesAsTimecode } from 'medialoopster/formatTimecode';
import { Delete, EditList } from 'medialoopster/icons';
import { getLink, getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { detailsActions, detailsSelectors, detailsTypes } from '../../state/modules/details';
import { favoritesSelectors } from '../../state/modules/favorites';
import { searchSelectors } from '../../state/modules/search';
import { Highlight, SequenceHighlight } from '../../state/modules/search/types';
import { videoTypes } from '../../state/modules/video';
import {
  Sequence as SequenceResource,
  SequenceMetadata,
  VideoAsset,
} from '../../state/modules/video/types';
import { AssetType } from '../../state/types/asset/baseTypes';
import InplaceSelectEdit from '../../ui/components/InplaceEdits/InplaceSelectEdit';
import InplaceTextAreaEdit from '../../ui/components/InplaceEdits/InplaceTextAreaEdit';
import InplaceTextEdit from '../../ui/components/InplaceEdits/InplaceTextEdit';
import Shot from '../../ui/components/Shot';
import {
  textWithHighlightedItems,
  textWithHighlightedRegions,
} from '../../ui/services/highlighting';
import Field from './AssetFields/Field';
import KeywordCategories from './KeywordCategories';
import SequenceAncestorLinks from './SequenceAncestorLinks';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: theme.spacing(1), // space for elevation shadow
    },
  },
  header: {
    backgroundColor: theme.palette.background.secondary,
    padding: '0.6rem 0.9rem',
  },
  title: {
    display: 'flex',
  },
  menu: {
    marginLeft: 'auto',
  },
  footer: {
    padding: '0.15rem 0.9rem',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cursorGrab: {
    cursor: 'grab',
  },
}));

const getSequenceMetadataHighlights = (field: string, highlights?: SequenceHighlight) => {
  if (highlights?.fields?.[field]) {
    return highlights?.fields?.[field];
  }
  return _.intersection(
    ...Object.values(highlights?.shots || {}).map((shot) => shot.fields?.[field]),
  );
};

type SequenceFieldProps = {
  readonly label: string;
  readonly children: NonNullable<ReactNode>;
  readonly testId: string;
  readonly visible: boolean;
};

const SequenceField: FC<SequenceFieldProps> = ({
  label,
  children,
  testId,
  visible,
}: SequenceFieldProps) => (
  <Field
    label={label}
    visible={visible}
    variant="caption"
    labelAndDisplayOnSameLine
    testId={testId}
  >
    {children}
  </Field>
);

type ShotWrapperProps = {
  readonly shot: videoTypes.Shot;
  readonly assetId: number;
  readonly assetURI: string;
  readonly assetName: string;
  readonly assetIsAvailable: boolean;
  readonly assetTypeName: AssetType;
  readonly fps: string;
  readonly offsetFrames: number;
  readonly productionId: number;
  readonly highlights?: Highlight;
  readonly canDelete: boolean;
  readonly isSelected: boolean;
  readonly isEditMode: boolean;
  readonly isKeywordsEditMode: boolean;
  readonly setKeywordsEditURI: (keywordsEditURI: string | null) => void;
};

export const ShotWrapper: FC<ShotWrapperProps> = ({
  assetId,
  assetURI,
  assetTypeName,
  assetName,
  offsetFrames,
  assetIsAvailable,
  fps,
  productionId,
  shot,
  highlights,
  canDelete,
  isSelected,
  isEditMode,
  isKeywordsEditMode,
  setKeywordsEditURI,
}: ShotWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const videoController = useVideoController();
  const updateFrame$ = useMemo(
    () => videoController.frame$.pipe(throttleTime(200)),
    [videoController],
  );
  const currentFrame = useObservableEagerState(updateFrame$);

  const isActive = currentFrame >= shot.timecode_start && currentFrame <= shot.timecode_end;
  useLayoutEffect(() => {
    if (isActive) {
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }, [ref, isActive]);

  return (
    <Shot
      ref={ref}
      assetId={assetId}
      assetURI={assetURI}
      assetTypeName={assetTypeName}
      assetName={assetName}
      assetIsAvailable={assetIsAvailable}
      offsetFrames={offsetFrames}
      fps={fps}
      productionId={productionId}
      shot={shot}
      highlights={highlights}
      isActive={isActive}
      canDelete={canDelete}
      isSelected={isSelected}
      showKeywords
      showLicenseFlag
      isEditMode={isEditMode}
      isKeywordsEditMode={isKeywordsEditMode}
      setKeywordsEditURI={setKeywordsEditURI}
    />
  );
};

interface SequenceProps {
  readonly asset: VideoAsset;
  readonly sequence: SequenceResource;
  readonly sequenceMetadata: SequenceMetadata;
  readonly sequenceHighlights: SequenceHighlight;
  readonly isEditMode: boolean;
  readonly keywordsEditURI: string | null;
  readonly setKeywordsEditURI: (keywordsEditURI: string | null) => void;
  readonly canDelete: boolean;
}

const Sequence: FC<SequenceProps> = ({
  asset,
  sequence,
  sequenceMetadata,
  sequenceHighlights,
  isEditMode,
  keywordsEditURI,
  setKeywordsEditURI,
  canDelete,
}: SequenceProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mediaLicenseChoices = useSelector(detailsSelectors.getLicenseChoices);
  const mediaLicensorChoices = useSelector(detailsSelectors.getLicensorChoices);

  const canEditSequenceLicense =
    useSelector(detailsSelectors.getCanEditSequenceLicense) && isEditMode;
  const canEditSequenceLicensor =
    useSelector(detailsSelectors.getCanEditSequenceLicensor) && isEditMode;
  const canEditSequenceLocationCity =
    useSelector(detailsSelectors.getCanEditSequenceLocationCity) && isEditMode;
  const canEditSquenceRightsRNB =
    useSelector(detailsSelectors.getCanEditSquenceRightsRNB) && isEditMode;
  const selectedShotRange = useSelector(detailsSelectors.getSelectedShotRange);

  const canEditKeywords = useSelector(detailsSelectors.canEditShotKeywords);

  const assetURI = getResourceURI(asset);
  const assetTypeName = getResourceTypeName(asset);

  const canDrag = useSelector(favoritesSelectors.isActive);
  const [, drag, preview] = useDrag({
    type: 'sequence',
    item: {
      type: 'sequence',
      asset_id: asset.id,
      thumbnailURL: sequenceMetadata.shots[0]?.thumbnail_url,
      deleted: false,
      displayName: sequence.name,
      detail: `${formatFramesAsTimecode(
        sequence.timecode_start,
        asset.fps,
      )} - ${formatFramesAsTimecode(sequence.timecode_end, asset.fps)}`,
      assetIsAvailable: asset.is_available,
      shots: sequenceMetadata.shots,
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const onSubmitEdit = useCallback(
    (resourceURI: string, fieldKey: keyof detailsTypes.SequenceEditFields) => (value: string) => {
      const updates: detailsTypes.SequenceEditFields = { [fieldKey]: value === '*' ? null : value };
      dispatch(detailsActions.editAsset('sequence', resourceURI, updates));
    },
    [dispatch],
  );
  const href = getResourceURI(sequence);
  const selfLink = getLink(sequence, 'self');
  const hasDeletePermission = selfLink?.methods?.DELETE?.code === 'ok';
  const options = useMemo(
    () => [
      {
        key: 'edit',
        label: gettext('Edit'),
        onSelect: () => {
          if (keywordsEditURI === href) {
            setKeywordsEditURI(null);
          } else {
            setKeywordsEditURI(href);
          }
        },
        selected: keywordsEditURI === href,
        icon: <EditList fontSize="small" />,
        visible: canEditKeywords,
      },
      {
        key: 'delete',
        label: gettext('Delete'),
        onSelect: () => {
          dispatch(detailsActions.deleteSequence(sequence));
        },
        icon: <Delete fontSize="small" />,
        visible: hasDeletePermission && canDelete,
      },
    ],
    [
      keywordsEditURI,
      sequence,
      href,
      canEditKeywords,
      hasDeletePermission,
      canDelete,
      dispatch,
      setKeywordsEditURI,
    ],
  );
  const showAncestorLinks = useMemo(
    () => sequenceMetadata?.sequenceAncestors.length > 0 && isEditMode,
    [sequenceMetadata, isEditMode],
  );
  return (
    <Paper key={href} className={classes.paper} data-testid={href}>
      <div
        ref={drag}
        className={clsx(classes.header, {
          [classes.cursorGrab]: canDrag,
          [classes.cursorPointer]: !canDrag,
        })}
      >
        <div className={classes.title}>
          <InplaceTextEdit
            variant="body2"
            textComponents={textWithHighlightedRegions(
              sequence.name,
              sequenceHighlights?.fields?.name,
            )}
            editValue={sequence.name}
            canEdit={isEditMode}
            onSubmit={onSubmitEdit(href, 'name')}
            dense
            data-testid={`${href}-name`}
          />
          {isEditMode && <DropdownMenuButton options={options} className={classes.menu} />}
        </div>
        {(sequence.description || isEditMode) && (
          <InplaceTextAreaEdit
            variant="caption"
            component="p"
            textComponents={textWithHighlightedRegions(
              sequence.description,
              sequenceHighlights?.fields?.description,
            )}
            editValue={sequence.description}
            canEdit={isEditMode}
            onSubmit={onSubmitEdit(href, 'description')}
            data-testid={`${href}-description`}
            dense
          />
        )}
        {(sequenceMetadata.licensor || canEditSequenceLicensor) && (
          <SequenceField
            label={gettext('Licensor')}
            testId={`${href}-licensor`}
            visible={!!sequenceMetadata.licensor || canEditSequenceLicensor}
          >
            {!showAncestorLinks ? (
              <InplaceSelectEdit
                variant="caption"
                component="p"
                onSubmit={onSubmitEdit(href, 'licensor')}
                editValue={sequenceMetadata.licensor ?? ''}
                canEdit={canEditSequenceLicensor}
                choices={mediaLicensorChoices}
                textComponents={textWithHighlightedRegions(
                  sequenceMetadata.licensor ?? '',
                  getSequenceMetadataHighlights('licensor', sequenceHighlights),
                )}
                dense
                minWidth="100%"
                defaultChoice="*"
              />
            ) : (
              <SequenceAncestorLinks
                sequenceAncestorHrefs={sequenceMetadata.sequenceAncestors}
                label={gettext('Licensor')}
              />
            )}
          </SequenceField>
        )}
        {(sequenceMetadata.license || canEditSequenceLicense) && (
          <SequenceField
            label={gettext('License')}
            testId={`${href}-license`}
            visible={!!sequenceMetadata.license || canEditSequenceLicense}
          >
            {!showAncestorLinks ? (
              <InplaceSelectEdit
                variant="caption"
                component="p"
                onSubmit={onSubmitEdit(href, 'license')}
                editValue={sequenceMetadata?.license ?? ''}
                canEdit={canEditSequenceLicense}
                choices={mediaLicenseChoices}
                textComponents={textWithHighlightedRegions(
                  sequenceMetadata.license ?? '',
                  getSequenceMetadataHighlights('license', sequenceHighlights),
                )}
                dense
                minWidth="100%"
                defaultChoice="*"
              />
            ) : (
              <SequenceAncestorLinks
                sequenceAncestorHrefs={sequenceMetadata.sequenceAncestors}
                label={gettext('License')}
              />
            )}
          </SequenceField>
        )}
        {(sequenceMetadata.locationCity || canEditSequenceLocationCity) && (
          <SequenceField
            label={gettext('Location (City)')}
            testId={`${href}-locationCity`}
            visible={!!sequenceMetadata.locationCity || canEditSequenceLocationCity}
          >
            {!showAncestorLinks ? (
              <InplaceTextEdit
                variant="caption"
                component="p"
                onSubmit={onSubmitEdit(href, 'location_city')}
                editValue={sequenceMetadata?.locationCity ?? ''}
                canEdit={canEditSequenceLocationCity}
                textComponents={textWithHighlightedRegions(
                  sequenceMetadata?.locationCity ?? '',
                  getSequenceMetadataHighlights('location_city', sequenceHighlights),
                )}
                dense
              />
            ) : (
              <SequenceAncestorLinks
                sequenceAncestorHrefs={sequenceMetadata.sequenceAncestors}
                label={gettext('Location (City)')}
              />
            )}
          </SequenceField>
        )}
        {(sequence.rights_rnb || canEditSquenceRightsRNB) && (
          <SequenceField
            label={gettext('RNB')}
            testId={`${href}-rights_rnb`}
            visible={!!sequence.rights_rnb || canEditSquenceRightsRNB}
          >
            <InplaceTextAreaEdit
              variant="caption"
              component="p"
              textComponents={textWithHighlightedRegions(
                sequence?.rights_rnb || '',
                sequenceHighlights?.fields?.rights_rnb,
              )}
              editValue={sequence?.rights_rnb ?? ''}
              canEdit={canEditSquenceRightsRNB}
              onSubmit={onSubmitEdit(href, 'rights_rnb')}
              dense
            />
          </SequenceField>
        )}
        {sequenceMetadata.keywordsByCategory
          .filter((category) => category.keywords.length > 0)
          .map((category) => (
            <SequenceField
              label={category.name}
              testId={`${href}-keywords-${category.name}`}
              visible={category.keywords.length !== 0}
              key={category.name}
            >
              {textWithHighlightedItems(
                category.keywords,
                getSequenceMetadataHighlights('keywords', sequenceHighlights),
              )}
            </SequenceField>
          ))}
      </div>
      <Divider light />
      {isEditMode && keywordsEditURI === href && (
        <KeywordCategories
          keywordsByCategory={sequenceMetadata.keywordsByCategory}
          obj={sequence}
        />
      )}
      <div>
        {sequenceMetadata.shots.map((shot) => (
          <ShotWrapper
            key={getResourceURI(shot)}
            assetId={asset.id}
            assetURI={assetURI}
            assetTypeName={assetTypeName}
            assetName={asset.name}
            assetIsAvailable={asset.is_available}
            productionId={asset.production}
            offsetFrames={asset.offset_frames}
            fps={asset.fps}
            shot={shot}
            highlights={sequenceHighlights?.shots[shot.id]}
            canDelete={sequenceMetadata.shots.length > 1}
            isSelected={
              isEditMode &&
              !!selectedShotRange &&
              shot.timecode_start >= selectedShotRange.start &&
              shot.timecode_end <= selectedShotRange.end
            }
            isEditMode={isEditMode}
            isKeywordsEditMode={keywordsEditURI === getResourceURI(shot)}
            setKeywordsEditURI={setKeywordsEditURI}
          />
        ))}
      </div>
      <div className={classes.footer}>
        <Typography variant="caption">
          {formatFramesAsTimecode(sequence.timecode_start + asset.offset_frames, asset.fps)}
          {' - '}
          {formatFramesAsTimecode(sequence.timecode_end + asset.offset_frames, asset.fps)}
        </Typography>
      </div>
    </Paper>
  );
};

const Sequences: FC = () => {
  const dispatch = useDispatch();
  const asset = useSelector(detailsSelectors.getCurrentAsset) as VideoAsset | null;
  const sequences = useSelector(detailsSelectors.getCurrentVideoSequencesCollection);
  const firstSequenceURI = useSelector(detailsSelectors.getFirstSequenceURI);
  const sequencesMetadata = useSelector(detailsSelectors.getCurrentVideoSequencesMetadata);
  const highlights = useSelector(searchSelectors.getCurrentAssetHighlight);
  const isEditMode = useSelector(detailsSelectors.isEditMode);
  const [keywordsEditURI, setKeywordsEditURI] = useState<string | null>(null);
  useEffect(() => {
    if (!isEditMode) {
      setKeywordsEditURI(null);
    }
  }, [isEditMode, setKeywordsEditURI]);
  const firstShotURI = useSelector(detailsSelectors.getFirstShotURI);
  useEffect(() => {
    if (firstShotURI) {
      dispatch(detailsActions.fetchShotOptions(firstShotURI));
    }
  }, [dispatch, firstShotURI]);

  useEffect(() => {
    if (firstSequenceURI) {
      dispatch(detailsActions.fetchSequenceOptions(firstSequenceURI));
    }
  }, [firstSequenceURI, dispatch]);
  if (!asset) {
    return null;
  }

  return (
    <>
      {sequences.items.map((sequence) => {
        const href = getResourceURI(sequence);
        const sequenceMetadata = sequencesMetadata[href];
        return (
          <Sequence
            key={href}
            sequence={sequence}
            asset={asset}
            sequenceMetadata={sequenceMetadata}
            sequenceHighlights={highlights?.sequences?.[sequenceMetadata.id] || { shots: {} }}
            keywordsEditURI={keywordsEditURI}
            setKeywordsEditURI={setKeywordsEditURI}
            canDelete={sequences.items.length > 1}
            isEditMode={isEditMode}
          />
        );
      })}
    </>
  );
};

export default Sequences;
