import { Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import { ReactNode, FC } from 'react';

import LastUpdate from './LastUpdate';

type FilterBarProps = {
  readonly update: DateTime;
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const FilterBar: FC<FilterBarProps> = ({ update, children = null }: FilterBarProps) => {
  const classes = useStyles();
  const isMdDown = useMediaQuery<DefaultTheme>((theme) => theme.breakpoints.down('md'));

  return (
    <Grid id="filter-bar" container className={classes.root}>
      <Grid container item spacing={2} md={10} sm={12} alignItems="center">
        {children}
      </Grid>
      {!isMdDown && (
        <Grid container item spacing={2} md={2} justifyContent="flex-end" alignItems="center">
          <LastUpdate update={update} />
        </Grid>
      )}
    </Grid>
  );
};

export default FilterBar;
