import Grid from '@mui/material/Grid';
import { SliderThumb } from '@mui/material/Slider';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useObservableEagerState } from 'observable-hooks';
import { forwardRef, HTMLAttributes, memo, Ref, RefObject, useMemo } from 'react';

import { gettext } from '../../Internationalization';
import { LANGUAGES } from '../../constants';
import InOutSlider from './InOutSlider';
import MpdProxyLoader from './MpdProxyLoader';
import ProgressSlider from './ProgressSlider';
import ShakaPlayer, { Subtitle } from './ShakaPlayer';
import { useVideoController } from './VideoControllerContext';

const useStyles = makeStyles(() => ({
  playbackProgressbar: {
    border: '1px solid black',
    height: '16px',
    position: 'relative',
  },
  progressMarker: {
    position: 'absolute',
    bottom: '0',
    width: '2px',
    height: '12px',
    boxSizing: 'unset',
    transform: 'translate(0, -50%)',

    backgroundColor: 'black',
    border: '1px solid #d3d3d3',
    borderRadius: 'unset',
    'z-index': '10',
    cursor: 'pointer',
    outline: 'none',
    '&:focus': {
      border: '1px solid #999',
    },
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'unset',
    },
  },
  gridPlayer: {
    background: '#000000',
    position: 'relative',
    display: 'flex',
    '&> div': {
      position: 'initial',
    },
    '&> div > video': {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
    },
  },
  cursorGrab: {
    cursor: 'grab',
  },
}));

interface ShakaPlayerWithSlidersProps {
  src: string | null;
  assetFps: number | undefined;
  poster?: string;
  inTime: number;
  outTime: number;
  sliderThumbRef: RefObject<HTMLBaseElement>;
  selectedAudioTrack?: shaka.extern.Track | null;
  showSubtitle?: boolean;
  subtitles?: ReadonlyArray<Subtitle>;
  selectedTextLanguage?: keyof typeof LANGUAGES | null;
  canDrag?: boolean;
  dragRef?: Ref<HTMLDivElement>;
  assetFrames: number;
}

const ShakaPlayerWithSliders = forwardRef<HTMLVideoElement, ShakaPlayerWithSlidersProps>(
  (
    {
      src,
      assetFps,
      poster,
      inTime,
      outTime,
      sliderThumbRef,
      selectedAudioTrack = null,
      showSubtitle = false,
      subtitles = [],
      selectedTextLanguage = null,
      canDrag = false,
      dragRef = undefined,
      assetFrames,
    }: ShakaPlayerWithSlidersProps,
    ref,
  ) => {
    const classes = useStyles();
    const assetDuration = useMemo(
      () => (assetFps ? assetFrames / assetFps : Infinity),
      [assetFrames, assetFps],
    );
    const videoController = useVideoController();
    const duration = useObservableEagerState(videoController.duration$);
    const sliderThumbComponent = useMemo(
      () =>
        ({ children, ...other }: HTMLAttributes<unknown>) => {
          return (
            <SliderThumb
              tabIndex={0}
              role="button"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              className={clsx(other.className, classes.progressMarker)}
              ref={sliderThumbRef}
            >
              {children}
            </SliderThumb>
          );
        },
      [classes, sliderThumbRef],
    );
    return (
      <Grid container item xs={12}>
        <Grid
          ref={dragRef}
          className={clsx(classes.gridPlayer, {
            [classes.cursorGrab]: canDrag,
          })}
          item
          xs={12}
          data-testid="video-player-drag-area"
        >
          <ShakaPlayer
            ref={ref}
            src={src}
            poster={poster}
            selectedAudioTrack={selectedAudioTrack}
            showSubtitle={showSubtitle}
            subtitles={subtitles}
            selectedTextLanguage={selectedTextLanguage}
            assetFps={assetFps}
          />
        </Grid>
        <Grid
          className={classes.playbackProgressbar}
          aria-label={gettext('Playback Progress')}
          item
          xs={12}
        >
          <InOutSlider inTime={inTime} outTime={outTime} duration={duration} />
          <ProgressSlider sliderThumbComponent={sliderThumbComponent} />
          <MpdProxyLoader assetDuration={assetDuration} />
        </Grid>
      </Grid>
    );
  },
);

export default memo(ShakaPlayerWithSliders);
