import { Link, ResourceMap } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { Annotation } from './types';

/**
 * Get the RescourceMap of all Video Annotations.
 * @param state The RootState of the frontend.
 */
export const getAnnotations = (state: RootState): ResourceMap<Annotation> =>
  state.annotation.annotations;

/**
 * Get the Link to the annotations-endpoint.
 */
export const getAnnotationEndpointLink: (state: RootState) => Link | null = (state) =>
  state.annotation.annotationEndpointLink;
