import { Theme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

type Props = Pick<
  ButtonProps,
  'style' | 'title' | 'variant' | 'color' | 'name' | 'aria-label' | 'disabled' | 'children'
> & {
  readonly href: string;
  readonly className?: string;
  readonly icon?: ReactNode;
  readonly forceDownload?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // HACK: Override bootstrap styling.
    '&:hover:not([disabled])': {
      color: `${theme.palette.background.secondary} !important`,
    },
    '&:active:not([disabled])': {
      color: `${theme.palette.background.secondary} !important`,
    },
    '&:focus:not([disabled])': {
      color: `${theme.palette.background.secondary} !important`,
    },
  },
}));

const LinkButton: FC<Props> = ({
  name,
  className,
  title,
  href,
  color,
  variant,
  disabled,
  style,
  'aria-label': ariaLabel,
  icon = undefined,
  forceDownload = undefined,
  children,
}: Props) => {
  const classes = useStyles();
  return (
    // TODO: Remove next two lines as soon as `Button` typescript supports `target`.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Button
      className={clsx(className, classes.root)}
      name={name}
      title={title}
      color={color}
      href={disabled ? undefined : href}
      variant={variant}
      target="_blank"
      disabled={disabled || !href}
      aria-label={ariaLabel}
      disableElevation
      style={style}
      startIcon={icon}
      download={forceDownload}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
