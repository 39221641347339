import Checkbox from '@mui/material/Checkbox';
import { FC, memo } from 'react';

import { gettext } from 'medialoopster/Internationalization';

import BaseCell, { BaseCellProps } from './base/BaseCell';

type Props = {
  readonly checked: boolean;
  readonly onChange?: (checked: boolean) => void;
} & Pick<BaseCellProps, 'disabled'>;

const CheckboxCell: FC<Props> = ({ disabled, checked, onChange }: Props) => {
  return (
    <BaseCell padding="checkbox" disabled={disabled}>
      <Checkbox
        color="primary"
        disabled={disabled}
        checked={checked}
        onChange={onChange && ((_event, newChecked) => onChange(newChecked))}
        inputProps={{ 'aria-label': gettext('Select row') }}
      />
    </BaseCell>
  );
};

export default memo(CheckboxCell);
