import { AssetChoice } from '../operations/types';
import {
  ANALYSE_ASSETS,
  AnalyseAssets,
  ASSIGN_FACE,
  AssignFace,
  FETCH_CURRENT_ASSET_PERSON_COUNT,
  FETCH_FACES_OPTIONS,
  FETCH_KNOWN_PERSONS,
  FETCH_SEGMENTS_OPTIONS,
  FetchCurrentAssetPersonCount,
  FetchFacesOptions,
  FetchKnownPersons,
  FetchSegmentsOptions,
  RENAME_PERSON,
  RenamePerson,
  SEEK_TO_ACTIVE_PERSON,
  SeekToActivePerson,
  SET_ACTIVE_PERSON,
  SET_CONFIDENCE_THRESHOLD,
  SET_CURRENT_ASSET_PERSON_COUNT,
  SET_HAS_RENAME_ERROR,
  SetActivePerson,
  SetConfidenceThreshold,
  SetCurrentAssetPersonCount,
  SetHasRenameError,
  SPLIT_FACES,
  SplitFaces,
  TOGGLE_ALL_BOUNDING_BOXES_VISIBLE,
  TOGGLE_PERSONS_VISIBLE,
  ToggleAllBoundingBoxesVisible,
  TogglePersonsVisible,
} from './types';

export const setConfidenceThreshold = (confidenceThreshold: string): SetConfidenceThreshold => ({
  type: SET_CONFIDENCE_THRESHOLD,
  payload: {
    confidenceThreshold,
  },
});

export const fetchKnownPersons = (): FetchKnownPersons => ({
  type: FETCH_KNOWN_PERSONS,
  payload: { url: '/api/persons/?is_known=True' },
});

export const fetchSegmentsOptions = (): FetchSegmentsOptions => ({
  type: FETCH_SEGMENTS_OPTIONS,
});

export const fetchFacesOptions = (): FetchFacesOptions => ({
  type: FETCH_FACES_OPTIONS,
});

export const setActivePerson = (uri: string): SetActivePerson => ({
  type: SET_ACTIVE_PERSON,
  payload: {
    uri,
  },
});

export const fetchCurrentAssetPersonCount = (): FetchCurrentAssetPersonCount => ({
  type: FETCH_CURRENT_ASSET_PERSON_COUNT,
});

export const setCurrentAssetPersonCount = (count: number): SetCurrentAssetPersonCount => ({
  type: SET_CURRENT_ASSET_PERSON_COUNT,
  payload: {
    count,
  },
});

export const togglePersonsVisible = (): TogglePersonsVisible => ({
  type: TOGGLE_PERSONS_VISIBLE,
});

export const toggleAllBoundingBoxesVisible = (): ToggleAllBoundingBoxesVisible => ({
  type: TOGGLE_ALL_BOUNDING_BOXES_VISIBLE,
});

export const renamePerson = (url: string, name: string): RenamePerson => ({
  type: RENAME_PERSON,
  payload: { url, name },
});

export const assignFace = (url: string, person: number): AssignFace => ({
  type: ASSIGN_FACE,
  payload: { url, person },
});

export const setHasRenameError = (hasError: boolean): SetHasRenameError => ({
  type: SET_HAS_RENAME_ERROR,
  payload: { hasError },
});

export const splitFaces = (): SplitFaces => ({
  type: SPLIT_FACES,
});

export const analyseAssets = (
  actionURI: string,
  assetChoices: ReadonlyArray<AssetChoice>,
): AnalyseAssets => ({
  type: ANALYSE_ASSETS,
  payload: {
    actionURI,
    assetChoices,
  },
});

export const seekToActivePerson = (): SeekToActivePerson => ({
  type: SEEK_TO_ACTIVE_PERSON,
});
