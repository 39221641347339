import { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { FC, ReactNode } from 'react';

import Button from '../Button';
import IconButton from '../buttons/IconButton';

type Props = {
  readonly popupId: string;
  readonly label?: ReactNode;
  readonly icon?: ReactNode;
  readonly children?: ReactNode;
  readonly anchorOrigin?: MenuProps['anchorOrigin'];
  readonly buttonProps?: ButtonProps;
};

const DropdownMenu: FC<Props> = ({
  popupId,
  label,
  icon,
  children,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  buttonProps = {},
}: Props) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId,
  });
  return (
    <>
      {icon ? (
        <IconButton
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...bindTrigger(popupState)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          title={label?.toString()}
        >
          {icon}
        </IconButton>
      ) : (
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...bindTrigger(popupState)}
          variant="outlined"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
        >
          {label}
        </Button>
      )}
      <Menu
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bindMenu(popupState)}
        anchorOrigin={anchorOrigin}
        transformOrigin={{
          vertical: anchorOrigin.vertical === 'bottom' ? 'top' : 'bottom',
          horizontal: anchorOrigin.horizontal,
        }}
        onClick={popupState.close}
        keepMounted
      >
        {children}
      </Menu>
    </>
  );
};
export default DropdownMenu;
