export const ERROR = 'error';
export const PREPARING_IMPORT = 'preparing_import';
export const GENERATING = 'generating';
export const PREVIEW_AVAILABLE = 'preview_available';
export const RECORDING = 'recording';

export type AssetStatus =
  | typeof ERROR
  | typeof PREPARING_IMPORT
  | typeof GENERATING
  | typeof PREVIEW_AVAILABLE
  | typeof RECORDING;

export const mapStatus = (value: number): AssetStatus => {
  if (value < 0) {
    return ERROR;
  }
  if (value < 3) {
    return PREPARING_IMPORT;
  }
  if (value === 3) {
    return RECORDING;
  }
  if (value < 6) {
    return GENERATING;
  }

  return PREVIEW_AVAILABLE;
};
