import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, memo } from 'react';

const useStyles = makeStyles((theme) => ({
  label: { fontSize: '75%' },
  textSmall: { fontSize: '85%' },
  textBig: { lineHeight: '1.1', fontWeight: theme.typography.fontWeightMedium },
}));

const getNodeText = (node: JSX.Element | string | number): string => {
  if (typeof node === 'string' || typeof node === 'number') return String(node);
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
  return '';
};

const isLongInfoText = (text: string | JSX.Element) => {
  if (typeof text === 'string') return text.length > 31;
  return getNodeText(text).length > 31;
};
const shortenText = (text: string | JSX.Element) =>
  isLongInfoText(text) ? `${getNodeText(text).substring(0, 31)} …` : text;

interface LabeledInfoProps {
  label: string;
  children: string | JSX.Element;
  isWide: boolean;
  big?: boolean;
  shorten?: boolean;
}
const LabeledInfo: FC<LabeledInfoProps> = ({
  label,
  children,
  isWide,
  big = false,
  shorten = false,
}: LabeledInfoProps) => {
  const classes = useStyles();

  return (
    <div data-testid={label}>
      {isWide && <span className={classes.label}>{`${label}: `}</span>}
      <Tooltip title={shorten && !isWide && isLongInfoText(children) ? <>{children}</> : ''}>
        <span
          className={clsx({
            [classes.textBig]: big,
            [classes.textSmall]: !big,
          })}
        >
          {shorten && !isWide ? shortenText(children) : children}
        </span>
      </Tooltip>
    </div>
  );
};

export default memo(LabeledInfo);
