import { Chip, ChipProps, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useState } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { MultiAutoCompleteInput } from 'medialoopster/components';

type Props = {
  readonly label: string;
  readonly selectedKeywords: ReadonlyArray<string>;
  readonly availableKeywords: ReadonlyArray<string>;
  readonly onAddKeywords: (keywords: ReadonlyArray<string>) => void;
  readonly getKeywordChipProps: (keyword: string) => Partial<ChipProps>;
  readonly disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
  noKeywords: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
  keywordBox: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

const KeywordsInput: FC<Props> = ({
  label,
  selectedKeywords,
  availableKeywords,
  onAddKeywords,
  getKeywordChipProps,
  disabled = false,
}: Props) => {
  const [value, setValue] = useState<string[]>([]);
  const classes = useStyles();
  return (
    <Grid item container alignItems="flex-end">
      <Grid item xs={12}>
        <MultiAutoCompleteInput
          label={label}
          name={label}
          onChange={setValue}
          onSubmit={() => {
            onAddKeywords(value);
            setValue([]);
          }}
          value={value}
          options={availableKeywords.slice()}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} className={classes.keywordBox}>
        {selectedKeywords.length === 0 && (
          <Chip
            aria-hidden
            className={clsx(classes.noKeywords)}
            label={gettext('No keywords')}
            size="small"
            variant="outlined"
            disabled={disabled}
          />
        )}
        {selectedKeywords.map((keyword) => (
          <Chip
            className={classes.chip}
            key={keyword}
            label={keyword}
            size="small"
            variant="filled"
            color="info"
            {...getKeywordChipProps(keyword)}
            disabled={disabled}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default KeywordsInput;
