import { createSelector } from 'reselect';

import { gettext } from 'medialoopster/Internationalization';
import { SelectOption } from 'medialoopster/components';
import { getResource, getResourceURI } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { Production, ProductionsMap } from './types';

export const getProductionsMap = (state: RootState): ProductionsMap =>
  state.productions.productions;
export const getProductions = createSelector(
  getProductionsMap,
  (map): ReadonlyArray<Production> => Object.values(map.resources),
);

export const getProductionResource = (
  state: RootState,
  productionHref: string,
): Production | undefined => {
  const productionResourceMap = getProductionsMap(state);
  return getResource(productionResourceMap, productionHref);
};

export const getWriteableProductionsWithProjectDevice = createSelector(
  getProductions,
  (productions): ReadonlyArray<Production> =>
    productions.filter((p) => p.can_write && p.projectasset_device !== null),
);

export const hasWriteableProductionsWithProjectDevice = (state: RootState): boolean =>
  getWriteableProductionsWithProjectDevice(state).length > 0;

export const getSelectedProductionURL = (state: RootState): string | null =>
  state.productions.selectedProductionURL;

export const getSelectedProduction = (state: RootState): Production | null => {
  const productionURL = state.productions.selectedProductionURL;
  if (productionURL === null) {
    return null;
  }
  return getResource(state.productions.productions, productionURL) || null;
};

export const getProductionOptions = createSelector(getProductions, (productions) => {
  let filter = productions.map((production) => ({
    id: production.id,
    display: production.name,
    value: getResourceURI(production),
  }));
  if (productions.length !== 1) {
    filter = [{ id: 0, display: gettext('All productions'), value: '' }].concat(filter);
  }
  return filter;
});

export const getWriteableProductionsWithProjectDeviceOptions = createSelector(
  getWriteableProductionsWithProjectDevice,
  (writableProductions): ReadonlyArray<SelectOption> =>
    writableProductions.map((el) => ({ display: el.name, value: getResourceURI(el) })),
);
