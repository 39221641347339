import makeStyles from '@mui/styles/makeStyles';
import { FC, memo, MouseEvent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import {
  ErrorML,
  NoPreview,
  Pause,
  Preview,
  Collection as CollectionIcon,
} from 'medialoopster/icons';
import { getLinkTitles, getResourceURI } from 'medialoopster/rest';

import {
  ERROR,
  GENERATING,
  mapStatus,
  PREPARING_IMPORT,
  PREVIEW_AVAILABLE,
  RECORDING,
} from '../../../../businessRules/models/AssetStatus';
import { SearchAssetCollectionResult } from '../../../../state/modules/rest/collectionsearchresult/types';
import { searchActions } from '../../../../state/modules/search';
import FormattedDate from '../../../../ui/components/FormattedDate';
import AssetHeader from '../AssetHeader';
import LabeledInfo from '../LabeledInfo';
import AssetCollectionStatusBar from './AssetCollectionStatusBar';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  body: { display: 'flex', flexGrow: 1, alignItems: 'center', paddingBottom: theme.spacing(1) },
  bodyInfo: { flexGrow: 1 },
  bodyInfoCentered: { display: 'flex', alignItems: 'center' },
  bodyImage: {
    width: '98px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  bodyIcon: { display: 'flex', paddingRight: theme.spacing(1) },
  bodyRowCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

const statusTitleMap: Record<string, string> = {
  [ERROR]: gettext('An error occurred'),
  [PREPARING_IMPORT]: `${gettext('Preparing import')} …`,
  [GENERATING]: gettext('Preparing collection'),
  [RECORDING]: gettext('Preparing collection'),
  [PREVIEW_AVAILABLE]: gettext('Collection preview available'),
};

const statusLevelMap: Record<string, string> = {
  [ERROR]: 'error',
  [PREPARING_IMPORT]: 'warning',
  [GENERATING]: 'default',
  [RECORDING]: 'default',
  [PREVIEW_AVAILABLE]: 'default',
};

export interface AssetCollectionProps {
  readonly asset: SearchAssetCollectionResult;
  readonly isWide: boolean;
  readonly current: boolean;
  readonly inSelection: boolean;
}

const renderStatusIcon = (internalStatus: string) => {
  // 'Preview' icons must be aligned with the other icons, since it has no left-right margin
  if (internalStatus === PREVIEW_AVAILABLE)
    return <Preview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  if (internalStatus === ERROR) return <ErrorML style={{ fontSize: '1.2rem' }} />;
  if (internalStatus === PREPARING_IMPORT) return <Pause style={{ fontSize: '1.2rem' }} />;
  if (internalStatus === GENERATING)
    return <NoPreview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  if (internalStatus === RECORDING)
    return <NoPreview style={{ fontSize: '1.2rem', marginLeft: '0.125rem' }} />;
  return <></>;
};

const renderAssetIcon = () => <CollectionIcon fontSize="small" />;
const renderAssetThumbnail = () => <CollectionIcon fontSize="large" />;

const AssetCollection: FC<AssetCollectionProps> = ({
  asset,
  isWide,
  current,
  inSelection,
}: AssetCollectionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const internalStatus = mapStatus(asset.status_asset);
  const renderAssetStatusIcon = useCallback(
    () => renderStatusIcon(internalStatus),
    [internalStatus],
  );
  const handleAssetClick = useCallback(
    (event: MouseEvent<Element>) => {
      dispatch(searchActions.clickEntry(asset.id, 'collection', getResourceURI(asset), event));
    },
    [dispatch, asset],
  );
  const CollectionAssetStatusBar: FC = () => {
    return (
      <AssetCollectionStatusBar
        asset={asset}
        isWide={isWide}
        renderAssetStatusIcon={renderAssetStatusIcon}
        statusTitle={statusTitleMap[internalStatus]}
        statusLevel={statusLevelMap[internalStatus]}
        showLabelAssetStatus={isWide || internalStatus === ERROR}
      />
    );
  };

  const productionTitle = useMemo(() => getLinkTitles(asset, 'production'), [asset]);
  return (
    <AssetHeader
      isWide={isWide}
      isCurrent={current}
      inSelection={inSelection}
      onAssetClick={handleAssetClick}
    >
      {!isWide && (
        <div className={classes.header}>
          <span className={classes.bodyIcon}>{renderAssetIcon()}</span>
          <LabeledInfo isWide={false} label={gettext('Title')} big shorten>
            {asset.name}
          </LabeledInfo>
        </div>
      )}
      <div className={classes.body}>
        <div className={classes.bodyImage}>{renderAssetThumbnail()}</div>

        {isWide && (
          <div className={classes.bodyInfo}>
            <div className={classes.bodyRowCentered}>
              <LabeledInfo isWide={isWide} label={gettext('Title')} big shorten>
                {asset.name}
              </LabeledInfo>
              <div className={classes.bodyInfoCentered}>
                <span className={classes.bodyIcon}>{renderAssetIcon()}</span>
                {gettext('Collection')}
              </div>
            </div>

            <div className={classes.bodyRowCentered}>
              <LabeledInfo isWide={isWide} label={gettext('Production')}>
                <>{productionTitle}</>
              </LabeledInfo>
              <LabeledInfo isWide={isWide} label={gettext('Added')}>
                <FormattedDate
                  date={asset.date_add}
                  format={pgettext(
                    'Localized numeric date and time with seconds',
                    'MM/dd/yyyy h:mm:ss a',
                  )}
                />
              </LabeledInfo>
            </div>
            <div className={classes.bodyRowCentered}>
              <CollectionAssetStatusBar />
            </div>
          </div>
        )}

        {!isWide && (
          <div className={classes.bodyInfo}>
            <div className={classes.bodyRowCentered}>
              <span>&nbsp;&nbsp;</span>
            </div>
            <div className={classes.bodyRowCentered}>
              <span>&nbsp;&nbsp;</span>
              <LabeledInfo isWide={isWide} label={gettext('Date added')}>
                <FormattedDate
                  date={asset.date_add}
                  format={pgettext('Localized numeric date', 'MM/dd/yyyy')}
                />
              </LabeledInfo>
            </div>

            <div className={classes.bodyRowCentered}>
              <LabeledInfo isWide={isWide} label={gettext('Production')}>
                <>{productionTitle}</>
              </LabeledInfo>
              <LabeledInfo isWide={isWide} label={gettext('Time added')}>
                <FormattedDate
                  date={asset.date_add}
                  format={pgettext('Localized time with seconds', 'h:mm:ss a')}
                />
              </LabeledInfo>
            </div>

            <div className={classes.bodyRowCentered}>
              <CollectionAssetStatusBar />
            </div>
          </div>
        )}
      </div>
    </AssetHeader>
  );
};

export default memo(AssetCollection);
