import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useCallback, useEffect, useReducer } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { gettext, interpolate } from 'medialoopster/Internationalization';
import { BaseResource, getLinkHref, getResourceTypeName, mlRel } from 'medialoopster/rest';

import {
  DraggableAssetSelection,
  DraggableFavoritesItem,
  DraggableSequence,
} from '../../../businessRules/models/FavoriteListsHelpers';
import { favoritesActions } from '../../../state/modules/favorites';
import { FavoritesItemState } from '../../../state/modules/favorites/types';
import FavoriteItemsDragLayer from './FavoriteItemsDragLayer';
import FavoriteListItem from './FavoriteListItem';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 86,
    paddingLeft: 9,
    paddingRight: 10,
    display: 'flex',
    overflow: 'hidden',
  },
  list: {
    display: 'flex',
    overflow: 'auto',
  },
  emptyFavorites: {
    padding: '15px 0',
  },
  favoriteItemContent: {
    borderRadius: 4,
    boxShadow:
      theme.palette.mode === 'light'
        ? 'inset 0 1px 1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
        : 'inset 0 1px 1px rgba(255,255,255,0.2),0px 1px 1px 0px rgba(255,255,255,0.14),0px 1px 3px 0px rgba(255,255,255,0.12)',
    margin: '7px 0',
    cursor: 'pointer',
    padding: 2,
    backgroundColor: theme.palette.background.secondary,
    textAlign: 'center',
  },
  favoriteItemDecreasedOpacity: {
    opacity: 0.5,
  },
  favoriteItemDetail: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 98,
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(8.5),
  },
}));

type DNDRootState = {
  replacedItemId: number | null;
  draggedItemId: number | null;
  itemId: number;
  items: ReadonlyArray<FavoritesItemState>;
};

type DNDSetItemsAction = {
  type: 'setItems';
  items: ReadonlyArray<FavoritesItemState>;
};

type DNDDragHoverAction = {
  type: 'dragHover';
  itemId: number;
};

type DNDDragStartAction = {
  type: 'dragStart';
  itemId: number;
};

type DNDDragEndAction = {
  type: 'dragEnd' | 'drop';
};

const orderItems = (
  items: ReadonlyArray<FavoritesItemState>,
  replacedItemId: number | null,
  draggedItemId: number | null,
) => {
  const replacedItemIndex = replacedItemId
    ? items.map((item) => item.id).indexOf(replacedItemId)
    : -1;
  const otherItems = items.filter((item) => item.id !== draggedItemId);
  return [
    ...otherItems.slice(0, replacedItemIndex),
    ...items.filter((item) => item.id === draggedItemId),
    ...otherItems.slice(replacedItemIndex),
  ];
};

const itemOrderReducer = (
  state: DNDRootState,
  action: DNDSetItemsAction | DNDDragHoverAction | DNDDragStartAction | DNDDragEndAction,
): DNDRootState => {
  switch (action.type) {
    case 'setItems':
      return {
        ...state,
        items: orderItems(action.items, state.replacedItemId, state.draggedItemId),
      };
    case 'dragHover':
      return action.itemId === state.draggedItemId || state.draggedItemId == null
        ? state
        : {
            ...state,
            items: orderItems(state.items, action.itemId, state.draggedItemId),
            replacedItemId: action.itemId,
          };
    case 'dragStart':
      return {
        ...state,
        draggedItemId: action.itemId,
        replacedItemId: null,
      };
    case 'drop':
    case 'dragEnd':
      return {
        ...state,
        replacedItemId: null,
        draggedItemId: null,
      };
    default:
      return state;
  }
};

type FavoritesItemsProps = {
  listName: string;
  listItems: ReadonlyArray<FavoritesItemState>;
  onItemDragStart: () => void;
  onItemDragEnd: () => void;
  deleteDraggedItem: boolean;
};

const FavoritesItems: FC<FavoritesItemsProps> = ({
  listName,
  listItems,
  onItemDragStart,
  onItemDragEnd,
  deleteDraggedItem,
}: FavoritesItemsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [itemOrderState, dispatchItemOrder] = useReducer(itemOrderReducer, {
    itemId: -1,
    items: [],
    replacedItemId: null,
    draggedItemId: null,
  });
  useEffect(() => {
    dispatchItemOrder({ type: 'setItems', items: listItems });
  }, [listItems]);
  const onListItemDragStart = useCallback(
    (id) => {
      onItemDragStart();
      dispatchItemOrder({ type: 'dragStart', itemId: id });
    },
    [onItemDragStart, dispatchItemOrder],
  );
  const onListItemDragHover = useCallback(
    (id) => {
      dispatchItemOrder({ type: 'dragHover', itemId: id });
    },
    [dispatchItemOrder],
  );
  const [, drop] = useDrop({
    accept: [
      'favoriteitem',
      'videoasset',
      'imageasset',
      'audioasset',
      'shot',
      'pseudoshot',
      'asset-selection',
      'sequence',
    ],
    drop: (item: DraggableFavoritesItem | DraggableAssetSelection | DraggableSequence) => {
      switch (item.type) {
        case 'favoriteitem': {
          if (itemOrderState.draggedItemId !== null) {
            dispatchItemOrder({ type: 'drop' });
            const index = itemOrderState.items
              .map((favItem) => favItem.id)
              .indexOf(itemOrderState.draggedItemId);
            dispatch(favoritesActions.moveFavoriteItem(itemOrderState.draggedItemId, index));
          }
          break;
        }
        case 'videoasset':
        case 'imageasset':
        case 'audioasset':
          if (item.assetTypeName !== null) {
            dispatch(favoritesActions.addAssetToFavorites(item.assetTypeName, item.id));
          }
          break;
        case 'shot':
        case 'pseudoshot':
          if (
            item.asset_id &&
            item.timecode_start !== undefined &&
            item.timecode_end !== undefined
          ) {
            dispatch(
              favoritesActions.addVideoClipToFavorites(
                item.asset_id,
                item.timecode_start,
                item.timecode_end,
              ),
            );
          }
          break;
        case 'asset-selection':
          dispatch(favoritesActions.addAssetsToFavorites(item.assetTypeName, item.assetIds));
          break;
        case 'sequence':
          dispatch(favoritesActions.addSequenceToFavorites(item.shots));
          break;

        default:
          break;
      }
    },
  });
  const onListItemDragEnd = useCallback(() => {
    onItemDragEnd();
    dispatchItemOrder({ type: 'dragEnd' });
  }, [onItemDragEnd, dispatchItemOrder]);

  return (
    <div id="favItems" ref={drop} className={`${classes.container} droppable-favorites`}>
      <FavoriteItemsDragLayer deleteDraggedItem={deleteDraggedItem} cssClasses={classes} />
      <List className={classes.list} disablePadding dense>
        {itemOrderState.items.length > 0 &&
          itemOrderState.items.map((item) => (
            <FavoriteListItem
              key={item.id}
              id={item.id}
              deleted={item.deleted}
              displayName={item.displayName}
              detail={item.detail}
              thumbnailURL={item.thumbnailURL}
              assetTypeName={item.asset ? getResourceTypeName(item.asset) : undefined}
              assetHref={getLinkHref(item as BaseResource, mlRel('asset')) || ''}
              assetId={item.asset_id}
              assetIsAvailable={item?.asset?.is_available ?? true}
              timecodeStart={item.timecode_start}
              timecodeEnd={item.timecode_end}
              onDragStart={onListItemDragStart}
              onDragHover={onListItemDragHover}
              onDragEnd={onListItemDragEnd}
              isDragged={item.id === itemOrderState.draggedItemId}
              cssClasses={classes}
            />
          ))}
        {itemOrderState.items.length === 0 && (
          <ListItem classes={{ root: classes.emptyFavorites }}>
            <Alert icon={false} variant="filled" severity="info">
              {interpolate(gettext("Nothing in your list '%(list_name)s'."), {
                list_name: listName,
              })}
            </Alert>
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default FavoritesItems;
