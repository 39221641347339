import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { saveTranscript } from '../../../state/modules/details/actions';
import { TranscriptDiff } from './Editor/diff';

/**
 * Hook to provide a callback to save the transcript changes.
 *
 * The callback take s a transcript diff and saves it by dispatching a `saveTranscript` action.
 */
const useSaveTranscript = (): ((diff: TranscriptDiff) => void) => {
  const dispatch = useDispatch();
  return useCallback((diff) => dispatch(saveTranscript(diff)), [dispatch]);
};

export default useSaveTranscript;
