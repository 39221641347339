/**
 * Duck for basic information about annotations.
 *
 * @see Design.md in this directory for mor information about design considerations.
 */
import reducer from './reducers';
import * as annotationSelectors from './selectors';
import * as annotationTypes from './types';

export { annotationTypes, annotationSelectors };
export default reducer;
