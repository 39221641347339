import { FC, useCallback, useMemo } from 'react';
import { UseControllerProps } from 'react-hook-form';

import { gettext } from 'medialoopster/Internationalization';
import { Select, SelectOption } from 'medialoopster/components';

import useWidgetContext from '../hooks/useWidgetContext';

type Props = {
  readonly name: string;
  readonly label: string;
  readonly choices: ReadonlyArray<SelectOption>;
  readonly defaultValue: string | number | null;
  readonly allowNull: boolean;
  readonly disabled?: boolean;
  readonly requiredChoiceInfo?: string;
  readonly onChange?: (val: string | number | null) => void;
  readonly rules?: UseControllerProps['rules'];
};

const ChoiceWidget: FC<Props> = ({
  label,
  name,
  allowNull,
  choices,
  defaultValue,
  disabled = false,
  requiredChoiceInfo = gettext('Please select a value.'),
  rules = {},
  onChange,
}: Props) => {
  const {
    onChange: onWidgetChange,
    onBlur,
    value,
    error,
  } = useWidgetContext({
    name,
    defaultValue,
    rules: {
      validate: (v) => (v === null && !allowNull ? requiredChoiceInfo : true),
      ...rules,
    },
    disabled,
  });
  const selectChoices = useMemo(
    () => (allowNull ? [{ value: '', display: '---------' }, ...choices] : choices),
    [allowNull, choices],
  );
  const handleChange = useCallback(
    (newValue) => {
      const nextVal = newValue === '' ? null : newValue;
      onWidgetChange(nextVal);
      onChange?.(nextVal);
    },
    [onWidgetChange, onChange],
  );
  return (
    <Select
      id={name}
      label={label}
      choices={selectChoices}
      value={value?.toString() || ''}
      errorMsg={error}
      showError={!!error}
      onChange={handleChange}
      onBlur={onBlur}
      readOnly={disabled}
    />
  );
};

export default ChoiceWidget;
