import { FC, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { ChoiceWidget } from 'medialoopster/components';

import { addAssetsToCollectionActions } from '../../../state/modules/addAssetsToCollection';
import { productionsSelectors } from '../../../state/modules/productions';

const ProductionSelectWidget: FC = () => {
  const choices = useSelector(productionsSelectors.getWriteableProductionsWithProjectDeviceOptions);
  const dispatch = useDispatch();
  const validationRuleProduction = useMemo(
    () => ({
      validate: (val: string | null) => (val === null ? gettext('No production selected.') : true),
    }),
    [],
  );

  const defaultVal = useMemo(() => (choices.length === 1 ? choices[0].value : null), [choices]);

  useEffect(() => {
    if (defaultVal !== null) {
      dispatch(addAssetsToCollectionActions.findAddableCollection('', `${defaultVal}`));
    }
  }, [defaultVal, dispatch]);

  return (
    <ChoiceWidget
      allowNull={choices.length > 1}
      label={gettext('Production')}
      choices={choices}
      name="production"
      requiredChoiceInfo={gettext('No production selected.')}
      disabled={choices.length === 0}
      defaultValue={defaultVal}
      rules={validationRuleProduction}
    />
  );
};

export default ProductionSelectWidget;
