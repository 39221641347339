import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Select } from 'medialoopster/components';
import formatUserDisplayName from 'medialoopster/formatUserDisplayName';

import { usersTypes } from '../../state/modules/users';

type Props = {
  users: ReadonlyArray<usersTypes.User>;
  label: string;
  onChange: (value: string) => void;
};

const UserSelect: FC<Props> = ({ users, onChange, label }: Props) => (
  <Select
    id="select-user"
    name="select-user"
    label={label}
    onChange={onChange}
    choices={[
      { value: -1, display: gettext('All Users') },
      ...users.map((user) => ({ value: user.id, display: formatUserDisplayName(user) })),
    ]}
  />
);

export default UserSelect;
