import { Badge } from '@mui/material';
import { FC, memo, ComponentProps } from 'react';

import { ShareAsset as ShareIcon } from 'medialoopster/icons';

import UserAvatar from './UserAvatar';

export interface UserAvatarWithBadgeProps extends ComponentProps<typeof UserAvatar> {
  hasSharedAnnotation: boolean;
  isAnnotationOwner: boolean;
}

/**
 * UserAvatarWithBadge component.
 *
 * The component is responsible for adding share badge to avatar.
 *
 * @param firstName The first name of the user.
 * @param lastName The last name of the user.
 * @param hasSharedAnnotation Has user shared the corresponding annotation.
 * @param isAnnotationOwner Is user the owner of the annotation.
 */
const UserAvatarWithBadge: FC<UserAvatarWithBadgeProps> = ({
  firstName,
  lastName,
  hasSharedAnnotation,
  isAnnotationOwner,
}) => {
  return (
    <>
      {hasSharedAnnotation && isAnnotationOwner ? (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <ShareIcon style={{ width: '14px', height: '14px' }} fontSize={'inherit'} />
          }
        >
          <UserAvatar firstName={firstName} lastName={lastName} />
        </Badge>
      ) : (
        <UserAvatar firstName={firstName} lastName={lastName} />
      )}
    </>
  );
};

/**
 * Memoized UserAvatarWithBadge component.
 */
export default memo(UserAvatarWithBadge);
