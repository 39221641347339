/**
 * This package and its submodule implement the React components that Decorate TranscriptMetadataNode.
 *
 * The component in this module displays the metadata of a TranscriptTimeBlockNode.
 */
import { Box, Stack, Typography } from '@mui/material';
import { NodeKey } from 'lexical';
import React from 'react';

import { Decimal } from '../../decimal';
import { Speaker } from './Speaker';
import { TimeCodeLink } from './TimeCodeLink';

/**
 * The Props of the MetadataDisplay component.
 */
export interface MetadataProps {
  /**
   * The name of the speaker of the time block.
   */
  speakerName: string;
  /**
   * The start time of the time block
   */
  startTime: Decimal;
  /**
   * The NodeKey of the parent TranscripTimeBlockNode of the TranscriptMetadataNode.
   */
  timeBlockNodeKey: NodeKey;
}

/**
 * This is the React component that is used by TranscriptMetadataNode to display the
 * metadata of a TranscriptTimeBlockNode.
 *
 * The component displays the speaker name and the start time code of the time block.
 * The component also offers the user to navigate to the start time code of the time block
 * by clicking the time code and to change the speaker name via ChangeSpeakerModal.
 *
 * @param speakerName The name of the speaker of the time block.
 * @param startTime The start time of the time block
 * @param timeBlockNodeKey The NodeKey of the TranscriptTimeBlockNode.
 */
export const MetadataDisplay: React.FC<MetadataProps> = ({
  speakerName,
  startTime,
  timeBlockNodeKey,
}) => {
  return (
    <Typography variant="subtitle2">
      <Stack direction="row" alignItems="start" justifyContent="space-between" flexWrap="nowrap">
        <Speaker name={speakerName} timeBlockNodeKey={timeBlockNodeKey} />
        <Box textAlign="end" flexShrink="0">
          <TimeCodeLink time={startTime} />
        </Box>
      </Stack>
    </Typography>
  );
};

export default MetadataDisplay;
