import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { AddComment } from 'medialoopster/icons';

import { Annotation } from '../../../../state/modules/annotation/types';
import AnnotationsList from './AnnotationsList';

/**
 * Component to render a Placeholder if no Annotations are present for a video asset.
 */
const AnnotationPlaceholder: FC = () => (
  <Stack p="1rem" justifyContent="center" alignItems="center">
    <AddComment sx={{ fontSize: 90, color: (theme) => theme.palette.text.disabled }} />
    <Typography align="center" sx={{ color: (theme) => theme.palette.text.disabled, fontSize: 28 }}>
      {gettext('Create annotations')}
    </Typography>
  </Stack>
);

/**
 *  Component to render the body of the AnnotationsPanel
 *
 * The Body either contains the list of Annotations or a Placeholder if the list of filtered
 * annotations is empty. The list of annotations will be scrollable, if there are too many
 * annotations to display at once.
 *
 * @param annotations The list of annotations to display.
 * @constructor
 */
const AnnotationsPanelBody: FC<{ annotations: Annotation[] }> = ({ annotations }) => (
  <Box overflow="auto" flexShrink="1">
    {annotations.length > 0 ? (
      <AnnotationsList annotations={annotations} />
    ) : (
      <AnnotationPlaceholder />
    )}
  </Box>
);

export default AnnotationsPanelBody;
