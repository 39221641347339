import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { ShareAsset } from 'medialoopster/icons';

import StatusElement from '../StatusElement';

interface IsSharedStatusProps {
  readonly showLabel: boolean;
  readonly sharedWith: ReadonlyArray<string>;
}

const renderIcon = () => <ShareAsset style={{ fontSize: '1.2rem' }} />;

const IsSharedStatus: FC<IsSharedStatusProps> = ({
  showLabel,
  sharedWith,
}: IsSharedStatusProps) => {
  return (
    <StatusElement
      renderIcon={renderIcon}
      label={gettext('Asset is shared')}
      showLabel={showLabel}
      title={`${gettext('Shared with productions')}\n\n${sharedWith.join('\n')}`}
    />
  );
};

export default IsSharedStatus;
