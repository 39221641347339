import { ActionsMetadataV2 } from 'medialoopster/rest';

import { Asset } from '../../types/asset/unionTypes';
import {
  ASSETS_SHARED,
  AssetsShared,
  CLEAR_SHARE_ASSETS_ERRORS,
  ClearShareAssetsErrors,
  CLOSE_SHARE_ASSETS_MODAL,
  CloseShareAssetsModal,
  DESELECT_PRODUCTION,
  DeselectProduction,
  FETCH_SHARE_ASSETS_OPTIONS,
  FetchShareAssetsOptions,
  OPEN_SHARE_ASSETS_MODAL,
  OpenShareAssetsModal,
  SELECT_PRODUCTION,
  SelectProduction,
  SET_DATE_WITHDRAW,
  SET_SHARE_ASSETS_ERRORS,
  SetDateWithdraw,
  SetShareAssetsErrors,
  SHARE_ASSETS,
  SHARE_ASSETS_OPTIONS_LOADED,
  ShareAssets,
  ShareAssetsErrors,
  ShareAssetsOptionsLoaded,
} from './types';

export const openShareAssetsModal = (assets: ReadonlyArray<Asset>): OpenShareAssetsModal => ({
  type: OPEN_SHARE_ASSETS_MODAL,
  payload: {
    assets,
  },
});

export const selectProduction = (productionId: number): SelectProduction => ({
  type: SELECT_PRODUCTION,
  payload: {
    productionId,
  },
});

export const deselectProduction = (productionId: number): DeselectProduction => ({
  type: DESELECT_PRODUCTION,
  payload: {
    productionId,
  },
});

export const setDateWithdraw = (dateWithdraw: string): SetDateWithdraw => ({
  type: SET_DATE_WITHDRAW,
  payload: {
    dateWithdraw,
  },
});

export const closeShareAssetsModal = (): CloseShareAssetsModal => ({
  type: CLOSE_SHARE_ASSETS_MODAL,
});

export const fetchShareAssetsOptions = (): FetchShareAssetsOptions => ({
  type: FETCH_SHARE_ASSETS_OPTIONS,
});

export const shareAssetsOptionsLoaded = (actions: ActionsMetadataV2): ShareAssetsOptionsLoaded => ({
  type: SHARE_ASSETS_OPTIONS_LOADED,
  payload: {
    actions,
  },
});

export const shareAssets = (
  productionIds: ReadonlyArray<number>,
  dateWithdraw: string | null,
  assets: ReadonlyArray<Asset>,
): ShareAssets => ({
  type: SHARE_ASSETS,
  payload: {
    productionIds,
    dateWithdraw,
    assets,
  },
});

export const assetsShared = (assetCount: number): AssetsShared => ({
  type: ASSETS_SHARED,
  payload: {
    assetCount,
  },
});

export const setShareAssetsErrors = (errors: Partial<ShareAssetsErrors>): SetShareAssetsErrors => ({
  type: SET_SHARE_ASSETS_ERRORS,
  payload: {
    errors,
  },
});

export const clearShareAssetsErrors = (): ClearShareAssetsErrors => ({
  type: CLEAR_SHARE_ASSETS_ERRORS,
});
