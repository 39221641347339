import { Grid } from '@mui/material';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { Select } from 'medialoopster/components';
import { SelectOption } from 'medialoopster/components/Select';

import { productionsActions, productionsSelectors } from '../../state/modules/productions';

export interface ProductionOption extends SelectOption {
  readonly id: number;
}

type Props = {
  readonly productions: ReadonlyArray<ProductionOption>;
  readonly devices: ReadonlyArray<SelectOption>;
  readonly deviceFilter: ReadonlyArray<string>;
  readonly onChangeProductionFilter: (productionId: number) => void;
  readonly onChangeDeviceFilter: (deviceFilter: ReadonlyArray<string>) => void;
};

const SearchOptions: FC<Props> = ({
  productions,
  devices,
  deviceFilter,
  onChangeProductionFilter,
  onChangeDeviceFilter,
}: Props) => {
  const dispatch = useDispatch();
  const selectedProductionURL = useSelector(productionsSelectors.getSelectedProductionURL);
  useEffect(() => {
    dispatch(
      productionsActions.selectProduction(
        localStorage.getItem('mlProductionURL') ||
          (productions.length === 1 ? String(productions[0].value) : null) ||
          null,
      ),
    );
  }, [dispatch, productions]);
  return (
    <>
      <Grid item md={6}>
        <Select
          id="search-production-select"
          name="search-production-select"
          label={gettext('Production')}
          aria-label={gettext('Production')}
          choices={productions}
          value={selectedProductionURL || ''}
          onChange={(value: string) => {
            const productionURL = value === '' ? null : value;
            dispatch(productionsActions.selectProduction(productionURL));
            const production = productions.find((option) => option.value === productionURL);
            onChangeProductionFilter(production ? production.id : 0);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Select
          id="search-device-select"
          name="search-device-select"
          label={gettext('Device')}
          aria-label={gettext('Device')}
          choices={devices}
          value={deviceFilter.length > 0 ? JSON.stringify(deviceFilter) : ''}
          onChange={(value: string) => {
            onChangeDeviceFilter(value ? JSON.parse(value) : []);
          }}
        />
      </Grid>
    </>
  );
};

export default SearchOptions;
