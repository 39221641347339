import { SvgIconComponent } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { ReactElement } from 'react';

export type IconRadioButtonGroupOption<T extends string> = {
  readonly Icon: SvgIconComponent;
  readonly value: T;
  readonly title: string;
  readonly disabled?: boolean;
};

type Props<V extends string> = {
  readonly options: ReadonlyArray<IconRadioButtonGroupOption<V>>;
  readonly value: V;
  readonly onChange: (value: V) => void;
};

const IconRadioButtonGroup = <V extends string>({
  options,
  value,
  onChange,
}: Props<V>): ReactElement => {
  return (
    <ToggleButtonGroup
      size="small"
      value={value}
      exclusive
      onChange={(_event, newValue) => {
        if (!newValue) {
          return;
        }
        onChange(newValue);
      }}
    >
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          size="small"
          aria-label={option.title}
          value={option.value}
          disabled={option.disabled}
          style={{ pointerEvents: 'auto' }}
        >
          <Tooltip title={option.title}>{<option.Icon fontSize="small" />}</Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default IconRadioButtonGroup;
