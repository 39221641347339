import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ChangeEventHandler, FC, MouseEvent } from 'react';

import { gettext, interpolate, ngettext } from 'medialoopster/Internationalization';

import { useCheckboxTablePaginationContext } from '../CheckboxTableContextProvider';

const useStyles = makeStyles((theme: Theme) => ({
  highlight: {
    color: theme.palette.info.dark,
  },
  paginationSpacer: {
    flex: 'unset',
  },
  paginationCaption: {
    display: 'contents',
  },
  paginationCaptionOverride: {
    // paginationCaption is used for multiple components in pagination -> manual override for different instances
    whiteSpace: 'nowrap',
  },
  paginationSelect: {
    marginRight: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
}));

interface Props {
  readonly id?: string;
  readonly totalCount: number;
  readonly onChangePage: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  readonly onChangeRowsPerPage: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  readonly rowsPerPage: number;
  readonly currentPage: number;
  readonly rowsPerPageOptions?: number[];
  readonly disabledTableInfo?: string;
  readonly emptyChoicesInfo?: string;
}

const InternalPagination: FC<Props> = ({
  id,
  totalCount,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPage = 10,
  currentPage = 0,
  rowsPerPageOptions = [10, 25, 50, 100],
  disabledTableInfo = '',
  emptyChoicesInfo = '',
}: Props) => {
  const classes = useStyles();
  const { numSelectedChoices, disabled } = useCheckboxTablePaginationContext();

  return (
    <TablePagination
      id={id}
      classes={{
        spacer: classes.paginationSpacer,
        selectLabel: classes.paginationCaption,
        displayedRows: classes.paginationCaption,
        selectRoot: classes.paginationSelect,
      }}
      component="div"
      rowsPerPageOptions={rowsPerPageOptions}
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => (
        <Typography
          data-testid="labelDisplayedRows"
          className={classes.paginationCaptionOverride}
          color="inherit"
          variant="body2"
          component="span"
        >
          {count !== -1
            ? interpolate(gettext('%(from)s-%(to)s of %(count)s'), { from, to, count })
            : interpolate(gettext('%(from)s-%(to)s of more than %(to)s'), { from, to })}
        </Typography>
      )}
      labelRowsPerPage={
        <Grid container component="span" justifyContent="space-between">
          {numSelectedChoices > 0 && (
            <Grid item component="span">
              <Typography
                className={classes.highlight}
                color="inherit"
                variant="body2"
                component="span"
              >
                {interpolate(
                  ngettext(
                    '%(numSelectedChoices)s selected',
                    '%(numSelectedChoices)s selected',
                    numSelectedChoices,
                  ),
                  { numSelectedChoices },
                )}
              </Typography>
            </Grid>
          )}
          {numSelectedChoices === 0 && (
            <Grid item component="span">
              <Typography
                className={classes.highlight}
                color="inherit"
                variant="body2"
                component="span"
              >
                {disabled ? disabledTableInfo : emptyChoicesInfo}
              </Typography>
            </Grid>
          )}
          <Grid item component="span">
            <Typography color="inherit" variant="body2" component="span">
              {gettext('Rows per page:')}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

export default InternalPagination;
