import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import {
  RESTEpicDependencies,
  ResourceLoadedAction,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';

import { FETCH_KEYWORDCATEGORY, FetchKeywordCategory } from './types';

export const fetchKeywordCategoryEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  Record<string, unknown>,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, FetchKeywordCategory['type'], FetchKeywordCategory>(FETCH_KEYWORDCATEGORY),
    mergeMap(({ payload: { url } }) => fetchCollection(url)),
  );

export default combineEpics(fetchKeywordCategoryEpic);
