export type DeepRequired<T> = {
  [P in keyof T]-?: DeepRequired<T[P]>;
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type DeepReadonly<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export type DeepMutable<T> = {
  -readonly [P in keyof T]: DeepMutable<T[P]>;
};

export type DeepReadonlyArray<T> = ReadonlyArray<DeepReadonly<T>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReadonlyRecord<K extends keyof any, P> = {
  readonly [T in K]: P;
};

export type SomeNonNullable<T, K extends keyof T> = T & { [P in K]: NonNullable<T[P]> };

export type NumberOrString = number | string;

type JsonPrimitive = string | number | boolean | null;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface JsonMap extends Record<string, JsonPrimitive | JsonArray | JsonMap> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface JsonArray extends Array<JsonPrimitive | JsonArray | JsonMap> {}
export type Json = JsonPrimitive | JsonMap | JsonArray;

export type ValueOf<T> = T[keyof T];

export interface FormatSettings {
  DATETIME_FORMAT: string;
  DATETIME_INPUT_FORMATS: string[];
  DATE_FORMAT: string[];
  DATE_INPUT_FORMATS: string[];
  DECIMAL_SEPARATOR: string;
  FIRST_DAY_OF_WEEK: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  MONTH_DAY_FORMAT: string;
  NUMBER_GROUPING: number;
  SHORT_DATETIME_FORMAT: string;
  SHORT_DATE_FORMAT: string;
  THOUSAND_SEPARATOR: string;
  TIME_FORMAT: string;
  TIME_INPUT_FORMATS: string[];
  YEAR_MONTH_FORMAT: string[];
}

export enum TaskStatus {
  PENDING = 0,
  STARTED = 1,
  SUCCESS = 2,
  FAILURE = 3,
  RETRY = 4,
  REVOKED = 5,
  OTHER = 6,
}

/**
 * Fix Array.isArray type-narrowing issue with ReadonlyArray instances.
 */
export const isReadonlyArray = (arg: unknown): arg is ReadonlyArray<unknown> => Array.isArray(arg);
