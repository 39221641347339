import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  ResourceLoadedAction,
  getLinkHref,
  ReceiveRootResource,
  RECEIVE_ROOT_RESOURCE,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
  mlRel,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { FetchDevices } from './types';

export const fetchRootResourceEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const url = getLinkHref(root, mlRel('devices'));
      if (!url) {
        return EMPTY;
      }
      return fetchCollection(url, undefined, undefined, {
        Accept: 'application/hal+json; version=3',
        'Content-Type': 'application/hal+json; version=3',
      });
    }),
  );

export default combineEpics<
  Action,
  ResourceLoadedAction | FetchDevices | UnauthorizedRequestAction,
  RootState
>(fetchRootResourceEpic);
