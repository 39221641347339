import {
  BaseResource,
  CollectionResourceMap,
  Link,
  Resource,
  ResourceMap,
} from 'medialoopster/rest';

import { TranscriptDiff } from '../../../sections/DetailsSection/Transcript/Editor/diff';
import { AssetType } from '../../types/asset/baseTypes';
import { Annotation } from '../annotation/types';
import { AudioAsset } from '../audio/types';
import { ImageAsset } from '../image/types';
import { AssetCollection } from '../rest/collection/types';
import { Sequence, Shot, VideoAsset } from '../video/types';

export const SET_CURRENT_ASSET = 'FRONTEND/DETAIL_SECTION/SET_CURRENT_ASSET';
export const REMOVE_ASSET_FROM_COLLECTION = 'FRONTEND/DETAIL_SECTION/REMOVE_ASSET_FROM_COLLECTION';
export const SELECT_CLIP = 'FRONTEND/DETAIL_SECTION/SELECT_CLIP';
export const LOCK_CURRENT_ASSET = 'FRONTEND/DETAIL_SECTION/LOCK_CURRENT_ASSET';
export const UNLOCK_CURRENT_ASSET = 'FRONTEND/DETAIL_SECTION/UNLOCK_CURRENT_ASSET';
export const TOGGLE_EDIT_MODE = 'FRONTEND/DETAIL_SECTION/TOGGLE_EDIT_MODE';
export const TOGGLE_VIEW = 'FRONTEND/DETAIL_SECTION/TOGGLE_VIEW';
export const SET_EMBEDDED_ASSET = 'FRONTEND/DETAIL_SECTION/SET_EMBEDDED_ASSET';
export const EDIT_ASSET = 'FRONTEND/DETAIL_SECTION/EDIT_ASSET';
export const SELECT_SHOT_RANGE_START = 'FRONTEND/DETAIL_SECTION/SELECT_SHOT_RANGE_START';
export const SELECT_SHOT_RANGE_END = 'FRONTEND/DETAIL_SECTION/SELECT_SHOT_RANGE_END';
export const CREATE_SHOT = 'FRONTEND/DETAIL_SECTION/CREATE_SHOT';
export const DELETE_SHOT = 'FRONTEND/DETAIL_SECTION/DELETE_SHOT';
export const CREATE_SEQUENCE = 'FRONTEND/DETAIL_SECTION/CREATE_SEQUENCE';
export const DELETE_SEQUENCE = 'FRONTEND/DETAIL_SECTION/DELETE_SEQUENCE';
export const EDIT_SEQUENCE = 'FRONTEND/DETAIL_SECTION/EDIT_SEQUENCE';
export const EDIT_SEQUENCE_SHOTS = 'FRONTEND/DETAIL_SECTION/EDIT_SEQUENCE_SHOTS';
export const FETCH_SEQUENCE_OPTIONS = 'FRONTEND/DETAIL_SECTION/FETCH_SEQUENCE_OPTIONS';
export const CHANGE_POSTER_FRAME = 'FRONTEND/DETAIL_SECTION/CHANGE_POSTER_FRAME';
export const UPDATE_KEYWORDS = 'FRONTEND/DETAIL_SECTION/UPDATE_KEYWORDS';
export const FETCH_SHOT_OPTIONS = 'FRONTEND/DETAIL_SECTION/FETCH_SHOT_OPTIONS';
export const EDIT_ANCESTOR = 'FRONTEND/DETAIL_SECTION/EDIT_ANCESTOR';
export const LOAD_ANCESTOR = 'FRONTEND/DETAIL_SECTION/LOAD_ANCESTOR';
export const LOAD_ANCESTOR_ERROR = 'FRONTEND/DETAIL_SECTION/LOAD_ANCESTOR_ERROR';
export const REFRESH_CURRENT_ASSET = 'FRONTEND/DETAIL_SECTION/REFRESH_CURRENT_ASSET';
export const TOGGLE_ANNOTATIONS = 'FRONTEND/DETAIL_SECTION/TOGGLE_ANNOTATIONS';
export const SHOW_ALL_ANNOTATIONS = 'FRONTEND/DETAIL_SECTION/SHOW_ALL_ANNOTATIONS';
export const FETCH_ANNOTATIONS = 'FRONTEND/DETAILS_SECTION/FETCH_ANNOTATIONS';
export const ADD_ANNOTATION = 'FRONTEND/DETAILS_SECTION/ADD_ANNOTATION';
export const EDIT_ANNOTATION = 'FRONTEND/DETAIL_SECTION/EDIT_ANNOTATION';
export const DELETE_ANNOTATION = 'FRONTEND/DETAIL_SECTION/DELETE_ANNOTATION';
export const TOGGLE_EDITING_ANNOTATION = 'FRONTEND/DETAIL_SECTION/TOGGLE_EDITING_ANNOTATION';
export const PUBLISH_ALL_ANNOTATIONS = 'FRONTEND/DETAIL_SECTION/PUBLISH_ALL_ANNOTATIONS';
export const UNPUBLISH_ALL_ANNOTATIONS = 'FRONTEND/DETAIL_SECTION/UNPUBLISH_ALL_ANNOTATIONS';
export const DELETE_ALL_ANNOTATIONS = 'FRONTEND/DETAIL_SECTION/DELETE_ALL_ANNOTATIONS';

//-------------
// Action Types
//-------------

export interface SetCurrentAsset {
  readonly type: typeof SET_CURRENT_ASSET;
  readonly payload: {
    readonly href: string | null;
    readonly type: AssetType | null;
  };
}

export interface RemoveAssetFromCollection {
  readonly type: typeof REMOVE_ASSET_FROM_COLLECTION;
  readonly payload: {
    readonly collectionURI: string;
    readonly removeURI: string;
    readonly linkedAssetCollectionHref: string;
    readonly assetHref: string;
    readonly shareURL: string;
  };
}

export interface SetEmbeddedAsset {
  readonly type: typeof SET_EMBEDDED_ASSET;
  readonly payload: {
    readonly type: AssetType;
    readonly href: string;
  };
}

export interface SelectClip {
  readonly type: typeof SELECT_CLIP;
  readonly payload: {
    readonly assetHref: string;
    readonly timecodeStart: number;
    readonly timecodeEnd: number;
  };
}

export interface LockCurrentAsset {
  readonly type: typeof LOCK_CURRENT_ASSET;
  readonly payload: {
    readonly assetType: AssetType;
    readonly assetHref: string;
    readonly userId: number;
    readonly userHref: string;
  };
}

export interface UnlockCurrentAsset {
  readonly type: typeof UNLOCK_CURRENT_ASSET;
  readonly payload: {
    readonly assetType: AssetType;
    readonly assetHref: string;
  };
}

export interface ToggleEditMode {
  readonly type: typeof TOGGLE_EDIT_MODE;
}

export interface ToggleView {
  readonly type: typeof TOGGLE_VIEW;
  readonly payload: {
    readonly view: ViewType;
    readonly sidebarType: ViewSidebarType;
  };
}

export interface ChangePosterFrame {
  readonly type: typeof CHANGE_POSTER_FRAME;
  readonly payload: {
    readonly asset: VideoAsset;
    readonly frame: number;
  };
}

export interface SelectShotRangeStart {
  readonly type: typeof SELECT_SHOT_RANGE_START;
  readonly payload: {
    readonly shot: Shot;
  };
}

export interface SelectShotRangeEnd {
  readonly type: typeof SELECT_SHOT_RANGE_END;
  readonly payload: {
    readonly shot: Shot;
  };
}

export interface CreateShot {
  readonly type: typeof CREATE_SHOT;
  readonly payload: {
    readonly asset: VideoAsset;
    readonly timecodeStart: number;
    readonly timecodeEnd: number;
  };
}

export interface DeleteShot {
  readonly type: typeof DELETE_SHOT;
  readonly payload: {
    readonly shot: Shot;
  };
}

export interface CreateSequence {
  readonly type: typeof CREATE_SEQUENCE;
  readonly payload: {
    readonly asset: VideoAsset;
    readonly timecodeStart: number;
    readonly timecodeEnd: number;
  };
}

export interface DeleteSequence {
  readonly type: typeof DELETE_SEQUENCE;
  readonly payload: {
    readonly sequence: Sequence;
  };
}

export interface UpdateKeywords {
  readonly type: typeof UPDATE_KEYWORDS;
  readonly payload: {
    readonly resource: ImageAsset | AudioAsset | Shot | Sequence;
    readonly keywords: ReadonlyArray<string>;
  };
}

export interface FetchShotOptions {
  readonly type: typeof FETCH_SHOT_OPTIONS;
  readonly payload: {
    readonly shotURI: string;
  };
}

export interface RefreshCurrentAsset {
  readonly type: typeof REFRESH_CURRENT_ASSET;
}

export interface EditAncestor {
  readonly type: typeof EDIT_ANCESTOR;
  readonly payload: {
    ancestorHref: string;
  };
}

export interface LoadAncestor {
  readonly type: typeof LOAD_ANCESTOR;
  readonly payload: {
    ancestorHref: string;
  };
}

export interface LoadAncestorError {
  readonly type: typeof LOAD_ANCESTOR_ERROR;
  readonly payload: {
    ancestorHref: string;
    error: string;
  };
}

export interface ToggleAnnotations {
  readonly type: typeof TOGGLE_ANNOTATIONS;
  readonly payload: {
    readonly on?: boolean;
  };
}

export interface ShowAllAnnotationsAction {
  readonly type: typeof SHOW_ALL_ANNOTATIONS;
  readonly payload: {
    readonly showAll: boolean;
  };
}

export interface FetchAnnotationsAction {
  readonly type: typeof FETCH_ANNOTATIONS;
}

export interface AddAnnotationAction {
  readonly type: typeof ADD_ANNOTATION;
  readonly payload: {
    readonly frame: number;
    readonly text: string;
  };
}

export type AnnotationEditableFields = Readonly<Partial<Pick<Annotation, 'is_public' | 'text'>>>;

export interface EditAnnotationAction {
  readonly type: typeof EDIT_ANNOTATION;
  readonly payload: {
    readonly uri: string;
    readonly editFields: AnnotationEditableFields;
  };
}

export interface DeleteAnnotationAction {
  readonly type: typeof DELETE_ANNOTATION;
  readonly payload: {
    readonly annotation: Annotation;
  };
}

export interface ToggleEditingAnnotation {
  readonly type: typeof TOGGLE_EDITING_ANNOTATION;
  readonly payload: {
    readonly annotationURI: string;
  };
}

export interface PublishAllAnnotations {
  readonly type: typeof PUBLISH_ALL_ANNOTATIONS;
}

export interface UnpublishAllAnnotations {
  readonly type: typeof UNPUBLISH_ALL_ANNOTATIONS;
}

export interface DeleteAllAnnotations {
  readonly type: typeof DELETE_ALL_ANNOTATIONS;
}

//---------------
// Resource Types
//---------------

export type ViewType = 'all' | 'player' | 'search';
export type ViewSidebarType = 'default' | 'transcript';

export interface ViewLayoutState {
  readonly view: ViewType;
  readonly sidebarType: ViewSidebarType;
}

export enum LicenseDisplayStatus {
  GREY = 0,
  RED = 1,
  YELLOW = 2,
  GREEN = 3,
}

export interface License extends Resource<'license'> {
  readonly name: string;
  readonly display_status_id: LicenseDisplayStatus;
  readonly show_in_browser: boolean;
}

export interface Licensor extends Resource<'licensor'> {
  readonly name: string;
  readonly long_name: string;
  readonly note: string;
  readonly price_per_minute: string;
  readonly show_in_browser: boolean;
}

export interface ValidationRule extends Resource<'validationrule'> {
  readonly name: string;
  readonly id: number;
  readonly description: string;
  readonly rule: string;
}

export interface CustomMetadataSet extends Resource<'custommetadataset'> {
  readonly name: string;
  readonly order: number;
  readonly model_name: string;
}

export interface CustomMetadata extends Resource<'custommetadata'> {
  readonly key: string;
  readonly value: string;
  readonly input_type: string;
  readonly order: number;
  readonly allow_links: boolean;
}

export interface CustomMetadataField extends CustomMetadata {
  choices: ReadonlyArray<string>;
  rule: string;
}

export interface CustomMetadataSetWithFields extends CustomMetadataSet {
  readonly fields: ReadonlyArray<CustomMetadataField>;
}

export interface CustomMetadataChoice extends Resource<'custommetadatachoice'> {
  readonly id: number;
  readonly value: string;
  readonly order: number;
  readonly custom_metadata: number;
}

export interface AnnotationPostResource extends BaseResource {
  readonly time: number;
  readonly text: string;
  readonly is_public: boolean;
  readonly _links: {
    'http://medialoopster.com/relations/asset': Link;
  };
}

//------------
// State Types
//------------

export interface CurrentAssetState {
  readonly type: AssetType | null;
  readonly href: string | null;
}

export interface EmbeddedAssetState {
  readonly type: AssetType | null;
  readonly href: string | null;
}

export interface SelectedShotRange {
  readonly start: Shot;
  readonly end: Shot;
}

export interface ShotRangeState {
  readonly start: number;
  readonly end: number;
}

export type LoadAncestorErrorState = Record<string, string>;

export interface DetailsRootState {
  readonly currentAsset: CurrentAssetState;
  readonly embeddedAsset: EmbeddedAssetState;
  readonly licenseCollectionLink: Link | null;
  readonly licenseCollections: CollectionResourceMap<License>;
  readonly licenses: ResourceMap<License>;
  readonly licensorCollectionLink: Link | null;
  readonly licensorCollections: CollectionResourceMap<Licensor>;
  readonly licensors: ResourceMap<Licensor>;
  readonly customMetadataSetCollectionLink: Link | null;
  readonly customMetadataSetCollections: CollectionResourceMap<CustomMetadataSet>;
  readonly customMetadataSets: ResourceMap<CustomMetadataSet>;
  readonly customMetadata: ResourceMap<CustomMetadata>;
  readonly layout: ViewLayoutState;
  readonly selectedShotRange: ShotRangeState | null;
  readonly customMetadataChoiceCollectionLink: Link | null;
  readonly customMetadataChoiceCollections: CollectionResourceMap<CustomMetadataChoice>;
  readonly customMetadataChoices: ResourceMap<CustomMetadataChoice>;
  readonly validationrulesCollectionLink: Link | null;
  readonly validationrulesCollections: CollectionResourceMap<ValidationRule>;
  readonly validationrules: ResourceMap<ValidationRule>;
  readonly loadAncestorErrors: LoadAncestorErrorState;
  readonly showAnnotations: boolean;
  readonly showAllAnnotations: boolean;
  readonly editingAnnotation: string | null;
  readonly currentTranscript: CurrentTranscriptState;
}

export type VideoEditFields = Readonly<
  Partial<
    Pick<
      VideoAsset,
      | 'name'
      | 'meta_field_store'
      | 'date_del'
      | 'description'
      | 'date_crt'
      | 'transcript'
      | 'abstract'
    >
  >
>;

export type SequenceEditFields = Readonly<
  Partial<
    Pick<Sequence, 'name' | 'description' | 'rights_rnb' | 'license' | 'licensor' | 'location_city'>
  >
>;

export type ImageEditFields = Readonly<
  Partial<
    Pick<
      ImageAsset,
      | 'name'
      | 'meta_field_store'
      | 'date_del'
      | 'description'
      | 'date_crt'
      | 'make'
      | 'model'
      | 'date_orig'
      | 'user_comment'
    >
  >
>;

export type AudioEditFields = Readonly<
  Partial<
    Pick<
      AudioAsset,
      | 'name'
      | 'meta_field_store'
      | 'date_del'
      | 'description'
      | 'date_crt'
      | 'artist'
      | 'title'
      | 'album'
      | 'genre'
      | 'license_info'
      | 'date'
    >
  >
>;

type MetaFieldStore = {
  _embedded: Pick<AssetCollection['_embedded'], 'meta_field_store'>;
};
export type CollectionEditFields = Readonly<
  Partial<Pick<AssetCollection, 'name' | 'date_del' | 'description' | 'date_crt'> & MetaFieldStore>
>;

export interface EditAsset {
  readonly type: typeof EDIT_ASSET;
  readonly payload: {
    readonly assetType: AssetType | 'sequence';
    readonly assetHref: string;
    readonly updates:
      | VideoEditFields
      | SequenceEditFields
      | CollectionEditFields
      | AudioEditFields
      | ImageEditFields;
  };
}

export interface FetchSequenceOptions {
  readonly type: typeof FETCH_SEQUENCE_OPTIONS;
  readonly payload: {
    sequenceURI: string;
  };
}

export interface RemoveAssetFromLinkItem {
  readonly asset_id: number;
  readonly type: string;
}

export interface AncestorDetails {
  shot: Shot | undefined;
  video: VideoAsset | undefined;
  error: string | undefined;
}

export type CollectionFileType = 'Video' | 'Audio' | 'Image' | 'Other';

export interface CollectionFile {
  readonly path: string;
  readonly folder: string;
  readonly name: string;
  readonly type: CollectionFileType;
}

// region current transcript

/**
 * Type-tag for FetchCurrentTranscriptAction
 */
export const FETCH_CURRENT_TRANSCRIPT =
  'FRONTEND/DETAIL_SECTION/TRANSCRIPT/FETCH_CURRENT_TRANSCRIPT';

/**
 * Action that fetches the transcript for the current asset.
 */
export interface FetchCurrentTranscriptAction {
  readonly type: typeof FETCH_CURRENT_TRANSCRIPT;
}

/**
 * Type-tag for SaveTranscriptAction
 */
export const SAVE_TRANSCRIPT = 'FRONTEND/DETAIL_SECTION/SAVE_TRANSCRIPT';

/**
 * Action that saves the transcript for the current asset.
 */
export interface SaveTranscriptAction {
  readonly type: typeof SAVE_TRANSCRIPT;
  readonly payload: {
    readonly diff: TranscriptDiff;
  };
}

/**
 * Type-tag for SetTranscriptState
 */
export const SET_TRANSCRIPT_AVAILABILITY = 'FRONTEND/DETAIL_SECTION/SET_TRANSCRIPT_AVAILABILITY';

/**
 * Possible states of availability of the current transcript.
 *
 * The transcript is in the `loading` state while there are transcript-, unit- or speaker-resources
 * waiting to be fetched.
 *
 * The transcript is in the `ready` state when all units and speakers have been fetched.
 *
 * Teh transcript is in the ´no-transcript` state when the current asset has no transcript.
 */
export type TranscriptAvailability = 'ready' | 'loading' | 'no-transcript';

/**
 * Action that sets the availability state of the current transcript.
 */
export interface SetTranscriptAvailabilityAction {
  readonly type: typeof SET_TRANSCRIPT_AVAILABILITY;
  readonly payload: {
    readonly availability: TranscriptAvailability;
  };
}

/**
 * The current transcript redux state slice.
 */
export interface CurrentTranscriptState {
  availability: TranscriptAvailability;
}

// endregion
