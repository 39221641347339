/**
 * A small shim-Module to decouple the Editor from `big.js` and help with `big.js` strict semantic.
 *
 * The module exposes aliases the `Big` type and helper-functions working on them. There are 2
 * reasons behind this module:
 *   1. Offer a Refactoring target for changes and a little less direct coupling to `big.js`
 *   2. Configure `big.js` to be strict to help detect unintentional precision loss.
 */
import Big, { Comparison, RoundingMode } from 'big.js';

/**
 * A Big copy that will be configured as strict.
 */
const StrictBig: typeof Big = Big();
StrictBig.strict = true;

/**
 * A Big copy that will be configured as not strict.
 */
const LaxBig: typeof Big = Big();
LaxBig.strict = false;

/**
 * Valid sources of a value for Decimal.
 */
export type DecimalSource = Decimal | string;

/**
 * Type alias for Big in places that want to use the strict configuration.
 *
 * Note: Remember to memoize values of Decimal where identity is important!
 */
export interface Decimal extends Big {
  constructor: {
    strict: true;
  };

  /** Returns a Decimal number whose value is the absolute value, i.e. the magnitude, of this Decimal number. */
  abs(): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number plus n - alias for .plus().
   *
   * @throws `NaN` if n is invalid.
   */
  add(n: DecimalSource): Decimal;
  /**
   * Compare the values.
   *
   * @throws `NaN` if n is invalid.
   */
  cmp(n: DecimalSource): Comparison;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number divided by n.
   *
   * If the result has more fraction digits than is specified by Decimal.DP, it will be rounded to Decimal.DP decimal places using rounding mode Decimal.RM.
   *
   * @throws `NaN` if n is invalid.
   * @throws `±Infinity` on division by zero.
   * @throws `NaN` on division of zero by zero.
   */
  div(n: DecimalSource): Decimal;
  /**
   * Returns true if the value of this Decimal equals the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  eq(n: DecimalSource): boolean;
  /**
   * Returns true if the value of this Decimal is greater than the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  gt(n: DecimalSource): boolean;
  /**
   * Returns true if the value of this Decimal is greater than or equal to the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  gte(n: DecimalSource): boolean;
  /**
   * Returns true if the value of this Decimal is less than the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  lt(n: DecimalSource): boolean;
  /**
   * Returns true if the value of this Decimal is less than or equal to the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  lte(n: DecimalSource): boolean;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number minus n.
   *
   * @throws `NaN` if n is invalid.
   */
  minus(n: DecimalSource): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number modulo n, i.e. the integer remainder of dividing this Decimal number by n.
   *
   * The result will have the same sign as this Decimal number, and it will match that of Javascript's % operator (within the limits of its precision) and DecimalDecimal's remainder method.
   *
   * @throws `NaN` if n is negative or otherwise invalid.
   */
  mod(n: DecimalSource): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number times n - alias for .times().
   *
   * @throws `NaN` if n is invalid.
   */
  mul(n: DecimalSource): Decimal;
  /**
   * Return a new Decimal whose value is the value of this Decimal negated.
   */
  neg(): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number plus n.
   *
   * @throws `NaN` if n is invalid.
   */
  plus(n: DecimalSource): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number raised to the power exp.
   *
   * If exp is negative and the result has more fraction digits than is specified by Decimal.DP, it will be rounded to Decimal.DP decimal places using rounding mode Decimal.RM.
   *
   * @param exp The power to raise the number to, -1e+6 to 1e+6 inclusive
   * @throws `!pow!` if exp is invalid.
   *
   * Note: High value exponents may cause this method to be slow to return.
   */
  pow(exp: number): Decimal;
  /**
   * Return a new Decimal whose value is the value of this Decimal rounded to a maximum precision of sd
   * significant digits using rounding mode rm, or Decimal.RM if rm is not specified.
   *
   * @param sd Significant digits: integer, 1 to MAX_DP inclusive.
   * @param rm Rounding mode: 0 (down), 1 (half-up), 2 (half-even) or 3 (up).
   * @throws `!prec!` if sd is invalid.
   * @throws `!Decimal.RM!` if rm is invalid.
   */
  prec(sd: number, rm?: RoundingMode): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number rounded using rounding mode rm to a maximum of dp decimal places.
   *
   * @param dp Decimal places, 0 to 1e+6 inclusive
   * @param rm Rounding mode: 0 (down), 1 (half-up), 2 (half-even) or 3 (up).
   * @throws `!round!` if dp is invalid.
   * @throws `!Decimal.RM!` if rm is invalid.
   */
  round(dp?: number, rm?: RoundingMode): Decimal;
  /**
   * Returns a Decimal number whose value is the square root of this Decimal number.
   *
   * If the result has more fraction digits than is specified by Decimal.DP, it will be rounded to Decimal.DP decimal places using rounding mode Decimal.RM.
   *
   * @throws `NaN` if this Decimal number is negative.
   */
  sqrt(): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number minus n - alias for .minus().
   *
   * @throws `NaN` if n is invalid.
   */
  sub(n: DecimalSource): Decimal;
  /**
   * Returns a Decimal number whose value is the value of this Decimal number times n.
   *
   * @throws `NaN` if n is invalid.
   */
  times(n: DecimalSource): Decimal;
}

/**
 * Valid sources of the Value of a LaxDecimal.
 */
export type LaxDecimalSource = number | DecimalSource;

/**
 * Type alias for Big in places that want to use the lax default configuration.
 *
 * Note: Remember to memoize values of LaxDecimal where identity is important!
 */
export interface LaxDecimal extends Big {
  constructor: {
    strict: false;
  };

  /** Returns a LaxDecimal number whose value is the absolute value, i.e. the magnitude, of this LaxDecimal number. */
  abs(): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number plus n - alias for .plus().
   *
   * @throws `NaN` if n is invalid.
   */
  add(n: LaxDecimalSource): LaxDecimal;
  /**
   * Compare the values.
   *
   * @throws `NaN` if n is invalid.
   */
  cmp(n: LaxDecimalSource): Comparison;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number divided by n.
   *
   * If the result has more fraction digits than is specified by LaxDecimal.DP, it will be rounded to LaxDecimal.DP decimal places using rounding mode LaxDecimal.RM.
   *
   * @throws `NaN` if n is invalid.
   * @throws `±Infinity` on division by zero.
   * @throws `NaN` on division of zero by zero.
   */
  div(n: LaxDecimalSource): LaxDecimal;
  /**
   * Returns true if the value of this LaxDecimal equals the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  eq(n: LaxDecimalSource): boolean;
  /**
   * Returns true if the value of this LaxDecimal is greater than the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  gt(n: LaxDecimalSource): boolean;
  /**
   * Returns true if the value of this LaxDecimal is greater than or equal to the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  gte(n: LaxDecimalSource): boolean;
  /**
   * Returns true if the value of this LaxDecimal is less than the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  lt(n: LaxDecimalSource): boolean;
  /**
   * Returns true if the value of this LaxDecimal is less than or equal to the value of n, otherwise returns false.
   *
   * @throws `NaN` if n is invalid.
   */
  lte(n: LaxDecimalSource): boolean;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number minus n.
   *
   * @throws `NaN` if n is invalid.
   */
  minus(n: LaxDecimalSource): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number modulo n, i.e. the integer remainder of dividing this LaxDecimal number by n.
   *
   * The result will have the same sign as this LaxDecimal number, and it will match that of Javascript's % operator (within the limits of its precision) and LaxDecimalLaxDecimal's remainder method.
   *
   * @throws `NaN` if n is negative or otherwise invalid.
   */
  mod(n: LaxDecimalSource): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number times n - alias for .times().
   *
   * @throws `NaN` if n is invalid.
   */
  mul(n: LaxDecimalSource): LaxDecimal;
  /**
   * Return a new LaxDecimal whose value is the value of this LaxDecimal negated.
   */
  neg(): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number plus n.
   *
   * @throws `NaN` if n is invalid.
   */
  plus(n: LaxDecimalSource): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number raised to the power exp.
   *
   * If exp is negative and the result has more fraction digits than is specified by LaxDecimal.DP, it will be rounded to LaxDecimal.DP decimal places using rounding mode LaxDecimal.RM.
   *
   * @param exp The power to raise the number to, -1e+6 to 1e+6 inclusive
   * @throws `!pow!` if exp is invalid.
   *
   * Note: High value exponents may cause this method to be slow to return.
   */
  pow(exp: number): LaxDecimal;
  /**
   * Return a new LaxDecimal whose value is the value of this LaxDecimal rounded to a maximum precision of sd
   * significant digits using rounding mode rm, or LaxDecimal.RM if rm is not specified.
   *
   * @param sd Significant digits: integer, 1 to MAX_DP inclusive.
   * @param rm Rounding mode: 0 (down), 1 (half-up), 2 (half-even) or 3 (up).
   * @throws `!prec!` if sd is invalid.
   * @throws `!LaxDecimal.RM!` if rm is invalid.
   */
  prec(sd: number, rm?: RoundingMode): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number rounded using rounding mode rm to a maximum of dp decimal places.
   *
   * @param dp LaxDecimal places, 0 to 1e+6 inclusive
   * @param rm Rounding mode: 0 (down), 1 (half-up), 2 (half-even) or 3 (up).
   * @throws `!round!` if dp is invalid.
   * @throws `!LaxDecimal.RM!` if rm is invalid.
   */
  round(dp?: number, rm?: RoundingMode): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the square root of this LaxDecimal number.
   *
   * If the result has more fraction digits than is specified by LaxDecimal.DP, it will be rounded to LaxDecimal.DP decimal places using rounding mode LaxDecimal.RM.
   *
   * @throws `NaN` if this LaxDecimal number is negative.
   */
  sqrt(): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number minus n - alias for .minus().
   *
   * @throws `NaN` if n is invalid.
   */
  sub(n: LaxDecimalSource): LaxDecimal;
  /**
   * Returns a LaxDecimal number whose value is the value of this LaxDecimal number times n.
   *
   * @throws `NaN` if n is invalid.
   */
  times(n: LaxDecimalSource): LaxDecimal;
}

/**
 * Construct a Decimal from another Decimal or a String.
 *
 * This gives type safety against converting from `Number` and should be used instead of `new Big()`
 * where possible. This will return a strict Decimal.
 *
 * Note: Remember to memoize values of Decimal where identity is important!
 *
 * @param value A string or Decimal.
 */
export const decimal = (value: DecimalSource | LaxDecimal): Decimal =>
  new StrictBig(value) as Decimal;

/**
 * Return the bigger of two Decimals.
 *
 * When equal, the first Decimal is returned.
 *
 * @param a The first decimal to compare.
 * @param b The second decimal to compare.
 */
export const max = (a: Decimal, b: Decimal): Decimal => (a.gte(b) ? a : b);

/**
 * Return a LaxDecimal with the same value.
 *
 * Note: Remember to memoize values of LaxDecimald where identity is important!
 *
 * @param value A strict Decimal that has to be used in an operation that may lose precision.
 */
export const lax = (value: Decimal): LaxDecimal => new LaxBig(value) as LaxDecimal;
