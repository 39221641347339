import makeStyles from '@mui/styles/makeStyles';
import { useObservableEagerState } from 'observable-hooks';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { defer, mergeMap, retry } from 'rxjs';
import shaka from 'shaka-player';

import { LANGUAGES } from '../../constants';
import { useVideoController } from './VideoControllerContext';
import VideoControllerUpdater from './VideoControllerUpdater';

const MAX_RETRY_COUNT = 1;

const useStyle = makeStyles(() => ({
  videoContainer: {
    width: '100%',
    paddingTop: '56.25%',
    height: '0px',
    position: 'relative',
  },
  player: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: '#000000',
    '&::-webkit-media-controls': {
      display: 'none',
    },
  },
}));

export interface Subtitle {
  readonly file_url: string;
  readonly language: string;
  readonly kind: string;
}

export interface ShakaPlayerProps {
  assetFps: number | undefined;
  src: string | null;
  poster?: string;
  selectedAudioTrack?: shaka.extern.Track | null;
  showSubtitle?: boolean;
  subtitles?: ReadonlyArray<Subtitle>;
  selectedTextLanguage?: keyof typeof LANGUAGES | null;
  ControllerUpdateComponent?: React.ReactNode;
}

const createPlayer = (videoElement: HTMLVideoElement): shaka.Player => {
  const newPlayer: shaka.Player = new shaka.Player(videoElement);
  newPlayer.configure('streaming.durationBackoff', 0);
  newPlayer.configure('abr.enabled', false);
  newPlayer.configure('manifest.dash.ignoreMinBufferTime', true);
  return newPlayer;
};

const ShakaPlayer = forwardRef<HTMLVideoElement, ShakaPlayerProps>(
  (
    {
      src,
      poster,
      subtitles = [],
      selectedAudioTrack = null,
      showSubtitle = false,
      selectedTextLanguage = null,
      assetFps,
      ControllerUpdateComponent = <VideoControllerUpdater />,
    }: ShakaPlayerProps,
    ref,
  ) => {
    const classes = useStyle();
    const [player, setPlayer] = useState<shaka.Player | null>(null);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null);
    const videoController = useVideoController();
    const canPlay = useObservableEagerState(videoController.canPlay$);
    const videoEnded = useObservableEagerState(videoController.videoEnded$);
    const fps = useObservableEagerState(videoController.fps$);
    useImperativeHandle(ref, () => videoElement as HTMLVideoElement, [videoElement]);

    // Effect to handle component mount & mount.
    // Not related to the src prop, this hook creates a shaka.Player instance.
    // This should always be the first effect to run.
    useEffect(() => {
      if (!videoElement) {
        return () => {};
      }
      if (!player) {
        const newPlayer = createPlayer(videoElement);
        setPlayer(newPlayer);
        setLoaded(false);
      }
      return () => {
        if (player) {
          player.destroy();
        }
      };
    }, [videoElement, player]);

    // register player
    useEffect(() => {
      if (player) {
        videoController.registerPlayer(player);
      }
      return () => videoController.unRegisterPlayer();
    }, [videoController, player]);

    // handle new sources
    useEffect(() => {
      if (src && player && src !== player.getAssetUri()) {
        videoController.onNewSource();
        videoController.togglePlay(false);
        videoController.setVideoEnded(false);
      }
    }, [videoController, src, player]);

    // Load the source url when we have one. Retry loading if appropriate.
    useEffect(() => {
      if (!shaka.Player.isBrowserSupported()) {
        // eslint-disable-next-line no-console
        console.warn(
          'This browser does not support the shaka player. Use another browser or contact support.',
        );
      }
      let reloadTimeout: ReturnType<typeof setTimeout>;
      setLoaded(false);
      if (player && src) {
        const subscription = defer(() => player.unload())
          .pipe(
            mergeMap(() => player.load(src)),
            retry({ count: MAX_RETRY_COUNT, delay: 1000 }),
          )
          .subscribe({
            next: () => {
              setLoaded(true);
              player.getManifest()?.presentationTimeline?.setDelay(0);
            },
            error: (error) => {
              // eslint-disable-next-line no-console
              console.error(`Unable to load source "${src}":`, error);
            },
          });
        return () => subscription.unsubscribe();
      }
      return () => {
        clearTimeout(reloadTimeout);
      };
    }, [player, src, setLoaded]);

    // controller/player cannot play if it is not loaded
    useEffect(() => {
      if (!isLoaded && canPlay) {
        videoController.setCanPlay(false);
      }
    }, [videoController, isLoaded, canPlay]);

    // set controller fps
    useEffect(() => {
      if (assetFps && assetFps !== fps) {
        videoController.setFps(assetFps);
      }
    }, [videoController, assetFps, fps]);

    // set controller manifest and audio tracks
    useEffect(() => {
      if (player && isLoaded) {
        const manifest = player.getManifest();
        if (manifest) {
          videoController.setManifest(manifest);
        }
        const audioTracks = player.getVariantTracks();
        if (audioTracks) {
          videoController.setAudioTracks(audioTracks);
        }
      }
    }, [player, isLoaded, videoController]);

    // select and set the Audio Track
    useEffect(() => {
      if (videoElement && player && selectedAudioTrack) {
        // Have to use `safeMargin` if at the end of the video, or else the player would
        // not play after changing the audio track.
        const safeMargin = videoElement.currentTime === videoElement.duration ? 1 : 0;
        player.selectVariantTrack(selectedAudioTrack, true, safeMargin);
      }
    }, [videoElement, player, selectedAudioTrack]);

    // add subtitles
    useEffect(() => {
      if (player && isLoaded) {
        subtitles.forEach(async (subtitle) => {
          await player.addTextTrackAsync(subtitle.file_url, subtitle.language, subtitle.kind);
        });
      }
    }, [player, isLoaded, subtitles]);

    // show specified subtitles
    useEffect(() => {
      if (player && isLoaded) {
        if (selectedTextLanguage && showSubtitle) {
          player.selectTextLanguage(selectedTextLanguage);
        }
        player.setTextTrackVisibility(selectedTextLanguage !== null && showSubtitle);
      }
    }, [player, isLoaded, selectedTextLanguage, showSubtitle]);

    // Sync VideoController from events.
    //
    // Note: use event coupling responsibly since it might interfere with actions triggered by user.
    // Example/Potential use cases:
    // - User wants to load shot of video which does not yet exist in frontend state. E.g. via favorites.
    // - A progresslider event from user triggers bufferevent and user "needs to wait" for video to be available.
    const handleCanPlay = useCallback(() => {
      if (src && !videoEnded) {
        videoController.setCanPlay(true);
      }
    }, [videoController, src, videoEnded]);

    const handleLoadedMetadata = useCallback(
      ({ target }) => {
        videoController.setMaxTime(target.duration);
        videoController.setDuration(target.duration);
      },
      [videoController],
    );

    const handleEnded = useCallback(() => {
      videoController.setVideoEnded(true);
    }, [videoController]);

    // End of sync VideoController from events.

    return (
      <div className={classes.videoContainer} data-testid="video-container">
        <video
          controlsList="nodownload"
          data-testid="video-player"
          ref={setVideoElement}
          className={classes.player}
          poster={poster}
          onCanPlay={handleCanPlay}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={handleEnded}
        />
        {ControllerUpdateComponent}
      </div>
    );
  },
);

export default memo(ShakaPlayer);
