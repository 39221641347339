import { FC } from 'react';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import getNameById from 'medialoopster/getNameById';
import { ShareAsset } from 'medialoopster/icons';

import { productionsSelectors } from '../../../state/modules/productions';
import StatusElement from './StatusElement';

interface IsSharedStatusProps {
  readonly showLabel: boolean;
  readonly sharedWith: readonly { status: number; production: number }[];
}

const renderIcon = () => <ShareAsset style={{ fontSize: '1.2rem' }} />;

const IsSharedStatus: FC<IsSharedStatusProps> = ({
  showLabel,
  sharedWith,
}: IsSharedStatusProps) => {
  const productions = useSelector(productionsSelectors.getProductions);
  const sharedProductions = sharedWith.map(
    (shared) =>
      `${getNameById(productions, shared.production)}${
        shared.status === 0 ? ` (${gettext('Transferring')})` : ''
      }`,
  );
  return (
    <StatusElement
      renderIcon={renderIcon}
      label={gettext('Asset is shared')}
      showLabel={showLabel}
      title={`${gettext('Shared with productions')}\n\n${sharedProductions.join('\n')}`}
    />
  );
};

export default IsSharedStatus;
