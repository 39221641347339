import * as searchFieldMappingActions from './actions';
import searchFieldMappingEpics from './epics';
import reducer from './reducers';
import * as searchFieldMappingSelectors from './selectors';
import * as searchFieldMappingTypes from './types';

export {
  searchFieldMappingSelectors,
  searchFieldMappingEpics,
  searchFieldMappingActions,
  searchFieldMappingTypes,
};

export default reducer;
