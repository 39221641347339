import { Chip, ChipProps } from '@mui/material';
import { FC } from 'react';

type Props = {
  readonly icon?: ChipProps['icon'];
  readonly label: ChipProps['label'];
  readonly color: ChipProps['color'];
};

const StatusLabel: FC<Props> = ({ label, color, icon }: Props) => {
  return <Chip size="small" label={label} color={color} icon={icon} />;
};
export default StatusLabel;
