import { createSelector } from 'reselect';

import { gettext } from 'medialoopster/Internationalization';
import {
  Collection,
  createCollection,
  getEmbeddedItems,
  getLink,
  getLinkedItems,
  getLinkedResource,
  getLinkedResourceArray,
  getResource,
  getResourceHeader,
  getResourceURI,
  mlRel,
} from 'medialoopster/rest';

import { idFromHref } from '../../../businessRules/services/idFromHref';
import SearchQueryService, {
  SearchQuery,
} from '../../../sections/Header/services/SearchQueryService';
import { AssetType, SearchResultAssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { getAudioAssets } from '../audio/selectors';
import { getCurrentAsset, getCurrentAssetState } from '../details/selectors';
import { getImageAssets } from '../image/selectors';
import { collectionSelectors, collectionTypes } from '../rest/collection';
import { isAssetCollection } from '../rest/collection/types';
import { getProductionContentResourceMap } from '../rest/productioncontent/selectors';
import { getShots, getVideoAssets } from '../video/selectors';
import { Shot } from '../video/types';
import {
  AssetHighlight,
  AssetSearchEntryState,
  AssetSearchPage,
  HighlightForAssetState,
  SearchEntryAssetList,
  SearchEntryResource,
  SelectedAssetsState,
} from './types';

export const getSelection = (state: RootState): SelectedAssetsState => state.search.selection;
export const getSelectedAssetsTypeName = (state: RootState): AssetType | null =>
  getSelection(state).assetTypeName;

export const getSearchCategory = (state: RootState): SearchResultAssetType => state.search.category;

export const getSearchQuery = (state: RootState): SearchQuery => state.search.searchQuery;

export const getCurrentSearchCollectionURL = (state: RootState): string =>
  state.search.assetSearchURI;

const getSearchCollections = createSelector(
  (state: RootState) => getSearchCategory(state),
  (state: RootState) => state.search.resultCollections,
  (state: RootState) => state.rest.collectionsearchresult.pages,
  (searchCategory, resultCollections, collectionSearchResultPages) => {
    if (searchCategory === 'collection') {
      return collectionSearchResultPages;
    }
    return resultCollections[searchCategory];
  },
);

export const getAssetSearchResultCollection = createSelector(
  (state: RootState) => getSearchCategory(state),
  (state: RootState) => state.search.assetSearchURI,
  (state: RootState) => getSearchCollections(state),
  (state: RootState) =>
    ({
      videoasset: getVideoAssets,
      imageasset: getImageAssets,
      audioasset: getAudioAssets,
      collection: collectionSelectors.getAssetCollectionResourceMap,
    }[getSearchCategory(state)](state)),
  getProductionContentResourceMap,
  (
    searchCategory,
    assetSearchURI,
    searchCollections,
    mediaAssets,
    productioncontents,
  ): Collection<AssetSearchEntryState> =>
    createCollection<AssetSearchPage<SearchResultAssetType>, AssetSearchEntryState>(
      assetSearchURI,
      searchCollections,
      (page) =>
        getEmbeddedItems(page).flatMap((item) => {
          const useV3 = searchCategory === 'collection';
          if (useV3) {
            const asset = getLinkedResource<
              SearchEntryResource,
              Asset | collectionTypes.AssetCollection
            >(item, 'asset', mediaAssets);

            if (!isAssetCollection(asset)) {
              return [];
            }
            const sharedProductions = getLinkedResourceArray(
              asset,
              'productioncontents',
              productioncontents,
            );
            return {
              ...item,
              asset: {
                ...asset,
                sharedProductionLabels: sharedProductions.map((el) => {
                  if (!el) return '';
                  const sharedProd = getLink(el, 'production')?.title || '';
                  return el.status === 0
                    ? `${sharedProd} (${gettext('Transferring')})`
                    : sharedProd;
                }),
              },
            };
          }
          const asset = getLinkedResource<
            SearchEntryResource,
            Asset | collectionTypes.AssetCollection
          >(item, mlRel('asset'), mediaAssets);
          if (!asset) {
            return [];
          }
          return {
            ...item,
            asset,
          };
        }),
    ),
);

export const getShotSearchResultCollection = createSelector(
  (state: RootState) => state.search.shotSearchURI,
  (state: RootState) => state.search.resultCollections.shot,
  getShots,
  (shotSearchURI, shotCollections, shots): Collection<Shot> =>
    createCollection(shotSearchURI || '', shotCollections, (page) => getLinkedItems(page, shots)),
);

export const isRefreshingOrLoading = (state: RootState): boolean =>
  !getAssetSearchResultCollection(state).loaded || state.search.isRefreshingOrLoading;

export const getEntries = createSelector(
  getAssetSearchResultCollection,
  (resultCollection): SearchEntryAssetList =>
    resultCollection.items.map(({ asset }) => ({
      ...asset,
      // Need `sharedProductionLabels` on all asset types -> Migrate
      sharedProductionLabels:
        'sharedProductionLabels' in asset ? asset?.sharedProductionLabels || [] : [],
    })) as SearchEntryAssetList,
);

export const getNextURI = (state: RootState): string | undefined =>
  getAssetSearchResultCollection(state).next;

export const isFallbackSearch = (state: RootState): boolean =>
  getResource<AssetSearchPage<AssetType>>(
    state.search.resultCollections[getSearchCategory(state)],
    state.search.assetSearchURI,
  )?.isFallbackSearch || false;

export const getXTotalRelation = (state: RootState): string =>
  getResourceHeader(
    state.search.resultCollections[getSearchCategory(state)],
    state.search.assetSearchURI,
    'x-total-relation',
  ) || 'eq';

export const getXTotalCount = (state: RootState): number => {
  const totalCountHeader = getResourceHeader(
    state.search.resultCollections[getSearchCategory(state)],
    state.search.assetSearchURI,
    'x-total-count',
  );
  if (!totalCountHeader) {
    return 0;
  }
  return parseInt(totalCountHeader, 10);
};

export const getHighlights = createSelector(
  getAssetSearchResultCollection,
  (assetSearchResultCollection): HighlightForAssetState =>
    Object.fromEntries(
      assetSearchResultCollection.items.map(({ asset, highlight }) => [
        getResourceURI(asset),
        highlight,
      ]),
    ),
);

export const getCurrentAssetHighlight = createSelector(
  getCurrentAsset,
  getHighlights,
  (currentAsset, highlights) =>
    currentAsset ? highlights[getResourceURI(currentAsset)] : ({} as AssetHighlight),
);

export const getDeviceFilterCollection = createSelector(
  (state: RootState) => state.search.deviceFilterCollectionLink,
  (state: RootState) => state.search.deviceFilterCollections,
  (deviceFilterCollectionLink, deviceFilterCollections) =>
    createCollection(deviceFilterCollectionLink?.href, deviceFilterCollections, getEmbeddedItems),
);

export const getDeviceFilterOptions = createSelector(
  getDeviceFilterCollection,
  (deviceFilterCollection) =>
    [{ display: gettext('All devices'), value: '' }].concat(
      deviceFilterCollection.items.map((filter) => ({
        display: filter.display_name,
        value: JSON.stringify(filter.device_names),
      })),
    ),
);

export const getShotSearchURL = (state: RootState): string | null => {
  const { href, type } = getCurrentAssetState(state);
  if (!href) {
    return null;
  }
  if (type !== 'videoasset') {
    return null;
  }
  // TODO: Use ``getLinkHref('ml:shot_search')``, when implemented. <DT 2021-01-13 t:ML-2501>
  // Currently needed eg for page reloads and for smooth opening of shots. Reconsider after ML-2501
  const assetId = idFromHref(href);
  const searchQuery = getSearchQuery(state);
  const searchString = SearchQueryService.fromQuery({ ...searchQuery });
  return `/api/search/shots/?asset=${assetId}&${searchString}`;
};
