import { Button, ButtonGroup } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useState, memo } from 'react';

import { ArrowDropDown } from '../../icons';
import DropdownSelect, { Option } from './DropdownSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '22px',
  },
  button: {
    padding: '0px 4px',
    minWidth: 'unset !important',
    '& .MuiButton-startIcon': {
      marginRight: '2px',
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.selectedOpacity),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.text.primary,
          theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
      },
    },
  },
  drowDownButton: {
    padding: 0,
    minWidth: 'unset !important',
  },
}));

type Props = {
  readonly options: ReadonlyArray<Option & { readonly visible: boolean }>;
  readonly className?: string;
};

const DropdownMenuButton: FC<Props> = ({ options, className = undefined }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  if (options.length === 0) {
    return null;
  }
  const moreOptions = options.slice(1).filter(({ visible }) => visible);
  return (
    <>
      <ButtonGroup color="inherit" size="small" className={clsx(classes.root, className)}>
        {options[0].visible && (
          <Button
            className={clsx(classes.button, {
              'Mui-selected': options.length >= 1 && !!options[0].selected,
            })}
            onClick={options[0].onSelect}
            startIcon={options[0].icon}
          >
            {options[0].label}
          </Button>
        )}
        {moreOptions.length >= 1 && (
          <Button className={classes.drowDownButton} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <ArrowDropDown fontSize="small" />
          </Button>
        )}
      </ButtonGroup>
      {moreOptions.length >= 1 && (
        <DropdownSelect
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          options={moreOptions}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      )}
    </>
  );
};

export default memo(DropdownMenuButton);
