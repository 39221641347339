import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Audio, Image, File, Video } from 'medialoopster/icons';

import { CollectionFileType } from '../../state/modules/details/types';

type Props = {
  readonly fileType: CollectionFileType;
};

const CollectionFileIcon: FC<Props> = ({ fileType }: Props) => {
  if (fileType === 'Video') {
    return <Video fontSize="small" aria-label={gettext('Video')} aria-hidden={false} />;
  }
  if (fileType === 'Image') {
    return <Image fontSize="small" aria-label={gettext('Image')} aria-hidden={false} />;
  }
  if (fileType === 'Audio') {
    return <Audio fontSize="small" aria-label={gettext('Audio')} aria-hidden={false} />;
  }
  if (fileType === 'Other') {
    return <File fontSize="small" aria-label={gettext('Other')} aria-hidden={false} />;
  }
  return null;
};
export default CollectionFileIcon;
