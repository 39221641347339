import { useObservable, useObservableState } from 'observable-hooks';
import { EMPTY } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import { ResourceOptionsV2 } from 'medialoopster/rest';
import { getTokenAuthHeader, loginTypes } from 'medialoopster/state/login';

const useApiOptions = (url: string, token: loginTypes.Token): ResourceOptionsV2 => {
  const options$ = useObservable<ResourceOptionsV2>(() =>
    ajax<ResourceOptionsV2>({
      method: 'OPTIONS',
      url,
      headers: {
        ...getTokenAuthHeader(token),
      },
    }).pipe(
      map(({ response }) => response),
      catchError(() => EMPTY),
    ),
  );
  const options = useObservableState<ResourceOptionsV2>(options$, {
    version: '2',
    actions: {},
  });
  return options;
};
export default useApiOptions;
