import Collapse from '@mui/material/Collapse';
import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { searchActions, searchSelectors } from '../../../../state/modules/search';
import { videoTypes } from '../../../../state/modules/video';
import Shot from '../../../../ui/components/Shot';
import LoadMoreResults from '../../LoadMoreResults';

interface ShotListProps {
  isOpen: boolean;
  asset: videoTypes.VideoAsset;
}

const ShotList: FC<ShotListProps> = ({ isOpen, asset }: ShotListProps) => {
  const dispatch = useDispatch();
  const shots = useSelector(searchSelectors.getShotSearchResultCollection);

  const onLoadMoreShots = useCallback(() => {
    if (shots?.next) dispatch(searchActions.loadNextShots(shots?.next));
  }, [shots?.next, dispatch]);

  const assetURI = getResourceURI(asset);
  const assetTypeName = getResourceTypeName(asset);

  return (
    <Collapse
      in={isOpen}
      aria-label={isOpen ? gettext('Close shot list') : gettext('Open shot list')}
    >
      {shots?.items.map((shot) => (
        <Shot
          assetId={asset.id}
          assetURI={assetURI}
          assetTypeName={assetTypeName}
          assetName={asset.name}
          assetIsAvailable={asset.is_available}
          productionId={asset.production}
          offsetFrames={asset.offset_frames}
          fps={asset.fps}
          shot={shot}
          key={getResourceURI(shot)}
        />
      ))}
      {shots?.next && <LoadMoreResults onClick={onLoadMoreShots} />}
    </Collapse>
  );
};

export default memo(ShotList);
