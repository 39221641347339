import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import { ProductionsRootState, SelectProduction, SELECT_PRODUCTION } from './types';

export const selectedProductionURLReducer = (
  state: string | null = null,
  action: SelectProduction,
): string | null => {
  switch (action.type) {
    case SELECT_PRODUCTION:
      localStorage.setItem('mlProductionURL', action.payload.productionURL || '');
      return action.payload.productionURL;
    default:
      return state;
  }
};

export default combineReducers<ProductionsRootState>({
  productions: createResourcesReducer('production'),
  selectedProductionURL: selectedProductionURLReducer,
});
