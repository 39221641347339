import lodash from 'lodash';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { combineLatest, concat, EMPTY, merge, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  concatWith,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
  share,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { AlertFeedback, AlertMessage, alertMessage } from 'medialoopster/AlertMessage';
import { gettext, interpolate } from 'medialoopster/Internationalization';
import formatUserDisplayName from 'medialoopster/formatUserDisplayName';
import {
  BaseResource,
  createResourcesRemovedAction,
  getErrorAlertMessage,
  getLink,
  getLinkHref,
  getLinks,
  getLinkTitles,
  getResource,
  getResourceURI,
  mlRel,
  PageResource,
  RECEIVE_ROOT_RESOURCE,
  ReceiveRootResource,
  ResourceLoadedAction,
  ResourceOptionsLoadedAction,
  ResourceRemovedAction,
  RESTEpicDependencies,
  unauthorizedRequestAction,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { isVideoAsset } from '../../../businessRules/models/asset/utils';
import { isDiffEmpty } from '../../../sections/DetailsSection/Transcript/Editor';
import { onCurrentAssetLinkHrefChange } from '../../operators';
import { AssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';
import { RootState } from '../../types/rootState';
import { getAnnotationEndpointLink } from '../annotation/selectors';
import { Annotation } from '../annotation/types';
import { AudioAsset } from '../audio/types';
import { favoritesTypes } from '../favorites';
import { SelectFavoriteItemAsset } from '../favorites/types';
import { ImageAsset } from '../image/types';
import { playerActions, playerSelectors } from '../player';
import {
  SET_IN_FRAME,
  SET_OUT_FRAME,
  SetInFrame,
  SetInitialFrame,
  SetOutFrame,
} from '../player/types';
import { AssetCollection } from '../rest/collection/types';
import { getSpeakersUrl, getUnitsUrl, Transcript } from '../rest/transcript/types';
import { searchTypes } from '../search';
import { SelectEntry } from '../search/types';
import { usersSelectors } from '../users';
import { getCurrentUser, getCurrentUserURI, getUsersMap } from '../users/selectors';
import { User } from '../users/types';
import { getShots, getVideoAssets } from '../video/selectors';
import { Sequence, Shot, ShotRequest, VideoAsset } from '../video/types';
import {
  fetchAnnotations,
  fetchCurrentTranscript,
  loadAncestorError,
  lockCurrentAsset,
  selectClip,
  selectShotRangeEnd,
  selectShotRangeStart,
  setCurrentAsset,
  setTranscriptAvailability,
  toggleView,
  unlockCurrentAsset,
} from './actions';
import {
  getCurrentAnnotationsUrl,
  getCurrentAsset,
  getCurrentAssetHref,
  getCurrentAssetState,
  getCurrentAssetTypeName,
  getCurrentTranscript,
  getCurrentTranscriptsUrl,
  getCurrentUserAnnotations,
  getCurrentVideoSequencesMetadata,
  getDeleteAnnotationsURL,
  getLayoutView,
  getPublishAnnotationsURL,
  getUnpublishAnnotationsURL,
  isEditMode,
} from './selectors';
import {
  ADD_ANNOTATION,
  AddAnnotationAction,
  AnnotationPostResource,
  CHANGE_POSTER_FRAME,
  ChangePosterFrame,
  CREATE_SEQUENCE,
  CREATE_SHOT,
  CreateSequence,
  CreateShot,
  CurrentAssetState,
  DELETE_ALL_ANNOTATIONS,
  DELETE_ANNOTATION,
  DELETE_SEQUENCE,
  DELETE_SHOT,
  DeleteAllAnnotations,
  DeleteAnnotationAction,
  DeleteSequence,
  DeleteShot,
  EDIT_ANCESTOR,
  EDIT_ANNOTATION,
  EDIT_ASSET,
  EditAncestor,
  EditAnnotationAction,
  EditAsset,
  FETCH_ANNOTATIONS,
  FETCH_CURRENT_TRANSCRIPT,
  FETCH_SEQUENCE_OPTIONS,
  FETCH_SHOT_OPTIONS,
  FetchAnnotationsAction,
  FetchCurrentTranscriptAction,
  FetchSequenceOptions,
  FetchShotOptions,
  LOAD_ANCESTOR,
  LoadAncestor,
  LOCK_CURRENT_ASSET,
  LockCurrentAsset,
  PUBLISH_ALL_ANNOTATIONS,
  PublishAllAnnotations,
  REFRESH_CURRENT_ASSET,
  RefreshCurrentAsset,
  REMOVE_ASSET_FROM_COLLECTION,
  RemoveAssetFromCollection,
  SAVE_TRANSCRIPT,
  SaveTranscriptAction,
  SELECT_CLIP,
  SelectClip,
  SelectShotRangeEnd,
  SelectShotRangeStart,
  SetCurrentAsset,
  SetTranscriptAvailabilityAction,
  TOGGLE_EDIT_MODE,
  ToggleEditMode,
  ToggleView,
  UNLOCK_CURRENT_ASSET,
  UnlockCurrentAsset,
  UNPUBLISH_ALL_ANNOTATIONS,
  UnpublishAllAnnotations,
  UPDATE_KEYWORDS,
  UpdateKeywords,
} from './types';

export const onCurrentAssetHrefChange = <A,>(
  action$: Observable<Action>,
  state$: Observable<RootState>,
  createObservable: (href: string, assetType: AssetType) => Observable<A>,
): Observable<A> =>
  combineLatest([
    state$.pipe(map(getCurrentAssetHref), distinctUntilChanged()),
    action$.pipe(
      ofType<Action, RefreshCurrentAsset['type'], RefreshCurrentAsset>(REFRESH_CURRENT_ASSET),
      startWith(undefined),
    ),
  ]).pipe(
    withLatestFrom(state$.pipe(map(getCurrentAssetTypeName))),
    switchMap(([[href], assetType]) => {
      // NOTE: Need to check !href here, not via `filter` operator,
      // so the previous current asset's observable is cancelled.
      if (!href || !assetType) {
        return EMPTY;
      }
      return createObservable(href, assetType);
    }),
  );

export const fetchCurrentAssetEpic: Epic<
  Action,
  ResourceLoadedAction | Action,
  RootState,
  RESTEpicDependencies
> = (action$, state$: Observable<RootState>, { fetchResource }) =>
  onCurrentAssetHrefChange(action$, state$, (href, assetType) =>
    fetchResource(
      href,
      undefined,
      () => {
        return concat(
          of(alertMessage(gettext('Cannot load asset.'))),
          of(setCurrentAsset(null, null)),
        );
      },
      undefined,
      {
        Accept:
          assetType === 'collection'
            ? 'application/hal+json; version=3'
            : 'application/hal+json; version=2',
      },
    ),
  );

export const fetchCurrentAssetOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$: Observable<RootState>, { fetchOptions }) =>
  onCurrentAssetHrefChange(action$, state$, (href, assetType) =>
    fetchOptions(href, undefined, undefined, {
      Accept:
        assetType === 'collection'
          ? 'application/hal+json; version=3'
          : 'application/hal+json; version=2',
    }),
  );

// TODO: Not supported in api yet, and need for <AssetLinks> <PB 2024-04-08 t:ML-2034>
export const fetchCurrentAssetProjectsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('projects'), fetchCollection);

export const fetchCurrentAssetSequencesEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('sequences'), fetchCollection);

export const fetchCurrentAssetSuccessorsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('successors'), fetchCollection);

export const fetchCurrentCollectionVideoAssetsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, 'videoassets', fetchCollection);

export const fetchCurrentCollectionFilesEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, 'files', (href) =>
    fetchCollection(href, undefined, undefined, {
      Accept: 'application/hal+json; version=3',
    }),
  );

export const fetchCurrentCollectionImageAssetsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, 'imageassets', fetchCollection);

export const fetchCurrentCollectionAudioAssetsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, 'audioassets', fetchCollection);

export const fetchCurrentAssetSubtitlesEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('subtitles'), fetchCollection);

export const selectAssetEpic: Epic<Action, SetCurrentAsset | ToggleView, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<
      Action,
      SelectEntry['type'] | SelectFavoriteItemAsset['type'],
      SelectEntry | SelectFavoriteItemAsset
    >(searchTypes.SELECT_ENTRY, favoritesTypes.SELECT_FAVORITE_ITEM_ASSET),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { assetTypeName, assetHref },
        },
        state,
      ]) => {
        const view = getLayoutView(state);
        window.history.pushState({}, '', window.location.pathname);
        return concat(
          of(setCurrentAsset(assetTypeName, assetHref)),
          view === 'search' ? of(toggleView('all', 'default')) : EMPTY,
        );
      },
    ),
  );

export const selectClipEpic: Epic<
  Action,
  SetCurrentAsset | SetInitialFrame | SetInFrame | SetOutFrame | ToggleView,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, SelectClip['type'], SelectClip>(SELECT_CLIP),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { assetHref, timecodeStart, timecodeEnd },
        },
        state,
      ]) => {
        const view = getLayoutView(state);
        return concat(
          of(
            setCurrentAsset('videoasset', assetHref),
            playerActions.setInitialFrame(timecodeStart),
            playerActions.setInFrame(timecodeStart),
            playerActions.setOutFrame(timecodeEnd),
          ),
          view === 'search' ? of(toggleView('all', 'default')) : EMPTY,
        );
      },
    ),
  );

export const lockCurrentAssetEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { patchResource }) =>
  action$.pipe(
    ofType<Action, LockCurrentAsset['type'], LockCurrentAsset>(LOCK_CURRENT_ASSET),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { assetType, assetHref, userId, userHref },
        },
        state,
      ]) =>
        patchResource<Asset, AlertMessage>(
          // TODO: Use `_links/mlRel('locked_by')` when new HAL parser is ready.
          assetHref,
          assetType === 'collection'
            ? { _links: { locked_by: { href: userHref } } }
            : { locked_by: userId },
          (asset) => {
            if (!asset) {
              return EMPTY;
            }
            const lockedByHref = getLinkHref(
              asset,
              assetType === 'collection' ? 'locked_by' : mlRel('locked_by'),
            );
            if (lockedByHref && lockedByHref !== getCurrentUserURI(state)) {
              const lockedByUser = getResource(getUsersMap(state), lockedByHref);
              return of(
                alertMessage(
                  lockedByUser
                    ? interpolate(gettext('Asset is locked by %(locked_by_user)s'), {
                        locked_by_user: formatUserDisplayName(lockedByUser),
                      })
                    : gettext('Asset is locked by another user'),
                ),
              );
            }
            return EMPTY;
          },
          undefined,
          undefined,
          assetType === 'collection'
            ? {
                'Content-Type': 'application/hal+json; version=3',
                Accept: 'application/hal+json; version=3',
              }
            : undefined,
        ),
    ),
  );

export const unlockCurrentAssetEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { patchResource }) =>
  action$.pipe(
    ofType<Action, UnlockCurrentAsset['type'], UnlockCurrentAsset>(UNLOCK_CURRENT_ASSET),
    switchMap(({ payload: { assetType, assetHref } }) =>
      patchResource<Asset, AlertMessage>(
        // TODO: Use `_links/mlRel('locked_by')` when new HAL parser is ready.
        assetHref,
        assetType === 'collection' ? { _links: { locked_by: null } } : { locked_by: null },
        undefined,
        undefined,
        undefined,
        assetType === 'collection'
          ? {
              'Content-Type': 'application/hal+json; version=3',
              Accept: 'application/hal+json; version=3',
            }
          : undefined,
      ),
    ),
  );

export const toggleEditModeEpic: Epic<
  Action,
  LockCurrentAsset | UnlockCurrentAsset | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, ToggleEditMode['type'], ToggleEditMode>(TOGGLE_EDIT_MODE),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const currentAssetType = getCurrentAssetTypeName(state);
      if (!currentAssetType) {
        return EMPTY;
      }
      const currentAssetHref = getCurrentAssetHref(state);
      if (!currentAssetHref) {
        return EMPTY;
      }
      const currentUser = usersSelectors.getCurrentUser(state);
      if (!currentUser) {
        return EMPTY;
      }
      return of(
        isEditMode(state)
          ? unlockCurrentAsset(currentAssetType, currentAssetHref)
          : lockCurrentAsset(
              currentAssetType,
              currentAssetHref,
              currentUser.id,
              getResourceURI(currentUser),
            ),
      );
    }),
  );

export const unlockCurrentAssetWhenChangedEpic: Epic<
  Action,
  UnlockCurrentAsset | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (_action$, state$: Observable<RootState>) =>
  combineLatest([
    state$.pipe(
      map(getCurrentAssetState),
      filter(({ href }) => !!href),
    ),
    state$.pipe(map(isEditMode)),
  ]).pipe(
    // Emit previous and current asset href and edit mode.
    pairwise<[CurrentAssetState, boolean]>(),
    // When the current asset has changed and the previous asset had been in edit mode...
    filter(
      ([[{ href: prevHref }, prevEditMode], [{ href }]]) => !!(prevEditMode && prevHref !== href),
    ),
    // ...then unlock the previous asset
    mergeMap(([[prevAssetState]]) =>
      prevAssetState.type === null || prevAssetState.href === null
        ? EMPTY
        : of(unlockCurrentAsset(prevAssetState.type, prevAssetState.href)),
    ),
  );

export const fetchCustomMetadataSetCollectionEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const href = getLinkHref(root, mlRel('custommetadatasets'));
      if (!href) {
        return EMPTY;
      }
      return fetchCollection(href);
    }),
  );

export const fetchCustomMetadataCollectionEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const href = getLinkHref(root, mlRel('custommetadata'));
      if (!href) {
        return EMPTY;
      }
      return fetchCollection(href);
    }),
  );

export const changePosterFrameEpic: Epic<
  Action,
  ChangePosterFrame | AlertMessage | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, ChangePosterFrame['type'], ChangePosterFrame>(CHANGE_POSTER_FRAME),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { asset, frame },
        },
        state,
      ]) => {
        const link = getLink(asset, mlRel('change-poster-frame'));
        if (lodash.isUndefined(link)) {
          return of(alertMessage(gettext('Failed to change poster frame.'), AlertFeedback.Error));
        }
        return ajax
          .post(
            link.href,
            { frame },
            {
              'Content-Type': 'application/json',
              ...getTokenAuthHeader(loginSelectors.getToken(state)),
            },
          )
          .pipe(
            map(() =>
              alertMessage(gettext('Changed poster frame successfully.'), AlertFeedback.Success),
            ),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction());
              }
              return of(
                alertMessage(gettext('Failed to change poster frame.'), AlertFeedback.Error),
              );
            }),
          );
      },
    ),
  );

export const createShotEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { postResource, fetchResource }) =>
  action$.pipe(
    ofType<Action, CreateShot['type'], CreateShot>(CREATE_SHOT),
    mergeMap(({ payload: { asset, timecodeStart, timecodeEnd } }) => {
      const shotCollectionHref = getLinkHref(asset, mlRel('shots'));
      if (!shotCollectionHref) {
        return EMPTY;
      }
      return postResource<
        Shot,
        ShotRequest,
        ResourceLoadedAction,
        ResourceLoadedAction | AlertMessage
      >(
        shotCollectionHref,
        {
          timecode_start: timecodeStart,
          timecode_end: timecodeEnd,
        },
        (shot) => {
          if (!shot) {
            return EMPTY;
          }
          const sequenceURI = getLinkHref(shot, mlRel('sequence'));
          if (!sequenceURI) {
            return EMPTY;
          }
          return fetchResource(sequenceURI);
        },
        (err) => of(alertMessage(getErrorAlertMessage(err, gettext('Failed to create shot.')))),
      );
    }),
  );

export const deleteShotEpic: Epic<
  Action,
  ResourceLoadedAction | ResourceRemovedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { deleteResource, fetchResource }) =>
  action$.pipe(
    ofType<Action, DeleteShot['type'], DeleteShot>(DELETE_SHOT),
    mergeMap(({ payload: { shot } }) => {
      return deleteResource(shot, {
        createResourceObservable: () => {
          const sequenceURI = getLinkHref(shot, mlRel('sequence'));
          if (!sequenceURI) {
            return EMPTY;
          }
          return fetchResource(sequenceURI);
        },
        createErrorObservable: (err) =>
          of(alertMessage(getErrorAlertMessage(err, gettext('Failed to delete shot.')))),
      });
    }),
  );

export const createSequenceEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { postResource, fetchCollection }) =>
  action$.pipe(
    ofType<Action, CreateSequence['type'], CreateSequence>(CREATE_SEQUENCE),
    mergeMap(({ payload: { asset, timecodeStart, timecodeEnd } }) => {
      const sequencesCollectionHref = getLinkHref(asset, mlRel('sequences'));
      if (!sequencesCollectionHref) {
        return EMPTY;
      }
      return postResource<
        Sequence,
        ShotRequest,
        ResourceLoadedAction,
        ResourceLoadedAction | AlertMessage
      >(
        sequencesCollectionHref,
        {
          timecode_start: timecodeStart,
          timecode_end: timecodeEnd,
        },
        (sequence) => {
          if (!sequence) {
            return EMPTY;
          }
          return fetchCollection(sequencesCollectionHref);
        },
        (err) => of(alertMessage(getErrorAlertMessage(err, gettext('Failed to create sequence.')))),
      );
    }),
  );

export const deleteSequenceEpic: Epic<
  Action,
  ResourceLoadedAction | ResourceRemovedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { deleteResource, fetchResource }) =>
  action$.pipe(
    ofType<Action, DeleteSequence['type'], DeleteSequence>(DELETE_SEQUENCE),
    mergeMap(({ payload: { sequence } }) => {
      return deleteResource(sequence, {
        createResourceObservable: () => {
          const nextSequenceURI = getLinkHref(sequence, 'next');
          const previousSequenceURI = getLinkHref(sequence, 'previous');
          return concat(
            nextSequenceURI ? fetchResource(nextSequenceURI) : EMPTY,
            previousSequenceURI ? fetchResource(previousSequenceURI) : EMPTY,
          );
        },
        createErrorObservable: (err) =>
          of(alertMessage(getErrorAlertMessage(err, gettext('Failed to delete sequence.')))),
      });
    }),
  );

export const selectShotRangeEpic: Epic<
  Action,
  SelectShotRangeStart | SelectShotRangeEnd,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, SetInFrame['type'] | SetOutFrame['type'], SetInFrame | SetOutFrame>(
      SET_IN_FRAME,
      SET_OUT_FRAME,
    ),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const inFrame = playerSelectors.getInFrame(state);
      if (inFrame === null) {
        return EMPTY;
      }
      const outFrame = playerSelectors.getOutFrame(state);
      if (outFrame === null) {
        return EMPTY;
      }
      const shotRange = Object.values(getCurrentVideoSequencesMetadata(state))
        .flatMap(({ shots }) => shots)
        .filter((shot) => inFrame <= shot.timecode_end && outFrame >= shot.timecode_start);
      if (shotRange.length === 0) {
        return EMPTY;
      }
      return of(
        selectShotRangeStart(shotRange[0]),
        selectShotRangeEnd(shotRange[shotRange.length - 1]),
      );
    }),
  );

export const updateKeywordsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { patchResource }) =>
  action$.pipe(
    ofType<Action, UpdateKeywords['type'], UpdateKeywords>(UPDATE_KEYWORDS),
    mergeMap(({ payload: { resource, keywords } }) =>
      patchResource<
        ImageAsset | AudioAsset | Shot | Sequence,
        ResourceLoadedAction,
        ResourceLoadedAction
      >(getResourceURI(resource), {
        keywords,
      }),
    ),
  );

export const fetchShotOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchOptions }) =>
  action$.pipe(
    ofType<Action, FetchShotOptions['type'], FetchShotOptions>(FETCH_SHOT_OPTIONS),
    mergeMap(({ payload: { shotURI } }) => fetchOptions(shotURI)),
  );

export const editAssetEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { patchResource }) =>
  action$.pipe(
    ofType<Action, EditAsset['type'], EditAsset>(EDIT_ASSET),
    mergeMap(({ payload: { assetType, assetHref, updates } }) =>
      patchResource<
        VideoAsset | ImageAsset | AudioAsset | AssetCollection | Sequence,
        ResourceLoadedAction,
        AlertMessage
      >(
        assetHref,
        updates,
        undefined,
        (errors) => {
          const errrorMsgs = errors.response.errors?.map((err) => err.detail) || [];
          return of(alertMessage(errrorMsgs.join('\n'), AlertFeedback.Error));
        },
        undefined,
        assetType === 'collection'
          ? {
              Accept: 'application/hal+json; version=3',
              'Content-Type': 'application/hal+json; version=3',
            }
          : undefined,
      ),
    ),
  );

export const fetchCustomMetadataChoiceCollectionEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const href = getLinkHref(root, mlRel('custommetadatachoice'));
      if (!href) {
        return EMPTY;
      }
      return fetchCollection(href);
    }),
  );

export const fetchLicenseCollectionEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const href = getLinkHref(root, mlRel('license'));
      if (!href) {
        return EMPTY;
      }
      return fetchCollection(href);
    }),
  );

export const fetchLicensorCollectionEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const href = getLinkHref(root, mlRel('licensor'));
      if (!href) {
        return EMPTY;
      }
      return fetchCollection(href);
    }),
  );

export const fetchValidationRulesEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    mergeMap(({ payload: { root } }) => {
      const url = getLinkHref(root, mlRel('validationrules'));
      if (!url) {
        return EMPTY;
      }
      return fetchCollection(url);
    }),
  );

export const fetchSequenceOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { fetchOptions }) =>
  action$.pipe(
    ofType<Action, FetchSequenceOptions['type'], FetchSequenceOptions>(FETCH_SEQUENCE_OPTIONS),
    mergeMap(({ payload: { sequenceURI } }) => fetchOptions(sequenceURI)),
  );

export const removeAssetFromCollectionEpic: Epic<
  Action,
  ResourceLoadedAction | AlertMessage | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _state$, { postResource, fetchResource, fetchCollection }) =>
  action$.pipe(
    ofType<Action, RemoveAssetFromCollection['type'], RemoveAssetFromCollection>(
      REMOVE_ASSET_FROM_COLLECTION,
    ),
    mergeMap(
      ({ payload: { collectionURI, removeURI, linkedAssetCollectionHref, assetHref, shareURL } }) =>
        postResource<Asset, BaseResource, ResourceLoadedAction | AlertMessage, AlertMessage>(
          removeURI,
          {
            _links: {
              assets_list: [
                {
                  href: assetHref,
                },
              ],
            },
          },
          (res) => {
            if (!res) {
              return EMPTY;
            }
            const successMessage = (
              <>
                <p>{gettext('The following asset was removed from the collection:')}</p>
                <p>
                  <a target={'_blank'} href={shareURL}>
                    {getLinkTitles(res, 'assets_list').join(' ')}
                  </a>
                </p>
              </>
            );

            return concat(
              fetchResource(collectionURI, undefined, undefined, undefined, {
                Accept: 'application/hal+json; version=3',
              }),
              fetchCollection(linkedAssetCollectionHref),
              of(alertMessage(successMessage, AlertFeedback.Success)),
            );
          },
          (err) =>
            of(
              alertMessage(
                getErrorAlertMessage(
                  err,
                  gettext('Failed to remove the asset link from the collection.'),
                ),
              ),
            ),
          { version: 3 },
        ),
    ),
  );

const getEditSuccessActions = (
  shot: Shot | undefined,
  user: User | undefined,
): Observable<LockCurrentAsset | SelectClip> => {
  if (shot && user) {
    const assetHref = getLinkHref(shot, mlRel('asset'));
    if (assetHref) {
      return concat(
        of(lockCurrentAsset('videoasset', assetHref, user.id, getResourceURI(user))),
        of(selectClip(assetHref, shot.timecode_start, shot.timecode_end)),
      );
    }
    return EMPTY;
  }
  return EMPTY;
};

export const editAncestorEpic: Epic<Action, Action, RootState, RESTEpicDependencies> = (
  action$,
  state$,
  { useResource },
) =>
  action$.pipe(
    ofType<Action, EditAncestor['type'], EditAncestor>(EDIT_ANCESTOR),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { ancestorHref },
        },
        state,
      ]) => {
        return useResource(
          ancestorHref,
          getShots(state),
          (availableAncestorShot) => {
            const currentUser = getCurrentUser(state);
            if (availableAncestorShot && currentUser) {
              return getEditSuccessActions(availableAncestorShot, currentUser);
            }
            return EMPTY;
          },
          () => of(alertMessage(gettext('Cannot load ancestor'))),
        );
      },
    ),
  );

export const loadAncestorEpic: Epic<Action, Action, RootState, RESTEpicDependencies> = (
  action$,
  state$,
  { useResource },
) =>
  action$.pipe(
    ofType<Action, LoadAncestor['type'], LoadAncestor>(LOAD_ANCESTOR),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { ancestorHref },
        },
        state,
      ]) =>
        useResource(
          ancestorHref,
          getShots(state),
          (availableAncestorShot) => {
            if (availableAncestorShot) {
              const assetHref = getLinkHref(availableAncestorShot, mlRel('asset'));
              if (assetHref) {
                return useResource(assetHref, getVideoAssets(state), undefined, () =>
                  of(loadAncestorError(ancestorHref, gettext('Cannot load asset.'))),
                );
              }
            }
            return EMPTY;
          },
          () => of(loadAncestorError(ancestorHref, gettext('Cannot load ancestor'))),
        ),
    ),
  );

/**
 * Epic to fetch the annotations for the current videoasset.
 *
 * The Epic is triggered by FETCH_ANNOTATIONS and loads the annotations collection of the
 * current asset, if the asset is a video asset. Otherwise, it does nothing.
 *
 * The responsibility of this epic is to load the annotations of a video asset.
 */
export const fetchCurrentAssetAnnotationsEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) =>
  action$.pipe(
    ofType<Action, FetchAnnotationsAction['type'], FetchAnnotationsAction>(FETCH_ANNOTATIONS),
    withLatestFrom(state$.pipe(map(getCurrentAnnotationsUrl))),
    mergeMap(([, annotationUrl]) => (annotationUrl ? fetchCollection(annotationUrl) : EMPTY)),
  );

/**
 * Trigger fetching the current annotations whenever the link to the annotations of the current asset changes.
 *
 * The responsibility of this epic is to react to changes to the current video asset.
 */
export const fetchAnnotationsOnAssetChangeEpic: Epic<
  Action,
  FetchAnnotationsAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('annotations'), () => of(fetchAnnotations()));

/**
 * Epic to add a new annotation.
 *
 * Reacts to ADD_ANNOTATION actions.
 *
 * The epic currently uses a POST request to the annotation endpoint in API-V2.
 *
 * A failing POST-request or a current asset that is no video asset will dispatch alert actions.
 *
 */
export const addAnnotationEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction | AlertMessage | FetchAnnotationsAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<Action, AddAnnotationAction['type'], AddAnnotationAction>(ADD_ANNOTATION),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { frame, text },
        },
        state,
      ]) => {
        const currentAsset = getCurrentAsset(state);
        if (!isVideoAsset(currentAsset)) {
          return of(alertMessage(gettext('Annotations can only be added to video assets!')));
        }
        const currentAssetHref = getResourceURI(currentAsset);
        const annotationsHref = getAnnotationEndpointLink(state)?.href;
        if (annotationsHref === undefined) {
          // the root state should practically allways be loaded
          return EMPTY;
        }
        const annotationPostResource: AnnotationPostResource = {
          time: frame,
          text,
          is_public: false,
          _links: {
            'http://medialoopster.com/relations/asset': {
              href: currentAssetHref,
            },
          },
        };
        return postResource<
          Annotation,
          AnnotationPostResource,
          FetchAnnotationsAction,
          AlertMessage
        >(
          annotationsHref,
          annotationPostResource,
          () => of(fetchAnnotations()),
          () => of(alertMessage(gettext('Failed to add annotation.'))),
        );
      },
    ),
  );

/**
 * Edit an annotation.
 *
 * For example:
 *   - to publish or make it private again
 *   - to edit the annotation text
 *
 * The epic patches the annotation resource for editable annotation fields.
 *
 */
export const editAnnotationEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { patchResource }) =>
  action$.pipe(
    ofType<Action, EditAnnotationAction['type'], EditAnnotationAction>(EDIT_ANNOTATION),
    switchMap(({ payload: { uri, editFields } }) => {
      return patchResource<Annotation>(uri, editFields);
    }),
  );

/**
 * Delete an annotation.
 *
 * The epic deletes an annotation and emit RESOURCES_LOADED action for related asset.
 *
 */
export const deleteAnnotationEpic: Epic<
  Action,
  | ResourceLoadedAction
  | UnauthorizedRequestAction
  | ResourceRemovedAction
  | AlertMessage
  | FetchAnnotationsAction,
  RootState,
  RESTEpicDependencies
> = (action$, _, { deleteResource }) =>
  action$.pipe(
    ofType<Action, DeleteAnnotationAction['type'], DeleteAnnotationAction>(DELETE_ANNOTATION),
    switchMap(({ payload: { annotation } }) => {
      return deleteResource(annotation, {
        createResourceObservable: () => of(fetchAnnotations()),
        createErrorObservable: (err) =>
          of(alertMessage(getErrorAlertMessage(err, gettext('Failed to delete annotation.')))),
      });
    }),
  );

const createAllAnnotationsActionEpic =
  <A extends Action>(
    type: A['type'],
    getActionURL: (state: RootState) => string | null,
  ): Epic<
    Action,
    UnauthorizedRequestAction | ResourceLoadedAction | FetchAnnotationsAction,
    RootState,
    RESTEpicDependencies
  > =>
  (action$, state$, { postResource }) =>
    action$.pipe(
      ofType<Action, A['type'], A>(type),
      withLatestFrom(state$),
      mergeMap(([, state]) => {
        const annotations = getCurrentUserAnnotations(state);
        if (annotations.length === 0) {
          return EMPTY;
        }
        const actionURL = getActionURL(state);
        if (!actionURL) {
          return EMPTY;
        }
        return postResource(
          actionURL,
          {
            _links: {
              annotations_list: annotations.map((annotation) => ({
                href: getResourceURI(annotation),
              })),
            },
          },
          () => of(fetchAnnotations()),
          undefined,
          { version: 3 },
        );
      }),
    );

export const publishAllAnnotationsEpic = createAllAnnotationsActionEpic<PublishAllAnnotations>(
  PUBLISH_ALL_ANNOTATIONS,
  getPublishAnnotationsURL,
);
export const unpublishAllAnnotationsEpic = createAllAnnotationsActionEpic<UnpublishAllAnnotations>(
  UNPUBLISH_ALL_ANNOTATIONS,
  getUnpublishAnnotationsURL,
);
export const deleteAllAnnotationsEpic = createAllAnnotationsActionEpic<DeleteAllAnnotations>(
  DELETE_ALL_ANNOTATIONS,
  getDeleteAnnotationsURL,
);

// region current transcript

/**
 * Epic to fetch the transcript for the current videoasset.
 *
 * The Epic is triggered by FETCH_CURRENT_TRANSCRIPT and loads the transcript, transcript
 * unit and transcript speaker collections of the current asset. The transcript is only
 * loaded for asset types that support transcription, meaning video and audio. Otherwise,
 * the epic does nothing.
 *
 * The responsibility of this epic is to actually load the transcript, units and speadkers  of the
 * current asset.
 */
export const fetchCurrentTranscriptEpic: Epic<
  Action,
  ResourceLoadedAction | UnauthorizedRequestAction | SetTranscriptAvailabilityAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { fetchCollection }) => {
  // fetch the current transcript in reaction to the FETCH_CURRENT_TRANSCRIPT action.
  const fetchTranscript$ = action$.pipe(
    ofType<Action, FetchCurrentTranscriptAction['type'], FetchCurrentTranscriptAction>(
      FETCH_CURRENT_TRANSCRIPT,
    ),
    withLatestFrom(state$.pipe(map(getCurrentTranscriptsUrl))),
    switchMap(([, transcriptUrl]) => {
      if (transcriptUrl) {
        return of(setTranscriptAvailability('loading')).pipe(
          concatWith(
            fetchCollection(transcriptUrl, undefined, undefined, {
              Accept: 'application/hal+json; version=3',
            }),
          ),
        );
      }
      return of(setTranscriptAvailability('no-transcript'));
    }),
    share(), // needed to prevent multiple transcript fetches. share works because epics are async
  );

  // The action emitted when a transcript has been loaded.
  const TRANSCRIPT_LOADED = 'REST/RESOURCES_LOADED(transcript)';

  // React to a loaded transcript and load the corresponding units and speakers
  // of the respective transcript.
  const fetchTranscriptUnitsAndSpeakers$ = fetchTranscript$.pipe(
    ofType<Action, typeof TRANSCRIPT_LOADED, ResourceLoadedAction<Transcript>>(TRANSCRIPT_LOADED),
    switchMap((action) => {
      const fetchObservables = Object.entries(action.payload.resources).flatMap(
        ([, transcript]) => {
          const unitsUrl = getUnitsUrl(transcript);
          const speakersUrl = getSpeakersUrl(transcript);
          return [
            fetchCollection(unitsUrl, undefined, undefined, {
              Accept: 'application/hal+json; version=3',
            }),
            fetchCollection(speakersUrl, undefined, undefined, {
              Accept: 'application/hal+json; version=3',
            }),
          ];
        },
      );
      return merge(...fetchObservables).pipe(concatWith(of(setTranscriptAvailability('ready'))));
    }),
  );

  // The action emitted when a transcript collection page has been loaded
  const TRANSCRIPT_COLLECTION_LOADED = 'REST/RESOURCES_LOADED(transcript-collection)';

  // React to empty transcript collections to set the availability to 'no-transcript'
  // An empty transcript collection should only be possible when the current asset has no
  // transcript.
  const detectMissingTranscript$ = fetchTranscript$.pipe(
    ofType<
      Action,
      typeof TRANSCRIPT_COLLECTION_LOADED,
      ResourceLoadedAction<PageResource<'transcript-collection'>>
    >(TRANSCRIPT_COLLECTION_LOADED),
    withLatestFrom(state$.pipe(map(getCurrentTranscriptsUrl))),
    switchMap(([action, transcriptsUrl]) => {
      if (transcriptsUrl) {
        const resource = action.payload.resources[transcriptsUrl];
        if (resource.total_count > 0) {
          return EMPTY;
        }
      }
      return of(setTranscriptAvailability('no-transcript'));
    }),
  );

  // Return the RESOURCE_LOADED events for the transcript, the units and the speakers.
  return merge(fetchTranscript$, fetchTranscriptUnitsAndSpeakers$, detectMissingTranscript$);
};

/**
 * Trigger fetching the current transcript whenever the link to the transcript of the current asset changes.
 *
 * The responsibility of this epic is to react to changes to the current video asset.
 */
export const fetchTranscriptOnAssetChangeEpic: Epic<
  Action,
  FetchCurrentTranscriptAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$) =>
  onCurrentAssetLinkHrefChange(action$, state$, mlRel('transcripts'), () =>
    of(fetchCurrentTranscript()),
  );

/**
 * Epic to save the current transcript.
 *
 * The epic saves the edits on the current transcript by submitting the diff that represents the
 * edit. Empty diffs will be ignored.
 *
 * While saving (before actually submitting the diff) the transcript availability is set to
 * loading to prevent further edits on the transcript.
 *
 * After saving, the edit-response is used to remove deleted units and speakers from the store.
 * The rest is ignored and the current transcript is re-fetched to synchronize with the edited
 * state.
 */
export const saveTranscriptEpic: Epic<
  Action,
  | SaveTranscriptAction
  | ResourceLoadedAction
  | ResourceRemovedAction
  | UnauthorizedRequestAction
  | AlertMessage
  | FetchCurrentTranscriptAction
  | SetTranscriptAvailabilityAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<Action, typeof SAVE_TRANSCRIPT, SaveTranscriptAction>(SAVE_TRANSCRIPT),
    withLatestFrom(state$.pipe(map(getCurrentTranscript))),
    switchMap(
      ([
        {
          payload: { diff },
        },
        currentTranscript,
      ]) => {
        // ignore post requests if there is no current transcript
        if (!currentTranscript) {
          return EMPTY;
        }

        // ignore empty diffs
        if (isDiffEmpty(diff)) {
          return EMPTY;
        }

        // after successfully transmitting the diff
        const onPostDiffSuccess = (editResource?: BaseResource) => {
          const actions = [];
          // Re-Fetch and update the current transcript
          actions.push(fetchCurrentTranscript());
          if (editResource) {
            // delete obsolete transcript units from the store (units deleted by the edit)
            const deletedUnitLinks = getLinks(editResource, 'delete_units');
            if (deletedUnitLinks.length > 0) {
              actions.push(
                createResourcesRemovedAction(
                  'transcriptunit',
                  deletedUnitLinks.map(({ href }) => href),
                ),
              );
            }
            // delete obsolete transcript speakers from the store (speakers deleted by the edit)
            const deletedSpeakerLinks = getLinks(editResource, 'delete_speakers');
            if (deletedSpeakerLinks.length > 0) {
              actions.push(
                createResourcesRemovedAction(
                  'transcriptspeaker',
                  deletedSpeakerLinks.map(({ href }) => href),
                ),
              );
            }
          }
          // inform the user about the successful edit/save.
          actions.push(alertMessage(gettext('Transcript saved.'), AlertFeedback.Success));
          return of(...actions);
        };

        // Save the transcript by posting the diff to the edit endpoint
        const postDiff$ = postResource(
          // eslint-disable-next-line no-underscore-dangle
          currentTranscript._links.edit.href,
          diff as BaseResource,
          onPostDiffSuccess,
          () =>
            of(
              alertMessage(gettext('Error while saving transcript.')),
              setTranscriptAvailability('ready'),
            ),
          { version: 3 },
          undefined,
          { 'Content-Type': 'application/json; version=3' },
        );

        // First set the transcript availability to `loading` to prevent further edits.
        // Then save the transcript.
        return of(setTranscriptAvailability('loading')).pipe(concatWith(postDiff$));
      },
    ),
  );

// endregion

export default combineEpics(
  selectAssetEpic,
  selectClipEpic,
  fetchCurrentAssetEpic,
  fetchCurrentAssetOptionsEpic,
  fetchCurrentAssetProjectsEpic,
  fetchCurrentAssetSequencesEpic,
  fetchCurrentAssetSuccessorsEpic,
  fetchCurrentAssetSubtitlesEpic,
  fetchCurrentCollectionVideoAssetsEpic,
  fetchCurrentCollectionImageAssetsEpic,
  fetchCurrentCollectionAudioAssetsEpic,
  fetchCurrentCollectionFilesEpic,
  lockCurrentAssetEpic,
  unlockCurrentAssetEpic,
  toggleEditModeEpic,
  unlockCurrentAssetWhenChangedEpic,
  fetchCustomMetadataSetCollectionEpic,
  fetchCustomMetadataCollectionEpic,
  changePosterFrameEpic,
  createShotEpic,
  deleteShotEpic,
  createSequenceEpic,
  deleteSequenceEpic,
  selectShotRangeEpic,
  updateKeywordsEpic,
  fetchShotOptionsEpic,
  fetchCustomMetadataChoiceCollectionEpic,
  fetchLicensorCollectionEpic,
  fetchValidationRulesEpic,
  editAssetEpic,
  fetchLicenseCollectionEpic,
  fetchSequenceOptionsEpic,
  editAncestorEpic,
  loadAncestorEpic,
  fetchCurrentAssetAnnotationsEpic,
  fetchAnnotationsOnAssetChangeEpic,
  addAnnotationEpic,
  editAnnotationEpic,
  deleteAnnotationEpic,
  removeAssetFromCollectionEpic,
  publishAllAnnotationsEpic,
  unpublishAllAnnotationsEpic,
  deleteAllAnnotationsEpic,
  fetchCurrentTranscriptEpic,
  fetchTranscriptOnAssetChangeEpic,
  saveTranscriptEpic,
);
