import { DateTime } from 'luxon';

const formatSecondsAsTimecode = (seconds: number, format = 'HH:mm:ss.uu'): string => {
  // the rounding is necessary to fix errors of the floating point representation:
  // for example at seconds=32.12, seconds * 1000 is represented as 32119.99996
  const durationInMilliseconds = Math.round(seconds * 1000);
  return DateTime.fromMillis(durationInMilliseconds, { zone: 'UTC' }).toFormat(format);
};

export default formatSecondsAsTimecode;
