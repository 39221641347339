import { FC, useCallback, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { AutoSuggestWidget } from 'medialoopster/components';

import { addAssetsToCollectionSelectors } from '../../../state/modules/addAssetsToCollection';

const CollectionSuggestWidget: FC = () => {
  const {
    getValues,
    trigger,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const options = useSelector(addAssetsToCollectionSelectors.getCurrentAddableCollections);

  const validationRuleCollection = useMemo(
    () => ({
      validate: (val: string | null) => {
        const selectedProduction = getValues('production');
        if (!selectedProduction) {
          return gettext('Please select a production first.');
        }
        if (!val) return gettext('Collection name is empty.');
        if (options.length === 0) {
          return gettext('No collection available.');
        }
        return options.find((el) => el.display === val) === undefined
          ? gettext('Please select a collection.')
          : true;
      },
    }),
    [options, getValues],
  );

  const optionsDisp = useMemo(() => options.map((el) => el.display), [options]);
  const triggerValidate = useCallback(() => trigger('collection'), [trigger]);
  useEffect(() => {
    if (options.length && isDirty) {
      triggerValidate();
      if (document.activeElement?.id === 'collection') {
        // Note: Workaround - sometimes autosuggest looses focus when options change, but not always
        (document.activeElement as HTMLElement)?.focus();
      }
    }
  }, [options, triggerValidate, isDirty]);

  const onFocus = useCallback(() => {
    triggerValidate();
    setValue('collection', getValues('collection'), { shouldDirty: true });
  }, [triggerValidate, setValue, getValues]);

  return (
    <AutoSuggestWidget
      label={gettext('Collection')}
      name="collection"
      defaultValue={''}
      onFocus={onFocus}
      rules={validationRuleCollection}
      options={optionsDisp}
    />
  );
};
export default CollectionSuggestWidget;
