import fileSize from 'filesize';
import { DateTime, Duration } from 'luxon';
import { createSelector } from 'reselect';

import { gettext } from 'medialoopster/Internationalization';
import { SelectOption } from 'medialoopster/components';
import { getLink, mlRel } from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { RootState } from '../../types/rootState';
import { productionsSelectors } from '../productions';
import { getProductions } from '../productions/selectors';
import { Upload, UploadDisplay, UploadMessage, UploadProgress, UploadStatus } from './types';

export const getUploads = (state: RootState): ReadonlyRecord<string, Upload> =>
  state.upload.uploads;

const getFileBaseName = (name: string) => name.split('.').slice(0, -1).join('.');

const getFileExtension = (name: string) => `.${name.split('.').slice(-1)[0]}`;

const getNameErrorMessage = (upload: Upload, duplicateNames: ReadonlySet<string>): string => {
  if (duplicateNames.has(upload.name)) {
    return gettext('The name is already in use.');
  }
  if (upload.errors.length > 0) {
    const nameError = upload.errors.find(
      (error) => error.pointer === '/filename' && error.code !== 'unsupported_file_type',
    );
    if (nameError) {
      return nameError.message;
    }
  }
  return '';
};

const getMessage = (upload: Upload): UploadMessage | null => {
  if (upload.status === UploadStatus.SUCCESS) {
    return { label: gettext('Upload completed'), isError: false };
  }
  if (upload.size === 0) {
    return { label: gettext('File is empty'), isError: true };
  }
  if (upload.errors.length > 0) {
    const uploadError = upload.errors.find(
      (error) => error.pointer !== '/filename' || error.code === 'unsupported_file_type',
    );
    if (uploadError) {
      return { label: uploadError.message, isError: true };
    }
  } else if (upload.status === UploadStatus.ERROR) {
    // No errors, but ERROR status => internal error.
    return { label: gettext('Internal error'), isError: true };
  }
  return null;
};

const formatProgress = (bytesUploaded: number, bytesTotal: number): number =>
  +(bytesTotal > 0 ? (bytesUploaded * 100) / bytesTotal : 0).toFixed(2);

export const getUploadsForDisplay = createSelector(
  getUploads,
  (uploads: ReadonlyRecord<string, Upload>): ReadonlyArray<UploadDisplay> => {
    const uploadArray = Object.values(uploads);
    const names = uploadArray.map(({ name }) => name);
    const duplicateNames = new Set(names.filter((name, index) => names.indexOf(name) !== index));
    return uploadArray.map((upload) => {
      const message = getMessage(upload);
      return {
        objectURL: upload.objectURL,
        uploadURL: upload.uploadURL || '',
        editable:
          upload.status !== UploadStatus.ERROR &&
          upload.status !== UploadStatus.SUCCESS &&
          upload.status !== UploadStatus.STARTED &&
          !message?.isError,
        baseName: getFileBaseName(upload.name),
        nameErrorMessage: getNameErrorMessage(upload, duplicateNames),
        extension: getFileExtension(upload.name),
        sizeDisplay: fileSize(upload.size),
        message,
        progress: formatProgress(upload.bytesUploaded, upload.bytesTotal),
        disabled: upload.status !== UploadStatus.READY || duplicateNames.has(upload.name),
        isPending: upload.status === UploadStatus.STARTED && upload.bytesUploaded === 0,
      };
    });
  },
);

export const getUploadProductionChoices = createSelector(
  getProductions,
  (productions): ReadonlyArray<SelectOption> =>
    productions
      .map((production) => {
        const uploadLink = getLink(production, mlRel('upload'));
        return uploadLink && uploadLink.methods?.POST?.code === 'ok'
          ? { display: production.name, value: uploadLink.href }
          : { display: '', value: '' };
      })
      .filter(({ value }) => !!value),
);

export const getDefaultProductionUploadURL = (state: RootState): string | null => {
  const production = productionsSelectors.getSelectedProduction(state);
  if (!production) {
    const choices = getUploadProductionChoices(state);
    return choices.length === 0 ? null : (choices[0].value as string);
  }
  const uploadLink = getLink(production, mlRel('upload'));
  return uploadLink && uploadLink.methods?.POST?.code === 'ok' ? uploadLink.href : null;
};

export const getUploadProgress = createSelector(
  getUploads,
  (uploads: ReadonlyRecord<string, Upload>): UploadProgress => {
    const bytesUploaded = Object.values(uploads)
      .map((upload) => upload.bytesUploaded)
      .reduce((x, y) => x + y, 0);
    const bytesTotal = Object.values(uploads)
      .map((upload) => upload.bytesTotal)
      .reduce((x, y) => x + y, 0);
    const startTimeMs = Math.min(
      ...Object.values(uploads)
        .map((upload) => upload.startTime)
        .filter((startTime): startTime is number => !!startTime),
    );
    const timeElapsedMs = DateTime.local().toMillis() - startTimeMs;
    const bytesPerSecond = bytesUploaded / (timeElapsedMs / 1000);
    return {
      speed: `${fileSize(bytesPerSecond, { bits: true })}/s`,
      timeElapsed: Duration.fromMillis(timeElapsedMs).toFormat('hh:mm:ss'),
      visible: bytesUploaded < bytesTotal,
      bytesUploaded: fileSize(bytesUploaded),
      bytesTotal: fileSize(bytesTotal),
      percentage: formatProgress(bytesUploaded, bytesTotal),
    };
  },
);
