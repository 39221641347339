import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import { FC, ChangeEvent } from 'react';

import { gettext } from 'medialoopster/Internationalization';

import { FavoritesListState } from '../../../state/modules/favorites/types';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 'min-content',
  },
  tabRoot: {
    minWidth: 'min-content',
    minHeight: 'min-content',
    padding: '7px 10px',
    color: theme.palette.action.active,
    '&.Mui-selected': {
      color: theme.palette.action.active,
    },
    '&:hover': {
      color:
        theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.text.secondary,
    },
  },
  sharedListTab: {
    backgroundColor: theme.palette.action.selected,
  },
}));

type FavoriteListsProps = {
  favoriteLists: ReadonlyArray<FavoritesListState>;
  onDoubleClick: () => void;
  onSelect: (id: number) => void;
  selectedListId?: number;
};

const FavoriteLists: FC<FavoriteListsProps> = ({
  favoriteLists,
  onDoubleClick,
  onSelect,
  selectedListId,
}: FavoriteListsProps) => {
  const classes = useStyles();
  const handleClick = (_event: ChangeEvent<unknown>, id: number) => {
    if (selectedListId !== id) {
      onSelect(id);
    }
  };

  return (
    <Tabs
      value={favoriteLists.length > 0 && selectedListId ? selectedListId : false}
      onChange={handleClick}
      indicatorColor="primary"
      classes={{ root: classes.tabsRoot }}
      variant="scrollable"
      scrollButtons="auto"
    >
      {favoriteLists.length === 0 && (
        <Tab
          key="favorites-tab-no-list"
          label={gettext('You do not have any favorites yet')}
          classes={{ root: classes.tabRoot }}
          disabled
        />
      )}
      {favoriteLists.length > 0 &&
        favoriteLists.map((fav) => {
          return (
            <Tab
              key={`favorites-tab-list-${fav.id}`}
              value={fav.id}
              label={fav.name}
              onDoubleClick={onDoubleClick}
              id={`favorite-item-list-${fav.id}`}
              classes={{ root: classes.tabRoot }}
              className={fav.shared ? classes.sharedListTab : ''}
            />
          );
        })}
    </Tabs>
  );
};

export default FavoriteLists;
