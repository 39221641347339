import ListItem from '@mui/material/ListItem';
import makeStyles from '@mui/styles/makeStyles';
import { FC, memo, useCallback, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import { detailsActions } from '../../../../state/modules/details';
import { favoritesActions, favoritesSelectors } from '../../../../state/modules/favorites';
import { AssetType } from '../../../../state/types/asset/baseTypes';
import DeletedFavoriteItem from './DeletedFavoriteItem';
import FavoriteItem from './FavoriteItem';

const useStyles = makeStyles(() => ({
  favoriteItem: {
    display: 'inline-block',
    marginRight: 4,
    padding: 0,
  },
}));

type FavoriteListItemProps = {
  id: number;
  deleted: boolean;
  displayName: string;
  detail: string;
  thumbnailURL: string | undefined;
  isDragged: boolean;
  onDragStart: (id: number) => void;
  onDragEnd: (id: number) => void;
  onDragHover: (id: number) => void;
  assetTypeName: AssetType | undefined;
  assetHref: string | undefined;
  assetId: number | null;
  assetIsAvailable: boolean;
  timecodeStart?: number;
  timecodeEnd?: number;
  cssClasses: {
    favoriteItemContent: string;
    favoriteItemDetail: string;
    favoriteItemDecreasedOpacity: string;
  };
};

const FavoriteListItem: FC<FavoriteListItemProps> = ({
  id,
  deleted,
  displayName,
  detail,
  thumbnailURL,
  isDragged,
  onDragStart,
  onDragEnd,
  onDragHover,
  assetTypeName,
  assetHref,
  assetId,
  assetIsAvailable,
  timecodeStart,
  timecodeEnd,
  cssClasses,
}: FavoriteListItemProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locked = useSelector(favoritesSelectors.isLocked);
  const [, drag, preview] = useDrag({
    type: 'favoriteitem',
    item: () => {
      onDragStart(id);
      return {
        id,
        type: 'favoriteitem',
        deleted,
        displayName,
        detail,
        thumbnailURL,
        assetIsAvailable,
      };
    },
    end: () => {
      onDragEnd(id);
    },
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  const [, drop] = useDrop({
    accept: ['favoriteitem'],
    hover: () => {
      onDragHover(id);
    },
  });

  const onDoubleClickFavoriteItem = useCallback(() => {
    if (assetId && assetHref && assetTypeName) {
      if (timecodeStart !== undefined && timecodeEnd !== undefined) {
        dispatch(detailsActions.selectClip(assetHref, timecodeStart, timecodeEnd));
      } else {
        dispatch(favoritesActions.selectFavoriteItemAsset(assetTypeName, assetHref, assetId));
      }
    }
  }, [dispatch, assetId, assetHref, timecodeStart, timecodeEnd, assetTypeName]);

  const onRefUpdate: (el: HTMLLIElement | null) => void = useCallback(
    (el) => {
      if (locked) {
        return;
      }
      drag(el);
      drop(el);
    },
    [drag, drop, locked],
  );
  const listClasses = { root: classes.favoriteItem };
  const listStyle = isDragged ? { opacity: 0.0 } : {};
  return (
    <ListItem ref={onRefUpdate} style={listStyle} classes={listClasses}>
      {deleted ? (
        <DeletedFavoriteItem displayName={displayName} detail={detail} />
      ) : (
        <FavoriteItem
          displayName={displayName}
          thumbnailURL={thumbnailURL}
          detail={detail}
          onDoubleClick={onDoubleClickFavoriteItem}
          assetIsAvailable={assetIsAvailable}
          cssClasses={cssClasses}
        />
      )}
    </ListItem>
  );
};

export default memo(FavoriteListItem);
