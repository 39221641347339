import {
  AlertError,
  ALERT_ERROR,
  ClearAlert,
  CLEAR_ALERT,
  LoginRequest,
  LOGIN_REQUEST,
  LoginFailure,
  LOGIN_FAILURE,
  Logout,
  LOGOUT,
  LoginSuccess,
  LOGIN_SUCCESS,
  ResetStore,
  RESET_STORE,
  Token,
  CLEAR_TOKEN,
  ClearToken,
  INIT_APP,
  InitApp,
} from './types';

export const alertError = (message: string): AlertError => ({
  type: ALERT_ERROR,
  payload: { message },
});

export const clearAlert = (): ClearAlert => ({
  type: CLEAR_ALERT,
});

export const loginRequest = (username: string, password: string): LoginRequest => ({
  type: LOGIN_REQUEST,
  payload: { username, password },
});

export const loginSuccess = (token: Token, userId: number): LoginSuccess => ({
  type: LOGIN_SUCCESS,
  payload: { token, userId },
});

export const loginFailure = (): LoginFailure => ({
  type: LOGIN_FAILURE,
});

export const logout = (): Logout => ({
  type: LOGOUT,
});

export const clearToken = (): ClearToken => ({
  type: CLEAR_TOKEN,
});

export const resetStore = (): ResetStore => ({ type: RESET_STORE });

export const initApp = (): InitApp => ({
  type: INIT_APP,
});
