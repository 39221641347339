import { FC } from 'react';

import { gettext, interpolate } from 'medialoopster/Internationalization';
import { DragMultipleItems } from 'medialoopster/icons';

type MultipleFavoriteItemsProps = {
  count: number;
  cssClasses: {
    favoriteItemContent: string;
  };
};
const MultipleFavoriteItems: FC<MultipleFavoriteItemsProps> = ({
  count,
  cssClasses,
}: MultipleFavoriteItemsProps) => (
  <div className={cssClasses.favoriteItemContent}>
    <div style={{ fontSize: '40pt', lineHeight: 0 }}>
      <DragMultipleItems fontSize="inherit" />
    </div>
    <div>{interpolate(gettext('%(count)s assets'), { count })}</div>
  </div>
);

export default MultipleFavoriteItems;
