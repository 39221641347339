/**
 * This module implements a plugin to show a tooltip on transcript units.
 *
 * The tooltip shows the start time and the confidence of the unit.
 *
 * Because `lexical` employs its own rendering logic outside of React it is not easily possible
 * to render one tooltip for every unit on request. Instead, a single tooltip is rendered high up
 * in the DOM using a React portal and reused for every unit. When the mouse hovers over a unit
 * and a delay has passed, the tooltip is moved to the units position and made visible.
 */
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { Box, Tooltip } from '@mui/material';
import { $getNodeByKey, LexicalEditor } from 'lexical';
import { useState, useCallback, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

import { interpolate, pgettext } from 'medialoopster/Internationalization';

import { useTranscriptEditorContext } from '../components/TranscriptEditorContext';
import { decimal } from '../decimal';
import { TranscriptTimeBlockNode } from '../nodes/TranscriptTimeBlockNode';
import { $isTranscriptUnitNode, TranscriptUnitNode } from '../nodes/TranscriptUnitNode';

const TOOLTIP_DELAY = 1000;

/**
 * The shape of the style used to move and display the tooltip.
 */
interface MouseOverStyle {
  position: 'fixed';
  top: number;
  left: number;
  visibility: 'hidden';
}

/**
 * Calculate the position of the tooltip relative to the html element rendered for a hovered unit.
 *
 * Because the tooltip is not moved directly but the box the tooltip referes to, the top position
 * may match the top of the decorated unit element and no vertical space needs to be added.
 * @param element
 */
const calculateTooltipPosition = (element: HTMLSpanElement) => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    left: rect.left + rect.width / 2,
  };
};

/**
 * A plugin to decorate transcript units with a tooltip when the mouse hovers over them.
 *
 * The plugin is implemented reusing a single tooltip for every unit and by modifying this single
 * tooltip according to the selected/hovered unit. See the module description for more information
 * and the reasoning behind the implementation.
 */
export const TranscriptUnitTooltipPlugin: React.FC = () => {
  // the offset time of the asset
  const { offsetTime } = useTranscriptEditorContext();
  // the time currently displayed in the tooltip. Is changed to match the hovered unit
  const [time, setTime] = useState(decimal('0'));
  // the confidence currently displayed in the tooltip. Is changed to macht the hovered unit
  const [confidence, setConfidence] = useState(decimal('0'));
  // the open/close (visible/hidden) state of the tooltip
  const [open, setOpen] = useState(false);
  // A ref to hold the timeoutId for the display delay of the tooltip
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  // the style of the MUI-Box the tooltip points to. Used to move the Box and thus the Tooltip
  const [style, setStyle] = useState<MouseOverStyle>({
    visibility: 'hidden',
    top: 0,
    left: 0,
    position: 'fixed',
  });

  // open the tooltip after TOOLTIP_DELAY
  // set the position of the tooltip to the currently hovered unit and read out time and confidence
  const onMouseEnter = useCallback(
    (event: Event, editor: LexicalEditor, nodeKey: string) => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
      // move tooltip
      const { top, left } = calculateTooltipPosition(event.target as HTMLSpanElement);
      setStyle({
        ...style,
        top,
        left,
      });

      // read time and confidence of the currently hovered unit
      editor.getEditorState().read(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isTranscriptUnitNode(node)) {
          setTime(node.getTime());
          setConfidence(node.getConfidence());
        }
      });

      timeoutIdRef.current = setTimeout(() => {
        // open the tooltip
        setOpen(true);
      }, TOOLTIP_DELAY);
    },
    [setOpen, style, setStyle, setTime, setConfidence],
  );

  // close the tooltip
  const closeTooltip = useCallback(() => {
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    setOpen(false);
  }, []);

  // calculate and memoize the tootip's title
  const tooltipTitle = useMemo(() => {
    const displayTime = time.plus(offsetTime);
    return interpolate(
      pgettext(
        'Tooltip for transcript units',
        'Time: %(displayTime)ss, Confidence: %(confidence)s',
      ),
      { displayTime: displayTime.round(3).toString(), confidence: confidence.round(2).toString() },
    );
  }, [time, offsetTime, confidence]);

  // The component is rendered in a portal to appear high up in the DOM-tree
  return ReactDOM.createPortal(
    <>
      <Tooltip title={tooltipTitle} open={open} placement="top">
        <Box style={style} className="transcript__unit-tooltip-anchor" />
      </Tooltip>
      <NodeEventPlugin
        nodeType={TranscriptUnitNode}
        eventType={'mouseenter'}
        eventListener={onMouseEnter}
      />
      <NodeEventPlugin
        nodeType={TranscriptUnitNode}
        eventType={'mouseleave'}
        eventListener={closeTooltip}
      />
      <NodeEventPlugin
        nodeType={TranscriptTimeBlockNode}
        eventType={'mouseenter' /* Detect when the unit under the mouse is deleted */}
        eventListener={closeTooltip}
      />
    </>,
    document.body,
  );
};

export default TranscriptUnitTooltipPlugin;
