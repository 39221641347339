import { Box } from '@mui/material';
import { ReactNode, FC } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly title?: string;
};

const FieldGroup: FC<Props> = ({ children, title = undefined }: Props) => (
  <Box marginY={3}>
    {title && <h3>{title}</h3>}
    {children}
  </Box>
);

export default FieldGroup;
