import { useState, useCallback } from 'react';

import { getShareURL } from '../../businessRules/services/getShareURL';
import { AssetType } from '../../state/types/asset/baseTypes';

type CopiedValue = string | null;
export type CopyFn = (currentAssetType: AssetType, assetId: number) => Promise<void>;

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.innerText = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

const copyTextToClipboard = (text: string) => {
  if (navigator && navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }
  return new Promise<void>((resolve) => {
    fallbackCopyTextToClipboard(text);
    resolve();
  });
};

export const useCopyAssetUrlToClipboard: (
  onCopyCallback?: () => void,
  onErrCallback?: () => void,
) => [CopiedValue, CopyFn] = (onCopyCallback = undefined, onErrCallback = undefined) => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const copy: CopyFn = useCallback(
    async (currentAssetType: AssetType, assetId: number) => {
      const url = getShareURL(assetId, currentAssetType);

      try {
        await copyTextToClipboard(url);
        setCopiedText(url);
        onCopyCallback?.();
      } catch (error) {
        setCopiedText(null);
        onErrCallback?.();
      }
    },
    [onCopyCallback, onErrCallback],
  );
  return [copiedText, copy];
};
