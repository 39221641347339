import DialogContent from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode, FC } from 'react';

import ModalNotifier from './ModalNotifier';

type Props = {
  id?: string;
  children?: ReactNode;
};

type SubComponents = {
  Notifier: typeof ModalNotifier;
};

const useStyles = makeStyles((theme) => ({
  modalContent: {
    minWidth: 600,
    '& .MuiFormControl-root': {
      display: 'flex',
    },
    '& .MuiTextField-root:not(.MuiTable-root .MuiTextField-root)': {
      marginBottom: theme.spacing(2),
    },
    '& .MuiAlert-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const ModalBody: FC<Props> & SubComponents = ({ id, children }: Props) => {
  const cssClasses = useStyles();
  return (
    <DialogContent id={id} className={cssClasses.modalContent} dividers>
      {children}
    </DialogContent>
  );
};

ModalBody.Notifier = ModalNotifier;

export default ModalBody;
