import { CssBaseline, Drawer, Grid, Slide, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import { alertMessage } from 'medialoopster/AlertMessage';
import { gettext } from 'medialoopster/Internationalization';
import { VideoControllerContextProvider, withLogIn } from 'medialoopster/components';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { isMediaAsset } from '../businessRules/models/asset/utils';
import ShareAssetsModal from '../sections/AssetSharing/ShareAssetsModal';
import DetailsSection, { ResizeHandle } from '../sections/DetailsSection';
import Favorites from '../sections/Favorites';
import Header from '../sections/Header';
import LicenseNotification from '../sections/Notifications/LicenseNotification';
import UserNotifications from '../sections/Notifications/UserNotifications';
import SearchList from '../sections/SearchList';
import BulkTransferModal from '../sections/Transfer/BulkTransferModal';
import { detailsActions, detailsSelectors } from '../state/modules/details';
import { favoritesActions, favoritesSelectors } from '../state/modules/favorites';
import { mainSelectors } from '../state/modules/main';
import { playerSelectors } from '../state/modules/player';
import { productionsSelectors } from '../state/modules/productions';
import { isAssetCollection } from '../state/modules/rest/collection/types';
import { searchActions, searchSelectors } from '../state/modules/search';
import { usersSelectors } from '../state/modules/users';
import useLayout from './useLayout';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
  },
  content: {
    maxWidth: '1440px',
    margin: 'auto',
  },
  searchList: {
    overflowY: 'auto',
    paddingLeft: '15px', // based on padding left of Header
  },
  searchListFullSize: {
    paddingRight: '10px', // based on padding right of Header
  },
  details: {
    overflowY: 'auto',
    paddingLeft: '15px', // based on padding left of Header
    paddingRight: `calc(10px - ${theme.spacing(2)})`, // based on padding right of Header
  },
  drawerPaper: {
    overflow: 'unset', // override overflow to prevent horizontal scroll bar and to see menu
  },
  transitionOverride: {
    // transition not only the "sliding" but also the shrinking/growing
    transitionProperty: 'all !important',
  },
}));

const App: FC = withLogIn(() => {
  const classes = useStyles();
  const isMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  const searchResultAssets = useSelector(searchSelectors.getEntries);

  const searchCategory = useSelector(searchSelectors.getSearchCategory);
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const currentAssetType = useSelector(detailsSelectors.getCurrentAssetType);
  const favoritesOpen = useSelector(favoritesSelectors.isActive);
  const deviceFilterOptions = useSelector(searchSelectors.getDeviceFilterOptions);
  const productionOptions = useSelector(productionsSelectors.getProductionOptions);
  const selectedProduction = useSelector(productionsSelectors.getSelectedProduction);
  const selectedFavoriteList = useSelector(favoritesSelectors.getSelectedList, shallowEqual);
  const selectedAssets = useSelector(searchSelectors.getSelection);
  // the asset in the media player, either a media asset or a collection asset if the collection placeholder is displayed
  const embeddedAsset = useSelector(detailsSelectors.getEmbeddedAsset);
  const embeddedAssetState = useSelector(detailsSelectors.getEmbeddedAssetState);
  const linkedAssets = useSelector(detailsSelectors.getCurrentCollectionContent);
  const inFrame = useSelector(playerSelectors.getInFrame);
  const outFrame = useSelector(playerSelectors.getOutFrame);
  const version = useSelector(mainSelectors.getVersion);
  const siteName = useSelector(mainSelectors.getCurrentSiteName);

  const dispatch = useDispatch();

  useEffect(() => {
    const navigateAssets = (delta: -1 | 1) => {
      if (isAssetCollection(currentAsset) && isMediaAsset(embeddedAsset)) {
        // NAVIGATE IN LINKED ASSETS
        const indexLinkedAsset = linkedAssets.indexOf(embeddedAsset);
        // at an unforseeable error case, do nothing
        // (for example the embedded asset is still a formerly loaded media asset not linked to
        // collection, but the current asset is already the collection)
        if (indexLinkedAsset === -1) {
          return;
        }
        const nextIndex = indexLinkedAsset + delta;
        if (nextIndex < 0 || nextIndex >= linkedAssets.length) {
          return;
        }
        const nextAsset = linkedAssets[nextIndex];
        dispatch(
          detailsActions.setEmbeddedAsset(
            getResourceTypeName(nextAsset),
            getResourceURI(nextAsset),
          ),
        );
        return;
      }

      // NAVIGATE IN ASSET SEARCH RESULT ENTRIES
      let nextIndex: number;
      if (currentAsset === null || currentAssetType !== searchCategory) {
        // if there is no current asset yet, or the current asset is not of the search category type
        if (delta < 0) {
          // if going upwards, set to load the last asset in the list
          nextIndex = searchResultAssets.length - 1;
        } else {
          // if going downwards, set to load the last asset in the list
          nextIndex = 0;
        }
      } else {
        // there is a current asset, of the same type as the search category type; navigate with delta
        const indexCurrentAsset = searchResultAssets.findIndex(
          (searchResultAsset) => getResourceURI(currentAsset) === getResourceURI(searchResultAsset),
        );
        if (indexCurrentAsset === -1) {
          // at an unforseeable error case, do nothing
          return;
        }
        nextIndex = indexCurrentAsset + delta;
      }
      if (nextIndex < 0 || nextIndex >= searchResultAssets.length) {
        return;
      }
      const nextAsset = searchResultAssets[nextIndex];
      dispatch(
        detailsActions.setCurrentAsset(getResourceTypeName(nextAsset), getResourceURI(nextAsset)),
      );
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (
        document.activeElement?.tagName === 'INPUT' ||
        document.activeElement?.tagName === 'TEXTAREA'
      ) {
        return;
      }
      switch (event.code) {
        case 'ArrowDown':
          navigateAssets(1);
          event.preventDefault();
          break;
        case 'ArrowUp':
          navigateAssets(-1);
          event.preventDefault();
          break;
        case 'KeyE':
          dispatch(detailsActions.toggleEditMode());
          break;
        case 'Escape':
          dispatch(searchActions.deselectAllEntries());
          break;
        case 'KeyF':
          if (event.shiftKey) {
            dispatch(favoritesActions.toggleFavorites());
            break;
          }
          if (
            !embeddedAsset &&
            (selectedAssets.assetIds.length === 0 || !selectedAssets.assetTypeName)
          ) {
            dispatch(alertMessage(gettext('No asset selected or loaded in the player!')));
            break;
          }
          if (!favoritesOpen) {
            dispatch(alertMessage(gettext('No favorites open!')));
            break;
          }
          if (!selectedFavoriteList) {
            dispatch(alertMessage(gettext('No favorites list active!')));
            break;
          }
          if (selectedAssets.assetIds.length && selectedAssets.assetTypeName) {
            if (selectedAssets.assetTypeName === 'collection') {
              dispatch(alertMessage(gettext('Collections are not allowed in favorite lists!')));
              break;
            }
            dispatch(
              favoritesActions.addAssetsToFavorites(
                selectedAssets.assetTypeName,
                selectedAssets.assetIds,
              ),
            );
            break;
          }
          if (embeddedAssetState.type === 'collection') {
            dispatch(alertMessage(gettext('Collections are not allowed in favorite lists!')));
            break;
          }
          // only for safety, it should not occur
          if (!embeddedAsset) {
            dispatch(alertMessage(gettext('No asset loaded in the player!')));
            break;
          }
          if (embeddedAssetState.type === 'videoasset' && inFrame !== null && outFrame !== null) {
            dispatch(favoritesActions.addVideoClipToFavorites(embeddedAsset.id, inFrame, outFrame));
            break;
          }
          // only for safety, it should not occur
          if (!embeddedAssetState.type) {
            dispatch(alertMessage(gettext('Asset loaded in the player is of unknown type!')));
            break;
          }
          dispatch(favoritesActions.addAssetToFavorites(embeddedAssetState.type, embeddedAsset.id));
          break;
        default:
      }
    };
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [
    dispatch,
    searchResultAssets,
    searchCategory,
    currentAsset,
    currentAssetType,
    favoritesOpen,
    selectedFavoriteList,
    embeddedAsset,
    embeddedAssetState,
    linkedAssets,
    selectedAssets,
    inFrame,
    outFrame,
  ]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<ResizeHandle>(null);
  const showAnnotations = useSelector(detailsSelectors.showAnnotations);
  const view = useSelector(detailsSelectors.getLayoutView);
  const onResize = useCallback(() => {
    const lnHeight = document.getElementById('license-notification')?.clientHeight || 0;
    const headerHeight = document.getElementById('header')?.clientHeight || 0;
    const favHeight = favoritesOpen ? document.getElementById('favorites')?.clientHeight || 0 : 0;
    const heightOffset = lnHeight + headerHeight + favHeight + 6; // 6px extra padding for separation
    if (wrapperRef.current) {
      wrapperRef.current.style.height = `calc(100vh - ${heightOffset}px)`;
    }
    if (searchRef.current) {
      searchRef.current.style.maxHeight = `calc(100vh - ${heightOffset}px)`;
    }
    if (detailsRef.current) {
      detailsRef.current.resize(heightOffset);
    }
  }, [favoritesOpen]);
  useEffect(() => {
    onResize(); // resize if favoritesOpen changes (indicated by onResize-reference change)
  }, [onResize]);
  const { ref } = useResizeDetector({ onResize });
  const gridDetailsRef = useRef<HTMLDivElement>(null);
  const {
    showSearch,
    showDetails,
    searchSize,
    detailsSize,
    onDetailsViewExited,
    onSearchViewExited,
    detailsTransitionAddEndListener,
    searchTransitionAddEndListener,
    startTransitioning,
  } = useLayout(gridDetailsRef, searchRef);

  const searchVisible = useMemo(
    () => showSearch && !showAnnotations && (!isMdDown || view === 'search'),
    [showSearch, showAnnotations, isMdDown, view],
  );

  if (!currentUser) {
    return null;
  }

  return (
    <div ref={ref}>
      <VideoControllerContextProvider>
        <CssBaseline />
        <LicenseNotification />
        <Header
          version={version}
          siteName={siteName}
          productions={productionOptions}
          productionFilter={selectedProduction ? selectedProduction.id : 0}
          devices={deviceFilterOptions}
        />
        {/* container-wrapper is needed for drag'n'drop (monitor.over() not working over margin) */}
        <div ref={wrapperRef} id="container-wrapper" className={classes.contentWrapper}>
          <Grid container alignItems="stretch" className={classes.content} flexWrap={'nowrap'}>
            <Slide
              direction="right"
              in={searchVisible}
              mountOnEnter
              unmountOnExit
              onExited={onSearchViewExited}
              onEntered={onResize}
              addEndListener={searchTransitionAddEndListener}
              onEnter={() => startTransitioning('search')}
            >
              <Grid
                ref={searchRef}
                data-testid="search-list-column"
                data-testid-show={searchVisible}
                item
                xs={searchSize}
                className={clsx(classes.searchList, {
                  [classes.searchListFullSize]: !showDetails,
                })}
              >
                <SearchList isWide={searchSize === 12} />
              </Grid>
            </Slide>
            <Slide
              direction="left"
              in={showDetails}
              mountOnEnter
              unmountOnExit
              onExited={onDetailsViewExited}
              onEntered={onResize}
              addEndListener={detailsTransitionAddEndListener}
            >
              <Grid
                ref={gridDetailsRef}
                data-testid="details-column"
                item
                xs={detailsSize}
                className={clsx(classes.details)}
              >
                <DetailsSection ref={detailsRef} />
              </Grid>
            </Slide>
          </Grid>
        </div>
        <Drawer
          anchor="bottom"
          variant="persistent"
          open={favoritesOpen}
          classes={{ paper: classes.drawerPaper }}
        >
          <Favorites />
        </Drawer>
        <UserNotifications />
        <ShareAssetsModal />
        <BulkTransferModal />
      </VideoControllerContextProvider>
    </div>
  );
});

export default App;
