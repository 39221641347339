import { RootState } from '../../../types/rootState';
import { TranscriptRootState } from './types';

/**
 * Get the transcript state from the root state.
 * @param state The global root state.
 */
const getSelf = (state: RootState) => state.rest.transcript;

/**
 * Get the transcript resource map.
 * @param state The global root state.
 */
export const getTranscriptResourceMap = (state: RootState): TranscriptRootState['objects'] =>
  getSelf(state).objects;

/**
 * Get the transcript page map.
 * @param state The global root state.
 */
export const getTranscriptPageMap = (state: RootState): TranscriptRootState['pages'] =>
  getSelf(state).pages;

/**
 * Determine if the current user can use transcripts.
 * @param state The global root state.
 * @returns true if the user has permissions to work with transcript and false, otherwise.
 */
export const canUseTranscript = (state: RootState): boolean => {
  const self = getSelf(state);
  const collectionUrl = self.collectionLink?.href;
  if (collectionUrl === undefined) {
    return false;
  }
  return !!self.pages.options[collectionUrl]?.actions?.GET;
};

export default null;
