import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import {
  FieldPathByValue,
  FieldValues,
  PathValue,
  useController,
  useFormContext,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { Input } from 'medialoopster/components';
import { unauthorizedRequestAction } from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { getCollectionLink } from '../../../state/modules/createAssetCollectionModal/selectors';

const debouncedValidation = AwesomeDebouncePromise(
  async (rawCollectionName, productionId, collectionHref, token, dispatch) => {
    if (rawCollectionName === '') {
      return gettext('Collection name is empty.');
    }
    if (productionId === null) {
      return gettext('No production selected.');
    }

    const headers: Record<string, string> = {
      'Content-Type': 'application/json;version=3',
      ...getTokenAuthHeader(token),
    };

    try {
      const response = await fetch(`${collectionHref}?dry_run`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          production: productionId,
          name: rawCollectionName,
        }),
      });
      if (!response.ok) {
        if (response.status === 401) {
          dispatch(unauthorizedRequestAction()); // TODO: Test in ML-3735
        }
        const result = await response.json();
        return result.errors[0].detail;
      }

      if (response.ok) {
        return true;
      }
      return gettext('Collection name is already existing in the selected production.');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return gettext('Unknown Error.');
    }
  },
  300,
  { onlyResolvesLast: false, key: (_, productionId) => `${productionId}` },
);

/**
 * Widget for a valid new collection name in a given production.
 *
 * The widget registers itself at a react-hook-form form provided via a form context.
 *
 * The registered value is a string specifying a collection name. The widget expects another field to be registered
 * in the form that specifies a production. The name is validated against the existent collections in that production
 * and may not clash with a name already in the production.
 *
 * @param id The id of the rendered dom node.
 * @param name The name under wich to register at the react-hook-form form.
 * @param productionField The name of the form field for the production.
 * @param productionTransform A function that transforms the production form field to a production id.
 * @constructor
 */
export const NewCollectionNameInputWidget = <
  TFieldValues extends FieldValues,
  TProductionValue extends PathValue<TFieldValues, TProductionPath>,
  TPath extends FieldPathByValue<TFieldValues, string> = FieldPathByValue<TFieldValues, string>,
  TProductionPath extends FieldPathByValue<TFieldValues, TProductionValue> = FieldPathByValue<
    TFieldValues,
    TProductionValue
  >,
>({
  id,
  name,
  productionField,
  productionTransform,
}: {
  id: string;
  name: TPath;
  productionField: TProductionPath;
  productionTransform: (arg0: TProductionValue) => number | null;
}): ReactElement => {
  const dispatch = useDispatch();
  const methods = useFormContext<TFieldValues>();
  const {
    control,
    trigger,
    formState: { dirtyFields, touchedFields },
    watch,
  } = methods;

  const productionFieldHadInteraction =
    dirtyFields[productionField] || touchedFields[productionField];

  const productionId = productionTransform(watch(productionField));
  const lastProductionIdRef = useRef(productionId);

  const collectionHref = useSelector(getCollectionLink);

  const token = useSelector(loginSelectors.getToken);

  // Check that the collection name is not already taken.
  const validateCollectionAssetName = useCallback(
    (rawCollectionName: string) =>
      debouncedValidation(rawCollectionName, productionId, collectionHref, token, dispatch),
    [productionId, collectionHref, token, dispatch],
  );

  // react to interactions with the production field
  useEffect(() => {
    if (productionFieldHadInteraction) {
      trigger(name);
    }
  }, [productionFieldHadInteraction, trigger, name]);

  // react to changes of the production ID
  useEffect(() => {
    if (productionId !== lastProductionIdRef.current) {
      lastProductionIdRef.current = productionId;
      trigger(name);
    }
  }, [productionId, trigger, name]);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      validate: validateCollectionAssetName,
    },
    defaultValue: '' as PathValue<TFieldValues, TPath>,
  });

  return (
    <Input
      id={id}
      label={gettext('Collection Name')}
      name={field.name}
      showError={!!error}
      errorMsg={error ? error.message : undefined}
      onChange={field.onChange}
      onBlur={field.onBlur}
      inputRef={field.ref}
      onFocus={() => {
        trigger(productionField);
      }}
    />
  );
};

export default NewCollectionNameInputWidget;
