import { combineReducers } from 'redux';

import {
  ADD_ASSETS_TO_COLLECTION_API_RESPONSE,
  AddAssetsToCollectionApiResponse,
  MenuState,
  ModalState,
  AddAssetsToCollectionRootState,
  RESET_MODAL_API_FEEDBACK,
  ResetModalApiFeedback,
  SET_AVAILABLE_PRODUCTION_SEARCH_URIS,
  SET_CURRENT_ADDABLE_COLLECTION_SEARCH_URI,
  SetAvailableProductionSearchURIs,
  SetCurrentAddableCollectionSearchURI,
} from './types';

export const initialModalState = {
  searchURI: null,
  errors: {},
  success: null,
  isOpen: false,
};

export const modalReducer = (
  state: ModalState = initialModalState,
  action:
    | SetCurrentAddableCollectionSearchURI
    | AddAssetsToCollectionApiResponse
    | ResetModalApiFeedback,
): ModalState => {
  switch (action.type) {
    case SET_CURRENT_ADDABLE_COLLECTION_SEARCH_URI:
      return { ...state, searchURI: action.payload.searchURI };
    case ADD_ASSETS_TO_COLLECTION_API_RESPONSE:
      return { ...state, ...action.payload };
    case RESET_MODAL_API_FEEDBACK:
      return {
        ...state,
        errors: {},
        success: null,
      };
    default:
      return state;
  }
};

export const initialMenuState = {
  error: null,
  availableProductionSearchURIs: [],
};

export const menuReducer = (
  state: MenuState = initialMenuState,
  action: SetAvailableProductionSearchURIs,
): MenuState => {
  switch (action.type) {
    case SET_AVAILABLE_PRODUCTION_SEARCH_URIS:
      return {
        ...state,
        availableProductionSearchURIs: action.payload.availableProductionSearchURIs,
        error: action.payload.error || null,
      };
    default:
      return state;
  }
};

export default combineReducers<AddAssetsToCollectionRootState>({
  menu: menuReducer,
  modal: modalReducer,
});
