import { ReactElement, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, interpolate } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { ExchangeAsset } from 'medialoopster/icons';
import {
  getLink,
  getLinkHref,
  getLinkPermission,
  getResourceURI,
  Link,
  mlRel,
} from 'medialoopster/rest';

import { operationsActions, operationsSelectors } from '../../../../state/modules/operations';
import { AssetExchangeConnector } from '../../../../state/modules/operations/types';
import { VideoAsset } from '../../../../state/modules/video/types';

type Props = {
  readonly assets: ReadonlyArray<VideoAsset>;
  readonly connector: AssetExchangeConnector;
  readonly actionLink: Link;
};

const AssetExchangeActionMenuEntry = ({ assets, connector, actionLink }: Props) => {
  const dispatch = useDispatch();
  const title = interpolate(
    connector.isRemove
      ? gettext('Remove from %(connectorName)s')
      : gettext('Publish on %(connectorName)s'),
    { connectorName: connector.name },
  );
  const linkPermission = getLinkPermission(actionLink, 'POST');
  const assetURIs = assets.map(getResourceURI);
  const assetURIsJSON = JSON.stringify(assetURIs);
  const connectorURI = getResourceURI(connector);
  const removeAssetsURI = getLinkHref(connector, mlRel('remove-assets'));
  useEffect(() => {
    if (removeAssetsURI) {
      dispatch(
        operationsActions.checkExchangedAssetsRemoveAction(
          connectorURI,
          removeAssetsURI,
          JSON.parse(assetURIsJSON),
        ),
      );
    }
  }, [dispatch, assetURIsJSON, connectorURI, removeAssetsURI]);
  return (
    <MenuItem
      label={title}
      onClick={() => {
        dispatch(
          (connector.isRemove
            ? operationsActions.removeExchangedAssets
            : operationsActions.exchangeAssets)(
            actionLink.href,
            assetURIs,
            Object.fromEntries(assets.map((asset) => [getResourceURI(asset), asset.name])),
          ),
        );
      }}
      disabled={linkPermission.code !== 'ok'}
      tooltip={linkPermission.code === 'ok' ? title : linkPermission.message}
      icon={<ExchangeAsset fontSize="small" />}
    />
  );
};

const useAssetExchangeActionMenuEntries = (assets: ReadonlyArray<VideoAsset>): ReactNode[] => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(operationsActions.fetchAssetExchangeConnectors());
  }, [dispatch]);
  const assetExchangeConnectors = useSelector(operationsSelectors.getAssetExchangeConnectors);

  if (assets.length === 0) {
    return [];
  }

  return assetExchangeConnectors
    .map((connector) => {
      const actionLink = getLink(
        connector,
        mlRel(connector.isRemove ? 'remove-assets' : 'exchange-assets'),
      );
      if (!actionLink) {
        return null;
      }
      return (
        <AssetExchangeActionMenuEntry
          key={`connector-${connector.id}`}
          assets={assets}
          connector={connector}
          actionLink={actionLink}
        />
      );
    })
    .filter((element): element is ReactElement => !!element);
};

export default useAssetExchangeActionMenuEntries;
