import { ReactNode, FC, memo } from 'react';

import { SearchAssetCollectionResult } from '../../../../state/modules/rest/collectionsearchresult/types';
import IsArchivedStatus from '../IsArchivedStatus';
import StatusBar from '../StatusBar';
import StatusElement from '../StatusElement';
import IsSharedStatus from './IsSharedStatus';

type Props = {
  readonly assetFormat?: ReactNode;
  readonly asset: SearchAssetCollectionResult;
  readonly isWide: boolean;
  readonly renderAssetStatusIcon: () => JSX.Element;
  readonly statusTitle: string;
  readonly statusLevel: string;
  readonly showLabelAssetStatus: boolean;
};

const AssetCollectionStatusBar: FC<Props> = ({
  assetFormat = undefined,
  asset,
  isWide,
  renderAssetStatusIcon,
  statusTitle,
  statusLevel,
  showLabelAssetStatus,
}: Props) => {
  return (
    <StatusBar>
      {assetFormat}
      {asset.is_archive ? <IsArchivedStatus showLabel={isWide} /> : null}
      {asset.sharedProductionLabels.length > 0 && (
        <IsSharedStatus showLabel={isWide} sharedWith={asset.sharedProductionLabels} />
      )}
      <StatusElement
        renderIcon={renderAssetStatusIcon}
        title={statusTitle}
        label={statusTitle}
        statusLevel={statusLevel}
        showLabel={showLabelAssetStatus}
      />
    </StatusBar>
  );
};

export default memo(AssetCollectionStatusBar);
