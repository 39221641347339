import { FC, useState } from 'react';

import MultipleChoiceSelect from './index';
import { Choice } from './types';

type Props = {
  id?: string;
  name: string;
  onChange?: (value: string[]) => void;
  label?: string;
  choices?: ReadonlyArray<Choice>;
  choiceDisplay: keyof Choice;
  choiceValue: keyof Choice;
  readOnly?: boolean;
  showError?: boolean;
  errorMsg?: string;
  defaultValue?: Array<string | number>;
};

const Uncontrolled: FC<Props> = ({
  id,
  name,
  label = '',
  choices = [],
  choiceDisplay,
  choiceValue,
  readOnly = false,
  showError = false,
  errorMsg = '',
  onChange = () => {},
  defaultValue = [],
}: Props) => {
  const [values, setValues] = useState(defaultValue);

  const onSelectChange = (vals: string[]) => {
    setValues(vals);
    onChange(vals);
  };

  return (
    <MultipleChoiceSelect
      id={id}
      name={name}
      label={label}
      readOnly={readOnly}
      showError={showError}
      errorMsg={errorMsg}
      value={values}
      onChange={onSelectChange}
      choices={choices}
      choiceValue={choiceValue}
      choiceDisplay={choiceDisplay}
    />
  );
};

export default Uncontrolled;
