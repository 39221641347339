import { List, ListItem, ListItemText } from '@mui/material';
import { FunctionComponent } from 'react';

import { gettext } from '../../../Internationalization';
import { DefinedRange, DateRange } from '../types';
import { getCurrentDateRange } from '../utils';

type DefinedRangesProps = {
  setRange: (dateRange: Required<DateRange>) => void;
  selectedRange: DateRange;
  ranges: DefinedRange[];
};

const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return fStart.hasSame(sStart, 'minute') && fEnd.hasSame(sEnd, 'minute');
  }
  return false;
};

const DefinedRanges: FunctionComponent<DefinedRangesProps> = ({
  ranges,
  setRange,
  selectedRange,
}: DefinedRangesProps) => {
  let isCustom = true;
  const dateRangeEntries = ranges.map((range, idx) => {
    const isSame = isSameRange(getCurrentDateRange(range), selectedRange);
    if (isSame) {
      isCustom = false;
    }
    return (
      <ListItem
        button
        id={`predefined-range-${idx + 1}`}
        key={`predefined-range-${idx + 1}`}
        onClick={() => {
          setRange(getCurrentDateRange(range) as Required<DateRange>);
        }}
        aria-pressed={isSame}
      >
        <ListItemText
          primaryTypographyProps={{
            variant: 'body1',
            style: {
              fontWeight: isSame ? 'bold' : 'normal',
            },
          }}
        >
          {range.label}
        </ListItemText>
      </ListItem>
    );
  });
  return (
    <List dense disablePadding>
      {dateRangeEntries}
      <ListItem button key="custom" disabled={!isCustom} aria-pressed={isCustom}>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body1',
            style: {
              fontWeight: isCustom ? 'bold' : 'normal',
            },
          }}
        >
          {gettext('Custom')}
        </ListItemText>
      </ListItem>
    </List>
  );
};

export default DefinedRanges;
