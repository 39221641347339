import { ReactElement, useMemo } from 'react';
import {
  FieldValues,
  FieldPathByValue,
  useFormContext,
  PathValue,
  useController,
} from 'react-hook-form';

import { gettext } from 'medialoopster/Internationalization';
import { getResourceTypeName } from 'medialoopster/rest';

import { AssetType, MediaAssetResource } from '../../../state/types/asset/baseTypes';
import MultipleChoiceSelect from '../../../ui/components/MultipleChoiceSelect';
import { Choice } from '../../../ui/components/MultipleChoiceSelect/types';

/**
 * A single return value from AssetMultiSelectWidget.
 *
 * Represents a selected asset by specifying its id, name and type. Additionally contains
 * the key as used in the select input.
 */
export interface KeyedAsset extends Choice {
  id: number;
  name: string;
  type: AssetType;
  key: string;
}

/**
 * A list of KeyedAssets.
 */
export type KeyedAssets = ReadonlyArray<KeyedAsset>;

/**
 * Assets need to be traceable, preselected and have asset type for api.
 * E.g. when given videoassetId=1 and audioassetId=1.
 * @param assets - The assets from getSelectedListAssets.
 * @returns - Assets marked as preselected for user to choose from.
 */
const makeSelectAssets = (assets: ReadonlyArray<MediaAssetResource>): KeyedAssets =>
  assets.map((asset, index) => ({
    id: asset.id,
    name: asset.name,
    type: getResourceTypeName(asset),
    key: String(index),
  }));

const validateAssets = (assets: KeyedAssets) => {
  if (assets.length === 0) {
    return gettext('No assets selected.');
  }
  return true;
};

/**
 * A widget to select multiple assets from a given set of sharable assets.
 *
 * The widget will register itself under the given name at a react-hook-form form provided via
 * a form context. The value registerd is a list of KeyedAssets that represent the chosen assets.
 * By default, all given assets are selected.
 *
 * @param id The id for the rendered dom element.
 * @param name The name to register with at the react-hook-form form.
 * @param assets The set of assets the user can choose from.
 * @constructor
 */
export const AssetMultiSelectWidget = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, KeyedAssets> = FieldPathByValue<
    TFieldValues,
    KeyedAssets
  >,
>({
  id,
  name,
  assets,
}: {
  id: string;
  name: TPath;
  assets: ReadonlyArray<MediaAssetResource>;
}): ReactElement => {
  const methods = useFormContext<TFieldValues>();
  const { control } = methods;

  const keyedAssets = useMemo(() => makeSelectAssets(assets), [assets]);
  const keyedAssetMap = useMemo(() => {
    const map = new Map();
    keyedAssets.forEach((asset) => map.set(asset.key, asset));
    return map;
  }, [keyedAssets]);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: keyedAssets as PathValue<TFieldValues, TPath>,
    rules: {
      validate: validateAssets,
    },
  });

  return (
    <MultipleChoiceSelect
      id={id}
      name={field.name}
      value={field.value.map((ke: KeyedAsset) => ke.key)}
      ref={field.ref}
      onChange={(values: string[]) => {
        const selectedAssets: KeyedAssets = values.map((key: string) => keyedAssetMap.get(key));
        field.onChange(selectedAssets);
      }}
      label={gettext('Assets to Link')}
      choices={keyedAssets}
      choiceDisplay="name"
      choiceValue="key"
      showError={!!error}
      errorMsg={error ? error.message : ''}
    />
  );
};

export default AssetMultiSelectWidget;
