export const DOWNLOAD_FROM_URL = 'MAIN/DOWNLOAD_FROM_URL';

export interface DownloadFromURL {
  readonly type: typeof DOWNLOAD_FROM_URL;
  readonly payload: {
    readonly url: string;
    readonly headers: Readonly<Record<string, string>>;
    readonly fileCreateError: string;
    readonly requestError: string;
  };
}
