import { APIError, APIFieldError } from 'medialoopster/rest';

import {
  ASSET_COLLECTION_CREATED_RESULT,
  AssetCollectionCreatedResult,
  CREATE_ASSET_COLLECTION,
  CreateAssetCollection,
} from './types';

export const assetCollectionCreatedResult = (
  assetCollectionHref?: string,
  errors?: ReadonlyArray<APIError | APIFieldError>,
): AssetCollectionCreatedResult => ({
  type: ASSET_COLLECTION_CREATED_RESULT,
  payload: {
    assetCollectionHref,
    errors,
  },
});

export const createAssetCollection = (
  productionId: number,
  name: string,
  audioAssetIds: ReadonlyArray<number>,
  imageAssetIds: ReadonlyArray<number>,
  videoAssetIds: ReadonlyArray<number>,
): CreateAssetCollection => ({
  type: CREATE_ASSET_COLLECTION,
  payload: {
    productionId,
    name,
    audioAssetIds,
    imageAssetIds,
    videoAssetIds,
  },
});
