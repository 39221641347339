import {
  FETCH_LICENSE,
  FetchLicense,
  LICENSE_LOADED,
  LicenseLoaded,
  SITE_COUNT_LOADED,
  SiteCountLoaded,
} from './types';

/**
 * Load the current license medialoopster license.
 */
export const fetchLicense = (): FetchLicense => ({
  type: FETCH_LICENSE,
});

export const licenseLoaded = (license: string): LicenseLoaded => ({
  type: LICENSE_LOADED,
  payload: { license },
});

export const siteCountLoaded = (siteCount: number): SiteCountLoaded => ({
  type: SITE_COUNT_LOADED,
  payload: { siteCount },
});
