import { useEffect } from 'react';
import { DragElementWrapper, DragSourceOptions, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import { getResourceTypeName } from 'medialoopster/rest';

import { buildDraggableFavoritesItem } from '../../businessRules/models/FavoriteListsHelpers';
import { FavoritesItemAsset, FavoritesItemAssetType } from '../../state/modules/favorites/types';
import { searchSelectors } from '../../state/modules/search';

export default (
  asset: Exclude<FavoritesItemAsset, null>,
  assetType: FavoritesItemAssetType,
): DragElementWrapper<DragSourceOptions> => {
  const selection = useSelector(searchSelectors.getSelection);
  const isSelection =
    selection.assetTypeName === getResourceTypeName(asset) && selection.assetIds.includes(asset.id);
  const type = isSelection ? 'asset-selection' : assetType;
  const [, dragRef, preview] = useDrag({
    type,
    item: () => {
      if (isSelection) {
        return {
          type,
          assetTypeName: selection.assetTypeName,
          assetIds: selection.assetIds,
        };
      }
      return buildDraggableFavoritesItem(asset.id, assetType, asset, asset.thumbnail_url);
    },
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return dragRef;
};
