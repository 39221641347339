import { TypographyProps } from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { FC, ReactNode } from 'react';

import InplaceDateEdit from '../../../ui/components/InplaceEdits/InplaceDateEdit';

type Props = {
  readonly value: string | null;
  readonly canEdit?: boolean;
  readonly variant?: TypographyProps['variant'];
  readonly onSubmit?: (value: string | null) => void;
  readonly submitFormat?: string;
  readonly disablePast?: boolean;
};

const CalendarDate: FC<Props> = ({
  value,
  canEdit = false,
  variant = 'body2',
  onSubmit,
  submitFormat,
  disablePast = false,
}: Props) => {
  let textComponents: ReactNode = value ?? '';
  if (value) {
    textComponents = <>{DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL)}</>;
  }
  const editValue = value ?? '';
  return (
    <InplaceDateEdit
      textComponents={<span>{textComponents}</span>}
      onSubmit={onSubmit}
      editValue={editValue}
      canEdit={canEdit}
      variant={variant}
      dense
      component="p"
      minWidth="250px"
      submitFormat={submitFormat}
      disablePast={disablePast}
    />
  );
};

export default CalendarDate;
