/**
 * Problem:
 * This script solves the fullscreen api problem for different browsers.
 * Element.requestFullscreen() is not working on Safari. It is not reliable to only use that.
 * Browsers are using different fullscreen api. It creates
 * inconsistency. Because of that, we implemented this solution.
 */

export interface DocumentWithFullscreen extends Document {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

export const isFullScreen = (document: Document): boolean => {
  const doc = document as DocumentWithFullscreen;
  return !!(
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  );
};

export interface DocumentElementWithFullscreen extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export const requestFullScreen = (element: DocumentElementWithFullscreen): void => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  }
};

export const exitFullScreen = (doc: DocumentWithFullscreen): void => {
  if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  } else if (doc.exitFullscreen) {
    doc.exitFullscreen();
  }
};

export const toogleFullScreen = (element: HTMLMediaElement | null, document: Document): void => {
  if (element) {
    if (!isFullScreen(document)) {
      requestFullScreen(element);
    } else {
      exitFullScreen(document);
    }
  }
};
