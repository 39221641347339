import { getSessionAuthHeader } from 'medialoopster';
import { Action } from 'redux';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { EMPTY, merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { gettext } from 'medialoopster/Internationalization';

import { UNAUTHORIZED_REQUEST_ACTION, UnauthorizedRequestAction } from '../../rest';
import {
  alertError,
  clearAlert,
  clearToken,
  initApp,
  loginFailure,
  loginSuccess,
  logout,
  resetStore,
} from './actions';
import { getTokenAuthHeader } from './getTokenAuthHeader';
import { getToken } from './selectors';
import {
  AlertError,
  ClearAlert,
  ClearToken,
  InitApp,
  LOGIN_REQUEST,
  LoginFailure,
  LoginRequest,
  LoginRootState,
  LoginSuccess,
  Logout,
  LOGOUT,
  ResetStore,
  RootState,
  Token,
} from './types';

const callLoginEndpointToken = (username: string, password: string, session = true) =>
  ajax<{ token: Token; user_id: number }>({
    method: 'POST',
    url: '/api/token-auth/',
    body: { username, password, session },
    headers: {
      'Content-Type': 'application/json',
      ...getSessionAuthHeader(),
    },
  }).pipe(
    mergeMap((response) =>
      merge(of(clearAlert()), of(loginSuccess(response.response.token, response.response.user_id))),
    ),
    catchError((response) => {
      if (response) {
        return merge(of(loginFailure()), of(alertError(response.response.errors[0].detail)));
      }
      return merge(
        of(loginFailure()),
        of(alertError(gettext('medialoopster server is not reachable.'))),
      );
    }),
  );

export const userLoginEpic: Epic<Action, ClearAlert | LoginSuccess | LoginFailure | AlertError> = (
  action$,
) =>
  action$.pipe(
    ofType<Action, LoginRequest['type'], LoginRequest>(LOGIN_REQUEST),
    switchMap(({ payload: { username, password } }) => callLoginEndpointToken(username, password)),
  );

const callLogoutEndpointToken = (token: Token) => {
  if (token === '') {
    return EMPTY;
  }
  return ajax({
    method: 'POST',
    url: '/api/token-auth/logout/',
    headers: {
      'Content-Type': 'application/json',
      ...getTokenAuthHeader(token),
    },
  }).pipe(
    mergeMap(() => EMPTY),
    catchError(() => EMPTY),
  );
};

export const userLogoutEpic: Epic<Action, ClearToken | ResetStore> = (
  action$,
  state$: StateObservable<{ login: LoginRootState }>,
) =>
  action$.pipe(
    ofType<Action, Logout['type'], Logout>(LOGOUT),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      localStorage.clear();
      return merge(of(clearToken()), of(resetStore()), callLogoutEndpointToken(getToken(state)));
    }),
  );

export const userUnauthorizedEpic: Epic<Action, Logout> = (action$) =>
  action$.pipe(
    ofType<Action, UnauthorizedRequestAction['type'], UnauthorizedRequestAction>(
      UNAUTHORIZED_REQUEST_ACTION,
    ),
    map(() => logout()),
  );

export const initAppEpic: Epic<Action, InitApp, RootState> = (_action$, state$) =>
  state$.pipe(
    map(getToken),
    distinctUntilChanged(),
    filter((token) => token !== ''),
    map(() => initApp()),
  );

export default combineEpics<
  Action,
  | ClearAlert
  | LoginSuccess
  | LoginFailure
  | AlertError
  | ClearToken
  | ResetStore
  | Logout
  | InitApp,
  RootState
>(userLoginEpic, userLogoutEpic, userUnauthorizedEpic, initAppEpic);
