import { TextareaAutosize } from '@mui/base';
import { TypographyProps } from '@mui/material/Typography';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import ButtonGroup from './ButtonGroup';
import OverflowTextField from './OverflowTextField';

interface InplaceTextEditProps {
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  id?: string;
  editValue: string;
  textComponents: NonNullable<ReactNode>;
  canEdit: boolean;
  dense?: boolean;
  component?: 'div' | 'p'; // Eg. "span" does not support text overflow ellipse
  variant?: TypographyProps['variant'];
}

const InplaceTextEdit: FC<InplaceTextEditProps> = ({
  editValue,
  textComponents,
  canEdit,
  variant = 'body1',
  dense = false,
  component = 'div',
  onChange,
  onSubmit,
  id = '',
}: InplaceTextEditProps) => {
  const [value, setValue] = useState(editValue);
  const [isEditing, setIsEditing] = useState(false);
  const handleKeys = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Escape' || event.key === 'Esc') {
      setIsEditing(false);
    }
  };

  const onEditTitle = () => {
    if (!canEdit) return;
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false);
    }
  }, [canEdit]);

  const areaRef = useRef<HTMLTextAreaElement>(null);

  const onValueChange: ChangeEventHandler<HTMLTextAreaElement> = (
    e: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    e.stopPropagation();
    onChange?.(String(e.target.value));
    setValue(String(e.target.value));
  };

  const handleSubmit = () => {
    setIsEditing(false);
    if (value !== editValue) {
      onSubmit?.(value);
    }
  };
  const handleClose = () => {
    setIsEditing(false);
  };
  return (
    <>
      {!isEditing && (
        <OverflowTextField
          component={component}
          variant={variant}
          onDoubleClick={onEditTitle}
          textComponents={textComponents}
          highlightText={canEdit}
          showPlaceHolder={!editValue && canEdit}
        />
      )}
      {isEditing && (
        <>
          <p style={{ flexGrow: 1 }} />
          <div style={{ width: '100%' }}>
            <TextareaAutosize
              id={id}
              name={id}
              onKeyUp={handleKeys}
              onChange={onValueChange}
              value={value}
              minRows={6}
              style={{ width: '100%', resize: 'both' }}
              ref={areaRef}
              autoFocus
            />
          </div>
          <ButtonGroup onSubmit={handleSubmit} onClose={handleClose} dense={dense} />
        </>
      )}
    </>
  );
};

export default InplaceTextEdit;
