import { Chip, Grid } from '@mui/material';
import Autocomplete, {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useLayoutEffect, useRef } from 'react';

import { pgettext } from 'medialoopster/Internationalization';

import Input from './Input';

type Props = {
  id?: string;
  label?: string;
  name?: string;
  onChange: (value: string[]) => void;
  showError?: boolean;
  errorMsg?: string;
  maxLength?: number;
  options?: string[];
  value?: string[];
  dense?: boolean;
  onSubmit?: () => void;
  filtering?: (value: string, options: string[]) => string[];
  disabled?: boolean;
};

const useStyles = makeStyles(() => ({
  tagContainer: {
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    overflowAnchor: 'revert',
    width: 'min-content',
    maxWidth: '80%',
    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* Chrome, Safari */,
    },
    '& .MuiChip-sizeSmall': {
      height: '22px',
    },
  },
}));

const scrollIntoView = (element: Element) => {
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  });
};

type TagContainerProps = {
  tags: string[];
  getTagProps: AutocompleteRenderGetTagProps;
};

const TagContainer: FC<TagContainerProps> = ({ tags, getTagProps }: TagContainerProps) => {
  const lastChipRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  // When a tag has been added, scroll it into view.
  useLayoutEffect(() => {
    if (lastChipRef.current) {
      scrollIntoView(lastChipRef.current);
    }
  }, [tags, lastChipRef]);
  return (
    <Grid container className={classes.tagContainer}>
      {tags.map((tag, index) => (
        <Grid item key={tag}>
          <Chip
            size="small"
            ref={index === tags.length - 1 ? lastChipRef : undefined}
            label={tag}
            onClick={(e) => scrollIntoView(e.currentTarget)}
            onFocus={(e) => scrollIntoView(e.currentTarget)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const defaultFiltering = (value: string, options: string[]) =>
  options.filter((name) => name.toLowerCase().includes(value.toLowerCase()));

const MultiAutoCompleteInput: FC<Props> = ({
  id,
  label = '',
  name = '',
  onChange,
  showError = false,
  errorMsg = '',
  maxLength = 524288,
  options = [],
  dense = false,
  value = [],
  onSubmit = () => undefined,
  filtering = defaultFiltering,
  disabled = false,
}: Props) => {
  return (
    <Autocomplete
      autoComplete
      multiple
      clearOnBlur={false}
      id={id}
      options={options}
      filterOptions={(allOptions, { inputValue }) => filtering(inputValue, allOptions)}
      value={value}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      getOptionDisabled={(option) => value.includes(option)}
      openText={pgettext('Verb; open element', 'Open')}
      closeText={pgettext('Verb; close element', 'Close')}
      clearText={pgettext('Verb; clear input', 'Clear')}
      renderTags={(tags, getTagProps) => <TagContainer tags={tags} getTagProps={getTagProps} />}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <Input
            id={params.id}
            readOnly={params.disabled}
            // Workaround for options opening despite `openOnFocus=false` (see https://github.com/mui-org/material-ui/issues/20286).
            InputProps={{ ...params.InputProps, onMouseDownCapture: (e) => e.stopPropagation() }}
            inputElementProps={params.inputProps}
            name={name}
            label={label}
            showError={showError}
            errorMsg={showError && !!errorMsg ? errorMsg : undefined}
            maxLength={maxLength}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
            dense={dense}
          />
        );
      }}
      disabled={disabled}
    />
  );
};

export default MultiAutoCompleteInput;
