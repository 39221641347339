import { combineReducers } from 'redux';

import {
  createResourcesReducer,
  createRootLinkReducer,
  mlRel,
  ResourceActionV3,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import {
  ASSETS_BULK_EDIT_FIELDS_LOADED,
  AssetsBulkEditFieldsLoaded,
  BulkEditRootState,
  CLOSE_BULK_EDIT_MODAL,
  CloseBulkEditModal,
  KeywordsErrors,
  LOCK_ASSETS_SUCCESS,
  LockAssetsSuccess,
  SET_BULK_EDIT_FIELD_ERRORS,
  SET_BULK_EDIT_KEYWORDS_FIELD_ERRORS,
  SetBulkEditFieldErrors,
  SetBulkEditKeywordsFieldErrors,
} from './types';

export const modalIsOpenReducer = (
  state = false,
  action: LockAssetsSuccess | CloseBulkEditModal,
): boolean => {
  switch (action.type) {
    case LOCK_ASSETS_SUCCESS:
      return true;
    case CLOSE_BULK_EDIT_MODAL:
      return false;
    default:
      return state;
  }
};

export const errorsReducer = (
  state: ReadonlyRecord<string, ReadonlyArray<string>> = {},
  action: SetBulkEditFieldErrors | CloseBulkEditModal,
): ReadonlyRecord<string, ReadonlyArray<string>> => {
  switch (action.type) {
    case SET_BULK_EDIT_FIELD_ERRORS:
      return action.payload.errors;
    case CLOSE_BULK_EDIT_MODAL:
      return {};
    default:
      return state;
  }
};

export const keywordsErrorsReducer = (
  state: ReadonlyRecord<string, KeywordsErrors> = {},
  action: SetBulkEditKeywordsFieldErrors | CloseBulkEditModal,
): ReadonlyRecord<string, KeywordsErrors> => {
  switch (action.type) {
    case SET_BULK_EDIT_KEYWORDS_FIELD_ERRORS:
      return action.payload.errors;
    case CLOSE_BULK_EDIT_MODAL:
      return {};
    default:
      return state;
  }
};

export const assetsBulkEditFieldsReducer = (
  state: ResourceActionV3 | null = null,
  action: AssetsBulkEditFieldsLoaded | CloseBulkEditModal,
): ResourceActionV3 | null => {
  switch (action.type) {
    case ASSETS_BULK_EDIT_FIELDS_LOADED:
      return action.payload.fields;
    case CLOSE_BULK_EDIT_MODAL:
      return null;
    default:
      return state;
  }
};

export default combineReducers<BulkEditRootState>({
  modalIsOpen: modalIsOpenReducer,
  assetsBulkEditFields: assetsBulkEditFieldsReducer,
  formFields: combineReducers({
    errors: errorsReducer,
    keywordsErrors: keywordsErrorsReducer,
  }),
  actions: combineReducers({
    videoasset: combineReducers<BulkEditRootState['actions']['videoasset']>({
      lockAssetsLink: createRootLinkReducer(mlRel('videoassets_lock')),
      unlockAssetsLink: createRootLinkReducer(mlRel('videoassets_unlock')),
      bulkEditLink: createRootLinkReducer(mlRel('videoassets_bulk_edit')),
      bulkEditResources: createResourcesReducer('videoasset-bulk-edit'),
    }),
    audioasset: combineReducers<BulkEditRootState['actions']['audioasset']>({
      lockAssetsLink: createRootLinkReducer(mlRel('audioassets_lock')),
      unlockAssetsLink: createRootLinkReducer(mlRel('audioassets_unlock')),
      bulkEditLink: createRootLinkReducer(mlRel('audioassets_bulk_edit')),
      bulkEditResources: createResourcesReducer('audioasset-bulk-edit'),
    }),
    imageasset: combineReducers<BulkEditRootState['actions']['imageasset']>({
      lockAssetsLink: createRootLinkReducer(mlRel('imageassets_lock')),
      unlockAssetsLink: createRootLinkReducer(mlRel('imageassets_unlock')),
      bulkEditLink: createRootLinkReducer(mlRel('imageassets_bulk_edit')),
      bulkEditResources: createResourcesReducer('imageasset-bulk-edit'),
    }),
    collection: combineReducers<BulkEditRootState['actions']['collection']>({
      lockAssetsLink: createRootLinkReducer(mlRel('collections_lock')),
      unlockAssetsLink: createRootLinkReducer(mlRel('collections_unlock')),
      bulkEditLink: createRootLinkReducer(mlRel('collections_bulk_edit')),
      bulkEditResources: createResourcesReducer('collection-bulk-edit'),
    }),
  }),
});
