import { TypographyProps } from '@mui/material/Typography';
import { FC, ReactNode, memo, Fragment } from 'react';

import InplaceTextAreaEdit from '../../../ui/components/InplaceEdits/InplaceTextAreaEdit';
import createMultiElementHighlighter from './createMultiElementHighlighter';
import replaceMarkdownLinks from './replaceMarkdownLinks';

type Props = {
  readonly value: string | null;
  readonly allowLinks?: boolean;
  readonly canEdit?: boolean;
  readonly variant?: TypographyProps['variant'];
  readonly highlights?: ReadonlyArray<string>;
  readonly onSubmit?: (value: string) => void;
};

const TextArea: FC<Props> = ({
  value,
  highlights,
  allowLinks = false,
  canEdit = false,
  variant = 'body2',
  onSubmit,
}: Props) => {
  let textComponents: ReactNode = value ?? '';
  const highlight = createMultiElementHighlighter(highlights);
  if (value) {
    textComponents = value.split('\n').map((line, ind, lines) => (
      <Fragment key={`${ind + 1 + line}`}>
        {allowLinks ? replaceMarkdownLinks(line, highlight) : highlight(line)}
        {ind === lines.length - 1 ? '' : '\n'}
      </Fragment>
    ));
  }
  const editValue = value ?? '';
  return (
    <InplaceTextAreaEdit
      editValue={editValue}
      textComponents={textComponents}
      canEdit={canEdit}
      onSubmit={onSubmit}
      dense
      variant={variant}
    />
  );
};

export default memo(TextArea);
