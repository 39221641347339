import { Alert, Box, Tooltip, Typography } from '@mui/material';
import { FC, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { Select, Modal, Input, IconCell, SimpleCell, ErrorCell } from 'medialoopster/components';
import { Collection } from 'medialoopster/icons';

import { detailsSelectors } from '../../state/modules/details';
import { transferActions, transferSelectors } from '../../state/modules/transfer';
import AssetTypeCheckboxTable from '../../ui/components/Tables/AssetTypeCheckboxTable';
import TableLabel from '../../ui/components/Tables/AssetTypeCheckboxTable/TableLabel';

const BulkTransferModal: FC = () => {
  const dispatch = useDispatch();
  const [initial, setInitial] = useState<boolean>(true);
  const [selectedAssetKeys, setSelectedAssetKeys] = useState<ReadonlyArray<string>>([]);
  const visible = useSelector(transferSelectors.isBulkTransferModalVisible);
  const checking = useSelector(transferSelectors.isBulkTransferModalChecking);
  const actionURL = useSelector(transferSelectors.getBulkTransferActionURL);
  const transferAssetChoices = useSelector(transferSelectors.getBulkTransferAssetChoices);
  const deviceChoices = useSelector(transferSelectors.getBulkTransferModalTargetDeviceChoices);
  const selectedAssetURLs = useSelector(transferSelectors.getSelectedAssetURLs);
  const transferableAssetURLs = useSelector(transferSelectors.getTransferableAssetURLs);
  const monitoringURL = useSelector(transferSelectors.getMonitoringURL);
  const collectionChoice = useSelector(transferSelectors.getCollectionChoice);
  const isCurrentCollectionWithoutFiles = useSelector(
    detailsSelectors.isCurrentCollectionWithoutFiles,
  );
  const [collectionError, otherTransferError] = useSelector(
    transferSelectors.getBulkTransferCollectionAndOtherErrors,
  );

  const isValidActionURL = (url: string) => url !== '';
  const isValidAssetURLs = (urls: ReadonlyArray<string>) => urls.length > 0;
  const [filename, setFilename] = useState('');

  const getHeaderTitle = () => {
    if (collectionChoice) {
      return gettext('Transfer Collection');
    }
    return gettext('Transfer Assets');
  };
  const onActionURLChange = (url: string) => {
    setInitial(false);
    if (collectionChoice) {
      dispatch(
        transferActions.checkTransferCollection(url, selectedAssetURLs, collectionChoice.url),
      );
    } else {
      dispatch(transferActions.checkTransferAssets(url, selectedAssetURLs, filename));
    }
  };
  const onClose = () => {
    dispatch(transferActions.closeBulkTransferModal());
    setInitial(true);
  };

  const transferredAssetURLs = transferableAssetURLs.filter((assetUrl) =>
    selectedAssetKeys.includes(assetUrl),
  );

  const onFormSubmit = () => {
    setInitial(false);
    if (
      isValidActionURL(actionURL) &&
      (isValidAssetURLs(transferableAssetURLs) || transferableAssetURLs.length === 0) &&
      collectionChoice
    ) {
      dispatch(
        transferActions.transferCollection(actionURL, transferredAssetURLs, collectionChoice.url),
      );
    } else if (isValidActionURL(actionURL) && isValidAssetURLs(transferableAssetURLs)) {
      dispatch(transferActions.transferAssets(actionURL, transferredAssetURLs, filename));
    }
  };

  const onAssetSelectChange = useCallback(
    (selects: ReadonlyArray<string>) => {
      setInitial(false);
      setSelectedAssetKeys(selects);
    },
    [setSelectedAssetKeys],
  );
  const getSubmitTitle = () => {
    if (checking) {
      return pgettext('Form submit button title', 'Checking assets for device');
    }
    if (!isValidActionURL(actionURL) || selectedAssetKeys.length === 0) {
      return pgettext('Form submit button title', 'Please fill out form');
    }
    if (collectionChoice) {
      return pgettext('Form submit button title', 'Transfer collection');
    }
    return pgettext('Form submit button title', 'Transfer assets');
  };
  const getRowLabel = () => {
    if (collectionChoice) {
      return gettext('Collection assets to transfer');
    }
    return gettext('Assets to transfer');
  };

  const getIsDisabled = () => {
    if (!collectionChoice) {
      return checking || selectedAssetKeys.length === 0 || initial || !isValidActionURL(actionURL);
    }
    return (
      (transferableAssetURLs.length === 0 && transferAssetChoices.length > 0) ||
      !!otherTransferError ||
      checking ||
      initial ||
      !isValidActionURL(actionURL) ||
      !!collectionError ||
      (isCurrentCollectionWithoutFiles && selectedAssetKeys.length === 0)
    );
  };

  const collectionErrorMsg = useMemo(() => {
    if (collectionError) return collectionError;
    if (
      isValidActionURL(actionURL) &&
      selectedAssetKeys.length === 0 &&
      isCurrentCollectionWithoutFiles
    )
      return gettext('Collection has no files');
    return '';
  }, [isCurrentCollectionWithoutFiles, selectedAssetKeys, collectionError, actionURL]);
  return (
    <Modal id="bulk-transfer-modal" open={visible} onClose={onClose}>
      <Modal.Header headerTitle={getHeaderTitle()} onClose={onClose} />
      <Modal.Body>
        <Select
          id="bulk-transfer-target-device"
          name="device"
          label={pgettext('Field label; Title-style capitalization', 'Target Device')}
          onChange={onActionURLChange}
          choices={[{ value: '', display: '' }, ...deviceChoices]}
          showError={!initial && !isValidActionURL(actionURL)}
          errorMsg={
            !initial && !isValidActionURL(actionURL) ? gettext('You must select a device.') : ''
          }
        />
        {collectionChoice && otherTransferError && (
          <Alert severity="warning">
            <Typography>{otherTransferError}</Typography>
          </Alert>
        )}
        {collectionChoice ? (
          <Box>
            <TableLabel label={gettext('Collection')} />
            <div data-testid="collection-transfer-row">
              <IconCell noBorder component="span">
                <Tooltip
                  title={
                    !collectionChoice.hasFiles ? gettext('Collection has no files') : undefined
                  }
                  placement="left"
                >
                  <Collection sx={{ color: (theme) => theme.palette.text.disabled }} />
                </Tooltip>
              </IconCell>
              <SimpleCell key="display" value={collectionChoice.name} noBorder component="span" />
              <ErrorCell error={collectionErrorMsg} noBorder component="span" />
            </div>
          </Box>
        ) : (
          <Input
            id="bulk-transfer-target-filename"
            label={gettext('Target filename')}
            onChange={setFilename}
            readOnly={transferredAssetURLs.length !== 1}
          />
        )}
        <AssetTypeCheckboxTable
          dense
          onSelectChange={onAssetSelectChange}
          rowData={transferAssetChoices}
          label={getRowLabel()}
          defaultSelection={transferableAssetURLs}
          disabledTableInfo={gettext('No transferable assets')}
          emptyChoicesInfo={gettext('No assets selected')}
          loading={checking && isValidActionURL(actionURL)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ActionButton
          href={monitoringURL || ''}
          label={pgettext('Form action button label', 'Show Transfers')}
          title={pgettext('Form action button title', 'Show transfer activities in monitoring')}
          disabled={!monitoringURL}
        />
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel this form')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          onClick={onFormSubmit}
          label={pgettext('Form submit button label', 'Start Transfer')}
          title={getSubmitTitle()}
          disabled={getIsDisabled()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default BulkTransferModal;
