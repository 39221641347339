import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import {
  ActionMenuEntry,
  ActionMenuGroup,
  ActionMenu,
  ConfirmationModal,
} from 'medialoopster/components';
import { Delete, RevokeShare, ShareAsset, ShowMenu } from 'medialoopster/icons';

import { detailsActions, detailsSelectors } from '../../../../state/modules/details';

interface AnnotationsActionMenuEntry extends ActionMenuEntry {
  readonly confirmation: {
    readonly title: string;
    readonly message: string;
    readonly label: string;
  };
}

const actionGroups: ReadonlyArray<ActionMenuGroup<AnnotationsActionMenuEntry>> = [
  {
    key: 'actions',
    actions: [
      {
        id: 'publish_all',
        label: gettext('Publish all'),
        tooltip: gettext('Publish all of your unpublished annotations'),
        icon: <ShareAsset />,
        confirmation: {
          title: pgettext('Title-style capitalization', 'Publish All Annotations'),
          message: gettext('Do you really want to publish all of your annotations?'),
          label: gettext('Publish'),
        },
      },
      {
        id: 'unpublish_all',
        label: gettext('Unpublish all'),
        tooltip: gettext('Unpublish all of your published annotations'),
        icon: <RevokeShare />,
        confirmation: {
          title: pgettext('Title-style capitalization', 'Unpublish All Annotations'),
          message: gettext('Do you really want to unpublish all of your annotations?'),
          label: gettext('Unpublish'),
        },
      },
      {
        id: 'delete_all',
        label: gettext('Delete all'),
        tooltip: gettext('Delete all of your annotations'),
        icon: <Delete />,
        confirmation: {
          title: pgettext('Title-style capitalization', 'Delete All Annotations'),
          message: gettext('Do you really want to delete all of your annotations?'),
          label: gettext('Delete'),
        },
      },
    ],
  },
];

const AnnotationsActionMenu: FC = () => {
  const dispatch = useDispatch();
  const [currentAction, setCurrentAction] = useState<AnnotationsActionMenuEntry | null>(null);
  const hasPublishedAnnotations = useSelector(detailsSelectors.getHasPublishedAnnotations);
  const hasUnpublishedAnnotations = useSelector(detailsSelectors.getHasUnpublishedAnnotations);
  const [open, setOpen] = useState(false);
  const onCancel = () => {
    setOpen(false);
  };
  const onAction = (action: ActionMenuEntry) => {
    setCurrentAction(action as AnnotationsActionMenuEntry);
    setOpen(true);
  };
  const isActionDisabled = (action: ActionMenuEntry) => {
    switch (action.id) {
      case 'publish_all':
        return !hasUnpublishedAnnotations;
      case 'unpublish_all':
        return !hasPublishedAnnotations;
      default:
        return false;
    }
  };
  const getDisabledReason = (action: ActionMenuEntry) => {
    switch (action.id) {
      case 'publish_all':
        return gettext('There are no unpublished annotations to publish.');
      case 'unpublish_all':
        return gettext('There are no published annotations to unpublish.');
      default:
        return '';
    }
  };
  const submitAction = () => {
    switch (currentAction?.id) {
      case 'publish_all':
        dispatch(detailsActions.publishAllAnnotations());
        break;
      case 'unpublish_all':
        dispatch(detailsActions.unpublishAllAnnotations());
        break;
      case 'delete_all':
        dispatch(detailsActions.deleteAllAnnotations());
        break;
      default:
        break;
    }
    setOpen(false);
  };
  return (
    <>
      {currentAction && (
        <ConfirmationModal
          title={currentAction.confirmation.title}
          message={currentAction.confirmation.message}
          label={currentAction.confirmation.label}
          icon={currentAction.icon}
          open={open}
          onCancel={onCancel}
          onConfirm={submitAction}
        />
      )}
      <ActionMenu
        icon={<ShowMenu />}
        label={gettext('Show annotation menu')}
        actionGroups={actionGroups}
        onAction={onAction}
        isActionDisabled={isActionDisabled}
        getDisabledReason={getDisabledReason}
      />
    </>
  );
};

export default AnnotationsActionMenu;
