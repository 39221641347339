import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { pgettext } from 'medialoopster/Internationalization';
import { Modal } from 'medialoopster/components';

import { Webhook } from '../../../../../state/modules/operations/types';
import WebhookForm from './WebhookForm';

type Props = {
  readonly show: boolean;
  readonly activeWebhook: Webhook;
  readonly onClose: () => void;
  readonly onSubmit: (values: unknown) => void;
};

const WebhookModal: FC<Props> = ({ show, activeWebhook, onClose, onSubmit }: Props) => {
  const { control, handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal id="webhook-modal" open={show} onClose={onClose}>
        <Modal.Header
          headerTitle={pgettext('Title-style capitalization', 'Start Workflow')}
          onClose={onClose}
        />
        <Modal.Body>
          <WebhookForm
            formFields={activeWebhook.custom_payload_fields}
            control={control}
            handleSubmit={handleSubmit(onSubmit)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.CancelButton
            onClick={onClose}
            title={pgettext('Form cancel button title', 'Cancel')}
          />
          <Modal.Footer.SubmitButton
            onClick={handleSubmit(onSubmit)}
            label={pgettext('Form submit button label', 'Start Workflow')}
            title={pgettext('Form submit button title', 'Start workflow')}
          />
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default WebhookModal;
