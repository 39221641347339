import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { IconButton } from 'medialoopster/components';
import { RevokeShare, ShareAsset as ShareIcon } from 'medialoopster/icons';
import { getResourceURI } from 'medialoopster/rest';

import { Annotation } from '../../../../../../state/modules/annotation/types';
import { detailsActions } from '../../../../../../state/modules/details';

/**
 * The button that handles the sharing/publicising of an annotation.
 *
 * @param annotation The annotation to publish.
 */
const ShareAnnotationButton: FC<{ annotation: Annotation }> = ({ annotation }) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(
      detailsActions.editAnnotation(getResourceURI(annotation), {
        is_public: !annotation.is_public,
      }),
    );
  }, [annotation, dispatch]);

  return (
    <IconButton
      aria-label="share"
      onClick={onClick}
      dense
      title={
        annotation.is_public
          ? gettext('Unpublish this annotation')
          : gettext('Publish this annotation')
      }
    >
      {annotation.is_public ? (
        <RevokeShare fontSize="inherit" color="inherit" />
      ) : (
        <ShareIcon fontSize="inherit" color="inherit" />
      )}
    </IconButton>
  );
};

export default ShareAnnotationButton;
