import Tooltip from '@mui/material/Tooltip';
import { TypographyProps } from '@mui/material/Typography';
import { ReactNode, FC, KeyboardEvent, useState, useEffect } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { AutoSuggestInput } from 'medialoopster/components';

import ButtonGroup from './ButtonGroup';
import OverflowTextField from './OverflowTextField';

interface InplaceComboBoxEditProps {
  minWidth?: string;
  onChange?: (value: string | null) => void;
  onSubmit?: (value: string) => void;
  maxLength?: number;
  id?: string;
  editValue: string;
  textComponents: NonNullable<ReactNode>;
  canEdit: boolean;
  dense?: boolean;
  component?: 'div' | 'p'; // Eg. "span" does not support text overflow ellipse
  options?: ReadonlyArray<string>;
  validationRule?: string;
  variant?: TypographyProps['variant'];
}

const hasValueError = (value: string | null, rule?: string): boolean => {
  if (value && rule) {
    const pattern = RegExp(`^${rule}$`);
    return !pattern.test(value);
  }
  return false;
};

const InplaceComboBoxEdit: FC<InplaceComboBoxEditProps> = ({
  minWidth,
  onChange,
  onSubmit,
  maxLength,
  id = '',
  editValue,
  canEdit,
  textComponents,
  variant = 'body1',
  dense = false,
  component = 'div',
  options = [],
  validationRule = '',
}: InplaceComboBoxEditProps) => {
  const [value, setValue] = useState(editValue);
  const [showError, setShowError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = () => {
    setIsEditing(false);
    setShowError(false);
    setValue(editValue);
    if (value !== editValue) {
      onSubmit?.(value);
    }
  };

  const handleKeys = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!showError) {
        handleSubmit();
      }
    }
    if (event.key === 'Escape' || event.key === 'Esc') {
      setIsEditing(false);
      setValue(editValue);
    }
  };

  const onEditTitle = () => {
    if (!canEdit) return;
    if (!isEditing) {
      setShowError(hasValueError(value, validationRule));
      setIsEditing(true);
    }
  };

  useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  useEffect(() => {
    if (!canEdit) {
      setIsEditing(false);
    }
  }, [canEdit]);

  const onValueChange = (val: string | null) => {
    onChange?.(val);
    setValue(val ?? '');
    setShowError(hasValueError(val, validationRule));
  };

  const handleClose = () => {
    setIsEditing(false);
    setValue(editValue);
  };
  return (
    <>
      {!isEditing && (
        <OverflowTextField
          component={component}
          variant={variant}
          onDoubleClick={onEditTitle}
          textComponents={textComponents}
          highlightText={canEdit}
          showPlaceHolder={!editValue && canEdit}
        />
      )}
      {isEditing && (
        <div style={{ display: 'flex' }}>
          <div style={{ minWidth, marginRight: '0.5em' }} data-testid="inputWrapper">
            <AutoSuggestInput
              id={id}
              name={id}
              onKeyUp={handleKeys}
              onChange={onValueChange}
              options={options as string[]} // type from mui autosuggest
              filtering={(val, opts) =>
                opts.filter((name) => name.toLowerCase().includes(val.toLowerCase()))
              }
              value={value}
              autoFocus
              maxLength={maxLength}
              dense={dense}
              showError={showError}
            />
          </div>
          <Tooltip open={showError} title={showError ? gettext('Please enter valid format') : ''}>
            <ButtonGroup
              onSubmit={handleSubmit}
              onClose={handleClose}
              dense={dense}
              disableSubmit={showError}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default InplaceComboBoxEdit;
