import { useContext, createContext, FC, ReactNode, useMemo } from 'react';
import shaka from 'shaka-player';

import VideoController from './VideoController';

export interface VideoControllerContextProviderProps {
  readonly children: ReactNode | ReactNode[];
}

export type SetVideoPlayer = (player: shaka.Player | null) => void;

const VideoControllerContextValue = createContext<Readonly<VideoController>>(new VideoController());
export const useVideoController = (): Readonly<VideoController> =>
  useContext(VideoControllerContextValue);

/**
 * A Context that provides the VideoController for the VideoPlayer in context.
 *
 * The context value retrieved with useVideoController always returns a VideoController.
 *
 * This VideoController provides default values even is a shaka.Player or hmtl video element is not available yet.
 *
 */
const VideoControllerContextProvider: FC<VideoControllerContextProviderProps> = ({
  children,
}: VideoControllerContextProviderProps) => {
  const videoController = useMemo(() => new VideoController(), []);

  return (
    <VideoControllerContextValue.Provider value={videoController}>
      {children}
    </VideoControllerContextValue.Provider>
  );
};

export default VideoControllerContextProvider;
