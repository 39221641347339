import { Tooltip, TooltipProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';
import { FC, memo } from 'react';

import { gettext, pgettext } from 'medialoopster/Internationalization';

import BaseCell, { BaseCellProps } from './base/BaseCell';

const useStyles = makeStyles(() => ({
  tooltipTitle: {
    whiteSpace: 'pre-line',
  },
}));

type Props = {
  readonly started: string;
  readonly ended: string | null;
  readonly tooltipPlacement?: TooltipProps['placement'];
} & Pick<BaseCellProps, 'disabled'>;

const StartStopCell: FC<Props> = ({
  started,
  ended,
  tooltipPlacement = 'left',
  disabled = false,
}: Props) => {
  const classes = useStyles();
  return (
    <BaseCell disabled={disabled}>
      <Tooltip
        title={
          ended ? (
            <Typography variant="body2" className={classes.tooltipTitle}>
              {`${gettext('Finished')}: ${DateTime.fromISO(ended).toFormat(
                pgettext('Localized numeric date and time with seconds', 'MM/dd/yyyy h:mm:ss a'),
              )}`}
            </Typography>
          ) : (
            ''
          )
        }
        placement={tooltipPlacement}
      >
        <span>
          {DateTime.fromISO(started).toFormat(
            pgettext('Localized numeric date and time with seconds', 'MM/dd/yyyy h:mm:ss a'),
          )}
        </span>
      </Tooltip>
    </BaseCell>
  );
};

export default memo(StartStopCell);
