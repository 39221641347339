import {
  APIError,
  Link,
  Resource,
  ResourceOptionsV2,
  BaseResource,
  CollectionResourceMap,
} from 'medialoopster/rest';

import { AssetType } from '../../types/asset/baseTypes';

export const SET_CAN_SEE_CURRENT_ASSET_PROXY_DOWNLOAD =
  'FRONTEND/DOWNLOAD/SET_CAN_SEE_CURRENT_ASSET_PROXY_DOWNLOAD';
export const SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING =
  'FRONTEND/DOWNLOAD/SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING';
export const SET_CURRENT_ASSET_PROXY_DOWNLOAD_READY =
  'FRONTEND/DOWNLOAD/SET_CURRENT_ASSET_PROXY_DOWNLOAD_READY';
export const SET_CURRENT_ASSET_PROXY_DOWNLOAD_ERRORS =
  'FRONTEND/DOWNLOAD/SET_CURRENT_ASSET_PROXY_DOWNLOAD_ERRORS';

export const SET_CURRENT_ASSET_PROXY_DOWNLOAD_OPEN =
  'FRONTEND/DOWNLOAD/SET_CURRENT_ASSET_PROXY_DOWNLOAD_OPEN';
export const SET_CURRENT_ASSET_PROXY_DOWNLOAD_DRY_RUN_ERRORS =
  'FRONTEND/DOWNLOAD/SET_CURRENT_ASSET_PROXY_DOWNLOAD_DRY_RUN_ERRORS';

export const UPDATE_CURRENT_ASSET_PROXY_DOWNLOAD_ACTIVITY =
  'FRONTEND/DOWNLOAD/UPDATE_CURRENT_ASSET_PROXY_DOWNLOAD_ACTIVITY';
export interface SetCanSeeCurrentAssetProxyDownload {
  readonly type: typeof SET_CAN_SEE_CURRENT_ASSET_PROXY_DOWNLOAD;
  readonly payload: {
    readonly canSeeCurrenAssetProxyDownload: boolean;
  };
}

export interface SetCurrentAssetProxyDownloadPreparing {
  readonly type: typeof SET_CURRENT_ASSET_PROXY_DOWNLOAD_PREPARING;
}
export interface SetCurrentAssetProxyDownloadOpen {
  readonly type: typeof SET_CURRENT_ASSET_PROXY_DOWNLOAD_OPEN;
  readonly payload: {
    isOpen: boolean;
  };
}
export interface UpdateCurrentAssetProxyDownloadActivity {
  readonly type: typeof UPDATE_CURRENT_ASSET_PROXY_DOWNLOAD_ACTIVITY;
  readonly payload: {
    activity: AssetProxyDownloadActivity;
  };
}

export interface SetCurrentAssetProxyDownloadReady {
  readonly type: typeof SET_CURRENT_ASSET_PROXY_DOWNLOAD_READY;
}

export interface SetCurrentAssetProxyDownloadErrors {
  readonly type: typeof SET_CURRENT_ASSET_PROXY_DOWNLOAD_ERRORS;
  readonly payload: {
    errors: ReadonlyArray<Pick<APIError, 'detail'>>;
  };
}
export interface SetCurrentAssetProxyDownloadDryRunErrors {
  readonly type: typeof SET_CURRENT_ASSET_PROXY_DOWNLOAD_DRY_RUN_ERRORS;
  readonly payload: {
    errors: ReadonlyArray<Pick<APIError, 'detail'>>;
  };
}

export const DOWNLOAD_HIGHRES = 'highres';
export const DOWNLOAD_PROXY = 'proxy';
export type DownloadType = typeof DOWNLOAD_HIGHRES | typeof DOWNLOAD_PROXY;

export interface CurrentAssetProxyDownloadState {
  readonly canSeeCurrenAssetProxyDownload: boolean;
  readonly preparingDownload: boolean;
  readonly downloadReady: boolean;
  readonly errors: ReadonlyArray<Pick<APIError, 'detail'>>;
  readonly dryRunErrors: ReadonlyArray<Pick<APIError, 'detail'>>;
  readonly isOpen: boolean;
  readonly currentActivity: AssetProxyDownloadActivity | undefined;
}

export interface AssetProxyDownloadActivity extends Resource<'proxydownloadactivity'> {
  asset: {
    model: Exclude<AssetType, 'collection'>;
    id: number;
  };
  download_url: string | null;
  info: string;
  status: number;
  download_ready: boolean;
  monitoring_url: string;
}

export type AssetHighresDownloadActivity = Resource<'highresdownloadactivity'>;

export interface CreateAssetProxyDownloadActivity extends BaseResource {
  asset?: {
    model: Exclude<AssetType, 'collection'>;
    id: number;
  };
}

export interface DownloadRootState {
  readonly proxyDownloadActivitiesLink: Link | null;
  readonly proxyDownloadActivityCollections: CollectionResourceMap<
    AssetProxyDownloadActivity,
    ResourceOptionsV2
  >;
  readonly highresDownloadActivitiesLink: Link | null;
  readonly highresDownloadActivityCollections: CollectionResourceMap<
    AssetHighresDownloadActivity,
    ResourceOptionsV2
  >;
  readonly assetProxyDownloadActivitiesLink: Link | null;
  readonly currentAssetDownload: CurrentAssetProxyDownloadState;
}
