import { Slider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useObservableEagerState } from 'observable-hooks';
import { HTMLAttributes, ElementType, FC, useMemo, useCallback, memo } from 'react';

import { gettext } from '../../Internationalization';
import { useVideoController } from './VideoControllerContext';

const useStyles = makeStyles(() => ({
  progressSlider: {
    position: 'absolute',
    width: 'calc(100% - 4px)',
    padding: '0px',
    height: '14px',
    '&> .MuiSlider-rail': {
      display: 'none',
    },
    '& .MuiSlider-thumb::after': {
      width: 'inherit',
      height: 'inherit',
    },
    '& .MuiSlider-thumb': {
      transition: 'none',
    },
  },
}));

interface ProgressSliderProps {
  sliderThumbComponent: ElementType<HTMLAttributes<HTMLSpanElement>>;
  sliderClass?: string;
}

const ProgressSlider: FC<ProgressSliderProps> = ({
  sliderThumbComponent,
  sliderClass,
}: ProgressSliderProps) => {
  const classes = useStyles();
  const videoController = useVideoController();

  const currentTime = useObservableEagerState(videoController.time$);
  const duration = useObservableEagerState(videoController.duration$);
  const canPlay = useObservableEagerState(videoController.canPlay$);
  const maxPlayTime = useObservableEagerState(videoController.maxPlayTime$);
  const fps = useObservableEagerState(videoController.fps$);

  const disabled = useMemo(() => !canPlay || !fps, [canPlay, fps]);

  const max = useMemo(() => Math.min((maxPlayTime / duration) * 100, 100), [maxPlayTime, duration]);

  const setCurrentTimeByThumbPosition = useCallback(
    (e: Event, newValue: number | number[]) => {
      if (e?.type === 'keydown') {
        // If progressbar is active element without this it will short circuit player controller.
        return;
      }
      if (typeof newValue === 'number' && !Number.isNaN(newValue)) {
        const maxValue = newValue > max ? max : newValue;
        const nextTime = (maxValue * duration) / 100;
        videoController.seek(nextTime);
      }
    },
    [videoController, duration, max],
  );
  const currentValue = (currentTime / duration || 0) * 100;
  const getLabel = useCallback(() => gettext('Progressbar'), []);
  const Thumb = useMemo(() => ({ thumb: sliderThumbComponent }), [sliderThumbComponent]);
  return (
    <Slider
      name="video-progress-slider"
      className={sliderClass ?? classes.progressSlider}
      track={false}
      slots={Thumb}
      getAriaValueText={getLabel}
      marks={false}
      value={currentValue}
      onChange={setCurrentTimeByThumbPosition}
      disabled={disabled}
    />
  );
};

export default memo(ProgressSlider);
