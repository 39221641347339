import { BaseResource, Resource, ResourceMap } from 'medialoopster/rest';

export const FETCH_PRODUCTIONS = 'FRONTEND/PRODUCTIONS/FETCH_PRODUCTIONS';

export const SELECT_PRODUCTION = 'FRONTEND/PRODUCTIONS/SELECT_PRODUCTION';

//-------------
// Action Types
//-------------

export interface FetchProductions {
  readonly type: typeof FETCH_PRODUCTIONS;
}

export interface SelectProduction {
  readonly type: typeof SELECT_PRODUCTION;
  readonly payload: {
    readonly productionURL: string | null;
  };
}

//---------------
// Resource Types
//---------------

export interface Production extends Resource<'production'> {
  readonly id: number;
  readonly name: string;
  readonly codename: string;
  readonly projectasset_device: number | null;
  readonly can_write: boolean;
  readonly can_delete_assets: boolean;
  readonly san_mount: string;
}

export type ProductionsMap = ResourceMap<Production>;

export interface ProductionContent extends BaseResource {
  readonly id: number;
  readonly production: number;
  readonly shared_by: string;
  readonly date_released: string;
  readonly date_withdraw: string;
  readonly status: number;
}

//------------------
// Redux State Types
//------------------

export interface ProductionsRootState {
  readonly productions: ProductionsMap;
  readonly selectedProductionURL: string | null;
}
