import { createSelector } from 'reselect';

import { getResource, getResourceURI } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { User, UsersMap } from './types';

export const getUsersMap = (state: RootState): UsersMap => state.users.users;
export const getUsers = createSelector(
  getUsersMap,
  (resourceMap): ReadonlyArray<User> => Object.values(resourceMap.resources),
);

/**
 * Select a single user by its URI
 *
 * @param state The current RootState
 * @param userURI The URI of the user
 * @returns The User Resource that corresponds to the userURI
 */
export const getUserByURI = createSelector(
  [getUsersMap, (_state, userURI) => userURI],
  (userMap, userURI) => userMap.resources[userURI],
);

export const getCurrentUserURI = (state: RootState): string | undefined =>
  state.users.currentUserLink?.href;

export const getCurrentUser = (state: RootState): User | undefined => {
  const currentUserHref = getCurrentUserURI(state);
  if (!currentUserHref) {
    return undefined;
  }
  return getResource(getUsersMap(state), currentUserHref);
};

export const isSuperUser = (state: RootState): boolean => !!getCurrentUser(state)?.is_superuser;

export const getAllOtherUsers = createSelector(
  getCurrentUserURI,
  getUsers,
  (currentUserURI, users): ReadonlyArray<User> =>
    users.filter((user) => getResourceURI(user) !== currentUserURI),
);
