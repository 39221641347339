import { Badge } from '@mui/material';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { ToolbarButtonGroup, ToolbarIconButton } from 'medialoopster/components';
import {
  BoundingBoxes,
  BoundingBoxesOff,
  Person,
  PersonOff,
  SplitPerson,
} from 'medialoopster/icons';

import { isImageAsset, isVideoAsset } from '../../businessRules/models/asset/utils';
import { detailsSelectors } from '../../state/modules/details';
import { personsActions, personsSelectors } from '../../state/modules/persons';

const PersonsActionButtons: FC = () => {
  const canViewPersons = useSelector(personsSelectors.canViewPersons);
  const isSplitPersonButtonVisible = useSelector(personsSelectors.isSplitPersonButtonVisible);
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const currentAssetPersonCount = useSelector(personsSelectors.getCurrentAssetPersonCount);
  const isPersonsVisible = useSelector(personsSelectors.isPersonsVisible);
  const isAllBoundingBoxesVisible = useSelector(personsSelectors.isAllBoundingBoxesVisible);

  const getTooltipText = () => {
    if (currentAssetPersonCount === 0) {
      return gettext('No persons in asset');
    }
    if (isAllBoundingBoxesVisible) {
      return gettext('Bounding boxes off');
    }
    return gettext('Bounding boxes on');
  };

  const getShowPersonsButtonTitle = () => {
    if (currentAssetPersonCount === 0) {
      return gettext('No persons in asset');
    }
    if (isPersonsVisible) {
      return gettext('Hide persons');
    }
    return gettext('Show persons');
  };

  const dispatch = useDispatch();

  return (
    <>
      {canViewPersons && (isVideoAsset(currentAsset) || isImageAsset(currentAsset)) && (
        <ToolbarButtonGroup>
          <ToolbarIconButton
            id="content-analysis-boxes-button"
            title={getTooltipText()}
            onClick={() => dispatch(personsActions.toggleAllBoundingBoxesVisible())}
            aria-pressed={isAllBoundingBoxesVisible}
            disabled={currentAssetPersonCount === 0}
          >
            {isAllBoundingBoxesVisible ? <BoundingBoxesOff /> : <BoundingBoxes />}
          </ToolbarIconButton>
          <ToolbarIconButton
            title={getShowPersonsButtonTitle()}
            onClick={() => dispatch(personsActions.togglePersonsVisible())}
            aria-label={`${currentAssetPersonCount}`}
            aria-pressed={isPersonsVisible}
            ariaExpanded={isPersonsVisible}
            aria-controls="persons-in-asset"
            id="show-persons-button"
            disabled={currentAssetPersonCount === 0}
          >
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              color="primary"
              badgeContent={currentAssetPersonCount}
              max={100}
            >
              {isPersonsVisible ? <PersonOff /> : <Person />}
            </Badge>
          </ToolbarIconButton>
          {isSplitPersonButtonVisible && (
            <ToolbarIconButton
              id="content-analysis-split-button"
              title={gettext('Split person')}
              onClick={() => dispatch(personsActions.splitFaces())}
              disabled={currentAssetPersonCount === 0}
            >
              <SplitPerson />
            </ToolbarIconButton>
          )}
        </ToolbarButtonGroup>
      )}
    </>
  );
};

export default PersonsActionButtons;
