import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { medialoopsterTheme } from 'medialoopster';
import { FC, memo } from 'react';

interface StatusElementProps {
  title: string;
  label: string;
  showLabel: boolean;
  statusLevel?: string;
  renderIcon: () => JSX.Element;
}

const useStyles = makeStyles(() => ({
  statusAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  statusError: {
    color: medialoopsterTheme.palette.error.dark,
    '& .MuiSvgIcon-root': {
      color: `${medialoopsterTheme.palette.error.dark} !important`, // overwrite the values from ../AssetHeader.tsx
    },
  },
  statusWarning: {
    color: medialoopsterTheme.palette.warning.main,
    '& .MuiSvgIcon-root': {
      color: `${medialoopsterTheme.palette.warning.main} !important`, // overwrite the values from ../AssetHeader.tsx
    },
  },
}));

const StatusElement: FC<StatusElementProps> = ({
  renderIcon,
  title,
  label,
  showLabel,
  statusLevel = 'default',
}: StatusElementProps) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.statusAlign, {
        [classes.statusError]: statusLevel === 'error',
        [classes.statusWarning]: statusLevel === 'warning',
      })}
      data-testid="status-container"
    >
      <Tooltip title={title} PopperProps={{ style: { whiteSpace: 'pre-line' } }}>
        <span style={{ display: 'flex', marginRight: '0.1em' }}>{renderIcon()}</span>
      </Tooltip>
      {showLabel && (
        <span style={{ fontSize: '85%', paddingLeft: '0.25rem', paddingRight: '0.5rem' }}>
          {label}
        </span>
      )}
    </div>
  );
};

export default memo(StatusElement);
