import { Stack } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { OutlinedPaper } from 'medialoopster/components';

import { getCurrentAnnotations } from '../../../state/modules/details/selectors';
import AnnotationsPanelBody from './AnnotationsPanelBody';
import AnnotationsPanelFooter from './AnnotationsPanelFooter';
import AnnotationsPanelHeader from './AnnotationsPanelHeader';

/**
 * A panel for displaying the annotations of a video asset.
 *
 * The responsibility of this component is to describe the layout of the panel.
 *
 * The panel consists of a header, a body that may be scrollable if to big, and a footer.
 *
 * The Panel needs access to the store as well as a VideoController via a
 * VideoControllerContextProvider.
 *
 * @see Design.md in this directory.
 */
const AnnotationsPanel: FC = () => {
  const annotations = useSelector(getCurrentAnnotations);

  return (
    <Stack
      justifyContent="space-between"
      height="min-content"
      maxHeight="100%"
      width="100%"
      flexWrap="nowrap"
      component={OutlinedPaper}
    >
      <AnnotationsPanelHeader />
      <AnnotationsPanelBody annotations={annotations} />
      <AnnotationsPanelFooter />
    </Stack>
  );
};

export default AnnotationsPanel;
