// Adapted from https://coderwall.com/p/zrlulq/parsing-a-link-header-in-javascript

// Unquote string (utility)
const unquote = (value: string): string => {
  if (value.charAt(0) === '"' && value.charAt(value.length - 1) === '"') {
    return value.substring(1, value.length - 1);
  }
  return value;
};

export type LinkHeaderField = 'prev' | 'next' | 'first' | 'last' | 'type';

type LinkHeader = {
  [key in LinkHeaderField]?: string;
};

// Parse a Link header
export default (header: string | null): LinkHeader => {
  const links: LinkHeader = {};
  if (!header) {
    return links;
  }

  // See https://tools.ietf.org/html/rfc5988#section-5 for a definition of the link header field.

  // Regular Expression to match a link header field.
  const linkExp =
    /<[^>]*>\s*(\s*;\s*[^()<>@,;:"/[\]?={} \t]+=(([^()<>@,;:"/[\]?={} \t]+)|("[^"]*")))*(,|$)/g;
  // Regular Expression to match params in a link header field.
  const paramExp = /[^()<>@,;:"/[\]?={} \t]+=(([^()<>@,;:"/[\]?={} \t]+)|("[^"]*"))/g;

  const matches = header.match(linkExp);
  for (let i = 0; matches && i < matches.length; i += 1) {
    const split = matches[i].split('>');
    const href = split[0].substring(1);
    const ps = split[1];
    const link: Record<string, string> = { href };
    const s = ps.match(paramExp);
    if (s != null) {
      for (let j = 0; j < s.length; j += 1) {
        const p = s[j];
        const params = p.split('=');
        const name = params[0];
        link[name] = unquote(params[1]);
      }

      if (link.rel !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        links[link.rel] = link.href;
      }
    }
  }

  return links;
};
