import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Close } from 'medialoopster/icons';

type Props = {
  errorMsg: string;
  title?: string;
  onClose?: () => void;
};

const ModalNotifier: FC<Props> = ({ errorMsg, title, onClose }: Props) => (
  <Alert
    severity="error"
    action={
      onClose && (
        <IconButton onClick={onClose} title={gettext('Close this alert window.')} size="small">
          <Close fontSize="small" />
        </IconButton>
      )
    }
  >
    {title && <AlertTitle>{title}</AlertTitle>}
    {errorMsg}
  </Alert>
);

export default ModalNotifier;
