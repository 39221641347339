/* eslint no-underscore-dangle: 0 */
import _ from 'lodash';
import { createSelector, Selector } from 'reselect';

import { isReadonlyArray } from '../types';
import {
  APIError,
  APIFieldError,
  BaseResource,
  BaseResourceEmbedded,
  BaseResourceLinks,
  Choice,
  Collection,
  CollectionType,
  EmbeddedItemsPageResource,
  HttpMethod,
  Link,
  LinkedItemsPageResource,
  LinkPermission,
  PageResource,
  Resource,
  ResourceHeaders,
  ResourceMap,
  ResourceOptions,
  ResourceOptionsV2,
  ResourceType,
} from './types';

const medialoopsterRelationBaseURI = 'http://medialoopster.com/relations/';

/**
 * Create a medialoopster relation URI from a relation name.
 *
 * @param mlRelationType - The medialoopster relation name.
 *
 * @returns The full relation URI.
 */
export const mlRel = (mlRelationType: string): string =>
  `${medialoopsterRelationBaseURI}${mlRelationType}`;

/**
 * Normalize a (possibly relative) URI to an absolute URI.
 *
 * @param uri - The URI, possibly relative.
 *
 * @returns The normalized absolute URI.
 */
export const normalizeURI = (uri: string): string => {
  try {
    return new URL(uri).href;
  } catch (TypeError) {
    if (!uri.startsWith('/')) {
      return new URL(`/${uri}`, document.baseURI).href;
    }
    return new URL(uri, document.baseURI).href;
  }
};

/**
 * Get a resource by URI.
 *
 * @param resourceMap - The resource map containing the resource.
 * @param resourceURI - The URI of the resource.
 *
 * @returns The resource, if it exists.
 */
export const getResource = <R extends Resource<string>>(
  resourceMap: ResourceMap<R>,
  resourceURI: string,
): R | undefined => resourceMap.resources[normalizeURI(resourceURI)];

export const getResourceOptions = <O>(
  resourceMap: ResourceMap<Resource<string>, O>,
  resourceURI: string,
): O | undefined => resourceMap.options[normalizeURI(resourceURI)];

export const getResourceHeaders = (
  resourceMap: ResourceMap<Resource<string>>,
  resourceURI: string,
): ResourceHeaders | undefined => {
  return resourceMap.headers[normalizeURI(resourceURI)];
};

export const getResourceHeader = (
  resourceMap: ResourceMap<Resource<string>>,
  resourceURI: string,
  header: string,
): string | undefined => {
  const headers = getResourceHeaders(resourceMap, resourceURI);
  if (!headers) {
    return undefined;
  }
  return headers[header];
};

export const getCollectionTotalCount = (
  resourceMap: ResourceMap<Resource<string>>,
  resourceURI: string,
): number => {
  const totalCountHeader = getResourceHeader(resourceMap, resourceURI, 'x-total-count');
  if (!totalCountHeader) {
    return NaN;
  }
  return parseInt(totalCountHeader, 10);
};

export const getLinkTargetTypes = (
  options: ResourceOptionsV2,
  rel: string,
  method: 'POST' | 'PATCH',
): ReadonlyArray<string> => {
  const fields = options.actions[method];
  if (!fields) {
    return [];
  }
  const links = fields._links?.children;
  if (!links) {
    return [];
  }
  const field = links[rel];
  return field.child?.target_types || [];
};

export const isResourceMap = <R extends Resource<string>>(obj: unknown): obj is ResourceMap<R> => {
  const state = obj as ResourceMap<R>;
  return !!(state?.resourceTypeName && state?.resources);
};

const getLinkOrLinks = (
  resource: BaseResource,
  relationType: string,
): Link | ReadonlyArray<Link> | undefined | null => {
  if (!resource._links || !resource._links[relationType]) {
    return undefined;
  }
  return resource._links[relationType];
};

/**
 * Get a link from a resource.
 *
 * If there is more than one link, the first one will be returned.
 *
 * @param resource - The resource containing the link.
 * @param relationType - The relation type.
 *
 * @returns The link, if there is a link with the given relation type.
 */
export const getLink = (resource: BaseResource, relationType: string): Link | undefined => {
  const link = getLinkOrLinks(resource, relationType);
  return Array.isArray(link) ? link[0] : link;
};

/*
 * Get a link titles from a resource.
 *
 * If there is more than one link retunr all titles.
 *
 * @param resource - The resource containing the link.
 * @param relationType - The relation type.
 *
 * @returns The link titles of a resource with the given relation type.
 */
export const getLinkTitles = (
  resource: BaseResource,
  relationType: string,
): ReadonlyArray<string> => {
  const linkRel = getLinkOrLinks(resource, relationType);
  if (linkRel === undefined) {
    return [];
  }
  if (Array.isArray(linkRel))
    return linkRel.map((link: Link) => link?.title || '').filter((el) => el !== '');

  const linkTitle = (linkRel as Link)?.title;
  return linkTitle !== undefined ? [linkTitle] : [];
};

/**
 * Get an array of links from a resource.
 *
 * @param resource - The resource containing the links.
 * @param relationType - The relation type.
 *
 * @returns The array of links.
 */
export const getLinks = (resource: BaseResource, relationType: string): ReadonlyArray<Link> => {
  const link = getLinkOrLinks(resource, relationType);
  if (!link) {
    return [];
  }
  return Array.isArray(link) ? link : [link];
};

/**
 * Get a link's URI from a resource.
 *
 * @param resource - The resource containing the link.
 * @param relationType - The relation type.
 *
 * @returns The link's href, if there is a link with the given relation type.
 */
export const getLinkHref = (resource: BaseResource, relationType: string): string | undefined => {
  const link = getLink(resource, relationType);
  if (!link) {
    return undefined;
  }
  return link.href;
};

/**
 * Get a linked resource.
 *
 * @param resource - The resource containing the link.
 * @param relationType - The relation type.
 * @param resourceMap - The resource map containing the linked resource.
 *
 * @returns The linked resource, if it exists.
 */
export const getLinkedResource = <R extends BaseResource, E extends Resource<string>>(
  resource: R,
  relationType: string,
  resourceMap: ResourceMap<E>,
): E | undefined => {
  const link = getLink(resource, relationType);
  if (!link) {
    return undefined;
  }
  return getResource(resourceMap, link.href);
};

/**
 * Get an array of linked resources.
 *
 * @param resource - The resource containing the link.
 * @param relationType - The relation type.
 * @param resourceMap - The resource map containing the linked resources.
 *
 * @returns The linked resources.
 */
export const getLinkedResourceArray = <R extends BaseResource, E extends Resource<string>>(
  resource: R,
  relationType: string,
  resourceMap: ResourceMap<E>,
): E[] => {
  if (!resource) {
    return [];
  }
  return getLinks(resource, relationType)
    .map((link) => getResource(resourceMap, link.href))
    .filter((item): item is Exclude<typeof item, undefined> => !!item);
};

export const getEmbeddedResourceArray = (
  resource: BaseResource,
  relationType: string,
): ReadonlyArray<BaseResource> => {
  const embedded = resource._embedded && resource._embedded[relationType];
  if (!embedded) {
    return [];
  }
  if (isReadonlyArray(embedded)) {
    return embedded;
  }
  return [embedded];
};

/**
 * Get items embedded in a collection resource page.
 *
 * @param resource - The page resource containing the link.
 *
 * @returns The embedded items.
 */
export const getEmbeddedItems = <
  I extends BaseResource<BaseResourceLinks, BaseResourceEmbedded>,
  T extends string,
>(
  resource: EmbeddedItemsPageResource<I, T>,
): ReadonlyArray<I> => (resource && resource._embedded ? resource._embedded.item : []);

/**
 * Get items linked to a collection resource page.
 *
 * @param resource - The page resource containing the link.
 * @param resourceMap - The resource map containing the linked items.
 *
 * @returns The linked items.
 */
export const getLinkedItems = <
  PR extends LinkedItemsPageResource<string>,
  I extends Resource<string>,
>(
  resource: PR,
  resourceMap: ResourceMap<I>,
): I[] =>
  resource._links?.item
    ?.map(({ href }) => getResource(resourceMap, href))
    .filter((item): item is Exclude<typeof item, undefined> => !!item) || [];

/**
 * Create a collection from the resources of its pages by following ``next`` links.
 *
 * @param collectionURI - The URI of the collection's first page.
 * @param resourceMap - The resource map containing the collection's pages.
 * @param getPageItems - Gets items from a page.
 *   (Use ``getEmbeddedItems`` or ``getLinkedItems`` to get a page's items.)
 *
 * @returns The collection.
 */
export const createCollection = <
  PR extends PageResource<string>,
  I extends BaseResource<BaseResourceLinks, BaseResourceEmbedded> = BaseResource<
    BaseResourceLinks,
    BaseResourceEmbedded
  >,
>(
  collectionURI: string | undefined | null,
  resourceMap: ResourceMap<PR>,
  getPageItems: (page: PR) => ReadonlyArray<I>,
): Collection<I> => {
  if (!collectionURI) {
    return {
      loaded: false,
      items: [],
      totalCount: 0,
    };
  }
  const page = getResource(resourceMap, collectionURI);
  if (!page) {
    return {
      loaded: false,
      items: [],
      totalCount: 0,
    };
  }
  const items = getPageItems(page);
  const next = getLinkHref(page, 'next');
  if (next) {
    const nextPage = createCollection(next, resourceMap, getPageItems);
    if (nextPage && nextPage.loaded) {
      return {
        loaded: true,
        items: _.union(items, nextPage.items),
        totalCount: page.total_count,
        next: nextPage.next,
      };
    }
  }
  return {
    loaded: true,
    items,
    totalCount: page.total_count,
    next,
  };
};

/**
 * Collect all page URLs of a given collection.
 *
 * @param collectionURI - The URI of the collection's first page.
 * @param resourceMap - The resource map containing the collection's pages.
 *
 * @returns An array of the collection's page URLs.
 */
export const getCollectionPageURLs = <T extends string>(
  collectionURI: string | undefined | null,
  resourceMap: ResourceMap<PageResource<T>>,
): ReadonlyArray<string> => {
  if (!collectionURI) {
    return [];
  }
  const page = getResource(resourceMap, collectionURI);
  if (!page) {
    return [collectionURI];
  }
  return [collectionURI, ...getCollectionPageURLs(getLinkHref(page, 'next'), resourceMap)];
};

export const getCollectionType = <T extends string>(resourceTypeName: T): CollectionType<T> =>
  `${resourceTypeName}-collection`;

/**
 * Create a selector for creating a collection out of pages with linked items.
 *
 * @param getCollectionURI - Function getting a URI from the state.
 * @param getPageResourceMap - Function getting the resource map containing the pages.
 * @param getItemResourceMap - Function getting the resource map containing the collection's items.
 *
 * @returns A cached selector for creating a collection.
 */
export const createCollectionSelector = <
  S,
  I extends Resource<string>,
  PT extends string = CollectionType<ResourceType<I>>,
>(
  getCollectionURI: Selector<S, string | undefined | null, never>,
  getPageResourceMap: Selector<S, ResourceMap<LinkedItemsPageResource<PT>>, never>,
  getItemResourceMap: Selector<S, ResourceMap<I>, never>,
): Selector<S, Collection<I>, never> =>
  createSelector(
    getCollectionURI,
    getPageResourceMap,
    getItemResourceMap,
    (collectionURI, collections, items) =>
      createCollection(collectionURI, collections, (page) => getLinkedItems(page, items)),
  );

/**
 * Check if an object is a resource.
 *
 * An object is considered a resource if it contains a ``self`` and a ``type`` link.
 *
 * @param obj - The object to check.
 *
 * @returns Whether the object is a resource or not.
 */
export const isResource = <T extends string>(obj: unknown): obj is Resource<T> => {
  const resource = obj as Resource<T>;
  return !!(resource?._links?.self && resource?._links?.type);
};

/**
 * Get a resource's ``self`` link.
 *
 * @param resource - The resource.
 *
 * @returns The resource self Link.
 */
export const getResourceLink = <T extends string>(resource: Resource<T>): Link => {
  return resource._links.self;
};

/**
 * Get a resource's URI from its ``self`` link.
 *
 * @param resource - The resource.
 *
 * @returns The resource URI.
 */
export const getResourceURI = <T extends string>(resource: Resource<T>): string => {
  return normalizeURI(getResourceLink(resource).href);
};

/**
 * Convert a type name or URI to a type name.
 *
 * Used internally to force legacy type URIs to type names.
 *
 * @param typeNameOrURI - A resource type name or URI.
 *
 * @returns The resource type name.
 */
export const toTypeName = <T extends string>(typeNameOrURI: string): T => {
  const match = typeNameOrURI.match(/\/api\/types\/([a-zA-Z0-9-]+)\//);
  if (!match || match.length < 2) {
    return typeNameOrURI as T;
  }
  return match[1] as T;
};

/**
 * Get a resource's type name from its ``type`` link.
 *
 * @param resource - The resource.
 *
 * @returns The resource's type name.
 */
export const getResourceTypeName = <R extends Resource<string>>(resource: R): ResourceType<R> => {
  return resource._links.type.name;
};
/**
 * Get a permission embedded in a link.
 *
 * @param link - The link.
 * @param httpMethod - The HTTP method.
 *
 * @returns The link permission.
 */
export const getLinkPermission = (link: Link, httpMethod: HttpMethod): LinkPermission => {
  if (!link.methods) {
    return {
      code: 'unknown',
      message: '',
    };
  }
  const permission = link.methods[httpMethod];
  if (!permission) {
    return {
      code: 'method_not_allowed',
      message: '',
    };
  }
  return permission;
};

/**
 * Create a resource map for a resource type name and a list of resources.
 *
 * @param resourceTypeName - The resource type name.
 * @param resources - The list of resources.
 * @param options - The options by URI.
 *
 * @returns The resource map.
 */
export const createResourceMap = <
  T extends string,
  R extends Resource<T> = Resource<T>,
  O = ResourceOptions,
>(
  resourceTypeName: T,
  resources: ReadonlyArray<R> = [],
  options: { [uri: string]: O } = {},
  headers: { [uri: string]: ResourceHeaders } = {},
): ResourceMap<R, O> => ({
  resourceTypeName,
  resources: Object.fromEntries(resources.map((resource) => [getResourceURI(resource), resource])),
  options: Object.fromEntries(
    Object.entries(options).map(([url, metadata]) => [normalizeURI(url), metadata]),
  ),
  headers: Object.fromEntries(
    Object.entries(headers).map(([url, metadata]) => [normalizeURI(url), metadata]),
  ),
});

/**
 * Check if an object is a APIFieldError.
 *
 * @param obj - The object to check.
 * @param code - Optional error code to assert.
 *
 * @returns Whether error is an APIFieldError.
 */
export const isAPIFieldError = (obj: unknown, code?: string): obj is APIFieldError => {
  const error = obj as APIFieldError;
  return (!code || error?.code === code) && !!(error?.source && error.source?.pointer);
};

export const isAPINonFieldError = (obj: unknown, code?: string): obj is APIError => {
  const error = obj as APIError;
  return (!code || error?.code === code) && !isAPIFieldError(error);
};

export const getResourceArray = <R extends Resource<string>>(
  resourceMap: ResourceMap<R>,
): ReadonlyArray<R> => Object.values(resourceMap.resources);

export const getStringChoices = (
  choices: ReadonlyArray<Choice<string | number>>,
): ReadonlyArray<Choice<string>> =>
  choices.map((choice) => ({
    ...choice,
    value: typeof choice.value === 'string' ? choice.value : choice.value.toString(),
  }));

export const getNumberChoices = (
  choices: ReadonlyArray<Choice<string | number>>,
): ReadonlyArray<Choice<number>> =>
  choices.map((choice) => ({
    ...choice,
    value: typeof choice.value === 'string' ? parseInt(choice.value, 10) : choice.value,
  }));
