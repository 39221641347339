/**
 * This module implements a component to display a header for the transcript editor.
 */
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';

import { gettext, interpolate } from 'medialoopster/Internationalization';

import { detailsSelectors } from '../../../state/modules/details';

// TODO: The design of the header, especially the bottom border, is shared with the AnnotationsPanelHeader <KH 2024-08-28 t:ML-3549>

/**
 * A helper-component for a single header field.
 *
 * This component implements the styling of the transcript editor header fields.
 *
 * @param title The title of the field.
 * @param value The value of the field
 */
const HeaderField: React.FC<{ title: string; value: string }> = React.memo(({ title, value }) => {
  return (
    <Typography variant="caption">
      <Box>
        <Box sx={{ display: 'inline', fontWeight: 'bold', marginRight: '0.25rem' }}>{title}:</Box>
        <Box sx={{ display: 'inline' }}>{value}</Box>
      </Box>
    </Typography>
  );
});

/**
 * Display a header for the transcript editor and the current transcript.
 */
export const TranscriptHeader: React.FC = () => {
  const transcript = useSelector(detailsSelectors.getCurrentTranscript);
  const asset = useSelector(detailsSelectors.getCurrentAsset);
  const loading = gettext('Loading...');

  let assetName;
  let transcriptEngine;
  let transcriptLanguage;
  let transcriptCreatedAt;

  if (transcript) {
    transcriptEngine = transcript.engine;
    transcriptLanguage = transcript.language;
    transcriptCreatedAt = DateTime.fromISO(transcript.created_at).toLocaleString(
      DateTime.DATE_SHORT,
    );
  } else {
    transcriptEngine = loading;
    transcriptLanguage = loading;
    transcriptCreatedAt = loading;
  }

  if (asset) {
    assetName = asset.name;
  } else {
    assetName = loading;
  }

  return (
    <Box
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      sx={{ padding: '0.5rem', paddingLeft: '20px' }}
    >
      <Typography variant="subtitle1">
        {interpolate(gettext('Transcript of video asset "%(assetName)s"'), { assetName })}
      </Typography>
      <HeaderField title={gettext('Transcript Engine')} value={transcriptEngine} />
      <HeaderField title={gettext('Original Language')} value={transcriptLanguage} />
      <HeaderField title={gettext('Created')} value={transcriptCreatedAt} />
    </Box>
  );
};

export default TranscriptHeader;
