import { Checkbox, Grid } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Modal } from 'medialoopster/components';
import { FormField } from 'medialoopster/forms';

import { KeywordsField } from '../../../../../state/modules/bulkEdit/types';

type Props = {
  readonly title: string;
  readonly fields: ReadonlyArray<FormField | KeywordsField>;
  readonly renderField: (field: FormField | KeywordsField) => ReactNode;
  readonly isFieldActive: (field: FormField | KeywordsField) => boolean;
  readonly setFieldActive: (field: FormField | KeywordsField, active: boolean) => void;
};

const BulkEditFieldGroup: FC<Props> = ({
  title,
  fields,
  renderField,
  isFieldActive,
  setFieldActive,
}: Props) => (
  <Modal.Card title={title}>
    <Grid container spacing={2}>
      {fields.map((field) => (
        <Grid item xs={12} container key={field.name}>
          <Grid item xs={1}>
            <Checkbox
              checked={isFieldActive(field)}
              inputProps={{ 'aria-label': field.label }}
              onChange={(_event, checked) => setFieldActive(field, checked)}
            />
          </Grid>
          <Grid item xs={11}>
            {renderField(field)}
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Modal.Card>
);

export default BulkEditFieldGroup;
