import { Box } from '@mui/material';
import { FC } from 'react';

import { AssetCollection } from '../../state/modules/rest/collection/types';
import PlayerContainer from './PlayerContainer';

type Props = {
  readonly asset: AssetCollection;
};

const CollectionPlaceholder: FC<Props> = ({ asset }: Props) => (
  <PlayerContainer
    player={
      <img
        draggable={false}
        alt={asset.name}
        src="/static/img/default_collections_poster.png"
        style={{ maxWidth: '100%' }}
      />
    }
    toolbarButtons={<Box flexGrow={1} />}
  />
);

export default CollectionPlaceholder;
