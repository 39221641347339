import { Action, combineReducers, Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
// defaults to localStorage for web
import storage from 'redux-persist/lib/storage';

import {
  Alert,
  Authentication,
  ALERT_ERROR,
  CLEAR_ALERT,
  AlertError,
  ClearAlert,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LoginSuccess,
  LoginRequest,
  LoginFailure,
  LoginRootState,
  RESET_STORE,
  CLEAR_TOKEN,
  ClearToken,
} from './types';

export const alertInitialState: Alert = {
  message: '',
};

export const alertReducer = (
  state: Alert = alertInitialState,
  action: AlertError | ClearAlert,
): Alert => {
  switch (action.type) {
    case ALERT_ERROR:
      return {
        message: action.payload.message,
      };
    case CLEAR_ALERT:
      return alertInitialState;
    default:
      return state;
  }
};

const authenticationInitialState: Authentication = {
  loggingIn: false,
  token: '',
  userId: 0,
};

export const authenticationReducer = (
  state: Authentication = authenticationInitialState,
  action: LoginRequest | LoginSuccess | LoginFailure | ClearToken,
): Authentication => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...authenticationInitialState,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...authenticationInitialState,
        token: action.payload.token,
        userId: action.payload.userId,
      };
    case LOGIN_FAILURE:
      return authenticationInitialState;
    case CLEAR_TOKEN:
      return authenticationInitialState;
    default:
      return state;
  }
};

const authenticationPersistConfig = {
  key: 'authentication',
  storage,
  whitelist: ['token', 'userId'], // persist only the token and userId
};

export default combineReducers<LoginRootState>({
  alert: alertReducer,
  authentication: persistReducer(authenticationPersistConfig, authenticationReducer),
});

// reset the whole app state when the `RESET_STORE` is triggered, except for the slice `login`
export const resettingReducer =
  <S extends { login: LoginRootState }>(rootReducer: Reducer<S, Action>): Reducer<S, Action> =>
  (state, action) => {
    if (action.type === RESET_STORE) {
      if (state) {
        const stateToKeep = state.login;
        const stateReset = rootReducer(undefined, action);
        return { ...stateReset, login: stateToKeep };
      }
      return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
  };
