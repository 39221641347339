import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { DropdownMenu, MenuItem } from 'medialoopster/components';
import { Apps, LicenseManagement, Management, Monitoring } from 'medialoopster/icons';

import { mainSelectors } from '../../state/modules/main';
import useAppConnectorMenuEntries from './menuitems/useAppConnectorMenuEntries';

const AppsMenu: FC = () => {
  const monitoringURL = useSelector(mainSelectors.getMonitoringURL);
  const managementURL = useSelector(mainSelectors.getManagementURL);
  const licenseManagementURL = useSelector(mainSelectors.getLicenseManagementURL);
  const appConnectorMenuEntries = useAppConnectorMenuEntries();
  return (
    <Grid sx={{ marginTop: 4 }}>
      <DropdownMenu
        popupId="apps-menu"
        icon={<Apps />}
        buttonProps={{
          size: 'medium',
          variant: 'text',
          color: 'white',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {monitoringURL && (
          <MenuItem
            key="monitoring"
            icon={<Monitoring fontSize="small" />}
            label={gettext('Monitoring')}
            href={monitoringURL}
          />
        )}
        {managementURL && (
          <MenuItem
            key="management"
            icon={<Management fontSize="small" />}
            label={gettext('Management')}
            href={managementURL}
          />
        )}
        {licenseManagementURL && (
          <MenuItem
            key="license-management"
            icon={<LicenseManagement fontSize="small" />}
            label={gettext('License Management')}
            href={licenseManagementURL}
          />
        )}
        {(monitoringURL || managementURL || licenseManagementURL) &&
          appConnectorMenuEntries.length > 0 && <Divider key="divider" />}
        {[{ items: [...appConnectorMenuEntries] }]
          .filter((group) => group.items.length > 0)
          .flatMap((group) => [...group.items])}
      </DropdownMenu>
    </Grid>
  );
};

export default AppsMenu;
