import jsonpointer from 'json-pointer';
import { DateTime } from 'luxon';

import { SelectOption } from './components/Select';
import {
  APIFieldError,
  BaseResource,
  Choice,
  FieldObjectV3,
  LinkChoiceV3,
  LinkObjectV3,
} from './rest/types';

export type BaseField<V, E> = {
  readonly name: string;
  readonly label: string;
  readonly set: (resource: BaseResource, newValue: V) => void;
  readonly getErrors: (resource: BaseResource, errors: ReadonlyArray<APIFieldError>) => E;
  readonly defaultValue: V;
};

export type StringField = BaseField<string, ReadonlyArray<string>> & {
  readonly type: 'string';
  readonly maxLength?: number;
};

export type DateField = BaseField<DateTime | null, ReadonlyArray<string>> & {
  readonly type: 'date';
  readonly allowNull: boolean;
};

export type DateTimeField = BaseField<DateTime | null, ReadonlyArray<string>> & {
  readonly type: 'datetime';
  readonly allowNull: boolean;
};

export type ChoiceField = BaseField<string | number | null, ReadonlyArray<string>> & {
  readonly type: 'choice';
  readonly allowNull: boolean;
  readonly choices: ReadonlyArray<SelectOption>;
};

export type FormField = StringField | DateField | DateTimeField | ChoiceField;

const getFieldErrors = (
  errors: ReadonlyArray<APIFieldError>,
  fieldPointer: string,
): ReadonlyArray<string> =>
  errors.filter((error) => error.source.pointer === fieldPointer).map((error) => error.detail);

export const makeStringField = (
  fieldPointer: string,
  {
    group,
    name,
    label,
    maxLength,
    defaultValue,
  }: { group: string; name: string; label: string; maxLength?: number; defaultValue?: string },
): StringField => ({
  type: 'string',
  name: `${group}__${name}`,
  label,
  maxLength,
  set: (resource, value) => jsonpointer.set(resource, fieldPointer, value),
  getErrors: (_resource, errors) => getFieldErrors(errors, fieldPointer),
  defaultValue: defaultValue || '',
});

export const makeDateField = (
  fieldPointer: string,
  {
    group,
    name,
    label,
    allowNull = false,
    defaultValue,
  }: { group: string; name: string; label: string; allowNull?: boolean; defaultValue?: string },
): DateField => ({
  type: 'date',
  name: `${group}__${name}`,
  label,
  allowNull,
  set: (resource, value) =>
    jsonpointer.set(resource, fieldPointer, value?.toFormat('yyyy-MM-dd') || null),
  getErrors: (_resource, errors) => getFieldErrors(errors, fieldPointer),
  defaultValue: defaultValue ? DateTime.fromFormat(defaultValue, 'yyyy-MM-dd') : null,
});

export const makeDateTimeField = (
  fieldPointer: string,
  {
    group,
    name,
    label,
    allowNull = false,
    defaultValue,
  }: { group: string; name: string; label: string; allowNull?: boolean; defaultValue?: string },
): DateTimeField => ({
  type: 'datetime',
  name: `${group}__${name}`,
  label,
  allowNull,
  set: (resource, value) => jsonpointer.set(resource, fieldPointer, value?.toISO() || null),
  getErrors: (_resource, errors) => getFieldErrors(errors, fieldPointer),
  defaultValue: defaultValue ? DateTime.fromISO(defaultValue) : null,
});

export const makeChoiceField = (
  fieldPointer: string,
  {
    group,
    name,
    label,
    allowNull = false,
    defaultValue,
    choices,
  }: {
    group: string;
    name: string;
    label: string;
    allowNull?: boolean;
    defaultValue?: string;
    choices: ReadonlyArray<Choice<string | number>>;
  },
): ChoiceField => ({
  type: 'choice',
  name: `${group}__${name}`,
  label,
  allowNull,
  choices: choices.map(({ value, display_name }) => ({
    value,
    display: display_name,
  })),
  set: (resource, value) => jsonpointer.set(resource, fieldPointer, value),
  getErrors: (_resource, errors) => getFieldErrors(errors, fieldPointer),
  defaultValue: defaultValue || null,
});

export const fieldObjectToFormField = (
  pointer: string,
  group: string,
  name: string,
  {
    type,
    label,
    max_length: maxLength,
    allow_null: allowNull,
    choices,
    default: defaultValue,
  }: Pick<FieldObjectV3, 'type' | 'label' | 'max_length' | 'allow_null' | 'choices' | 'default'>,
): FormField | null => {
  const fieldPointer = `${pointer}/${name}`;
  switch (type) {
    case 'string': {
      return makeStringField(fieldPointer, { group, name, label, maxLength, defaultValue });
    }
    case 'date': {
      return makeDateField(fieldPointer, { group, name, label, allowNull, defaultValue });
    }
    case 'datetime': {
      return makeDateTimeField(fieldPointer, { group, name, label, allowNull, defaultValue });
    }
    case 'choice': {
      return makeChoiceField(fieldPointer, {
        group,
        name,
        label,
        allowNull,
        defaultValue,
        choices: choices || [],
      });
    }
    default:
      return null;
  }
};

export const makeLinkChoiceField = (
  fieldPointer: string,
  {
    group,
    name,
    label,
    allowNull = false,
    defaultValue,
    choices,
  }: {
    group: string;
    name: string;
    label: string;
    allowNull?: boolean;
    defaultValue?: string;
    choices: ReadonlyArray<LinkChoiceV3>;
  },
): ChoiceField => ({
  type: 'choice',
  name: `${group}__${name}`,
  label,
  allowNull,
  choices: choices?.map(({ href, title }) => ({ value: href, display: title })) || [],
  set: (resource, value) => jsonpointer.set(resource, fieldPointer, value),
  getErrors: (
    _resource: BaseResource,
    errors: ReadonlyArray<APIFieldError>,
  ): ReadonlyArray<string> => getFieldErrors(errors, fieldPointer),
  defaultValue: defaultValue || null,
});

export const linkObjectToFormField = (
  pointer: string,
  group: string,
  name: string,
  {
    label,
    allow_null: allowNull,
    choices,
    default: defaultValue,
  }: Pick<LinkObjectV3, 'label' | 'allow_null' | 'choices' | 'default'>,
): ChoiceField =>
  makeLinkChoiceField(`${pointer}/_links/${name}/href`, {
    group,
    name,
    label,
    allowNull,
    choices: choices || [],
    defaultValue,
  });
