import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { ExportList } from 'medialoopster/icons';
import { getLink } from 'medialoopster/rest';

import { favoritesActions, favoritesSelectors } from '../../../../../state/modules/favorites';
import { SiteExportConfig } from '../../../../../state/modules/rest/siteexportconfigs/types';

interface SiteExportChoiceProps {
  choice: SiteExportConfig;
  baseXMLFileName: string;
}

const SiteExportChoice: FC<SiteExportChoiceProps> = ({
  choice,
  baseXMLFileName,
}: SiteExportChoiceProps) => {
  const dispatch = useDispatch();
  const isSelectedListAssetsLoading = useSelector(favoritesSelectors.isSelectedListAssetsLoading);
  const isSelectedFavoritesListEmpty = useSelector(favoritesSelectors.isSelectedFavoritesListEmpty);
  const selectedFavoritesListXMLDownloadURL = useSelector(
    favoritesSelectors.getSelectedFavoritesListXMLDownloadURL,
  );
  const relatedRenderCollectionLinkPermission: string | true | undefined = useMemo(
    () =>
      getLink(choice, 'renderactivities')?.methods?.POST?.code === 'ok' ||
      getLink(choice, 'renderactivities')?.methods?.POST?.message,
    [choice],
  );

  const exportChoiceTitle = useCallback(
    (exportChoice: SiteExportConfig) => {
      if (relatedRenderCollectionLinkPermission !== true) {
        return relatedRenderCollectionLinkPermission || gettext('No permission to export to site');
      }
      if (isSelectedListAssetsLoading) {
        return gettext('Assets are being loaded');
      }
      if (isSelectedFavoritesListEmpty) {
        return gettext('No assets to export');
      }
      return exportChoice.description;
    },
    [
      isSelectedFavoritesListEmpty,
      isSelectedListAssetsLoading,
      relatedRenderCollectionLinkPermission,
    ],
  );
  return (
    <MenuItem
      disabled={
        isSelectedFavoritesListEmpty ||
        isSelectedListAssetsLoading ||
        relatedRenderCollectionLinkPermission !== true
      }
      tooltip={exportChoiceTitle(choice)}
      icon={<ExportList fontSize="small" />}
      label={choice.name}
      onClick={() => {
        if (selectedFavoritesListXMLDownloadURL)
          dispatch(
            favoritesActions.exportToRenderEngine(
              choice,
              selectedFavoritesListXMLDownloadURL,
              baseXMLFileName,
            ),
          );
      }}
    />
  );
};

export default SiteExportChoice;
