import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import { AudioRootState } from './types';

export default combineReducers<AudioRootState>({
  assets: createResourcesReducer('audioasset'),
  projectsCollections: createResourcesReducer('audioasset-projects'),
});
