import { DateTime } from 'luxon';
import { combineReducers } from 'redux';

import { ReadonlyRecord } from 'medialoopster/types';

import {
  AddFiles,
  ADD_FILES,
  ValidateUpload,
  VALIDATE_UPLOAD,
  FileUploadValidateError,
  FileUploadValidateSuccess,
  FileUploadError,
  FileUploadProgress,
  FileUploadSuccess,
  FILE_UPLOAD_VALIDATE_ERROR,
  FILE_UPLOAD_VALIDATE_SUCCESS,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_SUCCESS,
  RemoveUpload,
  REMOVE_UPLOAD,
  SetUploadName,
  SET_UPLOAD_NAME,
  Upload,
  UploadFile,
  UploadStatus,
  UPLOAD_FILE,
  SetUploadURL,
  SET_UPLOAD_URL,
  UPLOAD_ALL,
  UploadAll,
  START_UPLOAD,
  StartUpload,
} from './types';

export const uploadsReducer = (
  uploads: ReadonlyRecord<string, Upload> = {},
  action:
    | AddFiles
    | UploadFile
    | FileUploadSuccess
    | FileUploadError
    | SetUploadName
    | SetUploadURL
    | RemoveUpload
    | FileUploadValidateSuccess
    | FileUploadValidateError
    | ValidateUpload
    | FileUploadProgress
    | UploadAll
    | StartUpload,
): ReadonlyRecord<string, Upload> => {
  const updateUpload = (
    objectURL: string,
    update: Partial<Upload>,
  ): ReadonlyRecord<string, Upload> => ({
    ...uploads,
    [objectURL]: uploads[objectURL] && {
      ...uploads[objectURL],
      ...update,
    },
  });
  switch (action.type) {
    case ADD_FILES:
      return {
        ...uploads,
        ...Object.fromEntries(
          action.payload.files.map(({ objectURL, name, size }) => {
            return [
              objectURL,
              {
                name,
                size,
                objectURL,
                uploadURL: action.payload.uploadURL,
                status: UploadStatus.VALIDATING,
                errors: [],
                bytesUploaded: 0,
                bytesTotal: 0,
              },
            ];
          }),
        ),
      };
    case SET_UPLOAD_NAME:
      return updateUpload(action.payload.objectURL, {
        name: action.payload.name,
        status: UploadStatus.VALIDATING,
      });
    case SET_UPLOAD_URL:
      return updateUpload(action.payload.objectURL, {
        uploadURL: action.payload.uploadURL,
        status: UploadStatus.VALIDATING,
      });
    case VALIDATE_UPLOAD:
      return updateUpload(action.payload.objectURL, { status: UploadStatus.VALIDATING });
    case FILE_UPLOAD_VALIDATE_SUCCESS:
      return updateUpload(action.payload.objectURL, { errors: [], status: UploadStatus.READY });
    case FILE_UPLOAD_VALIDATE_ERROR:
      return updateUpload(action.payload.objectURL, {
        errors: action.payload.errors,
        status: UploadStatus.INVALID,
      });
    case UPLOAD_ALL: {
      const mapUpload = (upload: Upload): Upload => {
        if (upload.status === UploadStatus.STARTED) {
          // Do not reset already running uploads.
          return upload;
        }
        if (upload.status !== UploadStatus.READY) {
          return { ...upload, bytesUploaded: 0, bytesTotal: 0, startTime: undefined };
        }
        return {
          ...upload,
          bytesUploaded: 0,
          bytesTotal: upload.size,
          startTime: DateTime.local().toMillis(),
        };
      };
      return Object.fromEntries(
        Object.entries(uploads).map(([objectURL, upload]) => [objectURL, mapUpload(upload)]),
      );
    }
    case START_UPLOAD: {
      const mapUpload = (objectURL: string, upload: Upload): Upload => {
        if (upload.status === UploadStatus.STARTED) {
          // Do not reset already running uploads.
          return upload;
        }
        if (objectURL !== action.payload.objectURL) {
          return { ...upload, bytesUploaded: 0, bytesTotal: 0, startTime: undefined };
        }
        return {
          ...upload,
          bytesUploaded: 0,
          bytesTotal: upload.size,
          startTime: DateTime.local().toMillis(),
        };
      };
      return Object.fromEntries(
        Object.entries(uploads).map(([objectURL, upload]) => [
          objectURL,
          mapUpload(objectURL, upload),
        ]),
      );
    }
    case UPLOAD_FILE:
      return updateUpload(action.payload.objectURL, { status: UploadStatus.STARTED });
    case FILE_UPLOAD_PROGRESS:
      return updateUpload(action.payload.objectURL, {
        bytesUploaded: action.payload.bytesUploaded,
        bytesTotal: action.payload.bytesTotal,
      });
    case FILE_UPLOAD_SUCCESS: {
      URL.revokeObjectURL(action.payload.objectURL);
      return updateUpload(action.payload.objectURL, {
        status: UploadStatus.SUCCESS,
      });
    }
    case FILE_UPLOAD_ERROR:
      return updateUpload(action.payload.objectURL, {
        status: UploadStatus.ERROR,
        errors: action.payload.errors,
      });
    case REMOVE_UPLOAD: {
      URL.revokeObjectURL(action.payload.objectURL);
      return Object.fromEntries(
        Object.entries(uploads).filter(([objectURL]) => objectURL !== action.payload.objectURL),
      );
    }
    default:
      return uploads;
  }
};

export default combineReducers({
  uploads: uploadsReducer,
});
