import { getResource, ResourceMap } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { Site } from './types';

export const getMonitoringURL = (state: RootState): string | undefined =>
  state.main.monitoring?.href;

export const getManagementURL = (state: RootState): string | undefined =>
  state.main.management?.href;

export const getLicenseManagementURL = (state: RootState): string | undefined =>
  state.main.licenseManagement?.href;

export const getAdminURL = (state: RootState): string | undefined => state.main.admin?.href;

export const getVersion = (state: RootState): string => state.main.version;

export const getSites = (state: RootState): ResourceMap<Site> => state.main.sites;

export const getCurrentSiteURL = (state: RootState): string | null =>
  state.main.currentSite?.href || null;

export const getCurrentSiteName = (state: RootState): string => {
  const currentSiteURL = getCurrentSiteURL(state);
  if (!currentSiteURL) {
    return '';
  }
  const currentSite = getResource(getSites(state), currentSiteURL);
  return currentSite?.name || '';
};
