import {
  AddFiles,
  ADD_FILES,
  ValidateUpload,
  VALIDATE_UPLOAD,
  FileUploadValidateError,
  FileUploadValidateSuccess,
  FileUploadError,
  FileUploadProgress,
  FileUploadSuccess,
  FILE_UPLOAD_VALIDATE_ERROR,
  FILE_UPLOAD_VALIDATE_SUCCESS,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_SUCCESS,
  RemoveUpload,
  REMOVE_UPLOAD,
  SetUploadName,
  SET_UPLOAD_NAME,
  UploadAll,
  UploadFile,
  UPLOAD_ALL,
  UPLOAD_FILE,
  SetUploadURL,
  SET_UPLOAD_URL,
  UploadError,
  UploadedFile,
  START_UPLOAD,
  StartUpload,
} from './types';

export const addFiles = (
  files: ReadonlyArray<UploadedFile>,
  uploadURL: string | null,
): AddFiles => ({
  type: ADD_FILES,
  payload: {
    files,
    uploadURL,
  },
});

export const fileUploadSuccess = (objectURL: string): FileUploadSuccess => ({
  type: FILE_UPLOAD_SUCCESS,
  payload: {
    objectURL,
  },
});

export const fileUploadError = (
  objectURL: string,
  errors: ReadonlyArray<UploadError>,
): FileUploadError => {
  return {
    type: FILE_UPLOAD_ERROR,
    payload: {
      objectURL,
      errors,
    },
  };
};

export const fileUploadValidateSuccess = (objectURL: string): FileUploadValidateSuccess => ({
  type: FILE_UPLOAD_VALIDATE_SUCCESS,
  payload: {
    objectURL,
  },
});

export const fileUploadValidateError = (
  objectURL: string,
  errors: ReadonlyArray<UploadError>,
): FileUploadValidateError => ({
  type: FILE_UPLOAD_VALIDATE_ERROR,
  payload: {
    objectURL,
    errors,
  },
});

export const uploadFile = (objectURL: string): UploadFile => ({
  type: UPLOAD_FILE,
  payload: {
    objectURL,
  },
});

export const uploadAll = (): UploadAll => ({
  type: UPLOAD_ALL,
});

export const startUpload = (objectURL: string): StartUpload => ({
  type: START_UPLOAD,
  payload: {
    objectURL,
  },
});

export const setUploadName = (objectURL: string, name: string): SetUploadName => ({
  type: SET_UPLOAD_NAME,
  payload: {
    objectURL,
    name,
  },
});

export const setUploadURL = (objectURL: string, uploadURL: string): SetUploadURL => ({
  type: SET_UPLOAD_URL,
  payload: {
    objectURL,
    uploadURL,
  },
});

export const removeUpload = (objectURL: string): RemoveUpload => ({
  type: REMOVE_UPLOAD,
  payload: {
    objectURL,
  },
});

export const validateUpload = (objectURL: string): ValidateUpload => ({
  type: VALIDATE_UPLOAD,
  payload: {
    objectURL,
  },
});

export const fileUploadProgress = (
  objectURL: string,
  bytesUploaded: number,
  bytesTotal: number,
): FileUploadProgress => ({
  type: FILE_UPLOAD_PROGRESS,
  payload: {
    objectURL,
    bytesUploaded,
    bytesTotal,
  },
});
