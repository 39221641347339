import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';

import { detailsSelectors } from '../../state/modules/details';
import { CollectionFile } from '../../state/modules/details/types';
import { AssetCollection } from '../../state/modules/rest/collection/types';
import CollectionFileIcon from './CollectionFileIcon';

type Props = {
  readonly collection: AssetCollection;
};

const CollectionFiles: FC<Props> = ({ collection }) => {
  const files = useSelector(detailsSelectors.getCurrentCollectionFiles);
  if (files.length === 0) {
    return null;
  }
  const renderRow = (file: CollectionFile) => (
    <TableRow key={file.path}>
      <TableCell>
        <Grid item container spacing={1}>
          <Grid item>
            <CollectionFileIcon fileType={file.type} />
          </Grid>
          <Grid item>
            <Typography variant="caption">{file.name}</Typography>
          </Grid>
        </Grid>
        <Typography variant="caption">
          <em>…/{file.folder}</em>
        </Typography>
      </TableCell>
    </TableRow>
  );
  return (
    <Table size="small" style={{ tableLayout: 'fixed', maxWidth: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body2">
              <strong>{gettext('Files used in collection')}</strong>
            </Typography>
            <Typography variant="caption">
              <em>/{collection.rel_bundle_root}…</em>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{files.map(renderRow)}</TableBody>
    </Table>
  );
};
export default CollectionFiles;
