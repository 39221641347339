import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  INSERT_LINE_BREAK_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  KEY_ENTER_COMMAND,
} from 'lexical';
import React, { useEffect } from 'react';

import {
  $createTranscriptParagraphNode,
  $isTranscriptParagraphNode,
} from '../nodes/TranscriptParagraphNode';
import {
  $createTranscriptTimeBlockNode,
  $isTranscriptTimeBlockNode,
} from '../nodes/TranscriptTimeBlockNode';
import { $isTranscriptUnitNode } from '../nodes/TranscriptUnitNode';

/**
 * NoOp command handler that prevents other handlers from running.
 */
const doNothingAndStopProcessing = () => true;

/**
 * A command handler for the KEY_ENTER_COMMAND that splits a time block.
 *
 * This command handler splits the time-block when enter is pressed
 * transcript units following the current selection go into the new time-block
 *
 * Note: this could also be handled in INSERT_LINE_BREAK_COMMAND
 */
const splitTimeBlock = () => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    // handle only range selections
    return false;
  }
  let lastNode = selection.getNodes().slice(-1)[0];
  if (!$isTranscriptUnitNode(lastNode)) {
    // handle only selected Units
    return false;
  }

  // Special case:
  //
  // If the cursor is on the first significant character of a unit, the block will be split
  // at the beginning of that unit, e.g. the unit moves to the beginning of the new block.
  //
  // Unless it's the first unit in a block. In that case, the block won't be split.
  const startEndPoints = selection.getStartEndPoints();
  if (startEndPoints && startEndPoints[1].offset === lastNode.getTrimmedStartIndex()) {
    // The cursor is on the first character of a unit.
    const previousSibling = lastNode.getPreviousSibling();
    if (!$isTranscriptUnitNode(previousSibling)) {
      // The cursor is on the first character of the first unit in the block.
      // => Do not split.
      return false;
    }
    // The cursor is on the first character of a unit which is not the first in the block.
    // => Split the block so that the new block starts with the unit.
    lastNode = previousSibling;
  }

  const paragraphNode = lastNode.getParent();
  if (!$isTranscriptParagraphNode(paragraphNode)) {
    throw new Error('TranscriptUnitNode must be a child of TranscriptParagraphNode');
  }
  const timeBlockNode = paragraphNode.getParent();
  if (!$isTranscriptTimeBlockNode(timeBlockNode)) {
    throw new Error('TranscriptParagraphNode must be a child of TranscriptTimeBlockNode');
  }
  const restOfParagraph = lastNode.getNextSiblings();
  if (restOfParagraph.length < 1) {
    return true;
  }
  const firstUnitOfNewParagraph = restOfParagraph[0];
  if (!$isTranscriptUnitNode(firstUnitOfNewParagraph)) {
    throw new Error('TranscriptParagraphNodes may only contain TranscriptUnitNodes');
  }

  // calculate the durations of the old and new time block
  const updatedDurationOldTimeBlock = firstUnitOfNewParagraph
    .getTime()
    .minus(timeBlockNode.getStartTime());
  const newTimeBlockDuration = timeBlockNode.getDuration().minus(updatedDurationOldTimeBlock);

  // The paragraph and time-block have to be constructed and inserted completely and not
  // by NodeTransform to handle the selection correctly.
  const newParagraphNode = $createTranscriptParagraphNode();
  newParagraphNode.append(...restOfParagraph);
  const newTimeBlockNode = $createTranscriptTimeBlockNode(
    timeBlockNode.getSpeakerName(),
    firstUnitOfNewParagraph.getTime(),
    newTimeBlockDuration,
    'new',
  );
  newTimeBlockNode.append(newParagraphNode);
  timeBlockNode.insertAfter(newTimeBlockNode);

  // shorten the duration of old block and update its reference to the next block
  timeBlockNode.setTimeBlockState({
    ...timeBlockNode.getTimeBlockState(),
    duration: updatedDurationOldTimeBlock,
    nextTimeBlockKey: newTimeBlockNode.getKey(),
  });

  // Select the start of the paragraph
  $setSelection(newParagraphNode.selectStart());
  return true;
};

/**
 * A plugin to change the default behaviour/reaction of the editor to input commands.
 *
 * This plugin modifies the default `lexical` behaviour in the following ways:
 * - INSERT_LINE_BREAK is ignored to prevent the creation of line break nodes
 * - INSERT_PARAGRAPH is ignored to prevent uncontrolled creation of paragraph nodes
 * - KEY_ENTER_COMMAND is hooked with logic to split a time block at the current selected unit
 *
 * The plugin works by registering command handlers.
 */
export const TranscriptBehaviourPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      // prevent linebreaks from being inserted.
      editor.registerCommand(
        INSERT_LINE_BREAK_COMMAND,
        doNothingAndStopProcessing,
        COMMAND_PRIORITY_CRITICAL,
      ),
      // prevent logic for insert paragraph.
      editor.registerCommand(
        INSERT_PARAGRAPH_COMMAND,
        doNothingAndStopProcessing,
        COMMAND_PRIORITY_CRITICAL,
      ),
      // split time blocks when pressing enter.
      editor.registerCommand(KEY_ENTER_COMMAND, splitTimeBlock, COMMAND_PRIORITY_NORMAL),
    );
  }, [editor]);

  return null;
};

export default TranscriptBehaviourPlugin;
