import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';

import { AutoSuggestInput } from 'medialoopster/components';

import useWidgetContext from '../hooks/useWidgetContext';

type Props = {
  readonly name: string;
  readonly label: string;
  readonly defaultValue: string;
  readonly disabled?: boolean;
  readonly maxLength?: number;
  readonly onChange?: (val: string) => void;
  readonly onFocus?: () => void;
  readonly rules?: UseControllerProps['rules'];
  readonly options: string[];
  readonly filtering?: (val: string, options: string[]) => string[];
};

const noFiltering = (_: string, options: string[]) => options;

const AutoSuggestWidget: FC<Props> = ({
  name,
  label,
  defaultValue,
  disabled = false,
  rules = {},
  options = [],
  onChange,
  onFocus,
  filtering = noFiltering,
}: Props) => {
  const {
    onChange: onWidgetChange,
    value,
    error,
  } = useWidgetContext({
    name,
    defaultValue,
    rules: {
      ...rules,
    },
    disabled,
  });
  return (
    <AutoSuggestInput
      id={name}
      label={label}
      name={name}
      value={value?.toString() || ''}
      showError={!!error}
      errorMsg={error || undefined}
      onChange={(val) => {
        onWidgetChange(val);
        onChange?.(val || '');
      }}
      onFocus={onFocus}
      options={options}
      filtering={filtering}
      disabled={disabled}
    />
  );
};

export default AutoSuggestWidget;
