import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Konva from 'konva';
import { FC, useRef, useState, useCallback } from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import { useVideoController } from 'medialoopster/components';

import { isVideoAsset } from '../../../businessRules/models/asset/utils';
import { detailsSelectors } from '../../../state/modules/details';
import { personsSelectors, personsTypes } from '../../../state/modules/persons';

const SEGMENT_MIN_PX = 3;

const useStyle = makeStyles(() => ({
  personTimeline: {
    width: '100%',
    height: '20px',
    paddingBottom: '5px',
  },
}));

const PersonTimeline: FC = () => {
  const stage = useRef<Konva.Stage>(null);
  const segments = useSelector(personsSelectors.getPersonTimelineSegments);
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const duration = isVideoAsset(currentAsset) ? currentAsset.duration : 0;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const classes = useStyle();

  const onResize = useCallback(
    (w: number | undefined = 0, h: number | undefined = 0) => {
      setWidth(w);
      setHeight(h);
    },
    [setWidth, setHeight],
  );

  const { ref } = useResizeDetector({ onResize });

  const enhanceSegment = (segment: personsTypes.TimelineSegment) => {
    const ratio = width / duration;
    let segmentWidth = (segment.endFrameNo - segment.startFrameNo) * ratio;
    let x = segment.startFrameNo * ratio;
    if (segmentWidth < SEGMENT_MIN_PX) {
      x -= (SEGMENT_MIN_PX - segmentWidth) / 2;
      x = x < 0 ? 0 : x;
      segmentWidth = SEGMENT_MIN_PX;
    }
    return { ...segment, width: segmentWidth, x };
  };
  const videoController = useVideoController();
  const onSegmentSelect = useCallback(
    (segment: personsTypes.TimelineSegment) => {
      if (currentAsset) {
        videoController.seekFrame(segment.startFrameNo);
      }
    },
    [currentAsset, videoController],
  );

  return (
    <>
      {duration > 0 && segments.length > 0 && (
        <div ref={ref}>
          <div id="person-timeline" className={classes.personTimeline}>
            <Stage ref={stage} height={height} width={width}>
              <Layer>
                {width > 0 &&
                  height > 0 &&
                  segments
                    .map((segment) => enhanceSegment(segment))
                    .map((segment) => (
                      <Rect
                        key={`${segment.id}`}
                        x={segment.x}
                        y={0}
                        width={segment.width}
                        height={16}
                        fill={segment.color}
                        onMouseEnter={() => {
                          if (stage.current) {
                            stage.current.container().style.cursor = 'pointer';
                          }
                        }}
                        onMouseLeave={() => {
                          if (stage.current) {
                            stage.current.container().style.cursor = 'auto';
                          }
                        }}
                        onClick={() => onSegmentSelect(segment)}
                      />
                    ))}
              </Layer>
            </Stage>
          </div>
        </div>
      )}
      <Divider />
    </>
  );
};

export default PersonTimeline;
