import { ConnectorResource } from 'medialoopster/modules';
import { Resource } from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { AssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';

export const WEBHOOKS_LOADED = 'FRONTEND/OPERATIONS/WEBHOOKS_LOADED';
export const FETCH_ASSET_EXCHANGE_CONNECTORS =
  'FRONTEND/OPERATIONS/FETCH_ASSET_EXCHANGE_CONNECTORS';
export const ASSET_EXCHANGE_CONNECTORS_LOADED =
  'FRONTEND/OPERATIONS/ASSET_EXCHANGE_CONNECTORS_LOADED';
export const EXCHANGE_ASSETS = 'FRONTEND/OPERATIONS/EXCHANGE_ASSETS';
export const REMOVE_EXCHANGED_ASSETS = 'FRONTEND/OPERATIONS/REMOVE_EXCHANGED_ASSETS';
export const CHECK_EXCHANGED_ASSETS_REMOVE_ACTION =
  'FRONTEND/OPERATIONS/CHECK_EXCHANGED_ASSETS_REMOVE_ACTION';
export const EXCHANGED_ASSETS_REMOVE_ACTION_CHECKED =
  'FRONTEND/OPERATIONS/EXCHANGED_ASSETS_REMOVE_ACTION_CHECKED';
export const EXCHANGED_ASSETS_REMOVED = 'FRONTEND/OPERATIONS/EXCHANGED_ASSETS_REMOVED';
export const TOGGLE_APPROVAL_STATUS = 'FRONTEND/OPERATIONS/TOGGLE_APPROVAL_STATUS';

//-------------
// Action Types
//-------------

export interface WebhooksLoaded {
  readonly type: typeof WEBHOOKS_LOADED;
  readonly payload: {
    readonly instances: ReadonlyArray<Webhook>;
  };
}

export interface FetchAssetExchangeConnectors {
  readonly type: typeof FETCH_ASSET_EXCHANGE_CONNECTORS;
}

export interface AssetExchangeConnectorsLoaded {
  readonly type: typeof ASSET_EXCHANGE_CONNECTORS_LOADED;
  readonly payload: {
    readonly instances: ReadonlyArray<ConnectorResource<'assetexchangeconnector'>>;
  };
}

export interface ExchangeAssets {
  readonly type: typeof EXCHANGE_ASSETS;
  readonly payload: {
    readonly actionURI: string;
    readonly assetURIs: ReadonlyArray<string>;
    readonly assetTitles: Record<string, string>;
    readonly alreadyUploadedAssetURIs: ReadonlyArray<string>;
  };
}

export interface RemoveExchangedAssets {
  readonly type: typeof REMOVE_EXCHANGED_ASSETS;
  readonly payload: {
    readonly actionURI: string;
    readonly assetURIs: ReadonlyArray<string>;
    readonly assetTitles: Record<string, string>;
    readonly notUploadedAssetURIs: ReadonlyArray<string>;
  };
}

export interface ExchangedAssetsRemoved {
  readonly type: typeof EXCHANGED_ASSETS_REMOVED;
}

export interface CheckExchangedAssetsRemoveAction {
  readonly type: typeof CHECK_EXCHANGED_ASSETS_REMOVE_ACTION;
  readonly payload: {
    readonly connectorURI: string;
    readonly actionURI: string;
    readonly assetURIs: ReadonlyArray<string>;
  };
}

export interface ExchangedAssetsRemoveActionChecked {
  readonly type: typeof EXCHANGED_ASSETS_REMOVE_ACTION_CHECKED;
  readonly payload: {
    readonly connectorURI: string;
    readonly isSuccess: boolean;
  };
}

export interface ToggleApprovalStatus {
  readonly type: typeof TOGGLE_APPROVAL_STATUS;
  readonly payload: {
    asset: Asset;
  };
}

//---------------
// Resource Types
//---------------

export interface WebhookCustomPayloadField {
  readonly id: number;
  readonly field: { readonly key: string; readonly name: string };
  readonly is_required: boolean;
  readonly order: number;
}

export interface Webhook extends Resource<'webhook'> {
  readonly id: number;
  readonly display_name: string;
  readonly username: string;
  readonly password: string;
  readonly template_string: string;
  readonly content_type: string;
  readonly custom_payload_fields: ReadonlyArray<WebhookCustomPayloadField>;
  readonly active: boolean;
  readonly deactivation_reason: string;
}

//------------------
// Redux State Types
//------------------

export interface AssetExchangeConnector extends ConnectorResource<'assetexchangeconnector'> {
  readonly isRemove: boolean;
}

export interface AssetChoice {
  readonly url: string;
  readonly name: string;
  readonly typeName: AssetType;
  readonly isProduction: boolean;
}

export interface AssetChoiceWithError extends AssetChoice {
  readonly error?: string;
}

export interface CollectionChoice extends AssetChoice {
  readonly hasFiles: boolean;
}

export interface OperationsRootState {
  readonly webhooks: ReadonlyArray<Webhook>;
  readonly assetExchangeConnectors: ReadonlyArray<ConnectorResource<'assetexchangeconnector'>>;
  readonly isAssetExchangeRemove: ReadonlyRecord<string, boolean>;
}
