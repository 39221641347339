import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, merge, of, Subject, Subscriber } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  isAPIFieldError,
  ResourceLoadedAction,
  RESTEpicDependencies,
  RESTErrorResponse,
  unauthorizedRequestAction,
  UnauthorizedRequestAction,
} from 'medialoopster/rest';
import { getTokenAuthHeader, loginSelectors } from 'medialoopster/state/login';

import { RootState } from '../../types/rootState';
import {
  fileUploadError,
  fileUploadProgress,
  fileUploadSuccess,
  fileUploadValidateError,
  fileUploadValidateSuccess,
  uploadFile,
  validateUpload,
} from './actions';
import { getUploads } from './selectors';
import {
  ADD_FILES,
  AddFiles,
  FileUploadError,
  FileUploadProgress,
  FileUploadSuccess,
  FileUploadValidateError,
  FileUploadValidateSuccess,
  SET_UPLOAD_NAME,
  SET_UPLOAD_URL,
  SetUploadName,
  SetUploadURL,
  START_UPLOAD,
  StartUpload,
  UPLOAD_ALL,
  UPLOAD_FILE,
  UploadAll,
  UploadError,
  UploadFile,
  UploadResource,
  UploadStatus,
  VALIDATE_UPLOAD,
  ValidateUpload,
} from './types';

const restErrorResponseToUploadErrors = (response: RESTErrorResponse): ReadonlyArray<UploadError> =>
  (response.errors || []).map((error) => ({
    message: error.detail,
    code: error.code,
    ...(isAPIFieldError(error) ? { pointer: error.source.pointer } : {}),
  }));

export const validateAddedUploadsEpic: Epic<Action, ValidateUpload> = (action$) =>
  action$.pipe(
    ofType<Action, AddFiles['type'], AddFiles>(ADD_FILES),
    mergeMap(({ payload: { files } }) =>
      of(...files.map((uploadedFile) => validateUpload(uploadedFile.objectURL))),
    ),
  );

export const validateUploadOnChangeEpic: Epic<Action, ValidateUpload> = (action$) =>
  action$.pipe(
    ofType<Action, (SetUploadName | SetUploadURL)['type'], SetUploadName | SetUploadURL>(
      SET_UPLOAD_NAME,
      SET_UPLOAD_URL,
    ),
    map(({ payload: { objectURL } }) => validateUpload(objectURL)),
  );

export const validateUploadEpic: Epic<
  Action,
  | ResourceLoadedAction
  | FileUploadValidateSuccess
  | FileUploadValidateError
  | UnauthorizedRequestAction,
  RootState,
  RESTEpicDependencies
> = (action$, state$, { postResource }) =>
  action$.pipe(
    ofType<Action, ValidateUpload['type'], ValidateUpload>(VALIDATE_UPLOAD),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { objectURL },
        },
        state,
      ]) => {
        const uploadToValidate = getUploads(state)[objectURL];
        if (!uploadToValidate) {
          return EMPTY;
        }
        const { uploadURL, name: filename, size } = uploadToValidate;
        if (!uploadURL) {
          // TODO: Error: no production selected
          return EMPTY;
        }
        return postResource<
          UploadResource,
          UploadResource,
          FileUploadValidateSuccess,
          FileUploadValidateError
        >(
          uploadURL,
          {
            filename,
            size,
          },
          () => of(fileUploadValidateSuccess(objectURL)),
          ({ response }) =>
            of(fileUploadValidateError(objectURL, restErrorResponseToUploadErrors(response))),
        );
      },
    ),
  );

export const uploadFileEpic: Epic<
  Action,
  FileUploadSuccess | FileUploadError | FileUploadProgress | UnauthorizedRequestAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, UploadFile['type'], UploadFile>(UPLOAD_FILE),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { objectURL },
        },
        state,
      ]) => {
        const uploadToStart = getUploads(state)[objectURL];
        if (!uploadToStart) {
          return EMPTY;
        }
        const { uploadURL, name } = uploadToStart;
        if (!uploadURL) {
          // TODO: Error: no production selected
          return EMPTY;
        }
        const progressSubscriber = new Subject<ProgressEvent>().pipe(
          map((e: ProgressEvent) => {
            return fileUploadProgress(objectURL, e.loaded, e.total);
          }),
          catchError(() => EMPTY),
        );
        return merge(
          progressSubscriber,
          ajax<BlobPart>({
            method: 'GET',
            url: objectURL,
            responseType: 'blob',
            headers: getTokenAuthHeader(loginSelectors.getToken(state)),
          }).pipe(
            mergeMap((response) => {
              const file = new Blob([response.response], {
                type: response.xhr.getResponseHeader('Content-Type') || undefined,
              });
              const formData = new FormData();
              formData.append('file', file);
              formData.append('filename', name);
              return ajax({
                method: 'POST',
                url: uploadURL,
                body: formData,
                headers: getTokenAuthHeader(loginSelectors.getToken(state)),
                progressSubscriber: progressSubscriber as unknown as Subscriber<ProgressEvent>,
              }).pipe(
                map(() => fileUploadSuccess(objectURL)),
                catchError((err) => {
                  if (err && err.status === 401) {
                    return of(unauthorizedRequestAction());
                  }
                  return of(
                    fileUploadError(objectURL, restErrorResponseToUploadErrors(err.response)),
                  );
                }),
              );
            }),
            catchError((err) => {
              if (err && err.status === 401) {
                return of(unauthorizedRequestAction()); // TODO: Test in ML-3735
              }
              return of(fileUploadError(objectURL, []));
            }),
          ),
        );
      },
    ),
  );

export const uploadAllEpic: Epic<Action, UploadFile, RootState> = (action$, state$) =>
  action$.pipe(
    ofType<Action, UploadAll['type'], UploadAll>(UPLOAD_ALL),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const uploads = getUploads(state);
      return of(...Object.values(uploads)).pipe(
        filter(({ status }) => status === UploadStatus.READY),
        map((upload) => uploadFile(upload.objectURL)),
      );
    }),
  );

export const startUploadEpic: Epic<Action, UploadFile> = (action$) =>
  action$.pipe(
    ofType<Action, StartUpload['type'], StartUpload>(START_UPLOAD),
    map(({ payload: { objectURL } }) => uploadFile(objectURL)),
  );

export default combineEpics<
  Action,
  | UploadFile
  | ValidateUpload
  | ResourceLoadedAction
  | FileUploadValidateError
  | FileUploadValidateSuccess
  | FileUploadSuccess
  | FileUploadError
  | FileUploadProgress
  | UnauthorizedRequestAction,
  RootState
>(
  validateAddedUploadsEpic,
  validateUploadOnChangeEpic,
  validateUploadEpic,
  uploadFileEpic,
  uploadAllEpic,
  startUploadEpic,
);
