import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { stringifySearchDict } from 'medialoopster';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { DropdownMenu, MenuItem } from 'medialoopster/components';
import {
  Archive,
  ClearList,
  Collection,
  CreateList,
  Download,
  DownloadFavorites,
  Edit,
  RemoveFromArchive,
  Restore,
  RoughCutEditor,
  ShareAsset,
  SharedList,
} from 'medialoopster/icons';
import { unauthorizedRequestAction } from 'medialoopster/rest';
import { loginSelectors } from 'medialoopster/state/login';
import { downloadFromURL } from 'medialoopster/state/main/actions';

import { archiveActions, archiveSelectors } from '../../../../state/modules/archive';
import { assetSharingSelectors } from '../../../../state/modules/assetSharing';
import { detailsSelectors } from '../../../../state/modules/details';
import { downloadSelectors } from '../../../../state/modules/download';
import { favoritesActions, favoritesSelectors } from '../../../../state/modules/favorites';
import { productionsSelectors } from '../../../../state/modules/productions';
import BulkDownloadModal from '../../../Header/AssetActionMenu/menuitems/useDownloadActionMenuEntry/BulkDownloadModal';
import useCheckBulkDownload from '../../../Header/AssetActionMenu/menuitems/useDownloadActionMenuEntry/useCheckBulkDownload';
import AddFavoriteAssetsToCollectionMenu from './AddFavoriteAssetsToCollectionMenu';
import SiteExportChoices from './SiteExportChoices';
import TransferActionButton from './TransferActionButton';

const useStyles = makeStyles(() => ({
  buttonRoot: {
    minWidth: 'min-content',
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
  },
  itemText: {
    padding: '.25rem 1.5rem',
    opacity: 0.5,
  },
}));
type FavoritesActionMenuProps = {
  onSharedListsClicked: () => void;
  onEditListClicked: () => void;
  onCreateListClicked: () => void;
  onCreateCollectionFromList: () => void;
  onClearListClicked: () => void;
};

const FavoritesActionMenu: FC<FavoritesActionMenuProps> = ({
  onSharedListsClicked,
  onEditListClicked,
  onCreateListClicked,
  onCreateCollectionFromList,
  onClearListClicked,
}: FavoritesActionMenuProps) => {
  const classes = useStyles();

  // useSelector
  const token = useSelector(loginSelectors.getToken);
  const dispatch = useDispatch();

  const selectedList = useSelector(favoritesSelectors.getSelectedList, shallowEqual);
  const favoritesListContent = useSelector(favoritesSelectors.getSelectedListItems, shallowEqual);
  const favoriteItemAssets = useSelector(favoritesSelectors.getSelectedListAssets);
  const rceExportURL = useSelector(favoritesSelectors.getRceExportURL);
  const canRestoreArchivedAssets = useSelector(archiveSelectors.canRestoreArchivedAssets);
  const canArchiveAssets = useSelector(archiveSelectors.canArchiveAssets);
  const canCreateCollection = useSelector(detailsSelectors.canCreateCollection);
  const hasWriteableProductions = useSelector(
    productionsSelectors.hasWriteableProductionsWithProjectDevice,
  );

  const canShareProductionAsset = useSelector(assetSharingSelectors.canShareProductionAsset);
  const isSelectedListAssetsLoading = useSelector(favoritesSelectors.isSelectedListAssetsLoading);
  const hasNoTargetProduction = useSelector(assetSharingSelectors.hasNoTargetProduction)(
    favoriteItemAssets,
  );
  const selectedProduction = useSelector(productionsSelectors.getSelectedProduction);
  const proxyDownloadActivitiesURL = useSelector(downloadSelectors.getProxyDownloadActivitiesURL);
  const highresDownloadActivitiesURL = useSelector(
    downloadSelectors.getHighresDownloadActivitiesURL,
  );
  const allowedProxyDownloadAssetTypes = useSelector(
    downloadSelectors.getAllowedProxyDownloadAssetTypes,
  );
  const allowedHighresDownloadAssetTypes = useSelector(
    downloadSelectors.getAllowedHighresDownloadAssetTypes,
  );
  const assetChoices = useSelector(favoritesSelectors.getAssetChoices);

  // States
  const [favoritesListEmpty, setFavoritesListEmpty] = useState(false);
  const [contentCanBeArchived, setContentCanBeArchived] = useState(false);
  const [contentCanBeRestored, setContentCanBeRestored] = useState(false);
  const [isProxyDownloadModalVisible, setProxyDownloadModalVisible] = useState(false);
  const [isHighresDownloadModalVisible, setHighresDownloadModalVisible] = useState(false);

  const canDownloadProxy =
    allowedProxyDownloadAssetTypes.length > 0 && !!proxyDownloadActivitiesURL;
  const canDownloadHighres =
    allowedHighresDownloadAssetTypes.length > 0 && !!highresDownloadActivitiesURL;

  const {
    hasPermission: isProxyDownloadActionMenuDryRunAllowed,
    isAuthorized: isProxyDownloadActionMenuDryRunAuthorized,
  } = useCheckBulkDownload(
    token,
    canDownloadProxy,
    isSelectedListAssetsLoading,
    proxyDownloadActivitiesURL,
    assetChoices,
  );
  const {
    hasPermission: isHighresDownloadActionMenuDryRunAllowed,
    isAuthorized: isHighresDownloadActionMenuDryRunAuthorized,
  } = useCheckBulkDownload(
    token,
    canDownloadHighres,
    isSelectedListAssetsLoading,
    highresDownloadActivitiesURL,
    assetChoices,
  );

  useEffect(() => {
    if (
      isHighresDownloadActionMenuDryRunAuthorized === false ||
      isProxyDownloadActionMenuDryRunAuthorized === false
    ) {
      dispatch(unauthorizedRequestAction());
    }
  }, [
    dispatch,
    isHighresDownloadActionMenuDryRunAuthorized,
    isProxyDownloadActionMenuDryRunAuthorized,
  ]);

  const selectedFavoritesListXMLDownloadURL = useSelector(
    favoritesSelectors.getSelectedFavoritesListXMLDownloadURL,
  );

  useEffect(() => {
    setFavoritesListEmpty(!(favoritesListContent && favoritesListContent.length > 0));
    const archive = favoriteItemAssets.map((asset) => !asset.is_archive && asset.is_production);
    setContentCanBeArchived(archive.includes(true));
    setContentCanBeRestored(archive.includes(false));
  }, [favoritesListContent, favoriteItemAssets]);

  const shareListContentTitle = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (favoritesListEmpty) {
      return gettext('This favorite item list has no content to share');
    }
    if (hasNoTargetProduction) {
      return gettext('You cannot share assets because no target productions available');
    }
    return gettext('Share the content of this favorite item list');
  };

  const sendListContentToArchiveTitle = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (favoritesListEmpty) {
      return gettext('This favorite item list has no content to archive');
    }
    if (contentCanBeArchived) {
      return gettext('Archive the content of this favorite item list');
    }
    return gettext('The content of this favorite item list has already been archived');
  };

  const removeListContentFromArchiveQueueTitle = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (favoritesListEmpty) {
      return gettext('This favorite item list has no content to remove from the archive queue');
    }
    return gettext('Remove the content of this favorite item list from the archive queue');
  };

  const restoreListContentFromArchiveTitle = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (favoritesListEmpty) {
      return gettext('This favorite item list has no content to restore');
    }
    if (contentCanBeRestored) {
      return gettext('Restore the content of this favorite item list');
    }
    return gettext('The content of this favorite item list is already available');
  };

  const downloadFavoritesListFileTitle = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (favoritesListEmpty) {
      return gettext('This favorites list has no content and cannot be downloaded');
    }
    if (!selectedProduction) {
      return gettext('No production selected');
    }
    return gettext('Download selected favorites list as sequence file');
  };

  const openInRoughCutEditorTitle = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (favoritesListEmpty) {
      return gettext('No assets to open in Rough Cut Editor');
    }
    return gettext('Open in Rough Cut Editor');
  };

  const createCollectionFromListContentTitle = () => {
    if (!hasWriteableProductions) {
      return gettext(
        'You do not have write access for a production with default collection asset device',
      );
    }
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (favoritesListEmpty) {
      return gettext('This favorites list has no content to create a collection from');
    }
    return gettext('Create a collection from the content of this favorites list');
  };

  const downloadFavoritesListFile = useCallback(() => {
    if (selectedFavoritesListXMLDownloadURL && selectedProduction) {
      dispatch(
        downloadFromURL(
          `${selectedFavoritesListXMLDownloadURL}?${stringifySearchDict({
            san_mount: selectedProduction.san_mount,
          })}`,
          {
            Accept: 'text/xml; version=3',
          },
        ),
      );
    }
  }, [selectedFavoritesListXMLDownloadURL, selectedProduction, dispatch]);

  const getDownloadProxiesTooltip = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (assetChoices.length === 0) {
      return gettext('There are no assets to download in this list');
    }
    if (!isProxyDownloadActionMenuDryRunAllowed) {
      return gettext('None of the assets in this list can be downloaded');
    }
    return gettext('Download proxy files of the assets in this list');
  };

  const getDownloadHighresTooltip = () => {
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (assetChoices.length === 0) {
      return gettext('There are no assets to download in this list');
    }
    if (!isHighresDownloadActionMenuDryRunAllowed) {
      return gettext('None of the assets in this list can be downloaded');
    }
    return gettext('Download highres of the assets in this list');
  };
  return (
    <DropdownMenu
      popupId="favorites-menu"
      buttonProps={{
        classes: { root: classes.buttonRoot },
        variant: 'outlined',
        size: 'small',
      }}
      label={gettext('Favorite Actions')}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem
        key="new-list"
        disabled={false}
        tooltip={gettext('Create a new favorites list')}
        onClick={onCreateListClicked}
        icon={<CreateList fontSize="small" />}
        label={gettext('New list...')}
      />
      <MenuItem
        key="shared-lists"
        disabled={false}
        tooltip={gettext('Choose from the lists which are shared with you')}
        onClick={onSharedListsClicked}
        icon={<SharedList fontSize="small" />}
        label={gettext('Shared lists...')}
      />
      {selectedList?.id && (
        <div key="list-actions">
          <Divider key="favorites-menu-devider" light component="li" />
          <ListItem dense className={classes.itemText}>
            <ListItemText
              primary={`${gettext('Actions for list:')} ${selectedList && selectedList.name}`}
              primaryTypographyProps={{ variant: 'caption', noWrap: true }}
            />
          </ListItem>
          <MenuItem
            disabled={false}
            tooltip={gettext('Edit this favorite item list')}
            onClick={onEditListClicked}
            icon={<Edit fontSize="small" />}
            label={gettext('Edit list...')}
          />
          {canShareProductionAsset && (
            <MenuItem
              disabled={favoritesListEmpty || isSelectedListAssetsLoading || hasNoTargetProduction}
              tooltip={shareListContentTitle()}
              onClick={() => {
                dispatch(favoritesActions.shareListContents());
              }}
              icon={<ShareAsset fontSize="small" />}
              label={gettext('Share list content...')}
            />
          )}
          {canCreateCollection && (
            <MenuItem
              disabled={
                !hasWriteableProductions || favoritesListEmpty || isSelectedListAssetsLoading
              }
              tooltip={createCollectionFromListContentTitle()}
              onClick={onCreateCollectionFromList}
              icon={<Collection fontSize="small" />}
              label={gettext('Create collection from list content...')}
            />
          )}
          <AddFavoriteAssetsToCollectionMenu assets={favoriteItemAssets} />
          {canArchiveAssets && (
            <MenuItem
              disabled={favoritesListEmpty || !contentCanBeArchived || isSelectedListAssetsLoading}
              tooltip={sendListContentToArchiveTitle()}
              onClick={() => {
                dispatch(archiveActions.archiveAssets(favoriteItemAssets));
              }}
              icon={<Archive fontSize="small" />}
              label={gettext('Send list content to archive')}
            />
          )}
          {canRestoreArchivedAssets && (
            <MenuItem
              disabled={favoritesListEmpty || isSelectedListAssetsLoading}
              tooltip={removeListContentFromArchiveQueueTitle()}
              onClick={() => {
                dispatch(archiveActions.revokeArchiveActivities(favoriteItemAssets));
              }}
              icon={<RemoveFromArchive fontSize="small" />}
              label={gettext('Remove list content from archive queue')}
            />
          )}
          {canRestoreArchivedAssets && (
            <MenuItem
              disabled={favoritesListEmpty || !contentCanBeRestored || isSelectedListAssetsLoading}
              tooltip={restoreListContentFromArchiveTitle()}
              onClick={() => {
                dispatch(archiveActions.restoreArchivedAssets(favoriteItemAssets));
              }}
              icon={<Restore fontSize="small" />}
              label={gettext('Restore list content from archive')}
            />
          )}
          {selectedFavoritesListXMLDownloadURL && (
            <MenuItem
              disabled={!selectedProduction || favoritesListEmpty || isSelectedListAssetsLoading}
              tooltip={downloadFavoritesListFileTitle()}
              onClick={downloadFavoritesListFile}
              icon={<DownloadFavorites fontSize="small" />}
              label={gettext('Download favorites list file')}
            />
          )}
          <SiteExportChoices baseXMLFileName={selectedList.name} />
          {rceExportURL && (
            <MenuItem
              disabled={favoritesListEmpty || isSelectedListAssetsLoading}
              tooltip={openInRoughCutEditorTitle()}
              href={rceExportURL}
              icon={<RoughCutEditor fontSize="small" />}
              label={gettext('Open in Rough Cut Editor')}
            />
          )}
          {canDownloadProxy && (
            <>
              <MenuItem
                label={gettext('Download proxy...')}
                onClick={() => {
                  setProxyDownloadModalVisible(true);
                }}
                tooltip={getDownloadProxiesTooltip()}
                icon={<Download fontSize="small" />}
                disabled={
                  isSelectedListAssetsLoading ||
                  assetChoices.length === 0 ||
                  !isProxyDownloadActionMenuDryRunAllowed
                }
              />
              <BulkDownloadModal
                id="download-favlist-proxy-files"
                assetChoices={assetChoices}
                downloadActivitiesURL={proxyDownloadActivitiesURL}
                open={isProxyDownloadModalVisible}
                onClose={() => {
                  setProxyDownloadModalVisible(false);
                }}
                headerTitle={pgettext('Title-style capitalization', 'Download Asset Proxy Files')}
                submitButtonTitle={pgettext('Form submit button title', 'Download proxy')}
              />
            </>
          )}
          {canDownloadHighres && (
            <>
              <MenuItem
                label={gettext('Download highres...')}
                onClick={() => {
                  setHighresDownloadModalVisible(true);
                }}
                tooltip={getDownloadHighresTooltip()}
                icon={<Download fontSize="small" />}
                disabled={
                  isSelectedListAssetsLoading ||
                  assetChoices.length === 0 ||
                  !isHighresDownloadActionMenuDryRunAllowed
                }
              />
              <BulkDownloadModal
                id="download-favlist-highres"
                assetChoices={assetChoices}
                downloadActivitiesURL={highresDownloadActivitiesURL}
                open={isHighresDownloadModalVisible}
                onClose={() => {
                  setHighresDownloadModalVisible(false);
                }}
                headerTitle={pgettext('Title-style capitalization', 'Download Asset Highres Files')}
                submitButtonTitle={pgettext('Form submit button title', 'Download asset highres')}
              />
            </>
          )}
          <MenuItem
            disabled={false}
            tooltip={gettext('Clear list')}
            onClick={onClearListClicked}
            icon={<ClearList fontSize="small" />}
            label={gettext('Clear list...')}
          />
          <TransferActionButton />
        </div>
      )}
    </DropdownMenu>
  );
};

export default FavoritesActionMenu;
