import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTime } from 'luxon';
import { FocusEvent, useCallback, useLayoutEffect, useRef, useState, useEffect } from 'react';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { DateRangePicker, IconButton, Input } from 'medialoopster/components';
import { ClearField } from 'medialoopster/icons';

export type SearchDateRangeProps = {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly name?: string;
  readonly label?: string;
  readonly onSubmit?: () => void;
  readonly showError?: boolean;
};

const SearchDateRange: React.FC<SearchDateRangeProps> = ({
  value,
  onChange,
  name = '',
  onSubmit = () => {},
  label = '',
  showError = false,
}: SearchDateRangeProps) => {
  const dateFormat = pgettext('Localized numeric date', 'MM/dd/yyyy');
  const timeFormat = 'HH:mm';
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [cursorPos, setCursorPos] = useState(null);
  const [dateRange, setDateRange] = useState<{ startDate?: DateTime; endDate?: DateTime }>({});
  const [isExternalCalendarChange, setIsExternalCalendarChange] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onUserSubmit = useCallback(
    (nextDateRange) => {
      onChange(
        `${nextDateRange.startDate.toFormat(dateTimeFormat)} - ${nextDateRange.endDate.toFormat(
          dateTimeFormat,
        )}`,
      );
      setDateRange(nextDateRange);
      onSubmit();
      setAnchorEl(null);
    },
    [onChange, setDateRange, onSubmit, setAnchorEl, dateTimeFormat],
  );

  const onUserChange = useCallback(
    (event, lastValue) => {
      const newDateStr = event.target.value;
      setCursorPos(event.target.selectionStart);
      const [lastStrDateStart = '', lastStrDateEnd = ''] = lastValue.split(' - ');
      const [strDateStart = '', strDateEnd = ''] = newDateStr.split(' - ');
      const startDate = DateTime.fromFormat(strDateStart, dateTimeFormat);
      const endDate = DateTime.fromFormat(strDateEnd, dateTimeFormat);
      if (startDate.isValid && endDate.isValid) {
        // Both dates are valid inputs according to parsed format
        let newRange = { startDate, endDate };
        // But date ranges migth not conform to rule "startDate before endDate" -> so handle both changes
        if (lastStrDateStart !== strDateStart) {
          // User modified date start string
          if (startDate > endDate) {
            // New start date is after end date -> move end date along
            newRange = { startDate, endDate: startDate };
            onChange(`${strDateStart} - ${newRange.endDate.toFormat(dateTimeFormat)}`);
          } else {
            // New date string is before last dateStart
            newRange = { startDate, endDate };
            onChange(`${strDateStart} - ${strDateEnd}`);
          }
        }
        if (lastStrDateEnd !== strDateEnd) {
          // User modified date end string
          if (endDate < startDate) {
            // New end date is before start date -> move start date along
            newRange = { startDate: endDate, endDate };
            onChange(`${newRange.startDate.toFormat(dateTimeFormat)} - ${strDateEnd}`);
          } else {
            // New date string is after last dateEnd
            newRange = { startDate, endDate };
            onChange(`${strDateStart} - ${strDateEnd}`);
          }
        }
        setDateRange(newRange);
      } else {
        // Invalid format
        onChange(newDateStr);
      }
    },
    [setCursorPos, setDateRange, onChange, dateTimeFormat],
  );

  const onCalendarChange = useCallback(
    (range) => {
      setDateRange(range);
      onChange(
        `${range.startDate.toFormat(dateTimeFormat)} - ${range.endDate.toFormat(dateTimeFormat)}`,
      );
    },
    [setDateRange, onChange, dateTimeFormat],
  );

  const reset = useCallback(() => {
    onChange('');
    setAnchorEl(null);
    onSubmit();
  }, [onChange, setAnchorEl, onSubmit]);

  useEffect(() => {
    // React to external resets
    if (value === '') {
      setDateRange({});
    }
  }, [value, setDateRange]);

  const handleClickAway = useCallback(
    (e) => {
      if (
        inputRef.current?.id !== e.target.id ||
        (e.relatedTarget?.closest && e.relatedTarget?.closest(`#picker${name}`) === null)
      ) {
        setAnchorEl(null);
        setIsExternalCalendarChange(false);
      }
    },
    [setAnchorEl, setIsExternalCalendarChange, name],
  );

  useLayoutEffect(() => {
    if (inputRef && inputRef?.current && inputRef?.current?.id === document?.activeElement?.id) {
      inputRef.current.selectionStart = cursorPos;
      inputRef.current.selectionEnd = cursorPos;
    }
  }, [cursorPos]);

  return (
    <>
      <Input
        id={name}
        label={label}
        showError={showError}
        name={name}
        ref={ref}
        inputRef={inputRef}
        value={value}
        onChange={(_newValue, e) => {
          onUserChange(e, value);
        }}
        onFocus={() => {
          setAnchorEl(ref.current);
          setIsExternalCalendarChange(true);
        }}
        onBlur={(e: FocusEvent) => {
          handleClickAway(e);
          setIsExternalCalendarChange(false);
        }}
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              {(value.length > 0 || showError) && (
                <IconButton aria-label={gettext('Reset input')} onClick={reset}>
                  <ClearField />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <DateRangePicker
          open={open}
          anchorEl={ref.current}
          placement={
            (ref?.current?.offsetLeft || 0) > window.innerWidth / 2 ? 'bottom-end' : 'bottom-start'
          }
          popperId={`picker${name}`}
          onChange={onCalendarChange}
          rangeStart={dateRange.startDate}
          rangeEnd={dateRange.endDate}
          onSubmit={onUserSubmit}
          onReset={reset}
          isExternalChange={isExternalCalendarChange}
        />
      </ClickAwayListener>
    </>
  );
};

export default SearchDateRange;
