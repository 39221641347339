import { useObservable, useObservableState } from 'observable-hooks';
import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import { isAPIFieldError, RESTError } from 'medialoopster/rest';
import { getTokenAuthHeader, loginTypes } from 'medialoopster/state/login';

import { AssetChoice } from '../../../../../state/modules/operations/types';

export interface CheckBulkDownloadState {
  readonly hasPermission: boolean;
  readonly isAuthorized?: boolean;
}

const checkDownloadActive = () => {
  const prepare = (
    input$: Observable<
      [loginTypes.Token, boolean, boolean, string | null, ReadonlyArray<AssetChoice>]
    >,
  ): Observable<CheckBulkDownloadState> =>
    input$.pipe(
      switchMap(([token, isVisible, isLoading, downloadActivitiesURL, assetChoices]) => {
        if (!isVisible || isLoading || !downloadActivitiesURL || assetChoices.length === 0) {
          return of({ hasPermission: false, isAuthorized: true });
        }
        const downloadActivitiesURLURL = new URL(downloadActivitiesURL);
        downloadActivitiesURLURL.searchParams.append('dry_run', 'true');
        return ajax
          .post(
            downloadActivitiesURLURL.href,
            {
              _links: {
                // TODO: use `collection` key for the Collection (ML-3685)
                assets: assetChoices.map(({ url }) => ({ href: url })),
              },
            },
            {
              ...getTokenAuthHeader(token),
              Accept: 'application/hal+json; version=3',
              'Content-Type': 'application/hal+json; version=3',
            },
          )
          .pipe(
            mergeMap(() => {
              return of({ hasPermission: true, isAuthorized: true });
            }),
            catchError((err) => {
              if (err && err.status === 401) {
                return of({ hasPermission: false, isAuthorized: false }); // TODO: Test in ML-3735
              }
              const { response }: RESTError = err;
              if (!response || !response.errors) {
                return of({ hasPermission: false, isAuthorized: true });
              }
              const assetErrors = response.errors.filter(
                (error) =>
                  isAPIFieldError(error) && error.source.pointer.startsWith('/_links/assets/'),
              );
              // This is a hack, in order to allow visibility of menu entries when the collection is offline.
              // In the caller function the offline status will be checked again and set the menu entry as disabled
              // the hack would disappear when ML-3678 is implemented
              if (
                assetChoices.length === 1 &&
                assetErrors.length === 1 &&
                assetChoices[0].typeName === 'collection' &&
                assetErrors[0].code === 'not_at_site'
              ) {
                return of({ hasPermission: true, isAuthorized: true });
              }
              return of({
                hasPermission: assetErrors.length < assetChoices.length,
                isAuthorized: true,
              });
            }),
          );
      }),
    );
  return prepare;
};

/**
 * Do an API dry run check to check the download permissions for each asset in a bulk download or for a collection.
 * The check is possible for both proxy and highres downloads.
 *
 * TODO: Permissions to download Collections should be checked in the future via the `download-highres` Link
 * and not via this dry run API call (ML-3678)
 *
 * @param {string} token - The token necessary to authenticate.
 * @param {boolean} isVisible - If the download action asset menu entry should be visible at all, from constraints of asset and files count.
 * @param {boolean} isLoading - If the collection is still loading (if a collection is being downloaded).
 * @param {string | null} downloadActivitiesURL - The URL that shows proxy/highres downloads are allowed in the current API.
 * @param {Array} assetChoices - Array of dictionary of asset multiple fields (either array of 1 element representing the collection, or array of the selected media assets).
 * @returns {Observable} - Weather the download is allowed, in that not all assets have download error and authorization status.
 */
const useCheckBulkDownload = (
  token: loginTypes.Token,
  isVisible: boolean,
  isLoading: boolean,
  downloadActivitiesURL: string | null,
  assetChoices: ReadonlyArray<AssetChoice>,
): CheckBulkDownloadState => {
  return useObservableState(
    useObservable<
      CheckBulkDownloadState,
      [loginTypes.Token, boolean, boolean, string | null, ReadonlyArray<AssetChoice>]
    >(checkDownloadActive(), [token, isVisible, isLoading, downloadActivitiesURL, assetChoices]),
    { hasPermission: false, isAuthorized: true },
  );
};

export default useCheckBulkDownload;
