import * as personsActions from './actions';
import * as personsConstants from './constants';
import personsEpics from './epics';
import reducer from './reducers';
import * as personsSelectors from './selectors';
import * as personsTypes from './types';

export { personsActions, personsConstants, personsEpics, personsSelectors, personsTypes };

export default reducer;
