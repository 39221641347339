import { DateTime } from 'luxon';
import { combineReducers } from 'redux';

import { URL_PERMISSION_SHARE_PRODUCTION_ASSET } from '../../constants';
import { Asset } from '../../types/asset/unionTypes';
import { UPDATE_PERMISSIONS, UpdatePermissions } from '../favorites/types';
import {
  AssetSharingPermissionsState,
  CLOSE_SHARE_ASSETS_MODAL,
  CloseShareAssetsModal,
  DESELECT_PRODUCTION,
  DeselectProduction,
  OPEN_SHARE_ASSETS_MODAL,
  OpenShareAssetsModal,
  SELECT_PRODUCTION,
  SET_DATE_WITHDRAW,
  SET_SHARE_ASSETS_ERRORS,
  SHARE_ASSETS_OPTIONS_LOADED,
  SelectProduction,
  SetDateWithdraw,
  SetShareAssetsErrors,
  ShareAssetsModalOptionsState,
  ShareAssetsModalState,
  ShareAssetsOptionsLoaded,
} from './types';

export const initialAssetSharingPermissionState: AssetSharingPermissionsState = {
  canShareProductionAsset: false,
};

export const initialShareAssetsOptionsState: ShareAssetsModalOptionsState = {
  actions: {},
};

export const shareAssetsOptionsReducer = (
  state = initialShareAssetsOptionsState,
  { type, payload }: ShareAssetsOptionsLoaded,
): ShareAssetsModalOptionsState => {
  switch (type) {
    case SHARE_ASSETS_OPTIONS_LOADED:
      return payload;
    default:
      return state;
  }
};

const getDefaultDateWithdraw = (assets: ReadonlyArray<Asset>) => {
  const assetsWithDeletionDate = assets.filter((asset) => asset.date_del !== null);
  if (assetsWithDeletionDate.length === 0) {
    return null;
  }

  return DateTime.max(
    ...assetsWithDeletionDate.map((asset) => DateTime.fromISO(asset.date_del as string)),
  );
};

export const initialShareAssetsModalState: ShareAssetsModalState = {
  visible: false,
  values: {
    productionIds: [],
    dateWithdraw: null,
    assets: [],
  },
  errors: {
    nonField: [],
    productionIds: [],
    dateWithdraw: [],
    assets: [],
  },
};

type ShareAssetsModalReducerActions =
  | OpenShareAssetsModal
  | CloseShareAssetsModal
  | SetShareAssetsErrors
  | SelectProduction
  | DeselectProduction
  | SetDateWithdraw;
export const shareAssetsModalReducer = (
  state = initialShareAssetsModalState,
  action: ShareAssetsModalReducerActions,
): ShareAssetsModalState => {
  switch (action.type) {
    case OPEN_SHARE_ASSETS_MODAL:
      return {
        ...initialShareAssetsModalState,
        values: {
          ...initialShareAssetsModalState.values,
          assets: action.payload.assets,
          dateWithdraw: getDefaultDateWithdraw(action.payload.assets),
        },
        visible: true,
      };
    case CLOSE_SHARE_ASSETS_MODAL:
      return initialShareAssetsModalState;
    case SET_SHARE_ASSETS_ERRORS:
      return {
        ...state,
        errors: {
          ...initialShareAssetsModalState.errors,
          ...action.payload.errors,
        },
      };
    case SELECT_PRODUCTION: {
      const productionIds = state.values.productionIds || [];
      if (productionIds.indexOf(action.payload.productionId) >= 0) {
        return state;
      }
      return {
        ...state,
        values: {
          ...state.values,
          productionIds: [...productionIds, action.payload.productionId],
        },
      };
    }
    case DESELECT_PRODUCTION: {
      const productionIds = state.values.productionIds || [];
      return {
        ...state,
        values: {
          ...state.values,
          productionIds: productionIds.filter(
            (productionId) => productionId !== action.payload.productionId,
          ),
        },
      };
    }
    case SET_DATE_WITHDRAW:
      return {
        ...state,
        values: {
          ...state.values,
          dateWithdraw: DateTime.fromISO(action.payload.dateWithdraw),
        },
      };
    default:
      return state;
  }
};

export const assetSharingPermissionReducer = (
  state = initialAssetSharingPermissionState,
  { type, payload }: UpdatePermissions,
): AssetSharingPermissionsState => {
  switch (type) {
    case UPDATE_PERMISSIONS:
      if (payload.url === URL_PERMISSION_SHARE_PRODUCTION_ASSET) {
        return {
          ...state,
          canShareProductionAsset: payload.hasPermission,
        };
      }
      return state;
    default:
      return state;
  }
};
export default combineReducers({
  options: shareAssetsOptionsReducer,
  modal: shareAssetsModalReducer,
  permissions: assetSharingPermissionReducer,
});
