import { ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, ngettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Transfer } from 'medialoopster/icons';

import { detailsSelectors } from '../../../../state/modules/details';
import { convertAssetToAssetChoice } from '../../../../state/modules/operations/selectors';
import { AssetChoice, CollectionChoice } from '../../../../state/modules/operations/types';
import { isAssetCollection } from '../../../../state/modules/rest/collection/types';
import { transferActions, transferSelectors } from '../../../../state/modules/transfer';
import { Asset } from '../../../../state/types/asset/unionTypes';
import { RootState } from '../../../../state/types/rootState';

type Props = {
  readonly assetChoices: ReadonlyArray<AssetChoice>;
  readonly collectionChoice: CollectionChoice | null;
  readonly isEmpty: boolean;
  readonly isLoading: boolean;
};

const TransferActionMenuEntry = ({
  assetChoices,
  collectionChoice,
  isLoading = false,
  isEmpty = false,
}: Props) => {
  const dispatch = useDispatch();
  const hasTransferTargetDevice = useSelector((state: RootState) =>
    transferSelectors.hasTransferTargetDevice(state, assetChoices),
  );
  const isNotInProduction = useSelector((state: RootState) =>
    transferSelectors.isNotInProduction(state, assetChoices),
  );

  const getLabel = () => {
    return pgettext('Menu entry', 'Transfer...');
  };

  const getTooltip = () => {
    if (!hasTransferTargetDevice) {
      return gettext('There are no devices to transfer to');
    }
    if (collectionChoice) {
      if (!collectionChoice.isProduction) {
        return gettext('Collection is not in production');
      }
      if (isEmpty) return gettext('Collection is empty');
      if (isLoading) return gettext('Collection is loading');
      return gettext('Transfer collection');
    }
    if (isNotInProduction) {
      return ngettext(
        'Cannot transfer the asset because the highres is not available',
        'Cannot transfer the assets because the highres is not available',
        assetChoices.length,
      );
    }
    return ngettext(
      'Transfer the selected asset to an external device',
      'Transfer the selected assets to an external device',
      assetChoices.length,
    );
  };

  const isDisabled = () => {
    if (collectionChoice) {
      return !collectionChoice.isProduction || !hasTransferTargetDevice || isEmpty || isLoading;
    }
    return !hasTransferTargetDevice || isNotInProduction;
  };
  return (
    <MenuItem
      label={getLabel()}
      onClick={() => {
        dispatch(transferActions.openBulkTransferModal(assetChoices, collectionChoice));
      }}
      tooltip={getTooltip()}
      icon={<Transfer fontSize="small" />}
      disabled={isDisabled()}
    />
  );
};

const useTransferActionMenuEntry = (
  currentAsset: Asset | null,
  assetChoices: ReadonlyArray<AssetChoice>,
): ReadonlyArray<ReactElement> => {
  const transferrableSelection = useMemo(
    // Collection api transfers collection with its assets.
    () =>
      isAssetCollection(currentAsset) ? [convertAssetToAssetChoice(currentAsset)] : assetChoices,
    [currentAsset, assetChoices],
  );
  const isCollectionLoading = useSelector(detailsSelectors.isCurrentCollectionLoading);
  const isEmtpyCollection = useSelector(detailsSelectors.isCurrentCollectionEmpty);
  const hasCollectionFiles = useSelector(detailsSelectors.getCurrentCollectionHasFiles);

  const canTransferSelection = useSelector((state: RootState) =>
    transferSelectors.canTransferSelection(state, transferrableSelection),
  );
  const collectionChoice = useMemo(
    () =>
      isAssetCollection(currentAsset)
        ? {
            ...convertAssetToAssetChoice(currentAsset),
            hasFiles: hasCollectionFiles,
          }
        : null,
    [currentAsset, hasCollectionFiles],
  );

  if (!canTransferSelection || (!collectionChoice ? assetChoices.length === 0 : false)) {
    return [];
  }
  return [
    <TransferActionMenuEntry
      key="transfer"
      assetChoices={assetChoices}
      collectionChoice={collectionChoice}
      isLoading={!!collectionChoice && isCollectionLoading}
      isEmpty={!!collectionChoice && isEmtpyCollection}
    />,
  ];
};

export default useTransferActionMenuEntry;
