export const SET_INITIAL_FRAME = '/FRONTEND/PLAYER/SET_INITIAL_FRAME';

export interface SetInitialFrame {
  readonly type: typeof SET_INITIAL_FRAME;
  readonly payload: {
    readonly frame: number;
  };
}

export const SET_IN_FRAME = '/FRONTEND/PLAYER/SET_IN_FRAME';
export interface SetInFrame {
  readonly type: typeof SET_IN_FRAME;
  readonly payload: {
    readonly frame: number | null;
  };
}

export const SET_OUT_FRAME = '/FRONTEND/PLAYER/SET_OUT_FRAME';
export interface SetOutFrame {
  readonly type: typeof SET_OUT_FRAME;
  readonly payload: {
    readonly frame: number | null;
  };
}

export interface PlayerState {
  readonly initialFrame: number;
  readonly inFrame: number | null;
  readonly outFrame: number | null;
}
