import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { AudioAsset } from '../../../../state/modules/audio/types';
import { favoritesSelectors } from '../../../../state/modules/favorites';
import { ImageAsset } from '../../../../state/modules/image/types';
import { VideoAsset } from '../../../../state/modules/video/types';
import AddAssetsToCollectionMenu from '../../../Menus/AddAssetsToCollectionMenu';

interface Props {
  assets: ReadonlyArray<VideoAsset | ImageAsset | AudioAsset>;
}

const AddFavoriteAssetsToCollectionMenu: FC<Props> = ({ assets }: Props) => {
  const isSelectedListAssetsLoading = useSelector(favoritesSelectors.isSelectedListAssetsLoading);
  const favoritesListEmpty = useSelector(favoritesSelectors.isSelectedFavoritesListEmpty);
  const addableAssets = useMemo(
    () =>
      assets.map((asset) => ({
        display: asset.name,
        value: getResourceURI(asset),
        type: getResourceTypeName(asset),
      })),
    [assets],
  );
  return (
    <AddAssetsToCollectionMenu
      isLoading={isSelectedListAssetsLoading}
      isLoadingMsg={gettext('Assets are being loaded')}
      isEmpty={favoritesListEmpty}
      isEmptyMsg={gettext('This favorite item list has no content to add to a collection')}
      infoMsg={gettext(
        'Add media assets to a collection from the content of this favorite item list',
      )}
      label={gettext('Add assets to collection from list content...')}
      assets={addableAssets}
    />
  );
};

export default AddFavoriteAssetsToCollectionMenu;
