import { useSelector } from 'react-redux';

import { pgettext } from 'medialoopster/Internationalization';

import { getUserByURI } from '../../../../../../state/modules/users/selectors';

const unknownUser = {
  firstName: pgettext('Firstname for unknown user', 'Unknown'),
  lastName: pgettext('Lastname for unknown user', 'User'),
};

/**
 * Get the first and last username of a user by its resource URI.
 *
 * The username is read from the users in the store. If there is no user with the given URI in
 * the store then 'Unknown User' will be returned as username. Note that this may happen for a
 * lot of users if the currently logged-in user has no permissions to read user data.
 *
 * @param userURI The resource URI of the user.
 * @returns The first and last name of the user.
 */
export const useUserName = (userURI?: string): { firstName: string; lastName: string } => {
  const user = useSelector((state) => getUserByURI(state, userURI));
  return user ? { firstName: user.first_name, lastName: user.last_name } : unknownUser;
};

export default useUserName;
