import { Grid, NativeSelect, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { Time } from 'medialoopster/icons';

import { gettext } from '../../../Internationalization';

const makeDoubleDigit = (x: number): string => (x < 10 ? `0${String(x)}` : String(x));
const HOURS = Array.from(Array(24).keys()).map(makeDoubleDigit);
const MINUTES = Array.from(Array(60).keys()).map(makeDoubleDigit);

type SelectChange = ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const getSelectHours = (date: DateTime | undefined): string =>
  date ? String(makeDoubleDigit(date.hour)) : '00';
const getSelectMinutes = (date: DateTime | undefined): string =>
  date ? String(makeDoubleDigit(date.minute)) : '00';
interface TimePickerProps {
  date?: DateTime;
  onTimeChange: (val: DateTime) => void;
  ariaLabelHours?: string;
  ariaLabelMinutes?: string;
}
const TimePicker: FC<TimePickerProps> = ({ onTimeChange, date }: TimePickerProps) => {
  const [hours, setSelectedHours] = useState(getSelectHours(date));
  const [minutes, setSelectedMinutes] = useState(getSelectMinutes(date));

  useEffect(() => {
    setSelectedHours(getSelectHours(date));
    setSelectedMinutes(getSelectMinutes(date));
  }, [date]);

  const handleHourChange = ({ target: { value } }: SelectChange) => {
    setSelectedHours(String(value));
    if (typeof value === 'string' && date) {
      const updatedDate = date.set({ hour: +value });
      onTimeChange(updatedDate);
    }
  };
  const handleMinuteChange = ({ target: { value } }: SelectChange) => {
    setSelectedMinutes(String(value));
    if (typeof value === 'string' && date) {
      const updatedDate = date.set({ minute: +value });
      onTimeChange(updatedDate);
    }
  };
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item style={{ display: 'flex' }}>
        <Time style={{ alignSelf: 'center' }} />
      </Grid>
      <Grid item>
        <NativeSelect
          name="picker-hours"
          value={hours}
          onChange={handleHourChange}
          inputProps={{
            'aria-label': gettext('Select hour'),
          }}
        >
          {HOURS.map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </NativeSelect>
      </Grid>
      <Grid item>
        <Typography variant="button">:</Typography>
      </Grid>
      <Grid item>
        <NativeSelect
          name="picker-minutes"
          value={minutes}
          onChange={handleMinuteChange}
          inputProps={{
            'aria-label': gettext('Select minute'),
          }}
        >
          {MINUTES.map((minute) => (
            <option key={minute} value={minute}>
              {minute}
            </option>
          ))}
        </NativeSelect>
      </Grid>
    </Grid>
  );
};

export default TimePicker;
