import { gettext } from 'medialoopster/Internationalization';

export const LANGUAGES = {
  af: gettext('Afrikaans'),
  ar: gettext('Arabic'),
  ast: gettext('Asturian'),
  az: gettext('Azerbaijani'),
  bg: gettext('Bulgarian'),
  be: gettext('Belarusian'),
  bn: gettext('Bengali'),
  br: gettext('Breton'),
  bs: gettext('Bosnian'),
  ca: gettext('Catalan'),
  cs: gettext('Czech'),
  cy: gettext('Welsh'),
  da: gettext('Danish'),
  de: gettext('German'),
  dsb: gettext('Lower Sorbian'),
  el: gettext('Greek'),
  en: gettext('English'),
  'en-au': gettext('Australian English'),
  'en-gb': gettext('British English'),
  eo: gettext('Esperanto'),
  es: gettext('Spanish'),
  'es-ar': gettext('Argentinian Spanish'),
  'es-co': gettext('Colombian Spanish'),
  'es-mx': gettext('Mexican Spanish'),
  'es-ni': gettext('Nicaraguan Spanish'),
  'es-ve': gettext('Venezuelan Spanish'),
  et: gettext('Estonian'),
  eu: gettext('Basque'),
  fa: gettext('Persian'),
  fi: gettext('Finnish'),
  fr: gettext('French'),
  fy: gettext('Frisian'),
  ga: gettext('Irish'),
  gd: gettext('Scottish Gaelic'),
  gl: gettext('Galician'),
  he: gettext('Hebrew'),
  hi: gettext('Hindi'),
  hr: gettext('Croatian'),
  hsb: gettext('Upper Sorbian'),
  hu: gettext('Hungarian'),
  ia: gettext('Interlingua'),
  id: gettext('Indonesian'),
  io: gettext('Ido'),
  is: gettext('Icelandic'),
  it: gettext('Italian'),
  ja: gettext('Japanese'),
  ka: gettext('Georgian'),
  kk: gettext('Kazakh'),
  km: gettext('Khmer'),
  kn: gettext('Kannada'),
  ko: gettext('Korean'),
  lb: gettext('Luxembourgish'),
  lt: gettext('Lithuanian'),
  lv: gettext('Latvian'),
  mk: gettext('Macedonian'),
  ml: gettext('Malayalam'),
  mn: gettext('Mongolian'),
  mr: gettext('Marathi'),
  my: gettext('Burmese'),
  nb: gettext('Norwegian Bokmål'),
  ne: gettext('Nepali'),
  nl: gettext('Dutch'),
  nn: gettext('Norwegian Nynorsk'),
  os: gettext('Ossetic'),
  pa: gettext('Punjabi'),
  pl: gettext('Polish'),
  pt: gettext('Portuguese'),
  'pt-br': gettext('Brazilian Portuguese'),
  ro: gettext('Romanian'),
  ru: gettext('Russian'),
  sk: gettext('Slovak'),
  sl: gettext('Slovenian'),
  sq: gettext('Albanian'),
  sr: gettext('Serbian'),
  'sr-latn': gettext('Serbian Latin'),
  sv: gettext('Swedish'),
  sw: gettext('Swahili'),
  ta: gettext('Tamil'),
  te: gettext('Telugu'),
  th: gettext('Thai'),
  tr: gettext('Turkish'),
  tt: gettext('Tatar'),
  udm: gettext('Udmurt'),
  uk: gettext('Ukrainian'),
  ur: gettext('Urdu'),
  vi: gettext('Vietnamese'),
  'zh-hans': gettext('Simplified Chinese'),
  'zh-hant': gettext('Traditional Chinese'),
};

export default {};
