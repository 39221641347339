import { ReactNode, isValidElement } from 'react';

import { textWithHighlightedRegions } from '../../../ui/services/highlighting';

const createMultiElementHighlighter = (
  highlights?: ReadonlyArray<string>,
): ((text: string) => Readonly<ReactNode>) => {
  const mutableHighlights = highlights ? [...highlights] : [];
  return (text: string) => {
    const result = textWithHighlightedRegions(text, mutableHighlights);
    const elements: ReadonlyArray<ReactNode> = Array.isArray(result) ? result : [result];
    elements.forEach((element) => {
      if (isValidElement(element) && element.type === 'mark') {
        mutableHighlights.splice(mutableHighlights.indexOf(element.props.children), 1);
      }
    });
    return result;
  };
};
export default createMultiElementHighlighter;
