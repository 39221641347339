import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import { FC } from 'react';

import { getResourceURI } from 'medialoopster/rest';

import { Annotation } from '../../../../../state/modules/annotation/types';
import AnnotationCard from './AnnotationCard';
import TimecodeLink from './TimecodeLink';

/**
 * Helper component to display a group of annotations with the same timecode.
 *
 * The annotations will be displayed sorted by their creation date.
 *
 * @param annotationGroup A group of annotations referencing the same frame.
 * @param frame The Frame that is referenced by all the annotations in the group.
 */
const AnnotationsListGroup: FC<{ annotationGroup: Annotation[]; frame: number }> = ({
  annotationGroup,
  frame,
}) => {
  const sortedAnnotations = _.sortBy(annotationGroup, 'date_add');

  return (
    <Stack width="100%" p="0.5rem" spacing="0.5rem">
      <TimecodeLink frame={frame} />
      {sortedAnnotations.map((annotation) => (
        <AnnotationCard key={getResourceURI(annotation)} annotation={annotation} />
      ))}
      <Divider />
    </Stack>
  );
};

interface AnnotationsListProps {
  annotations: readonly Annotation[];
}

/**
 * Internal Annotation Component responsible to layout list of annotations.
 *
 * The annotations are grouped by the frame they reference and ordered inside the group by
 * their creation date.
 *
 * @param annotations The annotations to display.
 */
const AnnotationsList: FC<AnnotationsListProps> = ({ annotations }) => {
  const groupedAnnotations = _.groupBy(annotations, 'time');

  return (
    <Stack p="0.5rem">
      {Object.entries(groupedAnnotations).map(([time, annotationGroup]) => (
        <AnnotationsListGroup
          key={time}
          annotationGroup={annotationGroup}
          frame={Number.parseInt(time, 10)}
        />
      ))}
    </Stack>
  );
};

export default AnnotationsList;
