/**
 * The module implements a component to display the speaker name in MetadataDisplay
 */
import { Stack } from '@mui/material';
import { NodeKey } from 'lexical';
import React, { useCallback, useState } from 'react';

import { RecordVoiceOver } from 'medialoopster/icons';

import { ChangeSpeakerModal } from '../ChangeSpeakerModal';
import { useTranscriptEditorContext } from '../TranscriptEditorContext';

/**
 * Props for the Speaker component
 */
export interface SpeakerProps {
  /**
   * The speaker name
   */
  name: string;
  /**
   * The NodeKey of the TranscriptTimeBlockNode managing the speaker metadata.
   */
  timeBlockNodeKey: NodeKey;
}

/**
 * A component to display the Speaker name in the MetadataDisplay component.
 *
 * When clicked, The ChangeSpeakerModal is opened.
 *
 * @param name The speakers name
 * @param timeBlockNodeKey The NodeKey of the TranscriptTimeBlockNode managing the speaker.
 * @constructor
 */
export const Speaker: React.FC<SpeakerProps> = ({ name, timeBlockNodeKey }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isEditable } = useTranscriptEditorContext();

  const onModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const onClick = useCallback(() => {
    if (isEditable) {
      setModalOpen(true);
    }
  }, [isEditable, setModalOpen]);

  return (
    <>
      <Stack direction="row" marginRight="1em" onClick={onClick}>
        <RecordVoiceOver sx={{ marginRight: '0.5rem' }} />
        {name}
      </Stack>
      {modalOpen ? (
        <ChangeSpeakerModal
          onClose={onModalClose}
          speakerName={name}
          timeBlockNodeKey={timeBlockNodeKey}
        />
      ) : null}
    </>
  );
};

export default Speaker;
