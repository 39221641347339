import {
  SET_IN_FRAME,
  SET_INITIAL_FRAME,
  SET_OUT_FRAME,
  SetInFrame,
  SetInitialFrame,
  SetOutFrame,
} from './types';

export const setInitialFrame = (frame: number): SetInitialFrame => ({
  type: SET_INITIAL_FRAME,
  payload: {
    frame,
  },
});

export const setInFrame = (frame: number | null): SetInFrame => ({
  type: SET_IN_FRAME,
  payload: {
    frame,
  },
});

export const setOutFrame = (frame: number | null): SetOutFrame => ({
  type: SET_OUT_FRAME,
  payload: {
    frame,
  },
});
