import { formatUserDisplayName } from 'medialoopster';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, interpolate } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Edit, EditOff } from 'medialoopster/icons';
import { getResourceTypeName, getResourceURI } from 'medialoopster/rest';

import { bulkEditActions, bulkEditSelectors } from '../../../../state/modules/bulkEdit';
import { detailsActions, detailsSelectors } from '../../../../state/modules/details';
import { usersSelectors } from '../../../../state/modules/users';
import { User } from '../../../../state/modules/users/types';
import { Asset } from '../../../../state/types/asset/unionTypes';
import BulkEditModal from './BulkEditModal';

type EditActionMenuEntryProps = {
  readonly currentAsset: Asset;
  readonly currentUser: User;
  readonly isCurrentAssetLocked: boolean;
};

const EditActionMenuEntry = ({
  currentAsset,
  currentUser,
  isCurrentAssetLocked,
}: EditActionMenuEntryProps) => {
  const dispatch = useDispatch();
  const isCurrentAssetLockedByCurrentUser = useSelector(
    detailsSelectors.isCurrentAssetLockedByCurrentUser,
  );
  const currentAssetLockedBy = useSelector(detailsSelectors.getCurrentAssetLockedBy);
  const getLabel = () => {
    if (isCurrentAssetLockedByCurrentUser) {
      return gettext('Unlock');
    }
    if (isCurrentAssetLocked) {
      return currentAssetLockedBy
        ? interpolate(gettext('Asset is locked by %(locked_by_user)s'), {
            locked_by_user: formatUserDisplayName(currentAssetLockedBy),
          })
        : gettext('Asset is locked by another user');
    }
    return gettext('Edit');
  };
  const getTooltip = () => {
    if (isCurrentAssetLockedByCurrentUser) {
      return gettext('Unlock current asset');
    }
    if (isCurrentAssetLocked) {
      return currentAssetLockedBy
        ? interpolate(gettext('Asset is locked by %(locked_by_user)s'), {
            locked_by_user: formatUserDisplayName(currentAssetLockedBy),
          })
        : gettext('Asset is locked by another user');
    }
    return gettext('Lock current asset for editing');
  };
  return (
    <MenuItem
      disabled={isCurrentAssetLocked && !isCurrentAssetLockedByCurrentUser}
      label={getLabel()}
      tooltip={getTooltip()}
      icon={isCurrentAssetLocked ? <EditOff fontSize="small" /> : <Edit fontSize="small" />}
      onClick={() => {
        if (isCurrentAssetLocked) {
          dispatch(
            detailsActions.unlockCurrentAsset(
              getResourceTypeName(currentAsset),
              getResourceURI(currentAsset),
            ),
          );
        } else {
          dispatch(
            detailsActions.lockCurrentAsset(
              getResourceTypeName(currentAsset),
              getResourceURI(currentAsset),
              currentUser.id,
              getResourceURI(currentUser),
            ),
          );
        }
      }}
    />
  );
};

const BulkEditActionMenuEntry = () => {
  const dispatch = useDispatch();
  return (
    <>
      <MenuItem
        label={gettext('Edit')}
        tooltip={gettext('Edit selected assets')}
        icon={<Edit fontSize="small" />}
        onClick={() => {
          dispatch(bulkEditActions.openBulkEditModal());
        }}
      />
      <BulkEditModal />
    </>
  );
};

const useEditActionMenuEntry = (currentAsset: Asset | null): ReadonlyArray<ReactElement> => {
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const isCurrentAssetLocked = useSelector(detailsSelectors.isCurrentAssetLocked);
  const canLockCurrentAsset = useSelector(detailsSelectors.canLockCurrentAsset);
  const isBulkEdit = useSelector(bulkEditSelectors.isBulkEdit);
  if (isBulkEdit) {
    return [<BulkEditActionMenuEntry key="edit" />];
  }
  if (!currentUser || !currentAsset || (!canLockCurrentAsset && !isCurrentAssetLocked)) {
    return [];
  }
  return [
    <EditActionMenuEntry
      key="edit"
      currentAsset={currentAsset}
      currentUser={currentUser}
      isCurrentAssetLocked={isCurrentAssetLocked}
    />,
  ];
};

export default useEditActionMenuEntry;
