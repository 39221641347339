import { ReactNode } from 'react';

const replaceMarkdownLinks = (
  text: string,
  renderText: (text: string) => ReactNode | ReadonlyArray<ReactNode> = (x) => x,
): Readonly<ReactNode> => {
  const linkPattern = /\[(((?!\]).)*)\]\((((?!\)).)+)\)/g;
  let match;
  let prevIndex = 0;
  let matchCount = 0;
  const result: Array<ReactNode> = [];
  // eslint-disable-next-line no-cond-assign
  while ((match = linkPattern.exec(text)) !== null) {
    if (prevIndex < match.index) {
      // Add the text between previous match and this match.
      result.push(renderText(text.slice(prevIndex, match.index)));
    }
    prevIndex = match.index + match[0].length;
    // Add the link.
    let linkText = match[1];
    let linkHref = match[3];
    if (linkHref !== '' && linkText === '') {
      linkText = linkHref;
    }
    if (
      !linkHref.startsWith('/') &&
      !(
        linkHref.toLowerCase().startsWith('http://') ||
        linkHref.toLowerCase().startsWith('https://')
      )
    ) {
      linkHref = `https://${linkHref}`;
    }
    result.push(
      <a key={matchCount} href={linkHref} target="_blank" rel="noreferrer">
        {renderText(linkText)}
      </a>,
    );
    matchCount += 1;
  }
  if (prevIndex < text.length) {
    result.push(renderText(text.slice(prevIndex)));
  }
  return result;
};
export default replaceMarkdownLinks;
