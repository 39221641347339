import { useEffect } from 'react';
import { DragElementWrapper, DragSourceOptions, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import {
  buildDraggableFavoritesItem,
  DraggableFavoritesItemType,
} from '../../businessRules/models/FavoriteListsHelpers';
import { isImageAsset, isVideoAsset } from '../../businessRules/models/asset/utils';
import { detailsSelectors } from '../../state/modules/details';
import { playerSelectors } from '../../state/modules/player';

export default (): DragElementWrapper<DragSourceOptions> => {
  const currentAsset = useSelector(detailsSelectors.getCurrentAsset);
  const inFrame = useSelector(playerSelectors.getInFrame);
  const outFrame = useSelector(playerSelectors.getOutFrame);
  let type: DraggableFavoritesItemType;
  let buildItem;
  if (isVideoAsset(currentAsset)) {
    const isPseudoshot = inFrame !== null && outFrame !== null;
    type = isPseudoshot ? 'pseudoshot' : 'videoasset';
    buildItem = () =>
      buildDraggableFavoritesItem(
        currentAsset.id,
        type,
        currentAsset,
        currentAsset.thumbnail_url,
        isPseudoshot && inFrame !== null ? inFrame : undefined,
        isPseudoshot && outFrame !== null ? outFrame : undefined,
      );
  } else if (isImageAsset(currentAsset)) {
    type = 'imageasset';
    buildItem = () =>
      buildDraggableFavoritesItem(currentAsset.id, type, currentAsset, currentAsset.thumbnail_url);
  } else {
    type = 'videoasset';
    buildItem = () => undefined;
  }

  const [, drag, preview] = useDrag({
    type,
    item: buildItem,
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return drag;
};
