import { combineReducers } from 'redux';

import {
  ASSET_COLLECTION_CREATED_RESULT,
  AssetCollectionCreatedResult,
  CreateAssetCollectionModalRootState,
  CreatedAssetCollectionState,
} from './types';

export const initialCreatedAssetCollectionReducer: CreatedAssetCollectionState = {
  assetCollectionHref: undefined,
  errors: undefined,
};

export const createdAssetCollectionReducer = (
  state = initialCreatedAssetCollectionReducer,
  { type, payload }: AssetCollectionCreatedResult,
): CreatedAssetCollectionState => {
  switch (type) {
    case ASSET_COLLECTION_CREATED_RESULT:
      return {
        ...state,
        errors: payload.errors,
        assetCollectionHref: payload.assetCollectionHref,
      };
    default:
      return state;
  }
};

export default combineReducers<CreateAssetCollectionModalRootState>({
  createdAssetCollection: createdAssetCollectionReducer,
});
