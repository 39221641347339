/* eslint-disable import/prefer-default-export */
import lodash from 'lodash';
import { createSelector } from 'reselect';

import {
  createCollection,
  getCollectionTotalCount,
  getEmbeddedItems,
  getLink,
  getLinkedResource,
  getResourceURI,
} from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import { RootState } from '../../types/rootState';
import { collectionSelectors } from '../rest/collection';
import { AddableCollection, ModalState } from './types';

export const getCurrentAddableCollectionSearchURIResults = createSelector(
  (state: RootState) => state.addAssetsToCollection.modal.searchURI,
  (state: RootState) => state.rest.collectionsearchresult.pages,
  collectionSelectors.getAssetCollectionResourceMap,
  (currentAddableCollectionSearchURI, searchCollections, assetCollections) =>
    createCollection(currentAddableCollectionSearchURI, searchCollections, (page) =>
      getEmbeddedItems(page).flatMap((item) => {
        const asset = getLinkedResource(item, 'asset', assetCollections);
        if (!asset) {
          return [];
        }
        return asset;
      }),
    ),
);

export const getCurrentAddableCollections = createSelector(
  getCurrentAddableCollectionSearchURIResults,
  (addableCollections): ReadonlyArray<AddableCollection> =>
    addableCollections.items.map((el) => ({
      display: el.name,
      href: getResourceURI(el),
      addImageAssetsLink: getLink(el, 'add_image_assets'),
      addVideoAssetsLink: getLink(el, 'add_video_assets'),
      addAudioAssetsLink: getLink(el, 'add_audio_assets'),
    })),
);

const getAddableCollectionResponseStatus = (state: RootState): boolean | null =>
  state.addAssetsToCollection.modal.success;
const getAddableCollectionResponseErrors = (
  state: RootState,
): ReadonlyRecord<string, ReadonlyArray<string>> => state.addAssetsToCollection.modal.errors;

export const getApiFeedback = createSelector(
  getAddableCollectionResponseStatus,
  getAddableCollectionResponseErrors,
  (success, errors): Pick<ModalState, 'errors' | 'success'> => ({
    success,
    errors,
  }),
);

const writableProductionSearchCollectionCount = createSelector(
  (state: RootState) => state.addAssetsToCollection.menu.availableProductionSearchURIs,
  (state: RootState) => state.rest.collectionsearchresult.pages,
  (prods, pages) => {
    return Object.fromEntries(prods.map((p) => [p, getCollectionTotalCount(pages, p) || 0]));
  },
);

export const getWritableProductionSearchCollectionAvailability = createSelector(
  writableProductionSearchCollectionCount,
  (state: RootState) => state.addAssetsToCollection.menu.error,
  (prods, err): string | number => err ?? lodash.sum(lodash.values(prods)),
);
