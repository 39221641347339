import DialogActions from '@mui/material/DialogActions';
import { ReactNode, FC } from 'react';

import ModalActionButton from './ModalActionButton';
import ModalCancelButton from './ModalCancelButton';
import ModalSubmitButton from './ModalSubmitButton';

type Props = {
  children?: ReactNode;
};

type SubComponents = {
  CancelButton: typeof ModalCancelButton;
  SubmitButton: typeof ModalSubmitButton;
  ActionButton: typeof ModalActionButton;
};

const ModalFooter: FC<Props> & SubComponents = ({ children }: Props) => (
  <DialogActions>{children}</DialogActions>
);

ModalFooter.CancelButton = ModalCancelButton;
ModalFooter.SubmitButton = ModalSubmitButton;
ModalFooter.ActionButton = ModalActionButton;

export default ModalFooter;
