import { HttpHeaders } from './types';

export const getVersionHeaders = (version: number | undefined): HttpHeaders =>
  version !== undefined
    ? {
        Accept: `application/hal+json; version=${version}`,
        'Content-Type': `application/hal+json; version=${version}`,
      }
    : {};

export default getVersionHeaders;
