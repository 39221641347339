import { Avatar, ListItem, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC } from 'react';

import medialoopsterTheme from 'medialoopster/medialoopsterTheme';

import wrappedPersonNameLines from '../../../../businessRules/services/wrappedPersonNameLines';
import { PersonResource } from '../../../../state/modules/persons/types';

const useStyle = makeStyles<typeof medialoopsterTheme, { color: string }>(() => ({
  item: {
    padding: '4px',
  },
  content: {
    display: 'grid',
  },
  avatarContainer: {
    display: 'flex',
    cursor: 'pointer',
    margin: '3px auto',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',

      width: '100%',
      height: '100%',
      borderRadius: '50%',

      boxShadow: ({ color }) => `0 0 0 4px ${color}`,
      transition: 'transform 0.2s, opacity 0.2s',
      transform: 'scale(.8)',
      opacity: 0,
    },
    '&:hover:after, &$active:after': {
      transform: 'scale(.95)',
      opacity: 1,
    },
  },
  active: {}, // used above: &$active:after
  avatar: {
    zIndex: 1,
  },
  name: {
    fontSize: '0.6rem',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
}));

const MIN_NAME_LINE_LENGTH = 10;
const MAX_NAME_LINE_LENGTH = 20;

const isShortened = (nameLines: ReadonlyArray<string>) =>
  nameLines.some((line) => line.endsWith('…'));

type Props = {
  readonly person: PersonResource;
  readonly active?: boolean;
  readonly color?: string;
  readonly onClick?: () => void;
};

const PersonIcon: FC<Props> = ({
  person,
  active = false,
  color = '#ff0000',
  onClick = () => {},
}: Props) => {
  const classes = useStyle({ color });

  const nameLines = wrappedPersonNameLines(
    person.display_name,
    MIN_NAME_LINE_LENGTH,
    MAX_NAME_LINE_LENGTH,
  );
  return (
    <ListItem disableGutters className={classes.item} onClick={onClick}>
      <div className={classes.content}>
        <div
          className={clsx(classes.avatarContainer, {
            [classes.active]: active,
          })}
          data-testid="person-avatar-container"
        >
          <Avatar alt={person.display_name} src={person.thumbnail_url} className={classes.avatar} />
        </div>
        <Tooltip title={isShortened(nameLines) ? person.display_name : ''}>
          <div className={classes.name}>
            {nameLines[0]}
            {!!nameLines[1] && (
              <>
                <br />
                {nameLines[1]}
              </>
            )}
          </div>
        </Tooltip>
      </div>
    </ListItem>
  );
};

export default PersonIcon;
