import { ThemeProvider } from '@mui/material/styles';
import { ComponentType, FC } from 'react';
import { useSelector } from 'react-redux';

import medialoopsterTheme from 'medialoopster/medialoopsterTheme';

import { loginSelectors } from '../state/login';
import LogIn from './LogIn';

const withLogIn =
  (WrappedComponent: ComponentType): FC =>
  () => {
    const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
    return !isLoggedIn ? (
      <ThemeProvider theme={medialoopsterTheme}>
        <LogIn />
      </ThemeProvider>
    ) : (
      <WrappedComponent />
    );
  };
export default withLogIn;
