import Menu, { MenuProps } from '@mui/material/Menu';
import { FC } from 'react';

import MenuItem from './MenuItem';

export type Option = {
  readonly key: string | number;
  readonly onSelect: () => void;
  readonly selected?: boolean;
  readonly label: string;
  readonly icon?: JSX.Element;
};

type Props = {
  readonly id?: string;
  readonly anchorEl: Element | null;
  readonly setAnchorEl: (anchorEl: Element | null) => void;
  readonly options: ReadonlyArray<Option>;
  readonly anchorOrigin?: MenuProps['anchorOrigin'];
  readonly transformOrigin?: MenuProps['transformOrigin'];
};

const DropdownSelect: FC<Props> = ({
  id,
  anchorEl,
  setAnchorEl,
  options,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = undefined,
}: Props) => {
  const handleClose = () => setAnchorEl(null);
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {options.map((option) => (
        <MenuItem
          key={option.key}
          onClick={() => {
            option.onSelect();
            handleClose();
          }}
          selected={option.selected}
          label={option.label}
          icon={option.icon}
        />
      ))}
    </Menu>
  );
};

export default DropdownSelect;
