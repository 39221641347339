import { combineReducers } from 'redux';

import { createResourcesReducer, createRootLinkReducer, mlRel } from 'medialoopster/rest';

import { UsersRootState } from './types';

export default combineReducers<UsersRootState>({
  users: createResourcesReducer('user'),
  currentUserLink: createRootLinkReducer(mlRel('current-user')),
});
