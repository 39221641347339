import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, ngettext, pgettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { ShareAsset } from 'medialoopster/icons';

import { assetSharingSelectors } from '../../../../state/modules/assetSharing';
import { openShareAssetsModal } from '../../../../state/modules/assetSharing/actions';
import { Asset } from '../../../../state/types/asset/unionTypes';

type Props = {
  readonly hasNoTargetProduction: boolean;
  readonly assets: ReadonlyArray<Asset>;
};

const ShareAssetsActionMenuEntry = ({ hasNoTargetProduction, assets }: Props) => {
  const dispatch = useDispatch();
  const getToolTip = (hasNoTarget: boolean): string => {
    if (hasNoTarget) {
      return gettext('You cannot share assets, because no target productions are available.');
    }
    return ngettext(
      'Share the selected asset with other productions',
      'Share the selected assets with other productions',
      assets.length,
    );
  };
  return (
    <MenuItem
      label={pgettext('Menu entry', 'Share...')}
      tooltip={getToolTip(hasNoTargetProduction)}
      icon={<ShareAsset fontSize="small" />}
      disabled={hasNoTargetProduction}
      onClick={() => {
        dispatch(openShareAssetsModal(assets));
      }}
    />
  );
};

const useShareAssetsActionMenuEntry = (
  assets: ReadonlyArray<Asset>,
): ReadonlyArray<ReactElement> => {
  const canShareProductionAsset = useSelector(assetSharingSelectors.canShareProductionAsset);
  const hasNoTargetProduction = useSelector(assetSharingSelectors.hasNoTargetProduction)(assets);
  if (assets.length === 0 || !canShareProductionAsset) {
    return [];
  }
  return [
    <ShareAssetsActionMenuEntry
      key="share-assets"
      assets={assets}
      hasNoTargetProduction={hasNoTargetProduction}
    />,
  ];
};

export default useShareAssetsActionMenuEntry;
