import { Grid, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { ImageWithLoadingPlaceholder, IconButton } from 'medialoopster/components';
import { Info } from 'medialoopster/icons';

import { loadAncestor } from '../../../state/modules/details/actions';
import { getAncestorDetails } from '../../../state/modules/details/selectors';

interface AncestorLinkDetailsProps {
  ancestorHref: string;
}

const AncestorLinkDetails: FC<AncestorLinkDetailsProps> = ({
  ancestorHref,
}: AncestorLinkDetailsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ancestorSelector = useMemo(() => getAncestorDetails(ancestorHref), [ancestorHref]);
  const ancestorDetails = useSelector(ancestorSelector);
  const dataLoaded =
    (ancestorDetails.shot !== undefined && ancestorDetails.video !== undefined) ||
    ancestorDetails.error !== undefined;

  const dispatch = useDispatch();
  const onOpen = useCallback(() => {
    setIsOpen(true);
    dispatch(loadAncestor(ancestorHref));
  }, [dispatch, setIsOpen, ancestorHref]);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <Tooltip
      placement="right"
      title={
        <Grid container wrap="nowrap" spacing={1}>
          <Grid item>
            {ancestorDetails.video?.thumbnail_url && !ancestorDetails.error ? (
              <ImageWithLoadingPlaceholder
                url={ancestorDetails.video.thumbnail_url}
                placeholderWidth={70}
                placeholderHeight={40}
                className=""
              />
            ) : (
              ''
            )}
          </Grid>
          <Grid item>
            {ancestorDetails.error ? (
              <Typography component={'div'} noWrap variant="caption">
                {ancestorDetails.error}
              </Typography>
            ) : (
              <>
                <Typography component={'div'} noWrap variant="caption">
                  {gettext('Asset')}: {ancestorDetails.video?.name}
                </Typography>
                <Typography component={'div'} noWrap variant="caption">
                  {gettext('Ancestor')}: {ancestorDetails.shot?.name}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      }
      open={isOpen && dataLoaded}
      onOpen={onOpen}
      onClose={onClose}
    >
      <span>
        <IconButton sx={{ padding: '0 0 0 0' }}>
          <Info sx={{ fontSize: '1rem' }} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default AncestorLinkDetails;
