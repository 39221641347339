import qs, { stringify } from 'qs';

interface SearchDict {
  [key: string]: undefined | string | string[] | number | number[] | SearchDict | SearchDict[];
}

/**
 * Format an objects properties into a search string.
 */
export default (searchDict: SearchDict, options: qs.IStringifyOptions = {}): string =>
  stringify(searchDict, { format: 'RFC1738', ...options });
