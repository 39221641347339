/* eslint-disable import/prefer-default-export */
import { createCollectionSelector } from 'medialoopster/rest';

import { RootState } from '../../../types/rootState';

export const getSiteExportConfigs = createCollectionSelector(
  (state: RootState) => state.rest.siteexportconfigs.collectionLink?.href,
  (state: RootState) => state.rest.siteexportconfigs.pages,
  (state: RootState) => state.rest.siteexportconfigs.objects,
);
