import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { FC, memo, ReactNode } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import {
  CheckboxTableRow,
  ErrorCell,
  IconCell,
  SimpleCell,
  StaticTablePagination,
  Table,
  tableTypes,
} from 'medialoopster/components';

import { AssetType } from '../../../../state/types/asset/baseTypes';
import AssetIcon from '../../AssetIcon';
import TableLabel from './TableLabel';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
  },
}));

export interface AssetTypeCheckboxRowData extends tableTypes.RowData {
  readonly url: string;
  readonly display: string;
  readonly error: string;
  readonly typeName: AssetType;
  readonly defaultChecked?: boolean;
  readonly disabled?: boolean;
}

const tableHeadCells = [
  { display: gettext('Type'), key: 'headerType' },
  { display: gettext('Name'), key: 'headerName' },
  { display: '', key: 'optionalStatusCell' },
];

const renderRow = (dataRow: tableTypes.RowData, rowIdentifier: string): ReactNode => {
  const data = dataRow as AssetTypeCheckboxRowData;
  const hasError = data.error !== '';
  const defaultChecked = data?.defaultChecked ?? false;
  const disabled = data?.disabled ?? false;
  return (
    <CheckboxTableRow
      key={rowIdentifier}
      rowIdentifier={rowIdentifier}
      disabled={hasError || disabled}
      defaultChecked={defaultChecked}
    >
      <IconCell disabled={hasError}>
        <AssetIcon typeName={data.typeName} />
      </IconCell>
      <SimpleCell key="display" value={data.display} disabled={hasError} />
      <ErrorCell error={data.error} />
    </CheckboxTableRow>
  );
};

const getRowIdentifier = (dataRow: tableTypes.RowData) => {
  const data = dataRow as AssetTypeCheckboxRowData;
  return data.url;
};

interface AssetTypeCheckboxTableProps {
  readonly rowData: ReadonlyArray<AssetTypeCheckboxRowData>;
  readonly defaultSelection?: ReadonlyArray<string>;
  readonly dense?: boolean;
  readonly label?: string;
  // Note: A given onSelectChange needs a stable reference
  readonly onSelectChange?: (selected: ReadonlyArray<string>) => void;
  readonly disabledTableInfo?: string;
  readonly emptyChoicesInfo?: string;
  readonly rowsPerPageOptions?: number[];
  readonly loading?: boolean;
}

const AssetTypeCheckboxTable: FC<AssetTypeCheckboxTableProps> = ({
  rowData,
  dense = false,
  defaultSelection = [],
  label = '',
  // Note: A given onSelectChange needs a stable reference
  onSelectChange = () => {},
  disabledTableInfo = '',
  emptyChoicesInfo = '',
  rowsPerPageOptions = [10, 25, 50, 100],
  loading = false,
}: AssetTypeCheckboxTableProps) => {
  const classes = useStyles();

  const PaginationElement = (
    <StaticTablePagination
      rowData={rowData}
      rowsPerPageOptions={rowsPerPageOptions}
      disabledTableInfo={disabledTableInfo}
      emptyChoicesInfo={emptyChoicesInfo}
    />
  );

  return (
    <>
      <TableLabel label={label} />
      <Paper className={classes.paper}>
        <Table
          checkboxes
          dense={dense}
          height="367px"
          rowData={rowData}
          getRowIdentifier={getRowIdentifier}
          headCells={tableHeadCells}
          defaultSelection={defaultSelection}
          onSelectChange={onSelectChange}
          loading={loading}
          renderRow={renderRow}
          PaginationElement={PaginationElement}
        />
      </Paper>
    </>
  );
};

export default memo(AssetTypeCheckboxTable);
