import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

import { pgettext } from 'medialoopster/Internationalization';
import { Close } from 'medialoopster/icons';

type Props = {
  id?: string;
  headerTitle: string;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: 'flex',
  },
  button: {
    marginLeft: 'auto',
  },
}));

const label = pgettext('Modal window close button title', 'Close this modal window');

const ModalHeader: FC<Props> = ({ id, headerTitle, onClose }: Props) => {
  const cssClasses = useStyles();
  return (
    <DialogTitle id={id} classes={{ root: cssClasses.dialogTitle }}>
      {headerTitle}
      <IconButton
        onClick={onClose}
        aria-label={label}
        title={label}
        size="small"
        classes={{ root: cssClasses.button }}
      >
        <Close fontSize="small" />
      </IconButton>
    </DialogTitle>
  );
};

export default ModalHeader;
