import {
  ChangeEvent,
  FC,
  useReducer,
  useLayoutEffect,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  ReactNode,
  useContext,
} from 'react';

import { RowData } from './types';

const REFRESH_FROM_EXTERNAL_DATA = 'REFRESH_FROM_EXTERNAL_DATA';
const SELECT_ALL = 'SELECT_ALL';
const CLICK_TABLE_ROW = 'CLICK_TABLE_ROW';

interface RefreshFromExternalData {
  readonly type: typeof REFRESH_FROM_EXTERNAL_DATA;
  readonly payload: {
    readonly rowData: ReadonlyArray<RowData>;
    readonly getRowIdentifier: (dataRow: RowData) => string;
  };
}

interface SelectAll {
  readonly type: typeof SELECT_ALL;
  readonly payload: {
    readonly checkAll: boolean;
  };
}

interface ClickTableRow {
  readonly type: typeof CLICK_TABLE_ROW;
  readonly payload: {
    readonly key: string;
    readonly isRangeSelect?: boolean;
  };
}

interface TableState {
  disabled: boolean;
  selectableChoices: ReadonlyArray<string>;
  selectedChoices: ReadonlyArray<string>;
  memoUserSelects: ReadonlyArray<string>;
  errorChoices: ReadonlyArray<string>;
  latestSelect?: string;
  latestRangeSelect?: string;
}

interface TableContextValue {
  readonly selectableChoices: ReadonlyArray<string>;
  readonly selectedChoices: ReadonlyArray<string>;
  readonly disabled: boolean;
  readonly numSelectedChoices: number;
}
interface HeadContextValue {
  readonly handleSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  readonly numSelectableChoices: number;
  readonly numSelectedChoices: number;
}
interface RowContextValue {
  readonly isSelected: (key?: string) => boolean;
  readonly handleClick: (key: string, isRangeSelect?: boolean) => void;
}
interface PaginationContextValue {
  readonly numSelectedChoices: number;
  readonly disabled: boolean;
}

const CheckboxTableContext = createContext<TableContextValue>({
  selectableChoices: [],
  selectedChoices: [],
  disabled: false,
  numSelectedChoices: 0,
});
const CheckboxTableHeadContext = createContext<HeadContextValue>({
  handleSelectAllClick: () => {},
  numSelectableChoices: 0,
  numSelectedChoices: 0,
});
const CheckboxTableRowContext = createContext<RowContextValue>({
  isSelected: () => false,
  handleClick: () => {},
});
const CheckboxTablePaginationContext = createContext<PaginationContextValue>({
  numSelectedChoices: 0,
  disabled: false,
});

export const useCheckboxTableContext = (): TableContextValue => useContext(CheckboxTableContext);
export const useCheckboxTableHeadContext = (): HeadContextValue =>
  useContext(CheckboxTableHeadContext);
export const useCheckboxTableRowContext = (): RowContextValue =>
  useContext(CheckboxTableRowContext);
export const useCheckboxTablePaginationContext = (): PaginationContextValue =>
  useContext(CheckboxTablePaginationContext);

const refreshFromExternalData = (
  rowData: ReadonlyArray<RowData>,
  getRowIdentifier: (dataRow: RowData) => string,
): RefreshFromExternalData => ({
  type: REFRESH_FROM_EXTERNAL_DATA,
  payload: { rowData, getRowIdentifier },
});
const selectAll = (checkAll: boolean): SelectAll => ({
  type: SELECT_ALL,
  payload: { checkAll },
});
const clickTableRow = (key: string, isRangeSelect = false): ClickTableRow => ({
  type: CLICK_TABLE_ROW,
  payload: { key, isRangeSelect },
});

const filterSelectableKeys = (
  rowData: ReadonlyArray<RowData>,
  getRowIdentifier: (dataRow: RowData) => string,
): string[] => {
  // Return keys for rows that are not disabled and do not have errors
  return rowData.flatMap((elem) => {
    const rowDataElem = getRowIdentifier(elem);
    return elem.error === '' && elem.disabled !== true && rowDataElem ? [rowDataElem] : [];
  });
};
const filterDataKeysWithErrors = (
  rowData: ReadonlyArray<RowData>,
  getRowIdentifier: (dataRow: RowData) => string,
): string[] => {
  return rowData.flatMap((elem) => {
    const rowDataElem = getRowIdentifier(elem);
    return elem.error !== '' && rowDataElem ? [rowDataElem] : [];
  });
};
const getRangeTargets = (
  selectableChoices: ReadonlyArray<string>,
  key1: string,
  key2: string,
): ReadonlyArray<string> => {
  const indKey1 = selectableChoices.indexOf(key1);
  const indKey2 = selectableChoices.indexOf(key2);
  if (indKey1 >= indKey2) {
    return selectableChoices.slice(indKey2, indKey1 + 1);
  }
  return selectableChoices.slice(indKey1, indKey2 + 1);
};

const tableChoiceReducer = (
  state: TableState,
  action: ClickTableRow | SelectAll | RefreshFromExternalData,
): TableState => {
  switch (action.type) {
    case REFRESH_FROM_EXTERNAL_DATA: {
      const selectableDataKeys = filterSelectableKeys(
        action.payload.rowData,
        action.payload.getRowIdentifier,
      );
      const currentSelects = state.selectedChoices;
      const newErrorChoices = filterDataKeysWithErrors(
        action.payload.rowData,
        action.payload.getRowIdentifier,
      );
      const nextSelects = selectableDataKeys.filter(
        (elem) =>
          currentSelects.includes(elem) ||
          (state.errorChoices.includes(elem) && state.memoUserSelects.includes(elem)),
      );
      return {
        ...state,
        ...action.payload,
        selectedChoices: nextSelects,
        errorChoices: newErrorChoices,
        selectableChoices: selectableDataKeys,
        disabled: newErrorChoices.length === action.payload.rowData.length,
        latestSelect: nextSelects[0],
        latestRangeSelect: nextSelects[0],
      };
    }
    case SELECT_ALL: {
      if (action.payload.checkAll) {
        return {
          ...state,
          selectedChoices: state.selectableChoices,
          memoUserSelects: state.selectableChoices,
          latestSelect: state.selectableChoices[0],
          latestRangeSelect: state.selectableChoices[0],
        };
      }
      return { ...state, selectedChoices: [], memoUserSelects: [] };
    }
    case CLICK_TABLE_ROW: {
      const currentSelects = state.selectedChoices;
      const { latestSelect } = state;
      const { latestRangeSelect } = state;
      const { key, isRangeSelect } = action.payload;
      const selectedIndex = currentSelects.indexOf(key);
      let newSelected: string[] = [];
      if (!isRangeSelect) {
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(currentSelects, key);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(currentSelects.slice(1));
        } else if (selectedIndex === currentSelects.length - 1) {
          newSelected = newSelected.concat(currentSelects.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            currentSelects.slice(0, selectedIndex),
            currentSelects.slice(selectedIndex + 1),
          );
        }
      }
      const shouldDeselect = selectedIndex !== -1;
      if (isRangeSelect && latestSelect) {
        let rangeTargets: ReadonlyArray<string> = [];
        if (shouldDeselect) {
          // Target row is already selected -> range deselect, from last select target row
          rangeTargets = getRangeTargets(
            state.selectableChoices,
            key,
            latestRangeSelect ?? latestSelect,
          );
          newSelected = currentSelects
            .filter((elem) => !rangeTargets.includes(elem))
            .concat(state.selectableChoices[state.selectableChoices.indexOf(key)]);
        } else {
          // Target row is not selected  -> range select
          rangeTargets = getRangeTargets(state.selectableChoices, key, latestSelect);
          newSelected = currentSelects.filter((elem) => !rangeTargets.includes(elem));
          newSelected = newSelected.concat(rangeTargets);
        }
      }
      let nextLatestRangeSelect;
      let nextLatestSelect;
      if (isRangeSelect && !shouldDeselect) {
        nextLatestRangeSelect = key;
        nextLatestSelect = state.latestSelect;
      } else {
        nextLatestRangeSelect = key;
        nextLatestSelect = key;
      }
      return {
        ...state,
        selectedChoices: [...newSelected],
        memoUserSelects: [...newSelected],
        latestSelect: nextLatestSelect,
        latestRangeSelect: nextLatestRangeSelect,
      };
    }
    default:
      return state;
  }
};

interface Props {
  readonly rowData: ReadonlyArray<RowData>;
  readonly getRowIdentifier: (dataRow: RowData) => string;
  readonly defaultSelection?: ReadonlyArray<string>;
  readonly onSelectChange?: (selected: ReadonlyArray<string>) => void;
  readonly children: ReactNode | ReactNode[];
}

const CheckboxTableContextProvider: FC<Props> = ({
  rowData,
  getRowIdentifier,
  defaultSelection = [],
  onSelectChange = () => {},
  children,
}: Props) => {
  const [tableChoiceState, dispatchTableChoiceAction] = useReducer(tableChoiceReducer, {
    selectedChoices: defaultSelection,
    selectableChoices: filterSelectableKeys(rowData, getRowIdentifier),
    memoUserSelects: defaultSelection,
    errorChoices: [],
    disabled: false,
  });
  const { selectableChoices, selectedChoices, disabled } = tableChoiceState;

  useLayoutEffect(() => {
    onSelectChange(selectedChoices);
  }, [onSelectChange, selectedChoices]);

  useEffect(() => {
    dispatchTableChoiceAction(refreshFromExternalData(rowData, getRowIdentifier));
  }, [rowData, getRowIdentifier, dispatchTableChoiceAction]);

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatchTableChoiceAction(selectAll(event.target.checked));
    },
    [dispatchTableChoiceAction],
  );

  const handleClick = useCallback(
    (key: string, isRangeSelect = false) => {
      dispatchTableChoiceAction(clickTableRow(key, isRangeSelect));
    },
    [dispatchTableChoiceAction],
  );

  const contextValue = useMemo(
    () => ({
      selectableChoices,
      selectedChoices,
      disabled,
      numSelectedChoices: selectedChoices.length,
    }),
    [selectableChoices, selectedChoices, disabled],
  );

  const headContextValue = useMemo(
    () => ({
      handleSelectAllClick,
      numSelectableChoices: selectableChoices.length,
      numSelectedChoices: selectedChoices.length,
    }),
    [handleSelectAllClick, selectableChoices, selectedChoices],
  );

  const rowContextValue = useMemo(
    () => ({
      isSelected: (key?: string) => key !== undefined && selectedChoices.indexOf(key) !== -1,
      handleClick,
    }),
    [selectedChoices, handleClick],
  );

  const paginationContextValue = useMemo(
    () => ({
      numSelectedChoices: selectedChoices.length,
      disabled,
    }),
    [selectedChoices, disabled],
  );

  return (
    <CheckboxTableContext.Provider value={contextValue}>
      <CheckboxTableHeadContext.Provider value={headContextValue}>
        <CheckboxTableRowContext.Provider value={rowContextValue}>
          <CheckboxTablePaginationContext.Provider value={paginationContextValue}>
            {children}
          </CheckboxTablePaginationContext.Provider>
        </CheckboxTableRowContext.Provider>
      </CheckboxTableHeadContext.Provider>
    </CheckboxTableContext.Provider>
  );
};

export default CheckboxTableContextProvider;
