import { LinkedItemsPageResource, ResourceMap, ResourceOptionsV2 } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { Sequence, Shot, Subtitle, VideoAsset } from './types';

export const getVideoAssets = (state: RootState): ResourceMap<VideoAsset, ResourceOptionsV2> =>
  state.video.assets;
export const getSequences = (state: RootState): ResourceMap<Sequence, ResourceOptionsV2> =>
  state.video.sequences;
export const getShots = (state: RootState): ResourceMap<Shot, ResourceOptionsV2> =>
  state.video.shots;
export const getSubtitles = (state: RootState): ResourceMap<Subtitle> => state.video.subtitles;
export const getVideoProjectsCollections = (
  state: RootState,
): ResourceMap<LinkedItemsPageResource<'videoasset-projects'>> => state.video.projectsCollections;
