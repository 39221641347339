import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import MUITableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';

import { useCheckboxTableHeadContext } from './CheckboxTableContextProvider';
import { HeadCell } from './types';

const useStyles = makeStyles(() => ({
  cell: {
    whiteSpace: 'nowrap',
  },
}));

interface CheckboxTableHeadProps {
  readonly headCells: ReadonlyArray<HeadCell>;
  readonly checkboxes?: boolean;
}
const TableHead: FC<CheckboxTableHeadProps> = ({
  headCells = [],
  checkboxes = false,
}: CheckboxTableHeadProps) => {
  const classes = useStyles();
  let checkedState: undefined | boolean | 'mixed';
  let isChecked;
  let isIndeterminate;
  let isDisabled;
  const { handleSelectAllClick, numSelectableChoices, numSelectedChoices } =
    useCheckboxTableHeadContext();
  if (checkboxes) {
    isChecked = numSelectableChoices > 0 && numSelectedChoices === numSelectableChoices;
    isIndeterminate = numSelectedChoices > 0 && numSelectedChoices < numSelectableChoices;
    isDisabled = numSelectableChoices <= 0;
    checkedState = isIndeterminate && !isDisabled ? 'mixed' : isChecked && !isDisabled;
  }
  return (
    <MUITableHead>
      <TableRow aria-checked={checkedState}>
        {checkboxes && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={isIndeterminate}
              checked={isChecked}
              onChange={handleSelectAllClick}
              inputProps={{ 'aria-label': gettext('Select all table rows') }}
              disabled={isDisabled}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            className={classes.cell}
            align={headCell.align ? headCell.align : 'left'}
          >
            {headCell.display}
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  );
};

export default TableHead;
