import { FC } from 'react';

import AncestorLink from './AncestorLink';

interface SequenceAncestorLinksProps {
  sequenceAncestorHrefs: ReadonlyArray<string>;
  label: string;
}
const SequenceAncestorLinks: FC<SequenceAncestorLinksProps> = ({
  sequenceAncestorHrefs,
  label,
}: SequenceAncestorLinksProps) => {
  return (
    <>
      {sequenceAncestorHrefs.map((el) => (
        <AncestorLink href={el} label={label} key={el} />
      ))}
    </>
  );
};

export default SequenceAncestorLinks;
