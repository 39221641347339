import { Tooltip } from '@mui/material';
import MUIIconButton, { IconButtonProps as MUIIconButtonProps } from '@mui/material/IconButton';
import { FC, memo } from 'react';

interface IconButtonProps
  extends Pick<
    MUIIconButtonProps,
    | 'id'
    | 'onClick'
    | 'title'
    | 'onBlur'
    | 'aria-label'
    | 'disabled'
    | 'children'
    | 'className'
    | 'onMouseOver'
    | 'aria-valuetext'
    | 'aria-pressed'
    | 'aria-hidden'
    | 'sx'
    | 'color'
  > {
  dense?: boolean;
  ariaPressed?: boolean;
  ariaExpanded?: boolean;
  ariaControls?: string;
  ariaHasPopUp?: boolean;
}

// TODO: Implement a dense attribute <KH 2024-01-26 t:ML-3549>
// SEE: https://bitbucket.nachtblau.tv/projects/ML/repos/medialoopster/pull-requests/2576/overview?commentId=33633
const IconButton: FC<IconButtonProps> = ({
  onClick,
  onBlur,
  disabled,
  children,
  className,
  title,
  onMouseOver,
  'aria-label': ariaLabel,
  'aria-valuetext': ariaValueText,
  'aria-pressed': ariaPressed,
  'aria-hidden': ariaHidden,
  ariaExpanded,
  ariaControls,
  ariaHasPopUp,
  sx,
  color,
  dense = false,
}: IconButtonProps) => {
  const button = (
    <MUIIconButton
      onClick={onClick}
      onBlur={onBlur}
      disabled={disabled}
      size="small"
      className={className}
      color={color}
      onMouseOver={onMouseOver}
      aria-label={ariaLabel || title}
      aria-valuetext={ariaValueText}
      aria-pressed={ariaPressed}
      aria-hidden={ariaHidden}
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopUp}
      sx={dense ? { padding: '0.1rem', ...sx } : sx}
    >
      {children}
    </MUIIconButton>
  );
  return title ? (
    <Tooltip title={title}>
      <div>{button}</div>
    </Tooltip>
  ) : (
    button
  );
};

export default memo(IconButton);
