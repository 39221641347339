import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLinkHref, getResourceURI, mlRel } from 'medialoopster/rest';

import { Annotation } from '../../../../../../state/modules/annotation/types';
import { detailsActions } from '../../../../../../state/modules/details';
import { getIsEditingAnnotation } from '../../../../../../state/modules/details/selectors';
import { getCurrentUserURI } from '../../../../../../state/modules/users/selectors';
import AnnotationText from './AnnotationText';
import DeleteAnnotationButton from './DeleteAnnotationButton';
import EditAnnotationButton from './EditAnnotationButton';
import ShareAnnotationButton from './ShareAnnotationButton';
import UserAvatarWithBadge from './UserAvatarWithBadge';
import { useUserName } from './useUserName';

export interface AnnotationCardProps {
  annotation: Annotation;
}

/**
 * Internal annotation-component responsible for displaying a single comment/annotation.
 *
 * The representation contains an Avatar and Username for the User, the Text of the annotation
 * as well as its creation date. It also contains a set of controls to delete, edit and share
 * annotations.
 *
 * @param annotation The Annotation to display.
 */
const AnnotationCard: FC<AnnotationCardProps> = ({ annotation }) => {
  const dispatch = useDispatch();
  const userURI = getLinkHref(annotation, mlRel('user'));

  const { firstName, lastName } = useUserName(userURI);

  const currentUserURI = useSelector(getCurrentUserURI);
  const isAnnotationOwner = userURI === currentUserURI;
  const formattedDateAdd = DateTime.fromISO(annotation.date_add).toLocaleString(
    DateTime.DATETIME_SHORT,
  );
  const annotationURI = useMemo(() => getResourceURI(annotation), [annotation]);

  const editingAnnotation = useSelector(getIsEditingAnnotation(annotationURI));
  const toggleEditAnnotation = () => {
    dispatch(detailsActions.toggleEditingAnnotation(annotationURI));
  };

  return (
    <Stack>
      <Stack mb="0.1rem" direction="row" alignItems="center">
        <UserAvatarWithBadge
          firstName={firstName}
          lastName={lastName}
          hasSharedAnnotation={annotation.is_public}
          isAnnotationOwner={isAnnotationOwner}
        />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          &nbsp;{firstName} {lastName}
        </Typography>
      </Stack>
      <AnnotationText
        text={annotation.text}
        annotationURI={getResourceURI(annotation)}
        editText={editingAnnotation}
        toggleEditText={toggleEditAnnotation}
      />

      <Stack direction="row" alignItems="center" flexWrap="wrap">
        {/* TODO: Standardize grey color <KH 2024-01-24 t:ML-3549> */}
        <Typography variant="caption" sx={{ color: 'Gray' }}>
          {formattedDateAdd}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" flexGrow="1">
          {isAnnotationOwner && (
            <>
              <DeleteAnnotationButton annotation={annotation} />
              <EditAnnotationButton
                editingAnnotation={editingAnnotation}
                toggleEditAnnotation={toggleEditAnnotation}
              />
              <ShareAnnotationButton annotation={annotation} />
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AnnotationCard;
