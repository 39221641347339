import { AssetChoice, CollectionChoice } from '../operations/types';
import {
  OPEN_BULK_TRANSFER_MODAL,
  CLOSE_BULK_TRANSFER_MODAL,
  TRANSFER_ASSETS,
  CHECK_TRANSFER_ASSETS,
  SET_TRANSFER_ERRORS,
  TRANSFER_STARTED,
  OpenBulkTransferModal,
  CloseBulkTransferModal,
  TransferAssets,
  CheckTransferAssets,
  SetTransferErrors,
  AssetErrors,
  TransferStarted,
  CheckTransferCollection,
  TransferCollection,
  TRANSFER_COLLECTION,
  CHECK_TRANSFER_COLLECTION,
} from './types';

/**
 * Open the bulk transfer modal.
 */
export const openBulkTransferModal = (
  assetChoices: ReadonlyArray<AssetChoice>,
  collectionChoice: CollectionChoice | null = null,
): OpenBulkTransferModal => ({
  type: OPEN_BULK_TRANSFER_MODAL,
  payload: { assetChoices, collectionChoice },
});

/**
 * Close the bulk transfer modal.
 */
export const closeBulkTransferModal = (): CloseBulkTransferModal => ({
  type: CLOSE_BULK_TRANSFER_MODAL,
});

/**
 * Transfer assets to a device.
 *
 * @param actionURL: The transfer-assets action URL of the device.
 * @param assetURLs: The URLs of the assets to transfer.
 * (it helps to identify the need to create intermediate target directories for linked media assets).
 */
export const transferAssets = (
  actionURL: string,
  assetURLs: ReadonlyArray<string>,
  filename: string,
): TransferAssets => ({
  type: TRANSFER_ASSETS,
  payload: { actionURL, assetURLs, filename },
});

/**
 * Check if given assets can be transferred to a device.
 *
 * @param actionURL: The transfer-assets action URL of the device.
 * @param assetURLs: The URLs of the assets to transfer.
 * (it helps to identify the need to create intermediate target directories for linked media assets).
 */
export const checkTransferAssets = (
  actionURL: string,
  assetURLs: ReadonlyArray<string>,
  filename: string,
  collection: string | null = null,
): CheckTransferAssets => ({
  type: CHECK_TRANSFER_ASSETS,
  payload: { actionURL, assetURLs, filename, collection },
});

/**
 * Set errors to be expected when transferring given assets.
 *
 * @param actionURL: The transfer-assets action URL of the device.
 * @param assetErrors: Mapping of asset URL to error string.
 */
export const setTransferErrors = (
  actionURL: string,
  assetErrors: AssetErrors,
  collectionError: string | null = null,
  otherError: string | null = null,
): SetTransferErrors => ({
  type: SET_TRANSFER_ERRORS,
  payload: { actionURL, assetErrors, collectionError, otherError },
});

export const transferStarted = (monitoringURL?: string): TransferStarted => ({
  type: TRANSFER_STARTED,
  payload: { monitoringURL },
});

/**
 * Check if given collection can be transferred to a device.
 *
 * @param actionURL: The transfer-assets action URL of the device.
 * @param assetURLs: The URLs of the assets to transfer.
 * @param collectionURL: The collection URLs to transfer.
 */
export const checkTransferCollection = (
  actionURL: string,
  assetURLs: ReadonlyArray<string>,
  collectionURL: string,
): CheckTransferCollection => ({
  type: CHECK_TRANSFER_COLLECTION,
  payload: { actionURL, assetURLs, collectionURL },
});

/**
 * Transfer collection to a device.
 *
 * @param actionURL: The transfer-assets action URL of the device.
 * @param assetURLs: The URLs of the linked assets to transfer.
 * @param collectionURL: The collection URLs to transfer.
 */
export const transferCollection = (
  actionURL: string,
  assetURLs: ReadonlyArray<string>,
  collectionURL: string,
): TransferCollection => ({
  type: TRANSFER_COLLECTION,
  payload: { actionURL, assetURLs, collectionURL },
});
