import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import { ProjectAssetsRootState } from './types';

// TODO: Remove. Note needed for <AssetLinks/> <PB 2024-04-08 t:ML-2034>
export default combineReducers<ProjectAssetsRootState>({
  assets: createResourcesReducer('projectasset'),
});
