import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { AudioAsset } from '../../state/modules/audio/types';
import { detailsActions } from '../../state/modules/details';
import { ImageAsset } from '../../state/modules/image/types';
import { Sequence, Shot } from '../../state/modules/video/types';
import CategoryKeywordsInput from './CategoryKeywordsInput';

type Props = {
  readonly obj: ImageAsset | AudioAsset | Shot | Sequence;
  readonly keywordsByCategory: ReadonlyArray<{
    readonly name: string;
    readonly keywords: ReadonlyArray<string>;
    readonly availableKeywords: ReadonlyArray<string>;
  }>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const KeywordCategories: FC<Props> = ({ obj, keywordsByCategory }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formHooks = useForm({ mode: 'onChange' });
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <FormProvider {...formHooks}>
      <Grid container spacing={4} className={classes.container}>
        {keywordsByCategory.map((category) => (
          <CategoryKeywordsInput
            key={category.name}
            categoryName={category.name}
            selectedKeywords={category.keywords}
            availableKeywords={category.availableKeywords}
            onAddKeywords={(keywordsToAdd) => {
              dispatch(
                detailsActions.updateKeywords(obj, [
                  ...keywordsByCategory.flatMap(({ keywords }) => keywords),
                  ...keywordsToAdd,
                ]),
              );
            }}
            onRemoveKeyword={(keywordToRemove) => {
              dispatch(
                detailsActions.updateKeywords(
                  obj,
                  keywordsByCategory
                    .flatMap(({ keywords }) => keywords)
                    .filter((keyword) => keyword !== keywordToRemove),
                ),
              );
            }}
          />
        ))}
      </Grid>
    </FormProvider>
  );
};

export default KeywordCategories;
