import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { gettext, pgettext } from 'medialoopster/Internationalization';
import { ConfirmationModal, IconButton } from 'medialoopster/components';
import { Delete, Delete as DeleteIcon } from 'medialoopster/icons';

import { Annotation } from '../../../../../../state/modules/annotation/types';
import { detailsActions } from '../../../../../../state/modules/details';

/**
 * The button that handles the deletion of an annotation.
 *
 * @param annotation The annotation to delete.
 */
const DeleteAnnotationButton: FC<{ annotation: Annotation }> = ({ annotation }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const deleteAnnotation = useCallback(() => {
    dispatch(detailsActions.deleteAnnotation(annotation));
  }, [annotation, dispatch]);

  return (
    // TODO: Standardize padding <KH 2024-01-24 t:ML-3549>
    <>
      <ConfirmationModal
        title={gettext('Delete Annotation')}
        message={gettext('Do you really want to delete this annotation?')}
        label={pgettext('Form submit button label', 'Delete')}
        icon={<Delete />}
        open={open}
        onCancel={handleDialogClose}
        onConfirm={deleteAnnotation}
      />
      <IconButton
        title={gettext('Delete this annotation.')}
        aria-label="delete"
        onClick={handleDialogOpen}
        sx={{ padding: '3px' }}
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </>
  );
};

export default DeleteAnnotationButton;
