import { DateTime } from 'luxon';
import { FC } from 'react';

interface FormattedDateProps {
  date: string;
  format: string;
}

const FormattedDate: FC<FormattedDateProps> = ({ date = '0', format }: FormattedDateProps) => (
  <time>{DateTime.fromISO(date).toFormat(format)}</time>
);

export default FormattedDate;
