import { DateTime } from 'luxon';

import { pgettext } from 'medialoopster/Internationalization';

export const parseDateRangeStringToISO = (dateRangeStr: string): string[] => {
  const dateTimeFormat = `${pgettext('Localized numeric date', 'MM/dd/yyyy')} HH:mm`;
  const [startDateStr = '', endDateStr = ''] = dateRangeStr.split(' - ');
  const startDate = DateTime.fromFormat(startDateStr, dateTimeFormat);
  const endDate = DateTime.fromFormat(endDateStr, dateTimeFormat);
  if (startDate.isValid && endDate.isValid) {
    return [startDate.toISO(), endDate.toISO()];
  }

  return [];
};

export default {};
