/**
 * This module implements a component to display the timecode of a time block.
 */
import { Link } from '@mui/material';
import React, { MouseEventHandler, useCallback } from 'react';

import { formatSecondsAsTimecode } from 'medialoopster/formatTimecode';

import { Decimal, lax } from '../../decimal';
import { useTranscriptEditorContext } from '../TranscriptEditorContext';

/**
 * A component to display the time code of a TranscriptTimeBlockNode.
 *
 * The timecode is rendered as timecode with millisecond accuracy.
 *
 * @param time The time of the timecode to display.
 * @constructor
 */
export const TimeCodeLink: React.FC<{ time: Decimal }> = ({ time }) => {
  const { onNavigation, offsetTime } = useTranscriptEditorContext();
  const displayTime = time.plus(offsetTime);
  const timecode = formatSecondsAsTimecode(lax(displayTime).toNumber());
  const onClick: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      onNavigation(time);
    },
    [onNavigation, time],
  );

  return (
    <Link href="#" onClick={onClick}>
      {timecode}
    </Link>
  );
};

export default TimeCodeLink;
