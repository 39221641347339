import { Button, DialogActions, Grid, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactNode, FC, ChangeEvent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { pgettext, gettext } from 'medialoopster/Internationalization';
import { Modal, Table } from 'medialoopster/components';
import StaticPagination from 'medialoopster/components/Table/pagination/StaticPagination';
import { RowData } from 'medialoopster/components/Table/types';

import { uploadActions, uploadSelectors } from '../../state/modules/upload';
import { UploadDisplay } from '../../state/modules/upload/types';
import UploadRow from './UploadRow';

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: '1000px',
    },
    '& .MuiDialogContent-root': {
      width: '1000px',
    },
  },
  progressBar: {
    marginBottom: '12px',
  },
}));

type Props = {
  readonly visible: boolean;
  readonly onClose: () => void;
};

const getRowIdentifier = (row: RowData): string => (row as UploadDisplay).objectURL;

const renderRow = (row: RowData, rowIdentifier: string): ReactNode => {
  const uploadDisplay = row as UploadDisplay;
  return (
    <UploadRow
      key={rowIdentifier}
      objectURL={uploadDisplay.objectURL}
      baseName={uploadDisplay.baseName}
      extension={uploadDisplay.extension}
      sizeDisplay={uploadDisplay.sizeDisplay}
      uploadURL={uploadDisplay.uploadURL}
      editable={uploadDisplay.editable}
      nameErrorMessage={uploadDisplay.nameErrorMessage}
      message={uploadDisplay.message}
      progress={uploadDisplay.progress}
      disabled={uploadDisplay.disabled}
      isPending={uploadDisplay.isPending}
    />
  );
};

const UploadModal: FC<Props> = ({ visible, onClose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const defaultUploadURL = useSelector(uploadSelectors.getDefaultProductionUploadURL);
  const uploadDisplays = useSelector(uploadSelectors.getUploadsForDisplay);
  const uploadProgress = useSelector(uploadSelectors.getUploadProgress);
  const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(
        uploadActions.addFiles(
          Array.from(event.target.files).map((file) => ({
            objectURL: URL.createObjectURL(file),
            name: file.name,
            size: file.size,
          })),
          defaultUploadURL,
        ),
      );
      // HACK: Set the value, so adding the same files again in Chrome will lead to a change event.
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      dispatch(
        uploadActions.addFiles(
          acceptedFiles.map((file) => ({
            objectURL: URL.createObjectURL(file),
            name: file.name,
            size: file.size,
          })),
          defaultUploadURL,
        ),
      );
    },
    [dispatch, defaultUploadURL],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: true,
  });
  return (
    <Modal id="upload-modal" open={visible} onClose={onClose} className={classes.modal}>
      <Modal.Header
        headerTitle={pgettext('Modal title', 'medialoopster Uploader')}
        onClose={onClose}
      />
      <Modal.Body>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div {...getRootProps()} data-testid="dropzone">
          <Grid container alignItems="flex-end">
            <Grid item xs={9}>
              {uploadProgress.visible && (
                <div>
                  {uploadProgress.percentage === 0 ? (
                    <>
                      <LinearProgress className={classes.progressBar} variant="indeterminate" />
                      <span>{gettext('Preparing upload')}</span>
                    </>
                  ) : (
                    <>
                      <LinearProgress
                        className={classes.progressBar}
                        value={uploadProgress.percentage}
                        variant="determinate"
                      />
                      <span>
                        {uploadProgress.speed} | {uploadProgress.timeElapsed} |{' '}
                        {uploadProgress.percentage} % | {uploadProgress.bytesUploaded} /{' '}
                        {uploadProgress.bytesTotal}
                      </span>
                    </>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={3}>
              <DialogActions>
                <Button
                  id="upload-modal-add-files-button"
                  size="small"
                  color="success"
                  variant="contained"
                  component="label"
                  disableElevation
                >
                  {pgettext('Form action button', 'Add files...')}
                  <input
                    aria-labelledby="upload-modal-add-files-button"
                    type="file"
                    hidden
                    multiple
                    onChange={onFileSelected}
                  />
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  component="label"
                  onClick={() => dispatch(uploadActions.uploadAll())}
                  color="primary"
                  disableElevation
                >
                  {pgettext('Form action button', 'Upload all')}
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          {uploadDisplays.length > 0 && (
            <Table
              dense
              rowData={uploadDisplays}
              getRowIdentifier={getRowIdentifier}
              renderRow={renderRow}
              headCells={[]}
              PaginationElement={
                <StaticPagination rowData={uploadDisplays} rowsPerPageOptions={[10, 15, 25]} />
              }
            />
          )}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button tooltip', 'Close upload window')}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default UploadModal;
