import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { FC } from 'react';

import { pgettext } from 'medialoopster/Internationalization';
import { MultilineText, Modal } from 'medialoopster/components';

import { AssetChoiceWithError } from '../../../../../state/modules/operations/types';
import AssetTypeCheckboxTable, {
  AssetTypeCheckboxRowData,
} from '../../../../../ui/components/Tables/AssetTypeCheckboxTable';

interface Props {
  readonly id: string;
  readonly downloadHref?: string;
  readonly monitoringHref?: string;
  readonly open: boolean;
  readonly isFinished: boolean;
  readonly hasError: boolean;
  readonly onClose: () => void;
  readonly helpText: string;
  readonly headerTitle: string;
  readonly submitButtonTitle: string;
  readonly assetRows?: ReadonlyArray<AssetTypeCheckboxRowData>;
  readonly assetErrors?: ReadonlyArray<AssetChoiceWithError>;
}

const PrepareDownloadModal: FC<Props> = ({
  id,
  onClose,
  headerTitle,
  open,
  monitoringHref,
  downloadHref,
  helpText,
  isFinished,
  hasError,
  submitButtonTitle,
  assetRows = [],
  assetErrors = [],
}: Props) => {
  return (
    <Modal id={id} open={open} onClose={onClose}>
      <Modal.Header headerTitle={headerTitle} onClose={onClose} />
      <Modal.Body>
        <Box py={8} px={12}>
          <Box pb={4}>
            <MultilineText text={helpText} />
          </Box>
          <Box px={12}>
            <LinearProgress
              variant={isFinished ? 'determinate' : 'indeterminate'}
              value={isFinished ? 100 : 0}
              color={hasError ? 'secondary' : 'primary'}
            />
          </Box>
        </Box>
        {assetErrors && assetErrors.length > 0 && <AssetTypeCheckboxTable rowData={assetRows} />}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ActionButton
          href={monitoringHref || ''}
          label={pgettext('Form action button label', 'Show Downloads')}
          title={pgettext('Form action button title', 'Show download activities in monitoring')}
          disabled={!monitoringHref}
        />
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel this form')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          href={downloadHref || ''}
          label={pgettext('Form submit button label', 'Download')}
          title={submitButtonTitle}
          disabled={!isFinished || hasError || !downloadHref}
          forceDownload
        />
      </Modal.Footer>
    </Modal>
  );
};

export default PrepareDownloadModal;
