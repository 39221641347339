import { APIError, APIFieldError, getResourceOptions } from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import { CreatedAssetCollectionState } from './types';

export const canCreateAssetCollection = (state: RootState): boolean => {
  if (state.rest.collection.collectionLink) {
    return !!getResourceOptions(
      state.rest.collection.pages,
      state.rest.collection.collectionLink.href,
    )?.actions?.POST;
  }
  return false;
};

export const getNewAssetCollection = (state: RootState): CreatedAssetCollectionState => {
  return {
    assetCollectionHref:
      state.createAssetCollectionModal.createdAssetCollection.assetCollectionHref,
  };
};

const defaultAssetCollectionErrors: ReadonlyArray<APIError | APIFieldError> = [];
export const getNewAssetCollectionCreateErrors = (
  state: RootState,
): ReadonlyArray<APIError | APIFieldError> =>
  state.createAssetCollectionModal.createdAssetCollection.errors || defaultAssetCollectionErrors;

export const getCollectionLink = (state: RootState): string | undefined =>
  state.rest.collection.collectionLink?.href;
