import { ListItemIcon, TextFieldProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { Ref, FC, ReactNode } from 'react';

import InternalTextField from './internal/InternalTextField';

const useStyles = makeStyles(() => ({
  itemRoot: {
    minHeight: '36px',
  },
  iconContainer: {
    display: 'flex', // to properly align ListItemIcon while displayed within input field
  },
  listItemIconRoot: {
    minWidth: 32,
    fontSize: '1.187rem', // .MuiInputBase-root line-height
    alignSelf: 'center',
  },
}));

export interface SelectOption {
  readonly value: number | string;
  readonly display: number | string;
  icon?: ReactNode;
  [key: string]: number | string | ReactNode;
}

interface Choice {
  [key: string]: number | string;
}

export const makeSelectOptions = (
  choices: ReadonlyArray<Choice>,
  choiceValue = 'id',
  choiceDisplay = 'name',
  defaultDisplay: string | null = '---------',
  defaultValue: string | number = 0,
): SelectOption[] => {
  const options = choices.map((c) => ({
    value: c[choiceValue],
    display: c[choiceDisplay],
  }));
  if (defaultDisplay && options.length !== 1) {
    return [{ value: defaultValue, display: defaultDisplay }, ...options];
  }
  return options;
};

type Props = {
  id: string;
  name?: string;
  onChange: (value: string) => void;
  label?: string;
  title?: string;
  choices?: ReadonlyArray<SelectOption>;
  showError?: boolean;
  errorMsg?: string;
  value?: string;
  defaultValue?: number | string;
  dense?: boolean;
  readOnly?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  onKeyUp?: TextFieldProps['onKeyUp'];
  onBlur?: TextFieldProps['onBlur'];
};

// https://github.com/mui-org/material-ui/issues/16978
type HackyMUIRefObject = {
  focus: () => void;
  node: HTMLInputElement;
  value: number | string;
};

const Select: FC<Props> = ({
  id,
  name,
  onChange,
  choices = [],
  label = '',
  title = undefined,
  readOnly = false,
  showError = false,
  errorMsg = '',
  value = undefined,
  defaultValue,
  dense = false,
  inputRef = undefined,
  onKeyUp = undefined,
  onBlur = undefined,
}: Props) => {
  const classes = useStyles();

  const initial = defaultValue ?? choices[0]?.value ?? '';
  const hasError = showError && !_.isEmpty(errorMsg);
  return (
    <InternalTextField
      inputRef={(obj) => {
        if (obj && 'node' in obj && typeof inputRef === 'function') {
          inputRef((obj as HackyMUIRefObject).node);
        }
      }}
      id={id}
      name={name}
      title={title}
      label={label}
      value={value}
      defaultValue={initial}
      onChange={onChange}
      showError={hasError}
      errorMsg={hasError ? errorMsg : undefined}
      dense={dense}
      select
      onKeyUp={onKeyUp}
      readOnly={readOnly}
      onBlur={onBlur}
    >
      {choices.map((choice) => (
        <MenuItem key={choice.value} value={choice.value} classes={{ root: classes.itemRoot }}>
          {choice.icon ? (
            <div className={classes.iconContainer}>
              <ListItemIcon className={classes.listItemIconRoot}>{choice.icon}</ListItemIcon>
              {choice.display}
            </div>
          ) : (
            choice.display
          )}
        </MenuItem>
      ))}
    </InternalTextField>
  );
};

export default Select;
