import { getDeviceDisplayOption } from 'medialoopster';
import { createSelector } from 'reselect';

import { Device } from 'medialoopster/modules';
import { getLink, getLinkHref } from 'medialoopster/rest';

import { AssetType } from '../../types/asset/baseTypes';
import { RootState } from '../../types/rootState';
import { deviceSelectors } from '../device';
import { AssetChoice, CollectionChoice } from '../operations/types';
import { isSuperUser } from '../users/selectors';
import {
  DeviceChoice,
  TRANSFER_ASSETS_REL,
  TRANSFER_AUDIO_ASSETS_REL,
  TRANSFER_IMAGE_ASSETS_REL,
  TRANSFER_VIDEO_ASSETS_REL,
  TransferAssetChoice,
} from './types';

const transferRelationMapping = {
  videoasset: TRANSFER_VIDEO_ASSETS_REL,
  imageasset: TRANSFER_IMAGE_ASSETS_REL,
  audioasset: TRANSFER_AUDIO_ASSETS_REL,
  collection: 'transfer_collection',
};

/**
 * Get the transfer relation to be used.
 */
const getTransferRelation = (_: RootState, assetChoices: ReadonlyArray<AssetChoice>): string => {
  const typeNameSet = new Set(assetChoices.map(({ typeName }) => typeName));
  if (typeNameSet.size === 1) {
    const typeName: AssetType = typeNameSet.values().next().value;
    return transferRelationMapping[typeName] || '';
  }
  return TRANSFER_ASSETS_REL;
};

/**
 * Get all devices to which asset transfer is permitted.
 */
export const getPermittedTransferDevices = (
  state: RootState,
  assetChoices: ReadonlyArray<AssetChoice>,
): ReadonlyArray<Device> => {
  const transferRelation = getTransferRelation(state, assetChoices);
  return deviceSelectors.getDeviceList(state).filter((device) => {
    const transferActionCode = getLink(device, transferRelation)?.methods?.POST?.code;
    return transferActionCode && transferActionCode !== 'permission_denied';
  });
};

/**
 * Get all devices where assets can actually be transferred to.
 */
export const getValidTransferDevices = (
  state: RootState,
  assetChoices: ReadonlyArray<AssetChoice>,
): ReadonlyArray<Device> => {
  const transferRelation = getTransferRelation(state, assetChoices);
  return getPermittedTransferDevices(state, assetChoices).filter(
    (device) => getLink(device, transferRelation)?.methods?.POST?.code === 'ok',
  );
};

/**
 * Decide whether a transfer is generally possible.
 * (Determines the action menu item's visibility.)
 */
export const canTransferSelection = (
  state: RootState,
  assetChoices: ReadonlyArray<AssetChoice>,
): boolean => getPermittedTransferDevices(state, assetChoices).length > 0;

/**
 * Check whether there are any transfer target devices.
 * (Determines if the action menu item is clickable.)
 */
export const hasTransferTargetDevice = (
  state: RootState,
  assetChoices: ReadonlyArray<AssetChoice>,
): boolean =>
  canTransferSelection(state, assetChoices) &&
  getValidTransferDevices(state, assetChoices).length > 0;

/**
 * Check if all asset choices are not in production. This will disable the transfer.
 */
export const isNotInProduction = (
  _: RootState,
  assetChoices: ReadonlyArray<AssetChoice>,
): boolean => assetChoices.every((assetChoice) => assetChoice.isProduction === false);

/**
 * Get the visibility of the bulk transfer modal.
 */
export const isBulkTransferModalVisible = (state: RootState): boolean =>
  state.transfer.bulkTransferModal.visible;

/**
 * Get the choices for the target devices to be displayed in the bulk transfer modal.
 *
 * Contains the bulk transfer action URI and a display name.
 */
export const getBulkTransferModalTargetDeviceChoices = createSelector(
  (state: RootState) =>
    getValidTransferDevices(state, state.transfer.bulkTransferModal.assetChoices),
  (state: RootState) =>
    getTransferRelation(
      state,
      state.transfer.bulkTransferModal.collectionChoice
        ? [state.transfer.bulkTransferModal.collectionChoice]
        : state.transfer.bulkTransferModal.assetChoices,
    ),
  (state: RootState) => isSuperUser(state),
  (
    deviceList: ReadonlyArray<Device>,
    transferRelation: string,
    hasSuperUserRight: boolean,
  ): ReadonlyArray<DeviceChoice> => {
    return deviceList.map((device) => ({
      value: getLinkHref(device, transferRelation) || '',
      // TODO: When converting to API V3, use `getResourceLink(device).title`. <DT 2024-03-21 t:ML-3609>
      display: getDeviceDisplayOption(device, hasSuperUserRight),
    }));
  },
);

/**
 * Get the errors for the current transfer action url.
 */
export const getTransferActionURLErrors = createSelector(
  (state: RootState) => state.transfer.bulkTransferModal.actionURL,
  (state: RootState) => state.transfer.bulkTransferModal.transferErrors,
  (url, errs) => errs[url],
);

/**
 * Get the asset choices to be displayed in the bulk transfer modal.
 */
export const getBulkTransferAssetChoices = createSelector(
  (state: RootState) => state.transfer.bulkTransferModal.assetChoices,
  getTransferActionURLErrors,
  (assetChoices, transferErrors): ReadonlyArray<TransferAssetChoice> =>
    assetChoices.map((choice) => {
      const error = (transferErrors?.assetErrors || {})[choice.url] || '';
      return {
        url: choice.url,
        error,
        display: `${choice.name}`,
        typeName: choice.typeName,
      };
    }),
);

export const getBulkTransferCollectionAndOtherErrors = createSelector(
  getTransferActionURLErrors,
  (actionError) => [actionError?.collectionError || null, actionError?.otherError || null],
);

/**
 * Get the transfer action URI of the currently selected target device.
 */
export const getBulkTransferActionURL = (state: RootState): string =>
  state.transfer.bulkTransferModal.actionURL;

/**
 * Check if the bulk transfer modal is currently checking the asset transfer.
 */
export const isBulkTransferModalChecking = (state: RootState): boolean =>
  state.transfer.bulkTransferModal.checking;

/**
 * Get the URLs of the currently selected assets.
 */
export const getSelectedAssetURLs = createSelector(
  getBulkTransferAssetChoices,
  (choices: ReadonlyArray<TransferAssetChoice>): ReadonlyArray<string> =>
    choices.map(({ url }) => url),
);

/**
 * Get the URLs of the currently selected assets, which can be transferred.
 */
export const getTransferableAssetURLs = createSelector(
  getBulkTransferAssetChoices,
  (assetChoices) => assetChoices.filter(({ error }) => !error).map(({ url }) => url),
);

/**
 * Get the link to the activities of the started transfer in the monitoring.
 */
export const getMonitoringURL = (state: RootState): string | null =>
  state.transfer.bulkTransferModal.monitoringURL;

/**
 * Get the collection choice if available.
 */
export const getCollectionChoice = (state: RootState): CollectionChoice | null =>
  state.transfer.bulkTransferModal.collectionChoice;
