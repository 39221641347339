import { SelectOption } from 'medialoopster/components/Select';
import { CollectionResourceMap, Resource, ResourceMap } from 'medialoopster/rest';

export const FETCH_SEARCHFIELDMAPPINGS = '/FRONTEND/SEARCHFIELDMAPPINGS/FETCH_SEARCHFIELDMAPPINGS';
export const SEARCHFIELDMAPPING_LOADED = 'FRONTEND/SEARCHFIELDMAPPING_LOADED';

export type SearchFieldMappingInputType =
  | 'Text'
  | 'Date'
  | 'Keyword'
  | 'Select'
  | 'Duration'
  | 'Person'
  | 'Exists';

export type SearchFieldLookup = '0' | '1';

export interface SearchFieldMapping extends Resource<'searchfieldmapping'> {
  readonly id: number;
  readonly uuid: string;
  readonly type: 'Video' | 'Audio' | 'Image' | 'Project';
  readonly input_type: SearchFieldMappingInputType;
  readonly label: string;
  readonly field_lookups: ReadonlyArray<SearchFieldLookup>;
  readonly default_field_lookup: SearchFieldLookup | '';
  readonly videokeywordcategories: ReadonlyArray<number>;
  readonly imagekeywordcategories: ReadonlyArray<number>;
  readonly audiokeywordcategories: ReadonlyArray<number>;
  readonly duration_presets?: ReadonlyArray<string>;
  readonly select_field?: string;
  readonly select_options?: ReadonlyArray<{ value: string; display_value: string }>;
}

export interface SearchFieldMappingWithOptions extends SearchFieldMapping {
  readonly keywords?: ReadonlyArray<string>;
  readonly persons?: ReadonlyArray<string>;
  readonly selectOptions?: ReadonlyArray<SelectOption>;
}

export type FetchSearchFieldMappings = {
  readonly type: typeof FETCH_SEARCHFIELDMAPPINGS;
  readonly payload: {
    readonly next?: string;
  };
};

export interface SearchFieldMappingRootState {
  readonly searchfieldmappings: ResourceMap<SearchFieldMapping>;
  readonly searchfieldmappingCollections: CollectionResourceMap<SearchFieldMapping>;
}
