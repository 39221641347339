import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import { SearchFieldMappingRootState } from './types';

export default combineReducers<SearchFieldMappingRootState>({
  searchfieldmappings: createResourcesReducer('searchfieldmapping'),
  searchfieldmappingCollections: createResourcesReducer('searchfieldmapping-collection'),
});
