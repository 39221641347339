import { ConnectorResource } from 'medialoopster/modules';

import { Asset } from '../../types/asset/unionTypes';
import {
  ASSET_EXCHANGE_CONNECTORS_LOADED,
  AssetExchangeConnectorsLoaded,
  CHECK_EXCHANGED_ASSETS_REMOVE_ACTION,
  CheckExchangedAssetsRemoveAction,
  EXCHANGE_ASSETS,
  ExchangeAssets,
  EXCHANGED_ASSETS_REMOVE_ACTION_CHECKED,
  EXCHANGED_ASSETS_REMOVED,
  ExchangedAssetsRemoveActionChecked,
  ExchangedAssetsRemoved,
  FETCH_ASSET_EXCHANGE_CONNECTORS,
  FetchAssetExchangeConnectors,
  REMOVE_EXCHANGED_ASSETS,
  RemoveExchangedAssets,
  TOGGLE_APPROVAL_STATUS,
  ToggleApprovalStatus,
  Webhook,
  WEBHOOKS_LOADED,
  WebhooksLoaded,
} from './types';

export const webhooksLoaded = (instances: ReadonlyArray<Webhook>): WebhooksLoaded => ({
  type: WEBHOOKS_LOADED,
  payload: {
    instances,
  },
});

export const fetchAssetExchangeConnectors = (): FetchAssetExchangeConnectors => ({
  type: FETCH_ASSET_EXCHANGE_CONNECTORS,
});

export const assetExchangeConnectorsLoaded = (
  instances: ReadonlyArray<ConnectorResource<'assetexchangeconnector'>>,
): AssetExchangeConnectorsLoaded => ({
  type: ASSET_EXCHANGE_CONNECTORS_LOADED,
  payload: {
    instances,
  },
});

export const exchangeAssets = (
  actionURI: string,
  assetURIs: ReadonlyArray<string>,
  assetTitles: Record<string, string>,
  alreadyUploadedAssetURIs: ReadonlyArray<string> = [],
): ExchangeAssets => ({
  type: EXCHANGE_ASSETS,
  payload: {
    actionURI,
    assetURIs,
    assetTitles,
    alreadyUploadedAssetURIs,
  },
});

export const removeExchangedAssets = (
  actionURI: string,
  assetURIs: ReadonlyArray<string>,
  assetTitles: Record<string, string>,
  notUploadedAssetURIs: ReadonlyArray<string> = [],
): RemoveExchangedAssets => ({
  type: REMOVE_EXCHANGED_ASSETS,
  payload: {
    actionURI,
    assetURIs,
    assetTitles,
    notUploadedAssetURIs,
  },
});

export const exchangedAssetsRemoved = (): ExchangedAssetsRemoved => ({
  type: EXCHANGED_ASSETS_REMOVED,
});

export const checkExchangedAssetsRemoveAction = (
  connectorURI: string,
  actionURI: string,
  assetURIs: ReadonlyArray<string>,
): CheckExchangedAssetsRemoveAction => ({
  type: CHECK_EXCHANGED_ASSETS_REMOVE_ACTION,
  payload: {
    connectorURI,
    actionURI,
    assetURIs,
  },
});

export const exchangedAssetsRemoveActionChecked = (
  connectorURI: string,
  isSuccess: boolean,
): ExchangedAssetsRemoveActionChecked => ({
  type: EXCHANGED_ASSETS_REMOVE_ACTION_CHECKED,
  payload: {
    connectorURI,
    isSuccess,
  },
});

export const toggleApprovalStatus = (asset: Asset): ToggleApprovalStatus => ({
  type: TOGGLE_APPROVAL_STATUS,
  payload: {
    asset,
  },
});
