import { gettext } from 'medialoopster/Internationalization';

import { DOWNLOAD_FROM_URL, DownloadFromURL } from './types';

export const downloadFromURL = (
  url: string,
  headers: Record<string, string> = {},
  fileCreateError: string = gettext('Failed to create the download file.'),
  requestError: string = gettext('Failed to download from the URL'),
): DownloadFromURL => ({
  type: DOWNLOAD_FROM_URL,
  payload: {
    url,
    headers,
    fileCreateError,
    requestError,
  },
});

export default {};
