import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, ngettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Transfer } from 'medialoopster/icons';

import { favoritesSelectors } from '../../../../state/modules/favorites';
import { transferActions, transferSelectors } from '../../../../state/modules/transfer';
import { RootState } from '../../../../state/types/rootState';

const TransferActionButton: FC = () => {
  const dispatch = useDispatch();
  const assetChoices = useSelector(favoritesSelectors.getAssetChoices);
  const canTransferListContent = useSelector((state: RootState) =>
    transferSelectors.canTransferSelection(state, assetChoices),
  );
  const hasTransferTargetDevice = useSelector((state: RootState) =>
    transferSelectors.hasTransferTargetDevice(state, assetChoices),
  );
  const isSelectedListAssetsLoading = useSelector(favoritesSelectors.isSelectedListAssetsLoading);

  const isNotInProduction = useSelector((state: RootState) =>
    transferSelectors.isNotInProduction(state, assetChoices),
  );

  const getTransferTitle = () => {
    if (!hasTransferTargetDevice) {
      return gettext('There are no devices to transfer to');
    }
    if (isSelectedListAssetsLoading) {
      return gettext('Assets are being loaded');
    }
    if (assetChoices.length === 0) {
      return gettext('There are no assets in the favorite item list');
    }
    if (isNotInProduction) {
      return ngettext(
        'The asset in the list cannot be transferred',
        'None of the assets in the list can be transferred',
        assetChoices.length,
      );
    }
    return gettext('Transfer assets in the favorite item list');
  };

  return canTransferListContent ? (
    <MenuItem
      disabled={
        isNotInProduction ||
        isSelectedListAssetsLoading ||
        !hasTransferTargetDevice ||
        assetChoices.length === 0
      }
      tooltip={getTransferTitle()}
      onClick={() => {
        dispatch(transferActions.openBulkTransferModal(assetChoices));
      }}
      icon={<Transfer fontSize="small" />}
      label={gettext('Transfer list content...')}
    />
  ) : (
    <></>
  );
};

export default TransferActionButton;
