import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import { FC } from 'react';

import { gettext, interpolate } from 'medialoopster/Internationalization';

/**
 *
 * @property {boolean} loading - The search result is loading or not.
 * @property {number} totalCount - The total count of search results.
 * @property {string} totalRelation - The relationship of the total count and actual result count.
 * @property {string} searchCategory - The category of the search results.
 */
type ResultsCountProps = {
  readonly loading: boolean;
  readonly totalCount: number;
  readonly totalRelation: string;
  readonly searchCategory: string;
};

const singleAssetFoundTextMapping: Record<string, string> = {
  videoasset: gettext('1 video asset found'),
  audioasset: gettext('1 audio asset found'),
  imageasset: gettext('1 image asset found'),
  collection: gettext('1 collection found'),
};

const multipleAssetsFoundTextMapping: Record<string, string> = {
  videoasset: gettext('%(totalCount)s video assets found'),
  audioasset: gettext('%(totalCount)s audio assets found'),
  imageasset: gettext('%(totalCount)s image assets found'),
  collection: gettext('%(totalCount)s collections found'),
};
/**
 * SearchResultCount Component
 *
 * This component renders the search result count.
 *
 */
const SearchResultCount: FC<ResultsCountProps> = ({
  loading,
  totalCount,
  totalRelation,
  searchCategory,
}) => {
  const getTotalCountMessage = (): string => {
    const totalCountText = `${totalCount.toLocaleString()}${totalRelation === 'gte' ? '+' : ''}`;
    if (totalCount === 0) {
      return '';
    }
    if (totalCount <= 1) {
      return singleAssetFoundTextMapping[searchCategory];
    }
    return interpolate(multipleAssetsFoundTextMapping[searchCategory], {
      totalCount: totalCountText,
    });
  };
  return (
    <Fade in={!loading && totalCount !== 0}>
      <Box
        sx={{
          textAlign: 'left',
          color: 'text.secondary',
          padding: '0px 0px 0px 20px',
          height: '30px',
          position: 'absolute',
          marginTop: '5px',
        }}
      >
        {getTotalCountMessage()}
      </Box>
    </Fade>
  );
};
export default SearchResultCount;
