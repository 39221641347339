import { MediaAssetType } from '../../types/asset/baseTypes';
import { FETCH_KEYWORDCATEGORY, FetchKeywordCategory } from './types';

export const assetTypeToKeywordCategoryUrlMap = {
  videoasset: '/api/videokeywordcategories/',
  audioasset: '/api/audiokeywordcategories/',
  imageasset: '/api/imagekeywordcategories/',
};

export const fetchKeywordCategory = (assetType: MediaAssetType): FetchKeywordCategory => ({
  type: FETCH_KEYWORDCATEGORY,
  payload: { url: assetTypeToKeywordCategoryUrlMap[assetType] },
});
