import MUITableBody from '@mui/material/TableBody';
import { FC, memo, ReactNode } from 'react';

import { usePaginationContext } from './pagination/PaginationContextProvider';
import { RowData } from './types';

export interface TableBodyProps {
  readonly getRowIdentifier: (dataRow: RowData) => string;
  readonly renderRow: (dataRow: RowData, rowIdentifier: string) => ReactNode;
}

const TableBody: FC<TableBodyProps> = ({ getRowIdentifier, renderRow }: TableBodyProps) => {
  const paginationSlice = usePaginationContext();
  return (
    <MUITableBody>
      {paginationSlice.map((row) => renderRow(row, getRowIdentifier(row)))}
    </MUITableBody>
  );
};

export default memo(TableBody);
