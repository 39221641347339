export const parseDurationString = (duration: string): number => {
  const timeArray = duration.split(':');

  if (timeArray.length === 0 || timeArray.length > 3) {
    return NaN;
  }

  return (
    parseInt(timeArray[timeArray.length - 1], 10) +
    (parseInt(timeArray[timeArray.length - 2], 10) * 60 || 0) +
    (parseInt(timeArray[timeArray.length - 3], 10) * 3600 || 0)
  );
};

export const parseDurationRangeString = (range: string): number[] => {
  const durationStrings = range.split(' - ');
  if (durationStrings.length !== 2) {
    return [NaN, NaN];
  }
  const minStr = parseDurationString(durationStrings[0]);
  const maxStr = parseDurationString(durationStrings[1]);

  return [minStr, maxStr];
};
