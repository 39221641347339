import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { ReactNode, forwardRef } from 'react';

interface UserNotificationProps {
  onClose: () => void;
  message: ReactNode;
  feedback?: 'error' | 'warning' | 'info' | 'success';
}

const UserNotification = forwardRef<HTMLDivElement, UserNotificationProps>(
  ({ feedback, message, onClose }: UserNotificationProps, ref) => {
    if (feedback) {
      return (
        <MuiAlert ref={ref} severity={feedback} onClose={onClose}>
          <Typography component="span" style={{ whiteSpace: 'pre-line' }}>
            {message}
          </Typography>
        </MuiAlert>
      );
    }
    // Empty dummy. Snackbar needs subcomponnents with ref because of transitions
    // Eg. https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Slider/Slider.js#L514
    return <div ref={ref} />;
  },
);

export default UserNotification;
