import { Box } from '@mui/material';
import { FC } from 'react';

import AnnotationInput from './AnnotationInput';

/**
 * Helper component to render the footer of the AnnotationsPanel
 *
 * The footer holds the input for new annotations.
 */
const AnnotationsPanelFooter: FC = () => {
  return (
    <Box m={2}>
      <AnnotationInput />
    </Box>
  );
};

export default AnnotationsPanelFooter;
