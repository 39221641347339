import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type Props = {
  readonly title: ReactNode;
  readonly children?: ReactNode;
};

const ModalCard: FC<Props> = ({ title, children }: Props) => (
  <Card>
    <CardHeader title={<Typography variant="h6">{title}</Typography>} />
    <CardContent>{children}</CardContent>
  </Card>
);

export default ModalCard;
