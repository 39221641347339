import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FC, useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import { favoritesSelectors } from '../../state/modules/favorites';
import { ImageAsset } from '../../state/modules/image/types';
import { personsSelectors } from '../../state/modules/persons';
import useAssetDragRef from '../../ui/hooks/useAssetDragRef';
import BoundingBoxes from './BoundingBoxes';
import PersonsView from './PersonsView';
import PlayerContainer from './PlayerContainer';

type ImageProxyProps = {
  readonly asset: ImageAsset;
};

const useStyles = makeStyles(() => ({
  cursorGrab: {
    cursor: 'grab',
  },
}));

const ImageProxy: FC<ImageProxyProps> = ({ asset }: ImageProxyProps) => {
  const classes = useStyles();
  const isFavoriteListActive = useSelector(favoritesSelectors.isActive);
  const imageDragRef = useAssetDragRef(asset, 'imageasset');
  const { padding } = useSelector(personsSelectors.getOverlayDisplayValues);

  const [isLoading, setIsLoading] = useState(true);
  const [imageRefTarget, setImageRefTarget] = useState<HTMLImageElement | null>(null);
  const onLoaded = () => setIsLoading(false);

  useEffect(() => {
    imageRefTarget?.addEventListener('load', onLoaded);
    return () => {
      imageRefTarget?.removeEventListener('load', onLoaded);
    };
  }, [imageRefTarget]);

  return (
    <PlayerContainer
      player={
        <>
          <img
            className={isLoading ? undefined : clsx({ [classes.cursorGrab]: isFavoriteListActive })}
            alt={asset.name}
            src={asset.proxy_url}
            style={{
              width: '100%',
              height: '100%',
              paddingLeft: `${padding * 100}%`,
              paddingRight: `${padding * 100}%`,
            }}
            ref={(cur) => {
              setImageRefTarget(cur);
              imageDragRef?.(cur);
            }}
            data-testid="image-proxy"
          />
        </>
      }
      overlay={
        <>
          <div id="overlay-container">
            <DndProvider backend={HTML5Backend}>
              <BoundingBoxes />
            </DndProvider>
          </div>
          <div id="persons-in-asset">
            <PersonsView />
          </div>
        </>
      }
    />
  );
};

export default ImageProxy;
