import { Resource } from '../rest';

export const IMAGE_TYPE = 'imageasset';
export const AUDIO_TYPE = 'audioasset';
export const VIDEO_TYPE = 'videoasset';
export const PROJECT_TYPE = 'projectasset';
export const COLLECTION_TYPE = 'collection';

export type AssetType =
  | typeof IMAGE_TYPE
  | typeof AUDIO_TYPE
  | typeof VIDEO_TYPE
  | typeof PROJECT_TYPE;

export interface Device extends Resource<'device'> {
  readonly name: string;
  readonly display_name: string;
  readonly is_archive: boolean;
}

export interface ConnectorResource<T extends string> extends Resource<T> {
  readonly id: number;
  readonly name: string;
  readonly engine: string;
}

export interface ContentAnalysisConnectorResource
  extends ConnectorResource<'contentanalysisconnector'> {
  readonly supported_asset_types: ReadonlyArray<AssetType | 'collection'>;
}
