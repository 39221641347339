import { FC, useCallback } from 'react';

import { CustomMetadataField as Field } from '../../../state/modules/details/types';
import { isAssetCollection } from '../../../state/modules/rest/collection/types';
import { Highlight } from '../../../state/modules/search/types';
import { Asset } from '../../../state/types/asset/unionTypes';
import AssetMetadataField from '../AssetFields/AssetMetadataField';
import CalendarDate from '../AssetFields/CalendarDate';
import CalendarDateTime from '../AssetFields/CalendarDateTime';
import ComboBox from '../AssetFields/ComboBox';
import Selects from '../AssetFields/Selects';
import Text from '../AssetFields/Text';
import TextArea from '../AssetFields/TextArea';
import Year from '../AssetFields/Year';

type Props = {
  readonly field: Field;
  readonly asset: Asset;
  readonly assetHighlights: Highlight;
  readonly isEditMode: boolean;
  readonly onSubmit: (key: string, value: string | null) => void;
};

const CustomMetadataField: FC<Props> = ({
  field,
  asset,
  assetHighlights,
  isEditMode,
  onSubmit,
}: Props) => {
  const metaFieldStore = isAssetCollection(asset)
    ? // eslint-disable-next-line no-underscore-dangle
      asset._embedded.meta_field_store
    : asset.meta_field_store;
  const value = metaFieldStore[field.key]?.toString() || null;
  const visible = !!value || isEditMode;

  const handleSubmit = useCallback(
    (key: string) => (val: string | null) => {
      onSubmit(key, val);
    },
    [onSubmit],
  );
  if (field.input_type === 'Date') {
    return (
      <AssetMetadataField key={field.key} label={field.value} visible={visible} testId={field.key}>
        <CalendarDate value={value} canEdit={isEditMode} onSubmit={handleSubmit(field.key)} />
      </AssetMetadataField>
    );
  }
  if (field.input_type === 'DateTime') {
    return (
      <AssetMetadataField key={field.key} label={field.value} visible={visible} testId={field.key}>
        <CalendarDateTime value={value} canEdit={isEditMode} onSubmit={handleSubmit(field.key)} />
      </AssetMetadataField>
    );
  }
  if (field.input_type === 'Year') {
    return (
      <AssetMetadataField key={field.key} label={field.value} visible={visible} testId={field.key}>
        <Year value={value} />
      </AssetMetadataField>
    );
  }
  if (field.input_type === 'Select') {
    return (
      <AssetMetadataField key={field.key} label={field.value} visible={visible} testId={field.key}>
        <Selects
          value={value}
          canEdit={isEditMode}
          choices={field.choices}
          onSubmit={handleSubmit(field.key)}
        />
      </AssetMetadataField>
    );
  }
  if (field.input_type === 'ComboBox') {
    return (
      <AssetMetadataField key={field.key} label={field.value} visible={visible} testId={field.key}>
        <ComboBox
          value={value}
          canEdit={isEditMode}
          choices={field.choices}
          validationRule={field.rule}
          onSubmit={handleSubmit(field.key)}
        />
      </AssetMetadataField>
    );
  }
  if (field.input_type === 'TextArea') {
    return (
      <AssetMetadataField key={field.key} label={field.value} visible={visible} testId={field.key}>
        <TextArea
          value={value}
          highlights={assetHighlights?.fields?.[`meta_field_store.${field.key}`]}
          canEdit={isEditMode}
          onSubmit={handleSubmit(field.key)}
        />
      </AssetMetadataField>
    );
  }
  return (
    <AssetMetadataField key={field.key} label={field.value} visible={visible} testId={field.key}>
      <Text
        value={value}
        highlights={assetHighlights?.fields?.[`meta_field_store.${field.key}`]}
        allowLinks={field.allow_links}
        canEdit={isEditMode}
        validationRule={field.rule}
        onSubmit={handleSubmit(field.key)}
      />
    </AssetMetadataField>
  );
};
export default CustomMetadataField;
