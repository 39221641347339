import { Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback } from 'react';

import { gettext, pgettext } from '../../../Internationalization';
import IconButton from '../../buttons/IconButton';

const useStyles = makeStyles((theme: Theme) => ({
  leftBorderRadius: {
    borderRadius: '50% 0 0 50%',
    background: `linear-gradient(to right, white 50%, ${theme.palette.action.hover} 50%)`,
  },
  rightBorderRadius: {
    borderRadius: '0 50% 50% 0',
    background: `linear-gradient(to left, white 50%, ${theme.palette.action.hover} 50%)`,
  },
  buttonContainer: {
    display: 'flex',
    height: 28,
  },
  button: {
    padding: 0,
    height: 28,
    width: 28,
  },
  spacerButton: {
    height: 28,
    width: 8,
  },
  firstSpacerButton: {
    height: 28,
    width: 3,
  },
  buttonText: {
    lineHeight: 1.6,
  },
  outlined: {
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  filled: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    backgroundColor: theme.palette.primary.dark,
  },
  highlighted: {
    backgroundColor: theme.palette.action.hover,
  },
  contrast: {
    color: theme.palette.primary.contrastText,
  },
  rowSpacing: {
    height: 28,
  },
  hidden: {
    visibility: 'hidden',
  },
  bootstrapOverrides: {
    '&:focus': {
      outline: 'none !important',
    },
  },
}));

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onDayClick?: (day: DateTime) => void;
  onDayHover?: (day: DateTime) => void;
  day: DateTime;
  weekIndex?: number;
}

const Day: FunctionComponent<DayProps> = ({
  startOfRange,
  endOfRange,
  disabled,
  highlighted,
  outlined,
  filled,
  onDayClick,
  onDayHover,
  day,
  weekIndex,
}: DayProps) => {
  const classes = useStyles();
  const onHover = useCallback(() => onDayHover && onDayHover(day), [day, onDayHover]);
  const onClick = useCallback(() => onDayClick && onDayClick(day), [day, onDayClick]);
  return (
    <div className={clsx(classes.rowSpacing, { [classes.hidden]: disabled })}>
      <div
        className={clsx(classes.buttonContainer, {
          [classes.leftBorderRadius]: startOfRange,
          [classes.rightBorderRadius]: endOfRange,
          [classes.highlighted]: !startOfRange && !endOfRange && !disabled && highlighted,
        })}
      >
        {weekIndex !== 0 ? (
          <div className={clsx(classes.spacerButton)} />
        ) : (
          <div className={clsx(classes.firstSpacerButton)} />
        )}
        <IconButton
          className={clsx(classes.button, classes.bootstrapOverrides, {
            [classes.outlined]: !disabled && outlined,
            [classes.filled]: !disabled && filled,
          })}
          disabled={disabled}
          onClick={onClick}
          onMouseOver={onHover}
          aria-label={gettext('Select a day')}
          aria-valuetext={day.toFormat(pgettext('Localized numeric date', 'MM/dd/yyyy'))}
          aria-pressed={startOfRange || endOfRange}
          aria-hidden={disabled}
        >
          <Typography
            color={!disabled ? 'textPrimary' : 'textSecondary'}
            className={clsx(classes.buttonText, { [classes.contrast]: !disabled && filled })}
            variant="body2"
          >
            {String(day.day)}
          </Typography>
        </IconButton>
      </div>
    </div>
  );
};

export default Day;
