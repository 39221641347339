import { Box, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import makeStyles from '@mui/styles/makeStyles';
import { FC, ReactNode } from 'react';

import AssetActionToolbar from './AssetActionToolbar';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    position: 'relative',
    display: 'flow-root',
  },
  videoPlayerControl: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  gridPlayer: {
    position: 'relative',
    display: 'flex',
    marginBottom: '5px',
  },
  videoPlayerTime: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: '2px',
    padding: '0px 5px 3px',
  },
}));

type Props = {
  readonly player: ReactNode;
  readonly overlay?: ReactNode;
  readonly currentTimeDisplay?: ReactNode;
  readonly toolbarButtons?: ReactNode;
  readonly getCurrentTime?: () => number;
  readonly onDragStart?: () => void;
};

const PlayerContainer: FC<Props> = ({
  player,
  overlay,
  currentTimeDisplay,
  toolbarButtons,
  getCurrentTime = () => 0,
  onDragStart,
}: Props) => {
  const classes = useStyles();
  const isMdDown = useMediaQuery<DefaultTheme>((theme) => theme.breakpoints.down('md'));

  return (
    <Grid container className={classes.gridContainer}>
      <Grid container item xs={12}>
        <Grid className={classes.gridPlayer} item xs={12} onDragStart={onDragStart}>
          {player}
        </Grid>
        <Grid item xs={12}>
          {overlay}
        </Grid>
        <Grid
          className={classes.videoPlayerTime}
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {currentTimeDisplay}
        </Grid>
        <Grid
          item
          container
          xs={12}
          columnGap="1%"
          flexWrap="nowrap"
          direction="row"
          justifyContent="space-between"
          className={classes.videoPlayerControl}
        >
          {toolbarButtons}
          <Box flexGrow={1} />
          {!isMdDown && <AssetActionToolbar getCurrentTime={getCurrentTime} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlayerContainer;
