import { Link, Resource, ResourceMap } from 'medialoopster/rest';

export const FETCH_USERS = 'FRONTEND/USERS/FETCH_USERS';

//-------------
// Action Types
//-------------

export interface FetchUsers {
  readonly type: typeof FETCH_USERS;
}

//---------------
// Resource Types
//---------------

export interface User extends Resource<'user'> {
  readonly id: number;
  readonly username: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly is_superuser: boolean;
}
export type UsersMap = ResourceMap<User>;

//------------------
// Redux State Types
//------------------

export interface UsersRootState {
  readonly users: UsersMap;
  readonly currentUserLink: Link | null;
}
