import { KeyboardEvent, FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { gettext } from 'medialoopster/Internationalization';
import { Input } from 'medialoopster/components';

import { WebhookCustomPayloadField } from '../../../../../state/modules/operations/types';
import { capitalizeFirstLetter } from '../../../../../ui/services/capitalizeFirstLetter';

type Props = {
  readonly formFields: ReadonlyArray<WebhookCustomPayloadField>;
  readonly control: Control;
  readonly handleSubmit: (e: KeyboardEvent<HTMLDivElement>) => void;
};

const WebhookForm: FC<Props> = ({ formFields, control, handleSubmit }: Props) => {
  return (
    <>
      {formFields
        .slice()
        .sort((a, b) => a.order - b.order)
        .map((field) => {
          const displayName = field.field.name
            ? field.field.name
            : capitalizeFirstLetter(field.field.key);
          const rules = field.is_required
            ? {
                required: true,
                pattern: /\S+/i,
              }
            : {};
          return (
            <Controller
              key={field.field.key}
              control={control}
              defaultValue=""
              rules={rules}
              name={field.field.key}
              render={({ field: { onChange, value, name, ref }, fieldState: { invalid } }) => (
                <Input
                  onChange={(val) => onChange(val)}
                  value={value}
                  required={field.is_required}
                  inputRef={ref}
                  name={name}
                  label={displayName}
                  showError={invalid}
                  errorMsg={gettext('Please enter a valid value. This field is required.')}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                />
              )}
            />
          );
        })}
    </>
  );
};

export default WebhookForm;
