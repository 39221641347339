import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_NORMAL } from 'lexical';
import React, { useEffect } from 'react';

import { SAVE_TRANSCRIPT } from '../commands';
import {
  $calculateTranscriptDiff,
  TranscriptDiff,
  TranscriptSpeakerData,
  TranscriptUnitData,
} from '../diff';

export interface TranscriptSavePluginProps {
  /**
   * The original units of the transcript, that is currently shown in the editor.
   * The diff is calculated against these units.
   */
  readonly units: readonly TranscriptUnitData[];
  /**
   * The original speakers of the transcript, that is currently shown in the editor.
   */
  readonly speakers: readonly TranscriptSpeakerData[];
  /**
   * This function is called with the diff when the SAVE_TRANSCRIPT command is executed.
   */
  readonly onSave: (diff: TranscriptDiff) => void;
}

/**
 * A plugin to handle the saving of the transcript.
 *
 * The plugin supports saving the edits done to the current transcript. It provides the
 * command handler for the `SAVE_TRANSCRIPT` command. When the command
 * is dispatched this plugin calculates the difference representing the edit. It then calls the
 * `onSave` callback with the calculated diff.
 *
 * @param units The original transcript units.
 * @param speakers The original transcript speakers.
 * @param onSave A callback to handle the actual save logic. Called with the difference after a save is requested.
 */
export const TranscriptSavePlugin: React.FC<TranscriptSavePluginProps> = ({
  units,
  speakers,
  onSave,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      SAVE_TRANSCRIPT,
      () => {
        editor.getEditorState().read(() => {
          const diff = $calculateTranscriptDiff(units, speakers);
          onSave(diff);
        });
        return true;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [editor, onSave, units, speakers]);

  return null;
};
