import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import { MouseEvent, RefCallback, FC, useState, useEffect, memo } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
}));

interface ImageLoaderWithPlaceholderProps {
  placeholderWidth: number;
  placeholderHeight: number;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  dragRef?: RefCallback<HTMLImageElement>;
  className: string;
  url: string;
}

const ImageWithLoadingPlaceholder: FC<ImageLoaderWithPlaceholderProps> = ({
  placeholderWidth,
  placeholderHeight,
  url,
  dragRef,
  onClick,
  className,
}: ImageLoaderWithPlaceholderProps) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [imageRefTarget, setImageRefTarget] = useState<HTMLImageElement | null>(null);
  const onLoaded = () => setIsLoading(false);
  useEffect(() => {
    imageRefTarget?.addEventListener('load', onLoaded);
    return () => {
      imageRefTarget?.removeEventListener('load', onLoaded);
    };
  }, [imageRefTarget]);

  return (
    <div className={classes.container}>
      <img
        ref={(cur) => {
          setImageRefTarget(cur);
          dragRef?.(cur);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={onClick}
        className={isLoading ? undefined : className}
        src={url}
        alt="thumbnail"
        role="presentation"
        width={isLoading ? 0 : placeholderWidth}
        height={isLoading ? 0 : placeholderHeight}
        loading="lazy"
      />
      {isLoading && (
        <Skeleton variant="rectangular" width={placeholderWidth} height={placeholderHeight} />
      )}
    </div>
  );
};

export default memo(ImageWithLoadingPlaceholder);
