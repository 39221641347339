import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { Download } from 'medialoopster/icons';
import { loginSelectors } from 'medialoopster/state/login';
import { downloadFromURL } from 'medialoopster/state/main/actions';

import useStatusCodePermission, { StatusMessages } from '../services/useStatusCodePermission';

interface Props {
  readonly downloadURL: string;
}

const CurrentAssetHighresDownloadActionMenuEntry: FC<Props> = ({ downloadURL }: Props) => {
  const dispatch = useDispatch();
  const statusMessages: StatusMessages = {
    200: gettext('Download highres of the current asset'),
    400: gettext('Cannot download file because its file size exceeds the download limit'),
    404: gettext('Cannot download file because the highres is not available'),
    default: gettext('Cannot download file because of unknown reasons'),
  };
  const token = useSelector(loginSelectors.getToken);
  const { tooltip, disabled } = useStatusCodePermission(downloadURL, statusMessages, token);
  return (
    <MenuItem
      label={gettext('Download highres')}
      tooltip={tooltip}
      disabled={disabled}
      icon={<Download fontSize="small" />}
      onClick={() => dispatch(downloadFromURL(downloadURL))}
    />
  );
};

export default CurrentAssetHighresDownloadActionMenuEntry;
