import { ActionsMetadataV2 } from 'medialoopster/rest';

import { Asset } from '../../types/asset/unionTypes';
import {
  ARCHIVE_ASSET_OPTIONS_LOADED,
  ARCHIVE_ASSETS,
  ArchiveAssetOptionsLoaded,
  ArchiveAssets,
  FETCH_ARCHIVE_ASSET_OPTIONS,
  FETCH_RESTORE_ARCHIVED_ASSET_OPTIONS,
  FetchArchiveAssetOptions,
  FetchRestoreArchivedAssetOptions,
  RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED,
  RESTORE_ARCHIVED_ASSETS,
  RestoreArchivedAssetOptionsLoaded,
  RestoreArchivedAssets,
  REVOKE_ARCHIVE_ACTIVITIES,
  RevokeArchiveActivities,
} from './types';

export const fetchArchiveAssetOptions = (): FetchArchiveAssetOptions => ({
  type: FETCH_ARCHIVE_ASSET_OPTIONS,
});

export const archiveAssetOptionsLoaded = (
  actions: ActionsMetadataV2,
): ArchiveAssetOptionsLoaded => ({
  type: ARCHIVE_ASSET_OPTIONS_LOADED,
  payload: {
    actions,
  },
});

export const fetchRestoreArchivedAssetOptions = (): FetchRestoreArchivedAssetOptions => ({
  type: FETCH_RESTORE_ARCHIVED_ASSET_OPTIONS,
});

export const restoreArchivedAssetOptionsLoaded = (
  actions: ActionsMetadataV2,
): RestoreArchivedAssetOptionsLoaded => ({
  type: RESTORE_ARCHIVED_ASSET_OPTIONS_LOADED,
  payload: {
    actions,
  },
});

export const archiveAssets = (assets: ReadonlyArray<Asset>): ArchiveAssets => ({
  type: ARCHIVE_ASSETS,
  payload: {
    assets,
  },
});

export const revokeArchiveActivities = (assets: ReadonlyArray<Asset>): RevokeArchiveActivities => ({
  type: REVOKE_ARCHIVE_ACTIVITIES,
  payload: {
    assets,
  },
});

export const restoreArchivedAssets = (assets: ReadonlyArray<Asset>): RestoreArchivedAssets => ({
  type: RESTORE_ARCHIVED_ASSETS,
  payload: {
    assets,
  },
});
