import { combineReducers } from 'redux';

import {
  createResourcesReducer,
  createRootLinkReducer,
  getEmbeddedItems,
  getLinkHref,
  getResourceURI,
  mlRel,
  ResourceLoadedAction,
} from 'medialoopster/rest';

import { SEARCH_RESULT_COLLECTION_TYPE_TO_TYPE_NAME } from '../../../businessRules/models/asset/utils';
import { idFromHref } from '../../../businessRules/services/idFromHref';
import { SearchQuery } from '../../../sections/Header/services/SearchQueryService';
import { AssetType } from '../../types/asset/baseTypes';
import { Asset } from '../../types/asset/unionTypes';
import {
  AssetSearchPage,
  AUDIOASSET_SEARCH_RESULT_COLLECTION_LOADED,
  COLLECTION_SEARCH_RESULT_COLLECTION_LOADED,
  DESELECT_ALL_ENTRIES,
  DeselectAllEntries,
  FETCH_SEARCH_RESULT,
  FetchSearchResult,
  IMAGEASSET_SEARCH_RESULT_COLLECTION_LOADED,
  LOAD_SHOTS,
  LoadShots,
  MULTI_SELECT_ENTRY,
  MultiSelectEntry,
  RANGE_SELECT_ENTRIES,
  RangeSelectEntries,
  SEARCH_LOADED,
  SearchLoaded,
  SearchRootState,
  SelectedAssetsState,
  VIDEOASSET_SEARCH_RESULT_COLLECTION_LOADED,
} from './types';

export const assetSearchURIReducer = (
  state = '/api/search/videoassets/',
  { type, payload }: FetchSearchResult,
): string => {
  switch (type) {
    case FETCH_SEARCH_RESULT:
      return payload.url;
    default:
      return state;
  }
};

export const searchQueryReducer = (
  state = {},
  { type, payload }: FetchSearchResult,
): SearchQuery => {
  switch (type) {
    case FETCH_SEARCH_RESULT:
      return payload.query;
    default:
      return state;
  }
};

export const shotSearchURIReducer = (
  state: string | null = null,
  { type, payload }: LoadShots,
): string | null => {
  switch (type) {
    case LOAD_SHOTS:
      return payload.url;
    default:
      return state;
  }
};

export const initialSelectedAssetsState: SelectedAssetsState = {
  assetIds: [],
  assetTypeName: null,
};

export const selectAssetReducer = (
  state = initialSelectedAssetsState,
  action:
    | MultiSelectEntry
    | RangeSelectEntries
    | DeselectAllEntries
    | ResourceLoadedAction<AssetSearchPage<AssetType>>
    | FetchSearchResult,
): SelectedAssetsState => {
  switch (action.type) {
    // FIXME: `ResourceLoadedAction` should not be handled in epics or reducers,
    // as they might come from a completely different source than intended.
    // This reducer caused bug https://nachtblau.atlassian.net/browse/ML-3714 because of that.
    //
    // TODO: Refactor selection logic to not depend on internal `rest` actions. <DT 2024-07-09 t:ML-3714>
    case VIDEOASSET_SEARCH_RESULT_COLLECTION_LOADED:
    case IMAGEASSET_SEARCH_RESULT_COLLECTION_LOADED:
    case AUDIOASSET_SEARCH_RESULT_COLLECTION_LOADED: {
      const resourceLoadedAction = action as ResourceLoadedAction<AssetSearchPage<AssetType>>;
      const searchResultResource = Object.values(resourceLoadedAction.payload.resources)[0];
      if (!searchResultResource) {
        // HACK: No resource means only headers were fetched, so it wasn't a request for search results.
        // Quick and dirty fix for https://nachtblau.atlassian.net/browse/ML-3714.
        // See FIXME at the top of this reducer.
        return state;
      }
      if (getLinkHref(searchResultResource, 'prev') !== undefined) {
        return state;
      }

      if (
        SEARCH_RESULT_COLLECTION_TYPE_TO_TYPE_NAME[
          resourceLoadedAction.payload.resourceTypeName
        ] !== state.assetTypeName
      ) {
        return {
          assetIds: [],
          assetTypeName:
            SEARCH_RESULT_COLLECTION_TYPE_TO_TYPE_NAME[
              resourceLoadedAction.payload.resourceTypeName
            ],
        };
      }
      const searchResults = getEmbeddedItems(searchResultResource).map(
        (item) => getLinkHref(item, mlRel('asset')) || '',
      );

      const searchResultAssetIds = searchResults.map((x: string) =>
        parseInt(
          x
            .split('/')
            .filter((y) => y)
            .slice(-1)[0],
          10,
        ),
      );
      const assetIds = state.assetIds.filter((x) => searchResultAssetIds.indexOf(x) !== -1);
      return {
        ...state,
        assetIds,
      };
    }
    case COLLECTION_SEARCH_RESULT_COLLECTION_LOADED: {
      const resourceLoadedAction = action as ResourceLoadedAction<AssetSearchPage<AssetType>>;
      const searchResultResource = Object.values(resourceLoadedAction.payload.resources)[0];
      if (!searchResultResource) {
        // HACK: No resource means only headers were fetched, so it wasn't a request for search results.
        // Quick and dirty fix for https://nachtblau.atlassian.net/browse/ML-3714.
        // See FIXME at the top of this reducer.
        return state;
      }

      if (
        SEARCH_RESULT_COLLECTION_TYPE_TO_TYPE_NAME[
          resourceLoadedAction.payload.resourceTypeName
        ] !== state.assetTypeName
      ) {
        return {
          assetIds: [],
          assetTypeName:
            SEARCH_RESULT_COLLECTION_TYPE_TO_TYPE_NAME[
              resourceLoadedAction.payload.resourceTypeName
            ],
        };
      }
      return {
        ...state,
        assetIds: [],
      };
    }
    case MULTI_SELECT_ENTRY: {
      const assetIds = state.assetIds.slice();
      const { payload } = action as MultiSelectEntry;
      const indexOfId = state.assetIds.indexOf(payload.assetId);
      if (assetIds.length === 0) {
        // if there was no selection add the clicked asset to the selection
        assetIds.splice(0, 0, payload.assetId);
        const currentAssetId = idFromHref(payload.currentAssetState.href);
        // if an asset is loaded with the same type as the search list entries
        // and the loaded asset is not the clicked asset
        // and the loaded asset is in the displayed search list entries
        if (
          payload.assetTypeName === payload.currentAssetState.type &&
          payload.assetHref !== payload.currentAssetState.href &&
          payload.entries.some(
            (entry: Asset) => getResourceURI(entry) === payload.currentAssetState.href,
          )
        ) {
          // also add the loaded asset to the selection
          assetIds.splice(0, 0, currentAssetId);
        }
      } else if (indexOfId >= 0) {
        // if clicked asset was already in the selection remove it from the selection
        assetIds.splice(indexOfId, 1);
      } else {
        // otherewise add it to the selection
        assetIds.splice(0, 0, payload.assetId);
      }
      return {
        ...state,
        assetIds,
      };
    }
    case RANGE_SELECT_ENTRIES: {
      const { payload } = action as RangeSelectEntries;
      // reference entry for range selection is the currently loaded asset
      if (payload.assetTypeName !== payload.currentAssetState.type) {
        // clicked asset type not matching -> no range selection
        return state;
      }
      const assetIds = [];
      const currentAssetId = idFromHref(payload.currentAssetState.href);
      if (payload.assetId === currentAssetId) {
        assetIds.push(payload.assetId);
      } else {
        // collect all entries between click asset and currently loaded asset
        let foundInId = false;
        const inOutIds = [payload.assetId, currentAssetId];
        payload.entries.some((entry: Asset) => {
          if (foundInId) {
            assetIds.push(entry.id);
            if (inOutIds.includes(entry.id)) {
              return true; // we have all entries in selection
            }
          } else if (inOutIds.includes(entry.id)) {
            foundInId = true;
            assetIds.push(entry.id);
          }
          return false;
        });
      }
      return {
        ...state,
        assetIds,
      };
    }
    case DESELECT_ALL_ENTRIES:
    case FETCH_SEARCH_RESULT:
      return {
        ...state,
        assetIds: [],
      };
    default:
      return state;
  }
};

export const searchCategoryReducer = (
  state: AssetType = 'videoasset',
  { type, payload }: FetchSearchResult,
): AssetType => {
  switch (type) {
    case FETCH_SEARCH_RESULT:
      return payload.assetTypeName;
    default:
      return state;
  }
};

export const isRefreshingOrLoadingReducer = (
  state = false,
  { type }: FetchSearchResult | SearchLoaded,
): boolean => {
  switch (type) {
    case FETCH_SEARCH_RESULT:
      return true;
    case SEARCH_LOADED:
      return false;
    default:
      return state;
  }
};

export default combineReducers<SearchRootState>({
  isRefreshingOrLoading: isRefreshingOrLoadingReducer,
  assetSearchURI: assetSearchURIReducer,
  shotSearchURI: shotSearchURIReducer,
  selection: selectAssetReducer,
  resultCollections: combineReducers({
    videoasset: createResourcesReducer('videoassetsearchresult-collection'),
    imageasset: createResourcesReducer('imageassetsearchresult-collection'),
    audioasset: createResourcesReducer('audioassetsearchresult-collection'),
    shot: createResourcesReducer('shotsearchresult-collection'),
    collection: createResourcesReducer('collectionsearchresult-collection'),
  }),
  category: searchCategoryReducer,
  searchQuery: searchQueryReducer,
  deviceFilterCollectionLink: createRootLinkReducer(mlRel('search/devicefilters')),
  deviceFilterCollections: createResourcesReducer('devicefilter-collection'),
});
