import { Alert } from 'medialoopster/AlertMessage';

export const ARCHIVE_LAST_USER_ALERT = 'FRONTEND/ALERTS/ARCHIVE_LAST_USER_ALERT';

export interface ArchiveLastUserAlert {
  readonly type: typeof ARCHIVE_LAST_USER_ALERT;
}
export interface AlertsRootState {
  readonly active: ReadonlyArray<Alert>;
}
