import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext, ngettext } from 'medialoopster/Internationalization';
import { MenuItem } from 'medialoopster/components';
import { RemoveFromArchive } from 'medialoopster/icons';

import { archiveActions, archiveSelectors } from '../../../../state/modules/archive';
import { detailsSelectors } from '../../../../state/modules/details';
import { Asset } from '../../../../state/types/asset/unionTypes';

type Props = {
  readonly assets: ReadonlyArray<Asset>;
};

const RemoveFromArchiveActionMenuEntry = ({ assets }: Props) => {
  const dispatch = useDispatch();
  return (
    <MenuItem
      tooltip={ngettext(
        'Remove the selected asset from the archive queue',
        'Remove the selected assets from the archive queue',
        assets.length,
      )}
      onClick={() => {
        dispatch(archiveActions.revokeArchiveActivities(assets));
      }}
      icon={<RemoveFromArchive fontSize="small" />}
      label={gettext('Remove from archive queue')}
    />
  );
};

const useRemoveFromArchiveQueueActionMenuEntry = (
  assets: ReadonlyArray<Asset>,
): ReadonlyArray<ReactElement> => {
  const canArchiveAssets = useSelector(archiveSelectors.canArchiveAssets);
  const currentAssetType = useSelector(detailsSelectors.getCurrentAssetType);
  if (currentAssetType === 'collection') {
    return [];
  }
  if (assets.length === 0 || !canArchiveAssets) {
    return [];
  }
  return [<RemoveFromArchiveActionMenuEntry key="remove-from-archive-queue" assets={assets} />];
};

export default useRemoveFromArchiveQueueActionMenuEntry;
