import { DateTime } from 'luxon';

import { gettext, interpolate } from '../../Internationalization';
import { DefinedRange } from './types';

const getDefaultRanges = (): DefinedRange[] => [
  {
    label: gettext('Last hour'),
    startDate: (date = DateTime.local()) => date.minus({ hour: 1 }),
    endDate: () => DateTime.local(),
  },
  {
    label: interpolate(gettext('Last %(hours)s hours'), { hours: 4 }),
    startDate: () => DateTime.local().minus({ hours: 4 }),
    endDate: () => DateTime.local(),
  },
  {
    label: interpolate(gettext('Last %(hours)s hours'), { hours: 8 }),
    startDate: () => DateTime.local().minus({ hours: 8 }),
    endDate: () => DateTime.local(),
  },
  {
    label: gettext('Yesterday'),
    startDate: (date = DateTime.local()) => date.startOf('day').minus({ day: 1 }),
    endDate: (date = DateTime.local()) => date.endOf('day').minus({ day: 1 }),
  },
  {
    label: gettext('Today'),
    startDate: (date = DateTime.local()) => date.startOf('day'),
    endDate: (date = DateTime.local()) => date.endOf('day'),
  },
  {
    label: gettext('This week'),
    startDate: (date = DateTime.local()) => date.startOf('day').startOf('week'),
    endDate: (date = DateTime.local()) => date.endOf('day').endOf('week'),
  },
  {
    label: gettext('Last week'),
    startDate: (date = DateTime.local()) => date.startOf('day').minus({ week: 1 }).startOf('week'),
    endDate: (date = DateTime.local()) => date.endOf('day').minus({ week: 1 }).endOf('week'),
  },
  {
    label: interpolate(gettext('Last %(days)s days'), { days: 7 }),
    startDate: (date = DateTime.local()) => date.startOf('day').minus({ week: 1 }),
    endDate: (date = DateTime.local()) => date.endOf('day'),
  },
  {
    label: gettext('This month'),
    startDate: (date = DateTime.local()) => date.startOf('day').startOf('month'),
    endDate: (date = DateTime.local()) => date.endOf('day').endOf('month'),
  },
  {
    label: gettext('Last month'),
    startDate: (date = DateTime.local()) =>
      date.startOf('day').minus({ month: 1 }).startOf('month'),
    endDate: (date = DateTime.local()) => date.endOf('day').minus({ month: 1 }).endOf('month'),
  },
];

export const defaultRanges = getDefaultRanges();

export default {};
