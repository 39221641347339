import { SelectChangeEvent } from '@mui/material';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useMemo } from 'react';
import { RefCallBack, UseControllerProps } from 'react-hook-form';

import { SelectOption } from '../../Select';
import useWidgetContext from '../hooks/useWidgetContext';

type MultipleChoiceSelectProps = {
  id?: string;
  name: string;
  onChange?: (val: string[]) => void;
  label?: string;
  options?: ReadonlyArray<SelectOption>;
  readOnly?: boolean;
  showError?: boolean;
  error?: string;
  value?: ReadonlyArray<number | string>;
  inputRef?: RefCallBack;
};

const useStyles = makeStyles(() => ({
  select: {
    minHeight: 300,
  },
}));

const MultipleChoiceSelect: FC<MultipleChoiceSelectProps> = ({
  id,
  name,
  label = '',
  options = [],
  readOnly = false,
  error = '',
  onChange = () => {},
  value,
  inputRef,
}) => {
  const cssClasses = useStyles();
  const onChanges = (event: SelectChangeEvent<unknown>) => {
    const { options: selectOptions } = event.target as HTMLSelectElement;
    onChange(Array.from(selectOptions).flatMap((opt) => (opt.selected ? [opt.value] : [])));
  };
  return (
    <TextField
      inputRef={inputRef}
      disabled={readOnly}
      id={id}
      name={name}
      label={label}
      error={!!error}
      helperText={error || undefined}
      value={value}
      variant="outlined"
      size="small"
      select
      SelectProps={{
        native: true,
        multiple: true,
        classes: { select: cssClasses.select },
        onChange: onChanges,
      }}
      InputLabelProps={{ shrink: true }}
      FormHelperTextProps={{ role: 'alert', sx: { whiteSpace: 'pre' } }}
    >
      {options.map((option) => (
        <option disabled={readOnly} key={option.value} value={option.value}>
          {option.display}
        </option>
      ))}
    </TextField>
  );
};

type Props = {
  readonly name: string;
  readonly label: string;
  readonly defaultValue: ReadonlyArray<string | number>;
  readonly disabled?: boolean;
  readonly onChange?: (val: string) => void;
  readonly rules?: UseControllerProps['rules'];
  readonly options?: ReadonlyArray<SelectOption>;
};

const MultipleChoiceSelectWidget: FC<Props> = ({
  name,
  label,
  defaultValue,
  disabled = false,
  rules = {},
  options = [],
}: Props) => {
  const defaultVals = useMemo(() => defaultValue.map((el) => String(el)), [defaultValue]);
  const {
    onChange: onWidgetChange,
    value,
    error,
  } = useWidgetContext({
    name,
    defaultValue: defaultVals,
    rules: {
      ...rules,
    },
    disabled,
  });
  return (
    <MultipleChoiceSelect
      id={name}
      readOnly={disabled}
      name={name}
      label={label}
      value={value}
      onChange={(values) => {
        onWidgetChange(values);
      }}
      options={options}
      showError={!!error}
      error={error || undefined}
    />
  );
};

export default MultipleChoiceSelectWidget;
