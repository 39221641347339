import { Grid } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { IconRadioButtonGroup, IconRadioButtonGroupOption } from 'medialoopster/components';
import { DetailView, PlayerView, SearchView, TranscriptView } from 'medialoopster/icons';

import { detailsActions, detailsSelectors } from '../../state/modules/details';
import { ViewSidebarType, ViewType } from '../../state/modules/details/types';
import { transcriptSelectors } from '../../state/modules/rest/transcript';
import { AssetType } from '../../state/types/asset/baseTypes';

const canHaveTranscript = (assetTypeName: AssetType | null) =>
  assetTypeName === 'videoasset' || assetTypeName === 'audioasset';

type ViewOptionValue = `${ViewType}.${ViewSidebarType}`;

const ViewOptions: FC = () => {
  const dispatch = useDispatch();

  const canUseTranscript = useSelector(transcriptSelectors.canUseTranscript);
  const noTranscripts = !useSelector(detailsSelectors.getCurrentTranscript);
  const currentAssetTypeName = useSelector(detailsSelectors.getCurrentAssetTypeName);
  const { view, sidebarType } = useSelector(detailsSelectors.getLayout);
  const value: ViewOptionValue = `${view}.${sidebarType}`;

  const options = useMemo(() => {
    const viewOptions: Array<IconRadioButtonGroupOption<ViewOptionValue>> = [
      {
        title: gettext('Default view'),
        value: 'all.default',
        Icon: DetailView,
      },
      {
        title: gettext('Search view'),
        value: 'search.default',
        Icon: SearchView,
      },
      {
        title: gettext('Player view'),
        value: 'player.default',
        Icon: PlayerView,
      },
    ];
    if (canUseTranscript && canHaveTranscript(currentAssetTypeName)) {
      viewOptions.push({
        title: gettext('Transcript view'),
        value: 'player.transcript',
        Icon: TranscriptView,
        disabled: noTranscripts,
      });
    }
    return viewOptions;
  }, [canUseTranscript, currentAssetTypeName, noTranscripts]);

  const onChange = useCallback(
    (newValue: ViewOptionValue) => {
      const newView: ViewType = newValue.split('.')[0] as ViewType;
      const newSidebarType = newValue.split('.')[1] as ViewSidebarType;
      if (newView && newSidebarType) {
        dispatch(detailsActions.toggleView(newView, newSidebarType));
      }
    },
    [dispatch],
  );

  return (
    <Grid container alignItems="center">
      <Grid item>
        <IconRadioButtonGroup<ViewOptionValue>
          options={options}
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default ViewOptions;
