import Button from '@mui/material/Button';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { Close, Check } from 'medialoopster/icons';

const useStyles = makeStyles(() => ({
  btnDense: {
    height: '24px',
    width: '30px',
    minWidth: '30px',
  },
  btnGroupDense: {
    minWidth: '60px',
  },
}));

interface ButtonGroupProps {
  onSubmit: () => void;
  onClose: () => void;
  disableSubmit?: boolean;
  dense?: boolean;
}
const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  ({ onSubmit, onClose, disableSubmit, dense = false, ...props }: ButtonGroupProps, ref) => {
    const classes = useStyles();
    return (
      <MuiButtonGroup
        // Fix warning 'The `children` component of the Tooltip is not forwarding its props correctly.'
        // See https://github.com/mui/material-ui/issues/33476.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        disableElevation
        variant="contained"
        size="small"
        className={clsx({ [classes.btnGroupDense]: dense })}
        ref={ref}
      >
        <Button
          onClick={onSubmit}
          className={clsx({ [classes.btnDense]: dense })}
          color="success"
          aria-label={gettext('Submit')}
          disabled={disableSubmit}
        >
          <Check />
        </Button>
        <Button
          onClick={onClose}
          aria-label={gettext('Close')}
          className={clsx({ [classes.btnDense]: dense })}
          color="error"
        >
          <Close />
        </Button>
      </MuiButtonGroup>
    );
  },
);

export default ButtonGroup;
