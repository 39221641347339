import lodash from 'lodash';
import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { pgettext } from 'medialoopster/Internationalization';
import { Modal } from 'medialoopster/components';

import {
  addAssetsToCollectionActions,
  addAssetsToCollectionSelectors,
} from '../../../state/modules/addAssetsToCollection';
import { AddableAsset } from '../../../state/modules/addAssetsToCollection/types';
import { detailsActions } from '../../../state/modules/details';
import AssetSelectionWidget from './AssetSelectionWidget';
import CollectionSuggestWidget from './CollectionSuggestWidget';
import ProductionSelectWidget from './ProductionSelectWidget';

export interface FormData {
  collection: string;
  production: string;
  selectedAssets: ReadonlyArray<string>;
}

const getSubmitAssets = (
  selectedAssets: Set<string>,
  availableAssets: ReadonlyArray<AddableAsset>,
) => availableAssets.filter((el) => selectedAssets.has(el.value));

interface AddToCollectionModalProps {
  readonly show: boolean;
  readonly onClose: () => void;
  readonly assets: ReadonlyArray<AddableAsset>;
}

const AddToCollectionModal: FC<AddToCollectionModalProps> = ({
  show,
  onClose,
  assets,
}: AddToCollectionModalProps) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { success: apiSuccess, errors: apiErrors } = useSelector(
    addAssetsToCollectionSelectors.getApiFeedback,
  );
  const [apiErrs, setApiErrs] = useState<string[]>([]);
  const methods = useForm<FormData>({
    mode: 'onChange',
  });

  const {
    handleSubmit,
    getValues,
    setError,
    reset,
    trigger,
    clearErrors,
    formState: { isValid },
    watch,
  } = methods;

  const onModalClose = useCallback(() => {
    // Reset modal, errors & close
    dispatch(addAssetsToCollectionActions.resetModalApiFeedback());
    clearErrors();
    setApiErrs([]);
    reset();
    onClose();
  }, [reset, onClose, dispatch, clearErrors, setApiErrs]);

  const addableCollections = useSelector(
    addAssetsToCollectionSelectors.getCurrentAddableCollections,
  );

  useEffect(() => {
    // Handle success from the api, close modal and load collection in default search view.
    if (apiSuccess) {
      // Load collection for preview
      const selectedCollection = getValues('collection');
      const collection = addableCollections.find((el) => el.display === selectedCollection);
      if (collection) {
        dispatch(detailsActions.toggleView('all', 'default'));
        dispatch(detailsActions.setCurrentAsset('collection', collection.href));
      }
      onModalClose();
    }
  }, [apiSuccess, onModalClose, dispatch, getValues, addableCollections]);

  useEffect(() => {
    // Handle errors from the api or close modal and load preview of assetCollection.
    if (!lodash.isEmpty(apiErrors) && show) {
      // Handle field errors
      const assetErrKeys = new Map(assets.map(({ value, display }) => [value, display]));
      const assetSelectErrs = lodash.mapValues(
        lodash.pickBy(apiErrors, (_, key) => assetErrKeys.has(key)),
        (a, b) => {
          const assetDisp = assetErrKeys.get(b);
          return a.map((el) => `[${assetDisp}] ${el}`);
        },
      );
      const assetFieldErrKey = '/_links/assets_list';
      const assetErrMsgs = [
        ...lodash.values(assetSelectErrs),
        ...(apiErrors[assetFieldErrKey] || []),
      ];
      if (assetErrMsgs.length > 0) {
        setError('selectedAssets', {
          type: 'validate',
          message: assetErrMsgs.join('\n'),
        });
      }
      // Handle non asset errors
      const unhandledErrKeys = new Set([...assets.map(({ value }) => value), assetFieldErrKey]);
      const nonAssetSelectErrs = lodash
        .values(lodash.omitBy(apiErrors, (_, key) => unhandledErrKeys.has(key)))
        .flatMap((val) => val);
      setApiErrs(nonAssetSelectErrs);
    }
  }, [apiErrors, dispatch, assets, setError, show]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (apiSuccess === false) {
        // Reset modal error states when
        dispatch(addAssetsToCollectionActions.resetModalApiFeedback());
        setApiErrs([]);
        clearErrors();
        trigger();
      }
      if (name === 'production') {
        const collectionname = value.collection;
        dispatch(
          addAssetsToCollectionActions.findAddableCollection(
            collectionname || '',
            value.production || '',
          ),
        );
      }
      if (name === 'collection') {
        dispatch(
          addAssetsToCollectionActions.findAddableCollection(
            value.collection || '',
            value.production || '',
          ),
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, clearErrors, setApiErrs, apiSuccess, trigger]);

  const onSubmit: SubmitHandler<FormData> = (fromData) => {
    const collSel = addableCollections.find((el) => el.display === fromData.collection);
    if (!collSel) {
      return;
    }
    const addableAssetsSelected = getSubmitAssets(new Set(fromData.selectedAssets), assets);
    dispatch(addAssetsToCollectionActions.addAssetsToCollection(collSel, addableAssetsSelected));
  };
  return (
    <FormProvider {...methods}>
      <Modal id="add-assets-to-collection-list-modal" open={show} onClose={onModalClose}>
        <Modal.Header
          id="add-assets-to-collection-list-modal-label"
          headerTitle={pgettext('Title-style capitalization', 'Add Assets to Collection')}
          onClose={onModalClose}
        />
        <Modal.Body id="add-assets-to-collection-list-form">
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            {apiErrs.map((errMsg) => (
              <Modal.Body.Notifier errorMsg={errMsg} key={errMsg} />
            ))}
            <>
              <ProductionSelectWidget />
              <CollectionSuggestWidget />
              <AssetSelectionWidget assets={assets} />
            </>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.CancelButton
            title={pgettext('Form cancel button title', 'Cancel this form')}
            onClick={onModalClose}
          />
          <Modal.Footer.SubmitButton
            onClick={handleSubmit(onSubmit)}
            label={pgettext('Form submit button label', 'Add')}
            title={pgettext('Form submit button title', 'Submit this form')}
            disabled={!isValid}
          />
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
};

export default AddToCollectionModal;
