import { Action } from 'redux';
import { Epic, ofType, combineEpics } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  ResourceLoadedAction,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
  ResourceOptionsLoadedAction,
  ReceiveRootResource,
  RECEIVE_ROOT_RESOURCE,
  getLinkHref,
  mlRel,
} from 'medialoopster/rest';

import { RESTRootState } from '../types';

/**
 * Load the transcript options as soon as the root resource is loaded.
 *
 * The options are needed to determine the permissions of the user to work with transcripts.
 */
export const fetchTranscriptOptionsEpic: Epic<
  Action,
  ResourceOptionsLoadedAction<string> | UnauthorizedRequestAction,
  RESTRootState,
  RESTEpicDependencies
> = (action$, _, { fetchOptions }) =>
  action$.pipe(
    ofType<Action, ReceiveRootResource['type'], ReceiveRootResource>(RECEIVE_ROOT_RESOURCE),
    switchMap(({ payload: { root } }) => {
      const href = getLinkHref(root, mlRel('transcripts'));
      if (!href) {
        return EMPTY;
      }
      return fetchOptions(href, undefined, undefined, {
        Accept: 'application/hal+json; version=3',
      });
    }),
  );

export default combineEpics<
  Action,
  UnauthorizedRequestAction | ResourceLoadedAction | ResourceOptionsLoadedAction<string>,
  RESTRootState,
  RESTEpicDependencies
>(fetchTranscriptOptionsEpic);
