import { Link, ListItemIcon, Menu, MenuItem as MuiMenuItem, SxProps, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ReactNodeArray } from 'prop-types';
import { FC, forwardRef, MouseEvent, ReactNode, useState } from 'react';

interface CommonProps {
  readonly label: string;
  readonly tooltip?: string;
  readonly icon?: ReactNode;
  readonly endIcon?: ReactNode;
  readonly disabled?: boolean;
  readonly selected?: boolean;
  readonly target?: string;
  style?: SxProps<Theme>;
}

interface LinkItemProps extends CommonProps {
  // if href is present then onClick is optional for additional side effects
  readonly href: string;
  readonly onClick?: () => void;
  readonly submenuItems?: undefined;
  readonly onSubmenuClose?: undefined;
}

interface ClickableItemProps extends CommonProps {
  // if href is missing then onClick is required
  readonly href?: undefined;
  readonly onClick: () => void;
  readonly onSubmenuClose?: undefined;
  readonly submenuItems?: undefined;
}

interface SubMenuItemProps extends CommonProps {
  // if submenu is present submenuItems is required
  readonly href?: undefined;
  readonly onClick?: undefined;
  readonly endIcon?: ReactNode;
  readonly submenuItems: ReactNodeArray;
  readonly onSubmenuClose: () => void;
}

type Props = LinkItemProps | ClickableItemProps | SubMenuItemProps;

const MenuItem: FC<Props> = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      label,
      icon,
      endIcon,
      onClick,
      href,
      target = '_blank',
      disabled = false,
      selected = false,
      tooltip = '',
      onSubmenuClose = undefined,
      submenuItems = undefined,
      style,
    }: Props,
    ref,
  ) => {
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleSubMenuClose = () => {
      setSubMenuAnchorEl(null);
      if (onSubmenuClose) {
        onSubmenuClose();
      }
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      if (submenuItems && submenuItems.length !== 0) {
        setSubMenuAnchorEl(event.currentTarget);
      }
      if (onClick) {
        onClick();
      }
    };

    const submenu = submenuItems ? (
      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleSubMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClick={handleSubMenuClose}
      >
        {submenuItems}
      </Menu>
    ) : undefined;
    return (
      <>
        <Tooltip title={tooltip} enterDelay={800} enterNextDelay={400}>
          <MuiMenuItem
            sx={style}
            ref={ref}
            dense
            disabled={disabled || (submenuItems && submenuItems.length === 0)}
            selected={selected}
            // Link is fine since it behaves like a button if href is undefined
            component={Link}
            href={disabled ? undefined : href}
            target={target}
            onClick={disabled ? undefined : handleClick}
          >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            {label}
            {endIcon && (
              <ListItemIcon sx={{ position: 'absolute', right: 0 }}>{endIcon}</ListItemIcon>
            )}
          </MuiMenuItem>
        </Tooltip>
        {submenu}
      </>
    );
  },
);

export default MenuItem;
