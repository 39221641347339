import { FC, useState } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { DropdownSelect, IconButton } from 'medialoopster/components';
import { Audio, Image, Video, Collection } from 'medialoopster/icons';

import { SearchResultAssetType } from '../../state/types/asset/baseTypes';
import AssetIcon from '../../ui/components/AssetIcon';

type Props = {
  readonly searchCategory: SearchResultAssetType;
  readonly onChange: (searchCategory: SearchResultAssetType) => void;
};

const SearchCategorySelect: FC<Props> = ({ searchCategory, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} aria-label={gettext('Asset type')}>
        <AssetIcon typeName={searchCategory} />
      </IconButton>
      <DropdownSelect
        id="search-asset-type-select"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={[
          {
            key: 'video',
            onSelect: () => onChange('videoasset'),
            selected: searchCategory === 'videoasset',
            label: gettext('Video'),
            icon: <Video />,
          },
          {
            key: 'audio',
            onSelect: () => onChange('audioasset'),
            selected: searchCategory === 'audioasset',
            label: gettext('Audio'),
            icon: <Audio />,
          },
          {
            key: 'image',
            onSelect: () => onChange('imageasset'),
            selected: searchCategory === 'imageasset',
            label: gettext('Images'),
            icon: <Image />,
          },
          {
            key: 'collection',
            onSelect: () => onChange('collection'),
            selected: searchCategory === 'collection',
            label: gettext('Collections'),
            icon: <Collection />,
          },
        ]}
      />
    </>
  );
};
export default SearchCategorySelect;
