import { Box } from '@mui/material';
import { FC } from 'react';

import { gettext } from 'medialoopster/Internationalization';
import { NoResults } from 'medialoopster/icons';

const NoResultsFound: FC = () => (
  <Box sx={{ pt: 20, textAlign: 'center', color: 'text.disabled' }}>
    <Box sx={{ fontSize: 'h1.fontSize', mb: -7 }}>
      <NoResults fontSize="inherit" />
    </Box>
    <Box sx={{ fontSize: 'h3.fontSize' }}>{gettext('No results')}</Box>
  </Box>
);
export default NoResultsFound;
