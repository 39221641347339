/* eslint-disable import/prefer-default-export */
import { ReadonlyRecord } from 'medialoopster/types';

import {
  ADD_ASSETS_TO_COLLECTION,
  ADD_ASSETS_TO_COLLECTION_API_RESPONSE,
  AddAssetsToCollection,
  AddAssetsToCollectionApiResponse,
  AddableAsset,
  AddableCollection,
  CHECK_ASSETS_COLLECTIONS_AVAILABILITY,
  CheckAssetCollectionAvailabilityForProduction,
  FIND_ADDABLE_COLLECTIONS,
  FindAddableCollection,
  RESET_MODAL_API_FEEDBACK,
  ResetModalApiFeedback,
  SET_AVAILABLE_PRODUCTION_SEARCH_URIS,
  SET_CURRENT_ADDABLE_COLLECTION_SEARCH_URI,
  SetAvailableProductionSearchURIs,
  SetCurrentAddableCollectionSearchURI,
} from './types';

export const findAddableCollection = (name: string, production: string): FindAddableCollection => ({
  type: FIND_ADDABLE_COLLECTIONS,
  payload: {
    name,
    production,
  },
});

export const setCurrentAddableCollectionSearchURI = (
  searchURI: string,
): SetCurrentAddableCollectionSearchURI => ({
  type: SET_CURRENT_ADDABLE_COLLECTION_SEARCH_URI,
  payload: {
    searchURI,
  },
});

export const addAssetsToCollection = (
  addableCollection: AddableCollection,
  addableAssets: ReadonlyArray<AddableAsset>,
): AddAssetsToCollection => ({
  type: ADD_ASSETS_TO_COLLECTION,
  payload: {
    addableCollection,
    addableAssets,
  },
});

export const addAssetsToCollectionApiResponse = (
  success: boolean,
  errors: ReadonlyRecord<string, ReadonlyArray<string>>,
): AddAssetsToCollectionApiResponse => ({
  type: ADD_ASSETS_TO_COLLECTION_API_RESPONSE,
  payload: {
    success,
    errors,
  },
});

export const resetModalApiFeedback = (): ResetModalApiFeedback => ({
  type: RESET_MODAL_API_FEEDBACK,
});

export const checkAssetCollectionAvailabilityForProduction =
  (): CheckAssetCollectionAvailabilityForProduction => ({
    type: CHECK_ASSETS_COLLECTIONS_AVAILABILITY,
  });

export const setAvailableProductionSearchURIs = (
  availableProductionSearchURIs: ReadonlyArray<string>,
  error: string | null = null,
): SetAvailableProductionSearchURIs => ({
  type: SET_AVAILABLE_PRODUCTION_SEARCH_URIS,
  payload: { availableProductionSearchURIs, error },
});
