/* eslint-disable import/prefer-default-export */
import { AssetType } from '../../state/types/asset/baseTypes';

/**
 * Get share url from asset href
 * @param id Id of a shareable mediasset.
 * @param assetType Asset type to share.
 */
export const getShareURL = (id: number, assetType: AssetType): string =>
  `${window.location.protocol}//${window.location.host}${window.location.pathname}?asset=${assetType}&id=${id}`;
