import { FC } from 'react';

import { KeywordsInput } from 'medialoopster/components';

type Props = {
  readonly categoryName: string;
  readonly selectedKeywords: ReadonlyArray<string>;
  readonly availableKeywords: ReadonlyArray<string>;
  readonly onAddKeywords: (keywords: ReadonlyArray<string>) => void;
  readonly onRemoveKeyword: (keyword: string) => void;
};

const CategoryKeywordsInput: FC<Props> = ({
  categoryName,
  selectedKeywords,
  availableKeywords,
  onAddKeywords,
  onRemoveKeyword,
}: Props) => {
  return (
    <KeywordsInput
      label={categoryName}
      selectedKeywords={selectedKeywords}
      availableKeywords={availableKeywords}
      onAddKeywords={onAddKeywords}
      getKeywordChipProps={(keyword) => ({ onDelete: () => onRemoveKeyword(keyword) })}
    />
  );
};

export default CategoryKeywordsInput;
