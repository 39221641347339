import { useSubscription } from 'observable-hooks';
import { FC, useMemo } from 'react';
import { combineLatest, timer, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { useVideoController } from './VideoControllerContext';

/**
 * Tells VideoController to refresh fast changing properties of a video
 * to ensure good user feedback.
 *
 * This component implements the polling scheme for the VideoController refresh ,particularly time and frame,
 * while the Player is playing.
 *
 * There must exist exactly one VideoControllerUpdater per VideoControllerContext
 * and this VideoControllerUpdater will then be responsible to advance/update the frames
 * and time of the VideoController.
 *
 * ## Details:
 * Video events like `progress` or `timeupdate` don't fire often enough.
 * So we use polling for a responsive ui.
 * This design was chosen to enable smooth and fine-grained updates to the VideoController
 * that match the current frame rate. The matching events on the video player do not fire
 * often enough to show frame-precise timestamps.
 *
 * An alternative design that had been considered is to fold this update logic into the
 * VideoController and to start the polling logic in i.e. the constructor of VideoController.
 * The VideoControllerUpdater approach has been choosen for the moment because it allows for
 * an easy switch to different updating schemes in a react component like style.
 */
const VideoControllerUpdater: FC = () => {
  const videoController = useVideoController();
  const update$ = useMemo(() => {
    return combineLatest([videoController.paused$, videoController.fps$]).pipe(
      switchMap(([paused, fps]) => {
        if (!paused && fps) {
          const updateInterval = Math.max(5, 1000 / fps);
          return timer(0, updateInterval);
        }
        return of(-1);
      }),
    );
  }, [videoController]);
  useSubscription(update$, () => {
    videoController.refreshPlayer();
  });
  return <></>;
};

export default VideoControllerUpdater;
