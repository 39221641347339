import { ChangeEvent, Dispatch, FC, MouseEvent, useReducer, useEffect, memo } from 'react';

import { RowData } from '../types';
import InternalPagination from './InternalPagination';
import { usePaginationChangeContext } from './PaginationContextProvider';
import { Pagination } from './types';

const CHANGE_PAGE = 'CHANGE_PAGE';
const CHANGE_ROWS_PER_PAGE = 'CHANGE_ROWS_PER_PAGE';
const UPDATE_PAGINATION_SLICES = 'UPDATE_PAGINATION_SLICES';

interface ChangePage {
  readonly type: typeof CHANGE_PAGE;
  readonly payload: {
    readonly nextPage: number;
  };
}

interface ChangeRowsPerPage {
  readonly type: typeof CHANGE_ROWS_PER_PAGE;
  readonly payload: {
    readonly rowsPerPage: number;
  };
}

interface UpdatePaginationSlices {
  readonly type: typeof UPDATE_PAGINATION_SLICES;
  readonly payload: {
    readonly newTableData: ReadonlyArray<RowData>;
  };
}

interface PaginationState extends Pagination {
  readonly dataSlice: ReadonlyArray<RowData>;
  readonly rowData: ReadonlyArray<RowData>;
}

const changePage = (nextPage: number): ChangePage => ({
  type: CHANGE_PAGE,
  payload: { nextPage },
});
const changeRowsPerPage = (rowsPerPage: number): ChangeRowsPerPage => ({
  type: CHANGE_ROWS_PER_PAGE,
  payload: { rowsPerPage },
});
const updatePaginationSlices = (newTableData: ReadonlyArray<RowData>): UpdatePaginationSlices => ({
  type: UPDATE_PAGINATION_SLICES,
  payload: { newTableData },
});

const tablePaginationReducer = (
  state: PaginationState,
  action: ChangeRowsPerPage | ChangePage | UpdatePaginationSlices,
): PaginationState => {
  switch (action.type) {
    case CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage,
        currentPage: 0,
        dataSlice: state.rowData.slice(0, action.payload.rowsPerPage),
      };
    case CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload.nextPage,
        dataSlice: state.rowData.slice(
          action.payload.nextPage * state.rowsPerPage,
          action.payload.nextPage * state.rowsPerPage + state.rowsPerPage,
        ),
      };
    case UPDATE_PAGINATION_SLICES:
      return {
        ...state,
        rowData: action.payload.newTableData,
        dataSlice: action.payload.newTableData.slice(
          state.currentPage * state.rowsPerPage,
          state.currentPage * state.rowsPerPage + state.rowsPerPage,
        ),
      };
    default:
      return state;
  }
};

interface Props {
  readonly rowData: ReadonlyArray<RowData>;
  readonly rowsPerPageOptions?: number[];
  readonly disabledTableInfo?: string;
  readonly emptyChoicesInfo?: string;
}

const StaticPagination: FC<Props> = ({
  rowData,
  rowsPerPageOptions = [10, 25, 50, 100],
  disabledTableInfo = '',
  emptyChoicesInfo = '',
}: Props) => {
  const [paginationState, dispatchPaginationAction]: [
    PaginationState,
    Dispatch<ChangePage | ChangeRowsPerPage | UpdatePaginationSlices>,
  ] = useReducer(tablePaginationReducer, {
    currentPage: 0,
    rowsPerPage: rowsPerPageOptions[0],
    dataSlice: rowData.slice(0, rowsPerPageOptions[0]),
    rowData,
  });
  const { currentPage, rowsPerPage, dataSlice } = paginationState;
  const onChange = usePaginationChangeContext();

  useEffect(() => {
    dispatchPaginationAction(updatePaginationSlices(rowData));
  }, [rowData, dispatchPaginationAction]);

  useEffect(() => {
    onChange(dataSlice);
  }, [dataSlice, onChange]);

  const handleChangePage = (_: MouseEvent<HTMLButtonElement> | null, nextPage: number) => {
    dispatchPaginationAction(changePage(nextPage));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    dispatchPaginationAction(changeRowsPerPage(+event.target.value));
  };

  return (
    <InternalPagination
      totalCount={rowData.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      rowsPerPage={rowsPerPage}
      currentPage={currentPage}
      rowsPerPageOptions={rowsPerPageOptions}
      disabledTableInfo={disabledTableInfo}
      emptyChoicesInfo={emptyChoicesInfo}
    />
  );
};

export default memo(StaticPagination);
