import { FC, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { gettext, interpolate, pgettext } from 'medialoopster/Internationalization';
import { Input, Modal } from 'medialoopster/components';
import formatUserDisplayName from 'medialoopster/formatUserDisplayName';

import { isNameValid } from '../../../businessRules/models/FavoriteListsHelpers';
import {
  favoritesActions,
  favoritesSelectors,
  favoritesTypes,
} from '../../../state/modules/favorites';
import { usersSelectors } from '../../../state/modules/users';
import MultipleChoiceSelect from '../../../ui/components/MultipleChoiceSelect/Uncontrolled';
import { FormEvent } from './types';

interface CreateListModalProps {
  show: boolean;
  onClose: () => void;
}

interface DuplicateError {
  key: number;
  title: string;
  errorMsg: string;
}

const CreateListModal: FC<CreateListModalProps> = ({ show, onClose }: CreateListModalProps) => {
  const dispatch = useDispatch();
  const users = useSelector(usersSelectors.getAllOtherUsers);
  const userFavoriteLists = useSelector(favoritesSelectors.getOwnedFavoriteListsForUser);

  const sharedWithUsers = users.map((user) => ({
    id: user.id,
    displayName: formatUserDisplayName(user),
  }));
  const defaultFavoriteList: favoritesTypes.FavoritesListPostResource = {
    name: '',
    shared_with: [],
  };
  const [createList, setCreatedList] = useState({ ...defaultFavoriteList });

  const [value, setValue] = useState('');
  const [showError, setShowError] = useState(false);
  const [dataErrors, setDataErrors] = useState<Array<DuplicateError>>([]);

  const formRef = useRef<HTMLFormElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const onDataErrorClose = (key: number) => {
    const updatedErrors = [...dataErrors];
    const removeIndex = updatedErrors.findIndex((elem) => elem.key === key);
    updatedErrors.splice(removeIndex, 1);
    setDataErrors([...updatedErrors]);
  };

  const validateUserInput = () => isNameValid(createList.name);

  const disableFormPropagate = (event: FormEvent): void => {
    // Disable propagation (page reload) on enter
    if (formRef.current === event.target) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const isDuplicateName = (newList: favoritesTypes.FavoritesListPostResource) =>
    userFavoriteLists.some((list) => list.name === newList.name);

  const resetUserInput = () => {
    setValue('');
    setCreatedList({ ...defaultFavoriteList });
  };

  const reFocusInput = () => {
    nameInputRef?.current?.focus();
  };

  const onCreateModalSubmit = (event: FormEvent): void => {
    disableFormPropagate(event);
    const isDuplicate = isDuplicateName(createList);
    if (isDuplicate) {
      setDataErrors([
        ...dataErrors,
        {
          key: dataErrors.length + 1,
          title: pgettext('Noun', 'Duplicate'),
          errorMsg: interpolate(
            "You already have a list called '%(name)s'! Please choose a different name.",
            { name: createList.name },
          ),
        },
      ]);
      resetUserInput();
      reFocusInput();
      setShowError(false);
      return;
    }
    const isValidInput = validateUserInput();
    if (isValidInput) {
      setShowError(false);
      dispatch(favoritesActions.createFavoriteList(createList));
      onClose();
    } else {
      reFocusInput();
      setShowError(true);
    }
  };

  const onNameChange = (name: string) => {
    setValue(name);
    setShowError(false);
    setCreatedList({ ...createList, name });
  };

  const onSharedWithChange = (values: string[]) => {
    setCreatedList({
      ...createList,
      shared_with: values.map((val) => +val),
    });
  };

  return (
    <Modal id="favorites-list-modal" open={show} onClose={onClose}>
      <Modal.Header
        id="favorites-list-modal-label"
        headerTitle={pgettext('Title-style capitalization', 'Create a New Favorites List')}
        onClose={onClose}
      />
      <Modal.Body id="create-favorites-list-form">
        <form ref={formRef} onSubmit={onCreateModalSubmit}>
          {dataErrors.map((elem) => (
            <Modal.Body.Notifier
              onClose={() => {
                onDataErrorClose(elem.key);
              }}
              title={elem.title}
              errorMsg={elem.errorMsg}
              key={elem.key}
            />
          ))}
          <Input
            id="favoritelist-name"
            name="favoritelist-name"
            label={gettext('Name')}
            value={value}
            inputRef={nameInputRef}
            onChange={onNameChange}
            autoFocus
            showError={showError}
            errorMsg={gettext('Please enter a valid name.')}
            maxLength={255}
          />
          <MultipleChoiceSelect
            id="favoritelist-shared_with"
            name="favoritelist-shared_with"
            label={gettext('Shared with')}
            choices={sharedWithUsers}
            onChange={onSharedWithChange}
            choiceDisplay="displayName"
            choiceValue="id"
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.CancelButton
          title={pgettext('Form cancel button title', 'Cancel this form')}
          onClick={onClose}
        />
        <Modal.Footer.SubmitButton
          onClick={onCreateModalSubmit}
          label={pgettext('Form submit button label', 'Create')}
          title={pgettext('Form submit button title', 'Submit this form')}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CreateListModal;
