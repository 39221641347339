import { ReactNode, FC, memo } from 'react';

// Note: use sharedProductionLabels from selector
import { getEmbeddedSharedWithProductions } from '../../../businessRules/models/asset/utils';
import { MediaAsset } from '../../../state/types/asset/unionTypes';
import IsArchivedStatus from './IsArchivedStatus';
import IsSharedStatus from './IsSharedStatus';
import StatusBar from './StatusBar';
import StatusElement from './StatusElement';

type Props = {
  readonly assetFormat?: ReactNode;
  readonly asset: MediaAsset;
  readonly isWide: boolean;
  readonly renderAssetStatusIcon: () => JSX.Element;
  readonly statusTitle: string;
  readonly statusLevel: string;
  readonly showLabelAssetStatus: boolean;
  readonly showShared?: boolean;
};

const MediaAssetStatusBar: FC<Props> = ({
  assetFormat = undefined,
  asset,
  isWide,
  renderAssetStatusIcon,
  statusTitle,
  statusLevel,
  showLabelAssetStatus,
  showShared = true,
}: Props) => {
  return (
    <StatusBar>
      {assetFormat}
      {asset.is_archive ? <IsArchivedStatus showLabel={isWide} /> : null}
      {showShared && getEmbeddedSharedWithProductions(asset).length > 0 ? (
        <IsSharedStatus showLabel={isWide} sharedWith={getEmbeddedSharedWithProductions(asset)} />
      ) : null}
      <StatusElement
        renderIcon={renderAssetStatusIcon}
        title={statusTitle}
        label={statusTitle}
        statusLevel={statusLevel}
        showLabel={showLabelAssetStatus}
      />
    </StatusBar>
  );
};

export default memo(MediaAssetStatusBar);
