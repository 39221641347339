import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import { VideoRootState } from './types';

export default combineReducers<VideoRootState>({
  assets: createResourcesReducer('videoasset'),
  sequences: createResourcesReducer('sequence'),
  sequencesCollections: createResourcesReducer('videoasset-sequences'),
  shots: createResourcesReducer('shot'),
  subtitles: createResourcesReducer('subtitle'),
  subtitleCollections: createResourcesReducer('videoasset-subtitles'),
  projectsCollections: createResourcesReducer('videoasset-projects'),
  successorsCollections: createResourcesReducer('videoasset-successors'),
  annotationsCollections: createResourcesReducer('videoasset-annotations'),
});
