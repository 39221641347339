/**
 * This module provides a plugin to navigate by double-clicking transcript units
 *
 * The plugins main usage is to navigate the player to the start time of a unit when the unit
 * is double-clicked.
 */
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { $getNodeByKey, LexicalEditor } from 'lexical';
import React, { useCallback } from 'react';

import { useTranscriptEditorContext } from '../components/TranscriptEditorContext';
import { Decimal } from '../decimal';
import { $isTranscriptUnitNode, TranscriptUnitNode } from '../nodes/TranscriptUnitNode';

export interface TranscriptNavigationPluginProps {
  onNavigation: (time: Decimal) => void;
}

/**
 * A plugin that informs a callback about the start time of double-clicked transcript units.
 *
 * The plugin listens on double-click events on transcript units and enables selecting the
 * whole unit when the editor is not in read-only mode. When a unit is double-clicked the
 * `onNavigation` callback (passed as a prop) is called with the start-time of the clicked unit.
 *
 * @param onNavigation A callback that is called with the start-time of a unit after it has been
 * double-clicked.
 */
export const TranscriptNavigationPlugin: React.FC<TranscriptNavigationPluginProps> = ({
  onNavigation,
}) => {
  const { isEditable } = useTranscriptEditorContext();

  const onDoubleClick = useCallback(
    (event: Event, editor: LexicalEditor, nodeKey: string) => {
      event.preventDefault();
      event.stopPropagation();
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isTranscriptUnitNode(node)) {
          onNavigation(node.getTime());
          if (isEditable) {
            node.select(0);
          } else {
            // selection of the node does not work when not editable
            // thus clear all selections of the current window to prevent
            // the whole line from being selected
            window.getSelection()?.removeAllRanges();
          }
        }
      });
    },
    [isEditable, onNavigation],
  );

  return (
    <NodeEventPlugin
      nodeType={TranscriptUnitNode}
      eventType={'dblclick'}
      eventListener={onDoubleClick}
    />
  );
};

export default TranscriptNavigationPlugin;
