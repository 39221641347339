import { useMemo, ReactElement } from 'react';
import {
  Controller,
  FieldValues,
  useFormContext,
  FieldPathByValue,
  PathValue,
} from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';

import { gettext } from 'medialoopster/Internationalization';
import { makeSelectOptions, Select } from 'medialoopster/components';

import { productionsSelectors } from '../../../state/modules/productions';
import { Production } from '../../../state/modules/productions/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RE = ReactElement<any, any>;

/**
 * A widget to select a production from the store.
 *
 * The widget registers itself with a react-hook-form provided via a form context under the
 * given name. The value under this name will be the selected production or null if no valid
 * production has been selected. the possible productions are the writable productions available
 * in the redux store.
 *
 * @param id The id of the rendered control
 * @param name The name of the field registered with the react-hook-form form.
 * @constructor
 */
export const WritableProductionSelectWidget = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, Production | null> = FieldPathByValue<
    TFieldValues,
    Production | null
  >,
>({
  id,
  name,
}: {
  id: string;
  name: TPath;
}): RE => {
  const methods = useFormContext<TFieldValues>();
  const { control } = methods;

  const writeableProductions = useSelector(
    productionsSelectors.getWriteableProductionsWithProjectDevice,
    shallowEqual,
  );

  const selectProductions = useMemo(
    () =>
      makeSelectOptions(
        writeableProductions.map(({ name: productionName, id: productionId }) => ({
          name: productionName,
          id: productionId.toString(),
        })),
      ),
    [writeableProductions],
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: true,
          message: gettext('No production selected.'),
        },
      }}
      defaultValue={null as PathValue<TFieldValues, TPath>}
      render={({ field, fieldState: { error } }) => (
        <Select
          id={id}
          name={field.name}
          value={field.value?.id.toString() ?? '0'}
          inputRef={field.ref}
          onBlur={field.onBlur}
          label={gettext('Production')}
          choices={selectProductions}
          onChange={(value) => {
            const productionId = parseInt(value, 10);
            const production = writeableProductions.find((wp) => wp.id === productionId) ?? null;
            field.onChange(production);
          }}
          showError={!!error}
          errorMsg={error ? error.message : ''}
        />
      )}
    />
  );
};

export default WritableProductionSelectWidget;
