import { ResourceActionV3 } from 'medialoopster/rest';
import { ReadonlyRecord } from 'medialoopster/types';

import {
  OpenBulkEditModal,
  OPEN_BULK_EDIT_MODAL,
  CloseBulkEditModal,
  CLOSE_BULK_EDIT_MODAL,
  SubmitBulkEdit,
  SUBMIT_BULK_EDIT,
  SET_BULK_EDIT_FIELD_ERRORS,
  SetBulkEditFieldErrors,
  LockAssetsSuccess,
  LOCK_ASSETS_SUCCESS,
  BulkEditResource,
  ASSETS_BULK_EDIT_FIELDS_LOADED,
  AssetsBulkEditFieldsLoaded,
  SetBulkEditKeywordsFieldErrors,
  KeywordsErrors,
  SET_BULK_EDIT_KEYWORDS_FIELD_ERRORS,
} from './types';

export const openBulkEditModal = (): OpenBulkEditModal => ({
  type: OPEN_BULK_EDIT_MODAL,
});

export const closeBulkEditModal = (): CloseBulkEditModal => ({
  type: CLOSE_BULK_EDIT_MODAL,
});

export const setBulkEditFieldErrors = (
  errors: ReadonlyRecord<string, ReadonlyArray<string>>,
): SetBulkEditFieldErrors => ({
  type: SET_BULK_EDIT_FIELD_ERRORS,
  payload: {
    errors,
  },
});

export const setBulkEditKeywordsFieldErrors = (
  errors: ReadonlyRecord<string, KeywordsErrors>,
): SetBulkEditKeywordsFieldErrors => ({
  type: SET_BULK_EDIT_KEYWORDS_FIELD_ERRORS,
  payload: {
    errors,
  },
});

export const submitBulkEdit = (bulkEditResource: BulkEditResource): SubmitBulkEdit => ({
  type: SUBMIT_BULK_EDIT,
  payload: {
    bulkEditResource,
  },
});

export const lockAssetsSuccess = (): LockAssetsSuccess => ({
  type: LOCK_ASSETS_SUCCESS,
});

export const assetsBulkEditFieldsLoaded = (
  fields: ResourceActionV3,
): AssetsBulkEditFieldsLoaded => ({
  type: ASSETS_BULK_EDIT_FIELDS_LOADED,
  payload: {
    fields,
  },
});
