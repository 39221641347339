import { combineReducers } from 'redux';

import { createResourcesReducer } from 'medialoopster/rest';

import {
  SHOW_FAVORITES,
  HIDE_FAVORITES,
  FAVORITE_LISTS_OPTIONS_LOADED,
  SELECT_FAVORITE_LIST,
  SET_SHARED_ACTIVE_LISTS,
  REMOVE_SHARED_ACTIVE_FAVORITE_LIST,
  ShowFavorites,
  HideFavorites,
  SelectFavoriteList,
  SetSharedActiveLists,
  RemoveSharedActiveList,
  FavoriteListsOptionsLoaded,
  ModelOptionsState,
  SET_LIST_ASSETS_LOADING,
  SetListAssetsLoading,
  FavoritesRootState,
} from './types';

export const favoritesActiveReducer = (
  state = false,
  { type }: ShowFavorites | HideFavorites,
): boolean => {
  switch (type) {
    case SHOW_FAVORITES:
      return true;
    case HIDE_FAVORITES:
      return false;
    default:
      return state;
  }
};

const favoritesSelectedListReducerState = +`${sessionStorage.getItem('favoriteListId')}` || null;

export const favoritesSelectedListReducer = (
  state = favoritesSelectedListReducerState,
  { type, payload }: SelectFavoriteList,
): number | null => {
  switch (type) {
    case SELECT_FAVORITE_LIST:
      localStorage.setItem('favoriteListId', String(payload.listId));
      return payload.listId;
    default:
      return state;
  }
};

const favoritesActiveSharedListsReducerState: ReadonlyArray<number> = [];
export const favoritesActiveSharedListsReducer = (
  state = favoritesActiveSharedListsReducerState,
  action: SetSharedActiveLists | RemoveSharedActiveList,
): ReadonlyArray<number> => {
  switch (action.type) {
    case SET_SHARED_ACTIVE_LISTS:
      localStorage.setItem('sharedActiveLists', JSON.stringify(action.payload.ids));
      return action.payload.ids;
    case REMOVE_SHARED_ACTIVE_FAVORITE_LIST: {
      const updatedLists = state.filter((sharedId) => sharedId !== action.payload.favoriteListId);
      localStorage.setItem('sharedActiveLists', JSON.stringify(updatedLists));
      return updatedLists;
    }
    default:
      return state;
  }
};

export const initialFavoritesListsModelsReducerState: ModelOptionsState = {
  FavoriteItemLists: {
    modelOptions: {
      actions: {},
      allow: '',
      canCreate: false,
    },
  },
};
type FavoritesListsModelsReducerActions = FavoriteListsOptionsLoaded;
export const favoritesListsModelsReducer = (
  state = initialFavoritesListsModelsReducerState,
  action: FavoritesListsModelsReducerActions,
): ModelOptionsState => {
  switch (action.type) {
    case FAVORITE_LISTS_OPTIONS_LOADED:
      return {
        ...state,
        FavoriteItemLists: {
          modelOptions: {
            actions: action.payload.actions,
            allow: action.payload.allowHeader,
            canCreate: 'POST' in action.payload.actions,
          },
        },
      };
    default:
      return state;
  }
};

export const selectedListAssetsLoadingReducer = (
  state = false,
  { type, payload }: SetListAssetsLoading,
): boolean => {
  switch (type) {
    case SET_LIST_ASSETS_LOADING:
      return payload.loading;
    default:
      return state;
  }
};

export default combineReducers<FavoritesRootState>({
  isActive: favoritesActiveReducer,
  selectedListId: favoritesSelectedListReducer,
  sharedActiveLists: favoritesActiveSharedListsReducer,
  models: favoritesListsModelsReducer,
  favoriteItemLists: createResourcesReducer('favoriteitemlist'),
  favoriteItemCollections: createResourcesReducer('favoriteitemlist-favoriteitems'),
  favoriteItems: createResourcesReducer('favoriteitem'),
  selectedListAssetsLoading: selectedListAssetsLoadingReducer,
});
