import pointer from 'json-pointer';
import _ from 'lodash';
import { Action } from 'redux';
import { combineEpics, ofType, Epic } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';

import { AlertMessage, alertMessage, AlertFeedback } from 'medialoopster/AlertMessage';
import { gettext } from 'medialoopster/Internationalization';
import {
  RESTError,
  APIFieldError,
  getLinkHref,
  BaseResource,
  RESTEpicDependencies,
  UnauthorizedRequestAction,
  isAPINonFieldError,
  isAPIFieldError,
} from 'medialoopster/rest';

import { RootState } from '../../types/rootState';
import * as transferActions from './actions';
import {
  CHECK_TRANSFER_ASSETS,
  TRANSFER_ASSETS,
  TransferAssets,
  CheckTransferAssets,
  TransferStarted,
  CHECK_TRANSFER_COLLECTION,
  CheckTransferCollection,
  TRANSFER_COLLECTION,
  TransferCollection,
  SetTransferErrors,
  AssetErrors,
} from './types';

interface DryRunResource extends BaseResource {
  dry_run: boolean;
}
/**
 * Perform a pre-check for an asset bulk transfer action and set errors of individual assets when done.
 */
export const checkTransferAssetsEpic: Epic<Action, Action, RootState, RESTEpicDependencies> = (
  action$,
  _state$,
  { postResourceDryRun },
) =>
  action$.pipe(
    ofType<Action, CheckTransferAssets['type'], CheckTransferAssets>(CHECK_TRANSFER_ASSETS),
    switchMap(({ payload: { actionURL, assetURLs, filename } }) => {
      return actionURL
        ? postResourceDryRun<DryRunResource, Action, Action>(
            actionURL,
            {
              dry_run: true,
              _links: {
                assets: assetURLs.map((href) => ({
                  href,
                  ...(assetURLs.length === 1 && filename ? { filename } : {}),
                })),
              },
            },
            {
              createErrorObservable: (err: RESTError) => {
                if (!err.response || !err.response.errors || !err.request || !err.request.body) {
                  return of(transferActions.setTransferErrors(actionURL, {}));
                }
                const requestBody = JSON.parse(err.request.body);
                const assetErrors = Object.fromEntries(
                  Object.entries(
                    _.groupBy(
                      err.response.errors.filter((error): error is APIFieldError =>
                        isAPIFieldError(error),
                      ),
                      ({ source }) => source.pointer,
                    ),
                  ).map(([sourcePointer, errors]) => [
                    pointer.get(requestBody, sourcePointer)?.href,
                    errors.map(({ detail }) => detail).join('\n'),
                  ]),
                );
                return of(transferActions.setTransferErrors(actionURL, assetErrors));
              },
              createResourceObservable: () => of(transferActions.setTransferErrors(actionURL, {})),
              headers: {
                Accept: 'application/hal+json; version=3',
                'Content-Type': 'application/hal+json; version=3',
              },
            },
          )
        : of(transferActions.setTransferErrors(actionURL, {}));
    }),
  );

/**
 * Perform the asset bulk transfer.
 *
 * Errors are not acted upon, since they are already predicted by a pre-check
 * before the bulk transfer form can be submitted (see ``checkTransferAssetsEpic``).
 */
export const transferAssetsEpic: Epic<Action, Action, RootState, RESTEpicDependencies> = (
  action$,
  _state$,
  { postResource },
) =>
  action$.pipe(
    ofType<Action, TransferAssets['type'], TransferAssets>(TRANSFER_ASSETS),
    mergeMap(({ payload: { actionURL, assetURLs, filename } }) =>
      postResource<
        BaseResource,
        BaseResource,
        AlertMessage | TransferStarted | UnauthorizedRequestAction,
        AlertMessage
      >(
        actionURL,
        {
          _links: {
            assets: assetURLs.map((href) => ({
              href,
              ...(assetURLs.length === 1 && filename ? { filename } : {}),
            })),
          },
        },
        (response) => {
          if (response) {
            return of(
              alertMessage(gettext('Asset transfer started.'), AlertFeedback.Success),
              transferActions.transferStarted(getLinkHref(response, 'monitoring')),
            );
          }
          return EMPTY;
        },
        () => of(alertMessage(gettext('Failed to start asset transfer.'), AlertFeedback.Error)),
        { version: 3 },
      ),
    ),
  );

/**
 * Perform a pre-check for an collection transfer action and set errors of individual assets and collection when done.
 */
export const checkTransferCollectionEpic: Epic<Action, Action, RootState, RESTEpicDependencies> = (
  action$,
  _state$,
  { postResourceDryRun },
) =>
  action$.pipe(
    ofType<Action, CheckTransferCollection['type'], CheckTransferCollection>(
      CHECK_TRANSFER_COLLECTION,
    ),
    switchMap(({ payload: { actionURL, assetURLs, collectionURL } }) => {
      return actionURL
        ? postResourceDryRun<BaseResource, Action, Action>(
            actionURL,
            {
              _links: {
                collection: { href: collectionURL },
                assets: assetURLs.map((href) => ({ href })),
              },
            },
            {
              createErrorObservable: (err: RESTError) => {
                if (!err.response || !err.response.errors || !err.request || !err.request.body) {
                  return of(transferActions.setTransferErrors(actionURL, {}));
                }
                const requestBody = JSON.parse(err.request.body);
                const assetErrors: AssetErrors = Object.fromEntries(
                  Object.entries(
                    _.groupBy(
                      err.response.errors.filter(
                        (error): error is APIFieldError =>
                          isAPIFieldError(error) &&
                          error.source.pointer.startsWith('/_links/assets/'),
                      ),
                      ({ source }) => source.pointer,
                    ),
                  ).map(([sourcePointer, errors]) => [
                    pointer.get(requestBody, sourcePointer)?.href,
                    errors.map(({ detail }) => detail).join('\n'),
                  ]),
                );
                const collectionError = err.response.errors
                  .filter(
                    (error) =>
                      isAPIFieldError(error) &&
                      error.source.pointer.startsWith('/_links/collection'),
                  )
                  .map(({ detail }) => detail)
                  .join('\n');
                const otherError = err.response.errors
                  .filter(
                    (error) =>
                      (isAPIFieldError(error) &&
                        !(
                          error.source.pointer.startsWith('/_links/collection') ||
                          error.source.pointer.startsWith('/_links/assets/')
                        )) ||
                      isAPINonFieldError(error),
                  )
                  .map(({ detail }) => detail)
                  .join('\n');
                return of(
                  transferActions.setTransferErrors(
                    actionURL,
                    assetErrors,
                    collectionError || null,
                    otherError || null,
                  ),
                );
              },
              createResourceObservable: () => of(transferActions.setTransferErrors(actionURL, {})),
              headers: {
                Accept: 'application/hal+json; version=3',
                'Content-Type': 'application/hal+json; version=3',
              },
            },
          )
        : of(transferActions.setTransferErrors(actionURL, {}));
    }),
  );

/**
 * Perform the collection (bulk) transfer.
 *
 * Errors are not acted upon, since they are already predicted by a pre-check
 * before the bulk transfer form can be submitted (see ``checkTransferCollectionEpic``).
 */
export const transferCollectionEpic: Epic<Action, Action, RootState, RESTEpicDependencies> = (
  action$,
  _state$,
  { postResource },
) =>
  action$.pipe(
    ofType<Action, TransferCollection['type'], TransferCollection>(TRANSFER_COLLECTION),
    mergeMap(({ payload: { actionURL, assetURLs, collectionURL } }) =>
      postResource<
        BaseResource,
        BaseResource,
        AlertMessage | TransferStarted | UnauthorizedRequestAction,
        AlertMessage
      >(
        actionURL,
        {
          _links: {
            collection: { href: collectionURL },
            assets: assetURLs.map((href) => ({ href })),
          },
        },
        (response) => {
          if (response) {
            return of(
              alertMessage(gettext('Collection transfer started.'), AlertFeedback.Success),
              transferActions.transferStarted(getLinkHref(response, 'monitoring')),
            );
          }
          return EMPTY;
        },
        () =>
          of(alertMessage(gettext('Failed to start collection transfer.'), AlertFeedback.Error)),
        { version: 3 },
      ),
    ),
  );

export default combineEpics<
  Action,
  AlertMessage | TransferStarted | SetTransferErrors | UnauthorizedRequestAction,
  RootState
>(checkTransferAssetsEpic, transferAssetsEpic, checkTransferCollectionEpic, transferCollectionEpic);
